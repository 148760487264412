export type Grid = Array<Array<number>>;
export type SolvedGrid = {
  grid: Grid;
  solutions: Array<Grid>;
};

export abstract class GridBuilder {
  private static _segmentGridMap = {
    "4": 2,
    "9": 3,
    "16": 4,
  };
  private static _complexityMap = {
    easy: 2,
    middle: 4,
    hard: 6,
  };

  public static isGridFilled(grid: Grid) {
    for (let i = 0; i < grid.length; i++) {
      for (let j = 0; j < grid.length; j++) {
        if (grid[i][j] === 0) {
          return false;
        }
      }
    }
    return true;
  }
  public static isGridSolved(gird: Grid, solutions: Array<Grid>) {
    return solutions.find((solution) => {
      for (let i = 0; i < gird.length; i++) {
        for (let j = 0; j < gird.length; j++) {
          if (solution[i][j] !== gird[i][j]) {
            return false;
          }
        }
      }
      return true;
    });
  }

  public static generateGrid(gridSize: 4 | 9 | 16 = 4, complexity: "easy" | "middle" | "hard" = "easy"): SolvedGrid {
    const grid = GridBuilder._mixGrid(GridBuilder._createBaseGrid(gridSize));
    return GridBuilder._composeGridAndSolutions(grid, GridBuilder._complexityMap[complexity]);
  }

  private static _createBaseGrid(gridSize: number): Grid {
    const grid: Grid = new Array(gridSize).fill(0).map(() => new Array(gridSize).fill(0));

    let segmentDivisions = 0;

    for (let i = 0; i < grid.length; i++) {
      if (i % GridBuilder._segmentGridMap[gridSize] === 0) segmentDivisions++;

      for (let j = 0; j < grid.length; j++) {
        const cellValue = (segmentDivisions + i * GridBuilder._segmentGridMap[gridSize] + j) % gridSize;
        grid[i][j] = cellValue === 0 ? grid.length : cellValue;
      }
    }
    return grid;
  }

  private static _mixGrid(grid: Grid): Grid {
    const mixGridMap = [
      GridBuilder._transposition.bind(this, grid),
      GridBuilder._withRandomLine.bind(this, grid, GridBuilder._swapRows),
      GridBuilder._withRandomLine.bind(this, grid, GridBuilder._swapColums),
    ];

    let mixQuantity = grid.length;
    while (mixQuantity > 0) {
      const mixFunctionIndex = Math.floor(Math.random() * mixGridMap.length);
      mixGridMap[mixFunctionIndex]();
      mixQuantity--;
    }
    return grid;
  }

  private static _transposition(grid: Grid): Grid {
    const transpositionedGrid = grid[0].map((_, i) => grid.map((_, j) => grid[j][i]));
    for (let i = 0; i < grid.length; i++) {
      for (let j = 0; j < grid.length; j++) {
        grid[i][j] = transpositionedGrid[i][j];
      }
    }
    return grid;
  }

  private static _swapRows(grid: Grid, i: number, j: number): Grid {
    for (let k = 0; k < grid.length; k++) {
      [grid[j][k], grid[i][k]] = [grid[i][k], grid[j][k]];
    }
    return grid;
  }

  private static _swapColums(grid: Grid, i: number, j: number): Grid {
    for (let k = 0; k < grid.length; k++) {
      [grid[k][j], grid[k][i]] = [grid[k][i], grid[k][j]];
    }
    return grid;
  }

  private static _withRandomLine(grid: Grid, fn: (gird: Grid, i: number, j: number) => Grid): Grid {
    const rowSegments = GridBuilder._segmentGridMap[grid.length];
    const segment = Math.floor(Math.random() * rowSegments);
    let line1: number;
    let line2: number;
    const segmentRate = segment * rowSegments;
    if (segmentRate === 0) {
      line1 = Math.floor(Math.random() * rowSegments);
      line2 = Math.floor(Math.random() * rowSegments);
    } else {
      line1 = Math.floor(Math.random() * (segmentRate + rowSegments - segmentRate) + segmentRate);
      line2 = Math.floor(Math.random() * (segmentRate + rowSegments - segmentRate) + segmentRate);
    }
    return fn.call(this, grid, line1, line2);
  }

  private static _composeGridAndSolutions(grid: Grid, complexity: number): SolvedGrid {
    const emptyGridRecord: Array<Array<number>> = [];
    for (let i = 0; i < grid.length * complexity; i++) {
      let col = Math.floor(Math.random() * grid.length);
      let row = Math.floor(Math.random() * grid.length);

      for (let k = 0; k < emptyGridRecord.length; k++) {
        grid[emptyGridRecord[k][0]][emptyGridRecord[k][1]] = 0;
      }
      const tmpValue = grid[col][row];
      grid[col][row] = 0;
      const tmpSolutions = [];
      GridBuilder._fillSolutions(grid, tmpSolutions);
      if (tmpSolutions.length) {
        // for only one solution just replace on tmpSolutions.length === 1
        emptyGridRecord.push([col, row]);
      } else {
        grid[col][row] = tmpValue;
      }
    }
    const solutions: Array<Grid> = [];
    GridBuilder._fillSolutions(grid, solutions);

    return { grid, solutions };
  }

  private static _fillSolutions(grid: any, store: any, row = 0, col = 0): void {
    if (col === grid.length && row === grid.length - 1) {
      const solution = grid.map((arr: any) => {
        return arr.slice();
      });
      store.push(solution);
      return;
    }
    if (col === grid.length) {
      row++;
      col = 0;
    }

    if (grid[row][col] !== 0) {
      GridBuilder._fillSolutions(grid, store, row, col + 1);
      return;
    }

    for (let i = 1; i <= grid.length; i++) {
      if (GridBuilder._isValid(grid, row, col, i)) {
        grid[row][col] = i;
        GridBuilder._fillSolutions(grid, store, row, col + 1);
      }
      grid[row][col] = 0;
    }
  }

  private static _isValid(grid: Grid, row: number, col: number, value: number): boolean {
    for (let i = 0; i < grid.length; i++) {
      if (grid[row][i] === value) {
        return false;
      }
    }

    for (let j = 0; j < grid.length; j++) {
      if (grid[j][col] === value) {
        return false;
      }
    }
    const rowSegments = GridBuilder._segmentGridMap[grid.length];
    row = row - (row % rowSegments);
    col = col - (col % rowSegments);

    for (let i = 0; i < rowSegments; i++) {
      for (let j = 0; j < rowSegments; j++) {
        if (grid[i + row][j + col] === value) {
          return false;
        }
      }
    }

    return true;
  }
}
