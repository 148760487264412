import { useMemo } from "react";
import { useLocation } from "react-router-dom";

/**
 * @alias useQuery
 * @description get object from query params string.
 * "?&page=1&test=hello" => { page: "1", test: "hello" }
 */
const useQuery = (): { [key: string]: string } => {
  const location = useLocation();
  return useMemo(() => Object.fromEntries(new URLSearchParams(location.search)), [location.search]);
};

export default useQuery;
