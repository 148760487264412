import GridBase from "./GridBase";

class SimpleGrid extends GridBase {
  index: number = 0;
  gridView: any = null;

  constructor(gridView) {
    super();

    this.index = 0;
    this.gridView = gridView;
  }

  get canSlidePrev() {
    return this.index - this.gridView.props.restrictBy >= 0;
  }

  get canSlideNext() {
    return (
      this.index + this.gridView.props.restrictBy <
      this.gridView.props.children.length
    );
  }

  slideNext() {
    this.index = this.index + this.gridView.props.restrictBy;
    this.gridView.forceUpdate();
  }

  slidePrev() {
    this.index = this.index - this.gridView.props.restrictBy;
    this.gridView.forceUpdate();
  }

  renderItems() {
    return this.gridView.props.children.slice(
      this.index,
      this.index + this.gridView.props.restrictBy
    );
  }
}

export default SimpleGrid;
