import { useEffect, useRef, useState, FC } from "react";
import { getDistance, getAngle } from "./Ball.helper";
import { Noise } from "noisejs";
import "./Ball.scss";
import { BallProps, Coords, SnowballCoords } from "./Ball.types";
import fullGlobe from "../../../../../../assets/newyear2023/games/wishBall/fullGlobe.svg";

const noise = new Noise("random seed");
const width = 260;
const height = 260;
const contentWidth = width - 40;
const contentHeight = height - 40;
const radius = contentHeight / 2;
const countParticles = 300;
const y = height / 2;
const x = width / 2;
const contentY = contentHeight / 2;
const endTriggerY = contentY + radius / 3;
let timer = 0;
let flyDuration = 0;

const Ball: FC<BallProps> = ({ onStartAnimation, onEndAnimation, fastEndAnimation, disable = false }) => {
  const [isEndAnimation, setIsEndAnimation] = useState(false);
  const [isCanShake, setIsCanShake] = useState(true);
  const [isInit, setIsInit] = useState(true);
  const [ctx, setCtx] = useState(null);
  const [snowParticles, setSnowParticles] = useState([]);
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const drawCircle = (x, y, radius, fill) => {
    ctx.beginPath();
    ctx.arc(x, y, radius, 0, 2 * Math.PI);
    ctx.closePath();
    renderShape(fill);
  };

  const renderShape = (fill) => {
    if (!fill) return;
    ctx.lineJoin = "miter";
    ctx.lineWidth = 2;
    ctx.fillStyle = fill;
    ctx.fill();
  };

  const clearScreen = () => {
    ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
  };

  const createSnowParticle = (x, y, radius): SnowballCoords => {
    const vectorX = Math.random() * 8 - 4;
    const vectorY = Math.random() * 8 - 4;
    return {
      x,
      y,
      vectorX,
      vectorY,
      radius,
    };
  };

  const createUnitCircle = (): Coords => {
    return {
      x: 0,
      y: radius,
    };
  };

  const updateParticle = (particle) => {
    const distanceToCenter = getDistance({ x: particle.x, y: particle.y }, { x: x, y: y });
    particle.vectorX += noise.simplex3(particle.x / 100, particle.y / 100, timer / 100) * 0.5 * flyDuration;
    particle.vectorY += noise.simplex3(particle.x / 100, particle.y / 100, (timer + 100) / 100) * 0.5 * flyDuration;
    particle.vectorY += 0.03;
    particle.x += particle.vectorX * 0.3;
    particle.y += particle.vectorY * 0.3;
    if (distanceToCenter > radius) {
      const a = getAngle({ x: particle.x, y: particle.y }, { x: x, y: y });
      particle.x = x + Math.cos(a + (flyDuration >= 0.1 ? 0 : Math.PI)) * (radius - 1);
      particle.y = y + Math.sin(a + (flyDuration >= 0.1 ? 0 : Math.PI)) * (radius - 1);
    }
    particle.vectorX *= 0.999;
    particle.vectorY *= 0.999;
  };

  const renderClip = () => {
    ctx.beginPath();
    ctx.arc(x, y, radius, 0, Math.PI * 2);
    ctx.clip();
    ctx.beginPath();
    ctx.rect(20, 20, contentWidth, 172);
    ctx.clip();
  };

  const mainRender = () => {
    for (let particle of snowParticles) {
      renderParticle(particle);
    }
  };

  const mainUpdate = () => {
    if (isInit) return;
    for (let particle of snowParticles) {
      updateParticle(particle);
    }
    timer++;
    flyDuration -= 0.0025;
    if (flyDuration <= 0) {
      flyDuration = 0;
      if (!fastEndAnimation) checkEndAnimation();
    }
  };

  const checkEndAnimation = () => {
    if (isEndAnimation) return;
    const fallSnow = snowParticles.filter((particle) => particle.y > endTriggerY);
    const percentFallSnow = (fallSnow.length / snowParticles.length) * 100;
    const threshold = 95;
    const isEnd = percentFallSnow >= threshold;
    if (isEnd) setIsEndAnimation(isEnd);
  };

  const shake = () => {
    if (!isCanShake || disable) return;
    startAnimation();
    for (let particle of snowParticles) {
      const distanceToCenter = getDistance({ x: particle.x, y: particle.y }, { x: x, y: y });
      particle.vectorX = Math.random() * 8 - 4;
      particle.vectorY = Math.random() * 8 - 4;
      if (distanceToCenter > radius) {
        particle.x += (x - particle.x) * 0.1;
        particle.y += (y - particle.y) * 0.1;
      }
    }
    setTimeout(() => endAnimation(), 1500);
  };

  const startAnimation = () => {
    onStartAnimation && onStartAnimation();
    setIsCanShake(false);
    setIsEndAnimation(false);
    setIsInit(false);
    flyDuration = 1;
  };

  const endAnimation = () => {
    setIsCanShake(true);
    if (fastEndAnimation && onEndAnimation) onEndAnimation();
  };

  const start = () => {
    if (!canvasRef.current) return;
    clearScreen();
    mainUpdate();
    mainRender();
    requestAnimationFrame(start);
  };

  const renderParticle = (particle) => {
    drawCircle(particle.x, particle.y, particle.radius, "white");
  };

  useEffect(() => {
    if (!ctx) return;
    const particles = [];
    for (let i = 0; i < countParticles; i++) {
      const circle = createUnitCircle();
      particles.push(
        createSnowParticle(x + circle.x * radius * 0.9, y + circle.y * radius * 0.9, Math.random() * 2 + 2)
      );
    }
    setSnowParticles(particles);
  }, [ctx]);

  useEffect(() => {
    if (!canvasRef.current) return;
    setCtx(canvasRef.current.getContext("2d"));
  }, [canvasRef.current]);

  useEffect(() => {
    if (!snowParticles.length) return;
    renderClip();
    const id = requestAnimationFrame(start);
    return () => cancelAnimationFrame(id);
  }, [snowParticles, isInit]);

  useEffect(() => {
    if (!isEndAnimation || isInit) return;
    onEndAnimation && onEndAnimation();
  }, [isEndAnimation]);

  return (
    <div
      className={`new-year-2023-ball ${!isCanShake ? "new-year-2023-ball--active" : ""}`}
      style={{ width: width, height: height }}
      onClick={shake}
    >
      <canvas className={"new-year-2023-ball__canvas"} ref={canvasRef} width={width} height={height}></canvas>
      <div className="new-year-2023-ball__picture">
        <img src={fullGlobe} width={contentWidth} height={contentHeight + 53} />
      </div>
    </div>
  );
};

export default Ball;
