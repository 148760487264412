import TinyPin from "../../../assets/map/tinyPin.png";
import SmallPin from "../../../assets/map/smallPin.png";
import MediumPin from "../../../assets/map/mediumPin.png";
import LargePin from "../../../assets/map/largePin.png";

export const BulletRadiusTiny = 4;
export const BulletRadiusSmall = 14.5;
export const BulletRadiusMedium = 18;
export const BulletRadiusLarge = 21.5;

export const BorderWidthTiny = 2;
export const BorderWidthSmall = 3;
export const BorderWidthMedium = 4;
export const BorderWidthLarge = 5;

const getPinUrl = (size) => {
  if (size >= 100) return LargePin;
  if (size >= 10) return MediumPin;
  if (size >= 2) return SmallPin;
  return TinyPin;
}

export const getDefaultPinStyle = (feature) => {
  if (!feature) return;

  const size = feature.get("features").length;
  const pinUrl = getPinUrl(size);
  const text = size.toString() > 1 ? size.toString() : null;

  return new window.ol.style.Style({
    image: new window.ol.style.Icon({
      src: pinUrl
    }),
    ...(text && {
      text: new window.ol.style.Text({
        font: "normal normal bold 20px/48px Bebas Neue",
        textAlign: "center",
        text: text,
        fill: new window.ol.style.Fill({
          color: "#ffffff",
        }),
      }),
    }),
  });
};

export const getActivePinStyle = () => {
  return new window.ol.style.Style({
    image: new window.ol.style.Icon({
      src: require("../../../assets/map/active-pin.svg").default,
      anchor: [0.5, 1],
    }),
    zIndex: 1000
  });
};
