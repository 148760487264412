// @flow
import { StatisticState } from "../../types/Stats.interface";
import {
  STATISTICS_REQUEST,
  STATISTICS_SUCCESS,
  STATISTICS_FAIL,
} from "../actions/StatisticsActions";

const initialState = {
  stats: null,
  error: "",
  isFetching: false,
};

export function statisticsReducer(
  state: StatisticState = initialState,
  action
) {
  switch (action.type) {
    case STATISTICS_REQUEST:
      return { ...state, isFetching: true, error: "" };

    case STATISTICS_SUCCESS:
      return { ...state, isFetching: false, stats: action.payload };

    case STATISTICS_FAIL:
      return { ...state, isFetching: false, error: action.payload.message };

    default:
      return state;
  }
}
