import { forwardRef, useEffect } from "react";
import { useOuterClick } from "../../../../../hooks/useOnOutsideClick";
import { ModalProps } from "./Modal.types";
import "./Modal.scss";
import useCombinedRefsCallback from "../../../../../hooks/useCombinedRefsCallback";

const Modal = forwardRef<HTMLDivElement, ModalProps>(
  (
    {
      className,
      rootRef,
      style,
      onClose,
      closeOnOutside = true,
      closeOnEsc = false,
      showCloseBtn = false,
      withBackdrop = false,
      title,
      content,
    },
    ref
  ) => {
    const innerRef = useOuterClick<HTMLDivElement>((e: MouseEvent) => {
      const target = e.target as HTMLElement;
      if (closeOnOutside) {
        if (rootRef?.current && rootRef.current.contains(target)) return;
        onClose();
      }
    }, true);

    const combinedRef = useCombinedRefsCallback(innerRef, ref);

    useEffect(() => {
      if (closeOnEsc) {
        document.addEventListener("keydown", handleKeydown);
        return () => document.removeEventListener("keydown", handleKeydown);
      }
    }, [closeOnEsc]);

    const handleKeydown = (event) => {
      if (event.key === "Escape" || event.keyCode === 27) {
        onClose();
      }
    };

    return (
      <>
        <div ref={combinedRef} className={`leisure-landing-modal ${className || ""}`} style={style}>
          {showCloseBtn && <button type="button" className="leisure-landing-modal__close-btn" onClick={onClose} />}
          {!!title && <div className="leisure-landing-modal__title">{title}</div>}
          <div className="leisure-landing-modal__content">{content}</div>
        </div>
        {withBackdrop && <div className="leisure-landing-modal-backdrop"></div>}
      </>
    );
  }
);

export default Modal;
