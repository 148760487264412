import {
  DELETE_NOTIFICATION_HANDLE,
  DELETE_NOTIFICATION_SUCCESS,
  GET_NEW_NOTIFICATIONS_COUNT_SUCCESS,
  GET_NOTIFICATION_FAILED,
  GET_NOTIFICATION_HANDLE,
  GET_NOTIFICATION_SUCCESS,
  READ_NOTIFICATION_SUCCESS,
} from "../actions/NotificationsActions";
import { NotificationsState } from "../../types/Notifications.interface";

const initialState = {
  notifications: null,
  unreadNotifications: 0,
  error: "",
  isFetching: false,
  isFetchingUpdateNotifications: false,
  pagination: null,
};

export function notificationsReducer(state: NotificationsState = initialState, action) {
  switch (action.type) {
    case GET_NOTIFICATION_HANDLE: {
      return { ...state, isFetching: true, error: "" };
    }
    case GET_NOTIFICATION_SUCCESS:
      return {
        ...state,
        isFetching: false,
        notifications: action.payload,
      };

    case READ_NOTIFICATION_SUCCESS:
      const readIDS = action.payload.ids;
      return {
        ...state,
        notifications: state.notifications.map((notification) =>
          readIDS.includes(notification.id) ? { ...notification, read: true } : notification
        ),
      };

    case DELETE_NOTIFICATION_SUCCESS:
      const deletedID = action.payload.id;
      return {
        ...state,
        notifications: [...state.notifications.filter((notification) => notification.id !== deletedID)],
      };

    case GET_NEW_NOTIFICATIONS_COUNT_SUCCESS:
      return {
        ...state,
        isFetching: false,
        unreadNotifications: action.payload,
      };
    case GET_NOTIFICATION_FAILED:
      return {
        ...state,
        isFetching: false,
        error: action.payload.message,
      };

    default:
      return state;
  }
}
