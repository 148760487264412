import React from "react";
import "./NodeWithStageSelectorModal.scss";
import { AppModal } from "../../../../containers/Modal/AppModal";
import { GroupDirectionNode, GroupDirectionStage } from "../../../../../types/GroupDirection.interface";
import { calculateRemainedTime } from "../../../../../utils";

type DataItem = GroupDirectionNode & { activeStage: GroupDirectionStage };

interface NodeWithStageItemProps<T extends DataItem> {
  node: T;
  onChoose: (node: T) => void;
  close: () => void;
}

const NodeWithStageItem = <T extends DataItem>({ node, onChoose, close }: NodeWithStageItemProps<T>) => {
  const [_time, _unit] = calculateRemainedTime(node.activeStage);
  const handleItemClick = () => {
    close();
    onChoose(node);
  };

  return (
    <div className="node-with-stage-selector__stage-item">
      <div className="node-with-stage-selector__stage-item__title" onClick={handleItemClick}>
        {node.title}
      </div>
      <div className="node-with-stage-selector__stage-item__countdown">
        {_time} {_unit} до завершения
      </div>
    </div>
  );
};

interface NodeWithStageSelectorModalProps<T extends DataItem> {
  isOpen: boolean;
  close: () => void;
  header?: React.ReactNode;
  message?: string;
  listHeader?: string;
  nodes: T[];
  onChoose: (node: T) => void;
}

const NodeWithStageSelectorModal = <T extends DataItem>({
  isOpen,
  close,
  header,
  message,
  listHeader,
  nodes,
  onChoose,
}: NodeWithStageSelectorModalProps<T>) => {
  return (
    <AppModal
      open={isOpen}
      onClose={close}
      blockScroll
      center
      classNames={{ modal: "node-with-stage-selector__modal" }}
      overlayId="modal-overlay"
      closeIconId="close-modal-icon"
      closeIconSize={25}
      closeOnEsc
      closeOnOverlayClick={false}
    >
      <div className="node-with-stage-selector">
        {header && <div className="node-with-stage-selector__title">{header}</div>}
        {message && (
          <div className="node-with-stage-selector__description" dangerouslySetInnerHTML={{ __html: message }} />
        )}
        {!!nodes?.length && (
          <>
            {listHeader && <div className="node-with-stage-selector__subhead">{listHeader}</div>}
            <div className="node-with-stage-selector__stage-list">
              {nodes.map((node) => (
                <NodeWithStageItem key={node.id} node={node} onChoose={onChoose} close={close} />
              ))}
            </div>
          </>
        )}
      </div>
    </AppModal>
  );
};

export default NodeWithStageSelectorModal;
