import { forwardRef } from "react";

const Line3 = forwardRef<SVGPathElement>((_, ref) => (
  <svg
    width="1448"
    height="1126"
    viewBox="0 0 1448 1126"
    fill="none"
    style={{ transform: "translate(8px, 0px)" }}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      ref={ref}
      d="M104.04 1C47.5491 22.7603 -35.5555 153.742 18.5537 248.855C79.5441 356.065 207.954 368.347 453.985 296.622C637.956 242.989 690.704 111.394 682.449 62.5657C671.95 0.46926 601.648 75.8342 661.952 193.333C700.253 267.962 800.945 251.061 818.427 179.328C836.924 103.433 727.442 190.325 850.922 267.431C1009.44 366.419 1278.35 391.093 1392.34 602.327C1492.97 788.822 1445.33 1063.96 1325.35 1125"
      stroke="#252526"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
));

export default Line3;
