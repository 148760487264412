import React from "react";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { getAvatarSrc, isGuid, shuffle, stripHTML } from "../../../../utils";
import { phoneWidth, tabletWidth } from "../../../../utils/constants/widthConstants";
import { getQuarter, getQuarterString } from "../../../../utils/dates";
import { RealizedType, ResultIdea } from "../../../pages/WorkResult/WorkResultPage";
import NextModal from "../../../presentational/Modals/NextModal/NextModal";
import Share from "../../Share/Share";
import CommonSlider from "../../Sliders/CommonSlider/CommonSlider";
import "./WorkResultCard.scss";
import { nameIfHidden, renderHatIfUserNameIsHidden } from "../../../../utils/user.utils";
import { RootState } from "../../../../types/State.interface";
import { GroupDirection } from "../../../../types/GroupDirection.interface";

export interface WorkResultCardProps {
  project: any;
  groupDirection?: GroupDirection;
  idea: ResultIdea;
  type: string;
}

const WorkResultCard = ({ idea, project, groupDirection, type }: WorkResultCardProps) => {
  const currentUser = useSelector((state: RootState) => state.user.userDetails);
  const isMobile = useMediaQuery({ query: `(max-width: ${phoneWidth}px)` });
  const isDesktop = useMediaQuery({ query: `(min-width: ${tabletWidth}px)` });
  const isTablet = useMediaQuery({
    query: `(max-width: ${tabletWidth}px) and (min-width: ${phoneWidth}px)`,
  });

  const getPhotoList = (idea) => {
    const photoMainId = idea.mainPhotoId ? [idea.mainPhotoId] : [];
    const photoMainUserId = idea.mainUserPhotoAttachmentId ? [idea.mainUserPhotoAttachmentId] : [];
    const photoIds = idea.publishedPhotoIds || [];
    const photoUserIds = idea.publishedUserPhotoAttachmentsIds || [];
    const uniq = new Set([...photoMainId, ...photoMainUserId, ...photoIds, ...photoUserIds]);
    return Array.from(uniq);
  };

  const declByOrgName = (orgName) => {
    const lc = orgName.toLowerCase();
    if (
      lc.includes("правительство") ||
      lc.includes("управл") ||
      lc.includes("учреждение") ||
      lc.includes("объединение")
    ) {
      return "реализовало";
    }

    if (lc.includes("департамент") || lc.includes("комитет")) {
      return "реализовал";
    }

    if (lc.includes("префектура") || lc.includes("управа")) {
      return "реализовала";
    }

    return "реализовал";
  };

  const renderRealizationDate = (idea) => {
    if (idea.completed && type === "realized") {
      return `${declByOrgName(idea.organizationName)} идею в ${getQuarter(idea.completed)} квартале ${new Date(
        idea.completed
      ).getFullYear()} года.`;
    }

    if (idea.deadline) {
      return `реализует идею в ${getQuarter(idea.deadline)} квартале ${new Date(idea.deadline).getFullYear()} года.`;
    }
  };

  const getShareData = () => ({
    url: `/realized/ideas/${idea.id}#projectId=${project.id}${
      groupDirection ? `&groupDirectionId=${groupDirection.id}` : ""
    }`,
    imageUrl: "/images/logo-GI-VK.png",
    title: `На платформе «Город идей» в рамках проекта «${(
      groupDirection?.title ||
      project.nameOnPromo ||
      project.title ||
      ""
    ).replace(/&nbsp;?/g, " ")}» предложена идея «${stripHTML(idea.text).replace(/[\n\s]+/g, " ").trim()}»`,
  });

  const getName = () =>
    idea.author?.deletedSudir ? nameIfHidden : idea.author?.firstName + " " + idea.author?.lastName;

  const renderPlainContent = () => {
    const photoList = getPhotoList(idea);
    return (
      <div className="wrv-card">
        <div className="wrv-card__head">
          {idea.organizationName && (
            <div className="wrv-card__head-org">
              {idea.organizationName} {renderRealizationDate(idea)}
            </div>
          )}

          <div className="wrv-card__head-status">
            {idea.selectedByExperts && (
              <React.Fragment>
                <span className="role-icon chief-expert-icon"></span>
                Идея отмечена экспертами проекта
              </React.Fragment>
            )}
          </div>
        </div>
        <div className="wrv-card__body">
          {idea.author && (
            <div className="wrv-card__body-user">
              <img src={getAvatarSrc(idea.author)} alt="Аватар пользователя" />
              <span>
                {getName()}
                {renderHatIfUserNameIsHidden(idea.author, currentUser)}
              </span>
            </div>
          )}

          <div className="wrv-card__body-info">
            <div className="wrv-card__body-project">
              Проект &laquo;
              <span dangerouslySetInnerHTML={{ __html: groupDirection?.title || project?.nameOnPromo }} />
              &raquo;
            </div>
            <div className="wrv-card__body-list" dangerouslySetInnerHTML={{ __html: idea.text }}></div>
          </div>
        </div>

        <div className="wrv-card__footer">
          {idea.type === RealizedType.REALIZED && !!photoList.length && (
            <div className="wrv-card__photos">
              {shuffle(photoList)
                .slice(0, isDesktop ? 4 : 3)
                .map((id) => {
                  return <img key={id} src={window.location.origin + "/uploads/get/" + id}></img>;
                })}
            </div>
          )}

          <Share {...getShareData()} backgroundColorIcon="#8a8a8a" colorIcon="white" />
        </div>
      </div>
    );
  };

  const renderMobileContent = () => {
    return (
      <div className="wrm-card">
        <div className="wrm-card__title">
          Проект &laquo;
          <span dangerouslySetInnerHTML={{ __html: groupDirection?.title || project?.nameOnPromo }} />
          &raquo;
        </div>

        {idea.type === RealizedType.REALIZED && (
          <div className="wrm-card__photos">
            <CommonSlider
              className={"wrm-card__slider"}
              options={{
                slidesPerColumn: 1,
                spaceBetween: 2,
                slidesPerView: 1.1,
                centeredSlides: true,
              }}
            >
              {getPhotoList(idea)?.map((id) => (
                <img key={id} src={window.location.origin + "/uploads/get/" + id}></img>
              ))}
            </CommonSlider>
          </div>
        )}

        {idea.organizationName && (
          <div className="wrm-card__org">
            {idea.organizationName} {renderRealizationDate(idea)}
          </div>
        )}

        <div className="wrm-card__quote">
          <div className="wrm-card__quote-text">
            <div dangerouslySetInnerHTML={{ __html: idea.text }}></div>
          </div>
          {idea.author && <div className="wrm-card__quote-user">{getName()}</div>}
        </div>

        {idea.selectedByExperts && (
          <div className="wrm-card__status">
            <span className="role-icon chief-expert-icon"></span>
            Идея отмечена экспертами проекта
          </div>
        )}

        <Share {...getShareData()} backgroundColorIcon="#8a8a8a" colorIcon="white" />
      </div>
    );
  };

  return (idea && (isMobile ? renderMobileContent() : renderPlainContent())) || <React.Fragment></React.Fragment>;
};

export default WorkResultCard;
