import { API } from "./api";

export interface GetQuery {
    page: number;
    size: number;
}

export interface ReadQuery {
  ids: string[];
}

export interface DeleteOneQuery {
  id: string;
}

export interface DeleteManyQuery {
  ids: string[];
}

const baseUrl = "/api/notification";

const NotificationsService = {
  get: (query: GetQuery) => {
    return API.get(`${baseUrl}/user/get?page=${query.page}&size=${query.size}`).then(({ data }) => {
      return data;
    });
  },

  read: (query: ReadQuery) => {
    return API.post(`${baseUrl}/user/read`, query).then(({ data }) => {
      return data;
    });
  },

  deleteOne: (query: DeleteOneQuery) => {
    return API.post(`${baseUrl}/user/delete`, {ids: [query.id]}).then(({ data }) => {
      return data;
    });
  },

  deleteMany: (query: DeleteManyQuery) => {
    return API.post(`${baseUrl}/user/delete`, query).then(({ data }) => {
      return data;
    });
  },

  counUnread: () => {
    return API.get(`${baseUrl}/user/countUnread`).then(({ data }) => {
      return data;
    });
  },
};

export default NotificationsService;
