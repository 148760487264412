import { ComponentProps, forwardRef } from "react";
import Select from "../../../../presentational/Controls/Select/Select";
import EventsTrackWrapperClick from "../EventsTrackWrapperClick";

const EventsTrackClickSelectInput = forwardRef<HTMLDivElement, ComponentProps<ComponentProps<typeof Select>["ComponentSelectInput"]>>( ({isListVisible, label, ...otherProps}, ref) => (
    <EventsTrackWrapperClick id={25} replace={[label, isListVisible ? "Close" : "Open"]}>
        <div ref={ref} { ...otherProps } />
    </EventsTrackWrapperClick>
) );

export default EventsTrackClickSelectInput;