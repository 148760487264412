import { API, httpParamsSerialize } from "./api";

const IdeaService = {
  getByFilter: (params) => {
    const url = "/api/idea/getByFilter?" + httpParamsSerialize(params);
    return API.get(url).then((data) => {
      return data?.data;
    });
  },

  getRandomByStatusAndTag: (params) => {
    return API.get(`/api/idea/getRandomByStatusAndTag?${httpParamsSerialize(params)}`).then((data) => {
      return data?.data;
    });
  },

  getTags: () => {
    return API.get("/api/idea/tags").then(({ data }) => {
      return data;
    });
  },

  like: (id) => {
    return API.post("/api/idea/rate", { id: id }).then(({ data }) => {
      return data;
    });
  },

  propose: (idea) => {
    return API.post("/api/idea/rate", { id: idea.id }).then(({ data }) => {
      return data;
    });
  },

  getProjects: () => {
    return API.get("/api/idea/list").then(({ data }) => {
      return data;
    });
  },

  sendIdea: (query) => {
    return API.post("/api/idea/add", query).then(({ data }) => {
      return data;
    });
  },

  showAuthorInfo: () => {
    return API.get("/api/idea/list").then(({ data }) => {
      return data;
    });
  },
  ideaById: (id) => {
    return API.get(`/api/idea/get?id=${id}`).then(({ data }) => {
      return data;
    });
  },
  gorodIdeaById: (id) => {
    return API.get(`/api/gorod/idea/get?id=${id}`).then(({ data }) => {
      return data;
    });
  },

  getRealizedIdeas: (projectId) => {
    return API.get(`/api/idea/realized/getAll?projectId=${projectId}`).then(({ data }) => {
      return data;
    });
  },
};

export default IdeaService;
