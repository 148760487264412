import { FC } from "react";
import { AppModal } from "../../../../containers/Modal/AppModal";
import CommonSlider from "../../../../presentational/Sliders/CommonSlider/CommonSlider";
import "./PhotoReportModal.scss";
import { PhotoReport } from "../PhotoReport/PhotoReport";
import useImagePreload from "../../../NewYear2023/hooks/useImagePreload";
import Loader from "../../../../presentational/Loaders/Loader/Loader";
import { useMediaQuery } from "react-responsive";
import { phoneWidth } from "../../../../../utils/constants/widthConstants";

const PhotoReportModal: FC<{ onClose: () => void; photoReport: PhotoReport }> = ({ onClose, photoReport }) => {
  const hasBeenLoaded = useImagePreload(photoReport.imageIds.map((id) => `/uploads/get/${id}`));
  const isPhone = useMediaQuery({ query: `(max-width: ${phoneWidth}px)` });
  return (
    <AppModal
      classNames={{ modal: "photo-report__modal" }}
      center
      open={true}
      overlayId="modal-overlay"
      closeIconId="close-modal-icon"
      onClose={onClose}
      blockScroll={true}
      closeIconSize={25}
      closeOnEsc={true}
      closeOnOverlayClick={false}
    >
      <div className="photo-report">
        <p className="photo-report__title">{photoReport.name}</p>
        <CommonSlider
          onInit={() => {}}
          className={!isPhone ? "photo-report__slider" : null}
          options={{
            loop: false,
            slidesPerView: 1,
            slidesPerColumn: 1,
            slidesPerGroup: 1,
            spaceBetween: 24,
          }}
        >
          {!hasBeenLoaded ? (
            <div className="photo-report__loader">
              <Loader />
            </div>
          ) : (
            photoReport.imageIds.map((id) => {
              return (
                <div className="photo-report__img" key={id}>
                  <img src={`/uploads/get/${id}`} alt="" />
                </div>
              );
            })
          )}
        </CommonSlider>
      </div>
    </AppModal>
  );
};

export default PhotoReportModal;
