import { API } from "./api";

const getNewsServiceLine = {
  getNewsById: () => {
    return API.get("/api/settings/bands").then(({ data }) => {
      return data;
    });
  },
};

export default getNewsServiceLine;
