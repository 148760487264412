import React from "react";
import TagsCard from "../TagsCard/TagsCard";
import { TagsCardParentProps } from "../TagsCard/TagsCard.interface";
import { Video } from "../../../../types/Video.interface";

import "./VideoCard.scss";

const VideoCard = ({ element, showedVideo = false, id, onClick, onTagClick, share }: TagsCardParentProps<Video>) => {
  return (
    <TagsCard<Video>
      id={id}
      section="video"
      date={element?.uploaded}
      element={element}
      imageId={element?.previewId}
      onClick={onClick}
      onTagClick={onTagClick}
      videoId={showedVideo ? element?.videoId : ""}
      tag={element?.tag}
      title={element?.name}
      share={share}
    />
  );
};

export default VideoCard;
