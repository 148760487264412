import src from "*.avif";
import { readSync } from "fs";
import React from "react";
import HTMLFlipBook from "react-pageflip";

import Platform from "../../../../services/platform";
import BookViewBase from "./BookViewBase";

class FlipBook extends BookViewBase {
  flipBookref: any;
  bookView: any;
  page: number = 0;
  items: any;
  beginFlip: boolean = false;
  endFlip: boolean = false;

  constructor(bookView) {
    super();

    this.bookView = bookView;
    this.flipBookref = React.createRef();
  }

  swipe() {}

  swipeDown() {}

  swipeUp() {}

  getPageWidth() {
    const el: HTMLElement | null = document.querySelector(".wrapper");
    if (el) {
      return Math.floor(+(el.offsetWidth / 2));
    }

    return 0;
  }

  getTranslateX() {
    if (this.endFlip && !this.beginFlip) {
      return this.getPageWidth() / 2;
    }

    if (!this.beginFlip && !this.endFlip) {
      return this.getPageWidth() / -2;
    }

    return 0;
  }

  onFlip(e) {
    if (e.data === "flipping") {
      const turnFirstPage = e.object.pages.currentPageIndex === 0;
      if (turnFirstPage) {
        this.beginFlip = true;
      }

      const turnFirstPageBack =
        e.object.pages.render.direction === 1 &&
        e.object.pages.currentPageIndex === 2;
      if (turnFirstPageBack) {
        this.beginFlip = false;
      }

      const turnLastPage =
        e.object.pages.render.direction === 0 &&
        e.object.pages.currentPageIndex ===
          this.bookView.props.desktopImages.length - 2;
      if (turnLastPage) {
        this.beginFlip = false;
        this.endFlip = true;
      }

      const turnLastPageBack =
        e.object.pages.render.direction === 1 &&
        e.object.pages.currentPageIndex ===
          this.bookView.props.desktopImages.length;
      if (turnLastPageBack) {
        this.beginFlip = true;
        this.endFlip = false;
      }
    }

    this.bookView.forceUpdate();
  }

  canRender() {
    return (
      this.bookView.props.desktopImages &&
      this.bookView.props.desktopImages.length
    );
  }

  // RENDER

  renderCover() {
    return (
      <div>
        <div
          className="flipbook__page"
          style={{ backgroundColor: "#efefef" }}
        ></div>
      </div>
    );
  }

  renderPages() {
    return this.bookView.props.desktopImages.map((img, idx) => (
      <div>
        <div
          className="flipbook__page"
          style={{ backgroundImage: `url(${img})` }}
        />
      </div>
    ));
  }

  render() {
    return (
      <>
        {this.canRender() && (
          <div
            className="flipbook"
            ref={this.flipBookref}
            style={{ transform: `translateX(${this.getTranslateX()}px)` }}
          >
            <HTMLFlipBook
              width={this.getPageWidth()}
              height={this.getPageWidth()}
              showCover={false}
              drawShadow={false}
              onFlip={(e) => this.onFlip(e)}
              onChangeState={(e) => this.onFlip(e)}
            >
              {this.renderCover()}
              {this.renderPages()}
            </HTMLFlipBook>
          </div>
        )}
      </>
    );
  }
}

export default FlipBook;
