import React, { useState, useEffect, useRef, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { API } from "../../../../../services/api";
import { LocalStorageKeys, LocalStorageService } from "../../../../../services/LocalStorage.service";
import Button from "../../../../presentational/Controls/Button/Button";
import EventsTrackWrapperClick from "../../../EventsTrack/wrappers/EventsTrackWrapperClick";
import EventsTrackClickModalCloseBtn from "../../../EventsTrack/wrappers/helpers/EventsTrackClickModalCloseBtn";
import { AppModal } from "../../../Modal/AppModal";
import "./ActiveSessionForm.scss";
import { AppContext } from "../../../../Root";
import { AuthAction, AuthActionParam, AuthActionType, ComponentType, mapPageToComponentType } from "../../../../../services/sudirService";
import { logout } from "../../../../../store/actions/UserActions";

interface ActiveSessionFormProps {
  isOpen: boolean;
  onClose: () => void;
}

const mosUser = require("../../../../../assets/cross-promo/mos-user.svg").default;
const avatar = require("../../../../../assets/cross-promo/avatar.svg").default;

export const ActiveSessionForm = ({
  isOpen,
  onClose,
}: ActiveSessionFormProps) => {
  const appContext = useContext(AppContext);
  const dispatch = useDispatch();
  const sudirUserDetails = useSelector((state: any) => state.user.sudirUserDetails);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phone, setPhone] = useState('');

  useEffect(() => {
    if (sudirUserDetails) {
      const {firstName, lastName, phone} = sudirUserDetails;
      setFirstName(firstName);
      setLastName(lastName ? lastName[0] + '.' : '');
      setPhone(getMaskedPhoneNumber(phone));
    }

  }, [sudirUserDetails])

  const useThisAccount = () => {
    LocalStorageService.removeData(LocalStorageKeys.CROWD_FORCED_LOGOUT);
    const flexibleContainerData: AuthActionParam = {
      component: mapPageToComponentType[window.location.pathname],
      type: AuthActionType.LOGIN,
      args: {},
    };
    const action1 = new AuthAction(flexibleContainerData);
    const link = `${window.location.origin}/oauth2/authorization/sudir` + `?${appContext.sudirService.generateReturnUrlParameter(window.location.href, [action1])}`;
    window.location.href = link;
  };

  const useOtherAccount = () => {
    window.location.href = `${window.location.origin}/oauth2/authorization/sudir?returnUrl=${window.location.href}&logout=true`;
  };

  const handleOnClose = () => {
    dispatch(logout({keepGlobalSession: "true"}));
    onClose();
  }

  const getMaskedPhoneNumber = (origin) => {
    try {
      if (origin) {
        const digits = origin.replace('+', '').split('');
        let resultNumber = '';
        for (let i = 0; i < digits.length; i++) {
          if (i === 1 || i === 4 || i === 7 || i === 9) {
            resultNumber += ' ';
          }
          if (i > 3 && i < 9) {
            resultNumber += '*';
            continue;
          }
          resultNumber += digits[i];
        }
  
        return resultNumber = '+' + resultNumber;
      }

    } catch (err) {
      return '';
    }

    return '';
  }

  return (
    <AppModal
      classNames={{ modal: "active-session-form-modal" }}
      center
      overlayId="modal-overlay"
      closeIconId="close-modal-icon"
      blockScroll={false}
      closeIconSize={25}
      open={isOpen}
      closeOnEsc={true}
      closeOnOverlayClick={false}
      onClose={handleOnClose}
      CloseBtnComponent={EventsTrackClickModalCloseBtn}
    >
      <div className="active-session-form">
        <div className="active-session-form__title">Добро пожаловать!</div>

        <div className="active-session-form__user">
          <div className="active-session-form__avatar">
            <img src={avatar} />
          </div>
          <div className="active-session-form__info">
            <div className="active-session-form__name">{firstName + " " + lastName}</div>
            <div className="active-session-form__phone">{phone}</div>
          </div>

          <img src={mosUser} className="active-session-form__logo" />
        </div>

        <div className="active-session-form__buttons">
          <Button type="filled" text="Продолжить" onClick={useThisAccount} />
          <Button
            type="outlined"
            text="Войти под другой учетной записью"
            onClick={useOtherAccount}
          />
        </div>
      </div>
    </AppModal>
  );
};
