import React, { FC } from "react";
import Button from "./Button";
import { useMediaQuery } from "react-responsive";
import { mediumTabletWidth } from "../../../../../utils/constants/widthConstants";

interface IconButton {
  text: string;
  icon: string;
  iconClassName?: string;
  onClick: (e) => void;
}

const IconButton: FC<IconButton> = ({ text, icon, onClick, iconClassName }) => {
  const isTablet = useMediaQuery({ query: `(max-width: ${mediumTabletWidth}px)` });
  return (
    <Button className={"new-year-2023-iconButton"} onClick={onClick}>
      {isTablet && <div className={"new-year-2023-iconButton__arrow"} />}
      <div className={"new-year-2023-iconButton__content"}>
        {!isTablet &&  <div className={`new-year-2023-iconButton__icon ${iconClassName || ""}`}>
          <img src={icon} alt={text} />
        </div>}
        {text}
      </div>
      {!isTablet && <div className={"new-year-2023-iconButton__arrow"} />}
    </Button>
  );
};

export default IconButton;
