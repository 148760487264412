export const GET_SCREEN_WIDTH = "GET_SCREEN_WIDTH";
export const GET_SCROLL = "GET_SCROLL";
export const SET_IS_SCROLLING = "SET_IS_SCROLLING";

export function getScreenWidth() {
  return function (dispatch) {
    const getWidth = () =>
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;

    dispatch({
      type: GET_SCREEN_WIDTH,
      payload: getWidth(),
    });
  };
}

export const getScroll = () => (dispatch) => {
  return dispatch({
    type: GET_SCROLL,
    payload: window.scrollY,
  });
};

export const setIsScrolling = (isScrolling) => (dispatch) => {
  return dispatch({
    type: SET_IS_SCROLLING,
    payload: isScrolling,
  });
};
