import { FC, useEffect, useState } from "react";
import SwiperCore from "swiper";
import { SwiperSlide } from "swiper/react";

import CommentCard, { CommentProps } from "../Comment/Comment";
import "./TopComments.scss";
import CommonSlider from "../../../../../presentational/Sliders/CommonSlider/CommonSlider";
import { Comment, Discussion, DiscussionStatus } from "../Discussion.interface";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../types/State.interface";

export interface TopCommentsProps {
  comments: Comment[];
  discussion: Discussion;
  isLikeVisible: CommentProps["isLikeVisible"];
  onVote: (comment: Comment) => Promise<void>;
  isLikeDisabled?: boolean;
}

const TopComments = ({ comments, discussion, onVote, isLikeVisible, isLikeDisabled }: TopCommentsProps) => {
  const env = useSelector((state: RootState) => state.environment);

  const [swiperInstance, setSwiperInstance] = useState<SwiperCore>(null);

  const handleOnInit = () => {};

  const handleOnSlideChange = () => {};

  const getDiscussionLink = (discussion) => `${env.platformUrl}/discussion/${discussion.id}`;

  useEffect(() => {
    if (swiperInstance) {
      const handler = () => {
        const visibleSlides = swiperInstance.$el.find(".visible-slide");
        const height = Math.max(...visibleSlides.map((item) => item.clientHeight));
        swiperInstance.$el.css("height", height ? `${height}px` : "auto");
      };
      swiperInstance.on("slideChangeTransitionEnd", handler);
      handler();
      return () => swiperInstance.off("slideChangeTransitionEnd", handler);
    }
  }, [swiperInstance]);

  return (
    <div className="top-comments">
      <CommonSlider
        onInit={handleOnInit}
        onSwiper={setSwiperInstance}
        onSlideChange={handleOnSlideChange}
        className="discussion-landing-discussion__swiper"
        effect="fade"
        options={{
          loop: false,
          spaceBetween: 24,
          slidesPerView: 1,
          slidesPerColumn: 1,
          slidesPerGroup: 1,
          touchStartPreventDefault: false,
        }}
      >
        {comments?.map((comment) => (
          <CommentCard
            key={comment.id}
            label="Топ мнений участников"
            copyLinkUrl={`${getDiscussionLink(discussion)}/${comment.id}?navigate=true`}
            answerLinkUrl={`${getDiscussionLink(discussion)}/${comment.id}?navigate=true&openForm=true`}
            comment={comment}
            isLiked={comment.voted}
            isLikeVisible={isLikeVisible}
            isAnswerButtonVisible={discussion.status === DiscussionStatus.STARTED}
            onVote={onVote}
            isLikeDisabled={isLikeDisabled}
          />
        ))}
      </CommonSlider>
    </div>
  );
};

export default TopComments;
