import React, { FC, useEffect } from "react";
import { ToastProvider, useToasts } from "react-toast-notifications";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "../../../types/State.interface";
import { ToastState } from "../../../store/reducers/toast";

import "./Toast.scss";

const Toast: FC = ({ children }) => {
  const { addToast } = useToasts();

  const toaster = useSelector((state: RootState) => state.toast);

  useEffect(() => {
    const showToast = (toastConf: ToastState) => {
      addToast(toastConf.text, {
        appearance: toastConf.appearance,
        autoDismiss: toastConf.autoDismiss,
      });
    };

    toaster.text && showToast(toaster);
  }, [toaster]);

  return <ToastProvider>{children}</ToastProvider>;
};

const ToastContainer: FC = ({ children }) => {
  return (
    <ToastProvider>
      <Toast />
      {children}
    </ToastProvider>
  );
};

export default ToastContainer;
