export enum ProjectStage {
  PROPOSE = "Propose",
  ESTIMATE = "Estemate",
  DISCUSS = "Discuss",
  SOON_START = "SoonStart",
  FINISHED = "Finished",
}

export const ProjectStageToTextMap = {
  [ProjectStage.PROPOSE]: "Предлагай",
  [ProjectStage.ESTIMATE]: "Оценивай",
  [ProjectStage.DISCUSS]: "Обсуждай",
  [ProjectStage.SOON_START]: "Скоро начнется",
  [ProjectStage.FINISHED]: "Завершен",
};

export const ProjectStageToClassModicator = {
  [ProjectStage.PROPOSE]: "propose",
  [ProjectStage.ESTIMATE]: "estimate",
  [ProjectStage.DISCUSS]: "discuss",
  [ProjectStage.SOON_START]: "soon-start",
  [ProjectStage.FINISHED]: "finished",
};
