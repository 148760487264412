import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";

import SupportForm from "../Forms/SupportForm/SupportForm";
import SocialNetworks from "./SocialNetworks/SocialNetworks";
import "./Footer.scss";
import { useSelector } from "react-redux";
import { RootState } from "../../../types/State.interface";
import EventsTrackParentIdContext from "../EventsTrack/EventsTrackParentIdContext";
import EventsTrackWrapperScroll from "../EventsTrack/wrappers/EventsTrackWrapperScroll";
import EventsTrackWrapperClick from "../EventsTrack/wrappers/EventsTrackWrapperClick";

const Footer = (props) => {
  const footerTextColor = useSelector(
    (state: RootState) => state.layout.footerTextColor
  );
  const footerExtra = useSelector(
    (state: RootState) => state.layout.footerExtraComponent
  );

  const history = useHistory();
  const [modalOpened, setModalOpened] = useState(false);
  return (
    <EventsTrackParentIdContext.Provider value="FOOTER">
      {
        <EventsTrackWrapperScroll id={8}>
          <footer className={`footer footer-${footerTextColor}`}>
            <div className="footer__links">
              <div className="footer__info">

                <EventsTrackWrapperClick id={246}>
                  <span
                    className="footer__link footer__link-help"
                    onClick={(e) => setModalOpened(true)}
                  >
                    Помощь

                    <span className="link-divider"></span>
                  </span>
                </EventsTrackWrapperClick>
                <EventsTrackWrapperClick id={247}>
                  <Link className="footer__link footer__link-user-agreement" to="/license" target="_blank">
                    Пользовательское соглашение
                    <span className="link-divider"></span>
                  </Link>
                </EventsTrackWrapperClick>
                <EventsTrackWrapperClick id={248}>
                  <a
                    className="footer__link footer__link-use-agreement"
                    href="https://www.mos.ru/legal/rules/"
                    target="_blank"
                  >
                    Соглашение о пользовании информационными системами и ресурсами
                    города Москвы
                    <span className="link-divider"></span>
                  </a>
                </EventsTrackWrapperClick>

                <a
                    className="footer__link footer__link-data-agreement"
                    href="https://www.mos.ru/dit/documents/informatcionnaia-bezopasnost/view/274898220/"
                    target="_blank"
                  >
                    Политика в отношении обработки персональных данных
                  </a>


                <div className="footer__copyright_mobile">
                  {`© Краудсорсинг-проекты Правительства Москвы, ${new Date().getFullYear()}`}
                </div>
              </div>

              <div className="footer__social">{footerExtra}</div>
            </div>

            <div className="footer__copyright">
              {`© Краудсорсинг-проекты Правительства Москвы, ${new Date().getFullYear()}`}
            </div>

            {modalOpened && (
              <SupportForm
                isOpened={modalOpened}
                close={() => setModalOpened(false)}
              />
            )}
          </footer>
        </EventsTrackWrapperScroll>
      }
    </EventsTrackParentIdContext.Provider>
  );
};

export default Footer;
