import React, { FC } from "react";
import { ModalProps } from "../Modal/Modal.types";
import Modal from "../Modal/Modal";
import "./ModalGame.scss";

const ModalGame: FC<ModalProps> = ({ isOpened, onClose, content }) => (
  <Modal
    className={"ny-modal-game"}
    isOpened={isOpened}
    onClose={onClose}
    content={content}
    contentClassName={"ny-modal-game__content"}
  />
);

export default ModalGame;
