import { useContext, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CircleButtonData } from "./LeisureHeader.data";
import Button from "../../../../presentational/Controls/Button/Button";
import { RootState } from "../../../../../types/State.interface";
import { logout } from "../../../../../store/actions/UserActions";
import "./LeisureHeader.scss";
import { ActivityType } from "../../services/city-api";
import { AppContext } from "../../../../Root";
import { AuthAction, AuthActionParam, AuthActionType, ComponentType } from "../../../../../services/sudirService";
import {
  selectLeisureActiveIndex,
  selectLeisureLandingForward,
  selectLeisureLandingProgress,
} from "../../../../../store/reducers/leisureLanding";
import CircleButtonList from "./CircleButtonList/CircleButtonList";
import bulb from "../../../../../assets/leisure-landing/header/bulb.svg";
import bulbActive from "../../../../../assets/leisure-landing/header/bulb-active.svg";

export interface WiningListItem {
  type: ActivityType;
  won: boolean;
}

interface LeisureHeaderProps {
  informersActivity: Record<string, boolean>;
  onLogoClick: () => void;
  onCircleClick: (index) => void;
}

export type MappedWinningData = CircleButtonData & WiningListItem;

const LeisureHeader = ({ informersActivity, onLogoClick, onCircleClick }: LeisureHeaderProps) => {
  const appContext = useContext(AppContext);
  const dispatch = useDispatch();
  const progress = useSelector(selectLeisureLandingProgress);
  const forward = useSelector(selectLeisureLandingForward);
  const activeIndex = useSelector(selectLeisureActiveIndex);
  const user = useSelector((state: RootState) => state.user.userDetails);

  const [countActive, countTotal] = useMemo(() => {
    const keys = Object.keys(informersActivity);
    return [keys.filter((k) => informersActivity[k]).length, keys.length];
  }, [informersActivity]);

  const handleLogIn = () => {
    const thisContainerData: AuthActionParam = {
      component: ComponentType.LEISURE_LANDING_PAGE,
      type: AuthActionType.LOGIN,
      args: {
        lastProgress: progress,
        lastForward: forward,
        lastActiveIndex: activeIndex,
      },
    };

    const action1 = new AuthAction(thisContainerData);
    return appContext.sudirService.authWithActions([action1]);
  };

  return (
    <header className="leisure-header">
      <div className="leisure-header__inner">
        <img src={require("../../../../../assets/leisure-landing/logo.svg").default} onClick={onLogoClick} alt="" />

        {/* <CircleButtonList
          onClick={() => {}}
          showTooltip
          items={[
            {
              id: 0,
              icon: bulb,
              "icon-active": bulbActive,
              title: "Найдите все лампочки на странице",
              won: countActive > 0,
            } as any,
          ]}
        />
        <div className="leisure-header__counter">
          {countActive} / {countTotal}
        </div> */}

        {user?.loggedIn ? (
          <Button type="outlined" text="Выйти" onClick={() => dispatch(logout())} />
        ) : (
          <Button type="outlined" text="Войти" onClick={handleLogIn} />
        )}
      </div>
    </header>
  );
};

export default LeisureHeader;
