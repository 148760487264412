import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  hideInfoModal,
  onDismissModal,
} from "../../../../store/actions/LayoutActions";

import { AppModal } from "../../Modal/AppModal";

import "./InfoModal.scss";

interface Props {
  isOpened: boolean;
  onClose: () => {};
  text: string;
}

export function InfoModal() {
  const dispatch = useDispatch();
  const isOpen = useSelector((state: any) => state.layout.showInfoModal);
  const text = useSelector((state: any) => state.layout.infoModalMessage);
  const children = useSelector((state: any) => state.layout.infoModalJSX);
  const onDismiss = useSelector(
    (state: any) => state.layout.infoModalOnDismiss
  );

  function handleClose(e) {
    if (onDismiss) return onDismiss();
    dispatch(hideInfoModal());
  }

  return (
    <AppModal
      center
      classNames={{ modal: "info-modal", overlay: "info-modal-overlay" }}
      open={isOpen}
      blockScroll={true}
      closeOnEsc={true}
      closeOnOverlayClick={false}
      onClose={handleClose}
    >
      <div
        className="info-modal__text"
        dangerouslySetInnerHTML={{ __html: text }}
      ></div>
      <div className="info-modal__buttons">{children}</div>
    </AppModal>
  );
}

export default InfoModal;
