import { ComponentProps } from "react";
import Garland from "../Components/Garland/Garland";
import { GamesTypes, SlideObjectTypes, SlideTypes } from "./constants";
import { keys } from "../../../../utils/object";

type InteractiveMap = { top: number; left: number; width: number; height: number };

export type SlideObject = { pos?: [x: number, y: number] } & (
  | {
      type: SlideObjectTypes.FACT;
      id: string;
      text: string;
      src: string;
      srcBorder: string;
      interactiveMap?: InteractiveMap[];
    }
  | { type: SlideObjectTypes.GAME; id: GamesTypes; src: string; srcBorder: string; interactiveMap?: InteractiveMap[] }
  | { type: SlideObjectTypes.BG; src: string }
  | { type: SlideObjectTypes.GARLAND; variantNum: ComponentProps<typeof Garland>["variantNum"] }
  | { type: SlideObjectTypes.BULBS }
);

const slidesObjects: Record<SlideTypes, SlideObject[]> = {
  [SlideTypes.WELCOME]: [
    {
      type: SlideObjectTypes.BG,
      src: require("../../../../assets/newyear2023/slides/welcome/z0.svg").default,
    },
    {
      type: SlideObjectTypes.GARLAND,
      variantNum: 1,
      pos: [3, -632],
    },
    {
      type: SlideObjectTypes.BG,
      src: require("../../../../assets/newyear2023/slides/welcome/tree.svg").default,
      pos: [-545, 0],
    },
    {
      type: SlideObjectTypes.BULBS,
      pos: [-545, 0],
    },
    {
      type: SlideObjectTypes.BG,
      src: require("../../../../assets/newyear2023/slides/welcome/z1.svg").default,
      pos: [0, -30.5],
    },
  ],
  [SlideTypes.CROWD]: [
    {
      type: SlideObjectTypes.BG,
      src: require("../../../../assets/newyear2023/slides/crowd/z0.svg").default,
    },
    {
      type: SlideObjectTypes.FACT,
      id: "picture_gi",
      text: "Всего в Москве более 50 стадионов, которые могут одновременно разместить порядка 330 тыс. человек. И даже этого не хватит, чтобы вместить <strong>около 500 тыс. участников</strong> платформы «Город идей»!",
      src: require("../../../../assets/newyear2023/slides/crowd/city-picture.svg").default,
      srcBorder: require("../../../../assets/newyear2023/slides/crowd/city-picture-border.svg").default,
      pos: [-23, -455],
    },
    {
      type: SlideObjectTypes.FACT,
      id: "globus",
      text: "Для поиска нужного маршрута и интересных локаций жители и гости столицы всегда имеют под рукой карту Москвы. Платформа «Город идей» в 2023 году создала свою — <strong>карту реализованных идей</strong>! Теперь каждый желающий может в режиме онлайн прогуляться по столице и узнать больше о реализации идей.",
      src: require("../../../../assets/newyear2023/slides/crowd/globe.svg").default,
      srcBorder: require("../../../../assets/newyear2023/slides/crowd/globe-border.svg").default,
      pos: [-139, -425],
    },
    {
      type: SlideObjectTypes.FACT,
      id: "painting_gi",
      text: "В этом году в Москве заработали 2 новые линии МЦД: МЦД-3 и МЦД-4. В 2020 году более 17 тысяч жителей делились мнением о том, какими должны быть <a href='https://crowd.mos.ru/workresult?id=mcd' target='_blank'>Московские центральные диаметры</a>. Сегодня <strong>все 173 предложения уже реализованы</strong>: упрощена навигация, установлены табло с расписанием поездов и зарядные станции для мобильных телефонов, а также появились перехватывающие парковки и многое другое.",
      src: require("../../../../assets/newyear2023/slides/crowd/group-pictures.svg").default,
      srcBorder: require("../../../../assets/newyear2023/slides/crowd/group-pictures-border.svg").default,
      pos: [775, -419],
    },
    {
      type: SlideObjectTypes.FACT,
      id: "bookshelf",
      text: "Среди пользователей «Города идей» 35% сов. <strong>Каждый третий пользователь</strong> отправляет свои инициативы в раздел «<a href='https://crowd.mos.ru/propose' target='_blank'>Предложить идею</a>» после захода солнца.",
      src: require("../../../../assets/newyear2023/slides/crowd/bookshelf.svg").default,
      srcBorder: require("../../../../assets/newyear2023/slides/crowd/bookshelf-border.svg").default,
      pos: [271, -159],
      interactiveMap: [
        {
          top: 0,
          left: 0,
          width: 183,
          height: 395,
        },
        {
          top: 395,
          left: 0,
          width: 160,
          height: 23,
        },
        {
          top: 418,
          left: 30,
          width: 132,
          height: 15,
        },
        {
          top: 433,
          left: 45,
          width: 118,
          height: 36,
        },
        {
          top: 469,
          left: 34,
          width: 129,
          height: 13,
        },
        {
          top: 517,
          left: 151,
          width: 11,
          height: 11,
        },
      ],
    },
    {
      type: SlideObjectTypes.FACT,
      id: "laptop_gi",
      text: "Если бы каждую предложенную за время существования платформы идею нужно было бы отправлять по почте, участникам пришлось бы купить <strong>более 185 тыс. конвертов</strong>.",
      src: require("../../../../assets/newyear2023/slides/crowd/laptop.svg").default,
      srcBorder: require("../../../../assets/newyear2023/slides/crowd/laptop-border.svg").default,
      pos: [-426, -321],
    },
    {
      type: SlideObjectTypes.GARLAND,
      variantNum: 2,
      pos: [0, -652],
    },
    {
      type: SlideObjectTypes.BG,
      src: require("../../../../assets/newyear2023/slides/crowd/z1.svg").default,
      pos: [27.5, -113.45],
    },
    {
      type: SlideObjectTypes.BG,
      src: require("../../../../assets/newyear2023/slides/crowd/clock.svg").default,
      pos: [-752, -514],
    },
  ],
  [SlideTypes.AG]: [
    {
      type: SlideObjectTypes.BG,
      src: require("../../../../assets/newyear2023/slides/ag/z0.svg").default,
    },
    {
      type: SlideObjectTypes.GARLAND,
      variantNum: 2,
      pos: [0, -652],
    },
    {
      type: SlideObjectTypes.FACT,
      id: "tree",
      text: "За годы своей работы «Активный гражданин» собрал <strong>более 240 млн мнений горожан</strong>. Это в 16 раз больше, чем изданий в фондах всех московских библиотек!	",
      src: require("../../../../assets/newyear2023/slides/ag/christmas-tree.svg").default,
      srcBorder: require("../../../../assets/newyear2023/slides/ag/christmas-tree-border.svg").default,
      pos: [-728, -130],
      interactiveMap: [
        {
          top: 570,
          left: 0,
          width: 455,
          height: 124,
        },
        {
          top: 470,
          left: 25,
          width: 395,
          height: 100,
        },
        {
          top: 390,
          left: 50,
          width: 325,
          height: 80,
        },
        {
          top: 290,
          left: 95,
          width: 250,
          height: 100,
        },
        {
          top: 210,
          left: 110,
          width: 205,
          height: 80,
        },
        {
          top: 20,
          left: 155,
          width: 125,
          height: 190,
        },
      ],
    },
    {
      type: SlideObjectTypes.FACT,
      id: "cup_ag",
      text: "Как активные граждане создают историю Москвы? Выбирают названия для улиц, станций метро, бульваров. <strong>Больше 100 городских объектов</strong>, в том числе — 27 станций метро, 2 вокзала МЦД и улицы целого кинематографического квартала получили имена благодаря участникам проекта!",
      src: require("../../../../assets/newyear2023/slides/ag/trophy.svg").default,
      srcBorder: require("../../../../assets/newyear2023/slides/ag/trophy-border.svg").default,
      pos: [-499, -620],
    },
    {
      type: SlideObjectTypes.FACT,
      id: "ball",
      text: "<strong>Более 7,5 млн лайков</strong> поставили активные граждане Москвы в рубрике «Пульс столицы». Если каждый лайк превратить в новогодний шар, то ими можно нарядить 2000 двадцатиметровых елей фестиваля «Путешествие в Рождество».",
      src: require("../../../../assets/newyear2023/slides/ag/snowball.svg").default,
      srcBorder: require("../../../../assets/newyear2023/slides/ag/snowball-border.svg").default,
      pos: [-410, -526],
    },
    {
      type: SlideObjectTypes.FACT,
      id: "clock",
      text: "В 2023 году мы запустили <strong>квесты в парках Москвы</strong>. В сумме все участники прошли <strong>более 86 тыс. километров</strong>. Это как 500 раз проехаться туда и обратно на МЦД-4 от Апрелевки до Балашихи.",
      src: require("../../../../assets/newyear2023/slides/ag/helm-clock.svg").default,
      srcBorder: require("../../../../assets/newyear2023/slides/ag/helm-clock-border.svg").default,
      pos: [446, -570],
    },
    {
      type: SlideObjectTypes.FACT,
      id: "skates",
      text: "В «Активном гражданине» <strong>более 6,7 млн участников</strong>! Если каждый из них захочет покататься на катке на ВДНХ, лед будет занят не менее 14 зим! ",
      src: require("../../../../assets/newyear2023/slides/ag/skates.svg").default,
      srcBorder: require("../../../../assets/newyear2023/slides/ag/skates-border.svg").default,
      pos: [674, -474],
    },
    {
      type: SlideObjectTypes.BG,
      src: require("../../../../assets/newyear2023/slides/ag/z1.svg").default,
      pos: [188, -124],
    },
    {
      type: SlideObjectTypes.BG,
      src: require("../../../../assets/newyear2023/slides/ag/toy.svg").default,
      pos: [-76, -152],
    },
    {
      type: SlideObjectTypes.BG,
      src: require("../../../../assets/newyear2023/slides/ag/z2.svg").default,
      pos: [-729, -113],
    },
  ],
  [SlideTypes.ED]: [
    {
      type: SlideObjectTypes.BG,
      src: require("../../../../assets/newyear2023/slides/ed/z0.svg").default,
    },
    {
      type: SlideObjectTypes.FACT,
      id: "calendar",
      text: "В 2023 году к «Электронному дому» присоединилось <strong>более 700 тыс. жителей</strong> многоквартирных домов Москвы. Если бы «Электронный дом» существовал в 1882 году, то это составило бы 100% населения Москвы! ",
      src: require("../../../../assets/newyear2023/slides/ed/calendar.svg").default,
      srcBorder: require("../../../../assets/newyear2023/slides/ed/calendar-border.svg").default,
      pos: [-65, -520],
    },
    {
      type: SlideObjectTypes.BG,
      src: require("../../../../assets/newyear2023/slides/ed/christmas-wreath.svg").default,
      pos: [117, -517],
    },
    {
      type: SlideObjectTypes.FACT,
      id: "machine",
      text: "На платформе «Электронный дом» вызвать мастера можно <strong>всего за несколько кликов</strong>. В среднем каждый день мастера отправляются на 400 различных адресов по вызову пользователей и зову сердца.",
      src: require("../../../../assets/newyear2023/slides/ed/washing-machine.svg").default,
      srcBorder: require("../../../../assets/newyear2023/slides/ed/washing-machine-border.svg").default,
      pos: [366, -182],
    },
    {
      type: SlideObjectTypes.GARLAND,
      variantNum: 2,
      pos: [0, -652],
    },
    {
      type: SlideObjectTypes.BG,
      src: require("../../../../assets/newyear2023/slides/ed/z1.svg").default,
      pos: [-56, -113],
    },
    {
      type: SlideObjectTypes.FACT,
      id: "neighbours",
      text: "Александр Сергеевич Пушкин за свою жизнь написал около 800 писем, которые сохранились, из них около 80 — своей жене Наталье Гончаровой. Представьте себе, а в «Электронном доме» соседи ведут переписку уже в <strong>43 тыс. личных и общедомовых чатов</strong>!",
      src: require("../../../../assets/newyear2023/slides/ed/girl.svg").default,
      srcBorder: require("../../../../assets/newyear2023/slides/ed/girl-border.svg").default,
      pos: [714, -127],
      interactiveMap: [
        {
          top: 0,
          left: 87,
          width: 94,
          height: 409,
        },
        {
          top: 103,
          left: 0,
          width: 87,
          height: 44,
        },
        {
          top: 147,
          left: 65,
          width: 22,
          height: 262,
        },
      ],
    },
    {
      type: SlideObjectTypes.FACT,
      id: "handset",
      text: "Городской wi-fi, умный транспорт, видеокамеры с распознаванием лиц, умное освещение — все это и не только день за днем делает Москву одним из самых умных городов. А благодаря «Электронному дому» и самые обычные <strong>дома могут стать умными</strong>! Сегодня на платформе у каждого дома есть возможность подключить видеодомофоны, умные счетчики и установить постамат в подъезде.",
      src: require("../../../../assets/newyear2023/slides/ed/intercom.svg").default,
      srcBorder: require("../../../../assets/newyear2023/slides/ed/intercom-border.svg").default,
      pos: [640, -478],
    },
    {
      type: SlideObjectTypes.FACT,
      id: "laptop_ed",
      text: "Знаете ли вы, что в году 8760 часов? А в «Электронном доме» в 2023 году прошло <strong>более 9 тыс. общих собраний собственников</strong>. Получается, что <a href='https://ed.mos.ru/about-oss/' target='_blank'>собственники</a> на платформе за год приняли больше решений, чем часов в году!",
      src: require("../../../../assets/newyear2023/slides/ed/laptop.svg").default,
      srcBorder: require("../../../../assets/newyear2023/slides/ed/laptop-border.svg").default,
      pos: [-124, -256],
    },
  ],
  [SlideTypes.GZ]: [
    {
      type: SlideObjectTypes.BG,
      src: require("../../../../assets/newyear2023/slides/gz/z0.svg").default,
    },
    {
      type: SlideObjectTypes.FACT,
      id: "smartphone_gz",
      text: "Весной 2023 года проект «Город заданий» выпустил мобильное приложение. Выполнять задания стало удобнее и проще! Каждый участник проекта в среднем использовал приложение <strong>1 час 29 минут</strong> — именно столько времени требуется, чтобы полностью проехать круг по МЦК.",
      src: require("../../../../assets/newyear2023/slides/gz/speakers.svg").default,
      srcBorder: require("../../../../assets/newyear2023/slides/gz/speakers-border.svg").default,
      pos: [129, -587],
    },
    {
      type: SlideObjectTypes.FACT,
      id: "map",
      text: "Благодаря заданию по сдаче отработанных батареек участники проекта «Город заданий» спасли от загрязнения <strong>140 тыс. кв. м земли</strong>. Только представьте — это территория Екатерининского парка!",
      src: require("../../../../assets/newyear2023/slides/gz/picture.svg").default,
      srcBorder: require("../../../../assets/newyear2023/slides/gz/picture-border.svg").default,
      pos: [427, -406],
    },
    {
      type: SlideObjectTypes.FACT,
      id: "cup_gz",
      text: "В 2023 году на проекте «Город заданий» прошел «<a href='https://gz.mos.ru/news/119' target='_blank'>Марафон заданий</a>». С 1 августа по 10 сентября <strong>около 15 тыс. участников</strong> выполняли серию заданий и получали награды. Ежегодно для проведения Московского марафона дороги в Москве перекрывают на 6 часов. Если для «Марафона заданий», в котором москвичи выполнили 70 тыс. заданий, нужно было бы перекрыть дороги, они были бы перекрыты 35 часов.",
      src: require("../../../../assets/newyear2023/slides/gz/trophy.svg").default,
      srcBorder: require("../../../../assets/newyear2023/slides/gz/trophy-border.svg").default,
      pos: [-738, -636],
    },
    {
      type: SlideObjectTypes.FACT,
      id: "picture_gz",
      text: "С начала года к «Городу заданий» присоединилось <strong>более 85 тыс. человек</strong>. Новички проекта могли бы полностью заселить район «Крылатское»!",
      src: require("../../../../assets/newyear2023/slides/gz/picture2.svg").default,
      srcBorder: require("../../../../assets/newyear2023/slides/gz/picture2-border.svg").default,
      pos: [-716, -415],
    },
    {
      type: SlideObjectTypes.GARLAND,
      variantNum: 2,
      pos: [0, -652],
    },
    {
      type: SlideObjectTypes.BG,
      src: require("../../../../assets/newyear2023/slides/gz/z1.svg").default,
      pos: [-32.5, -113],
    },
    {
      type: SlideObjectTypes.FACT,
      id: "suit",
      text: "В одном килограмме жира примерно 7716 калорий. За 2023 год пользователи суммарно провели на тренировках <strong>14 тыс. часов</strong> и сожгли <strong>8,4 млн калорий</strong>, или целую тонну жира!",
      src: require("../../../../assets/newyear2023/slides/gz/t-shirt.svg").default,
      srcBorder: require("../../../../assets/newyear2023/slides/gz/t-shirt-border.svg").default,
      pos: [65, -324],
    },
    {
      type: SlideObjectTypes.BG,
      src: require("../../../../assets/newyear2023/slides/gz/gift.svg").default,
      pos: [21, -200],
    },
  ],
  [SlideTypes.NG]: [
    {
      type: SlideObjectTypes.BG,
      src: require("../../../../assets/newyear2023/slides/ng/z0.svg").default,
    },
    {
      type: SlideObjectTypes.FACT,
      id: "flashlight",
      text: "В Единой редакции портала «Наш город» работает всего 45 человек, за 24 часа они успевают обработать <strong>более 10 тыс. сообщений</strong> и ответов органов власти.",
      src: require("../../../../assets/newyear2023/slides/ng/lantern.svg").default,
      srcBorder: require("../../../../assets/newyear2023/slides/ng/lantern-border.svg").default,
      pos: [40, -345],
    },
    {
      type: SlideObjectTypes.FACT,
      id: "picture_ng",
      text: "Наземный городской транспорт в ТиНАО продолжает развиваться и становиться удобнее для горожан. С начала 2023 года там улучшили трассы более 40 маршрутов. Не отстает и портал «Наш город»: на сегодня жителям ТиНАО доступно <strong>более 190 тем</strong> для <a href='https://gorod.mos.ru/themes' target='_blank'>обращений</a>.",
      src: require("../../../../assets/newyear2023/slides/ng/picture.svg").default,
      srcBorder: require("../../../../assets/newyear2023/slides/ng/picture-border.svg").default,
      pos: [-413.5, -499],
    },
    {
      type: SlideObjectTypes.BG,
      src: require("../../../../assets/newyear2023/slides/ng/z1.svg").default,
      pos: [0, -170],
    },
    {
      type: SlideObjectTypes.GARLAND,
      variantNum: 2,
      pos: [0, -652],
    },
    {
      type: SlideObjectTypes.BG,
      src: require("../../../../assets/newyear2023/slides/ng/z2.svg").default,
      pos: [0, -111],
    },
    {
      type: SlideObjectTypes.BG,
      src: require("../../../../assets/newyear2023/slides/ng/lamp.svg").default,
      pos: [467, -286],
    },
    {
      type: SlideObjectTypes.FACT,
      id: "railway",
      text: "В столице в текущем году были открыты третий (МЦД-3) и четвертый (МЦД-4) Московские центральные диаметры. А на портале «Наш город» у пользователей <a href='https://gorod.mos.ru/news/13751' target='_blank'>появилась возможность</a> <strong>следить за состоянием объектов инфраструктуры МЦД</strong>, чтобы поездки были еще комфортнее.",
      src: require("../../../../assets/newyear2023/slides/ng/train.svg").default,
      srcBorder: require("../../../../assets/newyear2023/slides/ng/train-border.svg").default,
      pos: [36, -119],
    },
    {
      type: SlideObjectTypes.FACT,
      id: "dog",
      text: "На портале «Наш город» опубликовано <strong>более 10 млн</strong> сообщений. Если бы каждое сообщение писали от руки на отдельном листе, то понадобилось бы 50 тонн бумаги.",
      src: require("../../../../assets/newyear2023/slides/ng/dog.svg").default,
      srcBorder: require("../../../../assets/newyear2023/slides/ng/dog-border.svg").default,
      pos: [-728.5, -111],
    },
    {
      type: SlideObjectTypes.FACT,
      id: "smartphone_ng",
      text: "Портал «Наш город» работает уже <strong>12 лет</strong>, чтобы вы не опаздывали на свидания из-за сломавшегося автобуса, возвращались с работы по освещенным улицам, проводили время с семьей на безопасных площадках и выгуливали своих четвероногих друзей в чистых парках.",
      src: require("../../../../assets/newyear2023/slides/ng/phone.svg").default,
      srcBorder: require("../../../../assets/newyear2023/slides/ng/phone-border.svg").default,
      pos: [607.3, -288],
    },
    {
      type: SlideObjectTypes.BG,
      src: require("../../../../assets/newyear2023/slides/ng/z3.svg").default,
      pos: [646, -271.06],
    },
  ],
  [SlideTypes.MP]: [
    {
      type: SlideObjectTypes.BG,
      src: require("../../../../assets/newyear2023/slides/mp/z0.svg").default,
    },
    {
      type: SlideObjectTypes.FACT,
      id: "painting_mp",
      text: "<strong>2,3 млн москвичей</strong> выиграли сертификаты номиналом 1000, 3000 и 5000 призовых баллов, участвуя <a href='https://ag-vmeste.ru/events/28' target='_blank'>в акции #ВыбираемВместе2023</a>. Чтобы собрать всех этих счастливчиков вместе, понадобилось бы 20 площадок вместимостью, равной Красной площади.",
      src: require("../../../../assets/newyear2023/slides/mp/tv.svg").default,
      srcBorder: require("../../../../assets/newyear2023/slides/mp/tv-border.svg").default,
      pos: [-42, -439],
    },
    {
      type: SlideObjectTypes.FACT,
      id: "flower",
      text: "Обменять накопленные баллы на <a href='https://ag-vmeste.ru/catalog' target='_blank'>поощрения</a> можно более чем у 300 партнеров сайта «Миллион призов». Это магазины одежды и обуви, розничные сети товаров для детей, товаров для дома и офиса, супермаркеты, благотворительные фонды, театры, музеи и прочее. А самое популярное среди пользователей поощрение — пополнение карты «Тройка». Благодаря «Миллиону призов» в 2023 году москвичи совершили <strong>более 4,7 млн бесплатных поездок в метро</strong>.",
      src: require("../../../../assets/newyear2023/slides/mp/plant.svg").default,
      srcBorder: require("../../../../assets/newyear2023/slides/mp/plant-border.svg").default,
      pos: [-680, -410],
    },
    {
      type: SlideObjectTypes.FACT,
      id: "star",
      text: "Нас становится больше: в 2023 году новыми пользователями сайта «Миллион призов» стали <strong>свыше 600 тыс. человек</strong>! Чтобы все эти москвичи смогли посмотреть новогоднюю Елку Мэра, пришлось бы проводить это мероприятие более 100 раз подряд!",
      src: require("../../../../assets/newyear2023/slides/mp/star.svg").default,
      srcBorder: require("../../../../assets/newyear2023/slides/mp/star-border.svg").default,
      pos: [293, -651],
    },
    {
      type: SlideObjectTypes.FACT,
      id: "gift",
      text: "Общее число пользователей сайта «Миллион призов» превысило <strong>4 млн человек</strong>! Это означает, что каждый третий москвич уже присоединился к <a href='https://ag-vmeste.ru/' target='_blank'>ag-vmeste.ru</a>.",
      src: require("../../../../assets/newyear2023/slides/mp/gifts.svg").default,
      srcBorder: require("../../../../assets/newyear2023/slides/mp/gifts-border.svg").default,
      pos: [286, -154],
    },
    {
      type: SlideObjectTypes.FACT,
      id: "tablecloth",
      text: "В 2023 году каждый тридцатый пользователь «Миллиона призов» пожертвовал свои баллы на благотворительность. Всего за время работы сайта пользователи направили в благотворительные организации более <strong>300 млн рублей</strong>!",
      src: require("../../../../assets/newyear2023/slides/mp/tablecloth.svg").default,
      srcBorder: require("../../../../assets/newyear2023/slides/mp/tablecloth-border.svg").default,
      pos: [-618, -166],
    },
    {
      type: SlideObjectTypes.BG,
      src: require("../../../../assets/newyear2023/slides/mp/snowball.svg").default,
      pos: [-173, -284],
    },
    {
      type: SlideObjectTypes.GARLAND,
      variantNum: 2,
      pos: [0, -652],
    },
    {
      type: SlideObjectTypes.BG,
      src: require("../../../../assets/newyear2023/slides/mp/z1.svg").default,
      pos: [10, -109],
    },
  ],
};

export const slideKeys = keys(slidesObjects);

export default slidesObjects;
