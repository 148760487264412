import { AppColorsEnum, FooterTextColorsEnum } from "../../components/App.interface";
import SocialNetworks from "../../components/containers/Footer/SocialNetworks/SocialNetworks";
import { FooterExtra } from "../../components/pages/Birthday/Birthday";
import { footerTextColorsMap } from "../../utils/appColors";
import {
  SHOW_INFOMODAL,
  HIDE_INFOMODAL,
  SHOW_MAINTENANCE_MODAL,
  HIDE_MAINTENANCE_MODAL,
  SHOW_MOSRU_AUTH_FORM,
  TOGGLE_DOCUMENT_BLOCKED,
  ON_DISMISS_MODAL,
  SET_HEADER_OPTIONS,
  WIDEN_PAGE,
  LESSEN_PAGE,
  SET_APP_COLOR,
  SET_FOOTER_EXTRA,
  EVENTS_TRACK_SCROLL_UPDATE,
  DISABLE_BODY_SCROLL,
  ENABLE_BODY_SCROLL,
  SET_WEB_VIEW_MODE,
  FULLSCREEN_PAGE,
  SHOW_QRCODE_MODAL,
  HIDE_QRCODE_MODAL,
} from "../actions/LayoutActions";

export enum PageExpandEnum {
  LESSEN = "LESSEN",
  WIDEN = "WIDEN",
  FULLSCREEN = "FULLSCREEN",
}

export interface LayoutState {
  showQrCodeModal: boolean;
  showInfoModal: boolean;
  infoModalMessage: string;
  infoModalJSX: string;
  infoModalOnDismiss: () => void | null;
  showMaintenanceModal: boolean;
  isShowMosRuAuthForm: boolean;
  returnUrl: string;
  documentIsBlocked: boolean;
  routes: any;
  logo: { link: string };
  appColor: AppColorsEnum;
  footerTextColor: FooterTextColorsEnum;
  footerExtraComponent: any;
  eventsTrackScrollCounterTrigger: number;
  isBodyScrollDisabled: boolean;
  pageExpand: PageExpandEnum;
  inWebViewMode: boolean;
}

const initialState = {
  showQrCodeModal: false,
  showInfoModal: false,
  infoModalMessage: "",
  infoModalJSX: "",
  infoModalOnDismiss: null,
  showMaintenanceModal: false,
  isShowMosRuAuthForm: false,
  returnUrl: "",
  documentIsBlocked: false,
  routes: null,
  logo: null,
  footerExtraComponent: <SocialNetworks />,
  eventsTrackScrollCounterTrigger: 0, // триггер для переинициализации IntersectionObserver в EventsTrackContainer
  appColor: AppColorsEnum.WHITE,
  footerTextColor: footerTextColorsMap[AppColorsEnum.WHITE] as any,
  isBodyScrollDisabled: false,
  pageExpand: PageExpandEnum.LESSEN,
  inWebViewMode: false,
};

export function layoutReducer(state: LayoutState = initialState, action) {
  switch (action.type) {
    case SHOW_QRCODE_MODAL:
      return {
        ...state,
        showQrCodeModal: true,
      };
    case HIDE_QRCODE_MODAL:
      return {
        ...state,
        showQrCodeModal: false,
      };
    case SHOW_MOSRU_AUTH_FORM:
      return {
        ...state,
        isShowMosRuAuthForm: true,
        returnUrl: action.payload,
      };

    case "HIDE_MOSRU_AUTH_FORM":
      return {
        ...state,
        isShowMosRuAuthForm: false,
        returnUrl: "",
      };

    case SHOW_INFOMODAL:
      return {
        ...state,
        showInfoModal: true,
        infoModalMessage: action.payload.message,
        infoModalJSX: action.payload.jsx || state.infoModalJSX,
        infoModalOnDismiss: action.payload.onDismiss,
      };

    case HIDE_INFOMODAL:
      return {
        ...state,
        showInfoModal: false,
        infoModalMessage: "",
        infoModalOnDismiss: null,
      };

    case SHOW_MAINTENANCE_MODAL:
      return {
        ...state,
        showMaintenanceModal: true,
      };

    case HIDE_MAINTENANCE_MODAL:
      return {
        ...state,
        showMaintenanceModal: false,
      };

    case TOGGLE_DOCUMENT_BLOCKED:
      return {
        ...state,
        documentIsBlocked: action.payload,
      };

    case SET_HEADER_OPTIONS:
      return {
        ...state,
        routes: {
          type: action.payload?.type,
          baseRoutes: action.payload?.routes?.baseRoutes,
          mobileRoutes: action.payload?.routes?.mobileRoutes,
        },
        logo: action.payload?.logo,
      };

    case WIDEN_PAGE:
      return {
        ...state,
        pageExpand: PageExpandEnum.WIDEN,
      };

    case LESSEN_PAGE:
      return {
        ...state,
        pageExpand: PageExpandEnum.LESSEN,
      };

    case FULLSCREEN_PAGE:
      return {
        ...state,
        pageExpand: PageExpandEnum.FULLSCREEN,
      };

    case SET_WEB_VIEW_MODE:
      return {
        ...state,
        inWebViewMode: action.payload,
      };

    case SET_APP_COLOR:
      return {
        ...state,
        appColor: action.payload.appColor,
        footerTextColor: action.payload.footerTextColor,
      };

    case DISABLE_BODY_SCROLL:
      return {
        ...state,
        isBodyScrollDisabled: true,
      };

    case ENABLE_BODY_SCROLL:
      return {
        ...state,
        isBodyScrollDisabled: false,
      };

    case SET_FOOTER_EXTRA:
      return {
        ...state,
        footerExtraComponent: action.payload.footerExtraComponent,
      };

    case EVENTS_TRACK_SCROLL_UPDATE:
      return {
        ...state,
        eventsTrackScrollCounterTrigger: state.eventsTrackScrollCounterTrigger + 1,
      };

    default:
      return state;
  }
}
