import React, { useEffect } from "react";
import { useHistory, withRouter } from "react-router-dom";

import MetaDecorator from "../../presentational/MetaDecorator/MetaDecorator";
import PageTitle from "../../presentational/PageTitle/PageTitle";
import PageDescription from "../../presentational/PageDescription/PageDescription";
import ProjectFlipcard from "../../presentational/Cards/ProjectFlipcard/ProjectFlipcard";

import "../GenericProject/GenericProjectListPage.scss";
import "./CurrentPage.scss";
import { Project } from "../../../types/Projects.interface";

interface Props {
  projects: Project[];
}

const CurrentPage = (props: Props) => {
  const history = useHistory();

  useEffect(() => {
    if (!props.projects?.length) history.push("/");
  }, [props.projects]);

  const renderList = () => {
    return (
      <div className="current__list">
        {props.projects.map((project, idx) => (
          <ProjectFlipcard
            type="current"
            isFlippable={false}
            project={project}
            defaultLogo={require("../../../assets/logo/default/vertical.png").default}
            key={idx}
          ></ProjectFlipcard>
        ))}
      </div>
    );
  };

  return (
    <section className="current">
      <MetaDecorator
        title="Платформа Правительства Москвы «Город идей»"
        description="Информация о проектах, в которых можно принять участие в текущий момент."
      />
      <PageTitle text={"Текущие проекты"} />
      <PageDescription
        text={
          "В этом разделе вы можете ознакомиться с описанием и сроками проведения проектов, которые проходят в настоящее время. Также из этого раздела вы можете перейти к подаче заявки на проект или к наблюдению за его ходом."
        }
      />

      {renderList()}
    </section>
  );
};

export default withRouter(CurrentPage);
