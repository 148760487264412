// @flow

import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import ArchiveProjectPage from "../../pages/ArchiveProjectPage/ArchiveProjectPage";
import FadeIn from "../../presentational/FadeIn/FadeIn";
import { findUrlParam } from "../../../utils";
import ProjectService from "../../../services/projectService";
import DiscussionLandingService from "../../pages/DiscussionLanding/services/discussion-service";
import IdeaService from "../../../services/ideaService";
import GenericProjectLoader from "../../presentational/Loaders/GenericProjectLoader/GenericProjectLoader";
import { setAppColor } from "../../../store/actions/LayoutActions";
import { AppColorsEnum } from "../../App.interface";

interface Props {
  location: Location;
  match: any;
  setAppColor: any;
}

class ArchiveProjectContainer extends Component<Props, any> {
  constructor(props) {
    super(props);
    this.state = {
      project: null,
      pageType: "",
      pageTitle: "",
      projectSubTitle: "",
      projectDescription: "",
      pageMetaDescription: "",
      subProjects: [],
      defaultLogo: {
        horizontalSrc: require("../../../assets/logo/default/horizontal.png")
          .default,
        verticalSrc: require("../../../assets/logo/default/vertical.png")
          .default,
      },
      logo: {
        horizontalSrc: "",
        verticalSrc: "",
      },
      plannedRealizedIdeasData: {
        realizedIdeaTitle: "",
        waitingIdeaTitle: "",
        ideas: [],
        sections: [],
      },
      getBackHref: "/archive",
      isLoading: false,
    };
  }

  componentDidMount() {
    this.props.setAppColor(AppColorsEnum.WHITE);
    this.loadProject();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.loadProject();
    }
  }

  loadProject() {
    this.setState({ isLoading: true });
    const projectId = findUrlParam("id", this.props.location.search);

    ProjectService.getProjectInfo(projectId)
      .then((response) => {
        if (!response.data.length) return;

        const project = response.data[0];

        const pageType = this.props.match.url
          ? this.getTypeFromUrl(this.props.match.url, project.isMain)
          : this.state.pageType;

        const isNotProjectMainPage =
          project.parentProjectId || (project.isMain && pageType !== "main");

        IdeaService.getRealizedIdeas(project.id)
          .then((response) => {
            this.setState({
              plannedRealizedIdeasData: response.data,
            });
          })
          .catch((err) => {
            console.log(err);
          });

        const fetchGroupDirections = project.hasLanding
          ? DiscussionLandingService.projectGroupDirectionGetAll(projectId)
          : Promise.resolve({ data: [] });

        fetchGroupDirections
          .then((response) => {
            this.setState({
              groupDirections: response.data.filter(({ hasRootNode }) => !hasRootNode),
            });
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            this.setState({
              project,
              pageType,
              pageTitle: this.getPageTitle(this.state.pageType, project),
              projectSubTitle: this.getProjectSubTitle(pageType),
              projectDescription: this.getProjectDescription(project),
              pageMetaDescription: this.getPageMetaDescription(pageType),
              getBackHref: isNotProjectMainPage
                ? `/archive?id=${project.id}`
                : "/archive",
            });

            this.setProjectLogo(project);
            if (project.isMain) this.getSubProjects(project);
          });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        // main project is loaded only after subprojects are loaded
        if (!this.state.project?.isMain) this.setState({ isLoading: false });
      });
  }



  getProjectSubTitle(pageType) {
    if (pageType === "plan") {
      return "Перечень идей участников, принятых в реализацию по итогам проекта";
    } else if (pageType === "ideas") {
      return "Перечень идей участников проекта, которые на текущий момент реализованы городом";
    } else {
      return "";
    }
  }

  getPageTitle(pageType, project) {
    if (pageType === "plan") {
      return "список&nbsp;идей&nbsp;к&nbsp;реализации";
    } else if (pageType === "ideas") {
      return "реализованные&nbsp;идеи";
    } else {
      return project.nameOnPromo || project.title;
    }
  }

  getProjectDescription(project) {
    if (project.shortDescription) {
      return project.shortDescription;
    } else if (project.structure && project.structure.description) {
      return project.structure.description;
    }

    return project.description;
  }

  getPageMetaDescription(pageType) {
    if (pageType === "plan") {
      return "Список идей, которые будут реализованы по итогам проекта.";
    } else if (pageType === "ideas") {
      return "Перечень идей, полученных на проекте и реализованных в Москве.";
    } else if (pageType === "main" || pageType === "projectIndexForAG") {
      return "Основная информация о проекте и перечень идей, полученных на нем.";
    }
  }

  getSubProjects(project) {
    ProjectService.getArchiveProjects()
      .then((response) => {
        const projects = response.data;

        let subProjects = [];
        if (projects?.length) {
          subProjects = projects.filter(
            (p) => p.parentProjectId === project.id
          );

          // add main project to the end
          subProjects = subProjects.concat(
            projects.filter((p) => p.id === project.id)
          );

          this.setState({ subProjects });
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.setState({ isLoading: false });
        // this.setState({
        //   isDataLoaded: true,
        // });
      });
  }

  getTypeFromUrl(url, isMain) {
    if (url.startsWith("/archive")) {
      return isMain ? "main" : "projectIndexForAG";
    } else if (url.startsWith("/plan")) {
      return "plan";
    } else if (url.startsWith("/ideas")) {
      return "ideas";
    } else {
      return null;
    }
  }

  getProjectLogoUrl(project, defaultLogo, isVertical = false) {
    // TODO: remove hardcode by getting additional logo uploads
    const EL_USLUGI_ID = "el_uslugi";

    if (isVertical) {
      if (project.id === EL_USLUGI_ID) {
        return require("../../../assets/logo/el_uslugi_main_square.png").default;
      }
      return project.logo.square
        ? `/uploads/get/${project.logo.square}`
        : defaultLogo.verticalSrc;
    }

    if (project.id === EL_USLUGI_ID) {
      return require("../../../assets/logo/el_uslugi_main_horizontal.png").default;
    } else {
      return project.logo.horizontal
        ? `/uploads/get/${project.logo.horizontal}`
        : defaultLogo.horizontalSrc;
    }
  }

  setProjectLogo(project) {
    const logo = { ...this.state.logo };
    logo.horizontalSrc = this.getProjectLogoUrl(
      project,
      this.state.defaultLogo
    );
    logo.verticalSrc = this.getProjectLogoUrl(
      project,
      this.state.defaultLogo,
      true
    );
    this.setState({ logo });
  }

  render() {
    console.log(this.state.subProjects)
    return (
      <div className="archive-project-container" style={{ width: "100%" }}>
        {!this.state.isLoading && this.state.project ? (
          <FadeIn>
            <ArchiveProjectPage
              project={this.state.project}
              pageType={this.state.pageType}
              pageTitle={this.state.pageTitle}
              projectSubTitle={this.state.projectSubTitle}
              projectDescription={this.state.projectDescription}
              pageMetaDescription={this.state.pageMetaDescription}
              subProjects={this.state.subProjects}
              defaultLogo={this.state.defaultLogo}
              logo={this.state.logo}
              getBackHref={this.state.getBackHref}
              plannedRealizedIdeasData={this.state.plannedRealizedIdeasData}
              groupDirections={this.state.groupDirections}
            />
          </FadeIn>
        ) : (
          <GenericProjectLoader />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    archive: state.projects.archive,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setAppColor: (color) => dispatch(setAppColor(color)),
});

const connected = connect(
  mapStateToProps,
  mapDispatchToProps
)(ArchiveProjectContainer);

export default withRouter(connected);
