import React, { useCallback, useEffect, useRef, useState } from "react";
import "./Game.scss";
import { directionsEnum, gridType, modDirectionsType, passagesEnum } from "./Game.types";
import { randomizeDirections } from "./Game.helper";
import playerSvg from "../../../../../../assets/newyear2023/games/maze/penguin.svg";
import finishSvg from "../../../../../../assets/newyear2023/games/maze/gift.png";
import Barrier from "../Barriers/Barrier";
import Timer from "../../Timer/Timer";

interface GameProps {
  gameHeight: number;
  gameWidth: number;
  onWin?: () => void;
  onLoose?: () => void;
}

const step = 15;
const wallSize = step;
const stepThreshold = 15;

const Game = ({ gameHeight, gameWidth, onWin, onLoose }: GameProps) => {
  const [grid, setGrid] = useState<gridType[][]>([]);
  const [isStopGame, setIsStopGame] = useState(false);
  const [isMazeGenerate, setIsMazeGenerate] = useState(false);
  const [cells, setCells] = useState([]);
  const playerRef = useRef<HTMLDivElement>(null);
  const finishRef = useRef<HTMLDivElement>(null);
  const startPositionRef = useRef<HTMLDivElement>(null);
  const renderGrid = useRef([]);
  const lastTouchY = useRef(0);
  const lastTouchX = useRef(0);
  const gameWidthBorder = gameWidth / 2;
  const gameHeightBorder = gameHeight / 2;
  const mazeHeight = Math.floor(gameHeightBorder / step);
  const mazeWidth = Math.floor(gameWidthBorder / step);
  const directions: directionsEnum[] = [
    directionsEnum.up,
    directionsEnum.down,
    directionsEnum.left,
    directionsEnum.right,
  ];
  const modDirections: modDirectionsType = {
    [directionsEnum.up]: { y: -1, x: 0, opposite: directionsEnum.down },
    [directionsEnum.down]: { y: 1, x: 0, opposite: directionsEnum.up },
    [directionsEnum.left]: { y: 0, x: -1, opposite: directionsEnum.right },
    [directionsEnum.right]: { y: 0, x: 1, opposite: directionsEnum.left },
  };

  const generateGrid = () => {
    for (let height = 0; height < mazeHeight; height++) {
      const subGrid: gridType[] = [];
      for (let width = 0; width < mazeWidth; width++) {
        subGrid.push({
          [directionsEnum.up]: 0,
          [directionsEnum.down]: 0,
          [directionsEnum.left]: 0,
          [directionsEnum.right]: 0,
        });
      }
      setGrid((current) => [...current, subGrid]);
    }
  };

  const generateMaze = (currentX, currentY, s, visits = {}) => {
    if (!grid.length) return;
    const randomDirections = randomizeDirections(directions, s);
    for (let directionIndex = 0; directionIndex < randomDirections.length; directionIndex++) {
      const nextX = currentX + modDirections[randomDirections[directionIndex]].x;
      const nextY = currentY + modDirections[randomDirections[directionIndex]].y;
      visits[`${currentY}-${currentX}`] = true;
      if (nextX >= 0 && nextX < mazeWidth && nextY >= 0 && nextY < mazeHeight && !visits[`${nextY}-${nextX}`]) {
        setGrid((prevGrid) => {
          const newGrid = prevGrid;
          newGrid[currentY][currentX][randomDirections[directionIndex]] = 1;
          newGrid[nextY][nextX][modDirections[randomDirections[directionIndex]].opposite] = 1;
          return newGrid;
        });
        generateMaze(nextX, nextY, directionIndex, visits);
      }
    }
    if (!isMazeGenerate) setIsMazeGenerate(true);
  };

  const generateRenderGrid = () => {
    const height = mazeHeight * 2;
    const width = mazeWidth * 2;
    const result = Array.from(Array(height), () => new Array(width));
    let resultWidthIndex = 1;
    let resultHeightIndex = 1;
    for (let heightIndex = 0; heightIndex < mazeHeight; heightIndex++) {
      resultWidthIndex = 1;
      for (let widthIndex = 0; widthIndex < mazeWidth; widthIndex++) {
        result[resultHeightIndex - 1][resultWidthIndex - 1] = passagesEnum.pass;
        if (grid[heightIndex][widthIndex][directionsEnum.right] === 0) {
          result[resultHeightIndex - 1][resultWidthIndex] = passagesEnum.barrier;
          result[resultHeightIndex][resultWidthIndex] = passagesEnum.barrier;
        } else {
          result[resultHeightIndex - 1][resultWidthIndex] = passagesEnum.pass;
          result[resultHeightIndex][resultWidthIndex] = passagesEnum.barrier;
        }
        if (grid?.[heightIndex]?.[widthIndex + 1]?.[directionsEnum.down] === 0) {
          result[resultHeightIndex][resultWidthIndex] = passagesEnum.barrier;
        }
        if (grid[heightIndex][widthIndex][directionsEnum.down] === 0) {
          result[resultHeightIndex][resultWidthIndex - 1] = passagesEnum.barrier;
        } else {
          result[resultHeightIndex][resultWidthIndex - 1] = passagesEnum.pass;
        }
        resultWidthIndex += 2;
      }
      resultHeightIndex += 2;
    }
    const topBarrier = new Array(width).fill(passagesEnum.barrier);
    result.unshift(topBarrier);
    result.forEach((item) => item.unshift(passagesEnum.barrier));
    renderGrid.current = generateEntryAndExit(result);
  };

  const generateEntryAndExit = (grid) => {
    const max = mazeHeight * 2;
    const entry = Math.floor(Math.random() * max);
    const exit = max - entry;
    if (
      grid[entry] &&
      (grid[entry][1] !== passagesEnum.pass || grid[max - entry][grid[entry].length - 2] !== passagesEnum.pass)
    ) {
      return generateEntryAndExit(grid);
    }
    grid[entry][0] = passagesEnum.pass;
    grid[exit][grid[entry].length - 1] = passagesEnum.pass;
    setPlayerAndTargets(entry * step, (max - entry) * step);
    return grid;
  };

  const setPlayerAndTargets = (startVerticalPosition, endVerticalPosition) => {
    if (!playerRef.current || !startPositionRef.current || !finishRef.current) return;

    playerRef.current.style.transform = `translate(0px, ${startVerticalPosition}px)`;
    startPositionRef.current.style.transform = `translate(0px, ${startVerticalPosition}px)`;
    finishRef.current.style.transform = `translate(${
      gameWidth - finishRef.current.offsetWidth
    }px, ${endVerticalPosition}px)`;
  };

  const renderCells = () => {
    return renderGrid.current.map((el, heightIndex) => {
      const result = el.map((item, widthIndex) => {
        const hasLeftBorder = renderGrid.current?.[heightIndex]?.[widthIndex - 1] !== passagesEnum.barrier;
        const hasRightBorder = renderGrid.current?.[heightIndex]?.[widthIndex + 1] !== passagesEnum.barrier;
        const hasTopBorder = renderGrid.current?.[heightIndex - 1]?.[widthIndex] !== passagesEnum.barrier;
        const hasBottomBorder = renderGrid.current?.[heightIndex + 1]?.[widthIndex] !== passagesEnum.barrier;
        return (
          <Barrier
            key={`height-${heightIndex} width-${widthIndex}`}
            transparent={item !== passagesEnum.barrier}
            size={wallSize}
            hasTopBorder={hasTopBorder}
            hasBottomBorder={hasBottomBorder}
            hasLeftBorder={hasLeftBorder}
            hasRightBorder={hasRightBorder}
          />
        );
      });

      return (
        <div key={`row-${heightIndex}`} className={"game__rowWrapper"}>
          {result}
        </div>
      );
    });
  };

  const checkIsCanMove = (direction: directionsEnum) => {
    if (!playerRef.current) return;

    const { translateX, translateY } = getTranslateValues(playerRef.current);
    const playerVerticalPosition = translateY / step;
    const playerHorizontalPosition = translateX / step;

    if (direction === directionsEnum.up) {
      return renderGrid.current[playerVerticalPosition - 1][playerHorizontalPosition] === passagesEnum.pass;
    }
    if (direction === directionsEnum.down) {
      return renderGrid.current[playerVerticalPosition + 1][playerHorizontalPosition] === passagesEnum.pass;
    }
    if (direction === directionsEnum.left) {
      return renderGrid.current[playerVerticalPosition][playerHorizontalPosition - 1] === passagesEnum.pass;
    }
    if (direction === directionsEnum.right) {
      return renderGrid.current[playerVerticalPosition][playerHorizontalPosition + 1] === passagesEnum.pass;
    }
  };

  const keysHandler = useCallback(
    (e) => {
      let code = e.code;
      switch (code) {
        case "ArrowUp":
          moveAndCheckWin(directionsEnum.up);
          break;
        case "ArrowDown":
          moveAndCheckWin(directionsEnum.down);
          break;
        case "ArrowLeft":
          moveAndCheckWin(directionsEnum.left);
          break;
        case "ArrowRight":
          moveAndCheckWin(directionsEnum.right);
          break;
      }
    },
    [isStopGame]
  );

  const checkWin = () => {
    if (!playerRef.current || !finishRef.current) return false;

    const playerRect = playerRef.current.getBoundingClientRect();
    const finishRect = finishRef.current.getBoundingClientRect();

    const playerCenterX = playerRect.left + playerRect.width / 2;
    const playerCenterY = playerRect.top + playerRect.height / 2;
    const finishCenterX = finishRect.left + finishRect.width / 2;
    const finishCenterY = finishRect.top + finishRect.height / 2;

    const isXAligned = Math.abs(playerCenterX - finishCenterX) < playerRect.width / 2;
    const isYAligned = Math.abs(playerCenterY - finishCenterY) < playerRect.height / 2;

    return isXAligned && isYAligned;
  };

  const checkIsStartPosition = () => {
    const { translateX, translateY } = getTranslateValues(playerRef.current);
    return translateX === 0 && translateY === 0;
  };

  const moveAndCheckWin = (direction) => {
    if (!playerRef.current || isStopGame) return;

    let { translateX, translateY } = getTranslateValues(playerRef.current);

    switch (direction) {
      case directionsEnum.up:
        if (checkIsCanMove(directionsEnum.up) && !checkIsStartPosition()) {
          translateY -= step;
        }
        break;
      case directionsEnum.down:
        if (checkIsCanMove(directionsEnum.down) && !checkIsStartPosition()) {
          translateY += step;
        }
        break;
      case directionsEnum.right:
        if (checkIsCanMove(directionsEnum.right)) {
          translateX += step;
        }
        break;
      case directionsEnum.left:
        if (checkIsCanMove(directionsEnum.left) && !checkIsStartPosition()) {
          translateX -= step;
        }
        break;
    }

    playerRef.current.style.transform = `translate(${translateX}px, ${translateY}px)`;

    if (checkWin()) finishGame(true);
  };

  const getTranslateValues = (element) => {
    const style = window.getComputedStyle(element);
    const matrix = new DOMMatrixReadOnly(style.transform);
    return {
      translateX: matrix.m41,
      translateY: matrix.m42,
    };
  };

  const finishGame = (isWin) => {
    setIsStopGame(true);
    if (isWin) onWin();
    else onLoose();
  };

  const onTouchStart = (event) => {
    lastTouchY.current = event.changedTouches[0].pageY;
    lastTouchX.current = event.changedTouches[0].pageX;
  };

  const onTouchMove = (event) => {
    const diffY = event.changedTouches[0].pageY - lastTouchY.current;
    const diffX = event.changedTouches[0].pageX - lastTouchX.current;
    if (diffY > stepThreshold) {
      moveAndCheckWin(directionsEnum.down);
      lastTouchY.current = event.changedTouches[0].pageY;
    } else {
      if (diffY < -1 * stepThreshold) {
        moveAndCheckWin(directionsEnum.up);
        lastTouchY.current = event.changedTouches[0].pageY;
      }
    }
    if (diffX > stepThreshold) {
      moveAndCheckWin(directionsEnum.right);
      lastTouchX.current = event.changedTouches[0].pageX;
    } else {
      if (diffX < -1 * stepThreshold) {
        moveAndCheckWin(directionsEnum.left);
        lastTouchX.current = event.changedTouches[0].pageX;
      }
    }
  };

  useEffect(() => {
    generateMaze(0, 0, 0);
  }, [grid]);

  useEffect(() => {
    if (!isMazeGenerate) return;
    generateRenderGrid();
    setCells(renderCells());
  }, [isMazeGenerate]);

  useEffect(() => {
    generateGrid();
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", keysHandler);
    return () => document.removeEventListener("keydown", keysHandler);
  }, [keysHandler]);

  return (
    <div className={`game__wrapper ${cells ? "game__wrapper--active" : ""}`}>
      <div className={"game__mazeTimer"}>
        <Timer onTimeExpire={() => finishGame(false)} initialMinutes={2} isStopTimer={isStopGame} />
      </div>
      <div className={`game__maze`} onTouchStart={onTouchStart} onTouchMove={onTouchMove}>
        <div className={`game__mazeWrapper`}>
          <div className={"game__snow"} />
          {cells}
          <div className={"game__target"} ref={startPositionRef} />
          <div className={"game__target"} ref={finishRef}>
            <img src={finishSvg} alt={"Подарок"} />
          </div>
          <div className={"game__playerWrapper"} ref={playerRef}>
            <img src={playerSvg} alt={"Игрок"} className={"game__playerImg"} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Game;
