import React from "react";
import { Stage, StageType } from "../../../types/Stage.interface";
import { Banner } from "../../../types/Banner.interface";
import { IdeaCategory } from "../../../types/Idea.interface";
import { Project } from "../../../types/Projects.interface";

export interface BannerDirectionsProps {
  banner: Banner;
  currentProjects: Project[];
  announcementProjects: Project[];
  screenWidth: number;
  onDirectionsSwipe: (e: any) => void;
}

export enum BannerStageTypes {
  GENERATION = "generation",
  SIMPLE_DISCUSSION = "simple_discussion",
  VOTING = "voting",
}

export enum BannerStageNull {
  GENERATION = "Сбор идей не запланирован",
  VOTING = "Голосование за идеи не запланировано",
  SIMPLE_DISCUSSION = "Обсуждения с участниками не запланированы",
}

export type BannerStageType =
  | BannerStageTypes.GENERATION
  | BannerStageTypes.SIMPLE_DISCUSSION
  | BannerStageTypes.VOTING;

export type BannerStages = {
  [index: string]: BannerCurrentStage;
};

export interface BannerStage {
  type: BannerStageType;
  filtered: Stage[];
  all: Stage[];
  currentIndex: number;
  mobileCurrentIndex: number;
}

export type BannerCurrentStage = {
  [key in BannerStageType]?: BannerStage | string;
};

export type UserAssigned = { [index: string]: string[] } | null;
