import React, { FC, useEffect, useState, memo } from "react";
import { Garlands1, Garlands2 } from "./Garland.helper";
import "./Garland.scss";

const mapVariantToGarlands = {
  1: Garlands1,
  2: Garlands2,
};

const Garland: FC<{ variantNum: number, canGlow: boolean }> = memo(({ variantNum, canGlow }) => {
  const [currentImage, setCurrentImage] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => { 
      if (!canGlow) return;
      setCurrentImage(prevImage => (prevImage + 1) % mapVariantToGarlands[variantNum].length);
    }, 2000);

    return () => clearInterval(interval);
  }, [variantNum, canGlow]);

  const getClasslistForRoot = () => {
    let classlist = "garland ";
    if (variantNum) {
      classlist += `garland--type-${variantNum}`;
    }
    return classlist
  }

  return (
    <div className={getClasslistForRoot()}>
      <div className="garland__wire">
        <img src={mapVariantToGarlands[variantNum][currentImage]} alt="Гирлянда" />
      </div>
    </div>
  );
});

export default Garland;