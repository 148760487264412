import React, { Component, Suspense, useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";

import "./App.scss";
import "../common.scss";

import HeaderContainer from "./containers/Header/HeaderContainer";
import Footer from "./containers/Footer/Footer";
import {
  getProjectBanners,
  getCurrentProjects,
  getAnnouncementProjects,
  getArchiveProjects,
  getCompletedProjects,
} from "../store/actions/ProjectActions";
import { getUser, logout } from "../store/actions/UserActions";
import { getNewNotificationsCount } from "../store/actions/NotificationsActions";
import { getTotalStatistics } from "../store/actions/StatisticsActions";
import { HeaderRoutesType, hideInfoModal, lessenPage, setHeaderOptions } from "../store/actions/LayoutActions";
import { loadEnv } from "../store/actions/EnvironmentActions";
import indexedRoutes from "../routing/IndexedRoutes";
import ModerationPage from "../components/containers/Moderation/ModerationPage";
import ideaModerationPage from "../components/containers/ideaModeration/ideaModerationPage";
import LicensePage from "../components/pages/License/LicensePage";
import BlockedPage from "../components/pages/Blocked/BlockedPage";
import { hiddenURLs } from "../utils/constants";
import baseRoutes from "../routing/BaseRoutes";
import GuardService from "../routing/GuardService";
import BlockedUserGuard from "../routing/guards/BlockedUserGuard";
import MainPage from "./pages/Main/MainPage";
import PointsRulesPage from "./pages/PointsRules/PointsRulesPage";
import { routes as BaseRoutes } from "./App.helper";
import BookPresentationPage from "./pages/BookPresentation/BookPresentation";
import { getSpheres } from "../store/actions/GorodActions";
import { getDistricts, getRegions } from "../store/actions/AddressActions";
import { ping } from "../store/actions/OperabilityActions";
import { getSittingByKey } from "../store/actions/SittingsActions";
import { SittingsKeys } from "../types/Sittings.interface";
import { RootState } from "../types/State.interface";
import { ResourceLoader } from "./containers/Wrappers";
import NewYear2023Root from "./pages/NewYear2023/NewYear2023Root";
import LeisureLandingRoot from "./pages/LeisureLanding/LeisureLandingRoot";
import AfterRegistrationForm from "./containers/Forms/AfterRegistrationForm/AfterRegistrationForm";
import { AppContext } from "./Root";
import { AuthActionType, ComponentType } from "../services/sudirService";
import ResendEmailModal from "./containers/Modals/ResendEmailModal/ResendEmailModal";
import { LocalStorageKeys, LocalStorageService } from "../services/LocalStorage.service";

const App = () => {
  const appContext = useContext(AppContext);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const user = useSelector((state: RootState) => state.user.userDetails);
  const inWebViewMode = useSelector((state: RootState) => state.layout.inWebViewMode);
  const isLeisurePage = history.location.pathname === "/city";
  const isNewYearPage = history.location.pathname === "/newyear2024";
  const [afterRegistrationFormOpen, setAfterRegistrationFormOpen] = useState(false);
  const [resendEmailModalOpen, setResendEmailModal] = useState(false);
  const regFormRedirectRef = useRef(() => {});

  // ACTIONS AFTER AUTH
  useEffect(() => {
    const actions = appContext.sudirService.getActions(ComponentType.MAIN);
    if (actions.length) {
      const redirectAction = actions.find((act) => act.type === AuthActionType.REDIRECT_AFTER_REG_FORM);
      if (redirectAction) {
        const redirectAsLocal = () => {
          const [path, search] = redirectAction.args.redirectLink.split("?");
          history.push({
            pathname: path,
            search: "?" + search,
            state: { alreadyJoined: true },
          });
        };

        const redirectAsAbsolute = () => (window.location.href = redirectAction.args.redirectLink);

        const redirect = () => (redirectAction.args.absolutePath ? redirectAsAbsolute() : redirectAsLocal());

        regFormRedirectRef.current = redirect;
      }
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (user?.loggedIn) {
      dispatch(getNewNotificationsCount());
    }
  }, [user]);

  const fetchData = async () => {
    try {
      dispatch(getUser());
      await Promise.all([
        dispatch(ping()),
        dispatch(loadEnv()),
        dispatch(
          setHeaderOptions({
            routes: BaseRoutes,
            type: HeaderRoutesType.MAIN,
          })
        ),
        dispatch(getProjectBanners()),
        dispatch(getTotalStatistics()),
        dispatch(getSpheres()),
        dispatch(getRegions()),
        dispatch(getDistricts()),
        dispatch(getCurrentProjects()),
        dispatch(getAnnouncementProjects()),
        dispatch(getCompletedProjects()),
        dispatch(getArchiveProjects()),
        dispatch(getSittingByKey(SittingsKeys.NEW_IDEAS)),
      ]);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const emailAction = (history?.location?.state as any)?.emailAction;

    if (user?.loggedIn && user?.partiallyFilled) {
      setAfterRegistrationFormOpen(true);
    }

    if (!emailAction && user && user?.loggedIn && !user.emailVerified && !user.partiallyFilled) {
      setResendEmailModal(true);
    }

    if (!emailAction && user && user?.loggedIn && user.emailVerified && user.accountType === "NATIVE") {
      if (typeof user.passwordLastChanged === "undefined" || user.passwordLastChanged === null) {
        history.replace("/changePassword?firstTime=true");
      } else if (user.passwordExpired) {
        history.replace("/changePassword?firstTime=false");
      }
    }
  }, [user?.loggedIn, history?.location?.state, user?.partiallyFilled, user?.emailVerified]);

  const getBlockedGuardedRoutes = () => {
    const routes = <>{[<Route exact path="/" component={MainPage} key={"Main"} />]}</>;
    const guardService = new GuardService([BlockedUserGuard]);
    return guardService.getGuarded(routes);
  };

  const getGuardedRoutes = () => {
    const routes = (
      <>
        {[...indexedRoutes]}
        {[...baseRoutes]}
      </>
    );
    const guardService = new GuardService([BlockedUserGuard]);
    return guardService.getGuarded(routes);
  };

  const canShowHeader = () => {
    return !inWebViewMode && !hiddenURLs.includes(location.pathname.toLowerCase());
  };

  const canShowFooter = () => {
    return !inWebViewMode && !hiddenURLs.includes(location.pathname.toLowerCase());
  };

  const handleClose = () => {
    setAfterRegistrationFormOpen(false);
    dispatch(hideInfoModal());
    regFormRedirectRef.current();
  };

  return (
    <>
      {isNewYearPage ? (
        <NewYear2023Root />
      ) : isLeisurePage ? (
        <LeisureLandingRoot />
      ) : (
        <>
          <ResourceLoader />
          {canShowHeader() && <HeaderContainer />}
          <main>
            <Suspense fallback={<div />}>
              <Switch>
                <Route path="/blocked" component={BlockedPage} key={"Blocked"} />
                <Route path="/moderation" component={ModerationPage} key={"Moderation"} />
                <Route path="/ideaModeration" component={ideaModerationPage} key={"IdeaModeration"} />
                <Route path="/pointsRules" component={PointsRulesPage} key={"PointsRules"} />
                <Route path="/license" component={LicensePage} key={"License"} />
                <Route path="/projects/:project/book" component={BookPresentationPage} key={"ProjectBook"} />
                {user && getBlockedGuardedRoutes()}
                {user && getGuardedRoutes()}
              </Switch>
            </Suspense>
          </main>
          {canShowFooter() && <Footer />}

          {afterRegistrationFormOpen && (
            <AfterRegistrationForm
              user={user}
              isOpen={afterRegistrationFormOpen}
              close={handleClose}
              closeWithLogout={() => {
                setAfterRegistrationFormOpen(false);
                dispatch(logout());
              }}
              onSubmit={() =>
                dispatch(
                  getUser(() => {
                    if (!user.emailVerified) {
                      setResendEmailModal(true);
                    }

                    const actions = appContext.sudirService.getActions(ComponentType.AFTER_REGISTRATION_FORM);
                    const redirectToLastVisitedPage = actions.find(
                      (act) => act.type === AuthActionType.VISIT_LAST_PAGE
                    );
                    if (redirectToLastVisitedPage) {
                      history.push(redirectToLastVisitedPage.args.lastVisitedPage, {
                        lastVisitedPage: redirectToLastVisitedPage.args.lastVisitedPage,
                      });
                    }
                  })
                )
              }
            />
          )}

          {resendEmailModalOpen && (
            <ResendEmailModal isOpened={resendEmailModalOpen} onClose={() => setResendEmailModal(false)} />
          )}
        </>
      )}
    </>
  );
};

export default App;
