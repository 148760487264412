import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../types/State.interface";
import { mapScripts } from "../../containers/Wrappers/ResourceLoader/config";
import MapService from "../../../services/mapService";
import Button from "../Controls/Button/Button";
import { hideInfoModal, showInfoModal } from "../../../store/actions/LayoutActions";
import { getActivePinStyle, getDefaultPinStyle } from "./OpenLayersMap.helper";

import "./OpenLayersMap.scss";
import Loader from "../Loaders/Loader/Loader";
import EventsTrackWrapperClick from "../../containers/EventsTrack/wrappers/EventsTrackWrapperClick";
import { useOuterClick } from "../../../hooks/useOnOutsideClick";
import OpenLayersMapLoader from "./OpenLayersMapLoader/OpenLayersMapLoader";

interface Clasterization {}

interface Location {}

interface Point {
  id?: string;
  coordinates: [string, string];
}

interface ZoomOptions {
  min?: number;
  desired?: number;
  max?: number;
}

interface OpenLayersMapProps {
  mountSelectorId?: string;
  zoom?: ZoomOptions;
  clasterization?: Clasterization;
  location?: Location;
  points?: Point[];
  onClick?: (data) => void;
  onClickCluster?: (data) => void;
  onOutsideClick?: () => void;
}

const requiredScripts = mapScripts;

const OpenLayersMap: React.FC<OpenLayersMapProps> = ({
  mountSelectorId = "open-layers-map-id",
  zoom = {
    min: 8,
    desired: 11,
  },
  clasterization,
  points,
  onClick,
  onClickCluster,
  onOutsideClick,
}: OpenLayersMapProps) => {
  const dispatch = useDispatch();
  const isScrolling = useSelector((state: RootState) => state.globalEvents.isScrolling);

  const mapRef = useOuterClick<HTMLInputElement>((event: any) => {
    if (currentFeatureRef.current) {
      setFeatureAsInactive(currentFeatureRef.current);
      currentFeatureRef.current = null;
      onOutsideClick();
    }
  });

  const resources = useSelector((state: RootState) => state.resources.scripts);
  const currentFeatureRef = useRef(null);
  const [map, setMap] = useState(null);

  useEffect(() => {
    if (requiredScripts.every((script) => resources[script.src])) {
      const map = MapService.createMap(mountSelectorId, zoom.desired, getOverlay());
      setMap(map);
    }
  }, [resources]);

  useEffect(() => {
    if (map) {
      if (zoom) {
        map.getView().setMinZoom(zoom.min);
      }

      if (clasterization) {
        setClasterization(points);
      }

      map.on("click", clickHandler);
      map.on("pointermove", pointerMovehandler);
    }
  }, [map]);

  const setClasterization = (points: Point[]) => {
    if (!points?.length) return;

    const source = new window.ol.source.Vector();

    points.forEach((_point) => {
      const [latitude, longitude] = _point.coordinates;
      const point = new window.ol.Feature({
        geometry: new window.ol.geom.Point(window.ol.proj.fromLonLat([longitude, latitude])),
        id: _point.id,
      });
      source.addFeature(point);
    });

    const clusterLayer = new window.ol.layer.Vector({
      name: "Cluster",
      source: new window.ol.source.Cluster({
        distance: 80,
        source: source,
      }),

      style: getDefaultPinStyle,
    });

    map.addLayer(clusterLayer);
  };

  // Handlers

  const clickHandler = (event) => {
    const clickedCoordinate = event.coordinate;
    const [longitude, latitude] = window.ol.proj.toLonLat(clickedCoordinate);
    const egipCoords = MapService.getEgipCoords({latitude, longitude});

    const features = [];
    const layers = [];
    map.forEachFeatureAtPixel(event.pixel, (feature, layer) => {
      features.push(feature);
      layers.push(layer);
    });

    const clickedFeature = features[0];

    if (clickedFeature) {
      const _features = clickedFeature.get("features");
      let isSameAddress = true;
      for (let i = 0; i < _features.length; i++) {
        if (i > 0) {
          const [x, y] = _features[i].getGeometry().getCoordinates();
          const [xPrev, yPrev] = _features[i - 1].getGeometry().getCoordinates();
          if (x !== xPrev || y !== yPrev) {
            isSameAddress = false;
            break;
          }
        }
      }
      if (isSameAddress) {
        handlePointClick({
          feature: clickedFeature,
          coordinates: egipCoords,
        });
      } else {
        handleClusterClick({
          feature: clickedFeature,
          coordinates: egipCoords,
        });
      }
    }
  };

  const pointerMovehandler = (event) => {
    if (event.dragging) return;

    const pixel = map.getEventPixel(event.originalEvent);
    const hit = map.hasFeatureAtPixel(pixel);

    map.getTargetElement().style.cursor = hit ? "pointer" : "";
  };

  const handleClusterClick = ({ feature, coordinates }) => {
    const clusterFeatures = feature.get("features");
    const xArr = [];
    const yArr = [];
    for (const f of clusterFeatures) {
      const [x, y] = f.getGeometry().getCoordinates();
      xArr.push(x);
      yArr.push(y);
    }
    const extent = [Math.min(...xArr), Math.min(...yArr), Math.max(...xArr), Math.max(...yArr)];
    map.getView().fit(extent, {
      size: map.getSize(),
      duration: 600,
      padding: [10, 10, 10, 10],
      maxZoom: 18,
    });

    onClickCluster({ feature, coordinates });
  };

  const handlePointClick = ({ feature, coordinates }) => {
    if (currentFeatureRef.current) {
      setFeatureAsInactive(currentFeatureRef.current);
    }

    currentFeatureRef.current = feature;
    setFeatureAsActive(feature);

    centerMap(coordinates);

    onClick({ feature, coordinates });
  };

  const setFeatureAsActive = (feature) => {
    feature.setStyle(getActivePinStyle());
  };

  const setFeatureAsInactive = (feature) => {
    feature.setStyle(getDefaultPinStyle(feature));
  };

  const centerMap = (coordinates, duration = 600) => {
    map.getView().animate({
      center: coordinates,
      duration: duration,
    });
  };

  const detectLocation = () => {
    // Get the user's location
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;

          const iconStyle = new window.ol.style.Style({
            image: new window.ol.style.Icon({
              src: require("../../../assets/map/location-pin.svg").default,
              scale: 0.05,
            }),
          });

          const marker = new window.ol.Feature({
            geometry: new window.ol.geom.Point(window.ol.proj.fromLonLat([longitude, latitude])),
          });

          marker.setStyle(iconStyle);

          const vectorSource = new window.ol.source.Vector({
            features: [marker],
          });

          const vectorLayer = new window.ol.layer.Vector({
            source: vectorSource,
          });

          map.addLayer(vectorLayer);

          // Center the map on the user's location
          map.getView().setCenter(window.ol.proj.fromLonLat([longitude, latitude]));
        },
        (error) => {
          const text = `
            <div class="cr-map__location-prohibited">
              <div class="cr-map__location-prohibited-title">Мы не знаем где вы находитесь.</div>
              <div class="cr-map__location-prohibited-text">Разрешите «Городу идей» определять ваше местоположение.</div>
              <div class="cr-map__location-instruction">Посмотреть <a href="https://support.google.com/chrome/answer/142065?hl=ru&co=GENIE.Platform%3DDesktop&oco=1" target="_blank">инструкцию</a>.</div>
            </div>
            `;

          dispatch(
            showInfoModal(text, <Button type="outlined" text="Закрыть" onClick={() => dispatch(hideInfoModal())} />)
          );
        }
      );
    } else {
      dispatch(
        showInfoModal(
          "Геолокация не поддерживается этим браузером.",
          <Button type="outlined" text="Закрыть" onClick={() => dispatch(hideInfoModal())} />
        )
      );
    }
  };

  const zoomAnimate = (e, zoomIn: boolean, duration = 500) => {
    e && e.stopPropagation();

    const view = map.getView();
    const currentZoom = view.getZoom();

    view.animate({
      zoom: zoomIn ? currentZoom + 1 : currentZoom - 1,
      duration: duration,
    });
  };

  // Render

  const renderControlButtons = () => {
    if (!map) return;

    return (
      <div className="olm-map__controls">
        <EventsTrackWrapperClick id={255}>
          <button className="olm-map__control olm-map__location" onClick={detectLocation}>
            <span className="ic ui-icon-navigation"></span>
          </button>
        </EventsTrackWrapperClick>

        <div className="olm-map__controls-wrap">
          <EventsTrackWrapperClick id={253}>
            <button className="olm-map__control olm-map__zoom-in" onClick={(e) => zoomAnimate(e, true)}>
              <span className="ic ui-icon-zoom-in"></span>
            </button>
          </EventsTrackWrapperClick>
          <EventsTrackWrapperClick id={254}>
            <button className="olm-map__control olm-map__zoom-out" onClick={(e) => zoomAnimate(e, false)}>
              <span className="ic ui-icon-zoom-out"></span>
            </button>
          </EventsTrackWrapperClick>
        </div>
      </div>
    );
  };

  // TD
  const getOverlay = () => {
    const beforeClass = "bottom-center";
    const container = document.querySelector(".map-popup");

    var overlay = new window.ol.Overlay({
      element: container,
      // autoPan: true,
      positioning: beforeClass,
    });

    return overlay;
  };
  
  return (
    <div className="olm-wrapper" style={{ pointerEvents: isScrolling ? "none" : "auto" }}>
      <div ref={mapRef} className="olm-map" id="open-layers-map-id"></div>
      {renderControlButtons()}
      {!map && <OpenLayersMapLoader />}
    </div>
  );
};

export default OpenLayersMap;