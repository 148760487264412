import { FC, useContext, useEffect, useRef, useState } from "react";
import "./Header.scss";
import { HeaderProps } from "./Header.types";
import Button from "../Buttons/Button";
import IconButton from "../Buttons/IconButton";
import { navigations } from "./header.helpers";
import CircleButton from "./CircleButton/CircleButton";
import { useOuterClick } from "../../../../../hooks/useOnOutsideClick";
import { mediumTabletWidth } from "../../../../../utils/constants/widthConstants";
import BurgerButton from "./BurgerButton/BurgerButton";
import { useMediaQuery } from "react-responsive";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../types/State.interface";
import { logout } from "../../../../../store/actions/UserActions";
import { AuthAction, AuthActionParam, AuthActionType, ComponentType } from "../../../../../services/sudirService";
import { AppContext } from "../../../../Root";
import { toggleFullScreenDocument } from "../../../../../store/actions/WindowActions";
import { isIOS } from "react-device-detect";

const Header: FC<HeaderProps> = ({ swiper, facts, games }) => {
  const appContext = useContext(AppContext);
  const dispatch = useDispatch();
  const [swiperEnabled, setSwiperEnabled] = useState(true);
  const [isShowNavigation, setIsShowNavigation] = useState(false);
  const user = useSelector((state: RootState) => state.user.userDetails);
  const fullScreenId = useSelector((state: RootState) => state.window.fullScreenElementId);
  const navigationRef = useOuterClick<HTMLDivElement>((event) => isShowNavigation && toggleNavigation(event), false);
  const headerRef = useRef<HTMLHeadElement>(null);
  const isTablet = useMediaQuery({ query: `(max-width: ${mediumTabletWidth}px)` });
  const isFullScreen = !!fullScreenId;

  const onNavButtonClick = (event, index) => {
    toggleNavigation(event);
    goToSlide(index);
  };

  const toggleNavigation = (event) => {
    event.stopPropagation();
    setIsShowNavigation((current) => !current);
  };

  const goToSlide = (index) => {
    swiper.slideTo(index, 1000);
  };

  const onLogin = () => {
    const thisContainerData: AuthActionParam = {
      component: ComponentType.NY_2023_PAGE,
      type: AuthActionType.LOGIN,
      args: {
        lastSlideIndex: swiper.realIndex,
      },
    };

    const action1 = new AuthAction(thisContainerData);
    return appContext.sudirService.authWithActions([action1]);
  };

  const logOut = () => {
    dispatch(logout());
  };

  const toggleFullScreen = () => {
    dispatch(toggleFullScreenDocument());
  };

  useEffect(() => {
    if (swiper) {
      setSwiperEnabled((swiper as any).enabled);

      const cbEnable = () => setSwiperEnabled(true);
      const cbDisable = () => setSwiperEnabled(false);
      swiper.on("enable" as any, cbEnable);
      swiper.on("disable" as any, cbDisable);
      return () => {
        swiper.off("enable" as any, cbEnable);
        swiper.off("disable" as any, cbDisable);
      };
    }
  }, [swiper]);

  return (
    <header className="new-year-2023-header" ref={headerRef}>
      {/* <div className={"new-year-2023-header__side"}>
        <div className={"new-year-2023-header__counter"}>
          <CircleButton
            left={17}
            top={64}
            text={"Найдите все спрятанные предметы на странице"}
            active={facts.founded > 0}
          >
            <div className={"new-year-2023-header__garland"} />
          </CircleButton>
          <div className={"new-year-2023-header__countNumbers"}>
            {facts.founded} / {facts.all}
          </div>
        </div>
        <div className={"new-year-2023-header__counter"}>
          <CircleButton text={"Станьте победителем <br /> во всех играх на странице"} active={games.completed > 0}>
            <div className={"new-year-2023-header__game"} />
          </CircleButton>
          <div className={"new-year-2023-header__countNumbers"}>
            {games.completed} / {games.all}
          </div>
        </div>
      </div> */}
      <div className={"new-year-2023-header__side new-year-2023-header__navWrapper"}>
        {isTablet && !isIOS && (
          <button
            className={`new-year-2023-header__fullScreen ${
              isShowNavigation ? "new-year-2023-header__fullScreen--active" : ""
            }`}
            onClick={toggleFullScreen}
          >
            <div
              className={`new-year-2023-header__fullScreenIcon new-year-2023-header__fullScreen${
                isFullScreen ? "Close" : "Open"
              }`}
            />
          </button>
        )}
        {!isTablet && (
          <Button
            onClick={toggleNavigation}
            className={`${isShowNavigation ? "new-year-2023-button--active" : ""} ${!swiperEnabled ? "disabled" : ""}`}
          >
            <div className={"new-year-2023-header__liftIcon"} />
            Прокатиться на лифте
          </Button>
        )}
        {user?.loggedIn ? (
          <Button
            className={`new-year-2023-button__login ${isShowNavigation ? "new-year-2023-button--active" : ""} ${
              !swiperEnabled ? "disabled" : ""
            }`}
            onClick={logOut}
          >
            <div className={"new-year-2023-header__loginInner new-year-2023-header__logoutIcon"} />
          </Button>
        ) : (
          <Button
            className={`new-year-2023-button__login ${isShowNavigation ? "new-year-2023-button--active" : ""}`}
            onClick={onLogin}
          >
            <div className={"new-year-2023-header__loginInner new-year-2023-header__loginIcon"} />
          </Button>
        )}
        {isTablet && <BurgerButton onClick={toggleNavigation} active={isShowNavigation} />}
        <nav
          ref={navigationRef}
          className={`new-year-2023-header__navigation ${
            isShowNavigation ? "new-year-2023-header__navigation--active" : ""
          }`}
        >
          {navigations.map((navigation, index) => (
            <IconButton
              key={index}
              iconClassName={navigation.className}
              icon={navigation.icon}
              onClick={(event) => onNavButtonClick(event, navigation.slide)}
              text={navigation.text}
            />
          ))}
        </nav>
      </div>
    </header>
  );
};

export default Header;
