import { forwardRef } from "react";

const Line4 = forwardRef<SVGPathElement>((_, ref) => (
  <svg
    width="1496"
    height="1080"
    viewBox="0 0 1496 1080"
    fill="none"
    style={{ transform: "translate(-35px, 0px)" }}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      ref={ref}
      d="M1392.61 1C1449.11 22.4818 1528.25 131.116 1480.11 224.584C1411.34 358.096 1281.19 370.64 1035.14 299.834C816.65 236.96 750.462 139.506 763.654 62.486C774.153 1.18426 861.348 78.9498 777.997 151.557C553.397 347.207 262.653 338.838 100.695 508.563C-105.292 724.429 52.9287 1018.75 172.921 1079"
      stroke="#252526"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
));

export default Line4;
