import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { hideMaintenanceModal } from "../../../../store/actions/LayoutActions";

import { AppModal } from "../../Modal/AppModal";

import "./MaintenanceModal.scss";

export function MaintenanceModal() {
  const dispatch = useDispatch();
  const isOpen = useSelector((state: any) => state.layout.showMaintenanceModal);

  function handleClose(e) {
    dispatch(hideMaintenanceModal());
  }

  return (
    <AppModal
      center
      classNames={{
        modal: "maintenance-modal",
        overlay: "maintenance-modal-overlay",
      }}
      open={isOpen}
      blockScroll={true}
      closeOnEsc={true}
      closeOnOverlayClick={false}
      onClose={handleClose}
      showHeader={false}
    >
      <div className="went-wrong">
        <img
          src={require("../../../../assets/lock-24px@2x.png").default}
          alt="Логотип"
        ></img>
        <b>Внимание!</b>
        <p>На сайте ведутся технические работы.</p>
        <p>Приносим извинения за доставленные неудобства.</p>
      </div>
    </AppModal>
  );
}

export default MaintenanceModal;
