import { ADD_POSTMESSAGE_LISTENER } from "../actions/PostMessageActions";

export interface PostMessageListener {
    source: string;
    handler: (data?) => void;
}

export interface PostMessagesState {
  listeners: PostMessageListener[];
}

const initialState = {
    listeners: [],
};

export function postMessagesReducer(
  state: PostMessagesState = initialState,
  action
) {
  switch (action.type) {
    case ADD_POSTMESSAGE_LISTENER:
      return { 
        ...state, 
        listeners: [...state.listeners, action.payload]
     };

    default:
      return state;
  }
}
