// @flow
import IdeaService from "../../services/ideaService";
import { Idea } from "../../types/Idea.interface";

export const GET_IDEAS = "GET_IDEAS";
export const GET_IDEA_TAGS = "GET_IDEA_TAGS";
export const GET_PROJECTS = "GET_PROJECTS";

export const getIdeas = (params) => (dispatch) => {
  return IdeaService.getByFilter(params)
    .then(({ data }) => {
      dispatch({
        type: GET_IDEAS,
        payload: data,
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getIdeasList = (params, onSuccess?) => (dispatch) => {
  dispatch({
    type: 'GET_IDEAS_LIST_START'
  });
  return IdeaService.getByFilter(params)
    .then((data) => {
      dispatch({
        type: 'GET_IDEAS_LIST',
        payload: data,
      });
      onSuccess && onSuccess()
    })
    .catch((err) => {
      console.log(err);
    })
};

export const likeIdea = (idea: Idea) => (dispatch) => {
  return IdeaService.like(idea.id)
    .then((data) => {
      dispatch({
        type: 'LIKE_IDEA',
        payload: idea,
      });
    })
    .catch((err) => {
      console.log(err);
    })
};

export const getIdeaTags = () => (dispatch) => {
  return IdeaService.getTags()
    .then(({ data }) => {
      dispatch({
        type: GET_IDEA_TAGS,
        payload: data,
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getProjects = () => (dispatch) => {
  return IdeaService.getProjects()
    .then(({ data }) => {
      dispatch({
        type: GET_PROJECTS,
        payload: data,
      });
    })
    .catch((err) => {
      console.log(err);
    });
};
