import { PopupProps } from "../../components/Popup";
import { ActivityType } from "../../services/city-api";
import { fullWidth } from "../../utils/constants";
import leisureLandingObjects from "./leisureLandingObjects";

type LeisureLandingPointBase = {
  posLeft: number;
  posRight?: number;
  topText: string;
  bottomText?: string;
  wType?: ActivityType;
  popupSize?: PopupProps["size"];
};
export type LeisureLandingPoint = LeisureLandingPointBase &
  (
    | {
        type: "idea";
        data?: {
          sphereCodes?: string[];
        };
      }
    | {
        type: "photo";
        data?: {
          projectOrCategoryCodes?: string[];
        };
      }
    | {
        type: "game" | "default";
      }
  );

const characterObject = leisureLandingObjects.find(({ type }) => type === "character");
const leisureLandingPoints: LeisureLandingPoint[] = [
  {
    type: "default",
    posLeft: characterObject.posLeft,
    posRight: 1700,
    topText:
      "Привет! Я ваш помощник —<br />буду рассказывать вам полезную<br />информацию во время путешествия.<br />Передвигайтесь по странице,<br />используя стрелки внизу.<br />Нажимайте на лампочки с идеями,<br />чтобы узнать подробнее<br />о предложениях жителей.",
    popupSize: 3,
  },
  {
    type: "default",
    posLeft: 2127,
    posRight: 3704,
    topText:
      "Предложения жителей помогают развивать город в различных сферах. Например, благодаря реализованным идеям москвичей на станциях МЦД появились электронные информационные табло, вендинговые аппараты и многое другое.",
    popupSize: 2,
  },
  {
    type: "default",
    posLeft: 4091,
    posRight: 5959,
    topText:
      "С каждым днем зеленые зоны столицы<br />преображаются и становятся еще комфортнее<br />для досуга москвичей и гостей города.<br />К примеру, в парках появились пункты проката<br />велосипедов и самокатов, а еще проводятся<br />просветительские фотовыставки.<br /><br />Вы также можете поделиться своими идеями<br />по развитию столицы в любое удобное время<br />в разделе «<a href='https://crowd.mos.ru/propose' target='_blank'><strong>Предложить идею</strong></a>».",
    popupSize: 3,
  },
  {
    type: "default",
    posLeft: 5960,
    posRight: 7853,
    topText:
      "Благодаря идеям жителей в столице активно развивается спортивный досуг — во дворах устанавливаются тренажеры и проводятся занятия с профессиональными тренерами.",
    popupSize: 2,
  },
  {
    type: "default",
    posLeft: 7854,
    posRight: 9423,
    topText:
      "Горожане также активно участвуют и делятся<br />идеями по развитию сферы экологии. Например,<br />благодаря инициативам жителей в столице<br />проводятся мероприятия по высадке деревьев<br />и субботники.<br /><br />А в разделе «<a href='https://crowd.mos.ru/ideas?page=1' target='_blank'><strong>Галерея реализованных идей</strong></a>»<br />вы всегда можете отправить фотографии,<br />подтверждающие реализацию предложений.",
    popupSize: 3,
  },
  {
    type: "default",
    posLeft: 9800,
    posRight: 11393,
    topText:
      "Несколько раз в год на платформе проводятся<br />тематические проекты, где жители могут<br />подавать идеи по развитию городских сфер<br />и общаться с другими участниками.<br />Ознакомиться с их результатами можно<br />в разделе «<a href='https://crowd.mos.ru/archive' target='_blank'><strong>Выполненные проекты</strong></a>».",
    popupSize: 3,
  },
  {
    type: "default",
    posLeft: 11750,
    posRight: 13439,
    topText:
      "Ознакомьтесь с событиями<br />других электронных<br />городских проектов и узнайте,<br />как еще можно принимать<br />участие в развитии города.",
  },
  {
    type: "default",
    posLeft: 13620,
    posRight: fullWidth - characterObject.posLeft,
    topText: "Мы добрались до конца<br />страницы. Давайте еще раз<br />прокатимся по городу?",
  },
];

export default leisureLandingPoints;
