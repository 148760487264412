import React, { Component } from "react";
import ContentLoader from "react-content-loader";

import Platform from "../../../../services/platform";

export default function GenericProjectLoader(props) {
  if (Platform.isPhone()) {
    return (
      <div style={{ width: "100%" }}>
        <ContentLoader
          viewBox="0 0 100 100"
          preserveAspectRatio="none"
          {...props}
          title="Загрузка..."
        >
          <rect x="0" y="5" rx="0" ry="0" width="100" height="8"></rect>
          <rect x="0" y="15" rx="0" ry="0" width="100" height="12"></rect>
          <rect x="0" y="30" rx="0" ry="0" width="100" height="40"></rect>
        </ContentLoader>
      </div>
    );
  } else {
    return (
      <div style={{ width: "100%" }}>
        <ContentLoader
          viewBox="0 0 100 100"
          preserveAspectRatio="none"
          {...props}
          title="Загрузка..."
        >
          {/* Left reactangle*/}
          <rect x="0" y="3" rx="0" ry="0" width="50" height="40"></rect>
          {/* Right rectangle */}
          <rect x="65" y="10" rx="0" ry="0" width="35" height="15"></rect>

          {/* Bottom lines */}
          <rect x="65" y="28" rx="0" ry="0" width="35" height="4"></rect>
          <rect x="65" y="33" rx="0" ry="0" width="35" height="4"></rect>
        </ContentLoader>
      </div>
    );
  }
}
