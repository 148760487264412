import { forwardRef } from "react";
import { CharacterProps } from "./Character.types";
import "./Character.scss";
import Popup from "../Popup";

const Character = forwardRef<HTMLDivElement, CharacterProps>(
  ({ popup, position, className, object, onClick, ...otherProps }, ref) => (
    <Popup
      ref={ref}
      className={`leisure-landing-character leisure-landing-character_${position} ${className || ""}`}
      {...popup}
      {...otherProps}
      position={position}
    >
      <div
        className="leisure-landing-character__object"
        onClick={(e) => {
          e.stopPropagation();
          onClick();
        }}
      >
        {object}
      </div>
    </Popup>
  )
);

export default Character;
