// @flow
import { fetchRequest } from "../../utils/axiosConfig";

const url = "/api/project/totalStats";

export const STATISTICS_REQUEST = "STATISTICS_REQUEST";
export const STATISTICS_SUCCESS = "STATISTICS_SUCCESS";
export const STATISTICS_FAIL = "STATISTICS_FAIL";

export function getTotalStatistics() {
  return function (dispatch) {
    dispatch({
      type: STATISTICS_REQUEST,
    });

    fetchRequest(url, {}, "GET")
      .then((response) => {
        if (response.data.status === 0) {
          dispatch({
            type: STATISTICS_SUCCESS,
            payload: response.data.data,
          });
        } else {
          dispatch({
            type: STATISTICS_FAIL,
            error: true,
            payload: new Error(
              "Ошибка запроса данных. Пожалуйста, повторите попытку позже."
            ),
          });
        }
      })
      .catch(() => {
        dispatch({
          type: STATISTICS_FAIL,
          error: true,
          payload: new Error(
            "Ошибка запроса данных. Пожалуйста, повторите попытку позже."
          ),
        });
      });
  };
}
