import { ComponentType, FC, useEffect, useRef, useState } from "react";
import "./SearchList.scss";
import { SearchStyleType } from "../Search";

export interface SearchListProps {
  items: any;
  type?: SearchStyleType;
  selectedItems: any[];
  isMultiplePerRow?: boolean;
  isClearPlaceholderOnSelect?: boolean;
  isCloseOnSelect?: boolean;
  bindTo: string;
  restrictBy?: number;
  onItemSelect: (item) => void;
  replaceInputOnMouseEnter?: boolean;
  inputRef?: any;
}

const SearchList: FC<SearchListProps> = ({
  items,
  type = SearchStyleType.SIMPLE,
  selectedItems,
  isMultiplePerRow = true,
  isClearPlaceholderOnSelect = true,
  isCloseOnSelect = true,
  bindTo,
  restrictBy,
  onItemSelect,
  replaceInputOnMouseEnter,
  inputRef,
}) => {
  const listRef = useRef();
  const [listItems, setListItems] = useState([]);
  const [isShown, setIsShown] = useState(false);
  const [value, setValue] = useState(false);
  const [restrictByNumber, setRestrictByNumber] = useState(restrictBy);
  const [canShowList, setCanShowList] = useState(false);

  useEffect(() => {
    items && setListItems(items)
  }, [items])

  const handleMouseEnter = (item) => {
    if (replaceInputOnMouseEnter && inputRef?.current) {
      inputRef.current.value = bindTo ? item[bindTo] : item
    }
  };

  const handleMouseLeave = () => {
    if (replaceInputOnMouseEnter && inputRef?.current) {
      inputRef.current.value = "";
    }
  };


  const getClassesForList = () => {
    let classes = "cr-search-list";
    if (isMultiplePerRow) classes += " cr-search-list--mutiplePerRow";
    if (type === SearchStyleType.GROUP) {
      classes += " cr-search-list--grouped";
    }
    if (!listItems?.length) {
      classes += " cr-search-list--empty"
    }
    return classes;
  };

  const getClassesForSimpleItem = (item) => {
    let classlist = "cr-search-list__item";
    if (selectedItems && selectedItems.includes(item)) {
      classlist += " cr-search-list__item--selected";
    }
    return classlist;
  };

  const getClassesForGroup = (group) => {
    let classlist = "cr-search-list__group";
    if (group.isExpanded) {
      classlist += " cr-search-list__group--expanded";
    }
    return classlist;
  };

  const onSelectItem = (item) => {
    if (onItemSelect && typeof onItemSelect === "function") {
      const value = isClearPlaceholderOnSelect ? "" : bindTo ? item[bindTo] : item;

      setValue(value);
      setCanShowList(!isCloseOnSelect);
      onItemSelect(item);
    } else {
      console.error("Please provide a callback for Search component");
    }
  };

  const onSelectGroup = (group) => {
    setListItems(
      listItems.map((g) => {
        return { ...g, isExpanded: group.id === g.id ? !g.isExpanded : g.isExpanded };
      })
    );
  };

  const renderSimpleList = (items) => {
    return items.map((item, idx) => (
      <div
        key={idx}
        className={getClassesForSimpleItem(item)}
        onMouseDown={(e) => onSelectItem(item)}
        onMouseEnter={(e) => handleMouseEnter(item)}
        onMouseLeave={(e) => handleMouseLeave()}
      >
        {bindTo ? item[bindTo] : item}
      </div>
    ));
  };

  const renderGroupList = (items) => {
    return items.map((group, idx) => (
      <div className={getClassesForGroup(group)} key={group.id}>
        <div className="cr-search-list__group-title" onMouseDown={(e) => onSelectGroup(group)}>
          {group.title}
          <span className="ic ui-icon-88 arrow-icon"></span>
        </div>
        <div className="cr-search-list__group-items">
            {renderSimpleList(group.items)}
        </div>
      </div>
    ));
  };

  const renderItemsByType = () => {
    const _items = restrictByNumber ? listItems.slice(0, restrictByNumber) : listItems;
    switch (type) {
      case SearchStyleType.GROUP: {
        return renderGroupList(_items);
      }
      default:
        return renderSimpleList(_items);
    }
  };

  return (
    <>
      <div ref={listRef} className={getClassesForList()} onMouseDown={(e) => e.preventDefault()}>
        {renderItemsByType()}
        {listItems.length > restrictByNumber && (
          <div className="cr-search-list__dots" onClick={() => setRestrictByNumber(Infinity)}>
            ...
          </div>
        )}
      </div>
    </>
  );
};

export default SearchList;
