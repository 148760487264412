import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import BirthdayEventsService from "../../../../../../services/birthday-events.service";
import {
  hideInfoModal,
  showInfoModal,
} from "../../../../../../store/actions/LayoutActions";
import { ApiStatusCode } from "../../../../../../types/Common.interface";
import { RootState } from "../../../../../../types/State.interface";
import SubmitForm from "../../../../../containers/Forms/SubmitForm/SubmitForm";
import Button from "../../../../../presentational/Controls/Button/Button";
import Checkbox from "../../../../../presentational/Controls/Checkbox/Checkbox";
import FileSelector from "../../../../../presentational/Controls/FileSelector/FileSelector";
import Input from "../../../../../presentational/Controls/Input/Input";
import Textarea from "../../../../../presentational/Controls/Textarea/Textarea";
import { BirthdayUrls } from "../../../CrowdBirthday9.helper";

import "./SendCongratForm.scss";

export interface SendCongratFormProps {
  isOpen: boolean;
  onClose: () => void;
}

const SendCongratForm = ({ isOpen, onClose }: SendCongratFormProps) => {
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user.userDetails);

  const [firstName, setFirstName] = useState("");
  const [firstNameValidator, setFirstNameValidator] = useState(null);

  const [lastName, setLastName] = useState("");
  const [lastNameValidator, setLastNameValidator] = useState(null);

  const [congratulation, setCongratulation] = useState("");
  const [congratulationValidator, setCongratulationValidator] = useState(null);

  const [images, setImages] = useState(null);

  const [consent, setConsent] = useState(false);

  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const [settings, setSettings] = useState([]);

  useEffect(() => {
    setLoading(true);
    BirthdayEventsService.getSettings()
      .then((res) => {
        if (res.status === ApiStatusCode.OK) {
          const settings = res.data;
          if (settings.length) {
            setSettings(settings);
          } else {
            dispatch(showInfoModal("Мероприятия не настроены."));
            onClose();
          }
        }
      })
      .catch(console.log)
      .finally(() => setLoading(false));
  }, []);

  const submitForm = (e) => {
    if (e) e.preventDefault();

    setSubmitted(true);
    if (formValid()) {
      setSubmitting(true);

      const formData = {
        firstName: firstName.trim(),
        lastName: lastName.trim(),
        text: congratulation.trim(),
        settingId: settings?.[settings.length - 1].id,
        attachmentId: images[0].id,
      };

      setSubmitting(true);
      BirthdayEventsService.save(formData)
        .then((res) => {
          if (res.status === ApiStatusCode.OK) {
            dispatch(
              showInfoModal(
                "Спасибо! Ваше поздравление отправлено.",
                <Button
                  type="outlined"
                  text="Хорошо"
                  onClick={() => dispatch(hideInfoModal())}
                />
              )
            );
            onClose();
          }
        })
        .catch(console.log);
    }
  };

  const formValid = () => {
    return (
      firstNameValidator.isValid() &&
      lastNameValidator.isValid() &&
      congratulationValidator.isValid() &&
      images?.length
    );
  };

  const renderContent = () => {
    return (
      <div className="send-congrat-form">
        <Input
          label="Ваше имя"
          placeholder="Напишите ваше имя"
          isRequired={true}
          value={firstName}
          onChange={(value) => setFirstName(value)}
          validateRules={{
            minLength: 2,
            maxLength: 30,
          }}
          onInitValidator={(validator) => setFirstNameValidator(validator)}
          showError={submitted}
        />

        <Input
          label="Ваша фамилия"
          placeholder="Напишите вашу фамилию"
          isRequired={true}
          value={lastName}
          onChange={(value) => setLastName(value)}
          validateRules={{
            minLength: 2,
            maxLength: 30,
          }}
          onInitValidator={(validator) => setLastNameValidator(validator)}
          showError={submitted}
        />

        <Textarea
          value={congratulation}
          label="Ваше поздравление"
          placeholder="Напишите ваше поздравление"
          maxLength={160}
          isRequired={true}
          onChange={(value) => setCongratulation(value)}
          validateRules={{
            minLength: 5,
            maxLength: 160,
          }}
          hint={
            "Пожалуйста, придерживайтесь правила: один пользователь — одно поздравление. Текст поздравления не должен превышать 160 символов. Поздравления, прошедшие модерацию, могут быть отредактированы и будут опубликованы на праздничной странице."
          }
          onInitValidator={(validator) => setCongratulationValidator(validator)}
          showError={submitted}
        />

        <FileSelector
          upload={true}
          isInvalid={submitted && !images?.length}
          name="Загрузить фото"
          text={`Загрузите ваше личное фото для размещения на праздничной странице «Города идей». Фото может быть в формате *jpeg, *jpg, *png и не должно превышать размера 5 Мб.`}
          maxFiles={1}
          allowedExtensions={["image/jpeg", "image/jpg", "image/png"]}
          maxTotalSizeMB={5}
          onChange={(images) => setImages(images)}
        />

        <Checkbox
          html={`Я ознакомился с <a href='${BirthdayUrls.CONGRATULATION_RULES}' target='_blank'>Правилами направления и обработки поздравлений</a> и <a href='/license' target='_blank'>Пользовательским соглашением</a>.`}
          onChange={(value) => setConsent(value)}
          isInvalid={(submitted && !consent) || false}
        />
      </div>
    );
  };

  return (
    <SubmitForm
      user={user}
      isLoading={loading}
      isSubmitting={submitting}
      isOpen={isOpen}
      title="Поздравление для «Города идей»"
      onClose={onClose}
      onSubmit={submitForm}
    >
      {renderContent()}
    </SubmitForm>
  );
};

export default SendCongratForm;
