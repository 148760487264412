import React, { useState } from "react";

// type Props = {
//   src: string,
//   alt: string,
//   defaultSrc: string,
// };

const Image = (props) => {
  const [imgError, setImgError] = useState(false);
  const defaultSrc =
    props.defaultSrc || require("../../../assets/logo/default/horizontal.png");
  const alt = props.alt || "Изображение";

  const renderImage = () => {
    if (props.src && !imgError) {
      return (
        <img src={props.src} alt={alt} onError={() => setImgError(true)} />
      );
    } else {
      return <img src={defaultSrc} alt={alt} />;
    }
  };

  return <div>{renderImage()}</div>;
};

export default Image;
