import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";

import { Video } from "../../../types/Video.interface";
import VideoCard from "../../presentational/Cards/VideoCard/VideoCard";
import CardGridPageGenerate from "../../presentational/CardGridPageGenerate/CardGridPageGenerate";

import "./VideoPage.scss";
import VideoViewer from "../../containers/Modals/VideoViewer/VideoViewer";
import GridView from "../../presentational/GridView/GridView";
import { useSelector } from "react-redux";
import { RootState } from "../../../types/State.interface";
import useUpdateEffect from "../../../hooks/useUpdateEffect";
import VideoService from "../../../services/videoService";

export interface VideoPageProps {
  screenWidth: any;
}

const VideoPage = ({ screenWidth }: VideoPageProps) => {
  let history = useHistory();

  const isMountedRef = useRef(false);

  const [gridViewInstance, setGridViewInstance] = useState<GridView | null>(
    null
  );
  const [videoViewerIsOpened, setVideoViewerIsOpened] =
    useState<boolean>(false);
  const [selectedVideo, setSelectedVideo] = useState<Video | null>(null);
  const tags = useSelector((state: RootState) => state.videos.tags);

  const openVideoModal = (video: Video) => {
    setVideoViewerIsOpened(true);
    setSelectedVideo(video);
  };

  const closeVideoModal = () => {
    setVideoViewerIsOpened(false);
    setSelectedVideo(null);
  };

  const setPrevVideo = async () => {
    if (!gridViewInstance) return;

    const videos = gridViewInstance.getItems();
    const index = videos.indexOf(selectedVideo as Video);

    if (!~index || index === 0) {
      await gridViewInstance.slidePrev();
      const videos = gridViewInstance.getItems();
      setSelectedVideo(videos[videos.length - 1]);
      return;
    }

    setSelectedVideo(videos[index - 1]);
  };

  const setNexVideo = async () => {
    if (!gridViewInstance) return;

    const videos = gridViewInstance.getItems();
    const index = videos.indexOf(selectedVideo as Video);

    if (!~index) {
      setSelectedVideo(videos[0]);
      return;
    }

    if (index === videos.length - 1) {
      await gridViewInstance.slideNext();
      const videos = gridViewInstance.getItems();
      setSelectedVideo(videos[0]);
      return;
    }

    setSelectedVideo(videos[index + 1]);
    console.log(selectedVideo);
  };

  const handleTagClick = (video) => {
    closeVideoModal();
    history.location.state = { item: video };
  };

  const canSlidePrev = () => {
    if (!gridViewInstance) return false;

    const videos = gridViewInstance.getItems();
    const index = videos.indexOf(selectedVideo as Video);

    return (~index && index > 0) || gridViewInstance.grid.page !== 0;
  };

  const canSlideNext = () => {
    if (!gridViewInstance) return false;

    const videos = gridViewInstance.getItems();
    const index = videos.indexOf(selectedVideo as Video);

    return (
      (~index && index < videos.length - 1) || gridViewInstance.canSlideNext()
    );
  };

  useEffect(() => {
    if (!gridViewInstance || isMountedRef.current) {
      return;
    }

    isMountedRef.current = true;

    (async () => {
      const vid = new URLSearchParams(window.location.search).get("vid");
      if (vid) {
        let items = gridViewInstance.getItems();
        if (!items.length) {
          await gridViewInstance.grid.update();
          items = gridViewInstance.getItems();
        }

        let item = items.find((v) => v.id === vid);
        if (!item) {
          // видео уже на другой странице, надо отдельно запросить данные по vid
          const { data } = await VideoService.getList({ ids: [vid] });
          item = data[0];
        }
        if (!item) {
          console.error(`Не найдено видео на странице: id - ${vid}`);
          return;
        }

        if (!~items.indexOf(item)) {
          const finded = items.find((v) => v.id === item.id);
          if (finded) {
            item = finded;
          }
        }

        isMountedRef.current && openVideoModal(item);
      }
    })();

    return () => {
      isMountedRef.current = false;
    };
  }, [gridViewInstance]);

  useUpdateEffect(() => {
    /**
     * параметры достаем через window.location вместо history.location,
     * тк RestGrid манипулирует window.location и данные с history.location могут отличаться
     */
    const search = new URLSearchParams(window.location.search);
    if (selectedVideo?.id) {
      search.set("vid", String(selectedVideo.id));
    } else {
      search.delete("vid");
    }
    history.push({
      pathname: window.location.pathname,
      search: search.toString(),
    });
  }, [history, selectedVideo]);

  const title = "«Город идей» — Видео";
  const description = "Смотрите видео о проектах, реализованных идеях и важных событиях в работе платформы «Город идей».";
  return (
    <>
      <CardGridPageGenerate
        section="video"
        title={title}
        description={description}
        opengraph={{title, description}}
        pageTitle="Видео"
        pageDescription="В этом разделе публикуются видео о проектах, реализованных идеях и важных событиях в работе платформы Правительства Москвы «Город идей»."
        endpoint="/api/video/list?statuses=PUBLISHED,PUBLISHED_MAIN&sort=uploaded,desc&"
        screenWidth={screenWidth}
        tags={tags}
        onInit={setGridViewInstance}
      >
        <VideoCard onClick={openVideoModal} />
      </CardGridPageGenerate>

      {selectedVideo && (
        <VideoViewer
          video={selectedVideo}
          isOpened={videoViewerIsOpened}
          canSlidePrev={canSlidePrev()}
          canSlideNext={canSlideNext()}
          onSlideNext={setNexVideo}
          onSlidePrev={setPrevVideo}
          onTagClick={handleTagClick}
          onClose={() => {
            setVideoViewerIsOpened(false);
            setSelectedVideo(null);
          }}
        />
      )}
    </>
  );
};

export default VideoPage;
