import React, { Component, ReactNode } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../types/State.interface";

import "./FlippableCard.scss";
import classnames from "classnames";

interface FlippableCardProps {
  className?: string;
  front: ReactNode;
  back: ReactNode;
  flipBackOnTap?: boolean;
  id?: string
}

const FlippableCard = ({ front, back, className, flipBackOnTap, id }: FlippableCardProps) => {
  const [flipped, setFlipped] = useState(false)
  const screenWidth = useSelector((state: RootState) => state.globalEvents.width);
  const isMobileOrTablet = screenWidth <= 960
  const toggleFlip = () => {
    setFlipped(!flipped);
  };

  return (
    <div
      id={id}
      className={classnames("flippable-card", className)}
      onClick={(flipBackOnTap && isMobileOrTablet) ? toggleFlip : undefined}
    >
      <div
        className="flippable-card__content"
        style={{
          transform: flipBackOnTap && isMobileOrTablet
            ? `rotateY(${flipped ? 180 : 0}deg)`
            : undefined
        }}
      >
        <div className="flippable-card__face flippable-card__front">{front}</div>
        <div className="flippable-card__face flippable-card__back">{back}</div>
      </div>
    </div>
  );
};

export default FlippableCard;
