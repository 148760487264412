const CompleteUserGuard = (state) => {
  return {
    passedGuard: state.user.loggedIn
      ? !state.user.userDetails.partiallyFilled
      : true,
    redirectIfFail: "/",
  };
};

export default CompleteUserGuard;
