import React, { Component } from "react";

import LoggerService from "../../../../services/loggerService";
import SomethingWentWrong from "../../../pages/SomethingWentWrong/SomethingWentWrong";

interface State {
  hasError: boolean;
  error: any;
}

class ErrorBoundary extends React.Component<any, State> {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      hasError: false,
    };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, stackTrace) {
    this.setState({ error });
    LoggerService.registerError(error);
  }

  render() {
    if (this.state.hasError) {
      return <SomethingWentWrong />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
