import { forwardRef } from "react";

const Line1 = forwardRef<SVGPathElement>((_, ref) => (
  <svg
    width="1482"
    height="1125"
    viewBox="0 0 1482 1125"
    fill="none"
    style={{ transform: "translate(25px, 0px)" }}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      ref={ref}
      d="M103.348 1C46.846 21.6765 -32.1805 113.373 15.8446 203.226C84.8477 332.328 249.07 325.555 350.86 299.548C500.866 261.221 653.565 122.033 640.372 47.9003C629.872 -11.1033 475.909 75.1343 689.875 181.327C942.886 306.899 1161.54 253.892 1325.4 415.324C1605.42 691.178 1445.41 1066.01 1325.4 1124"
      stroke="#252526"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
));

export default Line1;
