// @flow

import React, { Component } from "react";
import { connect } from "react-redux";
import ReactCrop from "react-image-crop";
import "react-image-crop/lib/ReactCrop.scss";
import imageCompression from "browser-image-compression";
import { AppModal } from "../../Modal/AppModal";
import Button from "../../../presentational/Controls/Button/Button";
import UserService from "../../../../services/userService";
import { SUCCESS } from "../../../../types/statusCodes";
import {
  hideInfoModal,
  showInfoModal,
} from "../../../../store/actions/LayoutActions";

import "./CropAvatarModal.scss";
import { User } from "../../../../types/User.interface";

type Props = {
  file: any;
  onClose: Function;
  onSwitchModals: Function;
  onUploadUserAvatar?: Function;
  onSuccess: Function;
  isOpened: boolean;
};

type State = {
  croppedImageUrl: string;
  type: string;
  src: string;
  crop: {
    unit: string;
    width: number;
    height: number;
    aspect: number;
    x?: number;
    y?: number;
  };
  user?: Object;
  error: string;
  minWidth: number;
  minHeight: number;
  MAX_SIZE_FILE: number;
  firstChange: boolean;
  compressOptions: {
    maxSizeMB: number;
    maxWidthOrHeight: number;
  };
  isLoading?: boolean;
};

class CropAvatarModal extends Component<any, State> {
  imageRef: any;
  fileUrl: string = "";

  constructor(props) {
    super(props);

    this.state = {
      firstChange: true,
      croppedImageUrl: "",
      type: "",
      src: "",
      crop: {
        unit: "%",
        width: 30,
        height: 30,
        aspect: 1,
      },
      compressOptions: {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
      },
      error: "",
      minWidth: 160,
      minHeight: 160,
      MAX_SIZE_FILE: 1048576,
      isLoading: false,
    };
  }

  async componentDidMount() {
    const { file } = this.props;
    const { MAX_SIZE_FILE, compressOptions } = this.state;

    if (file) {
      const resultedFile =
        file.size >= MAX_SIZE_FILE
          ? await imageCompression(file, compressOptions)
          : file;
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        this.setState({
          type: resultedFile.type,
          src: reader.result as string,
        })
      );
      reader.readAsDataURL(resultedFile);
    }
  }

  closeCropAvatarModal = () => {
    this.props.onClose();
  };

  switchModals = () => {
    this.props.onSwitchModals();
  };

  updateAvatar = () => {
    const { type, src } = this.state;
    let { x, y, height, width } = this.state.crop;

    x = ((x || 0) * this.imageRef.naturalWidth) / 100;
    y = ((y || 0) * this.imageRef.naturalHeight) / 100;
    height *= this.imageRef.naturalHeight / 100;
    width *= this.imageRef.naturalWidth / 100;

    const params = {
      coords: {
        x1: Math.floor(x),
        x2: Math.floor(x + width),
        y1: Math.floor(y),
        y2: Math.floor(y + height),
      },
      type,
      src,
    };

    this.setState({ isLoading: true });
    UserService.uploadPicture(params)
      .then((res) => {
        if (res.status === SUCCESS) {
          this.props.onSuccess(res.data);
          this.props.onClose();
        } else {
          this.setState({
            error: res.message,
          });
        }
      })
      .catch((err) => {
        this.setState({
          error: err.message,
        });
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  onImageLoaded = (image) => {
    this.imageRef = image;
  };

  okButton = () => {
    return (
      <Button
        text="Хорошо"
        type="outlined"
        onClick={() => {
          this.props.hideInfoModal();
          this.props.onClose();
        }}
      />
    );
  };

  onImageError = (error) => {
    this.props.onClose();
    this.props.showInfoModal("Некорректные входные данные.", this.okButton());
  };

  onCropComplete = (crop) => {
    this.makeClientCrop(crop);
  };

  get centeredCropCoords() {
    const cropHeightPx = (this.imageRef.width / 100) * this.state.crop.width;
    const cropHeightPct =
      cropHeightPx > this.imageRef.height
        ? 100
        : cropHeightPx / (this.imageRef.height / 100);
    return (
      this.state.firstChange && {
        y: (100 - cropHeightPct) / 2,
        x: (100 - this.state.crop.width) / 2,
      }
    );
  }

  onCropChange = (crop, percentCrop) => {
    this.setState({ error: "" });
    if (
      crop.width < this.state.minWidth ||
      crop.height < this.state.minHeight
    ) {
      this.setState({
        error: `Минимальная ширина должна быть - ${this.state.minWidth}${crop.unit}`,
      });
    }
    this.setState({
      crop: {
        ...percentCrop,
        ...this.centeredCropCoords,
      },
      firstChange: false,
    });
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl: any = (await this.getCroppedImg(
        this.imageRef,
        crop,
        "newFile.jpeg"
      )) as string;
      this.setState({
        croppedImageUrl,
      });
    }
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx?.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve) => {
      canvas.toBlob((blob: any) => {
        if (!blob) {
          console.error("Canvas is empty");
          return;
        }
        (blob as any).name = fileName;
        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve(this.fileUrl);
      }, "image/jpeg");
    });
  }

  getClassesForModal() {
    let classes = "crop-avatar";

    if (this.state.isLoading) {
      classes += " crop-avatar-disabled";
    }

    return classes;
  }

  render() {
    const { crop, src, minWidth, minHeight } = this.state;

    return (
      <AppModal
        center
        classNames={{
          modal: "crop-avatar-modal",
        }}
        overlayId="modal-overlay"
        closeIconId="close-modal-icon"
        blockScroll={true}
        closeIconSize={25}
        open={!!this.props.isOpened}
        closeOnEsc={false}
        closeOnOverlayClick={false}
        onClose={this.props.onClose}
      >
        <div className={this.getClassesForModal()}>
          <h3 className="crop-avatar__title">Выбор фотографии</h3>

          {this.state.error && (
            <p className="crop-avatar__error cr-red">{this.state.error}</p>
          )}

          <div className="crop-avatar__content">
            {src && (
              <ReactCrop
                src={src}
                crop={crop}
                minWidth={minWidth}
                minHeight={minHeight}
                circularCrop={true}
                onImageLoaded={this.onImageLoaded}
                onImageError={this.onImageError}
                onComplete={this.onCropComplete}
                onChange={this.onCropChange}
              />
            )}
          </div>

          <div className="crop-avatar__buttons">
            <Button type="outlined" text="Назад" onClick={this.switchModals} />

            <Button
              type="outlined"
              text="Отменить"
              onClick={this.closeCropAvatarModal}
            />

            <Button
              type="outlined"
              text="Сохранить"
              isDisabled={!!this.state.error}
              isLoading={this.state.isLoading}
              onClick={this.updateAvatar}
            />
          </div>
        </div>
      </AppModal>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  showInfoModal: (message, jsx, onDismiss) =>
    dispatch(showInfoModal(message, jsx, onDismiss)),
  hideInfoModal: () => dispatch(hideInfoModal()),
});

export default connect(null, mapDispatchToProps)(CropAvatarModal);
