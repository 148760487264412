import { FC } from "react";
import "./Sign.scss";

interface Props {
  name: string;
  position: string;
}

const Sign: FC<Props> = ({ name, position }) => (
  <div className="discussion-landing-sign">
    <div className="discussion-landing-sign__name">{name}</div>
    <div className="discussion-landing-sign__position">{position}</div>
  </div>
);

export default Sign;
