import React, { Component, useEffect } from "react";
import { Link } from "react-router-dom";

import { hideElement } from "../../../utils";

import "./NotFoundPage.scss";

const NotFoundPage = (props) => {
  useEffect(() => {
    const cleanUpHeader = hideElement(".cr-header-link", true);
    const cleanUpFooter = hideElement("footer");
    const cleanUpChat = hideElement(".webchat-icon");

    return () => {
      cleanUpHeader();
      cleanUpFooter();
      cleanUpChat();
    };
  }, []);

  const goMain = () => {
    props.history.push("/");
  };

  const goBack = () => {
    props.history.goBack();
  };

  return (
    <div className="not-found-page">
      <div className="not-found-page__inner">
        <p className="not-found-page__hint">Что-то пошло не так…</p>
        <h1>404</h1>
        <p className="not-found-page__redirect">
          Страница не найдена. <br />
          Начните с <Link to="/">главной</Link>.
        </p>

        <span className="not-found-page__decoration decoration-type-1">?</span>
        <span className="not-found-page__decoration decoration-type-2">?</span>
        <span className="not-found-page__decoration decoration-type-3">#</span>
        <span className="not-found-page__decoration decoration-type-4">#</span>
      </div>
    </div>
  );
};

export default NotFoundPage;
