import Game from "./Game/Game";
import "./QuizGame.scss";
import { MosProjects } from "../../../../../types/Projects.interface";
import GameButton from "../Buttons/GameButton";
import { useEffect, useRef, useState } from "react";
import NewYear2023Service from "../../Services/newyear2023-service";
import { GameStatus } from "../../../../presentational/Game/Game.interface";
import Loader from "../../../../presentational/Loaders/Loader/Loader";

interface SudokuGameProps {
  onWin?: () => void;
}

const QuizGame: React.FC<SudokuGameProps> = ({ onWin }) => {
  const [isStarted, setIsStarted] = useState(false);
  const [isWin, setIsWin] = useState(false);
  const [isLoose, setIsLoose] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const statusRef = useRef({ started: false, win: false, loose: false });
  statusRef.current = { started: isStarted, win: isWin, loose: isLoose };

  const handleLoose = () => {
    setIsStarted(false);
    setIsLoose(true);
    fetchFinishGame(GameStatus.LOST);
  };

  const handleWin = () => {
    setIsStarted(false);
    setIsWin(true);
    onWin();
  };

  useEffect(() => {
    setIsLoading(true);
    NewYear2023Service.status([MosProjects.AG])
      .then((response) => {
        const [gameRes] = response.data || [];
        if (gameRes?.status === GameStatus.WON) {
          setIsWin(true);
        } else if (gameRes?.status === GameStatus.IN_PROGRESS) {
          fetchFinishGame(GameStatus.LOST);
        }
      })
      .catch(console.log)
      .finally(() => setIsLoading(false));

    return () => {
      const { started, win, loose } = statusRef.current;
      if (started && !win && !loose) fetchFinishGame(GameStatus.LOST);
    };
  }, []);

  const fetchFinishGame = (status: GameStatus) => {
    NewYear2023Service.finishGame({ type: MosProjects.AG, status: status }).catch(console.log);
  };

  const startGame = () => {
    setIsStarted(true);
    setIsLoose(false);
  };

  const renderLooseInfo = () => {
    return (
      <div className={"quiz__container"}>
        <div className={"quiz__icon quiz__icon--loose"} />
        <p className={"quiz__title"}>Увы!</p>
        <p className={"quiz__subtitle quiz__subtitle--limited"}>К сожалению, игра не пройдена. Попробуйте еще раз!</p>
        <GameButton onClick={startGame}>НАЧАТЬ ИГРУ</GameButton>
      </div>
    );
  };

  const renderWinInfo = () => {
    return (
      <div className={"quiz__container"}>
        <div className={"quiz__icon quiz__icon--win"} />
        <p className={"quiz__title"}> Поздравляем!</p>
        <p className={"quiz__subtitle quiz__subtitle--win quiz__subtitle--limited"}>
          Вы выиграли. Баллы за победу в игре будут начислены пользователям с полной учетной записью mos.ru
        </p>
      </div>
    );
  };

  const renderStartedInfo = () => {
    return (
      <div className={"quiz__container"}>
        <p className={"quiz__title"}>Викторина</p>
        <div className={"quiz__subtitle"}>
          Проверьте свои знания о проекте «Активный гражданин»: ответьте правильно на 10 вопросов подряд и получите{" "}
          <a href="new_year2024/Правила_участия_в_квесте.pdf" target="_blank">
            100 баллов
          </a>
          !
        </div>
        <GameButton onClick={startGame}>НАЧАТЬ ИГРУ</GameButton>
      </div>
    );
  };

  const renderGameStatus = () => {
    if (isWin) return renderWinInfo();
    if (isLoose) return renderLooseInfo();
    if (isStarted) return <Game onWin={handleWin} onLoose={handleLoose} />;
    return renderStartedInfo();
  };

  return (
    <div className={"quiz"}>
      {!isLoading ? (
        renderGameStatus()
      ) : (
        <div className={"quiz__loader"}>
          <Loader />
        </div>
      )}
    </div>
  );
};
export default QuizGame;
