import { FC, useEffect, useRef } from "react";
import "./ScreenLoader.scss";
import { ScreenLoaderProps } from "./ScreenLoader.types";

const ScreenLoader: FC<ScreenLoaderProps> = ({ progress }) => {
  const barRef = useRef<HTMLDivElement>();
  const starRef = useRef<HTMLDivElement>();
  const treeRef = useRef<HTMLDivElement>();

  useEffect(() => {
    const fillHeight = barRef.current.clientHeight * progress;
    treeRef.current
      .querySelector("span")
      .style.setProperty("height", `${Math.min(treeRef.current.clientHeight, fillHeight)}px`);
    starRef.current
      .querySelector("span")
      .style.setProperty(
        "height",
        `${Math.max(starRef.current.clientHeight - (barRef.current.clientHeight - fillHeight), 0)}px`
      );
  }, [progress]);

  return (
    <div className="screen-loader">
      <div className="screen-loader__bar" ref={barRef}>
        <div className="screen-loader__bar__star" ref={starRef}>
          <span />
        </div>
        <div className="screen-loader__bar__tree" ref={treeRef}>
          <span />
        </div>
      </div>
    </div>
  );
};

export default ScreenLoader;
