import { GorodIdeaSphere } from "../../services/gorodService";

export interface GorodState {
    spheres: GorodIdeaSphere[];
    isLoading: boolean;
}

const initialState: GorodState = {
  spheres: null,
  isLoading: false
};

export function gorodReducer(state: GorodState = initialState, action) {
  switch (action.type) {
    case 'GET_SPHERES_REQUEST':
        return {
          ...state,
          isLoading: true,
        };

    case 'GET_SPHERES':
      return {
        ...state,
        spheres: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
}
