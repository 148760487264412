import React from "react";
import { Link } from "react-router-dom";

import "./IconButton.scss";

interface Props {
  text: string;
  icon?: string;
  linkUrl?: string;
  externalLink?: Boolean;
  onClick?: Function;
}

const IconButton = (props: Props) => {
  const onClick = () => {
    if (props.onClick && typeof props.onClick === "function") {
      props.onClick();
    }
  };

  return (
    <div
      className="cr-icon-button"
      style={{ justifyContent: (!props.icon && "center") || "normal" }}
      onClick={onClick}
    >
      {props.externalLink
        ? props.linkUrl && (
            <a href={props.linkUrl} className="cr-icon-button__link" />
          )
        : props.linkUrl && (
            <Link to={props.linkUrl} className="cr-icon-button__link" />
          )}

      {props.icon && (
        <div className="cr-icon-button__icon">
          <div className={"ic " + props.icon}></div>
        </div>
      )}

      <div className="cr-icon-button__text">{props.text}</div>
    </div>
  );
};

export default IconButton;
