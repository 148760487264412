import React, { useEffect, useState } from "react";
import { AppModal } from "../../../../../containers/Modal/AppModal";
import "./DiscussionEventRegistrationSuccessModal.scss";

export interface DiscussionEventRegistrationModalProps {
  text: string;
  onClose: () => void;
}

const DiscussionEventRegistrationSuccessModal = ({ text, onClose }: DiscussionEventRegistrationModalProps) => {
  return (
    <AppModal
      center
      classNames={{ modal: "discussion-event-success-modal discussion-landing-modal" }}
      overlayId="modal-overlay"
      closeIconId="close-modal-icon"
      blockScroll={true}
      closeIconSize={25}
      open={true}
      closeOnEsc={true}
      closeOnOverlayClick={false}
      onClose={onClose}
    >
      <div className="discussion-event-success-modal__title">Вы успешно зарегистрированы.</div>
      <div className="discussion-event-success-modal__body">{text}</div>
    </AppModal>
  );
};

export default DiscussionEventRegistrationSuccessModal;
