import { ComponentType, InputHTMLAttributes } from "react";

import "./Checkbox.scss";

interface Props {
  html?: string;
  onChange?: (checked: boolean) => void;
  isDisabled?: boolean;
  isInvalid?: boolean;
  defaultValue?: boolean;
  ComponentCheckbox?: ComponentType<InputHTMLAttributes<HTMLInputElement> & { html: Props["html"] }>
}

const Checkbox = (props: Props) => {
  const handleOnChange = (event) => {
    event.stopPropagation();

    if (props.onChange && typeof props.onChange === "function") {
      props.onChange(event.target.checked);
    } else {
      console.error("Please provide a callback for Checkbox component");
    }
  };

  const checkboxProps: InputHTMLAttributes<HTMLInputElement> = {
    type: "checkbox",
    className: `cr-checkbox__input ${
      props.isInvalid ? "cr-checkbox__input-invalid" : ""
    }`,
    checked: props.defaultValue,
    onChange: handleOnChange
  };

  const { ComponentCheckbox } = props;
  return (
    <div className="cr-checkbox">
      <label>
        {ComponentCheckbox
          ? <ComponentCheckbox html={props.html} {...checkboxProps} />
          : <input {...checkboxProps} />
        }
        {props.html && (
          <span dangerouslySetInnerHTML={{ __html: props.html }} />
        )}
      </label>
    </div>
  );
};

export default Checkbox;
