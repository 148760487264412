// @flow

// CHECK IF WE NEED THIS REDUCER

export interface PersistedState {
  formAfterPageReload: string;
  formAfterPageReloadData: object | null;
}

const initialState = {
  formAfterPageReload: "",
  formAfterPageReloadData: null,
};

export function persistedReducer(state: PersistedState = initialState, action) {
  switch (action.type) {
    case "SHOW_FORM_AFTER_PAGE_RELOAD":
      return {
        ...state,
        formAfterPageReload: action.form,
        formAfterPageReloadData: action.data,
      };

    case "CLEAR_PAGE_RELOAD_FORM":
      return {
        ...state,
        formAfterPageReload: "",
        formAfterPageReloadData: null,
      };

    default:
      return state;
  }
}
