// @flow
import { Photo, PhotoState } from "../../types/Photo.interface";
import {
  PHOTOS_LOAD_HANDLE,
  PHOTOS_LOAD_SUCCESS,
  PHOTOS_LOAD_FAIL,
} from "../actions/PhotosActions";

const initialState = {
  photos: null,
  error: "",
  isFetching: false,
};

export function photosReducer(state: PhotoState = initialState, action) {
  switch (action.type) {
    case PHOTOS_LOAD_HANDLE:
      return { ...state, isFetching: true, error: "" };

    case PHOTOS_LOAD_SUCCESS:
      return { ...state, isFetching: false, photos: action.payload };

    case PHOTOS_LOAD_FAIL:
      return { ...state, isFetching: false, error: action.payload.message };

    default:
      return state;
  }
}
