// @flow

import React, { useState, useEffect, useMemo, Fragment, forwardRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import SubmitForm from "../SubmitForm/SubmitForm";
import Button from "../../../presentational/Controls/Button/Button";
import Input from "../../../presentational/Controls/Input/Input";
import Select from "../../../presentational/Controls/Select/Select";
import Textarea from "../../../presentational/Controls/Textarea/Textarea";
import FileSelector from "../../../presentational/Controls/FileSelector/FileSelector";
import Checkbox from "../../../presentational/Controls/Checkbox/Checkbox";
import IdeaService from "../../../../services/ideaService";
import { getProjects } from "../../../../store/actions/IdeasActions";
import { hideInfoModal, showInfoModal } from "../../../../store/actions/LayoutActions";
import "./SendIdeaForm.scss";
import { RootState } from "../../../../types/State.interface";
import { UserTypeEnum } from "../../../../types/User.interface";
import EventsTrackClickCheckbox from "../../EventsTrack/wrappers/helpers/EventsTrackClickCheckbox";
import EventsTrackClickTextarea from "../../EventsTrack/wrappers/helpers/EventsTrackClickTextarea";
import EventsTrackClickInput from "../../EventsTrack/wrappers/helpers/EventsTrackClickInput";
import EventsTrackClickFileSelectorLabel from "../../EventsTrack/wrappers/helpers/EventsTrackClickFileSelectorLabel";
import EventsTrackClickSelectInput from "../../EventsTrack/wrappers/helpers/EventsTrackClickSelectInput";
import EventsTrackClickSelectItem from "../../EventsTrack/wrappers/helpers/EventsTrackClickSelectItem";
import SendSuccessModal from "../../Modals/SendSuccessModal/SendSuccessModal";
import { PhotoIdeaAddressType } from "../../../../types/Photo.interface";
import Map from "../../../presentational/Map/Map";
import DynamoList from "../../../presentational/Controls/DynamoList/DynamoList";
import EventsTrackWrapperClick from "../../EventsTrack/wrappers/EventsTrackWrapperClick";

const maxTotalSizeMB = 15;
const maxFiles = 3;
const maxAllowedCommentLength = 200;

const SelectInputWrapper = forwardRef<HTMLInputElement>((props, ref) => (
  <EventsTrackClickInput ref={ref} {...props} label="Начните вводить адрес" />
));

const SendIdeaForm = (props) => {
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user.userDetails);
  const projectsStore = useSelector((state: RootState) => state.ideas.projects);
  const projects = props.projectsList || projectsStore;
  const projectsForSelect = useMemo(
    () =>
      projects?.map((project) => ({
        ...project,
        title: project.projectTitle || project.categoryTitle,
      })),
    [projects]
  );

  // ключ для ремонтирования селектов для сброса значений на предустановленные
  const [selectPresetTriggerCount, setSelectPresetTriggerCount] = useState(0);

  const [selectedProject, setSelectedProject] = useState<any>(null);
  const [idea, setIdea] = useState<any>(null);
  const [address, setAddress] = useState("");
  const [addressValidator, setAddressValidator] = useState<any>(null);
  const [specifyAddressValidator, setSpecifyAddressValidator] = useState<any>(null);
  const [mapAddress, setMapAddress] = useState("");
  const [mapErr, setMapErr] = useState("");
  const [images, setImages] = useState<any[]>([]);
  const [comment, setComment] = useState("");
  const [commentValidator, setCommentValidator] = useState<any>(null);
  const [agreed, setAgreed] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [selectCustomInputUsed, setSelectCustomInputUsed] = useState(false);
  const [openSuccessModal, setOpenSuccessModal] = useState("");
  const submitForm = (e) => {
    if (e) e.preventDefault();

    setSubmitted(true);
    if (formValid()) {
      setSubmitting(true);

      const formData = {};
      formData["ideaItemId"] = idea.id;
      formData["attachmentIds"] = images.map((img) => img.id);
      if (idea.addressType !== PhotoIdeaAddressType.EMPTY) formData["address"] = address;
      if (comment.length > 0) formData["comment"] = comment;

      IdeaService.sendIdea(formData)
        .then((response) => {
          if (response && response.status === 0) {
            const getTextContent = () => {
              let baseMessage = "Фотография отправлена.";

              if (!user?.ssoid) return baseMessage;

              if (user.userType === UserTypeEnum.FULL) {
                return `${baseMessage} В случае успешного прохождения модерации за одну опубликованную фотографию по каждой идее вам будут начислены <a href="https://crowd.mos.ru/pointsRules" target="_blank">баллы</a> (<b>не более чем за 10 фотографий в месяц</b>).`;
              }

              if (user.userType === UserTypeEnum.STANDARD) {
                return `${baseMessage} В случае успешного прохождения модерации за одну опубликованную фотографию по каждой идее вам будут начислены <a href="https://crowd.mos.ru/pointsRules" target="_blank">баллы</a> (<b>не более чем за 10 фотографий в месяц</b>).`;
              }

              if (user.userType === UserTypeEnum.SHORT) {
                return `${baseMessage} При наличии полной или стандартной учетной записи на портале <a href="https://my.mos.ru/my/#/" target="_blank">mos.ru</a> вы сможете получать <a href="https://crowd.mos.ru/pointsRules" target="_blank">баллы</a> за опубликованные фото (<b>за одну опубликованную фотографию по каждой идее, но не более чем за 10 фотографий в месяц</b>).`;
              }

              return baseMessage;
            };

            const message = `
              <div> 
                <p class="modal-title">Спасибо!<p> 
                <p class="modal-text">${getTextContent()}<p> 
                
              </div>`;
            props.onSuccessSubmitData?.(response.data);
            props.onSuccessSubmit ? props.onSuccessSubmit() : setOpenSuccessModal(message);
          }
          if (response && response.status === 13) {
            dispatch(showInfoModal("Некорректный формат фото.", renderOkButton()));
          }
        })
        .catch((err) => {
          console.log(err);
          setSubmitting(false);
        });
    }
  };
  const closeSendModal = () => {
    setOpenSuccessModal("");
    props.onClose();
    setSubmitting(false);
  };
  useEffect(() => {
    if (!projects) dispatch(getProjects());
  }, []);

  useEffect(() => {
    if (props.projectCategoryTagId && projectsForSelect) {
      const project = projectsForSelect.find((project) => project.id === props.projectCategoryTagId);
      if (project) {
        setSelectedProject(project);
        if (props.photoId) {
          const photoIdea = [...(project.items || []), ...(project.gorodIdeas || [])].find(
            (photoIdea) => photoIdea.id === props.photoId
          );
          if (photoIdea) {
            setIdea(photoIdea);
          }
        }

        // для ремонтирования селектов с предустановленными значениями
        setSelectPresetTriggerCount((prev) => prev + 1);
      }
    }
  }, [projectsForSelect, props.projectCategoryTagId, props.photoId]);

  const formValid = () => {
    return (
      selectedProject &&
      (comment.length === 0 || commentValidator.isValid()) &&
      idea &&
      images.length &&
      addressFieldsValid() &&
      agreed
    );
  };

  const addressFieldsValid = () => {
    if (idea?.addressType === PhotoIdeaAddressType.EMPTY) {
      return true;
    }

    if (idea?.addressType === PhotoIdeaAddressType.DIRECTLY) {
      return address;
    }

    // for preset address select
    if (idea?.addresses?.length) {
      if (selectCustomInputUsed) {
        // for custom input field of the select
        return specifyAddressValidator.isValid();
      } else {
        // for select
        return !!address;
      }
    } else {
      // for simple address field
      return addressValidator.isValid();
    }
  };

  const renderOkButton = () => {
    return <Button type="outlined" text="Хорошо" onClick={() => dispatch(hideInfoModal())} />;
  };
  const renderSendButton = () => {
    return <Button type="filled" text="Хорошо" onClick={() => closeSendModal()} />;
  };
  const addressValidateRules = {
    notEmpty: true,
    minLength: 5,
    maxLength: 90,
  };

  const renderContent = () => {
    const addressLabel = "Укажите адрес, где сделано изображение";

    const renderAnyAddressInput = () => (
      <Input
        value={address}
        label={addressLabel}
        placeholder={idea ? "Введите адрес" : "Выберите идею для отображения списка адресов"}
        isRequired={true}
        isDisabled={!idea}
        onChange={(value) => setAddress(value)}
        validateRules={addressValidateRules}
        onInitValidator={(validator) => setAddressValidator(validator)}
        showError={submitted}
        ComponentInput={EventsTrackClickInput}
      />
    );

    return (
      <div className="send-idea-form">
        <Fragment key={selectPresetTriggerCount}>
          <Select
            value={selectedProject?.title}
            items={projectsForSelect}
            closeOnSelect={true}
            bindTo="title"
            label="Выберите проект или тему"
            placeholder="Выберите из списка"
            isRequired={true}
            onItemSelect={(item) => setSelectedProject(item)}
            isInvalid={(submitted && !selectedProject) || false}
            ComponentSelectInput={EventsTrackClickSelectInput}
            ComponentSelectItem={EventsTrackClickSelectItem}
          />

          <Select
            value={idea?.title}
            items={selectedProject && (selectedProject.items || selectedProject.gorodIdeas)}
            closeOnSelect={true}
            bindTo="title"
            label="Выберите идею"
            placeholder={
              selectedProject ? "Выберите из списка" : "Выберите проект или тему для отображения списка идей"
            }
            isRequired={true}
            onItemSelect={(item) => setIdea(item)}
            isInvalid={(submitted && !idea) || false}
            ComponentSelectInput={EventsTrackClickSelectInput}
            ComponentSelectItem={EventsTrackClickSelectItem}
          />
        </Fragment>

        {!!idea?.photoInstruction && <div className="send-idea-form__hint">{idea.photoInstruction}</div>}

        <FileSelector
          name="Добавить файл"
          text={`Размер изображения не должен превышать ${maxTotalSizeMB} Мб, допустимы форматы .jpeg, .jpg, .png, .webp`}
          isMultiple={false}
          upload={true}
          allowedExtensions={["image/jpeg", "image/jpg", "image/png", "image/webp"]}
          maxTotalSizeMB={maxTotalSizeMB}
          maxFiles={maxFiles}
          onChange={(images) => setImages(images)}
          isInvalid={(submitted && !images.length) || false}
          ComponentLabel={EventsTrackClickFileSelectorLabel}
        />

        {idea?.addressType === PhotoIdeaAddressType.DIRECTLY ? (
          <>
            <DynamoList
              isRequired
              label={addressLabel}
              placeholder="Начните вводить адрес"
              value={mapAddress}
              endpoint="/api/address/search?query="
              debounceTime={500}
              bindTo="address"
              onChange={(item) => {
                setMapErr("");
                setAddress(item?.address || item);
              }}
              error={mapErr}
              onError={setMapErr}
              isInvalid={submitted && (!address || !!mapErr)}
              ComponentInput={EventsTrackClickInput}
            />

            <p className="send-idea-form__paragraph">Выбранный адрес на карте:</p>

            <EventsTrackWrapperClick id={["EMPTY", "CLICK_PARTIAL"]} replace={["Нажатие на карту"]}>
              <div>
                <Map address={address} onClick={setMapAddress} onError={setMapErr} />
              </div>
            </EventsTrackWrapperClick>
          </>
        ) : idea?.addressType === PhotoIdeaAddressType.ANY ? (
          renderAnyAddressInput()
        ) : (
          ([PhotoIdeaAddressType.LIST, PhotoIdeaAddressType.DEFAULT_LIST].includes(idea?.addressType) ||
            !idea?.addressType) &&
          ((!!idea?.addresses?.length && (
            <Select
              items={idea && idea.addresses}
              closeOnSelect={true}
              label={addressLabel}
              placeholder={idea ? "Выберите из списка" : "Выберите идею для отображения списка адресов"}
              inputOptions={
                idea?.addressType === PhotoIdeaAddressType.LIST
                  ? {
                      placeholder: "Введите адрес",
                      value: address,
                      onChange: (value) => setAddress(value),
                      validateRules: addressValidateRules,
                      onInitValidator: (validator) => setSpecifyAddressValidator(validator),
                      showError: submitted,
                      isRequired: true,
                      ComponentInput: SelectInputWrapper,
                    }
                  : undefined
              }
              isRequired={true}
              onItemSelect={(item) => setAddress(item)}
              isInvalid={(submitted && !address) || false}
              onSelfSpecifyChange={(inputWasShown, inputIsShown) => {
                setSelectCustomInputUsed(inputIsShown);
                if (inputIsShown && !inputWasShown) {
                  setAddress("");
                }
              }}
              ComponentSelectInput={EventsTrackClickSelectInput}
              ComponentSelectItem={EventsTrackClickSelectItem}
            />
          )) ||
            renderAnyAddressInput())
        )}

        <Textarea
          value={comment}
          label="Добавьте комментарий"
          placeholder="Введите текст"
          isRequired={false}
          maxLength={maxAllowedCommentLength}
          onlyVerticalResize={true}
          hint="Не более 200 символов"
          onChange={(value) => setComment(value)}
          validateRules={{
            minLength: 5,
            maxLength: maxAllowedCommentLength,
            cyrillicWithPunctuationAndNumbers: true,
          }}
          onInitValidator={(validator) => setCommentValidator(validator)}
          showError={submitted && comment.length > 0}
          ComponentTextarea={EventsTrackClickTextarea}
        />

        <Checkbox
          html={`Я ознакомился с <a data-click-id='[39]' data-replace='${JSON.stringify([
            "Правилами модерации",
          ])}' href='/moderation' target='_blank'>Правилами модерации</a> и <a data-click-id='[39]' data-replace='${JSON.stringify(
            ["инструкциями по фотографированию"]
          )}' href='/photos#ideas' target='_blank'>инструкциями по фотографированию</a>`}
          onChange={(value) => setAgreed(value)}
          isInvalid={(submitted && !agreed) || false}
          ComponentCheckbox={EventsTrackClickCheckbox}
        />
      </div>
    );
  };

  return (
    <>
      <SubmitForm
        isLoading={!projects}
        user={user}
        isSubmitting={submitting}
        isOpen={props.isOpened && !openSuccessModal}
        title="Загрузка фотографии"
        onClose={props.onClose}
        onSubmit={submitForm}
        classList="send-idea-modal"
      >
        {renderContent()}
      </SubmitForm>

      {openSuccessModal && (
        <SendSuccessModal isOpened={!!openSuccessModal} text={openSuccessModal} onClose={() => closeSendModal()}>
          {renderSendButton()}
        </SendSuccessModal>
      )}
    </>
  );
};

export default React.memo(SendIdeaForm);
