import { cloneElement, forwardRef } from "react";
import useEventsTrackParentId from "../../../../hooks/useEventsTrackParentId";
import { renderWithTrackId } from "../../../../utils/eventsTrack";
import { EventsTrackWrapperBaseProps } from "./EventsTrackWrapper.interface";

const EventsTrackWrapperBase = forwardRef<unknown, EventsTrackWrapperBaseProps>(({children, id, type, replace, needParent = false}, ref) => {
    const parentEventsTrackId = useEventsTrackParentId();
    const clonedChildren = ref ? cloneElement(children, { ref }) : children;

    if (needParent) {
        return parentEventsTrackId ? renderWithTrackId(clonedChildren, [parentEventsTrackId, id].flat(), type, replace) : clonedChildren;
    }
    return renderWithTrackId(clonedChildren, id, type, replace);
});

export default EventsTrackWrapperBase;