import PublicationService from "../../services/publicationService";
import { SUCCESS } from "../../types/statusCodes";

export const GET_PUBLICATIONS_REQUEST = "GET_PUBLICATIONS_REQUEST";
export const GET_PUBLICATIONS_SUCCESS = "GET_PUBLICATIONS_SUCCESS";
export const GET_PUBLICATIONS_FAIL = "GET_PUBLICATIONS_FAIL";

export const LOAD_PUBLICATIONS_TAGS_HANDLE = "LOAD_PUBLICATIONS_TAGS_HANDLE";
export const LOAD_PUBLICATIONS_TAGS_SUCCESS = "LOAD_PUBLICATIONS_TAGS_SUCCESS";
export const LOAD_PUBLICATIONS_TAGS_FAIL = "LOAD_PUBLICATIONS_TAGS_FAIL";

export function getPublications(params) {
  return async function (dispatch) {
    dispatch({
      type: GET_PUBLICATIONS_REQUEST,
    });

    try {
      const response = await PublicationService.getPublications(params);

      if (response.status === SUCCESS) {
        dispatch({
          type: GET_PUBLICATIONS_SUCCESS,
          payload: response.data,
        });
      } else {
        dispatch({
          type: GET_PUBLICATIONS_FAIL,
          error: true,
          payload: new Error("Ошибка получения новостей."),
        });
      }
    } catch (err) {
      dispatch({
        type: GET_PUBLICATIONS_FAIL,
        error: true,
        payload: new Error("Ошибка получения новостей."),
      });
    }
  };
}

export function getPublicationsTags(params?) {
  return async function (dispatch) {
    dispatch({
      type: LOAD_PUBLICATIONS_TAGS_HANDLE,
    });

    try {
      const response = await PublicationService.getTags(params);

      if (response.status === SUCCESS) {
        dispatch({
          type: LOAD_PUBLICATIONS_TAGS_SUCCESS,
          payload: response.data,
        });
      } else {
        dispatch({
          type: LOAD_PUBLICATIONS_TAGS_FAIL,
          error: true,
          payload: new Error("Ошибка получения тегов новостей."),
        });
      }
    } catch (err) {
      dispatch({
        type: LOAD_PUBLICATIONS_TAGS_FAIL,
        error: true,
        payload: new Error("Ошибка получения тегов новостей."),
      });
    }
  };
}
