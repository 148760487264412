import React, { useEffect, useMemo, useRef, useState } from "react";

import "./DistrictSelector.scss";
import Input from "../../../presentational/Controls/Input/Input";
import { useSelector } from "react-redux";
import { RootState } from "../../../../types/State.interface";
import { batchDistrictsByFirstLetter } from "./DistrictSelector.helper";
import { District } from "../../../../types/Address.interface";
import { useOuterClick } from "../../../../hooks/useOnOutsideClick";
import { Scrollbar } from "react-scrollbars-custom";

interface DistrictSelectorProps {
  value?: string;
  selectedDistrict?: District;
  onSelect: (district: District) => void;
  onClear: () => void;
}

const DistrictSelector = ({ value = "", selectedDistrict, onSelect, onClear }: DistrictSelectorProps) => {
  const districts = useSelector((state: RootState) => state.address.districts);
  const [batchedDistricts, setBatchedDistricts] = useState<Record<string, District[]> | null>(null);
  const [batchedDistrictsFiltered, setBatchedDistrictsFiltered] = useState<Record<string, District[]> | null>(null);
  const [showList, setShowList] = useState<boolean>(false);
  const [text, setText] = useState(value);

  const popupRef = useOuterClick<HTMLDivElement>((event: any) => {
    if (event.target?.className !== "cr-input__input") {
      setShowList(false);
      if (selectedDistrict) {
        setTextTruncated(selectedDistrict.name);
      }
    }
  });

  useEffect(() => {
    if (districts?.length) {
      const batchResult = batchDistrictsByFirstLetter(districts);
      setBatchedDistricts(batchResult);
      setBatchedDistrictsFiltered(batchResult);
    }
  }, [districts]);

  useEffect(() => {
    filterDistricts();
  }, [text, batchedDistricts]);

  const filterDistricts = () => {
    const trimmedValue = text.trim().replace(/\./g, '');

    if (trimmedValue.length < 3) {
      setBatchedDistrictsFiltered(batchedDistricts);
      return;
    }

    const lowerCaseValue = trimmedValue.toLowerCase();
    const filtered: Record<string, District[]> = {};

    Object.keys(batchedDistricts || {}).forEach((key) => {
      const districts = batchedDistricts[key];
      const filteredDistricts = districts.filter((district: District) =>
        district.name.toLowerCase().includes(lowerCaseValue)
      );

      if (filteredDistricts.length) {
        filtered[key] = filteredDistricts;
      }
    });

    setBatchedDistrictsFiltered(filtered);
  };

  const handleChange = (value) => {
    if (value.length === 0) {
      onClear();
    }

    setText(value);
  };

  const handleFocus = (event) => {
    setShowList(true);
  };

  const handleItemClick = (ev, district: District) => {
    ev.stopPropagation();
    ev.preventDefault();

    setTextTruncated(district.name);
    setShowList(false);
    onSelect(district);
  };

  const setTextTruncated = (text) => {
    if (text.length >= 20) {
      setText(text.substring(0, 20) + '...');
    } else {
      setText(text)
    };
  }

  const handleRemove = () => {
    setText("");
    onClear();
  };

  const onToggleList = (e) => {
    e?.stopPropagation();
    e?.preventDefault();
    if (showList) {
      setShowList(false);
      if (selectedDistrict) {
        setText(selectedDistrict.name);
      }
    } else {
      setShowList(true);
    }
  };

  const renderList = useMemo(() => {
    if (!showList || !batchedDistrictsFiltered) return;

    return (
        // <div className="cr-district-selector__list" ref={popupRef}>
        <Scrollbar className="scrollbar-container cr-district-selector__list">
          <div ref={popupRef}>
            {Object.entries(batchedDistrictsFiltered).map(([letter, list]: [string, District[]]) => (
              <div className="cr-district-selector__list-wrapper" key={letter}>
                <div className="cr-district-selector__list-letter">{letter}</div>
                <ul>
                  {list.map((district) => (
                    <li
                      key={district.externalId}
                      className="cr-district-selector__list-item"
                      onClick={(e) => handleItemClick(e, district)}
                    >
                      {district.name}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </Scrollbar>
        //</div>
    );
  }, [showList, batchedDistrictsFiltered]);

  return (
    <div className="cr-district-selector">
      <Input placeholder="Название района" value={text} maxLength={40} onChange={handleChange} onFocus={handleFocus} />
      <span
        className={`ic ui-icon-88 cr-district-selector__arrow ${
          showList ? "cr-district-selector__arrow_up" : "cr-district-selector__arrow_down"
        }`}
        onClick={onToggleList}
      />
      {text && <button className="ic ui-icon-14 close simple-btn" onClick={handleRemove}></button>}
      {renderList}
    </div>
  );
};

export default DistrictSelector;
