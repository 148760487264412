import { fetchRequest, httpParamsSerialize } from "../../utils/axiosConfig";

export const GET_VIDEOS_REQUEST = "GET_VIDEOS_REQUEST";
export const GET_VIDEOS_SUCCESS = "GET_VIDEOS_SUCCESS";
export const GET_VIDEOS_FAIL = "GET_VIDEOS_FAIL";

export const LOAD_VIDEOS_TAGS_SUCCESS = "LOAD_VIDEOS_TAGS_SUCCESS";
export const LOAD_VIDEOS_TAGS_FAIL = "LOAD_VIDEOS_TAGS_FAIL";
export const LOAD_VIDEOS_TAGS_HANDLE = "LOAD_VIDEOS_TAGS_HANDLE";

export function getVideos(params?) {
  return function (dispatch) {
    dispatch({
      type: GET_VIDEOS_REQUEST,
    });

    let url = "/api/video/list?";
    if (params) url += httpParamsSerialize(params);

    fetchRequest(url, {}, "GET")
      .then((response) => {
        if (response.data.status === 0) {
          dispatch({
            type: GET_VIDEOS_SUCCESS,
            payload: response.data.data,
          });
        } else {
          dispatch({
            type: GET_VIDEOS_FAIL,
            error: true,
            payload: new Error(
              "Ошибка запроса данных. Пожалуйста, повторите попытку позже."
            ),
          });
        }
      })
      .catch(() => {
        dispatch({
          type: GET_VIDEOS_FAIL,
          error: true,
          payload: new Error(
            "Ошибка запроса данных. Пожалуйста, повторите попытку позже."
          ),
        });
      });
  };
}

export function getVideosTags(params?) {
  return async function (dispatch) {
    dispatch({
      type: LOAD_VIDEOS_TAGS_HANDLE,
    });

    const url = "/api/video/tags?query=";

    try {
      let response = await fetchRequest(url, params, "GET");
      response = response.data;

      if (response.status === 0) {
        dispatch({
          type: LOAD_VIDEOS_TAGS_SUCCESS,
          payload: response.data,
        });
      } else {
        dispatch({
          type: LOAD_VIDEOS_TAGS_FAIL,
          error: true,
          payload: new Error("Ошибка получения тегов видео."),
        });
      }
    } catch (err) {
      dispatch({
        type: LOAD_VIDEOS_TAGS_FAIL,
        error: true,
        payload: new Error("Ошибка получения тегов видео."),
      });
    }
  };
}
