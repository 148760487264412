import { Video } from "../../../types/Video.interface";

const videoMock: Video = {
    changed: "",
    id: null,
    main: false,
    name: "",
    position: null,
    previewId: "",
    uploaded: "",
    videoId: "",
    tag: null,
};

export { videoMock };
