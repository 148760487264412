import { API, httpParamsSerialize } from "./api";
import StackTrace from "stacktrace-js";

function generateHash(str: string, seed = 0) {
  try {
    let h1 = 0xdeadbeef ^ seed,
      h2 = 0x41c6ce57 ^ seed;
    for (let i = 0, ch; i < str.length; i++) {
      ch = str.charCodeAt(i);
      h1 = Math.imul(h1 ^ ch, 2654435761);
      h2 = Math.imul(h2 ^ ch, 1597334677);
    }
    h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507);
    h1 ^= Math.imul(h2 ^ (h2 >>> 13), 3266489909);
    h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507);
    h2 ^= Math.imul(h1 ^ (h1 >>> 13), 3266489909);

    return 4294967296 * (2097151 & h2) + (h1 >>> 0);
  } catch (error) {
    console.log(`Ошибка генерации хеша: ${error}`);
  }
}

const LoggerService = {
  registerError: (error) => {
    const errorMessage = error.toString();
    StackTrace.fromError(error)
      .then((err) => {
        StackTrace.report(err, `//${window.location.hostname}:${process.env.REACT_APP_PORT}/api/log/jsError`, {
          type: "React boundary",
          url: window.location.href,
          userId: null,
          agent: window.navigator.userAgent,
          date: new Date(),
          msg: `Код ошибки: ${generateHash(errorMessage)}. Текст ошибки: ${errorMessage}`,
        } as any);
      })
      .catch((err) => console.log(err));
  },
};

export default LoggerService;
