import { BaseResponse } from "../types/Common.interface";
import { API, httpParamsSerialize } from "./api";

export const defaultZoom = 10;

declare global {
  interface Window {
    egip: any;
    ol: any;
  }
}

export interface LocationInfo {
  address: string;
  simpleAddress: string;
  id: string;
  unom: number;
}

export interface Coordinates {
  longitude: number;
  latitude: number;
}

const MapService = {
  createMap: (target, zoom?, overlay?) => {
    return window.egip?.layers.createMap({
      target: target,
      layers: [
        new window.ol.layer.Tile({
          source: new window.ol.source.XYZ({
            url: window.egip?.getConfig().getTiles2gisUrl(),
          }),
        }),
      ],
      view: window.egip.layers.createViewWGS({
        zoom: zoom ? zoom : defaultZoom,
        minZoom: 8,
        maxZoom: 20
      }),
      overlays: [overlay],
    });
  },

  clearMap: (map) => {
    const vectors = map.getLayers().getArray().filter((layer) => layer.type !== "TILE");
    vectors.forEach((vector) => map.removeLayer(vector));
  },

  transformCoords: (cords) => {
    return window.ol.proj.transform(cords, "EPSG:3857", "EPSG:4326");
  },

  getPoints: () => {
    return window.egip?.layers.createVectorLayer({
      id: "points",
      type: "random",
      source: window.egip.layers.createVectorSource({}),
    });
  },

  addMultiplePointLayer: (map, marker, addresses) => {
    const layer = window.egip.layers.createVectorLayer({
      id: "points",
      type: "random",
      source: window.egip.layers.createVectorSource({
        features: addresses.map((adress) => {
          const f = MapService.getFeatureFromCoords(adress.coords)
          f.setStyle(marker);
          f.meta = adress;
          return f;
        }),
      }),
    });

    map.addLayer(layer);
  },

  getEgipCoords: (coordinates: Coordinates) => {
    return window.ol?.proj.fromLonLat([coordinates.longitude, coordinates.latitude]);
  },

  pointFeature: (coords) => {
    var feat = new window.ol.Feature(new window.ol.geom.Point(coords));
    feat.setProperties({
      color: window.egip.layers.mock.getRandomColor(),
    });
    feat.setStyle(function (f, res) {
      return [MapService._marker()];
    });
    return feat;
  },

  addFeature: (points, coords) => {
    points.getSource().addFeature(MapService.pointFeature(coords));
  },

  getFeatureFromCoords: (coords: Array<string>) => {
    return new window.ol.Feature({
      geometry: new window.ol.geom.Point(
         window.ol.proj.fromLonLat(coords)
      )
    });
  },

  _marker: () => {
    return new window.ol.style.Style({
      image: new window.ol.style.Icon({
        anchor: [0.5, 1],
        src: require(`../assets/propose-item/pin.png`).default,
        scale: 0.1,
      }),
    });
  },

  getZoom: (map) => {
    return map.getView().getZoom();
  },

  zoomAnimate: (map, coords, zoom = 15) => {
    var duration = 500;
    var currentZoom = MapService.getZoom(map);
    var _zoom = zoom ? zoom : 15;
    map.getView().animate(
      {
        zoom: currentZoom - 1,
        duration: duration,
      },
      {
        center: coords,
        duration: duration,
      },
      {
        zoom: !zoom && currentZoom > _zoom ? currentZoom : _zoom,
        duration: duration,
      }
    );

    return true;
  },

  getAddressesByCoords: (coords): Promise<LocationInfo[]> => {
    let [longitude, latitude] = coords;

    const query = {
      latitude,
      longitude,
    };

    const url = "/api/address/v2/getByPoint?" + httpParamsSerialize(query);
    return API.get(url).then(({ data }) => {
      const response = data;
      return response.data;
    });
  },

  getCoordinatesByAddress: (address) => {
    return API.get<BaseResponse<Coordinates>>("/api/address/v2/getCoordinatesByAddress?address=" + address).then(({ data }) => {
      return data?.data;
    });
  },
};

export default MapService;
