import React from "react";
import { withRouter, Route, Redirect, Switch } from "react-router-dom";
import ChangePasswordContainer from "../components/containers/ChangePassword/ChangePasswordContainer";

import ModerationPage from "../components/containers/Moderation/ModerationPage";
import SetNewPasswordContainer from "../components/containers/SetNewPassword/SetNewPasswordContainer";
import RestorePasswordContainer from "../components/containers/RestorePassword/RestorePasswordContainer";
import SendRestoreEmailContainer from "../components/containers/SendRestoreEmail/SendRestoreEmailContainer";
import BirthdayPage from "../components/pages/Birthday/Birthday";
import BlockedPage from "../components/pages/Blocked/BlockedPage";
import LicensePage from "../components/pages/License/LicensePage";
import NewYear from "../components/pages/NewYear/NewYear";
import NewYearRulesPage from "../components/pages/NewYear/NewYearRulesPage";
import NotFoundPage from "../components/pages/NotFound/NotFoundPage";
import CompleteUserGuard from "./guards/CompleteUserGuard";
import LoggedInGuard from "./guards/LoggedInGuard";
import SmartRoute from "./SmartRoute";
import { notExistingURLs } from "../utils/constants";
import NotExistPage from "../components/pages/NotExist/NotExistPage";
import CrowdBirthday9 from "../components/pages/CrowdBirthday9/CrowdBirthday9";
import { discussionLandingUrl } from "../components/pages/DiscussionLanding/DiscussionLanding.constants";
import DiscussionLandingPage from "../components/pages/DiscussionLanding/DiscussionLandingPage";

const baseRoutes = [
  <SmartRoute
    path="/teamLogin"
    exact
    component="TeamLogin"
    guards={[CompleteUserGuard]}
    lazy={true}
    shouldPreload={true}
    key={"TeamLogin"}
  />,
  <SmartRoute
    path="/profile"
    exact
    component="Account"
    guards={[LoggedInGuard]}
    lazy={true}
    shouldPreload={true}
    key={"Account"}
  />,
  <Route
    path="/newpassword"
    component={SetNewPasswordContainer}
    key={"NewPassword"}
  />,
  <Route
    path="/changePassword"
    component={ChangePasswordContainer}
    key={"ChangePassword"}
  />,
  <Route
    path="/restorePassword"
    component={RestorePasswordContainer}
    key={"RestorePassword"}
  />,
  <Route
    path="/restore"
    exact
    component={SendRestoreEmailContainer}
    key={"Restore"}
  />,
  <Route path="/gameRules" component={NewYearRulesPage} key={"GameRules"} />,
  <Route path="/birthday" component={CrowdBirthday9} key={"Birthday"}></Route>,
  <Route path={discussionLandingUrl} component={DiscussionLandingPage} key={"DiscussionLanding"}></Route>,
  ...notExistingURLs.map((url) => (
    <Route key={url} path={url} component={NotExistPage} />
  )),
  <Route component={NotFoundPage} key={"NotFoundPage"} />,
];

export default baseRoutes;
