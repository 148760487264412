import NotificationsService, { DeleteOneQuery, GetQuery, ReadQuery } from "../../services/notifications.service";

export const GET_NOTIFICATION_HANDLE = "GET_NOTIFICATION_HANDLE";
export const GET_NOTIFICATION_SUCCESS = "GET_NOTIFICATION_SUCCESS";
export const GET_NOTIFICATION_FAILED = "GET_NOTIFICATION_FAILED";

export const GET_NEW_NOTIFICATIONS_COUNT_HANDLE = "GET_NEW_NOTIFICATIONS_COUNT_HANDLE";
export const GET_NEW_NOTIFICATIONS_COUNT_SUCCESS = "GET_NEW_NOTIFICATIONS_COUNT_SUCCESS";
export const GET_NEW_NOTIFICATIONS_COUNT_FAILED = "GET_NEW_NOTIFICATIONS_COUNT_FAILED";

export const READ_NOTIFICATION_HANDLE = "READ_NOTIFICATION_HANDLE";
export const READ_NOTIFICATION_SUCCESS = "READ_NOTIFICATION_SUCCESS";
export const READ_NOTIFICATION_FAILED = "READ_NOTIFICATION_FAILED";

export const DELETE_NOTIFICATION_HANDLE = "DELETE_NOTIFICATION_HANDLE";
export const DELETE_NOTIFICATION_SUCCESS = "DELETE_NOTIFICATION_SUCCESS";
export const DELETE_NOTIFICATION_FAILED = "DELETE_NOTIFICATION_FAILED";

export function getNotifications(query: GetQuery) {
  return async function (dispatch) {
    dispatch({
      type: GET_NOTIFICATION_HANDLE,
    });

    try {
      let response = await NotificationsService.get(query);
      if (response.status === 0) {
        dispatch({
          type: GET_NOTIFICATION_SUCCESS,
          payload: response.data,
        });
      } else {
        throw new Error(`Ошибка получения уведомления: ${response.message}`);
      }
    } catch (error) {
      dispatch({
        type: GET_NOTIFICATION_FAILED,
        payload: error,
      });
    }
  };
}

export function readNotifications(query: ReadQuery) {
  return async function (dispatch) {
    dispatch({
      type: READ_NOTIFICATION_HANDLE,
    });

    try {
      let response = await NotificationsService.read(query);
      if (response.status === 0) {
        dispatch({
          type: READ_NOTIFICATION_SUCCESS,
          payload: {ids: query.ids}
        });
        dispatch(getNewNotificationsCount());
      } else {
        throw new Error(`Ошибка чтения уведомления: ${response.message}`);
      }
    } catch (error) {
      dispatch({
        type: READ_NOTIFICATION_FAILED,
        payload: error,
      });
    }
  };
}

export function removeNotification(query: DeleteOneQuery, onSuccess?) {
  return async function (dispatch) {
    dispatch({
      type: DELETE_NOTIFICATION_HANDLE,
    });

    try {
      let response = await NotificationsService.deleteOne(query);
      if (response.status === 0) {
        dispatch(getNewNotificationsCount());
        dispatch({
          type: DELETE_NOTIFICATION_SUCCESS,
          payload: {id: query.id}
        });
        onSuccess()
      } else {
        throw new Error(`Ошибка удаления уведомления: ${response.message}`);
      }
    } catch (error) {
      dispatch({
        type: DELETE_NOTIFICATION_FAILED,
        payload: error,
      });
    }
  };
}

export function getNewNotificationsCount() {
  return async function (dispatch) {
    dispatch({
      type: GET_NEW_NOTIFICATIONS_COUNT_HANDLE,
    });

    try {
      let response = await NotificationsService.counUnread();
      if (response.status === 0) {
        dispatch({
          type: GET_NEW_NOTIFICATIONS_COUNT_SUCCESS,
          payload: response.data,
        });
      } else {
        throw new Error(`Ошибка получения счетчика уведомлений: ${response.message}`);
      }
    } catch (error) {
      dispatch({
        type: GET_NEW_NOTIFICATIONS_COUNT_FAILED,
        payload: error,
      });
    }
  };
}
