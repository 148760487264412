import { ForwardedRef, useCallback } from "react";

const useCombinedRefsCallback = <T = null>(...refs: ForwardedRef<T>[]) =>
  useCallback((refEl: T) => {
    refs.forEach((ref) => {
      if (ref) {
        typeof ref === "function" ? ref(refEl) : (ref.current = refEl);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, refs);

export default useCombinedRefsCallback;
