import React, { ComponentType, LabelHTMLAttributes } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import FilePreview from "./FilePreview/FilePreview";
import { fetchRequest } from "../../../../utils/axiosConfig";
import { getFileSize } from "../../../../utils/index";
import { showInfoModal, hideInfoModal } from "../../../../store/actions/LayoutActions";

import "./FileSelector.scss";
import Button from "../Button/Button";

interface FileWithPreview {
  base64: string;
  bytesSize: number;
  file: File;
  formattedSize: string;
  id: any;
  preview: string;
}

interface State {
  filesWithPreview: any[];
  isFileUploading: boolean;
}

interface InternalProps {
  showInfoModal: (message: string, cb: JSX.Element) => void;
  hideInfoModal: () => void;
}

interface DefaultFile {
  fileName: string;
  hasThumbnail: boolean;
  id: string;
  size: number;
}

interface Props extends InternalProps {
  defaultFiles?: DefaultFile[];
  name: string;
  text: string;
  isMultiple: boolean;
  isRequired: boolean;
  upload: boolean;
  onAddFiles: Function;
  allowedExtensions: string[];
  maxFileSizeMB: Number;
  maxTotalSizeMB: Number;
  maxFiles: number;
  isInvalid: Boolean;
  onChange: (files: FileWithPreview[]) => void;
  ComponentLabel?: ComponentType<LabelHTMLAttributes<HTMLLabelElement>>;
}

const defaultPDFImage = `<svg xmlns="http://www.w3.org/2000/svg" width="100" height="70" viewBox="0 0 100 70" style="background: #e6e7eb;"><defs><style>.a{fill:#7e7e7e;font-size:28px;font-family:PTSans-Bold, PT Sans;font-weight:700;}</style></defs><text class="a" transform="translate(48, 40)"><tspan x="-24.178" y="5">PDF</tspan></text></svg>`;

class FileSelector extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      filesWithPreview: [],
      isFileUploading: false,
    };
  }

  componentDidMount() {
    this.uploadDefaultFiles();
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    if (prevProps.defaultFiles?.length !== this.props.defaultFiles?.length) {
      if (this.props.defaultFiles?.length > 0) {
        this.uploadDefaultFiles();
      } else {
        this.setState(
          () => ({ filesWithPreview: [] }),
          () => this.props.onChange(this.state.filesWithPreview)
        );
      }
    }
  }

  async getDefaultFiles(): Promise<{ file: File; id: string }[]> {
    const { defaultFiles } = this.props;

    const getFile = async (file: DefaultFile) => {
      const res = await fetch(`/uploads/thumbnails/get/${file.id}`);
      const blob = await res.blob();
      const newFile = new File([blob], file.fileName, {
        type: blob.type,
      });
      return {
        file: newFile,
        id: file.id,
      };
    };
    return Promise.all(defaultFiles.map(getFile));
  }

  async uploadDefaultFiles() {
    if (this.props.defaultFiles?.length) {
      const items = await this.getDefaultFiles();

      if (this.props.maxFiles && items.length > this.props.maxFiles) {
        this.props.showInfoModal(
          `Максимальное количество вложений - ${this.props.maxFiles}.`,
          this.renderCloseButton()
        );
      }

      await Promise.all(items.map(({ file, id }) => this.setFilesPreview(file, id, true)));
      this.props.onChange(this.state.filesWithPreview);
    }
  }

  // VALIDATION

  hasEmptySlots(files = this.state.filesWithPreview) {
    if (!this.props.maxFiles) return true;

    return files.length < this.props.maxFiles;
  }

  matchWeight(file) {
    if (!this.props.maxFileSizeMB) return true;

    return file.size / Math.pow(1024, 2) < this.props.maxFileSizeMB;
  }

  isPassExtensionConstraints = (file) => {
    if (!this.props.allowedExtensions) return true;

    return this.props.allowedExtensions.includes(file.type);
  };

  isPassTotalSizeConstraints = (existingFiles, newFileSize, totalAllowedMB) => {
    if (!this.props.maxTotalSizeMB) return true;

    var bytesInMB = 1048576;
    var totalSize = 0;
    if (existingFiles && existingFiles.length > 0) {
      existingFiles.forEach((existingFile) => {
        totalSize += existingFile.bytesSize;
      });
    }

    totalSize += newFileSize;

    return totalSize / bytesInMB <= totalAllowedMB;
  };

  fileIsValid = (file) => {
    if (!this.hasEmptySlots()) {
      this.props.showInfoModal(`Максимальное количество вложений - ${this.props.maxFiles}.`, this.renderCloseButton());

      return;
    }

    if (!this.isPassExtensionConstraints(file)) {
      this.props.showInfoModal(
        `Вы можете прикрепить только файлы форматов ${this.props.allowedExtensions
          .map((ext) => ext.split("/")[1])
          .join(", ")}.`,
        this.renderCloseButton()
      );

      return;
    }

    if (!this.matchWeight(file)) {
      this.props.showInfoModal(
        `Максимальный объем файла не должен превышать ${this.props.maxFileSizeMB} Мб.`,
        this.renderCloseButton()
      );

      return;
    }

    if (!this.isPassTotalSizeConstraints(this.state.filesWithPreview, file.size, this.props.maxTotalSizeMB)) {
      this.props.showInfoModal(
        `Совокупный объем изображений не может быть более ${this.props.maxTotalSizeMB} Мб.`,
        this.renderCloseButton()
      );

      return;
    }

    return true;
  };

  onFileUpload = async (event) => {
    const files = Array.from(event.target.files);

    const totalFilesLength = files.length + this.state.filesWithPreview.length;
    if (this.props.maxFiles && totalFilesLength > this.props.maxFiles) {
      this.props.showInfoModal(`Максимальное количество вложений - ${this.props.maxFiles}.`, this.renderCloseButton());

      event.target.value = null;
      return;
    }

    let id;
    if (this.props.upload) {
      id = await this.uploadFile(files[files.length - 1]);
    }

    // const allFilesAreValid = !files.some((file) => {
    //   return !this.fileIsValid(file);
    // });

    // if (!allFilesAreValid) {
    //   event.target.value = null;
    //   return;
    // }

    files.map(async (file: any) => {
      if (!this.fileIsValid(file)) {
        return (event.target.value = null);
      }

      await this.setFilesPreview(file, id);
    });
  };

  getCleanBase64(str) {
    return str?.substring(str.indexOf(",") + 1);
  }

  async setFilesPreview(file, id, skipOnChange = false) {
    const readResult = await this.getFilePreview(file);
    const fileWithPreview = {
      id,
      file,
      bytesSize: file.size,
      fileName: file.name,
      formattedSize: getFileSize(file),
      preview: readResult,
      base64: this.getCleanBase64(readResult),
    };

    this.setState(
      (state, props) => ({
        filesWithPreview: this.props.isMultiple ? [...state.filesWithPreview, fileWithPreview] : [fileWithPreview],
      }),
      () => {
        !skipOnChange && this.props.onChange(this.state.filesWithPreview);
      }
    );
  }

  deleteFile = (file) => {
    this.setState(
      (state, props) => ({
        filesWithPreview: state.filesWithPreview.filter((f) => f.file !== file),
      }),
      () => {
        this.props.onChange(this.state.filesWithPreview);
      }
    );
  };

  uploadFile = async (file) => {
    this.setState({
      isFileUploading: true,
    });

    try {
      let uploadResult = await fetchRequest(`/api/attachment/upload?fileName=${file.name}`, file, "POST", {
        "Content-Type": file.type,
        Accept: "application/json",
      });

      uploadResult = uploadResult.data;

      if (uploadResult.status === 0) {
        return uploadResult.data.id;
      }
    } catch (err) {
      console.log(err);
    } finally {
      this.setState({
        isFileUploading: false,
      });
    }
  };

  getFilePreview = (file) => {
    switch (file.type) {
      case "image/jpeg":
      case "image/jpg":
      case "image/png":
      case "image/webp": {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();

          reader.onload = () => {
            resolve(reader.result);
          };

          reader.readAsDataURL(file);
        });
      }

      case "application/pdf": {
        return defaultPDFImage;
      }

      // pdf, doc etc goes here

      default:
        return Promise.resolve(null);
    }
  };

  renderCloseButton() {
    return <Button type="outlined" text="Закрыть" onClick={this.props.hideInfoModal} />;
  }

  renderLoader = () => {
    if (this.state.isFileUploading) {
      return (
        <div className="cr-file-selector__icon">
          <i className="cr-file-selector__icon-loader"></i>
        </div>
      );
    }
  };

  renderLabel() {
    const labelProps = {
      className: `cr-file-selector__head-button${this.props.isInvalid ? " error" : ""}`,
      htmlFor: "file",
      children: (
        <>
          {this.renderLoader()}
          {this.props.name || "Добавить файл"}
        </>
      ),
    };
    const { ComponentLabel } = this.props;
    if (ComponentLabel) {
      return <ComponentLabel {...labelProps} />;
    }
    return <label {...labelProps} />;
  }

  render() {
    return (
      <div className="cr-file-selector">
        <div className="cr-file-selector__head">
          <input
            type="file"
            id="file"
            multiple={this.props.isMultiple}
            onChange={(e) => {
              this.onFileUpload(e);
              e.target.value = "";
            }}
          />
          {this.renderLabel()}

          {this.props.text && <div className="cr-file-selector__head-text">{this.props.text}</div>}
        </div>

        {!!this.state.filesWithPreview.length && (
          <div className="cr-file-selector__files">
            {this.state.filesWithPreview.map((fileWithPreview, idx) => (
              <FilePreview
                file={fileWithPreview.file}
                preview={fileWithPreview.preview}
                size={fileWithPreview.formattedSize}
                onDelete={this.deleteFile}
                key={idx}
              />
            ))}
          </div>
        )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  showInfoModal: (message, jsx, onDismiss) => dispatch(showInfoModal(message, jsx, onDismiss)),
  hideInfoModal: () => dispatch(hideInfoModal()),
});

const connected = connect(undefined, mapDispatchToProps)(FileSelector);

export default withRouter(connected);
