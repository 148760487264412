import { forwardRef, useLayoutEffect, useRef, useState } from "react";
import { PopupProps } from "./Popup.types";
import "./Popup.scss";
import { useOuterClick } from "../../../../../hooks/useOnOutsideClick";
import { createPortal } from "react-dom";
import useCombinedRefsCallback from "../../../../../hooks/useCombinedRefsCallback";
import { useSelector } from "react-redux";
import { selectLeisureScale } from "../../../../../store/reducers/leisureLanding";

const Popup = forwardRef<HTMLDivElement, PopupProps>(
  (
    {
      className,
      style,
      topContent,
      bottomContent,
      forceOpened,
      onOutsideMouseDown,
      children,
      position = "right",
      size = 1,
      rootRef,
    },
    ref
  ) => {
    const scale = useSelector(selectLeisureScale);
    const innerRef = useRef<HTMLDivElement>();
    const cRef = useCombinedRefsCallback(ref, innerRef);
    const popupRef = useOuterClick<HTMLDivElement>(onOutsideMouseDown, false, true);
    const [opened, setOpened] = useState(false);

    const isOpened = typeof forceOpened === "boolean" ? forceOpened : opened;
    const renderPopup = () => (
      <div
        ref={popupRef}
        className={`leisure-landing-popup__bubble leisure-landing-popup__bubble_${position} ${
          isOpened ? "leisure-landing-popup__bubble_shown" : ""
        }`}
      >
        <div className="leisure-landing-popup__bubble__top-block">{topContent}</div>
        {!!bottomContent && <div className="leisure-landing-popup__bubble__bottom-block">{bottomContent}</div>}
      </div>
    );

    useLayoutEffect(() => {
      if (isOpened) {
        const { left, top, right } = innerRef.current.getBoundingClientRect();
        if (position === "right") {
          popupRef.current.style.left = `${right / scale}px`;
        } else {
          popupRef.current.style.left = `${left / scale}px`;
        }

        popupRef.current.style.top = `${top / scale}px`;
      }
    }, [isOpened, position, scale]);

    return (
      <div
        className={`leisure-landing-popup ${className || ""}`}
        style={style}
        ref={cRef}
        onMouseEnter={() => setOpened(true)}
        onMouseLeave={() => setOpened(false)}
      >
        {children}

        {rootRef?.current ? createPortal(renderPopup(), rootRef.current) : renderPopup()}

        <svg className="leisure-landing-popup__svg">
          <clipPath id="leisurePopupClipPath" clipPathUnits="objectBoundingBox">
            {size === 1 && (
              <path d="M0.221,0.862 L0.225,0.812 V1 L0.433,0.745 L0.372,0.845 L0.94,0.817 C0.962,0.815,1,0.793,1,0.717 C0.995,0.641,0.98,0.215,0.974,0.138 C0.974,0.113,0.961,0.061,0.908,0.055 C0.856,0.049,0.245,0.016,0.103,0 C0.08,-0.003,0.034,0.009,0.029,0.079 C0.027,0.113,0.022,0.178,0.017,0.249 C0.012,0.324,0.006,0.662,0.002,0.726 C-0.006,0.831,0.009,0.874,0.073,0.872 C0.106,0.87,0.166,0.867,0.19,0.865 L0.19,0.865 L0.19,0.865 C0.19,0.865,0.19,0.865,0.19,0.865 C0.191,0.865,0.192,0.865,0.193,0.865 C0.204,0.863,0.214,0.862,0.221,0.862" />
            )}
            {size === 2 && (
              <path d="M0.139,0.848 L0.142,0.792 V1 L0.3,0.719 L0.253,0.829 L0.954,0.798 C0.971,0.796,1,0.771,1,0.687 C0.996,0.603,0.985,0.237,0.98,0.153 C0.98,0.125,0.97,0.067,0.93,0.061 C0.89,0.055,0.187,0.018,0.078,0.001 C0.061,-0.003,0.026,0.009,0.022,0.087 C0.021,0.125,0.02,0.202,0.016,0.281 C0.012,0.364,0.005,0.627,0.001,0.698 C-0.004,0.813,0.007,0.861,0.056,0.858 C0.081,0.857,0.096,0.853,0.115,0.851 L0.115,0.851 L0.115,0.851 C0.115,0.851,0.115,0.851,0.115,0.851 C0.116,0.851,0.117,0.85,0.117,0.85 C0.126,0.849,0.133,0.848,0.139,0.848" />
            )}
            {size === 3 && (
              <path d="M0.109,0.884 L0.112,0.842 V1 L0.237,0.786 L0.2,0.87 L0.964,0.846 C0.977,0.845,1,0.826,1,0.762 C0.997,0.698,0.988,0.181,0.984,0.117 C0.984,0.095,0.976,0.051,0.945,0.046 C0.914,0.042,0.147,0.014,0.062,0 C0.048,-0.002,0.021,0.007,0.018,0.066 C0.016,0.095,0.014,0.149,0.01,0.21 C0.007,0.273,0.004,0.716,0.001,0.77 C-0.003,0.858,0.006,0.894,0.044,0.892 C0.064,0.891,0.076,0.888,0.091,0.886 L0.091,0.886 L0.091,0.886 C0.091,0.886,0.091,0.886,0.091,0.886 C0.091,0.886,0.092,0.886,0.092,0.886 C0.099,0.885,0.105,0.884,0.109,0.884" />
            )}
            {size === 4 && (
              <path d="M0.109,0.902 L0.112,0.866 V1 L0.237,0.819 L0.2,0.89 L0.964,0.87 C0.977,0.869,1,0.853,1,0.799 C0.997,0.744,0.988,0.153,0.984,0.099 C0.984,0.08,0.976,0.043,0.945,0.039 C0.914,0.035,0.147,0.012,0.062,0 C0.048,-0.002,0.021,0.006,0.018,0.056 C0.016,0.081,0.014,0.126,0.01,0.177 C0.007,0.231,0.004,0.76,0.001,0.805 C-0.003,0.88,0.006,0.91,0.044,0.909 C0.064,0.908,0.076,0.905,0.091,0.904 L0.091,0.904 L0.091,0.904 C0.091,0.904,0.091,0.904,0.091,0.904 C0.091,0.904,0.092,0.904,0.092,0.904 C0.099,0.903,0.105,0.902,0.109,0.902" />
            )}
          </clipPath>
        </svg>
      </div>
    );
  }
);

export default Popup;
