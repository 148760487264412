import { FC, useEffect, useRef } from "react";
import { useSelector } from "react-redux";

import { RootState } from "../../../../types/State.interface";

const PostMessageListener: FC = ({ children }) => {
  const listeners = useSelector((state: RootState) => state.postMessages.listeners);
  const handlers = useRef([]);

  useEffect(() => {
    if (listeners.length) {
      removeListeners();
      handlers.current = [];
      listeners.forEach((listener) => {
        const handler = function (data) {
          const { origin: eventOrigin, data: eventData } = data;
          if (eventOrigin === window.location.origin) {
            if (listeners.map((l) => l.source).includes(eventData.source)) {
              listener.handler(eventData.payload);
            }
          }
        };

        window.addEventListener("message", handler, false);
        handlers.current.push(handler);
      });
    }

    return () => removeListeners();
  }, [listeners]);

  const removeListeners = () => handlers.current.forEach((h) => window.removeEventListener("message", h));

  return <>{children}</>;
};

export default PostMessageListener;
