import React, { ComponentType, InputHTMLAttributes, RefAttributes } from "react";
import axios from "axios";

import "./DynamoList.scss";
import { getInputInfo, setListPosition } from "../../../../utils/selectHelper";
import { debounce } from "../../../../utils/index";

interface Props {
  value: string;
  endpoint: string;
  debounceTime: Number;
  bindTo: string;
  onChange: Function;
  isInvalid: Boolean;
  placeholder: string;
  error: string;
  onError?: Function;
  label?: string;
  isRequired?: boolean;
  ComponentInput?: ComponentType<
    InputHTMLAttributes<HTMLInputElement> & { label: Props["label"] } & RefAttributes<HTMLInputElement>
  >;
}

class DynamoList extends React.Component<Props, any> {
  inputRef: any;
  listRef: any;
  cancelToken: any;
  source: any;
  previousValue: string;

  constructor(props) {
    super(props);

    this.state = {
      items: null,
      canShowList: false,
      isFetching: false,
      value: this.props.value || "",
      inputInfo: null,
      placeholder: this.props.placeholder || "",
    };

    this.inputRef = React.createRef();
    this.listRef = React.createRef();

    this.cancelToken = axios.CancelToken;
    this.source = this.cancelToken.source();

    this.previousValue = "";
    this.handleOnInputChange = debounce(this.handleOnInputChange, this.props.debounceTime || 0);
  }

  componentDidUpdate(prevProps, nextState) {
    if (this.state.value !== nextState.value) return;

    const value =
      this.props.value && this.props.value[this.props.bindTo] ? this.props.value[this.props.bindTo] : this.props.value;
    const prevValue =
      prevProps.value && prevProps.value[prevProps.bindTo] ? prevProps.value[prevProps.bindTo] : prevProps.value;

    if (value !== prevValue) {
      this.setState({ value: "" });
      this.fetchSearchResults(value);
    }
  }

  fetchSearchResults = async (query) => {
    this.source.cancel();

    this.cancelToken = axios.CancelToken;
    this.source = this.cancelToken.source();

    this.setState({
      isFetching: true,
    });

    try {
      const result = await axios.get(this.props.endpoint + query, {
        cancelToken: this.source.token,
      });
      this.setState(
        {
          items: result.data.data,
          canShowList: true,
          inputInfo: getInputInfo(this.inputRef),
          isFetching: false,
        },
        () => setListPosition(this.listRef, this.state.inputInfo)
      );
    } catch (err) {
      if (axios.isCancel(err)) {
        console.log("Request canceled", err.message);
      } else {
        console.log("Error", err);
        this.setState({
          isFetching: false,
        });
      }
    }
  };

  persistEventAndCallOnChange = (event) => {
    event.persist();

    this.setState(
      {
        value: event.target.value,
      },
      () => this.handleOnInputChange(event.target.value)
    );
  };

  handleOnInputChange = (currentValue) => {
    if (currentValue) {
      currentValue = currentValue[this.props.bindTo] || currentValue;
      if (currentValue === this.previousValue) return;

      this.previousValue = currentValue;
      this.fetchSearchResults(currentValue);
    }

    if (currentValue === "") {
      this.previousValue = currentValue;
      this.props.onChange("");
    }
  };

  onSelectItem = (item) => {
    if (this.props.onChange && typeof this.props.onChange === "function") {
      this.setState({
        value: item[this.props.bindTo || "value"],
        canShowList: false,
      });

      this.props.onChange(item);
    } else {
      console.error("Please provide a callback for DynamoList component");
    }
  };

  // RENDER

  renderLoader = () => {
    if (this.state.isFetching) {
      return (
        <div className="cr-dynamo-list__input-icon">
          <i className="cr-dynamo-list__input-icon-loader"></i>
        </div>
      );
    }
  };

  renderList = () => {
    if (this.state.items && this.state.items.length && this.state.canShowList) {
      return (
        <div ref={this.listRef} className="cr-dynamo-list__list">
          {this.state.items.map((item, idx) => (
            <div key={idx} className="cr-dynamo-list__list-item" onMouseDown={(e) => this.onSelectItem(item)}>
              {item[this.props.bindTo || "value"]}
            </div>
          ))}
        </div>
      );
    }

    if (!this.state?.items?.length && this.state?.canShowList) {
      this.props.onError("Результат не найден, попробуйте указать другое значение!");
      return <></>;
    }
  };

  renderError = (err) => !!err && <div className="cr-dynamo-list__error">{err}</div>;

  render() {
    const { ComponentInput } = this.props;
    const inputProps = {
      className: `${this.props.isInvalid ? "cr-dynamo-list__input-invalid" : ""}`,
      value: this.state.value,
      type: "text",
      id: "search-input",
      placeholder: this.state.placeholder,
      onChange: this.persistEventAndCallOnChange,
      onBlur: (e) => this.setState({ canShowList: false }),
    };
    return (
      <div className="cr-dynamo-list">
        {this.props.label && (
          <div className="cr-dynamo-list__label">
            {this.props.label}
            {this.props.isRequired && <span className="cr-dynamo-list__label-required">*</span>}
          </div>
        )}

        <div className="cr-dynamo-list__inner">
          <div ref={this.inputRef} className="cr-dynamo-list__input">
            {ComponentInput ? <ComponentInput label={this.props.label} {...inputProps} /> : <input {...inputProps} />}
            {this.renderLoader()}
          </div>

          {this.renderList()}
          {this.renderError(this.props.error)}
        </div>
      </div>
    );
  }
}

export default DynamoList;
