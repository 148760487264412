import React, { ButtonHTMLAttributes, FC } from "react";
import "./Button.scss";
import Loader from "../../../../presentational/Loaders/Loader/Loader";

interface GameButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  isLoading?: boolean;
}

const GameButton: FC<GameButtonProps> = ({ isLoading = false, type = "button", className, ...otherProps }) => {
  const getClassListForRoor = () => {
    let classlist = " new-year-2023-gameButton ";
    if (isLoading) {
      classlist += " new-year-2023-gameButton--loading ";
    }
    if (className) {
      classlist += className;
    }
    return classlist;
  };

  return (
    <button className={getClassListForRoor()} type={type} {...otherProps}></button>
  );
};

export default GameButton;
