import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

import Button from "../../presentational/Controls/Button/Button";
import {
  showInfoModal,
  hideInfoModal,
} from "../../../store/actions/LayoutActions";
import "./SuccessEmailConfirmation.scss";
import { findUrlParam } from "../../../utils";
import ProjectService from "../../../services/projectService";

const SuccessEmailConfirmation = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    ProjectService.getActiveIDs().then(({ data }) => {
      const currentProject = data?.currentIds?.length;
      const announcementProjects = data?.announcementIds?.length;

      let message = getMessage(currentProject || announcementProjects);
      dispatch(showInfoModal(message, renderOkButton()));
    });
  }, []);

  const getMessage = (activeProjects) => {
    return `Благодарим за проявленный интерес! <br/> Вы стали пользователем платформы «Город идей». 
    `;

    // return activeProjects
    //   ? `Благодарим за проявленный интерес! <br/> Вы стали пользователем платформы «Город идей» и участником вашего первого проекта. Вам начислено ${getPointsFromUrl()} приветственных баллов. Ознакомьтесь с информацией о <a href='/pointsRules' target="blank">программе лояльности</a> на платформе.`
    //   : `Благодарим за проявленный интерес! <br/> Вы стали пользователем платформы «Город идей». Вам начислено ${getPointsFromUrl()} приветственных баллов. Ознакомьтесь с информацией о <a href='/pointsRules' target="blank">программе лояльности</a> на платформе.`;
  };

  const getPointsFromUrl = () => {
    return findUrlParam("userPoints", window.location.search) || 0;
  };

  const renderOkButton = () => {
    return (
      <Button
        type="outlined"
        text="Закрыть"
        onClick={() => {
          dispatch(hideInfoModal());
          history.push("/");
        }}
      />
    );
  };

  return <section className="success-email"></section>;
};

export default SuccessEmailConfirmation;
