import React, { Fragment, HTMLAttributes } from "react";

import Input from "../Input/Input";
import { getInputInfo, setListPosition } from "../../../../utils/selectHelper";
import { Props } from "./Select.interface";
import "./Select.scss";

const itemHeight = 43;

class Select extends React.Component<any, any> {
  inputRef: any;
  listRef: any;

  constructor(props) {
    super(props);

    this.announceSelfSpecifyChange(null, false);
    this.state = {
      selectedItem: this.getSelectedItem(),
      isListVisible: false,
      inputInfo: null,
      selfSpecify: false,
    };

    this.inputRef = React.createRef();
    this.listRef = React.createRef();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.propsDidChange(prevProps, this.props)) {
      const selectedItem = this.props.selectFirstItem
        ? this.props.items[0]
        : this.getSelectedItem();
      this.setState({
        selectedItem: selectedItem,
      })
      this.props.onItemSelect && this.props.onItemSelect(selectedItem);
    }
    if (prevProps.value !== this.props.value) {
      this.setState({ selectedItem: this.getSelectedItem() })
    }
  }

  propsDidChange(prev, curr) {
    if (this.isStringTypeItems()) {
      return prev.items?.join("") !== curr.items?.join("");
    }

    if (this.isObjectTypeItems()) {
      return (
        prev.items?.map((i) => i[this.props.bindTo as any]).join("") !==
        curr.items?.map((i) => i[this.props.bindTo as any]).join("")
      );
    }
  }

  announceSelfSpecifyChange(oldState, newState) {
    if (this.props.onSelfSpecifyChange && oldState !== newState) {
      this.props.onSelfSpecifyChange(oldState, newState);
    }
  }

  getSelectedItem() {
    if (this.isStringTypeItems()) {
      return this.props.items.find((i) => i === this.props.value);
    }

    if (this.isObjectTypeItems()) {
      return this.props.items.find(
        (i) => i[this.props.bindTo as any] === this.props.value
      );
    }

    return null;
  }

  isObjectTypeItems() {
    return this.props.items?.some((i) => typeof i === "object");
  }

  isStringTypeItems() {
    return this.props.items?.some((i) => typeof i === "string");
  }

  displayList = () => {
    this.setState(
      {
        isListVisible: !this.state.isListVisible,
        inputInfo: getInputInfo(this.inputRef),
      },
      () => setListPosition(this.listRef, this.state.inputInfo)
    );
  };

  hideList = () => {
    this.setState({
      isListVisible: false,
    });
  };

  onSelectItem = (e, item) => {
    e.stopPropagation();

    if (
      this.props.onItemSelect &&
      typeof this.props.onItemSelect === "function"
    ) {
      this.props.onItemSelect(item);

      this.announceSelfSpecifyChange(this.state.selfSpecify, false);
      this.setState({
        selfSpecify: false,
      });

      if (this.props.closeOnSelect) {
        this.setState({
          selectedItem: item,
        });
        this.hideList();
      }
    } else {
      console.error("Please provide a callback for Select component");
    }
  };

  onSelfSpecifyClick = (e, item) => {
    this.announceSelfSpecifyChange(this.state.selfSpecify, true);
    this.setState({ selfSpecify: true });
  };

  getPlaceholder() {
    if (this.state.selfSpecify) {
      return "Указать самостоятельно";
    }
    if (this.state.selectedItem) {
      return this.state.selectedItem[this.props.bindTo] || this.state.selectedItem;
    } else {
      return this.props.placeholder ? this.props.placeholder : this.props.label;
    }
  }

  canSelfSpecify() {
    return this.props.inputOptions;
  }

  getClassesForSelectInput() {
    let classes = "cr-select__input";

    if (this.props.isInvalid) {
      classes += " cr-select__input-invalid";
    }

    if (this.state.isListVisible) {
      classes += " cr-select__input_open";
    }

    if (this.props.isDisabled) {
      classes += " cr-select__input_disabled";
    }

    return classes;
  }

  //RENDER

  renderItem(item, isObjectType) {
    const selectedText = isObjectType ? item[this.props.bindTo || item] : item;
    const itemProps: HTMLAttributes<HTMLElement> = {
      className: "cr-select__input-list-item",
      onClick: (e) => this.onSelectItem(e, item),
      children: this.props.renderAsHtml ? <span dangerouslySetInnerHTML={{ __html: selectedText }} /> : selectedText,
    };
    const { ComponentSelectItem } = this.props;
    if (ComponentSelectItem) {
      return <ComponentSelectItem selectedText={selectedText} label={this.props.label} {...itemProps} />;
    }
    return <div {...itemProps} />;
  }
  renderList = () => {
    const isObjectType = this.isObjectTypeItems();
    if (this.props.items && this.props.items.length) {
      return (
        <div
          ref={this.listRef}
          className="cr-select__input-list"
          style={{
            maxHeight: this.props.restrictBy
              ? `${this.props.restrictBy * itemHeight}px`
              : "auto",
          }}
        >
          {this.props.items.map((item, idx) => (
            <Fragment key={idx}>
              {this.renderItem(item, isObjectType)}
            </Fragment>
          ))}
          {this.canSelfSpecify() && (
            <div
              className="cr-select__input-list-item"
              onClick={(e) => this.onSelfSpecifyClick(e, true)}
            >
              Указать самостоятельно
            </div>
          )}
        </div>
      );
    }
  };

  renderInput() {
    const inputProps: HTMLAttributes<HTMLElement> = {
      className: this.getClassesForSelectInput(),
      tabIndex: 0,
      onClick: this.displayList,
      onBlur: this.hideList,
      children: <>
          {this.props.renderAsHtml ? (
            <span dangerouslySetInnerHTML={{ __html: this.getPlaceholder() }} />
          ) : (
            <span>{this.getPlaceholder()}</span>
          )}
          {this.state.isListVisible && this.renderList()}
      </>
    };
    const { ComponentSelectInput } = this.props;
    if (ComponentSelectInput) {
      return <ComponentSelectInput
        ref={this.inputRef}
        isListVisible={this.state.isListVisible}
        label={this.props.label}
        {...inputProps}
      />;
    }
    return <div ref={this.inputRef} { ...inputProps } />;
  }

  render() {
    return (
      <div className="cr-select">
        <div className="cr-select__label">
          {this.props.label}
          {this.props.isRequired && (
            <span className="cr-select__label-required">*</span>
          )}
        </div>
        {this.renderInput()}

        {this.state.selfSpecify && (
          <Input
            placeholder={this.props.inputOptions.placeholder}
            value={this.props.inputOptions.value}
            isRequired={this.props.inputOptions.isRequired}
            isDisabled={this.props.inputOptions.isDisabled}
            showError={this.props.inputOptions.showError}
            onChange={(value) => this.props.inputOptions.onChange(value)}
            validateRules={this.props.inputOptions.validateRules}
            onInitValidator={(value) =>
              this.props.inputOptions.onInitValidator(value)
            }
            ComponentInput={this.props.inputOptions.ComponentInput}
          />
        )}
      </div>
    );
  }
}

export default Select;
