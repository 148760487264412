export enum directionsEnum {
  up = "UP",
  down = "DOWN",
  left = "LEFT",
  right = "RIGHT",
}

export enum passagesEnum {
  pass = "PASS",
  barrier = "BARRIER",
}

export type gridType = {
  [directionsEnum.up]: number;
  [directionsEnum.down]: number;
  [directionsEnum.left]: number;
  [directionsEnum.right]: number;
};

export type directionType = {
  x: number;
  y: number;
  opposite: directionsEnum;
};

export type modDirectionsType = {
  [directionsEnum.up]: directionType;
  [directionsEnum.down]: directionType;
  [directionsEnum.left]: directionType;
  [directionsEnum.right]: directionType;
};
