import { TOGGLE_FULL_SCREEN_DOCUMENT, SET_FULL_SCREEN_ELEMENT_BY_ID } from "../actions/WindowActions";

const ROOT_ID = "#body";

export interface windowState {
  fullScreenElementId: string;
}

const initialState: windowState = {
  fullScreenElementId: "",
};

export function windowReducer(state = initialState, action) {
  switch (action.type) {
    case TOGGLE_FULL_SCREEN_DOCUMENT:
      return {
        ...state,
        fullScreenElementId: state.fullScreenElementId ? "" : ROOT_ID,
      };

    case SET_FULL_SCREEN_ELEMENT_BY_ID:
      return {
        ...state,
        fullScreenElementId: action.payload,
      };

    default:
      return state;
  }
}
