import getNewsServiceLine from "../../services/newsServiceLine";

export const GET_NEWS_LINES_REQUEST = "GET_NEWS_LINES_REQUEST";
export const GET_NEWS_LINES_SUCCESS = "GET_NEWS_LINES_SUCCESS";
export const GET_NEWS_LINES_FAIL = "GET_NEWS_LINES_FAIL";

export function getNewsLines() {
  return (dispatch) => {
    dispatch(request());

    getNewsServiceLine.getNewsById().then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: GET_NEWS_LINES_REQUEST };
  }
  function success(response) {
    return {
      type: GET_NEWS_LINES_SUCCESS,
      payload: response?.data?.bands?.map((elem, index) => {
        return {
          id: index,
          imgUrlFull: "/uploads/get/" + elem.desktopAttachmentId,
          imgUrlTablet: "/uploads/get/" + elem.tabletAttachmentId,
          imgUrlPhone: "/uploads/get/" + elem.phoneAttachmentId,
          link: elem.link,
          identifier: elem.identifier,
          switchingTime: response.data.duration,
        };
      }),
    };
  }
  function failure(error) {
    return { type: GET_NEWS_LINES_FAIL, error };
  }
}
