import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import "./GenerationForm.scss";
import useQuery from "../../../../../../hooks/useQuery";
import Validator from "../../../../../../services/validator";
import { LocalStorageKeys, LocalStorageService } from "../../../../../../services/LocalStorage.service";
import { getFileSize, getPlainFromHtml, imageIdToBlob, replaceHtmlEntites } from "../../../../../../utils";
import { showErrorToast } from "../../../../../../store/actions/ToastActions";
import { ApiStatusCode } from "../../../../../../types/Common.interface";
import Tooltip from "../../../../../presentational/Controls/Tooltip/Tooltip";
import Select from "../../../../../presentational/Controls/Select/Select";
import { GroupDirectionStage, Specification, TaskType } from "../../../../../../types/GroupDirection.interface";
import { createPortal } from "react-dom";
import Input from "../../../../../presentational/Controls/Input/Input";
import FileSelector from "../../../../../presentational/Controls/FileSelector/FileSelector";
import Button from "../../../../../presentational/Controls/Button/Button";
import { showInfoModal } from "../../../../../../store/actions/LayoutActions";
import BadWordsService from "../../../../../../services/badWords.service";
import { DirtyWordsChecker } from "../../../../../presentational/DirtyWordsChecker/DirtyWordsChecker";
import TextAreaEditor from "../../../../../presentational/Controls/TextAreaEditor/TextAreaEditor";
import RejectReason from "../RejectReason/RejectReason";
import { RootState } from "../../../../../../types/State.interface";
import LinkPopup from "../../../../../presentational/LinkPopup/LinkPopup";
import ProposalService from "../../../../../../services/proposalService";

const MAX_COMMENT_LEN = 800;

export interface GenerationFormProps {
  stage: GroupDirectionStage;
  showFormDescription?: boolean;
  showTaskDescription?: boolean;
  showFullScreenButton?: boolean;
  showDraftButton?: boolean;
  showRejectReason?: boolean;
  draftAutoSubmitDelay?: number;
  onSubmitSuccess?: () => void;
  onSubmitDraftSuccess?: () => void;
  onClose?: () => void;
  onLocalDraftId;
}

export interface GenerationDraft {
  stageId: string;
  id?: string;
  editableProposalId?: string;
  draftId?: string;
  title: string;
  values: Record<string, string>;
  files: { id: string; name?: string }[];
  rejectReason?: {
    text: string;
    comment: string;
  };
}

export interface GenerationFormType {
  setDraftId: (draftId: any) => void;
  getDraftId: () => any;
}

const GenerationForm = forwardRef<GenerationFormType, GenerationFormProps>(
  (
    {
      stage,
      showFormDescription,
      showTaskDescription,
      showFullScreenButton,
      showRejectReason,
      showDraftButton,
      draftAutoSubmitDelay,
      onSubmitSuccess,
      onSubmitDraftSuccess,
      onClose,
      onLocalDraftId,
    },
    ref
  ) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const query = useQuery();
    const env = useSelector((state: RootState) => state.environment);

    const [badWordsList, setBadWordsList] = useState();

    const [proposalTitle, setProposalTitle] = useState("");
    const [inputValueMap, setInputValueMap] = useState<Record<string, string>>({});
    const [rejectReason, setRejectReason] = useState<GenerationDraft["rejectReason"]>(null);
    const [currentLink, setCurrentLink] = useState(null);
    const [selection, setSelection] = useState<Record<string, any>>({});
    const [validatorsMap, setValidatorsMap] = useState<Record<string, Validator>>({});
    const [submitted, setSubmitted] = useState(false);
    const [submitting, setSubmitting] = useState<boolean>();
    const [submittingDraft, setSubmittingDraft] = useState<boolean>();
    const [files, setFiles] = useState([]);
    const [defaultFiles, setDefaultFiles] = useState([]);
    const [dirtyController, setDirtyController] = useState(null);
    const [isDeclined] = useState(Boolean(query?.isDeclined === "true"));
    const [draftId, setDraftId] = useState(null);
    const [showLinkPopup, setShowLinkPopup] = useState<Record<string, boolean>>({});
    const [generationDraft, setGenerationDraft] = useState<GenerationDraft>(null);
    const [secondsElapsed, setSecondsElapsed] = useState(0);
    let timerRef = useRef(null);
    const editorRefs = useRef<Record<string, HTMLDivElement>>({});

    useEffect(() => {
      BadWordsService.getAll().then(({ data }) => setBadWordsList(data));
    }, []);

    useEffect(() => {
      onLocalDraftId?.(draftId?.draftId);
    }, [draftId]);

    const startTimer = () => {
      if (!draftAutoSubmitDelay) return;
      stopTimer();
      timerRef.current = setInterval(() => setSecondsElapsed((prevSeconds) => prevSeconds + 1), 1000);
    };

    const stopTimer = () => {
      if (timerRef.current) clearInterval(timerRef.current);
      timerRef.current = null;
      setSecondsElapsed(0);
    };

    const onAddLink = (link, id) => {
      const newValue = selection[id] ? createSelectionValue(link, id) : (inputValueMap[id] || "") + link;
      editorRefs.current[id].innerHTML = newValue;
      const newState = {
        ...inputValueMap,
        [id]: newValue,
      };
      setInputValueMap(newState);
    };

    const createSelectionValue = (link, id) => {
      const { focusOffset, text, backward } = selection[id];
      const textLength = text?.length || 0;
      selection[id].focusNode.textContent = selection[id].focusNode.textContent.splice(
        !backward && textLength ? focusOffset - textLength : focusOffset,
        textLength,
        link
      );
      return replaceHtmlEntites(editorRefs.current[id].innerHTML);
    };

    useImperativeHandle(ref, () => ({
      setDraftId,
      getDraftId: () => draftId,
    }));

    useEffect(() => {
      if (secondsElapsed === draftAutoSubmitDelay) {
        const valueExist = Object.values(inputValueMap).some((el) => !!el);
        if (valueExist) submitDraft(null, false);
        stopTimer();
      }
    }, [secondsElapsed]);

    useEffect(() => {
      return () => {
        if (timerRef.current) {
          stopTimer();
        }
      };
    }, []);

    useEffect(() => {
      if (stage?.id) {
        const canLoadDraft = query.loadDraft;
        if (canLoadDraft) {
          const generationDraft = JSON.parse(LocalStorageService.getData(LocalStorageKeys.GenerationDraft));
          if (generationDraft && generationDraft.stageId === stage.id) {
            setGenerationDraft(generationDraft);
          }
        }
      }
    }, [stage?.id]);

    useEffect(() => {
      const downloadFiles = async (filesData) => {
        const files = await Promise.all(filesData.map(({ id, name }) => imageIdToBlob(id, name)));
        const attachments = files.map((file) => ({
          id: file.id,
          file: file.file,
          fileName: file.file.name,
          bytesSize: file.file.size,
          formattedSize: getFileSize(file.file),
        }));
        setDefaultFiles(attachments);
      };

      if (generationDraft) {
        setProposalTitle(generationDraft.title);
        setInputValueMap(generationDraft.values);
        setEditorRefsValue(generationDraft.values);
        generationDraft.files?.length && downloadFiles(generationDraft.files);
        generationDraft.rejectReason &&
          setRejectReason({
            text: generationDraft.rejectReason.text,
            comment: generationDraft.rejectReason.comment,
          });
      }
    }, [generationDraft]);

    const setEditorRefsValue = (values) => {
      for (let id in values) {
        if (editorRefs.current[id]) {
          editorRefs.current[id].innerHTML = values[id];
        }
      }
    };

    const clearEditorRefs = () => {
      for (let id in editorRefs.current) {
        editorRefs.current[id].innerHTML = "";
      }
    };

    const openGenerationPage = () => {
      const draft: GenerationDraft = {
        stageId: stage.id,
        title: proposalTitle,
        values: inputValueMap,
        files: files.map((file) => ({ id: file.id, name: file.fileName })),
      };
      LocalStorageService.saveData(LocalStorageKeys.GenerationDraft, JSON.stringify(draft));
      window.open(`${env.platformUrl}/generation/${stage.id}?loadDraft=1`, "_blank");
    };

    const isFieldRequired = (specification) => specification.required || stage.specification.items?.length === 1;

    const canSubmit = () => {
      if (isDeclined) {
        const isProposalTextsSame = JSON.stringify(generationDraft?.values) === JSON.stringify(inputValueMap);
        if (isProposalTextsSame) return false;
      }

      return true;
    };

    const canSaveDraft = () => {
      return (proposalTitle || Object.values(inputValueMap).some(Boolean)) && showDraftButton;
    };

    const isFormValid = () => {
      const baseValidation = isValidatorsValid() && Boolean(proposalTitle);
      const listTasks = stage?.specification?.items?.filter((specification) => specification.type === TaskType.LIST);

      if (!listTasks?.length) {
        return baseValidation;
      }

      const areListTasksValid = listTasks.every((task) => Boolean(inputValueMap[task.id]));
      return baseValidation && areListTasksValid;
    };

    const isValidatorsValid = () => Object.values(validatorsMap).every((validator) => validator.isValid());

    const invalidate = () => {
      setProposalTitle("");
      setFiles([]);
      setDefaultFiles([]);
      setRejectReason(null);
      setInputValueMap(
        Object.keys(inputValueMap).reduce((object, key) => {
          object[key] = "";
          return object;
        }, {})
      );
      clearEditorRefs();
      history.replace(history.location.pathname);
      LocalStorageService.removeData(LocalStorageKeys.GenerationDraft);
      setGenerationDraft(null);
    };

    const handleFormValuesChange = (value, specification) => {
      startTimer();

      const newState = {
        ...inputValueMap,
        [specification.id]: value,
      };
      setInputValueMap(newState);
    };

    const submitProposal = async () => {
      setSubmitted(true);
      if (!dirtyController.isValid())
        return dispatch(
          showErrorToast("В вашей идее содержатся слова, недопустимые на площадке проекта. Пожалуйста, измените текст.")
        );
      if (!isFormValid()) return;

      const formData = {
        toDraft: false,
        stageId: stage.id,
        ...(generationDraft && generationDraft.draftId && { draftId: generationDraft.draftId || null }),
        proposals: [
          {
            ...(generationDraft && generationDraft.id && { editableProposalId: generationDraft.id }),
            title: proposalTitle,
            attachments: files?.map((file) => ({
              id: file.id,
              fileName: file.file.name,
              size: file.file.size,
              hasThumbnail: true,
            })),
            items: stage.specification.items.map((item) => ({
              metaId: item.id,
              orderNumber: item.orderNumber,
              value: inputValueMap[item.id],
            })),
            stageId: stage.id,
          },
        ],
      };

      setSubmitting(true);
      try {
        const response = await ProposalService.submitProposal(formData);
        if (response.status === ApiStatusCode.OK) {
          invalidate();
          onSubmitSuccess();
          setSubmitted(false);
        } else {
          dispatch(showErrorToast(response.message));
        }
      } catch (err) {
        dispatch(showErrorToast(err as string));
      } finally {
        setSubmitting(false);
      }
    };

    function submitDraft(e?, isClearFields = true) {
      if (e) e.preventDefault();

      const formData = {
        toDraft: true,
        stageId: stage.id,
        ...((generationDraft && generationDraft.draftId && { draftId: generationDraft.draftId || null }) || draftId),
        proposals: [
          {
            ...(generationDraft && generationDraft.id && { editableProposalId: generationDraft.id || null }),
            title: proposalTitle,
            attachments: files?.map((file) => ({
              id: file.id,
              fileName: file.file.name,
              size: file.file.size,
              hasThumbnail: true,
            })),
            items: stage.specification.items.map((item) => ({
              metaId: item.id,
              orderNumber: item.orderNumber,
              value: inputValueMap[item.id],
            })),
            stageId: stage.id,
          },
        ],
      };

      setSubmittingDraft(true);
      ProposalService.submitProposal(formData)
        .then(({ data, message, status }) => {
          if (status !== ApiStatusCode.OK) {
            dispatch(showErrorToast(message));
            return;
          }

          if (isClearFields) {
            setDraftId(null);
            invalidate();
          } else {
            setDraftId(data);
          }
          onSubmitDraftSuccess();
        })
        .catch((err) => {
          dispatch(showErrorToast(err as string));
        })
        .finally(() => {
          setSubmittingDraft(false);
          setSubmitted(false);
        });
    }

    const renderSpecification = (specification: Specification) => {
      if (!specification) return;

      return (
        <div key={specification.id}>
          <h4 className="generation__propose-form__title">{specification.title}</h4>
          {showTaskDescription && (
            <span className="generation__propose-form__description">
              <i>{specification.description}</i>
            </span>
          )}

          {specification.type === "FIELD" ? (
            <div>
              <DirtyWordsChecker words={badWordsList} onInit={setDirtyController}>
                <TextAreaEditor
                  placeholder={`Опишите идею${isFieldRequired(specification) ? "*" : ""}`}
                  maxLength={MAX_COMMENT_LEN}
                  value={inputValueMap[specification.id]}
                  isRequired={isFieldRequired(specification)}
                  mustContainLetters
                  restrictForeignSymbByPercent={30}
                  restrictUppercaseSymbByPercent={30}
                  onSetSelection={(sel) => setSelection({ ...selection, [specification.id]: sel })}
                  showError={submitted}
                  getRef={(ref) => {
                    editorRefs.current = { ...editorRefs.current, [specification.id]: ref };
                  }}
                  onInitValidator={(validator) =>
                    setValidatorsMap((validators) => ({
                      ...validators,
                      [specification.id]: validator,
                    }))
                  }
                  onChange={(value) => handleFormValuesChange(value, specification)}
                  onSetCurrentLink={(link) => {
                    setCurrentLink(link);
                    setShowLinkPopup({ ...showLinkPopup, [specification.id]: true });
                  }}
                />
              </DirtyWordsChecker>
              <div className="generation__add-link">
                <Tooltip text={"Добавить ссылку"} idx={draftId + "link"}>
                  <i
                    data-lp
                    className="ui-icon ui-icon-add-link"
                    onClick={() => setShowLinkPopup({ ...showLinkPopup, [specification.id]: true })}
                  ></i>
                </Tooltip>
                {!!showLinkPopup?.[specification.id] && (
                  <LinkPopup
                    text={selection?.[specification.id]?.text}
                    link={currentLink}
                    onEditSave={() =>
                      handleFormValuesChange(
                        replaceHtmlEntites(editorRefs.current[specification.id].innerHTML),
                        specification
                      )
                    }
                    onSubmit={(link) => onAddLink(link, specification.id)}
                    onClose={() => {
                      setCurrentLink(null);
                      setShowLinkPopup({ ...showLinkPopup, [specification.id]: false });
                    }}
                  />
                )}
              </div>
            </div>
          ) : specification.type === "LIST" ? (
            <Select
              items={specification.items?.map((item) => item.value)}
              closeOnSelect={true}
              isRequired={isFieldRequired(specification)}
              isInvalid={submitted && isFieldRequired(specification) && !inputValueMap[specification.id]}
              placeholder={`Выберите значение из списка${isFieldRequired(specification) ? "*" : ""}`}
              onItemSelect={(value) => handleFormValuesChange(value, specification)}
              value={inputValueMap[specification.id]}
            />
          ) : (
            <></>
          )}
        </div>
      );
    };

    const renderFullscreenIcon = () => {
      const node = document.getElementById("generation-modal__icon");
      if (!node) return;

      return createPortal(
        <div className="ui-icon-fullscreen-wrap">
          <Tooltip text={"Открыть на весь экран"} idx={"openFullPagePropose"} place="bottom">
            <button onClick={openGenerationPage} className="ui-icon ui-icon-fullscreen"></button>
          </Tooltip>
        </div>,
        document.getElementById("generation-modal__icon")
      );
    };

    const handleCancel = () => {
      LocalStorageService.removeData(LocalStorageKeys.GenerationDraft);
    };

    return (
      <>
        {showFormDescription ? (
          <>
            <h3 className="generation__title">{stage?.title}</h3>
            <div className="generation__description">
              Перед отправкой своего предложения рекомендуем вам внимательно ознакомиться с{" "}
              <a
                className="generation__link"
                href={`${env.platformUrl}/generation/${stage.id}?type=criteria`}
                target="_blank"
              >
                критериями отбора идей и описанием этапа
              </a>
            </div>
          </>
        ) : (
          <div
            className={"generation__description " + (getPlainFromHtml(stage?.shortStructureDescription) ? "empty" : "")}
            dangerouslySetInnerHTML={{
              __html: getPlainFromHtml(stage?.shortStructureDescription) || "",
            }}
          />
        )}

        <form key={"GenerationForm"} className="generation__propose-form" onSubmit={(e) => e.preventDefault()}>
          {showFullScreenButton && renderFullscreenIcon()}
          {stage?.specification?.items.map((item) => renderSpecification(item))}

          <DirtyWordsChecker words={badWordsList} onInit={setDirtyController}>
            <Input
              value={proposalTitle}
              isRequired={true}
              maxLength={200}
              placeholder="Придумайте заголовок*"
              validateRules={{
                notEmpty: true,
                maxLength: 200,
                mustContainLetters: true,
                restrictForeignSymbByPercent: 30,
                restrictUppercaseSymbByPercent: 30,
              }}
              onInitValidator={(validator) =>
                setValidatorsMap((validators) => ({
                  ...validators,
                  proposalTitle: validator,
                }))
              }
              onChange={(value) => setProposalTitle(value)}
              showError={submitted}
              hint="Не более 200 символов"
              showSymbolsReminded
            />
          </DirtyWordsChecker>
          <FileSelector
            defaultFiles={defaultFiles}
            name={
              <span>
                <span className="ui-icon ui-icon-49" />
                Прикрепить файл
              </span>
            }
            upload
            isMultiple={true}
            maxFiles={5}
            onChange={(files) => {
              setFiles(files);
            }}
            maxTotalSizeMB={25}
            allowedExtensions={["image/jpeg", "image/jpg", "image/png", "application/pdf"]}
            isInvalid={(submitted && !files.length) || false}
            showInfoModal={(msg) => {
              dispatch(showInfoModal(msg));
            }}
          />

          {showRejectReason && rejectReason && (
            <RejectReason
              reason={{ text: rejectReason.text, comment: rejectReason.comment }}
              hint={"Для повторной отправки идеи необходимо скорректировать описание идеи."}
            />
          )}

          <div className="generation__propose-form__controls">
            <Button
              text="Отменить"
              size="mid"
              onClick={() => {
                onClose();
                handleCancel();
              }}
              type="outlined-grey"
            />
            {showDraftButton && (
              <Button
                text="Сохранить черновик"
                size="mid"
                isDisabled={!canSaveDraft()}
                onClick={(e) => submitDraft(e)}
                type="outlined"
                isLoading={submittingDraft}
              />
            )}
            <Button
              text="Отправить"
              size="mid"
              isDisabled={!canSubmit()}
              onClick={submitProposal}
              type="filled"
              isLoading={submitting}
            />
          </div>
        </form>
      </>
    );
  }
);

export default GenerationForm;
