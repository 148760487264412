import { MutableRefObject, useEffect, useRef } from "react";

export function useOuterClick<T>(
  callback: (e: MouseEvent, innerRef: MutableRefObject<T>) => void,
  capture = false,
  isMouseDown = false
): MutableRefObject<T> {
  const innerRef = useRef<any>();
  const callbackRef = useRef<any>();

  useEffect(() => {
    callbackRef.current = callback;
  });

  useEffect(() => {
    const eType = isMouseDown ? "mousedown" : "click";
    document.addEventListener(eType, handleClick, capture);
    return () => document.removeEventListener(eType, handleClick, capture);

    function handleClick(e) {
      if (innerRef.current && callbackRef.current && !innerRef.current.contains(e.target)) {
        callbackRef.current(e);
      }
    }
  }, [capture, isMouseDown]);

  return innerRef;
}
