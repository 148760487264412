import { User, UserTypeEnum } from "../../../types/User.interface";

const userMock: User = {
  accountType: "NATIVE",
  agWidgetSignature: null,
  agWidgetUser: null,
  agreementConfirmed: false,
  birthYear: null,
  blocked: false,
  blockedOnProjects: [],
  commentCount: null,
  controlAnswer: null,
  controlQuestion: null,
  deletedSudir: false,
  description: null,
  email: "_-1",
  emailVerified: true,
  firstName: "anonymous",
  gender: null,
  id: "_-1",
  ideaPhotoCount: 0,
  lastName: "anonymous",
  login: null,
  main: false,
  moscowDistrict: null,
  moscowRegion: null,
  nativeRegistrationDate: null,
  partiallyFilled: false,
  passwordExpired: false,
  phone: null,
  pictureId: "undefined",
  points: null,
  position: null,
  projectsCount: 0,
  proposalCount: null,
  proposalVoteCount: null,
  registrationDate: "2024-06-11T17:21:48.140+03:00",
  showName: true,
  showParticipation: false,
  ssoid: null,
  userType: UserTypeEnum.SHORT,
  loggedIn: false,
  roles: [],
  projects: [],
  sudirRegistrationDate: null,
  userSystems: [],
  passwordLastChanged: null
};

export { userMock };
