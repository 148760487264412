import React from "react";
import Game from "./Game/Game";
import { MemoryGameProps } from "./MemoryGame.types";
import "./MemoryGame.scss";
import GameWrapper from "../Wrappers/GameWrapper/GameWrapper";
import { MosProjects } from "../../../../../types/Projects.interface";
import { cards } from "./Game/Game.helper";
import snowSvg from "../../../../../assets/newyear2023/games/memory/Snowflake.svg";
import useImagePreload from "../../hooks/useImagePreload";
import { gameText, gameTitle } from "./MemoryGame.constants";

const imageSources = [...Array.from(cards, (el) => el.src), snowSvg];

const MemoryGame = ({ onWin }: MemoryGameProps) => {
  const isLoad = useImagePreload(imageSources);

  return (
    <GameWrapper gameTitle={gameTitle} startText={gameText} onWin={onWin} project={MosProjects.ED}>
      {isLoad && <Game />}
    </GameWrapper>
  );
};

export default MemoryGame;
