import React, { useEffect, useState } from "react";
import { AppModal } from "../../Modal/AppModal";
import Button from "../../../presentational/Controls/Button/Button";
import "./JobReportsModal.scss";
import EventsTrackWrapperClick from "../../EventsTrack/wrappers/EventsTrackWrapperClick";
import EventsTrackClickModalCloseBtn from "../../EventsTrack/wrappers/helpers/EventsTrackClickModalCloseBtn";
import Select from "../../../presentational/Controls/Select/Select";

export interface JobReport {
  url: string;
  title: string;
  year: number;
}

type Props = {
  reports: JobReport[];
  isOpened: boolean | null;
  onClose: () => void;
};

const years = [
  {
    year: 2023,
  },
  {
    year: 2022,
  },
  {
    year: 2021,
  },
  {
    year: 2020,
  },
];

const JobReportsModal = ({ reports, isOpened, onClose }: Props) => {
  const [year, setYear] = useState(years[0].year);
  const [batched, setBatched] = useState({});

  useEffect(() => {
    setBatched(batchByYear(reports));
  }, []);

  function batchByYear(reports) {
    return reports.reduce((memo, curr) => {
      !memo[curr.year] && (memo[curr.year] = []);
      memo[curr.year].push(curr);
      return memo;
    }, {});
  }

  const getDescription = (): string | JSX.Element => {
    return reports?.length ? (
      "С гордостью делимся с вами результатами, которых удалось достичь городу благодаря инициативам жителей, полученным на платформе «Город идей»."
    ) : (
      <div style={{ textAlign: "center" }}>Отчеты отсутствуют</div>
    );
  };

  return (
    <AppModal
      center
      classNames={{ modal: "job-reports-modal" }}
      overlayId="modal-overlay"
      closeIconId="close-modal-icon"
      blockScroll={true}
      closeIconSize={25}
      open={!!isOpened}
      closeOnEsc={true}
      closeOnOverlayClick={false}
      onClose={onClose}
      CloseBtnComponent={EventsTrackClickModalCloseBtn}
    >
      <div className="job-reports">
        <div className="job-reports__title">Выбор отчета</div>
        <div className="job-reports__description">{getDescription()}</div>

        <Select
          items={years}
          bindTo="year"
          value={year}
          closeOnSelect={true}
          placeholder="Выберите год"
          label="Выберите отчетный год"
          onItemSelect={(item) => {
            setYear(item.year);
          }}
        />

        <ul className="job-reports__links">
          {batched[year]?.map((report, key) => (
            <li className="job-reports__link" key={key}>
              <EventsTrackWrapperClick id={196} replace={[report.title]}>
                <Button
                  text={report.title}
                  type="filled"
                  linkUrl={report.url}
                />
              </EventsTrackWrapperClick>
            </li>
          ))}
        </ul>
      </div>
    </AppModal>
  );
};

export default JobReportsModal;
