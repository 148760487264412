import { forwardRef } from "react";
import { ComponentProps } from "react";
import Input from "../../../../presentational/Controls/Input/Input";
import EventsTrackWrapperClick from "../EventsTrackWrapperClick";

const EventsTrackClickInput = forwardRef<HTMLInputElement, ComponentProps<ComponentProps<typeof Input>["ComponentInput"]>>( ({label, ...otherProps}, ref) => (
    <EventsTrackWrapperClick id={36} replace={[label]}>
        <input ref={ref} {...otherProps} />
    </EventsTrackWrapperClick>
) );

export default EventsTrackClickInput;