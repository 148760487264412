//@flow
import React, { useEffect, useRef, useState } from "react";
import { useOuterClick } from "../../../hooks/useOnOutsideClick";
import EventsTrackWrapperClick from "../../containers/EventsTrack/wrappers/EventsTrackWrapperClick";

import "./Share.scss";
// type Props = {
//   url: string,
//   imageUrl: string,
//   title: string,
//   colorIcon?: string,
//   backgroundColorIcon?: string,
// };

declare global {
  interface Window {
    FB: any;
  }
}

const Share = (props) => {
  const { url, imageUrl, title, backgroundColorIcon, colorIcon } = props;
  const [isShowIcons, setIsShowIcons] = useState(false);
  const fbRef = useRef(null);
  const vkUrl = "https://vk.com/share.php?";
  const twUrl = "http://twitter.com/share?";
  const okUrl = "https://connect.ok.ru/offer?";
  const fbUrl = "https://www.facebook.com/sharer/sharer.php?";
  const tgUrl = "https://t.me/share/url?";
  const waUrl = "https://wa.me?";

  // useEffect(() => fbXfbmlParse(), [isShowIcons]);

  const fbXfbmlParse = () => {
    try {
      if (isShowIcons || props.type === "unfolded") {
        typeof window.FB != "undefined" && window.FB != null
          ? window.FB.XFBML.parse(fbRef.current)
          : console.log("facebook share button error");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const shareRef = useOuterClick<HTMLInputElement>((event: any) => {
    setIsShowIcons(false);
  });

  const renderFbIcon = () => (
    <div style={{ position: "relative" }} className={`ic ui-icon-101 ${props.isColorized ? "fb-hover" : ""}`}>
      {/* <div className="fb-share-block"> */}
      <div
        // className="fb-share-button"
        data-size="small"
        style={{
          position: "absolute",
          height: "100%",
          width: "100%",
          left: 0,
          top: 0,
        }}
        // href={
        //   `https://www.facebook.com/sharer/sharer.php?u=${window.location.origin}` +
        //   props.url
        // }
        onClick={(e) => share("fb")}
        // target="_blank"
        data-layout="button"
      />
      {/* </div> */}
    </div>
  );

  const renderSocialIcons = () => {
    let classes = "share-icons";
    if (props.type === "unfolded") classes += " share-icons-unfolded";

    return (
      <div className={classes} ref={shareRef}>
        <EventsTrackWrapperClick needParent id={["SHARE", "CLICK"]} replace={["Vkontakte"]}>
          <div className={`ic ui-icon-98 ${props.isColorized ? "vk-hover" : ""}`} onClick={(e) => share("vk")} />
        </EventsTrackWrapperClick>
        {/* {renderFbIcon()}
        <div
          className={`ic ui-icon-99 ${props.isColorized ? "tw-hover" : ""}`}
          onClick={(e) => share("tw")}
        /> */}
        <EventsTrackWrapperClick needParent id={["SHARE", "CLICK"]} replace={["Одноклассники"]}>
          <div className={`ic ui-icon-100 ${props.isColorized ? "ok-hover" : ""}`} onClick={(e) => share("ok")} />
        </EventsTrackWrapperClick>
        <EventsTrackWrapperClick needParent id={["SHARE", "CLICK"]} replace={["Telegram"]}>
          <div className={`ic ui-icon-1106 ${props.isColorized ? "tg-hover" : ""}`} onClick={(e) => share("tg")} />
        </EventsTrackWrapperClick>
        <EventsTrackWrapperClick needParent id={["SHARE", "CLICK"]} replace={["Whatsapp"]}>
          <div className={`ic ui-icon-1107 ${props.isColorized ? "wa-hover" : ""}`} onClick={(e) => share("wa")} />
        </EventsTrackWrapperClick>
      </div>
    );
  };

  const getLocationHref = () => `${window.location.protocol}//${window.location.host}`;
  const getUrl = () => `${getLocationHref() + url}`;
  const getImageUrl = () => `${getLocationHref() + imageUrl}`;
  const popup = (url) =>
    window.open(
      url,
      "",
      `toolbar=0,status=0,width=626,height=436, top=${(window.screen.height - 436) / 2}, left=${
        (window.screen.width - 626) / 2
      }`
    );

  //provider: "vk" | "tw" | "ok" | "tg" | "wa"
  const share = (provider) => {
    const _url = encodeURIComponent(getUrl());
    const _imageUrl = encodeURIComponent(getImageUrl());
    const _title = encodeURIComponent(title);

    provider === "vk" && popup(`${vkUrl}redir=1&url=${_url}&image=${_imageUrl}&title=${_title}&noparse=true`);
    provider === "tw" && popup(`${twUrl}url=${_url}&counturl=${_url}`);
    provider === "ok" && popup(`${okUrl}url=${_url}&image=${_imageUrl}&title=${_title}`);
    provider === "fb" && popup(`${fbUrl}u=${_url}`);
    provider === "tg" && popup(`${tgUrl}url=${_url}&text=${_title}`);
    provider === "wa" && popup(`${waUrl}text=${_title}%20${_url}`);
  };

  const renderUnfolded = () => {
    return (
      <div className="share-icon-unfolded" style={{ color: colorIcon }}>
        {renderSocialIcons()}
      </div>
    );
  };

  const renderDefault = () => {
    return (
      <div
        className="share-icon"
        style={{
          backgroundColor: backgroundColorIcon,
          color: colorIcon,
          borderTopColor: backgroundColorIcon,
        }}
        onClick={(e) => {
          e.stopPropagation();
          setIsShowIcons(!isShowIcons);
        }}
      >
        {isShowIcons && renderSocialIcons()}

        <div className="ic ui-icon-93" />
      </div>
    );
  };

  const renderContent = () => {
    switch (props.type) {
      case "unfolded":
        return renderUnfolded();
      default:
        return renderDefault();
    }
  };

  return <>{renderContent()}</>;
};

export default Share;
