import { useContext, useEffect, useMemo, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  debounce,
  getRandomInt,
  shuffle,
  throttle,
} from "../../../../../utils";
import SwiperCore from "swiper";
import "./BirthdayGallery.scss";
import Platform from "../../../../../services/platform";
import { Link } from "react-router-dom";
import Button from "../../../../presentational/Controls/Button/Button";
import ProposeIdeaForm from "../../../../containers/Forms/ProposeIdeaForm/ProposeIdeaForm";
import ProposeIdeaSubmittedForm from "../../../../containers/Forms/ProposeIdeaForm/ProposeIdeaSubmittedForm";
import GorodService, {
  GorodIdeaSphere,
} from "../../../../../services/gorodService";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../types/State.interface";
import {
  AuthAction,
  AuthActionParam,
  AuthActionType,
  ComponentType,
} from "../../../../../services/sudirService";
import { AppContext } from "../../../../Root";
import { BirthdayUrls } from "../../CrowdBirthday9.helper";

const isMobileDevice =
  Platform.isPhone() || Platform.isTablet() || Platform.isIPadOS();

const srcImagesContext = (require as any).context(
  "../../../../../assets/birthday9/images/gallery/",
  false
);
const srcImages = srcImagesContext
  .keys()
  .map(srcImagesContext)
  .map((m) => m.default);

const stepSpeed = 300;

const getDefaultProgressStepSize = (reverse: boolean) =>
  (reverse ? -1 : 1) / srcImages.length / 30;

const moveSwiper = (swiper: SwiperCore, progressStep: number) => {
  (swiper as any).loopFix();
  (swiper as any)._clientLeft = swiper.wrapperEl.clientLeft;
  swiper.setProgress(swiper.progress + progressStep, stepSpeed);
};

const BirthdayGallery = () => {
  const appContext = useContext(AppContext);

  const [swiper, setSwiper] = useState<SwiperCore>();
  const [isReverse, setIsReverse] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);
  const [proposeFormOpen, setProposeFormOpen] = useState(false);
  const [isSubmittedProposeModalOpen, setIsSubmittedProposeModalOpen] =
    useState(false);
  const [selectedSphere, setSelectedSphere] = useState<GorodIdeaSphere>();

  const user = useSelector((state: RootState) => state.user.userDetails);
  const spheres = useSelector((state: RootState) => state.gorod.spheres);

  const slides = useMemo(
    () =>
      shuffle(srcImages).map((src) => (
        <SwiperSlide key={src}>
          <div style={{ transform: `rotate(${getRandomInt(-14, 14)}deg)` }}>
            <img src={src} alt="" />
          </div>
        </SwiperSlide>
      )),
    []
  );

  const openProposeForm = (sphere?: GorodIdeaSphere) => {
    if (!user?.loggedIn) {
      const thisContainerData: AuthActionParam = {
        component: ComponentType.BIRTHDAY9_GALLERY,
        type: AuthActionType.OPEN_PROPOSE_MODAL,
        args: {
          code: sphere?.code || null,
        },
      };

      const action = new AuthAction(thisContainerData);
      return appContext.sudirService.authWithActions([action]);
    }

    setSelectedSphere(sphere);
    setProposeFormOpen(true);
  };

  // ACTIONS AFTER AUTH
  useEffect(() => {
    if (!spheres?.length) return;

    const actions = appContext.sudirService.getActions(
      ComponentType.BIRTHDAY9_GALLERY
    );
    if (actions.length) {
      const actionData = actions.find(
        (act) => act.type === AuthActionType.OPEN_PROPOSE_MODAL
      );
      if (actionData) {
        const { code } = actionData.args;
        const sphere = GorodService.getSphereByCode(code, spheres);

        const element = document.getElementById("gallery");
        if (element) {
          element.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
          setTimeout(() => openProposeForm(sphere), 1000);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [spheres]);

  useEffect(() => {
    if (!isMobileDevice) {
      const wheelHandler = throttle((e: WheelEvent) => {
        const reverse = e.deltaY < 0;
        setIsReverse(reverse);
        if (swiper && isAnimating && !swiper.animating) {
          const progressStep = getDefaultProgressStepSize(reverse);
          moveSwiper(swiper, progressStep);
        }
      }, 100);

      window.addEventListener("wheel", wheelHandler, true);

      return () => {
        window.removeEventListener("wheel", wheelHandler, true);
        wheelHandler.cancel();
      };
    }
  }, [isAnimating, swiper]);

  useEffect(() => {
    if (swiper && !isMobileDevice) {
      const disableAnimation = () => setIsAnimating(false);
      const enableAnimation = () => setIsAnimating(true);

      const el = swiper.el;

      el.addEventListener("mouseenter", disableAnimation);
      el.addEventListener("mouseleave", enableAnimation);

      const observer = new IntersectionObserver((entries) => {
        entries[0].isIntersecting ? enableAnimation() : disableAnimation();
      });
      observer.observe(el);

      return () => {
        el.removeEventListener("mouseenter", disableAnimation);
        el.removeEventListener("mouseleave", enableAnimation);

        observer.unobserve(el);
      };
    }
  }, [swiper]);

  useEffect(() => {
    if (swiper && isAnimating && !isMobileDevice) {
      const el = swiper.wrapperEl;
      const eventTypes = ["transitionend", "webkitTransitionEnd"];
      const progressStep = getDefaultProgressStepSize(isReverse);
      const move = () => moveSwiper(swiper, progressStep);
      const resizeHandler = debounce(move, 500);

      eventTypes.forEach((eType) => {
        el.addEventListener(eType, move);
      });
      window.addEventListener("resize", resizeHandler, true);

      move();

      return () => {
        eventTypes.forEach((eType) => {
          el.removeEventListener(eType, move);
        });
        window.removeEventListener("resize", resizeHandler, true);
        resizeHandler.cancel();
      };
    }
  }, [isReverse, isAnimating, swiper]);

  return (
    <>
      {proposeFormOpen && (
        <ProposeIdeaForm
          isOpen
          onClose={() => setProposeFormOpen(false)}
          setIsSubmittedProposeModalOpen={setIsSubmittedProposeModalOpen}
          sphere={selectedSphere}
        />
      )}
      {isSubmittedProposeModalOpen && (
        <ProposeIdeaSubmittedForm
          isOpen
          onClose={() => setIsSubmittedProposeModalOpen(false)}
          handleOpenPropose={openProposeForm}
        />
      )}
      <section id="gallery" className="birthday-gallery">
        <div className="birthday-block-lessened">
          <div className="birthday-gallery__inner">
            <div className="birthday-title">Галерея реализованных идей</div>
            <div className="birthday-gallery__subtitle">
              Ознакомьтесь с фотографиями реализованных идей, которые предлагали жители на платформе «Город идей».<br></br>
              Предлагайте идеи в любое время в разделе «Предложить идею».
            </div>
            <Button
              type="filled"
              text="Предложить идею"
              linkUrl="/propose"
              hrefTarget="_blank"
            />
          </div>
        </div>

        <Swiper
          slidesPerView="auto"
          loop
          onSwiper={setSwiper}
          allowTouchMove={isMobileDevice}
        >
          {slides}
        </Swiper>
      </section>
    </>
  );
};

export default BirthdayGallery;
