import { AttachmentDto } from "./Attachment.interface";

export enum ProposalTypeEnum {
  DRAFT = "DRAFT",
  SUBMITTED = "SUBMITTED",
  NOT_SUBMITTED = "NOT_SUBMITTED",
  ACCEPTED = "ACCEPTED",
  REJECTED = "REJECTED",
  REMOVED = "REMOVED",
  FORWARDED = "FORWARDED",
}

export interface ProposalItemContentRequest {
  metaId: string;
  value: string;
  valueRaw?: string;
  orderNumber: number;
}

export interface ProposalContentRequest {
  id?: string;
  title: string;
  stageId: string;
  oldStageId?: string;
  editableProposalId?: string;
  lastSubmitted?: string;
  lastEdited?: string;
  items: ProposalItemContentRequest[];
  attachments: AttachmentDto[];
}

export interface ProposalSubmitRequest {
  stageId: string;
  draftId?: string;
  toDraft: boolean;
  proposals: ProposalContentRequest[];
}
