import React, { FC } from "react";
import "./BurgerButton.scss";

interface BurgerButtonProps {
  onClick: (event) => void;
  active: boolean
}

const BurgerButton: FC<BurgerButtonProps> = ({ onClick, active }) => {
  return (
    <button className={`new-year-2023-header-burger ${active ? 'new-year-2023-header-burger--active' : ''}`} onClick={onClick}>
      <span className={"new-year-2023-header-burger__burgerLine"} />
    </button>
  );
};

export default BurgerButton;
