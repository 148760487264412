import { FC, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectLeisureLandingProgress } from "../../../../../../store/reducers/leisureLanding";
import { step } from "../../../utils/constants";
import "./FooterProgress.scss";
import { FooterProgressProps } from "./FooterProgress.types";

const getClassesNav = (direction: string, disabled: boolean) => `
  leisure-landing-footer-progress__nav
  leisure-landing-footer-progress__nav-${direction}
  ${disabled ? "leisure-landing-footer-progress__nav_disabled" : ""}
`;

const FooterProgress: FC<FooterProgressProps> = ({ paused = false, addProgress }) => {
  const dispatch = useDispatch();
  const progress = useSelector(selectLeisureLandingProgress);
  const [moving, setMoving] = useState(0); // движение героя: -1 назад, 1 вперед, 0 стоит на месте

  const moveForward = () => setMoving(1);
  const moveBack = () => setMoving(-1);
  const stopMoving = () => setMoving(0);

  const addProgressRef = useRef(addProgress);
  useEffect(() => {
    addProgressRef.current = addProgress;
  });

  useEffect(() => {
    if (!moving || paused) {
      return;
    }

    let interval;
    const timeout = setTimeout(() => {
      interval = setInterval(() => addProgressRef.current(step * moving), 30);
    }, 500);

    return () => {
      clearTimeout(timeout);
      clearInterval(interval);
      if (!interval) {
        addProgressRef.current(step * moving);
      }
    };
  }, [dispatch, moving, paused]);

  const d = 50;
  const strokeWidth = 2;
  const size = d + strokeWidth;
  const r = d / 2;
  const c = d * Math.PI;
  const cPart = c - Math.min(progress * c, c);

  return (
    <div className="leisure-landing-footer-progress">
      <span
        className={getClassesNav("prev", !moving && progress === 0)}
        onTouchStart={moveBack}
        onTouchCancel={stopMoving}
        onTouchEnd={stopMoving}
        onMouseDown={moveBack}
        onMouseUp={stopMoving}
        onMouseLeave={stopMoving}
        onClick={(e) => e.stopPropagation()}
      />
      <span className="leisure-landing-footer-progress__bar">
        <span className="leisure-landing-footer-progress__bar__bg" />
        <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`}>
          <circle
            cx="50%"
            cy="50%"
            r={r}
            strokeWidth={strokeWidth}
            strokeDashoffset={cPart}
            strokeDasharray={c}
            fill="none"
            strokeLinecap="round"
          />
        </svg>
      </span>
      <span
        className={getClassesNav("next", !moving && progress === 1)}
        onTouchStart={moveForward}
        onTouchCancel={stopMoving}
        onTouchEnd={stopMoving}
        onMouseDown={moveForward}
        onMouseUp={stopMoving}
        onMouseLeave={stopMoving}
        onClick={(e) => e.stopPropagation()}
      />
    </div>
  );
};

export default FooterProgress;
