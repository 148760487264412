import { ComponentProps } from "react";
import { AppModal } from "../../../Modal/AppModal";
import CloseButton from "../../../Modal/CloseButton/CloseButton";
import EventsTrackWrapperClick from "../EventsTrackWrapperClick";

const EventsTrackClickModalCloseBtn: ComponentProps<typeof AppModal>["CloseBtnComponent"] = ({ onClick }) => (
    <EventsTrackWrapperClick id={14}>
        <CloseButton onClick={ onClick } />
    </EventsTrackWrapperClick>
);

export default EventsTrackClickModalCloseBtn;