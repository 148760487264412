import React from "react";

import "./FilePreview.scss";

interface State {}

interface Props {
  file: File;
  preview: string;
  size: string;
  onDelete: Function;
}

class FilePreview extends React.Component<Props, State> {
  constructor(props) {
    super(props);
  }

  close = () => {
    if (this.props.onDelete && typeof this.props.onDelete === "function") {
      this.props.onDelete(this.props.file);
    } else {
      console.error("Please provide a delete callback for FilePreview component");
    }
  };

  renderImageByType = () => {
    switch (this.props.file?.type) {
      case "application/pdf":
        return <div className="cr-file-preview__img" dangerouslySetInnerHTML={{ __html: this.props.preview }} />;

      default:
        return <img className="cr-file-preview__img" src={this.props.preview} alt="preview image" />;
    }
  };

  render() {
    return (
      <div className="cr-file-preview">
        {this.props.preview && this.renderImageByType()}
        <div className="cr-file-preview__close" onClick={this.close}>
          X
        </div>
        <div className="cr-file-preview__size">{this.props.size}</div>
      </div>
    );
  }
}

export default FilePreview;
