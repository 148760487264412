import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { HashLink as Link } from "react-router-hash-link";

import ProfileLoader from "../../presentational/Loaders/ProfileLoader/ProfileLoader";
import Logo from "./Logo/Logo";
import ProfilePic from "../../presentational/ProfilePic/ProfilePic";

import { scrollTop } from "../../../utils/helperFunctions";

import "./Header.scss";
import { User } from "../../../types/User.interface";
import {
  AuthAction,
  AuthActionParam,
  AuthActionType,
  ComponentType,
  SudirService,
} from "../../../services/sudirService";
import { isParticipant, limitNumberToMax } from "../../../utils";
import UserService from "../../../services/userService";
import { getUser } from "../../../store/actions/UserActions";
import { API } from "../../../services/api";
import EventsTrackWrapperClick from "../EventsTrack/wrappers/EventsTrackWrapperClick";
import CrossPromo from "./CrossPromo/CrossPromo";
import classNames from "classnames";

interface Props {
  showMosRuAuthForm: Function;
  hideMosRuAuthForm: Function;
  routes: any;
  logo: any;
  mobileRoutes: any;
  user: User;
  unreadNotifications: Number;
  logout: (history) => void;
  isLoading?: boolean;
  loggedIn: boolean;
  getUser: () => void;
  sudirService: any;
}

interface State {
  dropdown: { [key: string]: boolean };
}

class Header extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      dropdown: {},
    };
  }

  componentDidUpdate() {
    if (this.props.loggedIn) {
      this.checkActionsAfterAuth();
    }
  }

  checkActionsAfterAuth() {
    const actions = this.props.sudirService.getActions(ComponentType.HEADER);
    if (actions.length) {
      const joinAllProjectsAction = actions.find((act) => act.type === AuthActionType.JOIN_PROJECTS_SILENTLY);
      if (joinAllProjectsAction) {
        this.joinAllProjects();
      }
    }
  }

  joinAllProjects() {
    const user = this.props.user;
    const projectTypes = ["CURRENT", "ANNOUNCEMENT"];
    if (user && !user.partiallyFilled) {
      const reqs = projectTypes.map((type) => API.get(`/api/project/get?type=${type}`));
      Promise.all(reqs)
        .then(([currentResponse, announceResponse]) => {
          const currentProjects = currentResponse.data.data;
          const announceProjects = announceResponse.data.data;
          const projectsToJoin = [...currentProjects, ...announceProjects].filter(
            (project) => !isParticipant(user, project.id)
          );
          UserService.addToProjects({ ids: projectsToJoin.map((project) => project.id) })
            .catch((err) => console.log(err))
            .finally(() => this.props.getUser());
        })
        .catch(console.log);
    }
  }

  openModal = () => {
    if (!this.props.loggedIn) {
      const thisContainerData: AuthActionParam = {
        component: ComponentType.HEADER,
        type: AuthActionType.JOIN_PROJECTS_SILENTLY,
        args: {},
      };

      const action1 = new AuthAction(thisContainerData);
      return this.props.sudirService.authWithActions([action1]);
    }
  };

  closeModal = () => {
    this.props.hideMosRuAuthForm();
  };

  openDropdown = (id) => {
    setTimeout(() => {
      this.setState({ dropdown: { ...this.state.dropdown, [id]: true } });
    });
  };

  closeDropdown = (id) => {
    setTimeout(() => {
      this.setState({ dropdown: { ...this.state.dropdown, [id]: false } });
    });
  };

  isActiveLink = (pathname, search, route) => {
    const cleanSearch = search.replace(/[?&]/g, '');
    const cleanRoute = route.replace(/[?&]/g, '');
    return pathname !== "/" && (route?.includes(pathname) || (search && cleanSearch.includes(cleanRoute)));
  };

  isActiveListItem = (pathname, children) => {
    return pathname !== "/" && children.findIndex((child) => child.route.startsWith(pathname)) !== -1;
  };


  // RENDER

  renderLinks = () => {
    const {pathname, search} = document.location;
    return (
      <ul className="cr-header__inner-links">
        {this.props.routes?.map((item) =>
          !item.children ? (
            <li className="cr-header__inner-links-link cr-header-link" key={item.id}>
              <EventsTrackWrapperClick id={item.trackClickId}>
                <Link
                  to={item.route}
                  activeClassName="active-link"
                  className={this.isActiveLink(pathname, search, item.route) ? "active-link" : ""}
                >
                  {item.text}
                </Link>
              </EventsTrackWrapperClick>
              {item.badge && <span className="cr-header__inner-links-link-badge">{item.badge}</span>}
            </li>
          ) : (
            <EventsTrackWrapperClick key={item.text} id={item.trackClickId}>
              <li
                key={item.id}
                className={`cr-header__inner-links-dropdown cr-header-link ${
                  this.isActiveListItem(pathname, item.children) ? "active-link" : ""
                }`}
                onMouseEnter={() => this.openDropdown(item.id)}
                onMouseLeave={() => this.closeDropdown(item.id)}
                onClick={() => this.openDropdown(item.id)}
              >
                {item.text}
                {this.state.dropdown[item.id] && (
                  <div className="cr-header__inner-links-dropdown-menu">
                    {item.children.map((item) => (
                      <EventsTrackWrapperClick key={item.text} id={item.trackClickId}>
                        <NavLink
                          key={item.id}
                          onClick={this.closeDropdown}
                          to={item.route}
                          activeClassName="active-link"
                        >
                          {item.text}
                        </NavLink>
                      </EventsTrackWrapperClick>
                    ))}
                  </div>
                )}
              </li>
            </EventsTrackWrapperClick>
          )
        )}
      </ul>
    );
  };

  renderMobileLinks = () => {
    const {pathname, search} = document.location;
    return (
      <div className="cr-header__inner-mobile cr-header-link">
        <EventsTrackWrapperClick id={["HEADER", 22]}>
          <div
            tabIndex={0}
            className="ui-icon-39 ic"
            onClick={() => this.openDropdown("mobile")}
            onBlur={() => this.closeDropdown("mobile")}
          />
        </EventsTrackWrapperClick>

        {this.state.dropdown["mobile"] && (
          <div className="cr-header__inner-mobile-dropdown" tabIndex={1} onMouseDown={(e) => e.preventDefault()}>
            <div className="cr-header__inner-mobile-dropdown-head">
              {this.renderUserLink(false)}

              <div className="ic ui-icon-14" onClick={() => this.closeDropdown("mobile")} />
            </div>

            {this.props.mobileRoutes?.map((item) =>
              !item.children ? (
                <li className="cr-header__inner-mobile-dropdown-link" key={item.route}>
                  <EventsTrackWrapperClick id={item.trackClickId}>
                    <Link
                      to={item.route}
                      onClick={() => this.closeDropdown("mobile")}
                      activeClassName="active-link"
                      className={this.isActiveLink(pathname, search, item.route) ? "active-link" : ""}
                    >
                      {item.text}
                    </Link>
                  </EventsTrackWrapperClick>
                  {item.badge && <span className="cr-header__inner-mobile-dropdown-link-badge">{item.badge}</span>}
                </li>
              ) : (
                <div className="cr-header__inner-mobile-dropdown-link" key={item.text}>
                  {item.text}
                  <div>
                    {item.children.map((item) => (
                      <EventsTrackWrapperClick key={item.text} id={item.trackClickId}>
                        <NavLink
                          to={item.route}
                          onClick={() => this.closeDropdown("mobile")}
                          activeClassName="active-link"
                        >
                          {item.text}
                        </NavLink>
                      </EventsTrackWrapperClick>
                    ))}
                  </div>
                </div>
              )
            )}
          </div>
        )}
      </div>
    );
  };

  renderUserLink = (withNotifications) => {
    if (!this.props.loggedIn) return;

    return (
      <EventsTrackWrapperClick id={15}>
        <Link className="cr-header__userlink cr-header-link" to="/profile">
          <ProfilePic imageId={this.props.user.pictureId} isRounded={true} />

          {withNotifications && this.props.unreadNotifications > 0 && (
            <div className="cr-header__userlink-notifications">
              {limitNumberToMax(this.props.unreadNotifications, 99)}
            </div>
          )}
        </Link>
      </EventsTrackWrapperClick>
    );
  };

  renderLoggedIn = () => {
    return (
      <div className="cr-header__inner-profile__loggedIn">
        {this.renderUserLink(true)}

        <EventsTrackWrapperClick id={16}>
          <div className="ic ui-icon-45" onClick={this.props.logout}></div>
        </EventsTrackWrapperClick>

        <EventsTrackWrapperClick id={16}>
          <div onClick={this.props.logout} className="cr-header__inner-logInOutBtn">
            Выйти
          </div>
        </EventsTrackWrapperClick>
      </div>
    );
  };

  renderLoggedOut = () => {
    return (
      <div className="cr-header__inner-profile__loggedOut">
        <EventsTrackWrapperClick id={11}>
          <div className="ic ui-icon-87" onClick={() => this.openModal()}></div>
        </EventsTrackWrapperClick>

        <EventsTrackWrapperClick id={11}>
          <div onClick={() => this.openModal()} className="cr-header__inner-logInOutBtn">
            Войти
          </div>
        </EventsTrackWrapperClick>
      </div>
    );
  };

  renderProfile = () => {
    return (
      <div className="cr-header__inner-profile cr-header-link">
        {this.props.isLoading ? "" : this.props.loggedIn ? this.renderLoggedIn() : this.renderLoggedOut()}
      </div>
    );
  };

  renderLoader = () => {
    return <ProfileLoader />;
  };

  renderHomeLink = () => {
    return (
      <div className="cr-header__home">
        <div className="cross-promo__divider"></div>
        <Link to={{ pathname: "/" }} target="_blank">
          <i className="ui-icon ui-icon-home"></i>
        </Link>
      </div>
    );
  };

  render() {
    return (
      <div className={classNames("cr-header", `cr-header__${window.location.pathname.slice(1) || "base"}`)}>
        <div className="cr-header__inner">
          <EventsTrackWrapperClick id={1}>
            <Logo onLogoClick={scrollTop} options={this.props.logo} />
          </EventsTrackWrapperClick>
          {this.renderLinks()}
          {<CrossPromo />}
          {this.renderMobileLinks()}
          {this.renderProfile()}
          {this.renderHomeLink()}
        </div>
      </div>
    );
  }
}

// export default Header;

const mapStateToProps = (state) => {
  return {
    isLoading: state.user.isFetching,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getUser: () => dispatch(getUser()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
