import { User } from "../../../../../types/User.interface";

export enum DiscussionStatus {
  STARTED = "STARTED",
  NEW = "NEW",
  FINISHED = "FINISHED",
}

export interface Discussion {
  id: string;
  title: string;
  description: string;
  start: string;
  finish: string;
  status: DiscussionStatus;
  taskSpecification: {
    posts: {
      content: string;
      id: string;
      title: string;
    }[];
  };
}

export interface Comment {
  author: {
    id: string;
    firstName: string;
    lastName: string;
    online: true;
    pictureId: string;
    lastActivityDate: string;
    participant: boolean;
  };
  date: string;
  discussionId: string;
  id: string;
  stageId: string;
  text: string;
  topAssignmentDate: string;
  upVotes: number;
  voted: boolean;
}
