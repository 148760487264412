import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";

import Button from "../../presentational/Controls/Button/Button";
import {
  showInfoModal,
  hideInfoModal,
} from "../../../store/actions/LayoutActions";
import UserService from "../../../services/userService";
import { findUrlParam } from "../../../utils";

import ChangePasswordForm from "../../containers/Forms/ChangePasswordForm/ChangePasswordForm";
import { PasswordChangeParams } from "../../../types/User.interface";

const SetNewPasswordContainer = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const user = findUrlParam("user", location.search);
  const key = findUrlParam("key", location.search);
  const [isLoading, setIsLoading] = useState(false);

  const submitForm = (params: PasswordChangeParams) => {
    setIsLoading(true);

    const query = {
      user,
      key,
      password: params.newPassword,
      repeatPassword: params.newPassword,
    };

    UserService.setNewPassword(query)
      .then((response) => {
        if (response.data.status === 0) {
          dispatch(
            showInfoModal("Пароль успешно установлен.", renderButton("Хорошо"))
          );
          history.replace("/teamLogin");
        } else {
          dispatch(
            showInfoModal(response.data.message, renderButton("Закрыть"))
          );
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const renderButton = (buttonText) => {
    return (
      <Button
        type="outlined"
        text={buttonText}
        onClick={() => dispatch(hideInfoModal())}
      />
    );
  };

  return (
    <ChangePasswordForm
      requireCurrentPassword={false}
      onSubmit={submitForm}
      title="Придумайте пароль для вашей учетной записи"
      renderButton={renderButton}
      isLoading={isLoading}
      submitButtonLabel="Отправить"
    />
  );
};

export default SetNewPasswordContainer;
