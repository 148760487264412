import { forwardRef } from "react";

const Line5 = forwardRef<SVGPathElement>((_, ref) => (
  <svg
    width="1439"
    height="1091"
    viewBox="0 0 1439 1091"
    fill="none"
    style={{ transform: "translate(13px, -1px)" }}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      ref={ref}
      d="M95.7866 1C39.2942 22.0708 -20.0085 107.212 8.29832 207.083C48.7929 349.953 235.734 364.857 421.209 314.492C499.807 293.149 553.191 187.862 479.201 96.5894C386.713 -17.5012 381.214 190.619 611.183 229.181C893.146 276.462 1179.88 399.289 1346.85 578.134C1502.65 753.895 1436.07 1030.9 1316.09 1090"
      stroke="#252526"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
));

export default Line5;
