import React, { useEffect, useRef, useState } from "react";
import Modal from "react-responsive-modal";

import { getScrollbarSize } from "../../../utils";
import HeaderModal from "../Modals/HeaderModal/HeaderModal";
// import type { AppModalProps } from "./AppModalProps";

import Platform from "../../../services/platform";
import { useDispatch, useSelector } from "react-redux";
import { documentIsBlocked } from "../../../store/actions/LayoutActions";
import CloseButton from "./CloseButton/CloseButton";

export const AppModal = ({
  blockScroll,
  CloseBtnComponent = undefined,
  showHeader = true,
  ...props
}) => {
  const dispatch = useDispatch();
  const [doc] = useState(document.documentElement);
  const [header] = useState(document.querySelector(".cr-header"));
  const [scrollTopButton] = useState(
    document.querySelector(".scroll-top-button__wrapper")
  );
  const [elements] = useState([header, scrollTopButton]);
  const _documentIsBlocked = useSelector(
    (state: any) => state.layout.documentIsBlocked
  );

  const blockRef = useRef();
  useEffect(() => {
    blockRef.current = _documentIsBlocked;
  }, [_documentIsBlocked]);

  useEffect(() => {
    if (blockScroll) {
      props.open ? open() : close();
      return () => close();
    }
  }, [props.open]);

  const setMargins = (value) => {
    elements.forEach((el: any) => el && (el.style.paddingRight = value));
    doc.style.overflow = !value ? "" : "hidden";
  };

  const open = () => {
    if (!blockRef.current) {
      setMargins(`${getScrollbarSize()}px`);
      dispatch(documentIsBlocked(true));
    }
  };

  const close = () => {
    if (blockRef.current) {
      setMargins("");
      dispatch(documentIsBlocked(false));
    }
  };

  const onClose = (e) => {
    e?.stopPropagation();
    props.onClose();
  };

  const renderCloseButton = () =>
    CloseBtnComponent ? (
      <CloseBtnComponent onClick={onClose} />
    ) : (
      <CloseButton onClick={onClose} />
    );

  const { classNames = {}, children, ...otherProps } = props as any;
  const cls = {
    ...classNames,
    modal: `modal-base ${classNames.modal || ""}`,
  };
  return (
    <React.Fragment>
      {props.open && (
        <Modal
          classNames={cls}
          {...otherProps}
          blockScroll={!!Platform.isPhone()}
        >
          {showHeader && <HeaderModal onLogoClick={onClose} />}
          {children}
          {renderCloseButton()}
        </Modal>
      )}
    </React.Fragment>
  );
};
