// @flow
import { Publication, PublicationsState } from "../../types/Publication.interface";
import {
  GET_PUBLICATIONS_REQUEST,
  GET_PUBLICATIONS_SUCCESS,
  GET_PUBLICATIONS_FAIL,
  LOAD_PUBLICATIONS_TAGS_HANDLE,
  LOAD_PUBLICATIONS_TAGS_SUCCESS,
  LOAD_PUBLICATIONS_TAGS_FAIL,
} from "../actions/PublicationsActions";

const initialState = {
  list: null,
  tags: null,
  selectedTag: null,
  errorFetchingPublications: "",
  isFetchingPublicationsTags: false,
  isFetchingPublications: false,
};

export function publicationsReducer(state: PublicationsState = initialState, action) {
  switch (action.type) {
    case GET_PUBLICATIONS_REQUEST:
      return {
        ...state,
        isFetchingPublications: true,
        errorFetchingPublications: "",
      };

    case LOAD_PUBLICATIONS_TAGS_HANDLE:
      return {
        ...state,
        isFetchingPublicationsTags: true,
        errorFetchingPublicationsTags: "",
      };

    case GET_PUBLICATIONS_SUCCESS:
      return {
        ...state,
        isFetchingPublications: false,
        list: action.payload,
      };

    case LOAD_PUBLICATIONS_TAGS_SUCCESS: {
      return {
        ...state,
        isFetchingPublicationsTags: false,
        tags: action.payload,
      };
    }

    case GET_PUBLICATIONS_FAIL:
      return {
        ...state,
        isFetchingPublications: false,
        errorFetchingPublications: action.payload.message,
      };

    case LOAD_PUBLICATIONS_TAGS_FAIL:
      return {
        ...state,
        isFetchingPublicationsTags: false,
        errorFetchingPublicationsTags: action.payload.message,
      };

    case "SELECT_PUBLICATIONS_TAG":
      return {
        ...state,
        selectedTag: action.payload.tag,
      };

    default:
      return state;
  }
}
