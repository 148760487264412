// @TODO art дополнить остальные платформы
export enum NYLandingPlatform {
  CROWD = "CROWD",
  AG = "AG",
  // MP = "MILLION_PRIZES",
  ED = "ED",
  // GZ = "MISSION_CITY",
}

export type NYLandingObjectState<T = {}> = T & {
  id: string | number;
  [K: string]: any;
};

export interface NYLandingObjectsNormalizedState<T = {}> {
  entries: Record<string, NYLandingObjectState<T>>;
  ids: (string | number)[];
}

export interface NYUser {
  id: string;
  ssoid: string;
  email: string;
  firstName: string;
  lastName: string;
}

export interface NYLandingState<T = {}> {
  presentationOpen: boolean;
  platform: NYLandingPlatform;
  progress: number; // прогресс прокрутки лендинга от 0 до 1
  user: {
    details: NYUser | null;
    loggedIn: boolean;
  };
  game: {
    started: number | null;
    finished: number | null;
    state: {
      foundObjects: NYLandingObjectsNormalizedState<T>;
      allObjects: NYLandingObjectsNormalizedState<T & { deferred?: boolean }>;
    };
  };
}
