import { ButtonHTMLAttributes, FC, useCallback, useEffect, useRef } from "react";
import {
    ballParams,
    formatTime,
    gameParams,
    lostEmojiParams,
    maxAttempts,
    scoreBoard,
    wonEmojiParams,
  } from "./../BasketballGame.helper";

import "./Game.scss";
import { fullHeight } from "../../../utils/constants";

declare global {
  interface Window {
    Phaser: any;
  }
}

const timeLimit = 60;
let game;
let moveOutTween;
let emoji;
let ball;
let hoop;
let leftRim;
let rightRim;
let collisionGroup;
let startLocation = [0, 0];
let endLocation = [0, 0];
let locationInterval = null;
let isDown = false;
let currentScore = 0;
let currentScoreText = null;
let countdown = timeLimit;
let countdownText = null;
let timer = null;
let coef = 1; 

interface GameProps {
    onWin: () => void;
    onTimeExpire: () => void;
}

const Game: FC<GameProps> = ({onWin, onTimeExpire}) => {

  useEffect(() => {
    const root = document.querySelector('.leisure-landing');
    coef = root.clientHeight / fullHeight;
  }, [])

  useEffect(() => {
    if (window.Phaser) {
      const config = {
        width: gameParams.width,
        height: gameParams.height,
        renderer: window.Phaser.CANVAS,
        antialias: true,
        multiTexture: true,
        parent: "basketball-game",
        state: {
          preload: () => preload(),
          create: () => create(),
          update: () => update(),
        },
      };
      game = new window.Phaser.Game(config);

      return () => {
        clearInterval(timer);
        countdown = timeLimit;
        currentScore = 0;
        game.input.enabled = false;
        game.destroy(true, true)
      }
    }
  }, []);

  const moveCallback = useCallback(({ position: { x, y } }) => {
    const ballRadius = 60;
    const ballLeft = (ball.position.x - ballRadius) * coef;
    const ballRight = (ball.position.x + ballRadius) * coef;
    const ballTop = (ball.position.y - ballRadius) * coef;
    const ballBottom = (ball.position.y + ballRadius) * coef;
    if (x >= ballLeft && x <= ballRight && y >= ballTop && y <= ballBottom) {
      game.canvas.style.cursor = "pointer";
    } else {
      game.canvas.style.cursor = "default";
    }
  }, []);

  function preload() {
    game.load.image("background", require("../../../../../../assets/leisure-landing/game/images/bg.png").default);
    game.load.image(
      "current_score",
      require("../../../../../../assets/leisure-landing/game/images/current_score.svg").default
    );
    game.load.image("ball", require("../../../../../../assets/leisure-landing/game/images/ball.png").default);
    game.load.image("hoop", require("../../../../../../assets/leisure-landing/game/images/hoop.png").default);
    game.load.image("side rim", require("../../../../../../assets/leisure-landing/game/images/side_rim.png").default);
    game.load.image("front rim", require("../../../../../../assets/leisure-landing/game/images/front_rim.png").default);
    game.load.image("rack", require("../../../../../../assets/leisure-landing/game/images/rack.svg").default);
    game.load.image("won", require("../../../../../../assets/leisure-landing/game/images/won.png").default);
    game.load.image("lost", require("../../../../../../assets/leisure-landing/game/images/lost.png").default);
  }

  const renderHoop = () => {
    const { width, height } = gameParams;
    let hoopX = width * 0.37;
    let hoopY = height * 0.27;
    hoop?.destroy();
    hoop = game.add.sprite(hoopX, hoopY, "hoop");
  };

  const renderBackground = () => {
    game.add.sprite(0, 0, "background");
  };

  const renderRack = () => {
    const { width, height } = gameParams;
    const rack = game.add.sprite(0, height - 54, "rack");
    rack.width = "420";
    rack.height = "54";
  };

  const renderScoreDeck = () => {
    game.add.sprite(scoreBoard.x(), scoreBoard.y(), "current_score");
  };

  const renderScore = () => {
    const style = { fontFamily: "Montserrat", fontWeight: 800, fontSize: "22px", fill: "#493771" };
    const offsetX = 54;
    const offsetY = 93;
    currentScoreText = game.add.text(
      scoreBoard.x() + offsetX,
      scoreBoard.y() + offsetY,
      `${currentScore}/${maxAttempts}`,
      style
    );
  };

  const updateScoreText = (value) => {
    currentScoreText.text = `${currentScore}/${maxAttempts}`;
  };

  const renderTimer = () => {
    timer = setInterval(() => {
      updateCounter();
    }, 1000)

    const style = { fontFamily: "Montserrat", fontWeight: 800, fontSize: "22px", fill: "#493771" };
    const offsetX = 41;
    const offsetY = 35;
    countdownText = game.add.text(scoreBoard.x() + offsetX, scoreBoard.y() + offsetY, formatTime(countdown), style);
  };

  const showEmoji = (state) => {
    const offsetTop = 17;
    const x =
      state === "won"
        ? gameParams.width / 2 - wonEmojiParams.width / 2
        : gameParams.width / 2 - lostEmojiParams.width / 2;
    const y = offsetTop;
    emoji = game.add.sprite(x, y, state);
    tweenIn();
  };

  function tweenIn() {
    const moveInTween = game.add.tween(emoji).from({ y: 150 }, 500, window.Phaser.Easing.Elastic.Out, true);
    const fadeInTween = game.add
      .tween(emoji)
      .from({ alpha: 0 }, 200, window.Phaser.Easing.Linear.None, true, 0, 0, false);
    moveInTween.onComplete.add(tweenOut);
  }

  function tweenOut() {
    moveOutTween = game.add.tween(emoji).to({ y: 50 }, 600, window.Phaser.Easing.Elastic.In, true);
    moveOutTween.onComplete.add(() => emoji.kill());
    setTimeout(() => game.add.tween(emoji).to({ alpha: 0 }, 300, window.Phaser.Easing.Linear.None, true, 0, 0, false));
  }

  function updateCounter() {
    countdown = countdown - 1;
    countdownText.text = formatTime(countdown);

    if (countdown === 0) {
      clearInterval(timer);
      onTimeExpire();
    }
  }

  const renderLeftRim = () => {
    leftRim = game.add.sprite(150, 184, null);
    game.physics.p2.enable([leftRim], false);
    leftRim.body.setCircle(2.5);
    leftRim.body.static = true;
    leftRim.body.setCollisionGroup(collisionGroup);
    leftRim.body.collides([collisionGroup]);
  };

  const renderRightRim = () => {
    rightRim = game.add.sprite(249, 184, null);
    game.physics.p2.enable([rightRim], false);
    rightRim.body.setCircle(2.5);
    rightRim.body.static = true;
    rightRim.body.setCollisionGroup(collisionGroup);
    rightRim.body.collides([collisionGroup]);
  };

  function create() {
    game.physics.startSystem(window.Phaser.Physics.P2JS);
    game.physics.p2.setImpactEvents(true);
    game.physics.p2.restitution = 0.63;
    game.physics.p2.gravity.y = 0;
    collisionGroup = game.physics.p2.createCollisionGroup();
    game.stage.backgroundColor = "#EDEDFA";
    game.stage.disableVisibilityChange = true;

    renderBackground();
    renderHoop();
    renderScoreDeck();
    renderScore();
    renderTimer();
    renderLeftRim();
    renderRightRim();
    renderRack();
    createBall();
    game.input.keyboard.createCursorKeys();
    game.input.onDown.add(click);
    game.input.onUp.add(release);
  }

  function click(pointer) {
    const position = {...pointer.position, x: pointer.position.x / coef, y: pointer.position.y / coef}
    var bodies = game.physics.p2.hitTest(position, [ball.body]);
    if (bodies.length) {
      startLocation = [pointer.x, pointer.y];
      isDown = true;
      locationInterval = setInterval(() => {
        startLocation = [pointer.x, pointer.y];
      }, 200);
    }
  }

  function release(pointer) {
    if (isDown) {
      window.clearInterval(locationInterval);
      isDown = false;
      endLocation = [pointer.x, pointer.y];

      if (endLocation[1] < startLocation[1]) {
        var slope = [endLocation[0] - startLocation[0], endLocation[1] - startLocation[1]];
        var x_traj = (-2300 * slope[0]) / slope[1];
        launch(x_traj);
      }
    }
  }

  function update() {
    if (ball && ball.body.velocity.y > 0) {
      renderHoop();
      ball.body.collides([collisionGroup], null);
    }

    if (ball && ball.body.velocity.y > 0 && ball.body.y > 188 && !ball.isBelowHoop) {
      ball.isBelowHoop = true;
      ball.body.collideWorldBounds = false;
      const isHit = ball.body.x > 151 && ball.body.x < 249;

      if (isHit) {
        const newScore = currentScore + 1;
        currentScore = newScore;
        updateScoreText(newScore);

        if (currentScore === maxAttempts) {
          onWin();
          return;
        }

        showEmoji("won");
      } else {
        showEmoji("lost");
      }
    }

    if (ball && ball.body.y > gameParams.height) {
      game.physics.p2.gravity.y = 0;
      ball.kill();
      createBall();
    }
  }

  const getRandomBallPosition = () => {
    const maxBallOffset = 50;
    const numbers = [-maxBallOffset, 0, maxBallOffset];
    const index = Math.floor(Math.random() * numbers.length);
    return numbers[index];
  };

  function createBall() {
    const scale = 0.7;
    const bottomOffset = 45;
    const x = gameParams.width / 2 - getRandomBallPosition();
    const y = gameParams.height - ballParams.height / 2 - bottomOffset;
    ball = game.add.sprite(x, y, "ball");
    ball.inputEnabled = true;
    game.add.tween(ball.scale).from({ x: scale, y: scale }, 100, window.Phaser.Easing.Linear.None, true, 0, 0, false);
    game.physics.p2.enable(ball, false);
    ball.body.setCircle(70);
    ball.launched = false;
    ball.isBelowHoop = false;

    game.input.deleteMoveCallback(moveCallback);
    game.input.addMoveCallback(moveCallback);
  }

  function launch(x_traj) {
    const scale = 0.6;
    if (ball.launched === false) {
      ball.body.setCircle(36);
      ball.body.setCollisionGroup(collisionGroup);
      ball.launched = true;
      game.physics.p2.gravity.y = 3000;
      game.add.tween(ball.scale).to({ x: scale, y: scale }, 500, window.Phaser.Easing.Linear.None, true, 0, 0, false);
      ball.body.velocity.x = x_traj;
      ball.body.velocity.y = -1750;
      ball.body.rotateRight(x_traj / 3);
    }
  }

  return <div className="basketball__game" id="basketball-game"></div>;;
};

export default Game;
