import { declOfNum } from "../../../../../utils";

export interface CityCounter {
  id: string;
  title: string;
  count: string;
  subtitle: (count: any) => string;
  innerCircleSrc: string;
  outerCircleSrc: string;
  colors: ColorSet;
}

export interface ColorSet {
  title: string;
  inner: string[];
  outer: string[];
}

export const counterData: CityCounter[] = [
  {
    id: "registrationCount",
    title: "На платформе",
    count: null,
    subtitle: (count) => declOfNum(count, ["участник", "участника", "участников"]),
    innerCircleSrc: require("../../../../../assets/leisure-landing/statistics/pink-inner.svg").default,
    outerCircleSrc: require("../../../../../assets/leisure-landing/statistics/pink-outer.svg").default,
    colors: {
      title: "var(--sugar-plum)",
      inner: ["#F5EDF3", "#E9E1EE"],
      outer: ["#F5EDF3", "#D8BAD2"],
    },
  },
  {
    id: "realizedIdeaCount",
    title: "Реализовано",
    count: null,
    subtitle: (count) => declOfNum(count, ["идея", "идеи", "идей"]),
    innerCircleSrc: require("../../../../../assets/leisure-landing/statistics/blue-inner.svg").default,
    outerCircleSrc: require("../../../../../assets/leisure-landing/statistics/blue-outer.svg").default,
    colors: {
      title: "var(--adventure-seas)",
      inner: ["#F0F5FF", "#DDE3F8"],
      outer: ["#E9EEF7", "#ABBADA"],
    },
  },
  {
    id: "archivedProjectCount",
    title: "Проведено",
    count: null,
    subtitle: (count) => declOfNum(count, ["проект", "проекта", "проектов"]),
    innerCircleSrc: require("../../../../../assets/leisure-landing/statistics/green-inner.svg").default,
    outerCircleSrc: require("../../../../../assets/leisure-landing/statistics/green-outer.svg").default,
    colors: {
      title: "var(--peacock-silk)",
      inner: ["#EFF7F6", "#DFE9EE"],
      outer: ["#EFF7F6", "#AACAC7"],
    },
  },
  {
    id: "categoryCount",
    title: "Доступно",
    count: null,
    subtitle: (count) => `${declOfNum(count, ["тема", "темы", "тем"])} для подачи идей`,
    innerCircleSrc: require("../../../../../assets/leisure-landing/statistics/yellow-inner.svg").default,
    outerCircleSrc: require("../../../../../assets/leisure-landing/statistics/yellow-outer.svg").default,
    colors: {
      title: "var(--pink-shade)",
      inner: ["#F8EFF1", "#EADFE9"],
      outer: ["#F8EFF1", "#D9B4B9"],
    },
  },
];
