import { RoutesConfig } from "../../../App.helper";

export const THEMATIC_PROJECTS = "ThematicProjects";
export const MEDIA_PLATFORM = "MediaPlatform";
export const DISCUSSIONS = "Discussions";
export const PROPOSE_IDEA = "ProposeIdea";
export const RATING = "Rating";

const _routes = [
  {
    id: "d1",
    route: `?section=${THEMATIC_PROJECTS}`,
    text: "Тематические проекты",
  },
  {
    id: "d2",
    route: `?section=${MEDIA_PLATFORM}`,
    text: "Медиаплатформа",
  },
  {
    id: "d3",
    route: `?section=${DISCUSSIONS}`,
    text: "Обсуждения",
  },
  {
    id: "d4",
    route: `?section=${PROPOSE_IDEA}`,
    text: "Предложить идею",
  },
  {
    id: "d5",
    route: `?section=${RATING}`,
    text: "Рейтинг идей",
  },
];

const routes: RoutesConfig = {
  baseRoutes: _routes,
  mobileRoutes: _routes,
};

export { routes };
