import React, { Suspense, useEffect, useLayoutEffect } from "react";
import { Route, Switch } from "react-router-dom";

import AuthForm from "../../containers/Forms/AuthForm/AuthForm";
import MainPage from "./Pages/MainPage/MainPage";

import "./NewYear2023Root.scss";
import { fullScreenPage } from "../../../store/actions/LayoutActions";
import { useDispatch } from "react-redux";
import TestPage from "./Pages/TestPage/TestPage";
import { LocalStorageKeys, LocalStorageService } from "../../../services/LocalStorage.service";

const NewYear2023Root = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fullScreenPage());
  }, []);

  // useLayoutEffect(() => {
  //   const key = LocalStorageKeys.NEW_YEAR_24_REDIRECTED;
  //   if (!LocalStorageService.getData(key)) {
  //     LocalStorageService.saveData(key, "1");
  //   }
  // }, []);

  return (
    <>
      <AuthForm />
      <div className="new-year-root">
        <Suspense fallback={<div />}>
          <Switch>
            <Route path="/newyear2024" exact={true} component={MainPage} key={"NewYear"} />
          </Switch>
        </Suspense>
      </div>
    </>
  );
};

export default NewYear2023Root;
