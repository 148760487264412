class GridBase {
  constructor() {}

  update() {}

  get canSlidePrev() {
    return false;
  }

  get canSlideNext() {
    return false;
  }

  getItems() {}

  slideNext() {}

  slidePrev() {}

  renderItems() {}

  isDataFetching() {
    return false;
  }
}

export default GridBase;
