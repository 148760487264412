const gameParams = {
    width: 420,
    height: 630,
}

const ballParams = {
    width: 120,
    height: 120,
}

const scoreBoard = {
    width: 147,
    height: 134,
    offset: 37,
    x: () => (gameParams.width / 2) - (scoreBoard.width / 2),
    y: () => ((gameParams.height / 2) - (scoreBoard.height / 2)) + scoreBoard.offset,
}

const wonEmojiParams = {
    width: 98,
    height: 70,
}

const lostEmojiParams = {
    width: 86,
    height: 70,
}

const maxAttempts = 5;

export function formatTime(_seconds) {
    const minutes = Math.floor(_seconds / 60);
    const seconds = _seconds - minutes * 60;

    var minutesStr = minutes < 10 ? '0' + minutes : '' + minutes;
    var secondsStr = seconds < 10 ? '0' + seconds : '' + seconds;

    return minutesStr + ':' + secondsStr;
}

export {gameParams, scoreBoard, maxAttempts, lostEmojiParams, wonEmojiParams, ballParams};