import React, { useContext, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { phoneWidth } from "../../../../utils/constants/widthConstants";
import Tooltip from "../../../presentational/Controls/Tooltip/Tooltip";
import "./CrossPromo.scss";
import CrossPromoPopup from "./CrossPromoPopup/CrossPromoPopup";
import Platform from "../../../../services/platform";
import EventsTrackWrapperClick from "../../EventsTrack/wrappers/EventsTrackWrapperClick";
import { AppContext } from "../../../Root";
import { AuthActionType, ComponentType } from "../../../../services/sudirService";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../types/State.interface";
import { showQrCodeModal } from "../../../../store/actions/LayoutActions";

const CrossPromo = (props) => {
  const isPhone = useMediaQuery({ query: `(max-width: ${phoneWidth}px)` });
  const [popupOpen, setPopupOpen] = useState(false);
  const [seenProjects, setSeenProjects] = useState(false);

  const appContext = useContext(AppContext);

  const userDetails = useSelector((state: RootState) => state.user.userDetails);

  const dispatch = useDispatch();

  useEffect(() => {
    setSeenProjects(Boolean(localStorage.getItem("seen-cross-promo-projects")));
  }, []);

  const openPopup = () => {
    if (!seenProjects) {
      localStorage.setItem("seen-cross-promo-projects", "1");
      setSeenProjects(true);
    }

    setPopupOpen(true);
    isPhone && ((document.querySelector("body") as HTMLElement).style.overflow = "hidden");
  };

  const closePopup = () => {
    setPopupOpen(false);
    isPhone && ((document.querySelector("body") as HTMLElement).style.overflow = "auto");
  };

  // ACTIONS AFTER AUTH
  useEffect(() => {
    if (!userDetails || !userDetails.loggedIn) return;

    const actions = appContext.sudirService.getActions(ComponentType.CROSS_PROMO_POPUP);
    const openQrCodeModal = actions.find((action) => action.type === AuthActionType.OPEN_QR_CODE_MODAL);
    if (openQrCodeModal && !userDetails.blocked) {
      dispatch(showQrCodeModal());
    }
  }, [userDetails]);

  const renderButton = () => {
    return (
      <EventsTrackWrapperClick id={["HEADER", 600]}>
        <button className={"cross-promo__button"} onClick={openPopup}>
          <svg
            id="Component_318_17"
            data-name="Component 318 – 17"
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 32 32"
          >
            <rect id="Rectangle_2505" data-name="Rectangle 2505" width="32" height="32" fill="none" />
            <g id="cross_projects" transform="translate(3.5 2.778)">
              <g id="cross_projects-2" data-name="cross_projects" transform="translate(0.5 -0.777)">
                <path
                  id="Path_10201"
                  data-name="Path 10201"
                  d="M24.394,2H5.58A2.822,2.822,0,0,0,3,4.576V23.031c0,.641.452,1.576,1.994,2.307,1.011.474,8.483,4.409,8.975,4.55a2.175,2.175,0,0,0,1.343.013c.984-.436,9.547-4.486,10.172-4.9A2.968,2.968,0,0,0,27,22.506V4.589A2.64,2.64,0,0,0,24.394,2Zm-1.05,21.172c-.505.333-7.326,3.55-8.111,3.9a1.715,1.715,0,0,1-1.077-.013C13.77,26.94,7.813,23.8,7,23.428a1.858,1.858,0,0,1-1.316-1.82L5.7,6.063C5.7,5,6.178,4.576,6.856,4.576H23.011c.931,0,1.237.538,1.237,1.32,0,.487.013,14.931.013,15.341A1.9,1.9,0,0,1,23.343,23.172Z"
                  transform="translate(-3 -2)"
                  fill="#65aae0"
                />
              </g>
            </g>
          </svg>

          {!seenProjects && <span className="cross-promo__marker"></span>}
        </button>
      </EventsTrackWrapperClick>
    );
  };

  return (
    <div className={`cross-promo ${popupOpen ? "cross-promo--active" : ""}`}>
      {Platform.isTouchDevice() ? (
        renderButton()
      ) : (
        <Tooltip text={"Электронные городские проекты"} idx={"1"} place={"top"} isOuter={true}>
          {renderButton()}
        </Tooltip>
      )}

      <div className="cross-promo__divider"></div>

      {popupOpen && <CrossPromoPopup onClose={closePopup} />}
    </div>
  );
};

export default CrossPromo;
