import GorodService from "../../services/gorodService";

export const getSpheres = () => (dispatch) => {
    dispatch({type: 'GET_SPHERES_REQUEST'});
    return GorodService.API.getSpheres()
      .then((data) => {
        dispatch({
          type: 'GET_SPHERES',
          payload: data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  