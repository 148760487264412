import { SittingsState } from "../../types/Sittings.interface";
import { GET_SETTING_KEY_SUCCESS } from "../actions/SittingsActions";

const initialState: SittingsState = {
  SHOW_PROJECT_CATEGORY: false,
  NEW_IDEAS: false
};

export function sittingsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_SETTING_KEY_SUCCESS:
      return {
        ...state,
        [action.payload.key]: action.payload.value === "true"
      };

    default:
      return state;
  }
}
