import React, { ReactElement, useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

import EventsTrackParentIdContext from "../../../containers/EventsTrack/EventsTrackParentIdContext";
import EventsTrackWrapperScroll from "../../../containers/EventsTrack/wrappers/EventsTrackWrapperScroll";
import EventsTrackWrapperClick from "../../../containers/EventsTrack/wrappers/EventsTrackWrapperClick";
import IdeasList from "../../../containers/IdeasList/IdeasList";
import { phoneWidth } from "../../../../utils/constants/widthConstants";

import "./IdeasSection.scss";

const IdeasSection = () => {
  const isPhone = useMediaQuery({ query: `(max-width: ${phoneWidth}px)` });

  return (
    <EventsTrackParentIdContext.Provider value="BLOCK_RESULTS">
      <EventsTrackWrapperScroll id={5}>
        <div className="mein__ideas">
          <div className="mein__ideas-head">
            <EventsTrackWrapperClick id={174}>
              <Link to={`/ideas`} className="mein__ideas-title undecorated-link">
                Подтверждайте реализацию идей
              </Link>
            </EventsTrackWrapperClick>
          </div>

          <IdeasList isShowReports={!isPhone} />
        </div>
      </EventsTrackWrapperScroll>
    </EventsTrackParentIdContext.Provider>
  );
};

export default IdeasSection;
