import { Reducer } from "redux";
import { LeisureLandingState } from "../../types/LeisureLanding.interface";
import { RootState } from "../../types/State.interface";
import {
  LEISURE_LANDING_SET_ACTIVE_INDEX,
  LEISURE_LANDING_SET_PROGRESS,
  LEISURE_LANDING_SET_SCALE,
} from "../actions/LeisureLandingActions";

const correctProgress = (progress: number) => (progress < 0 ? 0 : progress > 1 ? 1 : progress);

const initialState: LeisureLandingState = {
  progress: 0,
  forward: true,
  scale: 1,
};

export const leisureLandingReducer: Reducer<LeisureLandingState> = (state = initialState, action) => {
  switch (action.type) {
    case LEISURE_LANDING_SET_PROGRESS:
      return { ...state, progress: correctProgress(action.payload.progress), forward: action.payload.forward };

    case LEISURE_LANDING_SET_ACTIVE_INDEX:
      return { ...state, activeIndex: action.payload };

    case LEISURE_LANDING_SET_SCALE:
      return { ...state, scale: action.payload };

    default:
      return state;
  }
};

export const selectLeisureLandingProgress = (state: RootState) => state.leisureLanding.progress;

export const selectLeisureLandingForward = (state: RootState) => state.leisureLanding.forward;

export const selectLeisureActiveIndex = (state: RootState) => state.leisureLanding.activeIndex;

export const selectLeisureScale = (state: RootState) => state.leisureLanding.scale;
