import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import { store } from "./store/store";
import Root from "./components/Root";
import smoothscroll from "smoothscroll-polyfill";

import * as serviceWorker from "./serviceWorker";

smoothscroll.polyfill();

ReactDOM.render(<Root store={store} />, document.getElementById("root"));
serviceWorker.register({
    onUpdate: registration => {
      // Код для обработки обновления Service Worker
    },
    onSuccess: registration => {
      // Код для обработки успешной регистрации
    }
  });