import { News } from "../../../../types/News.interface";
import TagsCard from "../TagsCard/TagsCard";
import { TagsCardParentProps } from "../TagsCard/TagsCard.interface";

import "./NewsCard.scss";

const NewsCard = ({ element, id, onClick, onTagClick, href, linkRel }: TagsCardParentProps<News>) => {
  return (
    <TagsCard<News>
      id={id}
      section="news"
      date={element?.published}
      element={element}
      imageId={element?.imageId}
      onClick={onClick}
      onTagClick={onTagClick}
      tag={element?.tag}
      title={element?.title}
      href={href}
      linkRel={linkRel}
    />
  );
};

export default NewsCard;
