import "./GenerationModal.scss";
import { Stage } from "../../../../../types/Stage.interface";
import { AppModal } from "../../../../containers/Modal/AppModal";
import GenerationFormContainer from "./GenerationContainer/GenerationFormContainer";
import { GroupDirectionStage } from "../../../../../types/GroupDirection.interface";

export interface GenerationModalProps {
  stage: GroupDirectionStage;
  open: boolean;
  showModalDescription?: boolean;
  onSubmit?: () => void;
  onSubmitDraft?: () => void;
  onFormClose?: () => void;
  onClose: () => void;
}

const GenerationModal = ({ stage, open, onFormClose, onClose, onSubmit, onSubmitDraft }: GenerationModalProps) => {
  return (
    <AppModal
      classNames={{ modal: "generation-modal" }}
      open={open}
      onClose={() => onClose()}
      blockScroll
      center
      overlayId="modal-overlay"
      closeIconId="close-modal-icon"
      closeIconSize={25}
      closeOnEsc
      closeOnOverlayClick={false}
    >
      <p className="generation-modal__title">Предложить идею</p>
      <div id="generation-modal__icon"></div>

      {stage && (
        <GenerationFormContainer
          stage={stage}
          showFormDescription={true}
          showTaskDescription={false}
          showFullScreenButton={true}
          showDraftButton
          onFormClose={onFormClose}
          onSubmit={onSubmit}
          onSubmitDraft={onSubmitDraft}
        />
      )}
    </AppModal>
  );
};

export default GenerationModal;
