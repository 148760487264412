import React from "react";

import "./PageDescription.scss";

const PageDescription = (props) => {
  return (
    <div
      className="page-description"
      dangerouslySetInnerHTML={{ __html: props.text }}
    ></div>
  );
};

export default PageDescription;
