import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import AnnouncementPage from "../../pages/Announcement/AnnouncementPage";
import FadeIn from "../../presentational/FadeIn/FadeIn";
import FourColumnLoader from "../../presentational/Loaders/FourColumnLoader/FourColumnLoader";
import { getAnnouncementProjects } from "../../../store/actions/ProjectActions";

const AnnouncementContainer = (props) => {
  const dispatch = useDispatch();
  const projects = useSelector((state: any) => state.projects.announcement);

  useEffect(() => {
    if (!projects) dispatch(getAnnouncementProjects());
  }, []);

  return (
    <div>
      {(projects && (
        <FadeIn>
          <AnnouncementPage projects={projects} />
        </FadeIn>
      )) || <FourColumnLoader />}
    </div>
  );
};

export default AnnouncementContainer;
