import React from "react";

import "./PageTitle.scss";

// type Props = {
// 	text: String
// };

const PageTitle = (props) => {
  return <div className="page-title">{props.text}</div>;
};

export default PageTitle;
