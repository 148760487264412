import { API } from "./api";

const url = '/api/event';

export interface EventsSaveParams {
    firstName: string;
    lastName: string;
    text: string;
    settingId: string;
    attachmentId: string;
}

const BirthdayEventsService = {
    async save(params: EventsSaveParams) {
        const { data } = await API.post(`${url}/message/save`, params);
        return data;
    },

    async get() {
        const { data } = await API.get(`${url}/message/get`);
        return data;
    },

    async getSettings() {
        const { data } = await API.get(`${url}/settings`);
        return data;
    },
};

export default BirthdayEventsService;
