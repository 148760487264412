import { Swiper, SwiperSlide } from "swiper/react";
import React, {
  HTMLAttributes,
  ReactElement,
  useEffect,
  useState,
} from "react";

import "./BannerLine.scss";
import { useMediaQuery } from "react-responsive";

import SwiperCore from "swiper";
import { INewsLine } from "../../../../types/NewsLine.interface";
import { phoneWidth, tabletWidth } from "../../../../utils/constants/widthConstants";
import EventsTrackWrapperClick from "../../../containers/EventsTrack/wrappers/EventsTrackWrapperClick";

export interface NewsLineProps extends HTMLAttributes<HTMLDivElement> {
  lines: INewsLine[];
  onInitSwiper?: (swiper: SwiperCore) => void;
}

const defaultTime = 7;

const BannerLine = ({
  lines = [],
  onInitSwiper,
  ...otherProps
}: NewsLineProps): ReactElement => {
  const isTablet = useMediaQuery({ query: `(max-width: ${tabletWidth}px)` });
  const isMobile = useMediaQuery({ query: `(max-width: ${phoneWidth}px)` });
  const [swiperInstance, setSwiperInstance] = useState<SwiperCore>(null);

  useEffect(() => {
    if (swiperInstance) {
      if (lines.length > 1) {
        swiperInstance.autoplay.start();
        return;
      }

      swiperInstance.autoplay.stop();
    }
  }, [lines, swiperInstance]);

  const getLineImgUrl = (line: INewsLine) => {
    if (isMobile) {
      return line.imgUrlPhone;
    }

    if (isTablet) {
      return line.imgUrlTablet;
    }

    return line.imgUrlFull;
  };

  const initSwiper = (swiper) => {
    // для отмены отключения автоплея при уходе с текущей вкладки браузера, чтобы свайпер синхронно переключался с баннерами
    document.removeEventListener('visibilitychange', swiper.autoplay.onVisibilityChange);
    setSwiperInstance(swiper);
    onInitSwiper?.(swiper);
  };

  return (
    lines.length > 0 && (
      <div className="news-line" {...otherProps}>
        <Swiper
          autoplay={{ delay: (lines[0]?.switchingTime || defaultTime) * 1000, waitForTransition: false, }}
          slidesPerView={1}
          loop={true}
          allowTouchMove={false}
          onSwiper={(instance) => initSwiper(instance)}
          speed={1000}
        >
          {lines?.map((line) => (
            <SwiperSlide key={line.id}>
              <EventsTrackWrapperClick id={17} replace={[line.identifier]}>
                <div className="news-line__slide">
                  <a
                    className="news-line__slide__link"
                    style={{ cursor: line.link ? "pointer" : "default" }}
                    href={line.link || ""}
                    target="_blank"
                    onClick={(evt) => {
                      if (!line.link) {
                        evt.preventDefault();
                      }
                    }}
                  >
                    <img src={getLineImgUrl(line)} />
                  </a>
                </div>
              </EventsTrackWrapperClick>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    )
  );
};

export default BannerLine;
