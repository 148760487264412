import { FC, useEffect, useState } from "react";
import "./MediaPlatform.scss";
import NewsAndPublications from "./NewsAndPublications/NewsAndPublications";
import DiscussionLandingService from "../../services/discussion-service";
import { ApiStatusCode } from "../../../../../types/Common.interface";
import { displayError } from "../../../../../utils";

export interface News {
  id: string;
  header: string;
  mainPhotoId: string;
  newsText: string;
  publishDate: string;
  delayed: boolean;
}

export type NewsList = Array<News>;

export interface MediaPlatformProps {
  onLoadEnd?: (data) => void;
}

const MediaPlatform: FC<MediaPlatformProps> = ({ onLoadEnd }) => {
  const [newsList, setNewsList] = useState<NewsList>([]);

  const fetchData = async () => {
    let onLoadEndData = [];

    try {
      const response = await DiscussionLandingService.getNews();
      if (response.status === ApiStatusCode.OK) {
        setNewsList(response.data);
        onLoadEndData = response.data;
      } else {
        displayError("Ошибка при получении новостей", response.message);
      }
    } catch (error) {
      displayError("MediaPlatform", "Ошибка при получении новостей");
    } finally {
      onLoadEnd(onLoadEndData);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (!newsList?.length) return <></>;

  return (
    <section className="discussion-landing-media" id="MediaPlatform">
      <div className="discussion-landing-title">МЕДИАПЛАТФОРМА</div>

      <div className="discussion-landing-text">
        Тема культурного досуга настолько масштабная и интересная, потому мы впервые запускаем такой проект для
        пользователей, где можно не только предложить идеи по развитию сфер культурного досуга, но и принять участие в
        обсуждении важных тем с экспертами и единомышленниками. В этом разделе вы можете ознакомиться с новостями и
        посмотреть фотоотчеты с прошедших мероприятий в сфере культуры.
      </div>

      {newsList.length ? <NewsAndPublications newsList={newsList} /> : null}
    </section>
  );
};
export default MediaPlatform;
