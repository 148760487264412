import React, { useState } from "react";
import dayjs from "dayjs";
import { Player, ControlBar, BigPlayButton } from "video-react";

import "./TagsCard.scss";
import { TagsCardProps } from "./TagsCard.interface";
import EventsTrackWrapperClick from "../../../containers/EventsTrack/wrappers/EventsTrackWrapperClick";
import { Link } from "react-router-dom";
import Share from "../../Share/Share";

const TagsCard = <T,>({
  id,
  onClick,
  onTagClick,
  element,
  imageId,
  title,
  tag,
  date,
  section,
  videoId,
  href,
  linkRel,
  share,
}: React.PropsWithChildren<TagsCardProps<T>>) => {
  const [showedPreviewIcon, setShowedPreviewIcon] = useState(true);

  const handleClick = (e) => {
    if (onClick) {
      e.stopPropagation();
      e.preventDefault();
      element && onClick(element);
    }
  };

  const handleTagClick = (e) => {
    if (onTagClick) {
      e.stopPropagation();
      e.preventDefault();
      element && onTagClick(element);
    }
  };

  const renderCard = () => (
    <>
      {!videoId ? (
        <div className="tags-card__image" onClick={handleClick}>
          {imageId && <img src={"/uploads/get/" + imageId} alt={title} />}
        </div>
      ) : (
        <div className={`tags-card__video ${!showedPreviewIcon ? "no-icon" : ""}`}>
          <Player
            playsInline
            disablePictureInPicture
            key={videoId}
            poster={imageId ? `/uploads/get/` + imageId : ""}
            onPlaying={() => setShowedPreviewIcon(false)}
            onPause={() => setShowedPreviewIcon(true)}
            onAbort={() => setShowedPreviewIcon(true)}
          >
            <source src={"/uploads/get/" + videoId} />
            <ControlBar autoHide={false} className="my-class" />
            <BigPlayButton position="center" />
          </Player>
        </div>
      )}
      <div className="tags-card__text-wrapper">
        <div className="tags-card__details">
          {Boolean(tag) && (
            <EventsTrackWrapperClick needParent id={184} replace={[tag]}>
              <div className="tags-card__tag activity__tag" onClick={handleTagClick}>
                {tag}
              </div>
            </EventsTrackWrapperClick>
          )}
          <div className="tags-card__date">{dayjs(date).format("DD.MM.YYYY")}</div>
        </div>
        <div className="tags-card__description" onClick={handleClick}>
          {title}
        </div>
        {share && <Share {...share} backgroundColorIcon="#999999" colorIcon="white" />}
      </div>
    </>
  );

  return (
    <EventsTrackWrapperClick needParent id={211} replace={[title]}>
      <div className={`tags-card tags-card_${section}`}>
        {href ? (
          <Link
            rel={linkRel}
            className="tags-card__link-wrapper"
            to={typeof href === "function" ? href(element) : href}
          >
            {renderCard()}
          </Link>
        ) : (
          renderCard()
        )}
      </div>
    </EventsTrackWrapperClick>
  );
};

export default TagsCard;
