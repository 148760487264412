import { FC, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import "./Reviews.scss";

import CommonSlider from "../../../../presentational/Sliders/CommonSlider/CommonSlider";
import { phoneWidth, tabletWidth } from "../../../../../utils/constants/widthConstants";
import { SwiperSlide } from "swiper/react";
import SwiperCore from "swiper";
import ReviewCard from "./ReviewCard/ReviewCard";
import DiscussionLandingService from "../../services/discussion-service";
import { ApiStatusCode } from "../../../../../types/Common.interface";
import { displayError } from "../../../../../utils";

export interface Review {
  id: string;
  link: string;
  news: string;
  resource: string;
  published: boolean;
}

export interface ReviewsProps {
  onLoadEnd?: (data) => void;
}

const Reviews: FC<ReviewsProps> = ({ onLoadEnd }) => {
  const isPhone = useMediaQuery({ query: `(max-width: ${phoneWidth}px)` });
  const isTablet = useMediaQuery({ query: `(max-width: ${tabletWidth}px) and (min-width: ${phoneWidth}px)` });
  const [swiperInstance, setSwiperInstance] = useState<SwiperCore>(null);
  const [reviews, setReviews] = useState<Review[]>([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await DiscussionLandingService.getAboutUs();

      if (response.status === ApiStatusCode.OK) {
        setReviews(response.data.filter((review) => review.published));
      } else {
        displayError("Ошибка при получении отзывов", response.message);
      }
    } catch (error) {
      displayError("Reviews", "Ошибка при получении отзывов");
    } finally {
      onLoadEnd([]);
    }
  };

  const handleOnInit = () => {};
  const handleOnSlideChange = () => {};

  return reviews.length ? (
    <section className="discussion-landing-reviews">
      <div className="discussion-landing-subtitle">О нас пишут</div>
      <CommonSlider
        onInit={handleOnInit}
        onSwiper={setSwiperInstance}
        onSlideChange={handleOnSlideChange}
        className="notifications__swiper"
        effect="fade"
        options={{
          loop: false,
          spaceBetween: 24,
          slidesPerView: isTablet ? 2 : isPhone ? 1 : 3,
          slidesPerColumn: 1,
          slidesPerGroup: 1,
        }}
      >
        {reviews.map((review) => (
          <ReviewCard key={review.id} {...review} />
        ))}
      </CommonSlider>
    </section>
  ) : null;
};
export default Reviews;
