import { User } from "../../types/User.interface";
import {
  TEAM_LOGIN_HANDLE,
  TEAM_LOGIN_SUCCESS,
  TEAM_LOGIN_FAIL,
  USER_SESSION_HANDLE,
  USER_SESSION_SUCCESS,
  USER_SESSION_FAILED,
  UPDATE_PROFILE_REQUEST,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_FAIL,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAIL,
  UPDATE_PROFILE_AVATAR_REQUEST,
  UPDATE_PROFILE_AVATAR_SUCCESS,
  UPDATE_PROFILE_AVATAR_FAIL,
  UPLOAD_PROFILE_AVATAR_REQUEST,
  UPLOAD_PROFILE_AVATAR_SUCCESS,
  UPLOAD_PROFILE_AVATAR_FAIL,
  LOGOUT,
  GET_USER_PROJECT_STATE,
  SET_SUDIR_USER_DETAILS,
} from "../actions/UserActions";

import { ProfileState } from "../../types/User.interface";
import { RootState } from "../../types/State.interface";

const initialState: ProfileState = {
  userDetails: null,
  sudirUserDetails: null,
  loggedIn: false,
  forcedLoggedOut: null,
  error: "",
  isFetching: false,
  projectsState: null,
};

export function profileReducer(state: ProfileState = initialState, action) {
  switch (action.type) {
    case TEAM_LOGIN_HANDLE:
    case USER_SESSION_HANDLE:
    case UPDATE_PROFILE_REQUEST:
    case CHANGE_PASSWORD_REQUEST:
    case UPDATE_PROFILE_AVATAR_REQUEST:
    case UPLOAD_PROFILE_AVATAR_REQUEST:
      return { ...state, isFetching: true, error: "" };

    case TEAM_LOGIN_SUCCESS:
    case USER_SESSION_SUCCESS:
    // case UPDATE_PROFILE_SUCCESS:
    case CHANGE_PASSWORD_SUCCESS:
      // case UPDATE_PROFILE_AVATAR_SUCCESS:
      // case UPLOAD_PROFILE_AVATAR_SUCCESS:
      return {
        ...state,
        isFetching: false,
        userDetails: action.payload,
        loggedIn: action.payload.loggedIn,
      };

    case TEAM_LOGIN_FAIL:
    case USER_SESSION_FAILED:
    case UPDATE_PROFILE_FAIL:
    case CHANGE_PASSWORD_FAIL:
    case UPDATE_PROFILE_AVATAR_FAIL:
    case UPLOAD_PROFILE_AVATAR_FAIL:
      return { ...state, isFetching: false, error: action.payload };

    case LOGOUT:
      // fill as anonym
      return {
        ...state,
        projectsState: null,
        userDetails: null,
        loggedIn: false,
        forcedLoggedOut: true
      };
    case GET_USER_PROJECT_STATE:
      return {
        ...state,
        projectsState: { ...state.projectsState, ...action.payload },
      };
    case SET_SUDIR_USER_DETAILS:
      return {
        ...state,
        sudirUserDetails: { ...action.payload.details },
      };
    default:
      return state;
  }
}

export const selectIsUserReady = ({ user: { userDetails } }: RootState) =>
  !!userDetails?.loggedIn && !userDetails.partiallyFilled && userDetails.emailVerified;
