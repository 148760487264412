import { FC, useMemo, useState } from "react";
import { SphereBlockProps } from "./SphereBlock.types";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore from "swiper";
import "./SphereBlock.scss";
import Button from "../../../../../presentational/Controls/Button/Button";
import { useMediaQuery } from "react-responsive";
import { shortDesktopWidth } from "../../../../../../utils/constants/widthConstants";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../types/State.interface";

const countShortList = 3;

const SphereBlock: FC<SphereBlockProps> = ({
  spheresCategoriesList,
  sphereTitle,
  statistics,
  code,
  people,
  projects,
  background,
  background2,
  showReverse,
  onSelectCategory,
  onSelectProject,
}) => {
  const [swiper, setSwiper] = useState<SwiperCore>();
  const [activeIndex, setActiveIndex] = useState<number>();
  const [fullProjects, setFullProjects] = useState(false);
  const [fullCategories, setFullCategories] = useState(false);
  const isShortDesktop = useMediaQuery({
    query: `(max-width: ${shortDesktopWidth}px)`,
  });
  const [isInitedSwiper, setIsInitedSwiper] = useState(false);

  const spheres = useSelector((state: RootState) => state.gorod.spheres);

  const currentSphere = useMemo(
    () => spheres.find((s) => s.code === code),
    [code, spheres]
  );

  const selectedPerson = people[activeIndex];
  return (
    <div
      className={`birthday-sphere-block ${
        !isInitedSwiper ? "birthday-sphere-block_wait-for-swiper" : ""
      }`}
      data-id={code}
    >
      <div className="birthday-title">{sphereTitle || currentSphere?.name}</div>
      <div
        className={`birthday-sphere-block__stat ${
          statistics[0]?.name ? "birthday-sphere-block__stat_named" : ""
        }`}
      >
        {statistics.map((statItem, i) => (
          <div key={i}>
            {statItem.name && (
              <div className="birthday-sphere-block__subtitle">
                {statItem.name}:
              </div>
            )}
            <div className="birthday-sphere-block__stat__nums">
              <div>
                <span className="birthday-sphere-block__stat__num">
                  {statItem.accepted}
                </span>{" "}
                идей принято в реализацию
              </div>
              <div>
                <span className="birthday-sphere-block__stat__num">
                  {statItem.realized}
                </span>{" "}
                идей реализовано
              </div>
            </div>
          </div>
        ))}
      </div>
      <div
        className={`birthday-sphere-block__content ${
          showReverse ? "birthday-sphere-block__content_reverse" : ""
        }`}
      >
        <div>
          <img className="birthday-sphere-block__bg" src={background} alt="" />
          <img
            className="birthday-sphere-block__bg2"
            src={background2}
            alt=""
          />
          <div className="birthday-sphere-block__people">
            <Swiper
              loop
              speed={700}
              slidesPerView={3}
              centeredSlides
              onSwiper={setSwiper}
              onInit={() => setIsInitedSwiper(true)}
              onSlideChange={(sw) => {
                setActiveIndex(
                  sw.realIndex >= people.length
                    ? sw.realIndex - people.length
                    : sw.realIndex
                );
              }}
              allowTouchMove={false}
            >
              {people.map((person, i) => (
                <SwiperSlide
                  key={i}
                  onClick={() => {
                    if (swiper) {
                      if (
                        activeIndex === i - 1 ||
                        (activeIndex === people.length - 1 && i === 0)
                      ) {
                        swiper.slideNext();
                      } else if (
                        activeIndex === i + 1 ||
                        (activeIndex === 0 && i === people.length - 1)
                      ) {
                        swiper.slidePrev();
                      }
                    }
                  }}
                >
                  <img src={person.img} alt="" />
                </SwiperSlide>
              ))}
            </Swiper>
            {selectedPerson && (
              <>
                <div className="birthday-sphere-block__people__name">
                  {selectedPerson.name}
                </div>
                <div className="birthday-sphere-block__people__pos">
                  {selectedPerson.position}
                </div>
                <div
                  className={`birthday-sphere-block__people__desc ${
                    selectedPerson.nick
                      ? `birthday-sphere-block__people__desc--${selectedPerson.nick}`
                      : ""
                  }`}
                  dangerouslySetInnerHTML={{
                    __html: selectedPerson.description,
                  }}
                />
              </>
            )}
          </div>
        </div>
        <div>
          <div className="birthday-sphere-block__lists">
            <div>
              <div className="birthday-sphere-block__subtitle">
                Выполненные проекты
              </div>
              <ul>
                {(isShortDesktop && !fullProjects
                  ? projects.slice(0, countShortList)
                  : projects
                ).map((project, i) => (
                  <li onClick={() => onSelectProject(project)} key={i}>
                    «{project.title}»
                  </li>
                ))}
                {isShortDesktop && projects.length > countShortList && (
                  <li
                    className={`birthday-sphere-block__lists__more ${
                      fullProjects
                        ? "birthday-sphere-block__lists__more_open"
                        : ""
                    }`}
                    onClick={() => setFullProjects(!fullProjects)}
                  >
                    {fullProjects ? "Свернуть" : "Ещё"}
                  </li>
                )}
              </ul>
            </div>
            <div>
              <div className="birthday-sphere-block__subtitle">
                Темы для отправки идей в любое время
              </div>
              <ul>
                {(isShortDesktop && !fullCategories
                  ? spheresCategoriesList.slice(0, countShortList)
                  : spheresCategoriesList
                ).map(([sphere, category]) => (
                  <li
                    key={category.code}
                    onClick={() => onSelectCategory(sphere, category)}
                  >
                    {category.title}
                  </li>
                ))}
                {isShortDesktop &&
                  spheresCategoriesList.length > countShortList && (
                    <li
                      className={`birthday-sphere-block__lists__more ${
                        fullCategories
                          ? "birthday-sphere-block__lists__more_open"
                          : ""
                      }`}
                      onClick={() => setFullCategories(!fullCategories)}
                    >
                      {fullCategories ? "Свернуть" : "Ещё"}
                    </li>
                  )}
              </ul>
              <Button
                type="filled"
                text="Предложить идею"
                onClick={() => onSelectCategory(currentSphere)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SphereBlock;
