import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import "dayjs/locale/ru";
import { withRouter, useHistory } from "react-router-dom";

import NewsService from "../../../services/newsService";
import Share from "../../presentational/Share/Share";
import MetaDecorator from "../../presentational/MetaDecorator/MetaDecorator";

import "./NewsDetailsPage.scss";
import { News } from "../../../types/News.interface";
import { ApiStatusCode } from "../../../types/Common.interface";

const NewsDetails = (props) => {
  const history = useHistory();
  const [news, setNews] = useState<News | any>(null);

  useEffect(() => {
    const id = getNewsId();

    NewsService.getNewsById(id)
      .then((value) => {
        if (value.status === ApiStatusCode.OK) {
          setNews(value.data);
        } else {
          history.push("/news");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const getNewsId = () => {
    const [blank, page, id] = history.location.pathname.split("/");

    return id;
  };

  const pushPage = (item, page) => {
    history.push({
      pathname: "/" + page,
      state: { item },
    });
  };

  // RENDER
  const renderArrowBack = () => {
    return (
      <div className="news-details__back" onClick={(e) => history.push("/news")}>
        <div className="ic ui-icon-88"></div>
        Все Новости
      </div>
    );
  };

  const renderTitle = () => {
    return news && <h1 className="news-details__title">{news.title}</h1>;
  };

  const renderInfo = () => {
    return (
      <div className="news-details__info">
        <div className="news-details__info-date">
          {dayjs(news.published).locale("ru").format("DD MMMM YYYY, HH:mm")}
        </div>
        <div className="news-details__info-tag" onClick={() => pushPage(news, "news")}>
          {news.tag}
        </div>
      </div>
    );
  };

  const renderImage = () => {
    return <img className="news-details__img" src={"/uploads/get/" + news.imageId} alt={news.imageId} />;
  };

  const renderText = () => {
    return <div className="news-details__text" dangerouslySetInnerHTML={{ __html: news.text }}></div>;
  };

  const renderShare = () => {
    return (
      <div className="news-details__share">
        <span>Поделиться:</span>
        <Share
          type="unfolded"
          url={`/news/${news.number}`}
          imageUrl={`/uploads/get/${news.imageId}`}
          title={`${news.title} — Платформа Правительства Москвы «Город идей»`}
          colorIcon="#282828"
        />
      </div>
    );
  };

  return (
    <section className="news-details">
      {news && (
        <MetaDecorator
          title="Платформа Правительства Москвы «Город идей»"
          description="Всё самое интересное и актуальное о проектах."
          opengraph={{
            title: news?.title,
            description: news?.text,
            url: window.location.href,
            image: window.location.origin + "/uploads/get/" + news?.imageId,
          }}
        />
      )}

      {renderArrowBack()}
      {news && renderTitle()}
      {news && renderInfo()}
      {news && renderImage()}
      {news && renderText()}
      {news && renderShare()}
    </section>
  );
};

export default NewsDetails;
