import { ComponentProps } from "react";
import Textarea from "../../../../presentational/Controls/Textarea/Textarea";
import EventsTrackWrapperClick from "../EventsTrackWrapperClick";

const EventsTrackClickTextarea: ComponentProps<typeof Textarea>["ComponentTextarea"] = ({textareaRef, label, ...otherProps}) => (
    <EventsTrackWrapperClick id={36} replace={[label]}>
        <textarea ref={textareaRef} {...otherProps} />
    </EventsTrackWrapperClick>
);

export default EventsTrackClickTextarea;