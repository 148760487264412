import React, { Component, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setAppColor } from "../../../store/actions/LayoutActions";
import { AppColorsEnum } from "../../App.interface";

import "./PointsRulesPage.scss";

// '/api/promo/ideas/moderation/get'

const styles = `
  body{color:#202020; line-height: 1.3em;}
  .wrapper {padding: 40px 20px !important;}
  .pointsRulesWrapper{max-width: 1200px; margin: 0 auto; text-align: left}
  li, p{font-family:sans-serif; font-size:16px;}
  h1{text-align:center; font-size:34px;}
  h3{font-size:24px;}
  h2{font-size:30px;}
  div{text-align:center;font-family:sans-serif; font-size:16px;}
  p{margin: 20px 0},
  b{font-weight: bolder !important};
`;

const PointsRulesPage = (props) => {
  const dispath = useDispatch();

  useEffect(() => {
    dispath(setAppColor(AppColorsEnum.WHITE));
  }, []);

  return (
    <div className="pointsRulesWrapper">
      <style>{styles}</style>
      <p style={{ textAlign: "center", marginTop: 0 }}>&nbsp;</p>
      <h3 style={{ textAlign: "center", textTransform: "capitalize" }}>
        <b>ПРАВИЛА НАЧИСЛЕНИЯ БАЛЛОВ</b>
      </h3>
      <p style={{ textAlign: "center", fontStyle: "italic", margin: "10px 0" }}>
        в рамках участия в краудсорсинг-проектах Правительства Москвы
      </p>

      <p style={{ textAlign: "center", fontStyle: "italic", margin: "10px 0" }}>
        (вступают в силу с 01.03.2024)
      </p>

      <p style={{ textAlign: "center" }}>
        <b>I. </b>
        <b>Общие положения</b>
      </p>

      <p style={{ textAlign: "left" }}></p>
      <p>
        1.1. Настоящие Правила определяют условия и порядок начисления баллов в рамках участия в краудсорсинг-проектах Правительства Москвы за выполнение Участниками Проекта определенных действий (активностей).
      </p>
      <p>
        1.2. Количество начисляемых баллов зависит от типа учетной записи Участника Проекта (упрощенная / стандартная /
        полная учетная запись) в подсистеме «личный кабинет» государственной информационной системы «Портал
        государственных и муниципальных услуг (функций) города Москвы» в соответствии с приложениями 1, 2, 3
        соответственно к постановлению Правительства Москвы от 7 февраля 2012 г. № 23-ПП «О доступе физических лиц, в
        том числе зарегистрированных в качестве индивидуальных предпринимателей, и юридических лиц к подсистеме «личный
        кабинет» государственной информационной системы «Портал государственных и муниципальных услуг (функций) города
        Москвы» по адресу в информационно-телекоммуникационной сети Интернет{" "}
        <a href="https://www.mos.ru">https://www.mos.ru</a> (далее – учетная запись).
      </p>
      <p>
        1.3. Участник Проекта вправе по своему усмотрению обменять накопленные им баллы на поощрения, перечень которых
        представлен на сайте «Миллион призов» по адресу в информационно-телекоммуникационной сети «Интернет»  
        <a href="https://ag-vmeste.ru/catalog">https://ag-vmeste.ru/catalog</a>, в том числе из специальной категории
        поощрений с символикой Проекта, представленной на витрине сайта «Миллион призов» и предназначенной исключительно
        для Участников Проекта (далее – специальная витрина), с соблюдением условий пунктов 1.4, 1.5 настоящих Правил.
      </p>
      <p>
        1.4. Поощрения из специальной витрины Магазина поощрений становятся доступны Участнику Проекта,
        зарегистрировавшемуся на Платформе, в срок до 14 дней с момента такой регистрации при условии наличия у
        Участника Проекта стандартной или полной учетной записи.
      </p>
      <p>
        1.5. В случае повышения уровня учетной записи Участника Проекта Поощрения из специальной витрины Магазина
        поощрений становятся доступны Участнику Проекта в срок до 14 дней с момента получения Участником Проекта
        стандартной или полной учетной записи.
      </p>

      <p style={{ textAlign: "center" }}>
        <b>II. </b>
        <b> Перечень действий (активностей) и количество начисляемых за них баллов</b>
      </p>

      <p>
        2.1. За публикацию фотографии, загруженной Участником Проекта в разделе «Галерея реализованных идей» Платформы,
        Участнику с полной или стандартной учетной записью начисляется <b>100 баллов</b>. Баллы начисляются только за одну опубликованную (прошедшую модерацию) фотографию по каждой идее, но не более, чем за <b>10</b> фотографий в месяц.
      </p>

      <p>
        2.2. За получение Участником Проекта достижения в номинации (в соответствии с выбором экспертов и команды
        краудсорсинг-проекта), Участнику Проекта с полной или стандартной учетной записью начисляется <b>3000 баллов</b>
        .
      </p>

      <br></br>
      <br></br>
      <br></br>
      <p style={{ textAlign: "left", fontStyle: "italic", margin: "10px 0" }}>Дата публикации: 01.03.2024, 18:00</p>
    </div>
  );
};

export default PointsRulesPage;
