import {
  GET_VIDEOS_REQUEST,
  GET_VIDEOS_SUCCESS,
  GET_VIDEOS_FAIL,
  LOAD_VIDEOS_TAGS_SUCCESS,
  LOAD_VIDEOS_TAGS_FAIL,
  LOAD_VIDEOS_TAGS_HANDLE,
} from "../actions/VideoActions";
import { VideoState } from "../../types/Video.interface";

const initialState = {
  list: [],
  tags: [],
  error: "",
  isFetching: false,
  errorTags: "",
  isFetchingTags: false,
};

export function videoReducer(state: VideoState = initialState, action) {
  switch (action.type) {
    case GET_VIDEOS_REQUEST:
      return { ...state, isFetching: true, error: "" };

    case GET_VIDEOS_SUCCESS:
      return { ...state, isFetching: false, list: action.payload };

    case GET_VIDEOS_FAIL:
      return { ...state, isFetching: false, error: action.payload.message };

    case LOAD_VIDEOS_TAGS_HANDLE:
      return {
        ...state,
        isFetchingTags: true,
        errorTags: "",
      };

    case LOAD_VIDEOS_TAGS_SUCCESS: {
      return {
        ...state,
        isFetchingTags: false,
        tags: action.payload,
      };
    }

    case LOAD_VIDEOS_TAGS_FAIL:
      return {
        ...state,
        isFetchingTags: false,
        errorTags: action.payload.message,
      };

    default:
      return state;
  }
}
