import dayjs from "dayjs";
import { batch } from "react-redux";
import { nyLandingGetSettings } from "../../components/pages/NYLanding/Utils/settings";
import NYLandingService from "../../services/nyLanding";
import UserService from "../../services/userService";
import {
  NYLandingObjectState,
  NYLandingState,
} from "../../types/NYLanding.interface";
import { AppThunk } from "../../types/State.interface";
import { SUCCESS } from "../../types/statusCodes";
import {
  selectNYLandingGame,
  selectNYLandingGameEnabled,
  selectNYLandingGameFinished,
  selectNYLandingGameState,
  selectNYLandingLoggedIn,
  selectNYLandingPlatform,
} from "../reducers/nyLanding";

export const NY_LANDING_PRESENTATION_OPEN = "NY_LANDING_PRESENTATION_OPEN";
export const NY_LANDING_PRESENTATION_CLOSE = "NY_LANDING_PRESENTATION_CLOSE";
export const NY_LANDING_SET_PLATFORM = "NY_LANDING_SET_PLATFORM";
export const NY_LANDING_SET_PROGRESS = "NY_LANDING_SET_PROGRESS";
export const NY_LANDING_GAME_START = "NY_LANDING_GAME_START";
export const NY_LANDING_GAME_INIT = "NY_LANDING_GAME_INIT";
export const NY_LANDING_GAME_RESET = "NY_LANDING_GAME_RESET";
export const NY_LANDING_GAME_ADD_OBJECT = "NY_LANDING_GAME_ADD_OBJECT";
export const NY_LANDING_GAME_SET_DEFERRED = "NY_LANDING_GAME_SET_DEFERRED";
export const NY_LANDING_GAME_FINISH = "NY_LANDING_GAME_FINISH";
export const NY_LANDING_LOGIN = "NY_LANDING_LOGIN";
export const NY_LANDING_LOGOUT = "NY_LANDING_LOGOUT";

export const nyLandingPresentationOpen = () => ({
  type: NY_LANDING_PRESENTATION_OPEN,
});

export const nyLandingPresentationClose = () => ({
  type: NY_LANDING_PRESENTATION_CLOSE,
});

export const nyLandingSetPlatform =
  (platform: NYLandingState["platform"]): AppThunk =>
  (dispatch, getState) => {
    const prevPlatform = selectNYLandingPlatform(getState());
    if (prevPlatform !== platform) {
      batch(() => {
        dispatch({
          type: NY_LANDING_SET_PLATFORM,
          payload: platform,
        });

        const { objects } = nyLandingGetSettings(platform);
        dispatch(nyLandingGameInit(objects));
      });
    }
  };

export const nyLandingSetProgress = (n: number) => ({
  type: NY_LANDING_SET_PROGRESS,
  payload: n,
});

export const nyLandingGameInit = <T extends NYLandingObjectState>(
  objects: T[]
) => ({
  type: NY_LANDING_GAME_INIT,
  payload: {
    objects,
  },
});

export const nyLandingGameReset = () => ({ type: NY_LANDING_GAME_RESET });

export const nyLandingGameStart =
  (id?: NYLandingObjectState["id"], timeDefer?: number): AppThunk =>
  (dispatch, getState) => {
    const loggedIn = selectNYLandingLoggedIn(getState());
    const gameEnabled = selectNYLandingGameEnabled(getState());
    const gameFinished = selectNYLandingGameFinished(getState());

    if (loggedIn && !gameEnabled && !gameFinished) {
      batch(() => {
        dispatch({ type: NY_LANDING_GAME_START });
        id && dispatch(nyLandingGameFoundObject(id, timeDefer));
      });
    }
  };

export const nyLandingGameSetDeferred = (
  id: NYLandingObjectState["id"],
  deferred: boolean
) => ({ type: NY_LANDING_GAME_SET_DEFERRED, payload: { id, deferred } });

export const nyLandingGameFoundObject =
  (id: NYLandingObjectState["id"], timeDefer?: number): AppThunk =>
  (dispatch, getState) => {
    const gameEnabled = selectNYLandingGameEnabled(getState());
    const gameState = selectNYLandingGameState(getState());
    if (
      gameEnabled &&
      gameState.foundObjects.ids.length < gameState.allObjects.ids.length
    ) {
      batch(() => {
        dispatch({ type: NY_LANDING_GAME_ADD_OBJECT, payload: id });
        if (timeDefer) {
          dispatch(nyLandingGameSetDeferred(id, true));
          setTimeout(
            () => dispatch(nyLandingGameSetDeferred(id, false)),
            timeDefer
          );
        }

        const {
          started,
          state: { foundObjects, allObjects },
        } = selectNYLandingGame(getState());
        if (foundObjects.ids.length === allObjects.ids.length) {
          NYLandingService.finishGame({
            start: dayjs(started).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
            system: selectNYLandingPlatform(getState()),
          })
            .then((response) => {
              if (response.status !== SUCCESS) {
                throw new Error(response.message);
              }
              dispatch({ type: NY_LANDING_GAME_FINISH });
            })
            .catch(console.error);
        }
      });
    }
  };

export const nyLandingGetSession = (successCallback?) => (dispatch) => {
  return UserService.getUserForNyLanding().then((userData) => {
    if (!userData?.data) return;

    if (userData.data.id === "-1") {
      dispatch({
        type: NY_LANDING_LOGIN,
        payload: { loggedIn: false, ...userData?.data },
      });
    } else {
      dispatch({
        type: NY_LANDING_LOGIN,
        payload: { loggedIn: true, ...userData?.data },
      });

      if (typeof successCallback === "function") successCallback();
    }
  });
};

export const nyLandingLogout = (): AppThunk => (dispatch) => {
  return UserService.logoutNY().then((response) => {
    batch(() => {
      dispatch({ type: NY_LANDING_LOGOUT });
      dispatch(nyLandingGameReset());
      dispatch(nyLandingGetSession());
    });
  });
};
