import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setAppColor } from "../../../store/actions/LayoutActions";

import { fetchRequest } from "../../../utils/axiosConfig";
import { AppColorsEnum } from "../../App.interface";
import MetaDecorator from "../../presentational/MetaDecorator/MetaDecorator";
import "./LicensePage.scss";

const LicensePage = () => {
  const dispatch = useDispatch();

  const [license, setLicense] = useState("");
  useEffect(() => {
    dispatch(setAppColor(AppColorsEnum.WHITE));
    fetchLicense();
  }, []);

  const fetchLicense = async () => {
    try {
      const res = await fetchRequest(" /api/settings/license", {}, "GET");
      setLicense(res.data.data);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <MetaDecorator
        title="Пользовательское соглашение"
        description="Проекты Правительства Москвы — это информационная технология, которая позволяет жителям предлагать свои идеи на заданную тему, комментировать, обсуждать, дорабатывать и выбирать из них лучшие."
      />
      <div
        className="license-wrapper"
        dangerouslySetInnerHTML={{ __html: license }}
      />
    </>
  );
};

export default LicensePage;
