import React from "react";

import "./Radio.scss";

interface Props {
  id: string;
  value: string;
  label: String;
  isRequired?: Boolean;
  onChange?: () => void;
  isInvalid?: Boolean;
  isSelected: any;
  changed: () => void;
}

class Radio extends React.Component<Props, any> {
  constructor(props) {
    super(props);
  }

  getClassesForLabel = () => {
    let classes = "cr-radio__label";

    if (this.props.isInvalid) classes += " cr-radio__label-invalid";

    return classes;
  };

  render() {
    const props = this.props;

    return (
      <div className="cr-radio">
        <input
          className="cr-radio__input"
          id={props.id}
          onChange={props.changed}
          value={props.value}
          type="radio"
          checked={props.isSelected}
        />
        <label className={this.getClassesForLabel()} htmlFor={props.id}>
          {props.label}
        </label>
      </div>
    );
  }
}

export default Radio;
