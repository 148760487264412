import React, { useEffect, useState } from "react";
import "./Game.scss";
import NewYear2023Service from "../../../Services/newyear2023-service";
import { MosProjects } from "../../../../../../types/Projects.interface";
import { AnswerStatusEnum, GameStatus, Question } from "../../../../../presentational/Game/Game.interface";
import Loader from "../../../../../presentational/Loaders/Loader/Loader";
import FadeIn from "../../../../../presentational/FadeIn/FadeIn";
import { Scrollbar } from "react-scrollbars-custom";
import { useInterval } from "../../../../../../hooks/useInterval";

interface GameProps {
  onWin?: () => void;
  onLoose?: () => void;
}

const Game: React.FC<GameProps> = ({ onWin, onLoose }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  const [isPaused, setIsPaused] = useState(true);
  const [time, setTime] = useState(30);
  const [quizQuestion, setQuizQuestion] = useState<Question>(null);

  useInterval(
    () => {
      if (time !== 0) {
        setTime(time - 1);
      } else {
        onLoose();
        setIsPaused(true);
        setTime(30);
      }
    },
    isPaused ? null : 1000
  );

  const mixAnswers = (quiestion: Question): Question => {
    const { answers } = quiestion;
    let index = answers.length - 1;
    while (index) {
      const randomIndex = Math.floor(Math.random() * answers.length);
      [answers[index], answers[randomIndex]] = [answers[randomIndex], answers[index]];
      index--;
    }
    return quiestion;
  };

  const requestQuestion = (): Promise<void> => {
    setIsPaused(true);
    setIsLoading(true);
    return NewYear2023Service.getQuizQuestion(MosProjects.AG)
      .then((response) => {
        setTime(30);
        mixAnswers(response);
        setQuizQuestion(response);
        setIsPaused(false);
      })
      .catch((error) => console.log(error))
      .finally(() => setIsLoading(false));
  };

  const selectAnswer = (answer: string, event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setIsSelected(true);
    setIsPaused(true);

    NewYear2023Service.checkQuizAnswer(answer, MosProjects.AG).then(({ gameStatus, answerStatus }) => {
      const { parentElement } = event.target as HTMLButtonElement;

      if (gameStatus === GameStatus.WON) {
        setIsSelected(false);
        setIsPaused(false);
        onWin();
        return;
      }

      if (answerStatus === AnswerStatusEnum.CORRECT) {
        parentElement.classList.add("quize-game__answer--right");
        setTimeout(() => {
          setIsSelected(false);
          requestQuestion();
        }, 1500);
      } else {
        parentElement.classList.add("quize-game__answer--wrong");

        setTimeout(() => {
          setIsSelected(false);
          onLoose();
        }, 1500);
      }
    });
  };

  useEffect(() => {
    requestQuestion();
  }, []);

  return (
    <div className="quize-game">
      <div className="quize-game__info">
        {quizQuestion ? (
          <>
            <span className="quize-game__question-number">{quizQuestion?.questionNumber || 1}/10</span>
            <span className="quize-game__timer">{time}</span>
          </>
        ) : null}
      </div>
      <Scrollbar className="quize-game__content" noDefaultStyles>
        {quizQuestion ? <p className="quize-game__question">{quizQuestion.question}</p> : null}

        {isLoading ? (
          <div className="quize-game__loader">
            <Loader />
          </div>
        ) : (
          <FadeIn>
            <ul>
              {quizQuestion?.answers.map((answer) => {
                return (
                  <li className="quize-game__answer" key={answer}>
                    <button disabled={isSelected} onClick={(event) => selectAnswer(answer, event)}>
                      {answer}
                    </button>
                  </li>
                );
              })}
            </ul>
          </FadeIn>
        )}
      </Scrollbar>
    </div>
  );
};
export default Game;
