import { API, httpParamsSerialize } from "./api";

const baseUrl = "/api/gorod";

export interface GorodIdeaCategory {
  id?: string;
  active: boolean;
  code: string;
  title: string;
  description: string;
  places: string[];
}

export interface GorodIdeaSphere {
  id: string;
  name: string;
  code: string;
  attachmentId: string;
  mobileAttachmentId: string;
  description: string;
  shortDescription: string;
  categories: GorodIdeaCategory[];
  active: boolean;
  enablePromoLabel?: boolean;
  promoLabelText?: string;
  logoAttachmentId?: string;
  titleColor?: string;
  titleSeo?: string;
  descriptionSeo?: string;
}

const GorodService = {
  API: {
    getSpheres: () => {
      const url = `${baseUrl}/idea/groups`;
      return API.get(url).then((response) => {
        return response?.data?.data;
      });
    },
  },

  getSphereByCode: (code: string, spheres: GorodIdeaSphere[]) =>
    spheres.find((s) => s.code === code),

  getCategoryByCode: (sphere: GorodIdeaSphere, code: string) =>
    sphere.categories.find((c) => c.code === code),

  isCityPlace: (category: GorodIdeaCategory) =>
    category?.places.some((place) => place === "CITY"),

  isAddressPlace: (category: GorodIdeaCategory) =>
    category?.places.some((place) => place === "ADDRESS"),

  isRegionPlace: (category: GorodIdeaCategory) =>
    category?.places.some((place) => place === "REGION"),
};

export default GorodService;
