import { TooltipProps } from "../../../../presentational/Controls/Tooltip/Tooltip.interface";
import { InformerProps } from "../../components/Informer";

export type LeisureLandingInfoItem = {
  code: string;
  posLeft: number;
  posTop: number;
  tooltipPlace?: TooltipProps["place"];
  isTopLayer?: boolean;
} & Pick<InformerProps, "modalPosX" | "modalPosY" | "modalSrc" | "modalTitle" | "modalOffset">;

const leisureLandingInfoItems: LeisureLandingInfoItem[] = [
  {
    posLeft: 819,
    posTop: 533,
    modalPosX: "right",
    modalSrc: require("../../../../../assets/leisure-landing/informers/1 - Озеленение набережных.jpg").default,
    modalTitle:
      "Благодаря идее Елены Никитиной, предложенной на проекте «<a href='https://crowd.mos.ru/workresult?id=ecology' target='_blank'><strong>Экологическая стратегия Москвы</strong></a>», озеленены набережные города",
    code: "landscaping_1",
  },
  {
    posLeft: 1558,
    posTop: 717,
    tooltipPlace: "left",
    modalPosX: "left",
    modalPosY: "top",
    modalSrc: require("../../../../../assets/leisure-landing/informers/2 - Благоустройство пространства у метро.jpg")
      .default,
    modalTitle:
      "Благодаря идее Никиты Седых, предложенной в сфере «<a href='https://crowd.mos.ru/propose/movement?page=1' target='_blank'><strong>Передвижение по городу</strong></a>», благоустроены пространства<br />у метро",
    code: "metro_1",
  },
  {
    posLeft: 2320,
    posTop: 726,
    modalPosX: "right",
    modalPosY: "top",
    modalSrc: require("../../../../../assets/leisure-landing/informers/1 - Вендинговые аппараты.jpg").default,
    modalTitle:
      "Благодаря идее Александра Щерблюка, предложенной на проекте «<a href='https://crowd.mos.ru/workresult?id=mcd' target='_blank'><strong>Московские центральные диаметры</strong></a>», на станциях МЦД установлены вендинговые аппараты",
    code: "vending_2",
  },
  {
    posLeft: 2523,
    posTop: 388,
    modalPosX: "right",
    modalPosY: "bottom",
    modalSrc: require("../../../../../assets/leisure-landing/informers/2 - Указатели направления движения поездов.jpg")
      .default,
    modalTitle:
      "Благодаря идее Марины Чернушевич, предложенной на проекте «<a href='https://crowd.mos.ru/workresult?id=mcd' target='_blank'><strong>Московские центральные диаметры</strong></a>», на станциях МЦД размещены указатели направления движения поездов",
    code: "indicator_2",
  },
  {
    posLeft: 2882,
    posTop: 733,
    tooltipPlace: "left",
    modalPosX: "left",
    modalPosY: "top",
    modalSrc: require("../../../../../assets/leisure-landing/informers/4 - Растения в вестибюле.jpg").default,
    modalTitle:
      "Благодаря идее Светланы Дорофеевой, предложенной на проекте «<a href='https://crowd.mos.ru/workresult?id=mcd' target='_blank'><strong>Московские центральные диаметры</strong></a>», вестибюли станций МЦД украшены растениями",
    code: "plant_2",
  },
  {
    posLeft: 3094,
    posTop: 639,
    modalPosX: "right",
    modalPosY: "top",
    modalSrc: require("../../../../../assets/leisure-landing/informers/5 - Стойки с картами линий.jpg").default,
    modalTitle:
      "Благодаря идее Анны Кутяшовой, предложенной на проекте «<a href='https://crowd.mos.ru/workresult?id=mcd' target='_blank'><strong>Московские центральные диаметры</strong></a>», на станциях МЦД установлены стойки с картами линий",
    code: "map_2",
  },
  {
    posLeft: 2978,
    posTop: 381,
    modalPosX: "right",
    modalPosY: "bottom",
    modalSrc: require("../../../../../assets/leisure-landing/informers/3 - Электронное инфо-табло.jpg").default,
    modalTitle:
      "Благодаря идее Марины Чернушевич, предложенной на проекте «<a href='https://crowd.mos.ru/workresult?id=mcd' target='_blank'><strong>Московские центральные диаметры</strong></a>», на станциях МЦД установлены электронные инфотабло",
    code: "information_2",
  },
  {
    posLeft: 3590,
    posTop: 493,
    tooltipPlace: "left",
    modalPosX: "left",
    modalSrc: require("../../../../../assets/leisure-landing/informers/6 - Место встречи.jpg").default,
    modalTitle:
      "Благодаря идее Ивана Понтуса, предложенной на проекте «<a href='https://crowd.mos.ru/workresult?id=mcd' target='_blank'><strong>Московские центральные диаметры</strong></a>», на станциях МЦД появилось место встречи",
    code: "meeting_2",
  },
  {
    posLeft: 3636,
    posTop: 732,
    tooltipPlace: "left",
    modalPosX: "left",
    modalPosY: "top",
    modalSrc: require("../../../../../assets/leisure-landing/informers/7 - Терминалы для карты «Тройка».jpg").default,
    modalTitle:
      "Благодаря идее Евгения Матвеева, предложенной на проекте «<a href='https://crowd.mos.ru/workresult?id=mcd' target='_blank'><strong>Московские центральные диаметры</strong></a>», на станциях МЦД установлены терминалы для карты «Тройка»",
    code: "terminal_2",
  },
  {
    posLeft: 4323,
    posTop: 524,
    modalPosX: "right",
    modalSrc: require("../../../../../assets/leisure-landing/informers/1 - Транспорт для катания.jpg").default,
    modalTitle:
      "Благодаря идее Марины Чернушевич, предложенной на проекте «<a href='https://crowd.mos.ru/workresult?id=moi_park' target='_blank'><strong>Мой парк</strong></a>», в столичных парках появился транспорт для катания",
    code: "transport_3",
  },
  // {
  //   posLeft: 4527,
  //   posTop: 460,
  //   modalPosX: "right",
  //   modalPosY: "top",
  //   modalSrc: require("../../../../../assets/leisure-landing/informers/2 - Амурский бархат.jpg").default,
  //   modalTitle: "Амурский бархат<br />в городе",
  // },
  {
    posLeft: 4571,
    posTop: 581,
    modalPosX: "right",
    modalPosY: "top",
    modalSrc: require("../../../../../assets/leisure-landing/informers/3 - Просветительские фотовыставки.jpeg").default,
    modalTitle:
      "Благодаря идее Ольги Лугавой, предложенной на проекте «<a href='https://crowd.mos.ru/workresult?id=moi_park' target='_blank'><strong>Мой парк</strong></a>», в столичных парках проводятся просветительские фотовыставки",
    code: "exhibition_3",
  },
  {
    posLeft: 4901,
    posTop: 793,
    modalPosX: "right",
    modalPosY: "top",
    modalSrc: require("../../../../../assets/leisure-landing/informers/4 - Кормушки для птиц.jpg").default,
    modalTitle:
      "Благодаря идее Аллы Колпаковой, предложенной на проекте «<a href='https://crowd.mos.ru/workresult?id=moi_park' target='_blank'><strong>Мой парк</strong></a>», в столичных парках установлены кормушки для птиц",
    isTopLayer: true,
    code: "feeder_3",
  },
  // {
  //   posLeft: 5555,
  //   posTop: 556,
  //   tooltipPlace: "left",
  //   modalPosX: "left",
  //   modalPosY: "top",
  //   modalSrc: require("../../../../../assets/leisure-landing/informers/5 - Запрет на кормление птиц около водоемов.jpg")
  //     .default,
  //   modalTitle: "Запрет на кормление птиц около водоемов",
  // },
  // {
  //   posLeft: 5670,
  //   posTop: 482,
  //   modalPosX: "right",
  //   modalPosY: "bottom",
  //   modalSrc: require("../../../../../assets/leisure-landing/informers/6 - Ели.jpeg").default,
  //   modalTitle: "Ели в городе",
  // },
  {
    posLeft: 5570,
    posTop: 717,
    modalPosX: "right",
    modalPosY: "top",
    modalSrc: require("../../../../../assets/leisure-landing/informers/7 - Подготовка к сдаче ГТО.jpg").default,
    modalTitle:
      "Благодаря идее Дианы Ивановой, предложенной на проекте «<a href='https://crowd.mos.ru/workresult?id=moi_park' target='_blank'><strong>Мой парк</strong></a>», в столичных парках проводится подготовка к сдаче ГТО",
    code: "GTO_2",
  },
  {
    posLeft: 6942,
    posTop: 605,
    tooltipPlace: "left",
    modalPosX: "left",
    modalPosY: "top",
    modalSrc: require("../../../../../assets/leisure-landing/informers/1 - Адаптивная и тонизирующая гимнастика.jpg")
      .default,
    modalTitle:
      "Благодаря идее Юрия Кочуры, предложенной на проекте «<a href='https://crowd.mos.ru/workresult?id=aktivnoe_dolgoletie' target='_blank'><strong>Активное долголетие</strong></a>»,<br />в городе проводятся занятия<br />по адаптивной гимнастике для всех возрастов",
    code: "gymnastic_4",
  },
  {
    posLeft: 6227,
    posTop: 580,
    modalPosX: "right",
    modalPosY: "top",
    modalSrc: require("../../../../../assets/leisure-landing/informers/2 - Семейный спорт.jpg").default,
    modalTitle:
      "Благодаря идее Ольги Абрамовой, предложенной на проекте «<a href='https://crowd.mos.ru/workresult?id=project_sport' target='_blank'><strong>Город спорта</strong></a>», в столице развивается спорт для всей семьи",
    code: "sport_4",
  },
  {
    posLeft: 6603,
    posTop: 653,
    tooltipPlace: "left",
    modalPosX: "left",
    modalPosY: "top",
    modalSrc: require("../../../../../assets/leisure-landing/informers/3 - Развитие любительского спорта.jpg").default,
    modalTitle:
      "Благодаря идее Вероники Стамболиевой, предложенной на проекте «<a href='https://crowd.mos.ru/workresult?id=project_sport' target='_blank'><strong>Город спорта</strong></a>», в столице развивается любительский спорт",
    code: "development_4",
  },
  // {
  //   posLeft: 7068,
  //   posTop: 235,
  //   tooltipPlace: "left",
  //   modalPosX: "left",
  //   modalPosY: "bottom",
  // },
  {
    posLeft: 7108,
    posTop: 527,
    tooltipPlace: "left",
    modalPosX: "left",
    modalPosY: "top",
    modalSrc: require("../../../../../assets/leisure-landing/informers/4 - Спортивный досуг во дворах.jpg").default,
    modalTitle:
      "Благодаря идее Аллы Осокиной, предложенной на проекте «<a href='https://crowd.mos.ru/workresult?id=project_sport' target='_blank'><strong>Город спорта</strong></a>», в столичных дворах создаются условия для спортивного<br />досуга",
    code: "leisure_4",
  },
  {
    posLeft: 7239,
    posTop: 515,
    modalPosX: "right",
    modalPosY: "top",
    modalSrc: require("../../../../../assets/leisure-landing/informers/5 - Установка тренажеров.jpg").default,
    modalTitle:
      "Благодаря идее Михаила Сорокина, предложенной на проекте «<a href='https://crowd.mos.ru/workresult?id=cmy' target='_blank'><strong>Комфортная Москва</strong></a>»,<br />в сквере по улице Юности установлены тренажеры",
    code: "trainer_4",
  },
  {
    posLeft: 7939,
    posTop: 634,
    modalPosX: "right",
    modalPosY: "top",
    modalSrc: require("../../../../../assets/leisure-landing/informers/1 - Зарядные станции для экотранспорта.jpg")
      .default,
    modalTitle:
      "Благодаря идее Влады Лобановой, предложенной на проекте «<a href='https://crowd.mos.ru/workresult?id=ecology' target='_blank'><strong>Экологическая стратегия Москвы</strong></a>», в городе установлены зарядные станции для экотранспорта",
    code: "charging_5",
  },
  {
    posLeft: 8401,
    posTop: 550,
    tooltipPlace: "left",
    modalPosX: "left",
    modalPosY: "top",
    modalSrc: require("../../../../../assets/leisure-landing/informers/2 - Мероприятия по высадке деревьев.jpg")
      .default,
    modalTitle:
      "Благодаря идее Алексея Котельникова, предложенной на проекте «<a href='https://crowd.mos.ru/workresult?id=moi_park' target='_blank'><strong>Мой парк</strong></a>», в столичных парках проводятся мероприятия по высадке деревьев",
    code: "event_5",
  },
  {
    posLeft: 8583,
    posTop: 535,
    tooltipPlace: "left",
    modalPosX: "left",
    modalPosY: "top",
    modalSrc: require("../../../../../assets/leisure-landing/informers/3 - Экотропы в городе.jpg").default,
    modalTitle:
      "Благодаря идее Федора Иванова, предложенной на проекте «<a href='https://crowd.mos.ru/workresult?id=ecology' target='_blank'><strong>Экологическая стратегия Москвы</strong></a>», в городе появились экотропы",
    code: "trail_5",
  },
  {
    posLeft: 8916,
    posTop: 753,
    modalPosX: "right",
    modalPosY: "top",
    modalSrc: require("../../../../../assets/leisure-landing/informers/4 - Информационные стенды о животных.jpeg")
      .default,
    modalTitle:
      "Благодаря идее Натальи Розиной, предложенной на проекте «<a href='https://crowd.mos.ru/workresult?id=ecology' target='_blank'><strong>Экологическая стратегия Москвы</strong></a>», на природных территориях города размещены информационные стенды о животных",
    isTopLayer: true,
    code: "stand_5",
  },
  {
    posLeft: 9030,
    posTop: 537,
    modalPosX: "right",
    modalPosY: "top",
    modalSrc:
      require("../../../../../assets/leisure-landing/informers/5 - Контейнеры для сбора лампочек и батареек.jpeg")
        .default,
    modalTitle:
      "Благодаря идее Владимира Елисеенко, предложенной на проекте «<a href='https://crowd.mos.ru/workresult?id=ecology' target='_blank'><strong>Экологическая стратегия Москвы</strong></a>», в городе установлены контейнеры для сбора лампочек и батареек",
    code: "container_5",
  },
  {
    posLeft: 8878,
    posTop: 602,
    tooltipPlace: "left",
    modalPosX: "left",
    modalPosY: "top",
    modalSrc: require("../../../../../assets/leisure-landing/informers/6 - Субботники в парках.jpg").default,
    modalTitle:
      "Благодаря идее Анатолия Рожкова, предложенной на проекте «<a href='https://crowd.mos.ru/workresult?id=moi_park' target='_blank'><strong>Мой парк</strong></a>», в столичных парках проводятся субботники",
    code: "cleaning_5",
  },
  {
    posLeft: 9255,
    posTop: 529,
    modalPosX: "right",
    modalSrc: require("../../../../../assets/leisure-landing/informers/7 - Парки на месте бывших промзон.jpg").default,
    modalTitle:
      "Благодаря идее Марины Кропотовой, предложенной на проекте «<a href='https://crowd.mos.ru/workresult?id=ecology' target='_blank'><strong>Экологическая стратегия Москвы</strong></a>», на месте бывших городских промзон созданы парки",
    code: "industrial_5",
  },
  {
    posLeft: 9822,
    posTop: 680,
    modalPosX: "right",
    modalPosY: "top",
    modalSrc: require("../../../../../assets/leisure-landing/informers/1 - Навигация.jpg").default,
    modalTitle:
      "Благодаря идее Валерии Васильевой, предложенной на проекте «<a href='https://crowd.mos.ru/workresult?id=libraries' target='_blank'><strong>Московские библиотеки</strong></a>», в столичных библиотеках появилась навигация",
    code: "navigation_6",
  },
  {
    posLeft: 10248,
    posTop: 716,
    tooltipPlace: "left",
    modalPosX: "left",
    modalPosY: "top",
    modalSrc: require("../../../../../assets/leisure-landing/informers/2 - Компьютерный класс.jpg").default,
    modalTitle:
      "Благодаря идее Елены Беловой, предложенной на проекте «<a href='https://crowd.mos.ru/workresult?id=cult' target='_blank'><strong>Культура рядом</strong></a>», в культурных учреждениях города открылись компьютерные классы",
    code: "computer_6",
  },
  {
    posLeft: 10450,
    posTop: 471,
    modalPosX: "left",
    modalSrc: require("../../../../../assets/leisure-landing/informers/3 - Буккроссинг.jpg").default,
    modalTitle:
      "Благодаря идее Елены Акиньшиной, предложенной на проекте «<a href='https://crowd.mos.ru/workresult?id=libraries' target='_blank'><strong>Московские библиотеки</strong></a>», в столичных библиотеках поддерживается буккроссинг",
    code: "bookcrossing_6",
  },
  {
    posLeft: 10620,
    posTop: 350,
    modalPosX: "right",
    modalPosY: "bottom",
    modalSrc: require("../../../../../assets/leisure-landing/informers/4 - Климатическое оборудование.jpg").default,
    modalTitle:
      "Благодаря идее Владимира Андреева, предложенной на проекте «<a href='https://crowd.mos.ru/workresult?id=libraries' target='_blank'><strong>Московские библиотеки</strong></a>», в столичных библиотеках установлено климатическое оборудование",
    code: "conditioner_6",
  },
  {
    posLeft: 10879,
    posTop: 774,
    tooltipPlace: "left",
    modalPosX: "left",
    modalPosY: "top",
    modalSrc: require("../../../../../assets/leisure-landing/informers/5 - Детские развивающие кружки.jpg").default,
    modalTitle:
      "Благодаря идее Максима Орехова, предложенной на проекте «<a href='https://crowd.mos.ru/workresult?id=libraries' target='_blank'><strong>Московские библиотеки</strong></a>», в столичных библиотеках созданы детские развивающие кружки",
    code: "children_6",
  },
  {
    posLeft: 10999,
    posTop: 692,
    modalPosX: "right",
    modalPosY: "top",
    modalSrc: require("../../../../../assets/leisure-landing/informers/6 - Выставки творчества.jpg").default,
    modalTitle:
      "Благодаря идее Вероники Стамболиевой, предложенной на проекте «<a href='https://crowd.mos.ru/workresult?id=gmst' target='_blank'><strong>Городские мастерские семейного творчества</strong></a>», в культурных учреждениях города проводятся выставки творчества",
    code: "creativity_6",
  },
  {
    posLeft: 11319,
    posTop: 727,
    tooltipPlace: "left",
    modalPosX: "left",
    modalPosY: "top",
    modalSrc: require("../../../../../assets/leisure-landing/informers/7 - Кофейня.jpg").default,
    modalTitle:
      "Благодаря идее Алексея Кузнецова, предложенной на проекте «<a href='https://crowd.mos.ru/workresult?id=cult' target='_blank'><strong>Культура рядом</strong></a>», в культурных учреждениях города открылись кофейни",
    code: "coffee_6",
  },
  {
    posLeft: 11915,
    posTop: 703,
    tooltipPlace: "left",
    modalPosX: "left",
    modalPosY: "top",
    modalSrc: require("../../../../../assets/leisure-landing/informers/1 - Благоустройство парков.jpg").default,
    modalTitle:
      "Благодаря идее Виталия Кромма, предложенной в сфере «<a href='https://crowd.mos.ru/propose/parks?page=1' target='_blank'><strong>Парки</strong></a>», осуществляется благоустройство различных парковых территорий столицы",
    code: "part_7",
  },
  {
    posLeft: 12154,
    posTop: 517,
    tooltipPlace: "left",
    modalPosX: "left",
    modalPosY: "top",
    modalSrc: require("../../../../../assets/leisure-landing/informers/2 - Современное освещение в парках.jpg").default,
    modalTitle:
      "Благодаря идее Виталия Кромма, предложенной в сфере «<a href='https://crowd.mos.ru/propose/parks?page=1' target='_blank'><strong>Парки</strong></a>», организовано современное освещение<br />на московских парковых территориях",
    code: "lighting_7",
  },
  {
    posLeft: 15141,
    posTop: 720,
    tooltipPlace: "left",
    modalPosX: "left",
    modalPosY: "top",
    modalSrc: require("../../../../../assets/leisure-landing/informers/1 - Организация велопарковок.jpg").default,
    modalTitle:
      "Благодаря идее Валерии Моторуевой, предложенной в сфере «<a href='https://crowd.mos.ru/propose/bicycle?page=1' target='_blank'><strong>Велосипеды и самокаты</strong></a>»,<br />в городе осуществляется организация велопарковок",
    code: "parking_8",
  },
];

export default leisureLandingInfoItems;
