import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import CurrentPage from "../../pages/Current/CurrentPage";
import FadeIn from "../../presentational/FadeIn/FadeIn";
import FourColumnLoader from "../../presentational/Loaders/FourColumnLoader/FourColumnLoader";
import { getCurrentProjects } from "../../../store/actions/ProjectActions";

const CurrentContainer = (props) => {
  const dispatch = useDispatch();
  const projects = useSelector((state: any) => state.projects.current);

  useEffect(() => {
    if (!projects) dispatch(getCurrentProjects());
  }, []);

  return (
    <div>
      {(projects && (
        <FadeIn>
          <CurrentPage projects={projects} />
        </FadeIn>
      )) || <FourColumnLoader />}
    </div>
  );
};

export default CurrentContainer;
