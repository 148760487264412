import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Logo from "../../Header/Logo/Logo";
import Button from "../../../presentational/Controls/Button/Button";
import Input from "../../../presentational/Controls/Input/Input";
import { logout, teamLogin } from "../../../../store/actions/UserActions";
import { SUCCESS, ACCESS_RIGHTS_ERROR } from "../../../../types/statusCodes";

import "./ResendEmailModal.scss";
import { AppModal } from "../../Modal/AppModal";
import { fetchRequest } from "../../../../utils/axiosConfig";
import UserService from "../../../../services/userService";
import {
  hideInfoModal,
  showInfoModal,
} from "../../../../store/actions/LayoutActions";

const ResendEmailModal = (props) => {
  const dispatch = useDispatch();
  const [blockedTime, setBlockedTime] = useState(0);
  const [blockedCounter, setBlockedCounter] = useState(
    setInterval(() => {}, 10 ** 10)
  );

  const blocked = () => blockedTime > 0;

  const closeAndLogout = () => {
    dispatch(logout());
    props.onClose();
  };

  const closeInfoModal = () => dispatch(hideInfoModal());
  const infoModalCloseButton = () => {
    return <Button text="Закрыть" type="outlined" onClick={closeInfoModal} />;
  };

  const sendVerify = () => {
    UserService.resendEmailVerification()
      .then((response) => {
        if (response.data.status === SUCCESS) {
          dispatch(
            showInfoModal("Письмо успешно отправлено.", infoModalCloseButton())
          );
        } else if (response.data.status === ACCESS_RIGHTS_ERROR) {
          dispatch(
            showInfoModal(
              "Произошла ошибка. Попробуйте заново войти в аккаунт и повторить попытку.",
              infoModalCloseButton()
            )
          );
        } else {
          dispatch(
            showInfoModal(response.data.message, infoModalCloseButton())
          );
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(showInfoModal(err.message, infoModalCloseButton()));
      })
      .finally(() => {
        setBlockedTime(30);
      });
  };

  useEffect(() => {
    if (blockedTime > 0) {
      clearInterval(blockedCounter);

      const counter = setInterval(() => setBlockedTime(blockedTime - 1), 1000);
      setBlockedCounter(counter);
    }
  }, [blockedTime]);

  return (
    <AppModal
      center
      classNames={{
        modal: "resend-email-modal",
        overlay: "resend-email-overlay",
      }}
      closeIconId="close-modal-icon"
      showCloseIcon={false}
      blockScroll={true}
      closeIconSize={25}
      open={!!props.isOpened}
      closeOnEsc={true}
      closeOnOverlayClick={false}
      onClose={closeAndLogout}
    >
      <>
        <div className="resend-email-modal__info">
          <h3 className="resend-email-modal__title">
            ВЫ НЕ ЗАВЕРШИЛИ РЕГИСТРАЦИЮ!
          </h3>
          <span className="resend-email-modal__text">
            Чтобы завершить регистрацию, перейдите по ссылке из письма,
            отправленного на ваш электронный адрес с темой “Подтверждение
            регистрации на crowd.mos.ru”.
          </span>
        </div>
        <div className="resend-email-modal__buttons">
          <Button
            type="filled"
            isDisabled={blocked()}
            onClick={sendVerify}
            text={
              !blocked()
                ? "Повторно отправить письмо"
                : `Повторная отправка через ${blockedTime}`
            }
          />
          <Button text="Выйти" type="outlined" onClick={closeAndLogout} />
        </div>
      </>
    </AppModal>
  );
};

export default ResendEmailModal;
