import React from "react";

import MapService, { Coordinates } from "../../../services/mapService";
import { arrayShallowEqual } from "../../../utils/index";
import "./Map.scss";
import { connect } from "react-redux";

interface Props {
  popupOptions?: any;
  coords?: any;
  address?: any;
  marker?: any;
  addresses?: any;
  mountingPlaceId?: string;
  readOnly?: boolean;
  zoom?: number;
  className?: string;
  enableFullScreen?: boolean;

  onError?: Function;
  onClick?: Function;
  onShowFullSreen?: Function;
}

class Map extends React.Component<Props, any> {
  map: any;
  address = "";
  points = null;
  coords = null;

  popupContent = null;
  overlay = null;
  currentFeature = null;

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    if (!window.egip) return;

    const { popupOptions, marker, addresses, mountingPlaceId } = this.props;

    if (popupOptions) {
      this.setPopup(popupOptions.popup);
    }

    this.map = MapService.createMap(mountingPlaceId || "map-placeholder", this.props.zoom, this.getOverlay());

    this.bindEvents();

    if (this.props.coords) {
      this.coords = this.props.coords;
      this.zoomAnimate(null, this.props.coords);
    }

    popupOptions && this.setInteraction();
    addresses && MapService.addMultiplePointLayer(this.map, marker, addresses);
  }

  setPopup(popup) {
    const container = document.createElement("div");
    container.innerHTML = popup;
    container.style.display = "none";
    document.body.appendChild(container);
    this.popupContent = document.querySelector(".map-popup__content");
  }

  setInteraction() {
    if (!this.map) return;

    const pointerInteraction = new window.ol.interaction.Select({
      condition: window.ol.events.condition.pointerMove,
      toggleCondition: this.showPopup,
      removeCondition: this.hidePopup,
    });

    const clickInteraction = new window.ol.interaction.Select({
      condition: window.ol.events.condition.click,
      toggleCondition: this.showPopup,
      removeCondition: this.hidePopup,
    });

    this.map.addInteraction(pointerInteraction);
    this.map.addInteraction(clickInteraction);
  }

  getOverlay() {
    const beforeClass = "bottom-center";
    const container = document.querySelector(".map-popup");

    var overlay = new window.ol.Overlay({
      element: container,
      // autoPan: true,
      positioning: beforeClass,
    });

    this.overlay = overlay;
    return overlay;
  }

  componentDidUpdate(nextProps, nextState) {
    if (this.props.address && this.props.address !== this.address) {
      this.address = this.props.address;
      this.zoomAnimate(this.address, null);
    }

    if (this.props.coords && JSON.stringify(this.props.coords) !== JSON.stringify(this.coords)) {
      this.coords = this.props.coords;
      this.zoomAnimate(null, this.coords);
    }
  }

  zoomAnimate(address, coords) {
    const map = this.map;
    if (!map) return;

    let promise;
    if (coords) {
      promise = new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve({ longitude: coords[0], latitude: coords[1] });
        }, 700);
      });
    } else {
      promise = MapService.getCoordinatesByAddress(address);
    }

    promise
      .then((result: Coordinates) => {
        if (result) {
          const { longitude, latitude } = result;
          const egipCoords = MapService.getEgipCoords({ latitude, longitude });
          MapService.clearMap(map);
          this.points = MapService.getPoints();
          const layer = map.addLayer(this.points);
          MapService.addFeature(this.points, egipCoords);
          MapService.zoomAnimate(this.map, egipCoords, this.props.zoom);
        } else {
          throw new Error();
        }
      })
      .catch((err) => {
        MapService.clearMap(map);
        this.onError(err);
      });
  }

  showPopup = (evt) => {
    var coordinate = evt.coordinate;
    const featureOnMap = this.map.getFeaturesAtPixel(evt.pixel);
    if (featureOnMap) {
      this.currentFeature = featureOnMap;
      this.popupContent.innerHTML = `<div>${featureOnMap[0].meta?.text}</div>`;
      this.overlay.setPosition(coordinate);
    }
  };

  hidePopup = (evt) => {
    if (this.currentFeature) {
      this.currentFeature = null;
      this.overlay.setPosition(undefined);
    }
  };

  click = async (event) => {
    if (this.props.readOnly) return;

    if (this.props.onError && typeof this.props.onError === "function") {
      this.props.onError("");
    }

    // MapService.clearMap(this.map);
    const coords = MapService.transformCoords(event.coordinate);
    this.points = MapService.getPoints();
    event.map.addLayer(this.points);

    try {
      if (this.checkFeature(event)) return;

      const hits = await MapService.getAddressesByCoords(coords);
      if (hits.length) {
        let [firstHit, ...restHits] = hits;
        firstHit.address = firstHit.address.replace(/\sк(\d)/, " корпус $1");
        if (this.props.onClick && typeof this.props.onClick === "function") {
          this.props.onClick(firstHit);
        }
      } else {
        this.onError();
      }
    } catch (err) {
      console.error(err);
    }
  };

  onError(err?) {
    if (this.props.onError && typeof this.props.onError === "function") {
      this.props.onError("Адрес не найден, попробуйте указать другой.");
    }
  }

  checkFeature(e) {
    const features = this.map?.getFeaturesAtPixel(e.pixel);
    if (features && features.length) {
      if (this.props.onClick && typeof this.props.onClick === "function") {
        this.props.onClick("");
      }
      return true;
    }
    return false;
  }

  bindEvents() {
    this.map?.on("click", (arg) => this.click(arg));
  }

  getClassList() {
    let classList = "cr-map ";
    this.props.className && (classList += this.props.className);
    return classList;
  }

  render() {
    const { mountingPlaceId } = this.props;

    return (
      <div className={this.getClassList()}>
        <div id={mountingPlaceId || "map-placeholder"} className="map-placeholder" />
      </div>
    );
  }
}

export default Map;
