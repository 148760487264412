import React, { Component } from "react";

import MayorAppealPage from "../../pages/MayorAppeal/MayorAppealPage";

class MayorAppealContainer extends Component {
  render() {
    return (
      <>
        <MayorAppealPage />
      </>
    );
  }
}

export default MayorAppealContainer;
