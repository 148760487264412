export const TOGGLE_FULL_SCREEN_DOCUMENT = "TOGGLE_FULL_SCREEN_DOCUMENT";
export const SET_FULL_SCREEN_ELEMENT_BY_ID = "SET_FULL_SCREEN_ELEMENT_BY_ID";

export const toggleFullScreenDocument = () => ({
  type: TOGGLE_FULL_SCREEN_DOCUMENT,
});

export const setFullScreenElementById = (payload) => ({
  type: SET_FULL_SCREEN_ELEMENT_BY_ID,
  payload: payload,
});
