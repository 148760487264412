import React, { useContext, useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore from "swiper";
import { useMediaQuery } from "react-responsive";

import Button from "../../../../presentational/Controls/Button/Button";
import SliderContentArrow from "../../../NYLanding/NYLandingSlider/Components/SliderContentArrow";
import CongratulationCard from "./CongratulationCard/CongratulationCard";
import {
  phoneWidth,
  tabletWidth,
} from "../../../../../utils/constants/widthConstants";

import "./BirthdayCongrats.scss";
import CommonSlider from "../../../../presentational/Sliders/CommonSlider/CommonSlider";
import SendCongratForm from "./SendCongratForm/SendCongratForm";
import { AppContext } from "../../../../Root";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../types/State.interface";
import {
  AuthAction,
  AuthActionParam,
  AuthActionType,
  ComponentType,
} from "../../../../../services/sudirService";
import BirthdayEventsService from "../../../../../services/birthday-events.service";
import { ApiStatusCode } from "../../../../../types/Common.interface";
import ArrowLeftIcon from "../../../../../assets/birthday9/icons/icon-left.svg"
import { initialCongrats } from "./BirthdayCongrats.helper";
import { getAvatarSrc } from "../../../../../utils";

const BirthdayCongrats = () => {
  const appContext = useContext(AppContext);
  const user = useSelector((state: RootState) => state.user.userDetails);

  const [congrats, setCongrats] = useState([]);

  const isSmallDescktop = useMediaQuery({ query: `(max-width: 1050px)` });
  const isTablet = useMediaQuery({ query: `(max-width: ${tabletWidth}px)` });
  const isPhone = useMediaQuery({ query: `(max-width: ${phoneWidth}px)` });

  const [swiperInstance, setSwiperInstance] = useState<SwiperCore>(null);
  const [isModalOpen, setIsModalOpen] = useState(null);

  useEffect(() => {
    BirthdayEventsService.get()
      .then((res) => {
        if (res.status === ApiStatusCode.OK) {
          setCongrats(congrats.concat(res.data.map(c => {
            return {...c, avatar: (c.attachment?.id && '/uploads/get/' + c.attachment?.id) || (c.originalAttachment?.id && '/uploads/get/' + c.originalAttachment?.id) || getAvatarSrc(c.author)}
          })));
        }
      })
      .catch(console.log);
  }, []);

  // ACTIONS AFTER AUTH
  useEffect(() => {
    if (!user?.loggedIn) return;

    const actions = appContext.sudirService.getActions(
      ComponentType.BIRTHDAY9_CONGRATS
    );
    if (actions.length) {
      const openFormAction = actions.find(
        (act) => act.type === AuthActionType.OPEN_FORM
      );
      if (openFormAction) {
        document.querySelector(".birthday-congratulation").scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
        setTimeout(() => setIsModalOpen(true), 1000)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.loggedIn]);

  const openCongratForm = () => {
    if (!user?.loggedIn) {
      const thisContainerData: AuthActionParam = {
        component: ComponentType.BIRTHDAY9_CONGRATS,
        type: AuthActionType.OPEN_FORM,
        args: {},
      };

      const action = new AuthAction(thisContainerData);
      return appContext.sudirService.authWithActions([action]);
    }

    setIsModalOpen(true);
  };

  const renderCongrats = () => {
    return (
      <>
        <CommonSlider
          onSwiper={setSwiperInstance}
          className="birthday-slider"
          options={{
            loop: true,
            spaceBetween: 0,
            slidesPerView: isPhone ? 1 : isSmallDescktop ? 2 : 3,
            centeredSlides: isPhone,
          }}
        >
          {congrats?.map((congratulation: any, idx) => (
              <CongratulationCard
                key={congratulation.text + idx}
                avatar={congratulation.avatar}
                firstName={congratulation.firstName}
                lastName={congratulation.lastName}
                text={congratulation.text}
              ></CongratulationCard>
          ))}
        </CommonSlider>
        <div className="congratulation-slider__arrows">
          <div className="congratulation-slider__arrow congratulation-slider__arrow-left" onClick={() => swiperInstance.slidePrev()}>
            
          </div>
          <div className="congratulation-slider__arrow congratulation-slider__arrow-right" onClick={() => swiperInstance.slideNext()}>
          </div>
        </div>
      </>
    );
  };

  const renderNoCongrats = () => {
    return (
      <div className="birthday-congratulation__nocongrats">
        Поздравлений пока нет
      </div>
    );
  };

  return (
    <section
      id="congratulation"
      className="birthday-congratulation birthday-block-lessened"
    >
      <div className="birthday-congratulation__inner">
        <div className="birthday-title">Поздравления от наших участников</div>
        <div className="birthday-congratulation__subtitle">
          Мы ценим каждого из вас и были рады получить поздравления в честь дня рождения «Города идей». <br/> Спасибо, что вы с нами!
        </div>
        {/* <Button
          type="filled"
          text="Поздравить «Город идей»"
          onClick={openCongratForm}
        /> */}

        <div className="birthday-congratulation__slider">
          {congrats?.length ? renderCongrats() : renderNoCongrats()}
        </div>
      </div>

      {isModalOpen && (
        <SendCongratForm
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
        />
      )}
    </section>
  );
};

export default BirthdayCongrats;
