import React, { useContext, useEffect } from "react";

import MetaDecorator from "../../presentational/MetaDecorator/MetaDecorator";
import PageTitle from "../../presentational/PageTitle/PageTitle";
import PageDescription from "../../presentational/PageDescription/PageDescription";
import Button from "../../presentational/Controls/Button/Button";
import { setAppColor } from "../../../store/actions/LayoutActions";
import { AppColorsEnum } from "../../App.interface";
import { useDispatch, useSelector } from "react-redux";

import "./AboutPage.scss";
import { AppContext } from "../../Root";
import { AuthAction, AuthActionParam, AuthActionType, ComponentType } from "../../../services/sudirService";

const AboutPage = () => {
  const dispatch = useDispatch();
  const appContext = useContext(AppContext);
  const user = useSelector((state: any) => state.user.userDetails);
  
  useEffect(() => {
    dispatch(setAppColor(AppColorsEnum.WHITE));

    const footer = document.querySelector("footer");
    let invalidateFooter;
    setTimeout(() => {
      invalidateFooter = patchFooter(footer);
    });

    return function () {
      invalidateFooter();
    };
  }, []);

  const patchFooter = (element) => {
    element.classList.add("about__footer");
    return () => element.classList.remove("about__footer");
  };

  const onLogIn = () => {
      const thisContainerData: AuthActionParam = {
        component: ComponentType.ABOUT_PAGE,
        type: AuthActionType.AUTH,
        args: {},
      };

      const action1 = new AuthAction(thisContainerData);
      return appContext.sudirService.authWithActions([action1]);
  }

  const renderAuthButtons = (loggedOutText, loggedInText) =>
    !user?.loggedIn || !user?.emailVerified || user?.partiallyFilled ? (
      <Button text={loggedOutText} type="filled" onClick={() => onLogIn()} />
    ) : (
      <span>
        <small className="ic ui-icon-21 auth__loggedIn-icon" />
        <b>{loggedInText}</b>
      </span>
    );

  const renderRegistration = () => (
    <div className="about__registration about-section">
      <div className="about__registration-wrapper">
        <div className="about__registration-image empty" />
        <div className="about__registration-content">
          <div className="about__subtitle">Зарегистрируйте аккаунт</div>
        </div>
      </div>
      <div className="about__registration-wrapper">
        <div className="about__registration-image">
          <img
            src={
              require("../../../assets/about/Kak_stat_ychastnikom-02.svg")
                .default
            }
            alt="Как стать участником"
          />
        </div>

        <div className="about__registration-content">
          <div className="about__registration-info">
            <p>
              Укажите настоящие фамилию, имя, отчество и ваш номер телефона, на
              который будет направлено СМС-сообщение с кодом подтверждения.
            </p>
            <p>
              <i>
                При регистрации укажите дополнительные данные, необходимые для
                участия в проектах в форме регистрации.
              </i>
            </p>
          </div>
          <div className="about__registration-loggedIn auth__loggedIn">
            {renderAuthButtons("Регистрация", "Вы зарегистрированы")}
          </div>
        </div>
      </div>
    </div>
  );

  const renderDownloadApp = () => (
    <div className="about__download about-section">
      <div className="about__subtitle">Скачайте приложение</div>
      <div className="about__download-info">
        <div className="about__download-text">
          <p>
            <b>
              Установите приложение{" "}
              <span className="nowrap">«Активный гражданин»</span>
            </b>{" "}
            на свой мобильный телефон.
          </p>
          <p>
            <b>
              Перейдите в раздел <span className="nowrap">«Город идей»</span>
            </b>{" "}
            и внесите необходимые для участия данные.
          </p>
        </div>

        <div className="about__download-links">
          <a href="https://apps.apple.com/ru/app/id873648765" target="_blank">
            <img
              alt="Доступно в App Store"
              width="117"
              src={
                require("../../../assets/about/Download_on_the_App_Store_Badge_RU_RGB_blk_100317.svg")
                  .default
              }
            />
          </a>
          {/* <a
            href="https://play.google.com/store/apps/details?id=ru.mos.polls"
            target="_blank"
          >
            <img
              alt="Доступно в Google Play"
              width="135"
              src={
                require("../../../assets/about/ru_badge_web_generic.png")
                  .default
              }
            />
          </a> */}
        </div>
      </div>
    </div>
  );

  const renderAuth = () => (
    <div className="about__auth about-section">
      <div className="about__auth-title">
        Если у вас есть учетная запись на mos.ru, просто авторизируйтесь
      </div>
      <div className="about__auth-link auth__loggedIn">
        {renderAuthButtons("войти через mos.ru", "Вы авторизованы")}
      </div>
    </div>
  );

  const renderInfo = () => (
    <div className="about__info about-section">
      <p>
        Одной учетной записи на mos.ru достаточно, чтобы пользоваться городскими
        сервисами и приложениями — «Активный гражданин», «Наш город»,
        «Электронный дом», «Моя Москва», «Помощник Москвы», «Электронный
        дневник» и другими. Вы также получите быстрый доступ ко всем госуслугам
        Москвы, подтвердив свою учетную запись.
      </p>
    </div>
  );

  const renderPressContacts = () => {
    return (
      <div className="about__press">
        <p>
          Контакты пресс-службы (для обращений СМИ): <br />
          <a href="mailto:press-crowd@newtech.mos.ru">
            press-crowd@newtech.mos.ru
          </a>{" "}
        </p>
      </div>
    );
  };

  const title = "«Город идей» — О нас";
  const description = "«Город идей» — платформа Правительства Москвы, благодаря которой жители предлагают идеи по развитию разных сфер города: экологии, транспорта, благоустройства, здравоохранения, культуры, спорта и др. Отобранные экспертами идеи гарантировано реализуются.";
  return (
    <section className="about">
      <MetaDecorator
        title={title}
        description={description}
        opengraph={{title, description}}
      />
      <PageTitle text={"О НАС"} />
      <PageDescription
        text={`«Город идей» — платформа Правительства Москвы, которая дает возможность жителям предлагать идеи по развитию разных сфер города: экологии, транспорта, благоустройства, здравоохранения, культуры, спорта и др. Отобранные экспертами идеи гарантированно реализуются.
        <br/><br/>Подробнее о возможностях платформы можно узнать в <a href="/video?page=0&vid=93e79c4d-d1b3-43f6-8ac0-51238f8857d4" target="_blank">видеоролике</a>.`}
      />
      <div className="about_page__subtitle">Как стать участником</div>
      {renderRegistration()}
      {renderDownloadApp()}
      {renderAuth()}
      {renderInfo()}
      {renderPressContacts()}
    </section>
  );
};

export default AboutPage;
