import { FC } from "react";
import { SlideObjectTypes, SlideTypes } from "../../Utils/constants";
import Modal from "../Modal/Modal";
import { ModalFactProps } from "./ModalFact.types";
import "./ModalFact.scss";
import slidesObjects, { SlideObject } from "../../Utils/slidesObjects";
import { keys } from "../../../../../utils/object";

// TODO
const logos = {
  [SlideTypes.CROWD]: require("../../../../../assets/newyear2023/slides/crowd/modal-logo.svg").default,
  [SlideTypes.AG]: require("../../../../../assets/newyear2023/slides/ag/modal-logo.svg").default,
  [SlideTypes.ED]: require("../../../../../assets/newyear2023/slides/ed/modal-logo.svg").default,
  [SlideTypes.GZ]: require("../../../../../assets/newyear2023/slides/gz/modal-logo.svg").default,
  [SlideTypes.NG]: require("../../../../../assets/newyear2023/slides/ng/modal-logo.svg").default,
  [SlideTypes.MP]: require("../../../../../assets/newyear2023/slides/mp/modal-logo.svg").default,
};

const factsMap = keys(slidesObjects).reduce((prev, slideType) => {
  return {
    ...prev,
    ...slidesObjects[slideType]
      .filter(
        (item): item is Extract<SlideObject, { type: SlideObjectTypes.FACT }> => item.type === SlideObjectTypes.FACT
      )
      .reduce((prev, item) => {
        return {
          ...prev,
          [item.id]: { ...item, slideType },
        };
      }, {}),
  };
}, {});

const ModalFact: FC<ModalFactProps> = ({ isOpened, onClose, factId, closeOnClickOutSide = false }) =>
  !!factsMap[factId] && (
    <Modal
      className="ny-modal-fact"
      isOpened={isOpened}
      onClose={onClose}
      content={
        <>
          {!!logos[factsMap[factId].slideType] && (
            <img className="ny-modal-fact__logo" src={logos[factsMap[factId].slideType]} alt="" />
          )}
          <span dangerouslySetInnerHTML={{ __html: factsMap[factId].text }} />
        </>
      }
      closeOnClickOutSide={closeOnClickOutSide}
    />
  );

export default ModalFact;
