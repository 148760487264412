export interface Logo {
  vertical: string;
  horizontal: string;
  square: string;
  link: string;
  realizedStub: string;
}

export enum ActionEventEnum {
  REGISTRATION = "REGISTRATION",
  ADD_TO_PROJECT = "ADD_TO_PROJECT",
  ADD_PHOTO = "ADD_PHOTO",
}

export enum ApiStatusCode {
  OK = 0, // "Операция выполнена успешно."
  NOT_IMPLEMENTED = 1, // "Операция не поддерживается."
  PROJECT_ADD_ERROR = 2, // "Ошибка добавления проекта."
  PROJECT_UPDATE_ERROR = 3, // "Ошибка редактирования проекта."
  ACCESS_RIGHTS_ERROR = 4, // "Ошибка прав доступа."
  ASSIGNMENT_EXPIRED = 5, // "Задание устарело."
  COMMENT_NOT_FOUND = 6, // "Комментарий не найден."
  COMMENT_VOTE_NOT_FOUND = 7, // "Голос не найден."
  PROPOSAL_DRAFT_NOT_FOUND = 8, // "Черновик идеи не найден."
  ILLEGAL_ASSIGNMENT_STATUS = 9, // "Недопустимый статус задания."
  ILLEGAL_GENERATION_VIEW_TYPE = 10, // "Недопустимый тип формы генерации."
  ILLEGAL_STAGE_TYPE = 11, // "Недопустимый тип этапа."
  TOO_MUCH_DRAFTS = 12, // "Лимит количества черновиков исчерпан."
  UNKNOWN_ERROR = 13, // "Неизвестная ошибка."
  UPLOAD_ERROR = 14, // "Ошибка загрузки файла."
  ILLEGAL_ASSIGNMENT = 15, // "Некорректное задание."
  PROPOSAL_ITEM_METAID_NOT_FOUND = 16, // "Элемент с таким metaId не был найден в идее."
  ILLEGAL_INPUT = 17, // "Некорректные входные данные."
  ILLEGAL_OPERATION = 18, // "Недопустимая операция."
  PV_CAPSLOCK_ERROR = 19, // "Наличие только заглавных букв."
  PV_TOO_SMALL_TEXT = 20, // "Введено менее 10 символов  = кроме заголовка)."
  PV_TOO_MANY_SPECIAL_CHARACTERS = 21, // "Наличие специальных символов, в объеме более 10% от введенного текста."
  PV_TRANSLIT_ERROR = 22, // "Ввод идеи только латиницей."
  PV_REQUIRED_FIELDS_EMPTY = 23, // "Не все обязательные поля заполнены."
  PV_TOO_LONG_TITLE = 24, // "Длина заголовка знаков не должно превышать 120 символов."
  PV_TOO_LONG_TEXT = 25, // "Количество знаков в поле ввода описания идеи не должно превышать 800 символов."
  PV_TOO_MANY_IMAGES_IN_FIELD = 26, // "Для каждого поля ввода описания идеи доступна вставка не более трех изображений."
  PV_TOO_MANY_ATTACHMENTS = 27, // "Максимальное количество прикрепляемых файлов к идее должно быть не более пяти."
  PV_ILLEGAL_FILE_FORMAT = 28, // "Недопустимый формат вложения."
  PV_TOO_BIG_PROPOSAL = 29, // "Суммарный размер идеи превысил лимит."
  PV_TOO_BIG_ATTACHMENTS = 30, // "Совокупный объем вложений не может превышать 20 мб."
  PV_TOO_BIG_IMAGES = 31, // "Размер изображений превысил лимит."
  PV_INCORRECT_MARKER_SELECTION = 32, // "Ошибка валидации идеи. Участник может выделить не более пяти ключевых слов маркером. Не допускается выделение отдельных символов."
  PV_SIMILAR_PROPOSAL_EXISTS = 33, // "Ошибка валидации идеи. Идея с аналогичным заголовком или описанием уже было подано пользователем."
  ILLEGAL_CONDITION = 34, // "Некорректное состояние приложения."
  ILLEGAL_STAGE_STATUS = 35, // "Некорректный статус этапа."
  NODE_ALREADY_EXISTS = 36, // "Попытка добавить узел, который уже существует."
  NODE_NOT_FOUND = 37, // "Структурный узел не найден."
  STAGE_ALREADY_EXISTS = 38, // "Этап уже существует."
  INVALID_CURRENT_PASSWORD = 39, // "Текущий пароль указан неверно."
  UNEQUAL_PASSWORD_FIELDS = 40, // "Поля ввода и повтора пароля не совпадают."
  UNEQUAL_CONTROL_ANSWER_FIELDS = 41, // "Поля ввода и повтора контрльного ответа не совпадают."
  INSUFFICIENT_ROLE_ERROR = 42, // "У пользователя отсутствует необходимая роль на проекте."
  ILLEGAL_PROPOSAL_STATUS = 43, // "Некорректный статус идеи."
  PROPOSAL_ALREADY_RESERVED = 44, // "Другой эксперт уже работает с идеей."
  OBJECT_IS_NOT_RESERVED_BY_USER = 45, // "Объект не зарезервирован пользователем. Пожалуйста, обновите страницу."
  NO_PROPOSAL_TO_RESERVE = 46, // "Нет идей, которые можно зарезервировать."
  USER_ALREADY_HAS_RESERVATION = 47, // "Пользователь уже зарезервировал другую идею на этапе."
  OBJECT_NOT_FOUND_OR_NOT_AVAILABLE = 48, // "Объект не найден или к нему нет доступа."
  FIRST_POST_CANT_BE_MOVED_UP = 49, // "Пост находится первым в списке и его нельзя поднять выше."
  LAST_POST_CANT_BE_MOVED_DOWN = 50, // "Пост находится последним в списке и его нельзя опустить ниже."
  DISCUSSION_IS_NOT_ACTIVE = 51, // "Дискуссия не активна."
  BLOG_POST_IS_NOT_ACTIVE = 52, // "Пост деактивирован."
  BLOG_IS_NOT_ACTIVE = 53, // "Блог не активен."
  COMMENT_LIMIT_EXCEEDED = 54, // "Количество комментариев превысило лимит."
  NEED_FIRST_RING = 55, // "Данную операцию можно выполнить только после первого звонка на этапе."
  POST_SUMMARY_ALREADY_EXISTS = 56, // "Резюме поста уже существует."
  COMMENT_IS_ALREADY_CHOSEN = 57, // "Данный комментарий был отобран другим экспертом."
  COMMENT_IS_NOT_CHOSEN = 58, // "Комментарий еще не выбран."
  OBJECT_NOT_FOUND = 59, // "Объект не найден."
  ILLEGAL_DISCUSSION_TYPE = 60, // "Недопустимый тип дискуссии."
  DISCUSSION_NOT_FOUND = 61, // "Дискуссия не найдена."
  COMMENT_CHOOSING_LIMIT_IS_EXCEEDED = 62, // "Превышен лимит отобранных комментариев на этапе. Исключите один из ранее отобранных комментариев."
  COMPLAINT_TEXT_IS_TOO_BIG = 63, // "Текст жалобы превышает допустимый размер."
  COMPLAINT_ALREADY_EXISTS = 64, // "Вы уже пожаловались на этот объект."
  UNSUPPORTED_OBJECT_TYPE = 65, // "Тип объекта не поддерживается."
  NODE_TEMPLATE_ALREADY_APPROVED = 66, // "Структура уже утверждена."
  NEED_AT_LEAST_ONE_APPROVED_STRUCTURE = 67, // "Требуется как минимум одна утвержденная структура."
  PROPOSAL_VALIDATION_ERROR = 68, // "Ошибка валидации идеи."
  PROPOSAL_LIMIT_EXCEEDED = 69, // "Превышен лимит количества идей допустимых для подачи на этапе."
  ACTIVITY_ALREADY_STOPPED = 70, // "Активность была остановлена ранее."
  ACTIVITY_IS_ALREADY_ACTIVE = 71, // "Активность нельзя возобновить, потому что она и так активна."
  INCORRECT_ATTACHMENT = 72, // "Attachment с таким id нельзя присоединить к объекту."
  PROPOSAL_IS_NOT_UNIQUE = 73, // "Вы уже ранее подавали идею с аналогичным заголовком или блоком «Описание идеи», пожалуйста, измените введенные данные."
  TAG_ALREADY_EXISTS = 74, // "Такая группа уже существует."
  PROPOSAL_DRAFT_IS_EMPTY = 75, // "Заполните хотя бы одну идею."
  INCORRECT_REJECTION_REASON_ID = 76, // "Некорректный id причины отклонения."
  ALREADY_VOTED = 77, // "Вы уже проголосовали."
  READONLY_MODIFICATION_ATTEMPT = 78, // "Попытка изменить поле read-only объекта."
  ATTACHMENT_TYPE_NOT_SUPPORTED = 79, // "Вы можете прикрепить только файлы форматов jpeg, jpg, png, pdf."
  CANT_ADD_COMMENT = 81, // "Нельзя добавить комментарий."
  USER_ALREADY_BLOCKED = 82, // "Пользователь уже заблокирован."
  AUTHORIZATION_FAILED = 84, // "Не осуществлен вход."
  EMPTY_REQUIRED_FIELDS = 85, // "Не заполнены обязательные поля."
  MAIN_PROPOSAL_WAS_CHANGED = 86, // "Главная идея группы изменена другим экспертом, пожалуйста, сравните идеи заново."
  EMPTY_REQUIRED_VOTING_CRITERIA = 87, // "Для сохранения вашего выбора требуется оценить все обязательные показатели."
  AUTHENTICATION_REQUIRED = 88, // "Требуется аутентификация."
  NO_GENERATION_STAGE_FOR_SELECTION = 89, // "Не найден этап генерации для этапа экспертный отбор."
  EMAIL_ALREADY_EXISTS = 90, // "Пользователь с таким адресом электронной почты уже существует."
  ALREADY_SUBSCRIBED = 91, // "Вы уже подписались на это обсуждение."
  COMMENT_IS_NOT_DELETED = 92, // "Комментарий не является удаленным."
  RECIPIENT_INBOX_NOT_AVAILABLE = 93, // "Отправка сообщения этому пользователю временно недоступна."
  EXPERT_NOT_AVAILABLE = 94, // "Отправка сообщений эксперту временно недоступна."
  FACILITATOR_NOT_AVAILABLE = 95, // "Отправка сообщений фасилитатору временно недоступна."
  SUPPORT_NOT_AVAILABLE = 96, // "Отправка сообщений в техническую поддержку временно недоступна."
  PLATFORM_USER_NOT_FOUND = 97, // "Пользователь не найден."
  USER_IS_NOT_BLOCKED = 98, // "Пользователь не заблокирован."
  ALL_POSTS_MUST_HAVE_SUMMARY = 99, // "Все посты должны иметь опубликованное резюме."
  STAGE_FINISH_MUST_BE_CONFIRMED_BY_CHIEFEXPERT = 100, // "Завершение этапа должно быть подтверждено главным экспертом."
  FINISH_CONDITIONS_NOT_NOT_MET = 101, // "Условия завершения этапа не выполнены."
  PERSONAL_MESSAGE_NOT_FOUND = 102, // "Сообщение не найдено."
  PERSONAL_MESSAGE_RECIPIENT_NOT_FOUND = 103, // "Получатель не найден."
  NO_SELECTION_STAGE_FOR_GENERATION = 104, // "Отсутствует этап отбора для этапа генерации."
  GENERATION_STAGE_ENDS_AFTER_SELECTION = 105, // "Этап экспертного отбора заканчивается до окончания связанного этапа генерации идей."
  RECIPIENT_TRASH_NOT_AVAILABLE = 106, // "Отсутствует каталог для удалённых сообщений."
  CANNOT_SEND_MESSAGE_TO_YOURSELF = 107, // "Нельзя отправить сообщение самому себе."
  ATTACHMENT_READ_ERROR = 108, // "Ошибка чтения медиа файла."
  POST_SUMMARY_ALREADY_APPROVED = 109, // "Резюме поста уже утверждено."
  VOTING_CRITERIA_NOT_FOUND = 110, // "Критерий голосования не найден."
  CANNOT_FORWARD_MESSAGE_TO_YOURSELF = 111, // "Нельзя переслать сообщение самому себе."
  MESSAGE_LIMIT_PER_MINUTE_EXCEEDED = 112, // "Вы слишком часто отправляете сообщения. Отправка будет доступна через 1 минуту."
  COMMENT_LIMIT_PER_MINUTE_EXCEEDED = 113, // "Вы слишком часто создаёте комментарии. Отправка будет доступна через 1 минуту."
  IMAGE_TOO_SMALL = 114, // "Ширина и высота изображения не должны быть менее 160 пикселей."
  STAGE_FINISH_IN_PAST = 115, // "Дата окончания этапа не может быть в прошлом."
  ILLEGAL_PROJECT_VERSION = 116, // "Кто-то успел обновить проект раньше. Синхронизируйте структуру через конструктор."
  USER_CANNOT_BE_UNBLOCKED = 117, // "Глобальную блокировку можно снять только в кабинете администратора."
  POST_CANT_BE_IN_FUTURE = 118, // "Дата и время размещения поста не могут быть в будущем."
  PV_TOO_BIG_ATTACHMENTS_IN_MESSAGE = 119, // "Объем вложения не может превышать 5 мб."
  NO_PROPOSAL_FOR_VOTING = 120, // "Нет идей для голосования."
  TOO_MANY_POST_DRAFTS = 121, // "Нельзя создать более 5 черновиков в блоге."
  TOO_MANY_SESSIONS = 0, // "Для вашей учётной записи превышено максимальное число одновременных сессий."
  CANNOT_CHANGE_POST_VISIBILITY = 122, // "Нельзя скрыть пост."
  CANNOT_SAVE_EMPTY_MESSAGE = 123, // "Для сохранения сообщения необходимо заполнить хотя бы одно поле."
  CANNOT_FIND_MESSAGE_DRAFT = 124, // "Черновик не найден. Возможно, он был перемещён или удалён."
  CANNOT_SAVE_MORE_MESSAGE_DRAFTS = 125, // "Нельзя сохранить более 20 черновиков."
  MAX_COMMENT_ATTACHMENT_SIZE_EXCEEDED = 126, // "Совокупный размер вложений к комментарию не должен превышать 20 мб."
  MAX_COMMENT_ATTACHMENT_COUNT_EXCEEDED = 127, // "К комментарию нельзя прикрепить более 5 вложений."
  UNSUPPORTED_COMMENT_ATTACHMENT_TYPE = 128, // "К комментарию можно прикрепить только файлы форматов jpeg, jpg, png, pdf."
  INVALID_ARGUMENTS = 129, // "Некорректные параметры запроса."
  PROPOSAL_AND_STAGE_HAVE_DIFFERENT_NODES = 130, // "Указанные идея и этап относятся к разным направлениям."
  CANNOT_MOVE_PROPOSAL_TO_THE_SAME_NODE = 131, // "Идея уже находится в указанном направлении."
  CANNOT_MOVE_PROPOSAL_TO_STAGE = 132, // "Этапы генерации идей в исходном и целевом направлении не совпадают."
  CANNOT_MOVE_PROPOSAL_TO_NODE = 133, // "В направлении отсутствует подходящий этап экспертного отбора."
  CANNOT_MOVE_PROCESSED_PROPOSAL = 134, // "Только нерассмотренную идею можно переместить в другое направление."
  CANNOT_MOVE_RESERVED_PROPOSAL = 135, // "В данный момент идею рассматривает эксперт."
  TAGS_BELONG_TO_DIFFERENT_NODES = 136, // "Не все указанные группы принадлежат одному направлению."
  CANNOT_DELETE_CHOSEN_COMMENT = 137, // "Невозможно удалить навсегда отобранный экспертом комментарий."
  CANNOT_DELETE_CHOSEN_CHILD_COMMENT = 138, // "Невозможно удалить навсегда комментарий, у которого есть отобранный экспертом дочерний комментарий."
  DISCUSSION_PRESET_EXISTS = 139, // "Пресет с таким названием уже существует."
  DISCUSSION_PRESET_NOT_FOUND = 140, // "Указанный пресет не найден."
  ILLEGAL_DATA_FORMAT = 141, // "Некорректный формат данных."
  DISCUSSION_PRESET_STAGE_EMPTY = 142, // "Для дискуссий проектных и личных блогов этапы не указываются."
  MAX_PRESET_COUNT = 143, // "Максимальное количество пресетов на одного пользователя может быть не более 20."
  CAPTCHA_VALIDATION_ERROR = 144, // "Ошибка валидации капчи."
  QUESTIONNAIRE_ALREADY_SUBMITTED = 145, // "Анкета уже принята."
  PROPOSAL_LIST_ITEM_NOT_FOUND = 146, // "Значение поля идеи не найдено."
  SSOID_ALREADY_EXISTS = 147, // "Пользователь с таким ssoid уже существует."
  PROPOSAL_AND_POOL_NOT_LINKED = 148, // "Указанная идея не связана с пулом этапа выбирай."
  VOTING_STAGE_NOT_FOUND = 149, // "Для указанной идеи не найден этап голосования."
  QUESTIONNAIRE_GROUP_CONDITION_ERROR = 150, // "Ошибка при проверке условия принадлежности к группе пользователей после заполнения анкеты."
  CANNOT_ADD_WARNING_TO_TEAM_MEMBER = 160, // "Действие нельзя сделать с ЧКП"
  VOTING_STAGE_HAS_NO_WINNER_CANDIDATES = 161, // "Отсутствуют сохранённые результаты для направлений: %s."
  VOTING_RESULTS_PUBLISH_SYNC_DISABLED = 162, // "Одновременная публикация результатов голосования отключена."
  NOT_ALL_VOTING_STAGES_ARE_FINISHED = 163, // "Не завершены голосования по направлениям: %s."
  MDM_FIND_EXTENDED_USER_ERROR = 164, // "Ошибка при получении дополнительной информации по пользователю с ssoid=%s"
  MDM_NOT_FIND_EXTENDED_USER_ERROR = 165, // "Не найдено дополнительной информации по пользователю с ssoid=%s"
  PROPOSAL_FORWARD_EXPERT_IS_NULL = 166, // "При передаче идеи нужно указать эксперта."
  CANNOT_FORWARD_PROPOSAL_TO_MYSELF = 167, // "Невозможно перевести идею на себя."
  EMAIL_ALREADY_EXISTS_AND_BLOCKED = 168, // "Пользователь с таким адресом электронной почты уже существует и заблокирован."
  QUESTIONNAIRE_DUPLICATE_KEYS = 169, // "В анкете обнаружен повторяющийся ответ.")
  QUESTIONNAIRE_DISABLED = 170, // "Анкета отсутствует.")
  CAPTCHA_ERROR = 171, // "Ошибка получения капчи.")
  COOKIE_CAPTCHA_ERROR = 172, // "Ошибка куки капчи.")
  SUCCESS = 200,
  GOROD_IDEA_EXISTS = 1061, //Вы уже ранее подавали идею с аналогичным названием или блоком «Описание идеи», пожалуйста, измените введенные данные."),
  NOT_CORRECT_LOGIN_OR_PASSWORD = 10109, // Введен некорректный логин или пароль.
  USER_NOT_FOUND = 2000, // Пользователь не найден.
  USER_BLOCKED = 2010, // "Юзер заблокирован")
  SIGNED_OUT = 2029, // "Юзер разлогинился"
  GAME_ALREADY_STARTED = 20045,
  GAME_CLOSED = 20046,
  USER_ALREADY_WON = 20047,
}

export type Entries<T> = {
  [K in keyof T]: [K, T[K]];
}[keyof T][];

export type Keys<T> = (keyof T)[];

export type ValueOf<T> = T[keyof T];

export type ArrayItem<T> = T extends (infer R)[] ? R : never;

export interface BaseResponse<T = void> {
  message: string;
  status: number;
  exceptionCode?: string;
  stackTrace?: string[];
  validationErrors?: {
    resource: string;
    field: string;
    code: string;
    description: string;
  }[];
  data: T extends Record<string, any> | Record<string, any>[] | string ? T : never;
}

export interface PaginationResponse<T = void> extends BaseResponse<T> {
  paging: Pagination;
}

export interface Pagination {
  pageNumber: number;
  pageSize: number;
  totalPages: number;
  totalElements: number;
}
