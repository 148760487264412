import React from "react";
import FilterLink from "../../../containers/Navigation/FilterLink";
import "./Logo.scss";

// type Props = {
//   onLogoClick: Object,
// };

const Logo = (props) => {
  const { onLogoClick, ...otherProps } = props;

  const handleClick = () => {
    if (onLogoClick) onLogoClick();
  };

  let className = "logo";

  return <FilterLink className={className} to={props.options?.link || ""} onLinkClick={handleClick} {...otherProps} />;
};

export default Logo;
