import React, { ReactElement, useContext, useEffect, useMemo, useRef, useState } from "react";
import OpenLayersMap from "../../../presentational/OpenLayersMap/OpenLayersMap";
import ResultPopup from "./ResultPopup/ResultPopup";
import EventsTrackWrapperScroll from "../../../containers/EventsTrack/wrappers/EventsTrackWrapperScroll";
import EventsTrackWrapperClick from "../../../containers/EventsTrack/wrappers/EventsTrackWrapperClick";
import MappedIdeaService, { GetByPolygonResponse, MappedIdea } from "../../../../services/mappedIdea.service";
import { ApiStatusCode } from "../../../../types/Common.interface";
import ReportButton from "../IdeasSection/ReportButton/ReportButton";

import "./ResultSection.scss";
import { shuffle } from "../../../../utils";

const ResultSection = () => {
  const [points, setPoints] = useState(null);
  const [allMappedIdeas, setAllMappedIdeas] = useState(null);
  const [currentMappedIdeas, setCurrentMappedIdeas] = useState(null);

  useEffect(() => {
    MappedIdeaService.getByPolygon().then((response: GetByPolygonResponse) => {
      if (response.status === ApiStatusCode.OK) {
        if (Array.isArray(response.data)) {
          setAllMappedIdeas(response.data);
        }
      }
    });
  }, []);

  useEffect(() => {
    if (allMappedIdeas?.length) {
      setPoints(
        allMappedIdeas.map((mappedIdea: MappedIdea) => {
          return {
            id: mappedIdea.id,
            coordinates: [mappedIdea.latitude, mappedIdea.longitude],
          };
        })
      );
    }
  }, [allMappedIdeas]);

  return (
    <EventsTrackWrapperScroll id={9}>
      <div className="result-section">
        <div className="result-section__head main-page__head">
          <div className="result-section__title main-page__title">Результаты</div>
          <div className="result-section__text main-page__description">
            Ознакомиться с результатами работы платформы можно на карте. Здесь отмечены идеи, предложенные жителями и
            реализованные городом.
          </div>
        </div>

        <div className="result-section__map">
          {points && (
            <EventsTrackWrapperClick id={252}>
              <div>
                <OpenLayersMap
                  points={points}
                  clasterization={{}}
                  location={{}}
                  onClick={({ feature }) => {
                    const mappedIdeas = feature
                      .get("features")
                      .map((f) => allMappedIdeas.find((idea) => idea.id === f.get("id")));
                      
                    setCurrentMappedIdeas(
                      shuffle(mappedIdeas).sort((a, b) => b.attachmentIds.length - a.attachmentIds.length)
                    );
                  }}
                  onClickCluster={() => setCurrentMappedIdeas(null)}
                  onOutsideClick={() => setCurrentMappedIdeas(null)}
                />
              </div>
            </EventsTrackWrapperClick>
          )}
          {currentMappedIdeas && <ResultPopup ideas={currentMappedIdeas} onClose={() => setCurrentMappedIdeas(null)} />}

          <ReportButton />
        </div>
      </div>
    </EventsTrackWrapperScroll>
  );
};

export default ResultSection;
