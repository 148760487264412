import React, { Component } from "react";
import { connect } from "react-redux";

import ArchivePage from "../../../components/pages/Archive/ArchivePage";
import FadeIn from "../../presentational/FadeIn/FadeIn";
import FourColumnLoader from "../../presentational/Loaders/FourColumnLoader/FourColumnLoader";
import {
  getArchiveProjects,
  getProjectsStats,
} from "../../../store/actions/ProjectActions";
import { Project, ProjectStats } from "../../../types/Projects.interface";
import { Stats } from "../../../types/Stats.interface";
import { setAppColor } from "../../../store/actions/LayoutActions";
import { AppColorsEnum } from "../../App.interface";
import { isUnited, countWithSuprojects } from "../../../utils";

interface Props {
  stats: Stats;
  archiveProjects: Project[];
  projectsStats: ProjectStats;
  getArchiveProjects: Function;
  getProjectsStats: Function;
}

class ArchiveContainer extends Component<any, any> {
  constructor(props) {
    super(props);

    if (!this.props.archiveProjects) this.props.getArchiveProjects();
    if (!this.props.projectsStats) this.props.getProjectsStats();
  }

  componentDidMount(): void {
    this.props.setAppColor(AppColorsEnum.GRAY);
  }

  isLoaded() {
    return this.props.archiveProjects && this.props.stats;
  }

  getProjects() {
    return (this.props.archiveProjects || [])
      .map((project) => {
        return isUnited(project)
          ? countWithSuprojects(project, this.props.archiveProjects || [])
          : project;
      })
      .filter((p) => !p.parentProjectId);
  }

  render() {
    return (
      <>
        {this.isLoaded() ? (
          <FadeIn>
            <ArchivePage
              projects={this.getProjects()}
              stats={this.props.stats}
            />
          </FadeIn>
        ) : (
          <FourColumnLoader />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    archiveProjects: state.projects.archive,
    stats: state.projects.stats,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getArchiveProjects: () => dispatch(getArchiveProjects()),
  getProjectsStats: () => dispatch(getProjectsStats()),
  setAppColor: (color) => dispatch(setAppColor(color)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ArchiveContainer);
