import { FC } from "react";
import "./ReviewCard.scss";

import { Scrollbar } from "react-scrollbars-custom";
import { Review } from "../Reviews";
import Button from "../../Button/Button";

type ReviewCardProps = Review;

const ReviewCard: FC<ReviewCardProps> = ({ resource, news, link }) => {
  return (
    <div className="review-card">
      <Scrollbar className="review-card__scroll discussion-landing-scroll" noDefaultStyles>
        <div className="review-card__source" dangerouslySetInnerHTML={{ __html: resource }}></div>
        {": "}
        <div className="review-card__message" dangerouslySetInnerHTML={{ __html: news }}></div>
      </Scrollbar>
      <Button linkUrl={link} hrefTarget="blank" classList="review-card__button" text="Читать" type="outlined"></Button>
    </div>
  );
};
export default ReviewCard;
