export enum GameType {
  NEW_YEAR = "NEW_YEAR",
  BIRTHDAY = "BIRTHDAY",
}

export interface GameProps {
  type: GameType;
  service: any;
  questionsAmount: number;
}

export interface Question {
  answers: string[];
  gameId: string;
  question: string;
  questionId: string;
  questionNumber: number;
  questionStarted: string;
  status: GameStatus;
}

export enum GameStatus {
  IN_PROGRESS = "IN_PROGRESS",
  LOST = "LOST",
  WON = "WON",
}

export interface AnswerResult {
  answerStatus: AnswerStatusEnum;
  gameId: string;
  gameStatus: GameStatus;
}

export enum AnswerStatusEnum {
  IN_PROGRESS = "IN_PROGRESS",
  CORRECT = "CORRECT",
  NOT_CORRECT = "NOT_CORRECT",
  EXPIRED = "EXPIRED",
}
