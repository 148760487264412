import React, { useEffect, useState } from "react";
import SupportForm from "../../../../containers/Forms/SupportForm/SupportForm";
import { BirthdayUrls } from "../../CrowdBirthday9.helper";

import "./BirthdayFooter.scss";

const BirthdayFooter = () => {
  const [helpModalOpen, setHelpModalOpen] = useState(false);

  return (
    <footer className="birthday-footer">
      <div className="birthday-footer__inner">
        <div className="birthday-footer__left">
          <span
            className="birthday-footer__help"
            onClick={(e) => setHelpModalOpen(true)}
          >
            Помощь
          </span>
          <span className="birthday-footer__rules">
            <a href={BirthdayUrls.GAME_RULES} target="_blank" rel="noreferrer">
              Правила участия
            </a>
          </span>
        </div>
        <span className="birthday-footer__right">
          © Краудсорсинг-проекты Правительства Москвы, 2023
        </span>
      </div>
      {helpModalOpen && (
        <SupportForm
          isOpened={helpModalOpen}
          close={() => setHelpModalOpen(false)}
        />
      )}
    </footer>
  );
};

export default BirthdayFooter;
