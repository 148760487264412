import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import MetaDecorator from "../../presentational/MetaDecorator/MetaDecorator";
import { hideElement, declination } from "../../../utils";
import { fullScreenPage, setAppColor } from "../../../store/actions/LayoutActions";
import { AppColorsEnum } from "../../App.interface";
import "./BlockedPage.scss";
import { RootState } from "../../../types/State.interface";

const BlockedPage = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const routes = useSelector((state: RootState) => state.layout.routes);

  const [daysLeft, setDaysLeft] = useState(null);
  const [hoursLeft, setHoursLeft] = useState(null);
  const [minutesLeft, setMinutesleft] = useState(null);
  const [secondsLeft, setSecondsLeft] = useState(null);

  useEffect(() => {
    dispatch(setAppColor(AppColorsEnum.WHITE));

    const cleanUpHeader = hideElement(".cr-header-link", true);
    const cleanUpFooter = hideElement("footer");
    const cleanUpChat = hideElement(".webchat-icon");

    const blockExpiration = props.location?.state?.blockExpiration || localStorage.getItem('block_expiration');
    let interval;
    if (blockExpiration && new Date(blockExpiration).getTime() > new Date().getTime()) {
      localStorage.setItem('block_expiration', blockExpiration)
      interval = setInterval(setTime, 1000, blockExpiration)
    }

    return () => {
      cleanUpHeader();
      cleanUpFooter();
      cleanUpChat();
      clearInterval(interval);
    };
  }, [routes]);

  useEffect(() => {
    if (blockTimeIsUp()) {
      localStorage.removeItem('block_expiration');
      history.push('/teamLogin');
    }

  }, [daysLeft, hoursLeft, minutesLeft, secondsLeft])

  const setTime = (timeLeft) => {
    const _second = 1000;
    const _minute = 1000 * 60;
    const _hour = _minute * 60;
    const _day = _hour * 24;
    const now = new Date();
    const distance = new Date(timeLeft).getTime() - now.getTime();

    setDaysLeft(Math.floor(distance / _day));
    setHoursLeft(Math.floor((distance % _day) / _hour));
    setMinutesleft(Math.floor((distance % _hour) / _minute));
    setSecondsLeft(Math.floor((distance % _minute) / _second));
  }

  const blockTimeIsOn = () => Boolean(daysLeft) || Boolean(hoursLeft) || Boolean(minutesLeft) || Boolean(secondsLeft);

  const blockTimeIsUp = () => daysLeft === 0 && hoursLeft === 0 && minutesLeft === 0 && secondsLeft === 0;

  return (
    <div className="blocked-page">
      <MetaDecorator title="Платформа Правительства Москвы «Город идей»" />

      <div className="blocked-page__content">
        <div className="blocked-page__image"></div>

        <div className="blocked-page__text">Учётная запись</div>
        <div className="blocked-page__text blocked-page__text--error">
          Заблокирована
        </div>
        {blockTimeIsOn() && (
          <>
            <div className="blocked-page__text blocked-page__text">
              До разблокировки осталось:
            </div>
            <div className="blocked-page__text blocked-page__text--countdown">
              <span className="blocked-page__text--countdown__digits">
                {daysLeft}
              </span>
              <span> {declination(daysLeft, [" день", " дня", " дней"])}</span>
              <span className="blocked-page__text--countdown__digits">
                {hoursLeft}
              </span>
              <span>
                {" "}
                {declination(hoursLeft, [" час", " часа", " часов"])}
              </span>
              <span className="blocked-page__text--countdown__digits">
                {minutesLeft}
              </span>
              <span>
                {" "}
                {declination(minutesLeft, [" минута", " минуты", " минут"])}
              </span>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default BlockedPage;
