import React, { useContext, useEffect, useState } from "react";
import { useOuterClick } from "../../../../../hooks/useOnOutsideClick";
import EventsTrackWrapperClick from "../../../EventsTrack/wrappers/EventsTrackWrapperClick";
import Logo from "../../Logo/Logo";
import { MosProjects } from "../CrossPromo.helper";
import "./CrossPromoPopup.scss";
import { useDispatch, useSelector } from "react-redux";
import { showQrCodeModal } from "../../../../../store/actions/LayoutActions";
import { RootState } from "../../../../../types/State.interface";
import { AppContext } from "../../../../Root";
import { AuthAction, AuthActionType, ComponentType } from "../../../../../services/sudirService";

export interface CrossPromoPopupProps {
  onClose: () => void;
}

const CrossPromoPopup = ({ onClose }: CrossPromoPopupProps) => {
  const dispatch = useDispatch();
  const userDetails = useSelector((state: RootState) => state.user.userDetails);
  const appContext = useContext(AppContext);

  const popupRef = useOuterClick<HTMLInputElement>((event: any) => {
    if (event.target?.className?.indexOf?.("cp-popup") === -1) {
      onClose();
    }
  });

  const openQrCodeModal = () => {
    if (userDetails.loggedIn && !userDetails.blocked) {
      dispatch(showQrCodeModal());
    } else {
      appContext.sudirService.authWithActions([
        new AuthAction({
          component: ComponentType.CROSS_PROMO_POPUP,
          type: AuthActionType.OPEN_QR_CODE_MODAL,
        }),
      ]);
    }
    onClose();
  };

  return (
    <div className="cp-popup" ref={popupRef}>
      <div className="cp-popup__header">
        <Logo onLogoClick={onClose} />
        <button className="modal__close-btn" onClick={onClose}>
          <span className="ic ui-icon-14"></span>
        </button>
      </div>
      <div className="cp-popup__title">Электронные городские проекты:</div>
      <div className="cp-popup__projects">
        <button onClick={openQrCodeModal} className="cp-popup__project">
          <img
            className="cp-popup__project__img cp-popup__project__img--qrcode"
            src={`${require("../../../../../assets/cross-promo/logos/qrcode.svg").default}`}
          />
          <div className="cp-popup__project-name">Персональный код</div>
        </button>

        {MosProjects.map((project) => (
          <EventsTrackWrapperClick key={project.id} id={["HEADER", 601]} replace={[project.name]}>
            <a
              href={`${project.link}?utm_source=crowd&utm_medium=cpwidget${project.anchor ? project.anchor : ""}`}
              target="_blank"
              className="cp-popup__project"
            >
              <img src={project.logo} />
              <div className="cp-popup__project-name">{project.name}</div>
            </a>
          </EventsTrackWrapperClick>
        ))}
      </div>
    </div>
  );
};

export default CrossPromoPopup;
