import { FC, useState } from "react";
import { useMediaQuery } from "react-responsive";
import "./NewsAndPublications.scss";

import CommonSlider from "../../../../../presentational/Sliders/CommonSlider/CommonSlider";
import { phoneWidth, tabletWidth } from "../../../../../../utils/constants/widthConstants";
import SwiperCore from "swiper";
import NewsCard from "./NewsCard/NewsCard";
import { News, NewsList } from "../../MediaPlatform/MediaPlatform";
import NewsAndPublicationsModal from "../NewsAndPublicationsModal/NewsAndPublicationsModal";

type NewsAndPublicationsProps = {
  newsList: NewsList;
};

const NewsAndPublications: FC<NewsAndPublicationsProps> = ({ newsList }) => {
  const isPhone = useMediaQuery({ query: `(max-width: ${phoneWidth}px)` });
  const isTablet = useMediaQuery({ query: `(max-width: ${tabletWidth}px) and (min-width: ${phoneWidth}px)` });
  const [swiperInstance, setSwiperInstance] = useState<SwiperCore>(null);
  const [selectedNews, setSelectedNews] = useState(null);

  const handleSelectNew = (news: News) => {
    setSelectedNews(news);
  };
  const handleOnCloseModal = () => {
    setSelectedNews(null);
  };

  const handleOnInit = () => {};
  const handleOnSlideChange = () => {};
  return (
    <div className="news-and-publications">
      <div className="discussion-landing-subtitle">Новости и публикации</div>

      <CommonSlider
        onInit={handleOnInit}
        onSwiper={setSwiperInstance}
        onSlideChange={handleOnSlideChange}
        className="notifications__swiper"
        effect="fade"
        options={{
          loop: false,
          spaceBetween: 24,
          slidesPerView: isTablet ? 2 : isPhone ? 1 : 3,
          slidesPerColumn: 1,
          slidesPerGroup: 1,
        }}
      >
        {newsList.map((news) => (
          <NewsCard key={news.id} news={news} onSelectNews={handleSelectNew} />
        ))}
      </CommonSlider>
      {selectedNews && <NewsAndPublicationsModal news={selectedNews} onClose={handleOnCloseModal} />}
    </div>
  );
};

export default NewsAndPublications;
