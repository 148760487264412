import { SittingKey } from "../../types/Sittings.interface";
import SittingsService from "../../services/sittingsService";

export const GET_SETTING_KEY_SUCCESS = "GET_SETTING_KEY_SUCCESS";

export const getSittingByKey = (key: SittingKey) => (dispatch) => {
  return SittingsService.getSittingByKey(key)
    .then(({ data }) => {
      dispatch({
        type: GET_SETTING_KEY_SUCCESS,
        payload: {key: key, value: data},
      });
    })
    .catch((err) => {
      console.log(err);
    });
};
