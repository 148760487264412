export enum AppColorsEnum {
  GRAY = "gray",
  LIGHTBLUE = "lightblue",
  DARKBLUE = "darkblue",
  WHITE = "white",
  LIGHTGRAY = "lightgray",
}

export enum FooterTextColorsEnum {
  WHITE = "white",
  GRAY = "gray",
}
