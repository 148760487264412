import { FC, useState } from "react";
import { Link } from "react-router-dom";
import useUpdateEffect from "../../../../../hooks/useUpdateEffect";
import { renderChildrenByKey } from "../../../../../utils";
import SupportForm from "../../../../containers/Forms/SupportForm/SupportForm";
import "./Footer.scss";
import { FooterProps } from "./Footer.types";

const getClassesBlock = (pos: string) => `leisure-landing-footer__block leisure-landing-footer__block-${pos}`;

const Footer: FC<FooterProps> = ({ children, onSupportModalOpen, onSupportModalHide }) => {
  const [helpModalOpen, setHelpModalOpen] = useState(false);

  useUpdateEffect(() => {
    helpModalOpen ? onSupportModalOpen?.() : onSupportModalHide?.();
  }, [helpModalOpen]);

  return (
    <>
      {helpModalOpen && <SupportForm isOpened close={() => setHelpModalOpen(false)} />}
      <footer className="leisure-landing-footer">
        <div className={getClassesBlock("left")}>
          <Link to="/" target="_blank" className="leisure-landing-footer__external-link">
            Перейти на crowd.mos.ru
          </Link>

          <Link to="#" onClick={() => setHelpModalOpen(true)} className="leisure-landing-footer__help-link">
            Помощь
          </Link>

          <Link to="/Правила_участия_в_квесте.pdf" target="_blank">
            Правила участия в квесте
          </Link>
        </div>

        <div className={getClassesBlock("center")}>{renderChildrenByKey(children, "FooterProgress")}</div>

        <div className={getClassesBlock("right")}>© Краудсорсинг-проекты Правительства Москвы, 2023</div>
      </footer>
    </>
  );
};

export default Footer;
