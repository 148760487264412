// @flow

import React, { useState } from "react";
import { AppModal } from "../../Modal/AppModal";
import { useDispatch } from "react-redux";

import Button from "../../../presentational/Controls/Button/Button";
import UserService from "../../../../services/userService";
import { SUCCESS } from "../../../../types/statusCodes";
import "./AvatarModal.scss";
import {
  hideInfoModal,
  showInfoModal,
} from "../../../../store/actions/LayoutActions";

interface Props {
  onClose: Function;
  onOpenCropAvatarModal?: Function;
  onSetDefaultAvatar?: Function;
  upload?: Boolean;
  gender: string;
  onSuccess: () => void;
  isOpened?: Boolean;
  pictureId?: string;
  onSelectFile?: Function;
  user?: Object;
}

const allowedExt = ["image/jpeg", "image/png"];

const AvatarModal = (props: any) => {
  const dispatch = useDispatch();

  const [error, setError] = useState("");
  const [pictureId, setPictureId] = useState(props.pictureId);
  const [submitting, setSubmitting] = useState(false);

  const submit = (e) => {
    if (pictureId === props.pictureId) {
      props.onClose();
      return;
    } else if (!props.upload) {
      props.onSuccess(pictureId);
      props.onClose();
      return;
    }

    setSubmitting(true);
    UserService.uploadDefaultPicture(pictureId)
      .then((res) => {
        if (res.status === SUCCESS) {
          props.onSuccess(pictureId);
          props.onClose();
        } else {
          setError(res.message);
        }
      })
      .catch((err) => {
        setError(err.message);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const getImage = (gender, i) => {
    try {
      return require(`../../../../assets/user/${gender}_${i}.png`).default;
    } catch (err) {
      console.log(err);
    }
  };

  const changeAvatar = (e) => {
    const parent = e.target.closest(".modal-avatar__item ");
    const previous = document.querySelector(".modal-avatar__item.selected");
    if (previous) {
      previous.classList.remove("selected");
    }
    parent.classList.add("selected");
    setPictureId(e.target.dataset.pictureId);
  };

  const okButton = () => {
    return (
      <Button
        text="Хорошо"
        type="outlined"
        onClick={() => dispatch(hideInfoModal())}
      />
    );
  };

  const passFormat = (file) => {
    return allowedExt.includes(file.type);
  };

  const onSelectFile = (e) => {
    if (!passFormat(e.target.files[0])) {
      return dispatch(
        showInfoModal(
          "Вы можете загрузить только файлы форматов jpeg, png.",
          okButton()
        )
      );
    }

    if (e.target.files && e.target.files.length > 0) {
      props.onSelectFile && props.onSelectFile(e.target.files[0]);

      // reset is needed for onChange trigger to fire if the same file was reuploaded
      e.target.value = null;
    }
  };

  const getClassesForModal = () => {
    let classes = "modal-avatar";

    if (submitting) {
      classes += " modal-avatar-disabled";
    }

    return classes;
  };

  // RENDER
  const getAvatarList = () => {
    const count = 9;
    const avatarsList: any[] = [];
    for (let i = 1; i <= count; i++) {
      avatarsList.push(
        <div
          key={i}
          className={`modal-avatar__item ${
            pictureId === `${props.gender.toLowerCase()}_${i}` ? "selected" : ""
          }`}
        >
          <img
            onClick={changeAvatar}
            data-picture-id={i}
            src={getImage(props.gender.toLowerCase(), i)}
            alt="logo images"
            className="modal-avatar__avatar"
          />
        </div>
      );
    }
    avatarsList.push(
      <div
        key="load avatar"
        className="modal-avatar__item modal-avatar__item_load"
      >
        <label className="modal-avatar__label">
          <input type="file" onChange={onSelectFile} />
          <span />
        </label>
      </div>
    );

    return avatarsList;
  };

  return (
    <AppModal
      center
      classNames={{
        modal: "edit-avatar-modal",
        overlay: "edit-avatar-modal-overlay",
      }}
      overlayId="modal-overlay"
      closeIconId="close-modal-icon"
      blockScroll={false}
      closeIconSize={25}
      open={props.isOpened}
      closeOnEsc={false}
      closeOnOverlayClick={false}
      onClose={props.onClose}
    >
      <div className={getClassesForModal()}>
        <h3 className="modal-avatar__title">Выбор аватара</h3>

        <div className="modal-avatar__container">{getAvatarList()}</div>

        <div className="modal-avatar__buttons">
          <Button
            type="outlined"
            text="Отменить"
            size="mid"
            onClick={props.onClose}
          />
          <Button
            type="filled"
            text="Сохранить"
            size="mid"
            isLoading={submitting}
            onClick={submit}
          />
        </div>
      </div>
    </AppModal>
  );
};

export default AvatarModal;
