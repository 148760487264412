import React, { useMemo } from "react";

import MetaDecorator from "../../presentational/MetaDecorator/MetaDecorator";
import ArchiveProjectDescription from "../../containers/ArchiveProjectDescription/ArchiveProjectDescription";
import ProjectFlipcard from "../../presentational/Cards/ProjectFlipcard/ProjectFlipcard";
import Button from "../../presentational/Controls/Button/Button";
import ArrowBack from "../../presentational/Controls/ArrowBack/ArrowBack";
import Image from "../../presentational/Image/Image";
import { Project } from "../../../types/Projects.interface";
import "../../pages/GenericProject/GenericProjectPage.scss";
import "./ArchiveProjectPage.scss";
import { findUrlParam } from "../../../utils";
import { useMediaQuery } from "react-responsive";
import { tabletWidth } from "../../../utils/constants/widthConstants";
import { GroupDirection } from "../../../types/GroupDirection.interface";

interface Props {
  project: Project;
  pageType: "main" | "plan" | "ideas" | "projectIndexForAG";
  pageTitle: string;
  projectSubTitle: string;
  projectDescription: string;
  pageMetaDescription: string;
  subProjects?: Project[];
  groupDirections?: GroupDirection[];
  defaultLogo: any;
  logo: any;
  getBackHref: string;
  plannedRealizedIdeasData: any;
}

const ArchiveProjectPage = (props: Props) => {
  const isWebView = findUrlParam("contentOnly", window.location.search);
  const isTablet = useMediaQuery({
    query: `(max-width: ${tabletWidth}px)`,
  });

  return (
    <div className={`project archive-page archive-page--${props.pageType}"`}>
      <MetaDecorator title="Платформа Правительства Москвы «Город идей»" description={props.pageMetaDescription} />

      <div className="project__nav-container">
        {!isWebView && <ArrowBack text="Все выполненные проекты" linkUrl="/archive" />}
      </div>

      <div className="project__header">
        <div className="project__wrapper">
          <div className="project__info">
            <div className="project__title" dangerouslySetInnerHTML={{ __html: props.pageTitle }}></div>

            <div className="project__logo project__logo--mobile">
              <Image
                src={props.logo.horizontalSrc}
                alt="Логотип проекта"
                defaultSrc={props.defaultLogo.horizontalSrc}
              />
            </div>

            {(props.pageType === "plan" || props.pageType === "ideas" || props.pageType === "projectIndexForAG") && (
              <div className="project__sub-title">{props.projectSubTitle}</div>
            )}

            <div className="project__page-description">
              {/* /archive */}
              {(props.pageType === "main" || props.pageType === "projectIndexForAG") && (
                <div className="user-text-style" dangerouslySetInnerHTML={{ __html: props.projectDescription }} />
              )}

              {/* /plan */}
              {/* includes '${project.id}/plan.jsx' or '${project.id}/ideas.jsx' */}
              {(props.pageType === "plan" || props.pageType === "ideas") && (
                <ArchiveProjectDescription
                  projectId={props.project.id}
                  pageType={props.pageType}
                  plannedRealizedIdeasData={props.plannedRealizedIdeasData}
                />
              )}
            </div>

            {/* {(props.pageType === "main" ||
              props.pageType === "projectIndexForAG") && (
              <div className="project__stats">
                <div className="project__stats-participant">
                  <div className="ic ui-icon-89"></div>
                  <div className="count">{props.project.participantCount}</div>
                </div>
                <div className="project__stats-proposal">
                  <div className="ic ui-icon-90"></div>
                  <div className="count">
                    {props.project.isMain
                      ? props.project.totalProposalCount
                      : props.project.proposalCount}
                  </div>
                </div>
              </div>
            )} */}
          </div>

          <div className="project__logo">
            <Image
              src={isTablet ? props.logo.verticalSrc : props.logo.horizontalSrc}
              alt="Логотип проекта"
              defaultSrc={isTablet ? props.defaultLogo.verticalSrc : props.defaultLogo.horizontalSrc}
            />
          </div>
        </div>
      </div>

      {/* /ideas only - user ideas carousel - needs fetching and flipster */}

      <div className="project__group-wrapper">
        {props.pageType !== "main" && !props.project.hasLanding && (
          <div className="project__buttons">
            {!(props.pageType === "plan") && (
              <Button type="filled" text="принятые идеи" linkUrl={`/plan?id=${props.project.id}`} />
            )}

            {!(props.pageType === "ideas") && (
              <Button type="filled" text="реализованные идеи" linkUrl={`/ideas?id=${props.project.id}`} />
            )}

            <Button
              type="filled"
              text="подробнее о проекте"
              externalLink={true}
              linkUrl={`projects/${props.project.parentProjectId || props.project.id}/book`}
            />
          </div>
        )}

        {/* {props.pageType === "main" && (
          <div className="project__buttons">
            <Button
              type="filled"
              text="подробнее о проекте"
              externalLink={true}
              linkUrl={`projects/${props.project.id}/book`}
            />
          </div>
        )} */}

        {props.pageType === "main" && !!props.subProjects.length && (
          <div className="project__sub-projects">
            {props.subProjects.map((project, idx) => (
              <ProjectFlipcard
                type="subproject"
                isFlippable={false}
                project={project}
                defaultLogo={props.defaultLogo.verticalSrc}
                key={idx}
              ></ProjectFlipcard>
            ))}
          </div>
        )}

        {props.project.hasLanding && !!props.groupDirections?.length && (
          <div className="project__sub-projects project__sub-projects_landing">
            {props.groupDirections.map((groupDirection) => (
              <ProjectFlipcard
                type="subproject"
                isFlippable={false}
                project={props.project}
                groupDirection={groupDirection}
                defaultLogo={props.defaultLogo.verticalSrc}
                key={groupDirection.id}
              ></ProjectFlipcard>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default ArchiveProjectPage;
