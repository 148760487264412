import React, { useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import ReactTooltip from "react-tooltip";

import { TooltipProps } from "./Tooltip.interface";
import "./Tooltip.scss";

const Tooltip = (props: TooltipProps) => {
  const rootElement = document.querySelector(
    props.rootSelector ? props.rootSelector : ".app"
  );
  const tooltipRef = useRef({} as any);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [props.text]);

  useEffect(() => {
    return () => {
      tooltipRef.current && tooltipRef.current.remove();
    }
  }, []);

  const afterShow = (event) => {
    if (props.isOuter) {
      const { left, top } = tooltipRef.current.getBoundingClientRect();

      rootElement?.appendChild(tooltipRef.current);
      if (!props.overridePosition) {
        tooltipRef.current.style.left = left + "px";
        tooltipRef.current.style.top = top + "px";
      }
    }
  };

  const setRef = (tooltop) => {
    if (tooltop) {
      tooltipRef.current = tooltop.tooltipRef;
    }
  };

  return (
    <div className={`cr-tooltip ${props.className || ""}`} key={props.idx}>
      <div data-tip={props.idx} data-for={props.idx}>
        {props.children}
      </div>
      <ReactTooltip
        ref={setRef}
        afterShow={afterShow}
        id={props.idx}
        place={props.place || "top"}
        type={props.type || "info"}
        effect={props.effect || "solid"}
        className={props.outerClassName || ""}
        overridePosition={props.overridePosition}
        clickable={props.clickable}
        globalEventOff={props.globalEventOff}
      >
        <div dangerouslySetInnerHTML={{ __html: props.text }}></div>
      </ReactTooltip>
    </div>
  );
};

export default Tooltip;
