export const randomizeDirections = (array, s) => {
  let con = array.slice(0, s);
  let ran = array.slice(s, array.length);

  for (let i = ran.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [ran[i], ran[j]] = [ran[j], ran[i]];
  }
  return con.concat(ran);
};
