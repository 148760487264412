import React, { Suspense, useEffect, useLayoutEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { Route, Switch } from "react-router-dom";
import LeisureLanding from "./pages/LeisureLanding/LeisureLanding";
import TestPage from "./pages/TestPage/TestPage";
import "./LeisureLandingRoot.scss";
import AuthForm from "../../containers/Forms/AuthForm/AuthForm";

const LeisureLandingRoot = () => {
  return (
    <>
      <AuthForm />
      <div className="leisure-landing-root">
        <Suspense fallback={<div />}>
          <Switch>
            <Route path="/city" exact={true} component={LeisureLanding} key={"LeisureLanding"} />
            {/* <Route path="/cityTest" exact={true} component={TestPage} key={"TestPage"} /> */}
          </Switch>
        </Suspense>
      </div>
    </>
  );
};

export default LeisureLandingRoot;
