import React, { Suspense, useEffect, useLayoutEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CityCounter } from "./CircleCounterList.data";
import CircleCounter from "./CircleCounter/CircleCounter";
import "./CircleCounterList.scss";
import { RootState } from "../../../../../types/State.interface";
import {counterData as data} from "./CircleCounterList.data";

interface CircleCounterListProps {
  items: CityCounter[];
}

const CircleCounterList = () => {
  const totalStatistics = useSelector((state: RootState) => state.totalStatistics.stats);
  const [counterData, setCounterData] = useState(null);

  useEffect(() => {
    if (totalStatistics) {
      const newCounterData = data.map((data) => {
        return {
          ...data,
          count: totalStatistics[data.id]
        }
      })
      setCounterData(newCounterData)
    }
  }, [totalStatistics])
  

  return (
    <div className="circle-counter-list">
      {counterData?.map((item) => (
        <CircleCounter data={item} key={item.title} />
      ))}
    </div>
  );
};

export default CircleCounterList;
