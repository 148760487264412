import { BirthdaySections, sections } from "../../CrowdBirthday9.helper";

export const descktopLinks = sections.filter(s => s.id !== BirthdaySections.GALLERY)

export const mobileLinks = descktopLinks.filter(link => link.id !== BirthdaySections.MAIN);

export const birthday9HeaderDesktopHeight = 90;
export const birthday9HeaderTabletHeight = 80;

export const scrollToBlock = (id, isTablet) => {
    const element = document.querySelector(`#${id.toLowerCase()}`);
    if (element) {
        const { top } = element.getBoundingClientRect();
        window.scroll(0, top + window.scrollY - (isTablet ? birthday9HeaderTabletHeight : birthday9HeaderDesktopHeight) - 80);
    }
};