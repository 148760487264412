// @flow
import {
  TAGLINE_REQUEST,
  TAGLINE_SUCCESS,
  TAGLINE_FAIL,
} from "../actions/TaglineActions";

export interface Tagline {
  disabled: boolean;
  id: string;
  text: string;
}

export interface TaglineState {
  tagline: Tagline | null;
  error: string;
  isFetching: boolean;
}

const initialState = {
  tagline: null,
  error: "",
  isFetching: false,
};

export function taglineReducer(state: TaglineState = initialState, action) {
  switch (action.type) {
    case TAGLINE_REQUEST:
      return { ...state, isFetching: true, error: "" };

    case TAGLINE_SUCCESS:
      return { ...state, isFetching: false, tagline: action.payload };

    case TAGLINE_FAIL:
      return { ...state, isFetching: false, error: action.payload.message };

    default:
      return state;
  }
}
