import { ProposalTypeEnum } from "../../../../../../types/Proposal.interface";
import "./RejectReason.scss";
import { FC } from "react";

interface RejectReasonProps {
  reason: {
    text: string;
    comment: string;
  };
  hint?: string;
  type?: ProposalTypeEnum.REJECTED | ProposalTypeEnum.REMOVED;
}

const RejectReason: FC<RejectReasonProps> = ({ reason, hint, type }) => {
  const getTitle = (): string => {
    switch (type) {
      case ProposalTypeEnum.REJECTED:
        return "Причина отклонения";
      case ProposalTypeEnum.REMOVED:
        return "Причина удаления";
      default:
        return "Причина отклонения";
    }
  };

  return (
    <div className="reject-reason">
      {hint && <div className="reject-reason__hint">{hint}</div>}
      <div className="reject-reason__title">{getTitle()}</div>
      <div className="reject-reason__text break-word">{reason?.text}</div>
      {reason?.comment && (
        <>
          <div className="reject-reason__title">Комментарий</div>
          <div className="reject-reason__text break-word">{reason?.comment}</div>
        </>
      )}
    </div>
  );
};

export default RejectReason;
