import { FC, useEffect, useMemo, useState } from "react";
import Loader from "../../Loaders/Loader/Loader";
import { useMediaQuery } from "react-responsive";

import { phoneWidth, tabletWidth } from "../../../../utils/constants/widthConstants";

import "./OpenLayersMapLoader.scss";

const OpenLayersMapLoader: FC = () => {
  const isPhone = useMediaQuery({ query: `(max-width: ${phoneWidth}px)` });
  const isTablet = useMediaQuery({ query: `(max-width: ${tabletWidth}px)` });

  const background = useMemo(() => {
    const image = isPhone
      ? require("../../../../assets/map/loader-phone.png").default
      : isTablet
      ? require("../../../../assets/map/loader-tab.png").default
      : require("../../../../assets/map/loader-desk.png").default;
    return <img className="olm-loader__image" src={image} />;
  }, [isPhone, isTablet]);

  return (
    <div className="olm-loader">
      {background}
      <Loader />
    </div>
  );
};

export default OpenLayersMapLoader;
