import React from "react";

import "./Slide.scss";

interface Props {
  children: any;
  data: any;
}

export default class Slide extends React.Component<Props, any> {
  render() {
    return <React.Fragment>{this.props.children}</React.Fragment>;
  }
}
