import { ButtonHTMLAttributes, FC } from "react";
import "./CloseButton.scss";

const CloseButton: FC<ButtonHTMLAttributes<HTMLButtonElement>> = ({ className = "", ...otherProps }) => (
    <button
        className={`modal__close-btn ${className}`}
        { ...otherProps }
    >
        <span className="ic ui-icon-14"></span>
    </button>
);

export default CloseButton;