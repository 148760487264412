import { EventsSliderProps } from "../../components/EventsSlider";

const leisureLandingEventsSlides: EventsSliderProps["slides"] = [
  // {
  //   src: require("../../../../../assets/leisure-landing/slides/Эколого-просветительские мероприятия.jpeg").default,
  //   title: "События в городе",
  //   text: "Эколого-просветительские мероприятия",
  //   link: "https://mospriroda.ru/what_to_do/",
  // },
  {
    src: require("../../../../../assets/leisure-landing/slides/Голосования за развитие Москвы.jpg").default,
    title: "События в городе",
    text: "Голосования за развитие Москвы",
    link: "https://ag.mos.ru/poll?filters=all",
  },
  // {
  //   src: require("../../../../../assets/leisure-landing/slides/Экскурсии для горожан.jpg").default,
  //   title: "События в городе",
  //   text: "Экскурсии для горожан",
  //   link: "https://turniketovnet.ru/factories/",
  // },
  // {
  //   src: require("../../../../../assets/leisure-landing/slides/День открытых дверей в культурных учреждениях.jpg")
  //     .default,
  //   title: "События в городе",
  //   text: "День открытых дверей<br />в культурных учреждениях",
  //   link: "https://mos-razvitie.ru/open_door",
  // },
  {
    src: require("../../../../../assets/leisure-landing/slides/Задания с пользой для города.jpg").default,
    title: "События в городе",
    text: "Задания с пользой для города",
    link: "https://gz.mos.ru/assignments/list",
  },
  // {
  //   src: require("../../../../../assets/leisure-landing/slides/Мероприятия для молодежи.jpg").default,
  //   title: "События в городе",
  //   text: "Мероприятия для молодежи",
  //   link: "https://mosmolodezh.ru/events/",
  // },
  // {
  //   src: require("../../../../../assets/leisure-landing/slides/Спортивные мероприятия.jpg").default,
  //   title: "События в городе",
  //   text: "Спортивные мероприятия",
  //   link: "https://moscow.sport",
  // },
  // {
  //   src: require("../../../../../assets/leisure-landing/slides/Досуг в парках.jpg").default,
  //   title: "События в городе",
  //   text: "Досуг в парках",
  //   link: "https://t.me/mosgorparks",
  // },
];

export default leisureLandingEventsSlides;
