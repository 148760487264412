import React, { ReactElement, useContext, useEffect, useState } from "react";
import EventsTrackWrapperClick from "../../../../containers/EventsTrack/wrappers/EventsTrackWrapperClick";

import "./ReportButton.scss";
import JobReportsModal from "../../../../containers/Modals/JobReportsModal/JobReportsModal";
import { reports } from "../../../../containers/Modals/JobReportsModal/reports";

export interface ReportButtonProps {}

const ReportButton = (props: ReportButtonProps) => {
  const [jobReportsModalOpen, setJobReportsModalOpen] = useState(false);

  return (
    <div className="reports-button">
      <EventsTrackWrapperClick needParent id={182} replace={["Отчеты о работе платформы"]}>
        <a
          href="#"
          className="reports-button__link"
          onClick={(e) => {
            e.preventDefault();

            setJobReportsModalOpen(true);
          }}
        >
          Отчеты о работе платформы <span className="small-arrow small-arrow_black" />
        </a>
      </EventsTrackWrapperClick>

      {
        <JobReportsModal
          reports={reports}
          isOpened={jobReportsModalOpen}
          onClose={() => setJobReportsModalOpen(false)}
        />
      }
    </div>
  );
};

export default ReportButton;
