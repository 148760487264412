import { FC, useState } from "react";
import "./PhotoReportCard.scss";
import Button from "../../Button/Button";
import { PhotoReport } from "../PhotoReport";

type PhotoReportCardProps = {
  photoReport: PhotoReport;
  onSelectPhotoReport: (photoReport: PhotoReport) => void;
};

const PhotoReportCard: FC<PhotoReportCardProps> = ({ photoReport, onSelectPhotoReport = () => {} }) => {
  const { coverId, name } = photoReport;

  return (
    <div className="photo-report-card">
      <div className="photo-report-card__header">
        <div className="photo-report-card__img">
          <img src={`/uploads/get/${coverId}`} alt={""} />
        </div>
      </div>
      <div className="photo-report-card__footer">
        <div className="photo-report-card__text" dangerouslySetInnerHTML={{ __html: name }}></div>
        <Button onClick={() => onSelectPhotoReport(photoReport)} text="Подробнее" type="outlined"></Button>
      </div>
    </div>
  );
};

export default PhotoReportCard;
