import { forwardRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import { eventsTrackScrollUpdate } from "../../../../store/actions/LayoutActions";
import { EventsTrackWrapperSpecialProps } from "./EventsTrackWrapper.interface";
import EventsTrackWrapperBase from "./EventsTrackWrapperBase";

const EventsTrackWrapperScroll = forwardRef<unknown, EventsTrackWrapperSpecialProps>((props, ref) => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(eventsTrackScrollUpdate());
        return () => {
            dispatch(eventsTrackScrollUpdate())
        };
    }, []);

    return <EventsTrackWrapperBase ref={ref} type="scroll" {...props} />;
});

export default EventsTrackWrapperScroll;