// @flow

import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import Button from "../../../presentational/Controls/Button/Button";
import Input from "../../../presentational/Controls/Input/Input";
import Select from "../../../presentational/Controls/Select/Select";
import Radio from "../../../presentational/Controls/Radio/Radio";
import Checkbox from "../../../presentational/Controls/Checkbox/Checkbox";
import ProfilePic from "../../../presentational/ProfilePic/ProfilePic";
import { AppModal } from "../../Modal/AppModal";
import addresses from "../../../../utils/addresses";
import { logout, getUser } from "../../../../store/actions/UserActions";
import { disableBodyScroll, enableBodyScroll, showInfoModal } from "../../../../store/actions/LayoutActions";
import UserService from "../../../../services/userService";
import { getRandomInt, isParticipant } from "../../../../utils/index";
import AvatarModal from "../../../containers/Modals/AvatarModal/AvatarModal";
import CropAvatarModal from "../../../containers/Modals/CropAvatarModal/CropAvatarModal";
import "./AfterRegistrationForm.scss";
import { SUCCESS } from "../../../../types/statusCodes";
import { RootState } from "../../../../types/State.interface";
import { User, UserTypeEnum } from "../../../../types/User.interface";
import PointsService from "../../../../services/pointsService";
import { ActionEventEnum } from "../../../../types/Common.interface";
import ProjectService from "../../../../services/projectService";
import { API } from "../../../../services/api";

const AfterRegistrationForm = (props) => {
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user.userDetails);
  const userRef: { current: User } = useRef();
  useEffect(() => {
    userRef.current = user;
  }, [user]);

  useEffect(() => {
    dispatch(disableBodyScroll());

    return () => {
      dispatch(enableBodyScroll());
    };
  }, []);

  const [firstName, setFirstName] = useState(user.firstName);
  const [firstNameValidator, setFistNameValidator] = useState(null);
  const [lastName, setLastName] = useState(user.lastName);
  const [lastNameValidator, setLastNameValidator] = useState(null);
  const [email, setEmail] = useState(user.email);
  const [emailValidator, setEmailValidator] = useState(null);
  const [gender, setGender] = useState("");
  const [birthYear, setBirthYear] = useState("");
  const [moscowRegion, setMoscowRegion] = useState(user.moscowRegion || "");
  const [moscowDistrict, setMoscowDistrict] = useState(user.moscowDistrict || "");
  const [consent, setConsent] = useState<boolean>();
  const [file, setFile] = useState(null);
  const [avatarIdx, setAvatarIdx] = useState(null);
  const [imageId, setImageId] = useState(null);
  const [editAvatarModalOpen, setEditAvatarModalOpen] = useState(false);
  const [cropAvatarModalOpen, setCropAvatarModalOpen] = useState(false);
  const [error, setError] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [submitting, setSubmitting] = useState<boolean>();
  const [moscowDistricts, setMoscowDistricts] = useState<any[]>([]);

  const submitForm = (e) => {
    if (e) e.preventDefault();

    setSubmitted(true);
    if (formValid()) {
      const formData = {
        ...user,
        firstName,
        lastName,
        email,
        gender,
        emailVerified: user.email === email,
        birthYear,
        moscowRegion,
        moscowDistrict,
        pictureId: getProfilePicId(),
      };

      setSubmitting(true);

      const baseReqs = [updateUserWithDelay(formData)];
      let updates = imageId
        ? [...baseReqs]
        : [...baseReqs, UserService.uploadDefaultPicture(avatarIdx || getRandomInt(1, 9))];

      Promise.all(updates)
        .then((responses) => {
          let withError = responses.filter(Boolean).filter((res) => res.status !== SUCCESS);

          if (!withError.length) {
            dispatch(
              getUser(() => {
                joinAllProjects();
                showPointsModal();
              })
            );
          } else {
            let error = withError.map((res) => res.message).join(" <br>");
            setError(error);
          }
        })
        .catch((err) => console.log(err))
        .finally(() => {
          setSubmitting(false);
          props.onSubmit && props.onSubmit();
        });
    }
  };

  function updateUserWithDelay(data): Promise<any> {
    return new Promise((res, rej) => {
      UserService.updateUser(data).then((response) => {
        setTimeout(() => {
          res(response);
        }, 3000);
      });
    });
  }

  const showPointsModal = async () => {
    const [amount, activeProjects] = await Promise.all([
      PointsService.getPoints(ActionEventEnum.REGISTRATION).then((response) => response.data),
      checkActiveProjects(),
    ]);
    const msg = getSuccessMessage(amount, activeProjects);
    dispatch(showInfoModal(msg, renderOkButton(), props.close));
  };

  const checkActiveProjects = () => {
    return ProjectService.getActiveIDs().then(({ data }) => {
      const currentProject = data?.currentIds?.length;
      const announcementProjects = data?.announcementIds?.length;
      return currentProject || announcementProjects;
    });
  };

  const joinAllProjects = () => {
    const projectTypes = ["CURRENT", "ANNOUNCEMENT"];
    if (user) {
      const reqs = projectTypes.map((type) => API.get(`/api/project/get?type=${type}`));
      Promise.all(reqs)
        .then(([currentResponse, announceResponse]) => {
          const currentProjects = currentResponse.data.data;
          const announceProjects = announceResponse.data.data;
          const projectsToJoin = [...currentProjects, ...announceProjects].filter(
            (project) => !isParticipant(user, project.id)
          );
          UserService.addToProjects({ ids: projectsToJoin.map((project) => project.id) }).catch((err) =>
            console.log(err)
          );
        })
        .catch(console.log);
    }
  };

  const userChangedEmail = () => {
    return user.email !== email;
  };

  const getSuccessMessage = (amount, activeProjects) => {
    if (user.email === null) {
      return "Анкета сохранена!";
    }

    if (userChangedEmail()) {
      return "Регистрация успешно завершена!";
    }

    if (activeProjects) {
      if (userRef.current.userType === UserTypeEnum.SHORT) {
        //return `Благодарим за проявленный интерес! <br/> Вы стали пользователем платформы «Город идей» и участником вашего первого краудсорсинг-проекта. При наличии полной или стандартной учетной записи на портале <a href="https://my.mos.ru/my/#/" target="_blank">mos.ru </a> вы сможете получать <a href='/pointsRules' target="blank">баллы</a> за участие в проектах.`;
        return `Благодарим за проявленный интерес! <br/> Вы стали пользователем платформы «Город идей» и участником вашего первого проекта.`;
      } else {
        //return `Благодарим за проявленный интерес! <br/> Вы стали пользователем платформы «Город идей» и участником вашего первого проекта. Вам начислено ${amount} приветственных баллов. Ознакомьтесь с информацией о <a href='/pointsRules' target="blank">программе лояльности</a> на платформе.`;
        return `Благодарим за проявленный интерес! <br/> Вы стали пользователем платформы «Город идей» и участником вашего первого проекта.`;
      }
    } else {
      return `Благодарим за проявленный интерес! <br/> Вы стали пользователем платформы «Город идей».`;
    }
  };

  const getClassesForFormBody = () => {
    let classes = "after-registration-form__body";
    if (submitting) {
      classes += " after-registration-form__body-disabled";
    }
    return classes;
  };

  const getProfilePicId = () => {
    return imageId || (avatarIdx && gender.toLowerCase() + "_" + avatarIdx) || "";
  };

  const getYears = (from, to) => {
    const years: any[] = [];

    while (to >= from) {
      const year = to--;

      years.push({
        name: year,
        value: year,
      });
    }

    return years;
  };

  // VALIDATION

  const formValid = () => {
    return (
      firstNameValidator.isValid() &&
      lastNameValidator.isValid() &&
      (!!user.email || emailValidator.isValid()) &&
      gender &&
      birthYear &&
      moscowRegion &&
      moscowDistrict &&
      consent
    );
  };

  const getMoscowRegions = () => {
    return addresses.map((region) => {
      return {
        value: region.name,
        origin: region,
      };
    });
  };

  const getMoscowDistricts = (regionName) => {
    if (!regionName) return;

    const { districts } = addresses.find((reg) => reg.name === regionName) || {};
    const result =
      (districts &&
        districts.map((district) => {
          return {
            value: district,
            item: district,
          };
        })) ||
      [];

    setMoscowDistricts(result);
  };

  const openCropAvatarModal = (file) => {
    setFile(file);
    setCropAvatarModalOpen(true);
  };

  // RENDER

  const renderOkButton = () => {
    return <Button type="outlined" text="Хорошо" onClick={props.close} />;
  };

  const renderEditAvatar = () => {
    return (
      <>
        {editAvatarModalOpen && (
          <AvatarModal
            isOpened={editAvatarModalOpen}
            gender={gender}
            pictureId={getProfilePicId()}
            onClose={() => setEditAvatarModalOpen(false)}
            onSelectFile={openCropAvatarModal}
            onSuccess={(imageId) => {
              setImageId(null);
              setAvatarIdx(imageId);
            }}
          />
        )}

        {cropAvatarModalOpen && (
          <CropAvatarModal
            isOpened={cropAvatarModalOpen}
            file={file}
            onClose={() => setCropAvatarModalOpen(false)}
            onSwitchModals={() => {
              setCropAvatarModalOpen(false);
              setEditAvatarModalOpen(true);
            }}
            onSuccess={(imageId) => {
              setImageId(imageId);
              setCropAvatarModalOpen(false);
              setEditAvatarModalOpen(false);
            }}
          />
        )}
      </>
    );
  };

  const renderContent = () => {
    return (
      <form onSubmit={submitForm} className="after-registration-form">
        <div className="after-registration-form">
          <div className="after-registration-form__title">
            <p>
              Вы вошли при помощи <span className="sudir-img"></span> <span className="cr-red">mos.ru</span>
            </p>
            <p>Все поля обязательны для заполнения</p>
          </div>

          <div className={getClassesForFormBody()}>
            <ProfilePic
              imageId={getProfilePicId() || "undefined"}
              gender={gender.toLowerCase()}
              onClick={() => setEditAvatarModalOpen(true)}
              showHoverEffect={true}
              isRounded={true}
            />

            {error && (
              <div
                className="after-registration-form__errors cr-error"
                dangerouslySetInnerHTML={{ __html: error }}
              ></div>
            )}

            <Input
              label="Имя"
              value={firstName}
              maxLength={40}
              onChange={(value) => setFirstName(value)}
              validateRules={{
                notEmpty: true,
                maxLength: 40,
                minLength: 2,
                docNameV2: true,
              }}
              onInitValidator={(validator) => setFistNameValidator(validator)}
              showError={submitted}
            />

            <Input
              label="Фамилия"
              maxLength={40}
              value={lastName}
              onChange={(value) => setLastName(value)}
              validateRules={{
                notEmpty: true,
                maxLength: 40,
                minLength: 2,
                docNameV2: true,
              }}
              onInitValidator={(validator) => setLastNameValidator(validator)}
              showError={submitted}
            />

            {!user.email && (
              <Input
                label="Электронная почта"
                value={email}
                onChange={(value) => setEmail(value)}
                validateRules={{
                  email: true,
                }}
                onInitValidator={(validator) => setEmailValidator(validator)}
                showError={submitted}
              />
            )}

            <Select
              items={getYears(1915, new Date().getFullYear() - 14)}
              closeOnSelect={true}
              restrictBy={10}
              bindTo="name"
              label="Год рождения"
              placeholder=""
              onItemSelect={(item) => setBirthYear(item.value)}
              isInvalid={(submitted && !birthYear) || false}
            />

            <div className="after-registration-form__body-radios">
              <span className="after-registration-form__body-radios-title">Пол</span>

              <Radio
                changed={() => setGender("MALE")}
                id="id1"
                isSelected={gender === "MALE"}
                value="MALE"
                label="M"
                isInvalid={(submitted && !gender) || false}
              />
              <Radio
                changed={() => setGender("FEMALE")}
                id="id2"
                isSelected={gender === "FEMALE"}
                value="FEMALE"
                label="Ж"
                isInvalid={(submitted && !gender) || false}
              />
            </div>

            <Select
              items={getMoscowRegions()}
              value={moscowRegion}
              bindTo="value"
              closeOnSelect={true}
              label="Округ Москвы"
              placeholder=""
              restrictBy={10}
              onItemSelect={(region) => {
                setMoscowRegion(region?.value);
                getMoscowDistricts(region?.value);
              }}
              isInvalid={(submitted && !moscowRegion) || false}
            />
            <Select
              items={moscowDistricts}
              value={moscowDistrict}
              bindTo="value"
              closeOnSelect={true}
              restrictBy={10}
              placeholder=""
              isDisabled={!moscowDistricts.length}
              label="Район Москвы"
              selectFirstItem={true}
              onItemSelect={(district) => setMoscowDistrict(district?.value)}
              isInvalid={(submitted && !moscowDistrict) || false}
            />

            <Checkbox
              html="Я принимаю условия <a href='/license' target='_blank'>Пользовательского соглашения</a>"
              onChange={(value) => setConsent(value)}
              isInvalid={(submitted && !consent) || false}
            />
          </div>

          <div className="after-registration-form__footer">
            <Button type="outlined" text="Отменить" onClick={props.closeWithLogout} />

            <Button type="filled" text="Подтвердить" isLoading={submitting} onClick={submitForm} />
          </div>
        </div>
      </form>
    );
  };

  return (
    <AppModal
      classNames={{ modal: "after-registration-modal" }}
      center
      overlayId="modal-overlay"
      closeIconId="close-modal-icon"
      blockScroll={true}
      closeIconSize={25}
      open={props.isOpen}
      closeOnEsc={false}
      closeOnOverlayClick={false}
      onClose={props.closeWithLogout}
    >
      {renderContent()}
      {renderEditAvatar()}
    </AppModal>
  );
};

export default AfterRegistrationForm;
