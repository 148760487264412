import React from "react";
import EventsTrackWrapperClick from "../../EventsTrack/wrappers/EventsTrackWrapperClick";
import "./SocialNetworks.scss";

const SocialNetworks = () => (
  <div className="social-links">
    {/* <a // eslint-disable-line jsx-a11y/anchor-has-content
			href="https://www.facebook.com/crowdmosru"
			target="_blank"
			rel="noopener noreferrer"
			className="link facebook"
		/> */}
    <EventsTrackWrapperClick needParent id={183} replace={["Vkontakte"]}>
      <a // eslint-disable-line jsx-a11y/anchor-has-content
        href="https://vk.com/crowdmosru"
        target="_blank"
        rel="noopener noreferrer"
        className="link vk"
      />
    </EventsTrackWrapperClick>
  </div>
);

export default SocialNetworks;
