import { FC } from "react";
import { ModalProps } from "./Modal.types";
import "./Modal.scss";
import { useOuterClick } from "../../../../../hooks/useOnOutsideClick";

const Modal: FC<ModalProps> = ({
  isOpened,
  onClose,
  logoSrc,
  title,
  content,
  closeOnClickOutSide = false,
  className = "",
}) => {
  const ref = useOuterClick<HTMLDivElement>(() => closeOnClickOutSide && onClose(), true);

  return isOpened ? (
    <div ref={ref} className={`ny-modal ${className}`}>
      <button type="button" className="ny-modal__close-btn" onClick={onClose}>
        <i className="ui-icon ui-icon-14" />
      </button>
      {!!logoSrc && <img className="ny-modal__logo" src={logoSrc} alt="" />}
      {!!title && <div className="ny-modal__title">{title}</div>}
      <div className="ny-modal__content">{content}</div>
    </div>
  ) : null;
};

export default Modal;
