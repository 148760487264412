import { useEffect, useRef } from "react";

const usePrevious = <T>(value: T, needUpdate?: (() => boolean) | boolean) => {
  const ref = useRef<T>();

  useEffect(() => {
    if (
      typeof needUpdate === "undefined" ||
      (typeof needUpdate === "boolean" && needUpdate) ||
      (typeof needUpdate === "function" && needUpdate())
    ) {
      ref.current = value;
    }
  }, [needUpdate, value]);

  return ref.current;
};

export default usePrevious;
