export const MosProjects = [
    {
      id: "ed",
      name: "Электронный дом",
      link: "https://ed.mos.ru/",
      logo: require("../../../../assets/cross-promo/logos/ed.svg").default,
    },
    {
      id: "ag",
      name: "Активный гражданин",
      link: "https://ag.mos.ru/",
      logo: require("../../../../assets/cross-promo/logos/ag.svg").default,
    },
    {
      id: "gorod",
      name: "Наш город",
      link: "https://gorod.mos.ru/",
      logo: require("../../../../assets/cross-promo/logos/gorod.svg").default,
    },
    {
      id: "gz",
      name: "Город заданий",
      link: "https://gz.mos.ru/",
      logo: require("../../../../assets/cross-promo/logos/gz.svg").default,
    },
    {
        id: "ag-vmeste",
        name: "Миллион призов",
        link: "https://ag-vmeste.ru/",
        logo: require("../../../../assets/cross-promo/logos/ag-vmeste.svg").default,
      },
    {
      id: "mos",
      name: "Портал mos.ru",
      link: "https://www.mos.ru/",
      logo: require("../../../../assets/cross-promo/logos/mos.svg").default,
    },
    {
      id: "myid",
      name: "Мой id",
      link: "https://www.mos.ru/city/projects/mosapps/",
      anchor: "#myid",
      logo: require("../../../../assets/cross-promo/logos/myid.svg").default,
    },
  ];