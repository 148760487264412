import Garland1 from "../../../../../assets/newyear2023/garlands1.png";
import Garland2 from "../../../../../assets/newyear2023/garlands2.png";
import Garland3 from "../../../../../assets/newyear2023/garlands3.png";
import Garland4 from "../../../../../assets/newyear2023/garlands4.png";
import Garland5 from "../../../../../assets/newyear2023/garlands5.png";
import Garland6 from "../../../../../assets/newyear2023/garlands6.png";

const Garlands1 = [Garland4, Garland5, Garland6];
const Garlands2 = [Garland1, Garland2, Garland3];

export {Garlands1, Garlands2}


