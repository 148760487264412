import { FC } from "react";
import Modal from "../Modal/Modal";
import { ModalProps } from "../Modal/Modal.types";
import "./ModalFactWin.scss";

const ModalFactWin: FC<Pick<ModalProps, "isOpened" | "onClose">> = ({ isOpened, onClose }) => (
  <Modal
    className="modal-fact-win"
    isOpened={isOpened}
    onClose={onClose}
    logoSrc={require("../../../../../assets/newyear2023/icons/victory-facts.svg").default}
    title="Поздравляем!"
    content="Вы нашли все спрятанные предметы в нашем доме и узнали много интересного о проектах. 500 баллов будет начислено пользователям с полной учетной записью mos.ru."
  />
);

export default ModalFactWin;
