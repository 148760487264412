import { ActivityType } from "../../services/city-api";

export interface CircleButtonData {
  id: number;
  type: ActivityType;
  icon: string;
  "icon-active": string;
  title: string;
}

export const circleButtonsData: CircleButtonData[] = [
  {
    id: 0,
    type: ActivityType.TRANSPORT,
    icon: require("../../../../../assets/leisure-landing/header/card.svg").default,
    "icon-active": require("../../../../../assets/leisure-landing/header/card-active.svg").default,
    title: "Предложите идею в сфере транспорта",
  },
  {
    id: 1,
    type: ActivityType.PARKS,
    icon: require("../../../../../assets/leisure-landing/header/kite.svg").default,
    "icon-active": require("../../../../../assets/leisure-landing/header/kite-active.svg").default,
    title: "Загрузите фото реализованной идеи в сфере парки",
  },
  {
    id: 2,
    type: ActivityType.GAME,
    icon: require("../../../../../assets/leisure-landing/header/cap.svg").default,
    "icon-active": require("../../../../../assets/leisure-landing/header/cap-active.svg").default,
    title: "Забросьте пять мячей в корзину для победы в игре",
  },
  {
    id: 3,
    type: ActivityType.ECOLOGY,
    icon: require("../../../../../assets/leisure-landing/header/eco.svg").default,
    "icon-active": require("../../../../../assets/leisure-landing/header/eco-active.svg").default,
    title: "Предложите идею в сфере экологии или благоустройства",
  },
  {
    id: 4,
    type: ActivityType.CULTURE,
    icon: require("../../../../../assets/leisure-landing/header/ticket.svg").default,
    "icon-active": require("../../../../../assets/leisure-landing/header/ticket-active.svg").default,
    title: "Загрузите фото реализованной идеи в сфере культуры",
  },
];

export const emptyWonList = [
  { type: ActivityType.TRANSPORT, won: false },
  { type: ActivityType.PARKS, won: false },
  { type: ActivityType.GAME, won: false },
  { type: ActivityType.ECOLOGY, won: false },
  { type: ActivityType.CULTURE, won: false },
];
