import { FC } from "react";
import BirthdayModal from "../../BirthdayModal";
import { SphereProjectModalProps } from "./SphereProjectModal.types";
import "./SphereProjectModal.scss";

const SphereProjectModal: FC<SphereProjectModalProps> = ({
  project,
  children,
  ...otherProps
}) => {
  return (
    <BirthdayModal {...otherProps}>
      <div className="sphere-project">
        <div className="sphere-project__img-container">
          <div className="sphere-project__img-container__inner">
            {Array.from(Array(5), (_, i) => (
              <div key={i} className="sphere-project__stone" />
            ))}
            <div className="sphere-project__stone-mask">
              <img src={project.img} alt="" />
            </div>
          </div>
        </div>
        <div className="sphere-project__title">{project.title}</div>
        <div className="sphere-project__desc">{project.description}</div>
      </div>
    </BirthdayModal>
  );
};

export default SphereProjectModal;
