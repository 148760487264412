import { Idea, IdeasState } from "../../types/Idea.interface";
import {
  GET_IDEAS,
  GET_IDEA_TAGS,
  GET_PROJECTS,
} from "../actions/IdeasActions";

const initialState = {
  ideas: null,
  ideasTags: null,
  projects: null,
};

export function ideasReducer(state: IdeasState = initialState, action) {
  switch (action.type) {
    case GET_IDEAS:
      return {
        ...state,
        ideas: action.payload,
      };

    case GET_IDEA_TAGS:
      return {
        ...state,
        ideasTags: action.payload,
      };

    case GET_PROJECTS:
      return {
        ...state,
        projects: action.payload,
      };

    default:
      return state;
  }
}
