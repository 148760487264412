// @flow
import { fetchRequest } from "../../utils/axiosConfig";
import ProjectService from "../../services/projectService";

export const GET_ARCHIVE_PROJECTS = "GET_ARCHIVE_PROJECTS";
export const GET_CURRENT_PROJECTS = "GET_CURRENT_PROJECTS";
export const GET_ANNOUNCEMENT_PROJECTS = "GET_ANNOUNCEMENT_PROJECTS";
export const GET_ACTIVE_IDS = "GET_ACTIVE_IDS";
export const GET_PROJECTS_STATS = "GET_PROJECTS_STATS";
export const GET_COMPLETED_PROJECTS = "GET_COMPLETED_PROJECTS";

const urls = ["/api/project/get?type=ANNOUNCEMENT", "/api/project/get?type=CURRENT", "/api/project/get?type=ARCHIVE"];

export const PROJECTS_REQUEST = "PROJECTS_REQUEST";
export const PROJECTS_SUCCESS = "PROJECTS_SUCCESS";
export const PROJECTS_FAIL = "PROJECTS_FAIL";

export const BANNERS_REQUEST = "BANNERS_REQUEST";
export const BANNERS_SUCCESS = "BANNERS_SUCCESS";
export const BANNERS_FAIL = "BANNERS_FAIL";

export function getProjects() {
  return function (dispatch) {
    dispatch({
      type: PROJECTS_REQUEST,
    });

    Promise.all(urls.map((url) => fetchRequest(url, {}, "GET")))
      .then((responses) => {
        if (responses.every((response) => response.data.status === 0)) {
          const projects = {
            announcement: responses[0].data.data,
            current: responses[1].data.data,
            archive: responses[2].data.data,
          };
          dispatch({
            type: PROJECTS_SUCCESS,
            payload: projects,
          });
        } else {
          dispatch({
            type: PROJECTS_FAIL,
            error: true,
            payload: new Error("Ошибка запроса данных. Пожалуйста, повторите попытку позже."),
          });
        }
      })
      .catch(() => {
        dispatch({
          type: PROJECTS_FAIL,
          error: true,
          payload: new Error("Ошибка запроса данных. Пожалуйста, повторите попытку позже."),
        });
      });
  };
}

export function getProjectBanners() {
  const url = "/api/banner/getByFilter?published=true";

  return function (dispatch) {
    dispatch({
      type: BANNERS_REQUEST,
    });

    fetchRequest(url, {}, "GET")
      .then((response) => {
        if (response.data.status === 0) {
          dispatch({
            type: BANNERS_SUCCESS,
            payload: response.data.data,
          });
        } else {
          dispatch({
            type: BANNERS_FAIL,
            error: true,
            payload: new Error("Ошибка запроса данных. Пожалуйста, повторите попытку позже."),
          });
        }
      })
      .catch(() => {
        dispatch({
          type: BANNERS_FAIL,
          error: true,
          payload: new Error("Ошибка запроса данных. Пожалуйста, повторите попытку позже."),
        });
      });
  };
}

export const getCompletedProjects = () => (dispatch) => {
  return ProjectService.getCompletedProjects()
    .then(({ data }) => {
      dispatch({ type: GET_COMPLETED_PROJECTS, payload: data });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getArchiveProjects = () => (dispatch) => {
  return ProjectService.getArchiveProjects()
    .then(({ data }) => {
      dispatch({ type: GET_ARCHIVE_PROJECTS, payload: data });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getAnnouncementProjects = () => (dispatch) => {
  return ProjectService.getAnnouncementProjects()
    .then(({ data }) => {
      dispatch({ type: GET_ANNOUNCEMENT_PROJECTS, payload: data });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getCurrentProjects = () => (dispatch) => {
  return ProjectService.getCurrentProjects()
    .then(({ data }) => {
      dispatch({ type: GET_CURRENT_PROJECTS, payload: data });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getActiveIds = () => (dispatch) => {
  return ProjectService.getActiveIDs()
    .then(({ data }) => {
      dispatch({ type: GET_ACTIVE_IDS, payload: data });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getProjectsStats = () => (dispatch) => {
  return ProjectService.getProjectsStats()
    .then(({ data }) => {
      dispatch({ type: GET_PROJECTS_STATS, payload: data });
    })
    .catch((err) => {
      console.log(err);
    });
};
