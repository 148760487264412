import React, { useContext, useEffect, useState } from "react";
import { Puzzle } from "./Puzzle/Puzzle";

import "./BirthdayGame.scss";
import Button from "../../../../presentational/Controls/Button/Button";
import PuzzleGame, { PuzzleGameController } from "./PuzzleGame/PuzzleGame";
import Timer from "../../../../presentational/Timer/Timer";
import GameService, {
  GameStatus,
  GameResult,
} from "../../../../../services/birthday-game.service";
import { ApiStatusCode } from "../../../../../types/Common.interface";
import { showInfoModal } from "../../../../../store/actions/LayoutActions";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../types/State.interface";
import {
  AuthAction,
  AuthActionParam,
  AuthActionType,
  ComponentType,
} from "../../../../../services/sudirService";
import { AppContext } from "../../../../Root";
import { BirthdayUrls } from "../../CrowdBirthday9.helper";

const BirthdayGame = () => {
  const dispatch = useDispatch();
  const appContext = useContext(AppContext);
  const user = useSelector((state: RootState) => state.user.userDetails);
  const spheres = useSelector((state: RootState) => state.gorod.spheres);

  const [gameController, setGameController] =
    useState<PuzzleGameController>(null);
  const [started, setStarted] = useState(false);
  const [won, setWon] = useState(false);
  const [lost, setLost] = useState(false);

  // ACTIONS AFTER AUTH
  useEffect(() => {
    if (!user?.loggedIn || !spheres?.length) return;

    const actions = appContext.sudirService.getActions(
      ComponentType.GAME
    );
    if (actions.length) {
      const startGameAction = actions.find(
        (act) => act.type === AuthActionType.START_GAME
      );
      if (startGameAction) {
        setTimeout(() => {
          document.querySelector(".birthday-game").scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
          startGame();
        }, 1000);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.loggedIn, spheres]);

  useEffect(() => {
    if (user?.loggedIn) {
      GameService.status()
        .then((res) => {
          if (res.status === ApiStatusCode.OK) {
            if (res.data === GameStatus.WON) {
              setWon(true);
            }
          } else {
            dispatch(showInfoModal(res.message));
          }
        })
        .catch((err) => {
          dispatch(showInfoModal(err.message));
        });

      GameService.finish({ status: GameResult.LOST }).then(
        (res) => {
          console.log(res);
        }
      );
    }

    if (!user?.loggedIn) {
      setStarted(false);
      setLost(false);
      setWon(false);
      gameController?.stop();
    }
  }, [user]);

  const startGame = () => {
    if (!user?.loggedIn) {
      const thisContainerData: AuthActionParam = {
        component: ComponentType.GAME,
        type: AuthActionType.START_GAME,
        args: {},
      };

      const action = new AuthAction(thisContainerData);
      return appContext.sudirService.authWithActions([action]);
    }

    setLost(false);
    GameService.start()
      .then((res) => {
        switch (res.status) {
          case ApiStatusCode.OK: {
            gameController.start();
            setStarted(true);
            break;
          }
          case ApiStatusCode.USER_ALREADY_WON: {
            console.log(res.message);
            break;
          }
        }
      })
      .catch((err) => dispatch(showInfoModal(err.message)));
  };

  const onWin = () => {
    GameService.finish({ status: GameResult.WON })
      .then((res) => {
        if (res.status === ApiStatusCode.OK) {
          setWon(true);
        } else {
          dispatch(showInfoModal(res.message));
        }
      })
      .catch((err) => dispatch(showInfoModal(err.message)));
  };

  const onTimeExpire = () => {
    setStarted(false);
    setLost(true);
    gameController.stop();
    GameService.finish({ status: GameResult.LOST })
      .then((res) => {})
      .catch((err) => dispatch(showInfoModal(err.message)));
  };

  const renderStatusTitle = () => {
    if (lost) {
      return (
        <div className="birthday-game__losttitle">
          К сожалению, Вы не успели собрать пазл. Попробуйте еще раз!
        </div>
      );
    } else if (won) {
      return (
        <div className="birthday-game__wontitle">
          Поздравляем! Вы собрали пазл. <br></br> Пользователям с полной учетной
          записью mos.ru до 31 мая 2023 года будут начислены баллы. Подробнее в{" "}
          <a target="_blank" href={BirthdayUrls.GAME_RULES} rel="noreferrer">
            {" "}
            Правилах{" "}
          </a>{" "}
          .
        </div>
      );
    } else {
      return (
        <div className="birthday-game__subtitle">
          Игра завершена. С Правилами игры можно ознакомиться по <a href={BirthdayUrls.GAME_RULES} target="_blank" rel="noreferrer">
            ссылке
          </a>.
        </div>
      );
    }
  };

  return (
    <section id="game" className="birthday-game">
      <div className="birthday-game__inner">
        <div className="birthday-title">Праздничная игра</div>
        {renderStatusTitle()}
        {won && (
          <div className="birthday-game__won-bg">
            <img
              src={
                require(`../../../../../assets/birthday9/images/game/won-bg.png`)
                  .default
              }
            />
          </div>
        )}

        {/* {!started && !won && (
          <Button type="filled" text="Начать игру" onClick={startGame} />
        )} */}

        {user?.loggedIn && started && !lost && !won && (
          <Timer
            initialMinutes={5}
            initialSeconds={0}
            onExpire={onTimeExpire}
          />
        )}

        {!won && (
          <PuzzleGame
            rows={6}
            columns={6}
            image={
              require(`../../../../../assets/birthday9/images/game/birthday-game-pic.jpg`)
                .default
            }
            onInit={setGameController}
            onStart={() => setStarted(true)}
            onWin={onWin}
          />
        )}
      </div>
    </section>
  );
};

export default BirthdayGame;
