import React, { Component } from "react";
import ContentLoader from "react-content-loader";
import "./FourColumnLoader.scss";
import { useMediaQuery } from "react-responsive";
import { phoneWidth, tabletWidth } from "../../../../utils/constants/widthConstants";

export interface FourColumnLoaderProps {
  isTitled: boolean;
}

export default function FourColumnLoader({ isTitled = true }) {
  const isTablet = useMediaQuery({ query: `(max-width: ${tabletWidth}px)` });
  const isPhone = useMediaQuery({ query: `(max-width: ${phoneWidth}px)` });

  const firstRowYOffset = isTitled ? 80 : 0;
  const secondRowYOffset = isTitled ? 300 : 220;

  const renderPhoneLoader = () => {
    return <rect x="0" y={firstRowYOffset} rx="2" ry="2" width="100%" height="600" />;
  };

  const renderTabletLoader = () => {
    return (
      <>
        <rect x="0" y={firstRowYOffset} rx="2" ry="2" width="49%" height="211" />
        <rect x="50%" y={firstRowYOffset} rx="2" ry="2" width="49%" height="211" />
        <rect x="0" y={secondRowYOffset} rx="2" ry="2" width="49%" height="211" />
        <rect x="50%" y={secondRowYOffset} rx="2" ry="2" width="49%" height="211" />
      </>
    );
  };

  const renderDesktopLoader = () => {
    return (
      <>
        <rect x="0" y={firstRowYOffset} rx="2" ry="2" width="24%" height="211" />
        <rect x="25%" y={firstRowYOffset} rx="2" ry="2" width="24%" height="211" />
        <rect x="50%" y={firstRowYOffset} rx="2" ry="2" width="24%" height="211" />
        <rect x="75%" y={firstRowYOffset} rx="2" ry="2" width="24%" height="211" />

        <rect x="0" y={secondRowYOffset} rx="2" ry="2" width="24%" height="211" />
        <rect x="25%" y={secondRowYOffset} rx="2" ry="2" width="24%" height="211" />
        <rect x="50%" y={secondRowYOffset} rx="2" ry="2" width="24%" height="211" />
        <rect x="75%" y={secondRowYOffset} rx="2" ry="2" width="24%" height="211" />
      </>
    );
  };

  return (
    <div className="four-column-loader">
      <ContentLoader
        height="100%"
        width="100%"
        viewBox="0 0 800 575"
        gradientRatio={0.2}
        speed={2}
        backgroundColor="#e8e8e8"
        foregroundColor="#ecebeb"
        title="Загрузка..."
      >
        {/* titles */}
        {isTitled && (
          <>
            <rect x="0" y="9" rx="2" ry="2" width="40%" height="11" />
            <rect x="0" y="30" rx="2" ry="2" width="100%" height="11"></rect>
          </>
        )}

        {/* cubes */}
        {isPhone ? renderPhoneLoader() : isTablet ? renderTabletLoader() : renderDesktopLoader()}
      </ContentLoader>
    </div>
  );
}
