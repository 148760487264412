class BookViewBase {
  constructor() {}

  update() {}

  get canSlidePrev() {
    return false;
  }

  get canSlideNext() {
    return false;
  }

  swipeRight(e) {}

  swipeLeft() {}

  swipeUp(e) {}

  swipeDown(e) {}
}

export default BookViewBase;
