import Game from "./Game/Game";
import GameWrapper from "../Wrappers/GameWrapper/GameWrapper";
import { MosProjects } from "../../../../../types/Projects.interface";
import useImagePreload from "../../hooks/useImagePreload";

import backgroundSvg from "../../../../../assets/newyear2023/games/sudoku/images/background.svg";
import boxPng from "../../../../../assets/newyear2023/games/sudoku/images/box.png";
import { gameTitle, startText } from "./SudokuGame.constants";
import { decorations } from "./SudokuGame.constants";
import "./SudokuGame.scss";

interface SudokuGameProps {
  onWin?: () => void;
}

const imageSources = [...decorations.map((decoration) => decoration.src), backgroundSvg, boxPng];

const SudokuGame: React.FC<SudokuGameProps> = ({ onWin }) => {
  const isLoad = useImagePreload(imageSources);
  return (
    <GameWrapper
      className="sudoku-game"
      gameTitle={gameTitle}
      startText={startText}
      onWin={onWin}
      project={MosProjects.GI}
    >
      {isLoad && <Game />}
    </GameWrapper>
  );
};

export default SudokuGame;
