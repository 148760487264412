import { FC } from "react";
import { LoadingProps } from "./Loading.types";
import "./Loading.scss";

const Loading: FC<LoadingProps> = ({ progress }) => {
  return (
    <div className="leisure-landing-loading">
      <div className="leisure-landing-loading__inner">
        <div className="leisure-landing-loading__bar">
          <span />
          <span style={{ width: `${progress * 100}%` }} />
        </div>
        <div className="leisure-landing-loading__text">Загрузка...</div>
      </div>
    </div>
  );
};

export default Loading;
