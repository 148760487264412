import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { HashLink as Link } from "react-router-hash-link";
import "./ProposeIdeaForm.scss";
import { RootState } from "../../../../types/State.interface";
import { GorodIdeaSphere } from "../../../../services/gorodService";
import { projectIsCurrent } from "../../../../utils";
import { AppModal } from "../../Modal/AppModal";
import { Project } from "../../../../types/Projects.interface";

interface ProposeIdeaSubmittedFormProps {
  isOpen?: boolean;
  onClose?: () => void;
  handleOpenPropose: Function;
}

const ProposeIdeaSubmittedForm = (props: ProposeIdeaSubmittedFormProps) => {
  const spheres: GorodIdeaSphere[] = useSelector(
    (state: RootState) => state.gorod.spheres
  );
  const currentProjects = useSelector(
    (state: RootState) => state.projects.current
  );

  const onSelectSphere = (sphere: GorodIdeaSphere) => {
    props.handleOpenPropose(sphere);
    props.onClose();
  };

  //RENDER

  const renderContent = () => {
    const project: Project = currentProjects && currentProjects[0];

    return (
      <div className="propose-idea-form">
        <div className={`submit-form__title  propose-idea-form__head`}>
          Ваша идея отправлена
        </div>
        <p className="propose-idea-form__status">
          Статус идеи вы можете отслеживать в{" "}
          <Link to="/profile">личном кабинете</Link>.
        </p>
        <p className="propose-idea-form__spheres-title">
          {project?.id ? (
            <>
              Участвуйте в проекте
              {" "}
              <span dangerouslySetInnerHTML={{ __html: '«' + project.nameOnPromo + '»' }} onClick={() => (window.location.href = project.url)}>
              </span>{" "}
              или предлагайте идеи по другим сферам:
            </>
          ) : (
            "Предлагайте идеи по другим сферам:"
          )}
        </p>
        <div className="propose-idea-form__spheres-list">
          {spheres?.map((sphere) => (
            <p onClick={() => onSelectSphere(sphere)} dangerouslySetInnerHTML={{ __html: sphere.name }} />
          ))}
        </div>
      </div>
    );
  };

  return (
    <div className="propose-idea_end__modal">
      <AppModal
        center
        classNames={{ modal: "submit-form-modal" }}
        overlayId="modal-overlay"
        closeIconId="close-modal-icon"
        blockScroll={true}
        closeIconSize={25}
        open={props.isOpen}
        closeOnEsc={true}
        closeOnOverlayClick={false}
        onClose={props.onClose}
      >
        {renderContent()}
      </AppModal>
    </div>
  );
};

export default ProposeIdeaSubmittedForm;
