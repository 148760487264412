import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { ProjectInfo } from "../../../../types/Banner.interface";
import { phoneWidth } from "../../../../utils/constants/widthConstants";
import { getPhotoList } from "../../../pages/WorkResult/WorkResult.helper";

import "./ContentCard.scss";
import { useSelector } from "react-redux";
import { RootState } from "../../../../types/State.interface";
import { nameIfHidden, renderHatIfUserNameIsHidden } from "../../../../utils/user.utils";
import { GroupDirection } from "../../../../types/GroupDirection.interface";

export interface IContentCard {
  text: string;
  author: any;
  imgId: string;
  publishedPhotoIds: any;
  mainPhotoId: string;
  mainUserPhotoAttachmentId: string;
  mainUserPhotoId: string;
}

export interface ContentCardProps {
  id: string;
  project: ProjectInfo;
  groupDirection?: GroupDirection;
  card: IContentCard;
  onClick: (idea) => void;
}

const ContentCard = ({ id, project, groupDirection, card, onClick }: ContentCardProps) => {
  const currentUser = useSelector((state: RootState) => state.user.userDetails);
  const isPhone = useMediaQuery({ query: `(max-width: ${phoneWidth}px)` });
  const [imageId, setImageId] = useState("");
  const [withStub, setWithStub] = useState(false);

  useEffect(() => {
    setImageId(getMainOrRandomOrStub(card));
  }, [card]);

  const handleClick = (id) => {
    typeof onClick === "function" && onClick(id);
  };

  const getMainOrRandomOrStub = (card) => {
    let candidate = card?.mainPhotoId || card?.mainUserPhotoAttachmentId;
    if (!candidate) {
      const all = getPhotoList(card);
      candidate = all[(Math.random() * all.length) | 0];
    }
    if (!candidate) {
      candidate = groupDirection ? groupDirection.logo?.stubAttachmentId : project?.logo?.realizedStub;
      setWithStub(true);
    }
    return candidate;
  };

  const getName = () => (card.author.deletedSudir ? nameIfHidden : card.author.firstName + " " + card.author.lastName);

  return (
    <div className={`content-card ${withStub ? "with-stub" : ""}`} onClick={() => handleClick(card)}>
      <img src={`/uploads/get/${imageId}`} alt={card.imgId}></img>

      <div className="content-card__fadeable">
        {card.author && (
          <div className="content-card__user">
            {getName()}
            {renderHatIfUserNameIsHidden(card.author, currentUser)}
          </div>
        )}
        <div className="content-card__quote">
          <div className="content-card__info" dangerouslySetInnerHTML={{ __html: card.text }}></div>
          <div className="content-card__more">{isPhone ? <span className="ic ui-icon-1101"></span> : "Подробнее"}</div>
        </div>
      </div>
    </div>
  );
};

export default ContentCard;
