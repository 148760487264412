import React, { ReactElement, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";

import CloseButton from "../../../../containers/Modal/CloseButton/CloseButton";
import CommonSlider from "../../../../presentational/Sliders/CommonSlider/CommonSlider";
import Logo from "../../../../containers/Header/Logo/Logo";
import { MappedIdea } from "../../../../../services/mappedIdea.service";
import { declOfNum, shuffle } from "../../../../../utils";
import { phoneWidth } from "../../../../../utils/constants/widthConstants";
import EventsTrackWrapperClick from "../../../../containers/EventsTrack/wrappers/EventsTrackWrapperClick";
import EventsTrackParentIdContext from "../../../../containers/EventsTrack/EventsTrackParentIdContext";
import EventsTrackWrapperScroll from "../../../../containers/EventsTrack/wrappers/EventsTrackWrapperScroll";
import { disableBodyScroll, enableBodyScroll } from "../../../../../store/actions/LayoutActions";
import "./ResultPopup.scss";
import { eventsTrackConfig } from "../../../../../utils/eventsTrack";
import eventsTrackConfigParams from "../../../../../utils/eventsTrack/eventsTrackConfigParams";
import { nameIfHidden, renderHatIfUserNameIsHidden } from "../../../../../utils/user.utils";
import { RootState } from "../../../../../types/State.interface";

export interface ResultPopupProps {
  ideas: MappedIdea[];
  onClose: () => void;
}

const ResultPopup = ({ ideas, onClose }: ResultPopupProps) => {
  const dispatch = useDispatch();
  const slidersRef = useRef({});
  const isPhone = useMediaQuery({ query: `(max-width: ${phoneWidth}px)` });
  const currentUser = useSelector((state: RootState) => state.user.userDetails);

  useEffect(() => {
    if (isPhone) {
      dispatch(disableBodyScroll());
    }

    return () => {
      if (isPhone) {
        dispatch(enableBodyScroll());
      }
    };
  }, []);

  const isProject = (idea: MappedIdea) => idea.projectId;

  const hasAttachments = (idea: MappedIdea) => Boolean(idea.attachmentIds?.length);

  const getName = (author) => {
      return author.deletedSudir ? nameIfHidden : author.firstName + " " + author.lastName
  };

  const getTitle = (idea: MappedIdea) => idea.groupDirectionName || idea.projectName;

  const renderPopup = useMemo(() => {
    let scrollTriggered = false;
    const onScroll = () => {
      // один раз отправляем событие в статс при скролле, если более 2х идей
      !scrollTriggered && ideas?.length > 1 && eventsTrackConfig.callback(eventsTrackConfigParams.scroll[10].data);
      scrollTriggered = true;
    };

    return (
      <EventsTrackParentIdContext.Provider value="POPUP_MAP_CARD">
        <div className="result-popup" onClick={(e) => e.stopPropagation()}>
          <div
            className={`result-popup__header ${
              ideas.length > 1 ? "result-popup__header--many" : "result-popup__header--one"
            }`}
          >
            <Logo />

            {ideas?.length > 1 && (
              <span className="result-popup__count">
                <b>{String(ideas.length)}</b>{" "}
                {declOfNum(ideas.length || 0, ["реализованная", "реализованные", "реализованных"])}{" "}
                {declOfNum(ideas.length || 0, ["идея", "идеи", "идей"])}
              </span>
            )}
            <EventsTrackWrapperClick needParent id={141}>
              <CloseButton onClick={onClose} />
            </EventsTrackWrapperClick>
          </div>

          <div className="result-popup__body" onScroll={onScroll}>
            <div className="result-popup__list">
              {ideas?.map((idea, idx) => (
                <div className="result-popup__idea" key={idx}>
                  {hasAttachments(idea) && (
                    <div className="result-popup__idea-slider">
                      <CommonSlider
                        onInit={() => null}
                        onSwiper={(slider) => (slidersRef.current[idea.id] = slider)}
                        onSlideChange={() => null}
                        className="result__swiper"
                        options={{
                          loop: true,
                          spaceBetween: 24,
                          slidesPerView: 1,
                          slidesPerColumn: 1,
                          slidesPerGroup: 1,
                        }}
                      >
                        {shuffle(idea.attachmentIds).map((attachmentId: string) => (
                          <img src={`/uploads/get/${attachmentId}`} key={attachmentId} />
                        ))}
                      </CommonSlider>
                    </div>
                  )}

                  <div className="result-popup__idea-title" style={{ marginTop: !hasAttachments(idea) && 0 }}>
                    <EventsTrackWrapperClick needParent id={isProject(idea) ? 256 : 257} replace={[getTitle(idea)]}>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={
                          isProject(idea)
                            ? `/workresult?id=${idea.projectId}${idea.groupDirectionId ? `&groupDirectionId=${idea.groupDirectionId}` : ''}`
                            : `/propose/${idea.groupCode}?page=1&categoryName=${getTitle(idea)}`
                        }
                      >
                        {getTitle(idea)}
                      </a>
                    </EventsTrackWrapperClick>
                  </div>
                  <div className="result-popup__idea-description">{idea.text}</div>

                  {idea.author && (
                    <>
                      {Boolean(!idea.author.blocked) && (
                        <div className="result-popup__idea-user">
                          <span className="ui-icon ui-icon-user"></span>
                          <span className="result-popup__idea-user-name">
                            {getName(idea.author)}
                            {renderHatIfUserNameIsHidden(idea.author, currentUser)}
                          </span>
                        </div>
                      )}
                    </>
                  )}

                  {idea.comment && <div className="result-popup__idea-comment">{idea.comment}</div>}

                  <div className="result-popup__idea-address">
                    <img src={require("../../../../../assets/map/active-pin.svg").default} />
                    <span className="result-popup__idea-address-street">{idea.address}</span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </EventsTrackParentIdContext.Provider>
    );
  }, [ideas]);

  return renderPopup;
};

export default ResultPopup;
