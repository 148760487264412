import "./ThematicProjectCard.scss";
import dayjs from "dayjs";
import classNames from "classnames";

import Button from "../../Button/Button";
import { ThematicProject } from "../ThematicProjects";
import { discussionLandingUrl } from "../../../DiscussionLanding.constants";
import Share from "../../../../../presentational/Share/Share";
import { ProjectStage, ProjectStageToClassModicator, ProjectStageToTextMap } from "./ThematicProjectCard.interface";
import { GroupDirection } from "../../../../../../types/GroupDirection.interface";

dayjs.locale("ru");

export type ThematicProjectCardProps = {
  thematicProject: ThematicProject;
  groupDirection: GroupDirection;
  activeProjectStage: ProjectStage;
  onSelect: () => void;
};

const ThematicProjectCard = ({
  thematicProject,
  groupDirection,
  activeProjectStage,
  onSelect,
}: ThematicProjectCardProps) => {
  const { imageId, description, startDate, finishDate } = thematicProject;

  return (
    <div className="thematic-project-card">
      <div className="thematic-project-card__img">
        <img src={`/uploads/get/${imageId}`} alt={""} />
      </div>
      <div className="thematic-project-card__repost-btn">
        <Share
          url={discussionLandingUrl}
          imageUrl="/images/logo-GI-VK.png"
          title="Приглашаю принять участие в проекте «Город обсуждает стандарты культурного досуга» на медиаплатформе «Город идей»!"
          backgroundColorIcon="rgba(26, 26, 26, 0.7)"
          colorIcon="white"
        />
      </div>

      <div className="thematic-project-card__info">
        <div className="thematic-project-card__date">
          {dayjs(startDate).format("DD.MM")} - {dayjs(finishDate).format("DD.MM.YYYY")}
        </div>
        <div className="thematic-project-card__title" dangerouslySetInnerHTML={{ __html: groupDirection.title }}></div>
        <div className="thematic-project-card__text" dangerouslySetInnerHTML={{ __html: description }}></div>
      </div>

      <Button
        classList={classNames(
          "thematic-project-card__button",
          `thematic-project-card__button--${ProjectStageToClassModicator[activeProjectStage]}`
        )}
        text={ProjectStageToTextMap[activeProjectStage]}
        type="filled"
        onClick={() => onSelect()}
      ></Button>
    </div>
  );
};

export default ThematicProjectCard;
