import { BaseResponse, PaginationResponse } from "../types/Common.interface";
import { Publication } from "../types/Publication.interface";
import { queryBuilder } from "../utils/url";
import { API } from "./api";

interface GetPublicationsQuery {
  page: number;
  size: number;
  sort: string;
}

const PublicationService = {
  getPublications: (query: GetPublicationsQuery) => {
    return API.get<PaginationResponse<Publication[]>>(`/api/publication/list${queryBuilder(query)}`).then(
      ({ data }) => {
        return data;
      }
    );
  },

  getPublicationById: (id) => {
    return API.get("/api/publication/getByNumber?number=" + id).then(({ data }) => {
      return data;
    });
  },

  getTags: (query?: string) => {
    return API.get<BaseResponse<string[]>>(`/api/publication/tags?query=${query || ""}`).then(({ data }) => {
      return data;
    });
  },
};

export default PublicationService;
