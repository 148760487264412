export enum ProjectBookTypes {
  FULLPAGE = "FULLPAGE",
  FLIPBOOK = "FLIPBOOK",
  EMPTY = "EMPTY",
}

export type ProjectBookType =
  | ProjectBookTypes.FULLPAGE
  | ProjectBookTypes.FLIPBOOK;

export interface ProjectBook {
  name: string;
  type: ProjectBookTypes;
  color: string;
}

export class BookAttachment {
  constructor(public id, public type, public bgColor) {}
}

export enum BookAttachmentType {
  MOBILE = "MOBILE",
  DESKTOP = "DESKTOP",
}
