import { TrainProps } from "../../components/Train";
import { trainArrival, trainLeaving } from "../../utils/constants";
import leisureLandingInfoItems, { LeisureLandingInfoItem } from "./leisureLandingInfoItems";

type LeisureLandingObjectBase = { posLeft: number; posTop: number };
type LeisureLandingObject = LeisureLandingObjectBase &
  (
    | {
        src: string;
        type: "train";
        data: Pick<TrainProps, "arrivalPoint" | "leavingPoint">;
      }
    | {
        src: string;
        srcAnimating: string;
        type: "character";
      }
    | {
        src: string;
        type: "floating" | "floating-reverse" | "default";
      }
    | {
        src?: string;
        type: "informer";
        data: Omit<LeisureLandingInfoItem, "posLeft" | "posTop">;
      }
  );

const convertInfoItem = ({ isTopLayer, posLeft, posTop, ...data }: LeisureLandingInfoItem) => ({
  type: "informer" as const,
  posLeft,
  posTop,
  data,
});

const leisureLandingObjects: LeisureLandingObject[] = [
  {
    src: require("../../../../../assets/leisure-landing/bg/z-5.png").default,
    posLeft: 0,
    posTop: 0,
    type: "default",
  },
  {
    src: require("../../../../../assets/leisure-landing/bg/z-4-train.png").default,
    posLeft: 1970,
    posTop: 528,
    type: "train",
    data: {
      arrivalPoint: trainArrival,
      leavingPoint: trainLeaving,
    },
  },
  {
    src: require("../../../../../assets/leisure-landing/bg/z-4-ship-2-reflection.png").default,
    posLeft: 387,
    posTop: 584,
    type: "floating-reverse",
  },
  {
    src: require("../../../../../assets/leisure-landing/bg/z-4-ship-2.png").default,
    posLeft: 387,
    posTop: 536,
    type: "floating",
  },
  {
    src: require("../../../../../assets/leisure-landing/bg/z-4-ship-1-reflection.png").default,
    posLeft: 582,
    posTop: 667,
    type: "floating-reverse",
  },
  {
    src: require("../../../../../assets/leisure-landing/bg/z-4-ship-1.png").default,
    posLeft: 582,
    posTop: 561,
    type: "floating",
  },
  {
    src: require("../../../../../assets/leisure-landing/bg/z-4.png").default,
    posLeft: 11641,
    posTop: 540,
    type: "default",
  },
  {
    src: require("../../../../../assets/leisure-landing/bg/z-3.png").default,
    posLeft: 0,
    posTop: 222,
    type: "default",
  },
  {
    src: require("../../../../../assets/leisure-landing/bg/z-2.png").default,
    posLeft: 1992,
    posTop: 0,
    type: "default",
  },
  ...leisureLandingInfoItems.filter(({ isTopLayer }) => !isTopLayer).map(convertInfoItem),
  {
    src: require("../../../../../assets/leisure-landing/bg/z-1-character.svg").default,
    srcAnimating: require("../../../../../assets/leisure-landing/bg/z-1-character-animating.svg").default,
    posLeft: 60,
    posTop: 573,
    type: "character",
  },
  {
    src: require("../../../../../assets/leisure-landing/bg/z-0.png").default,
    posLeft: 1738,
    posTop: 126,
    type: "default",
  },
  ...leisureLandingInfoItems.filter(({ isTopLayer }) => !!isTopLayer).map(convertInfoItem),
];

export default leisureLandingObjects;
