import { ComponentProps } from "react";
import FileSelector from "../../../../presentational/Controls/FileSelector/FileSelector";
import EventsTrackWrapperClick from "../EventsTrackWrapperClick";

const EventsTrackClickFileSelectorLabel: ComponentProps<typeof FileSelector>["ComponentLabel"] = (labelProps) => (
    <EventsTrackWrapperClick id={35}>
        <label {...labelProps} />
    </EventsTrackWrapperClick>
);

export default EventsTrackClickFileSelectorLabel;