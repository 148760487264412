export interface SittingsData {
  data: any;
}

export type SittingKey = keyof typeof SittingsKeys;

export enum SittingsKeys {
  SHOW_PROJECT_CATEGORY = "SHOW_PROJECT_CATEGORY",
  NEW_IDEAS = "NEW_IDEAS"
}

export type SittingsState = {
  [key in keyof typeof SittingsKeys]?: boolean;
};
