import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import SendRestoreEmailForm from "../Forms/SendRestoreEmailForm/SendRestoreEmailForm";
import { validateEmail } from "../../../utils/index";
import UserService from "../../../services/userService";
import { AppModal } from "../Modal/AppModal";

class SendRestoreEmailContainer extends Component<any, any> {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      isModalOpen: false,
      error: "",
    };
  }

  handleSubmit = ({ email }) => {
    if (!validateEmail(email)) {
      this.setState({
        error: "Неверный формат email (your@mail.ru)",
      });

      return;
    }

    this.setState({
      isLoading: true,
    });

    UserService.sendRestoreEmail({ email })
      .then((response) => {
        if (response.data.status === 0) {
          this.setState({
            isModalOpen: true,
          });
        } else {
          this.setState({
            error: response.data.message,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.setState({
          isLoading: false,
        });
      });
  };

  onModalClose = () => {
    this.props.history.replace("/");
  };

  render() {
    return (
      <>
        <SendRestoreEmailForm
          isLoading={this.state.isLoading}
          onSubmit={this.handleSubmit}
          error={this.state.error}
        />

        <AppModal
          center
          overlayId="modal-overlay"
          closeIconId="close-modal-icon"
          blockScroll={true}
          closeIconSize={25}
          open={!!this.state.isModalOpen}
          closeOnEsc={true}
          closeOnOverlayClick={false}
          onClose={this.onModalClose}
        >
          <div>
            На указанный вами адрес электронной почты было отправлено письмо со
            ссылкой для восстановления пароля.
          </div>
        </AppModal>
      </>
    );
  }
}

export default withRouter(SendRestoreEmailContainer);
