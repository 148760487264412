import { useEffect, useState, FC, useRef } from "react";
import Ball from "./Ball/Ball";
import "./WishesBall.scss";
import { WishesBallProps, wishType } from "./WishesBall.types";
import snowSvg from "../../../../../assets/newyear2023/games/wishBall/snow.svg";
import fullGlobe from "../../../../../assets/newyear2023/games/wishBall/fullGlobe.svg";
import useImagePreload from "../../hooks/useImagePreload";
import ResizeObserver from "resize-observer-polyfill";
import { useMediaQuery } from "react-responsive";
import { mediumTabletWidth } from "../../../../../utils/constants/widthConstants";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../types/State.interface";
import NewYear2023Service from "../../Services/newyear2023-service";
import { MosProjects } from "../../../../../types/Projects.interface";
import { ApiStatusCode } from "../../../../../types/Common.interface";
import Loader from "../../../../presentational/Loaders/Loader/Loader";
import { GameStatus } from "../../../../presentational/Game/Game.interface";

const imageSources = [fullGlobe, snowSvg];
const snowBallThreshold = 1000;
const snowThreshold = 568;

const WishesBall: FC<WishesBallProps> = ({ onInit, onGetFirstWish }) => {
  const [isShowWish, setIsShowWIsh] = useState(false);
  const [wish, setWish] = useState<wishType | null>(null);
  const [isDisplayGame, setIsDisplayGame] = useState(false);
  const user = useSelector((state: RootState) => state.user.userDetails);
  const isLoad = useImagePreload(imageSources);
  const rootRef = useRef<HTMLDivElement>();
  const snowWrapperRef = useRef<HTMLDivElement>();
  const snowRef = useRef<HTMLDivElement>();
  const isTablet = useMediaQuery({ query: `(max-width: ${mediumTabletWidth}px)` });

  const getFirstWish = async () => {
    const response = await NewYear2023Service.getNextWish();
    onGetFirstWish && onGetFirstWish();
    changeWish(response);
  };

  const checkStatusAndSetWish = async () => {
    const statusResponse = await NewYear2023Service.status([MosProjects.MP]);
    if (statusResponse.status !== ApiStatusCode.OK) return;
    const [gameRes] = statusResponse.data || [];
    if (gameRes?.status !== GameStatus.WON) return;
    const wishResponse = await NewYear2023Service.getNextWish();
    changeWish(wishResponse);
  };

  const displayGame = async () => {
    await checkStatusAndSetWish();
    setIsDisplayGame(true);
  };

  const changeWish = (wish: wishType) => {
    setWish(wish || null);
    setIsShowWIsh(true);
  };

  useEffect(() => {
    if (!snowWrapperRef.current) return;
    const el = snowWrapperRef.current;
    const resizeHandler = () => {
      const rootHeight = rootRef.current?.getBoundingClientRect()?.height;
      const snowHeight = snowRef.current?.getBoundingClientRect()?.height;
      const heightThreshold = Math.floor(rootHeight + snowHeight);
      const widthRatio = window.innerWidth / snowBallThreshold;
      const heightRatio = window.innerHeight / heightThreshold;
      const normalizeHeight = heightRatio >= 1 ? 0 : 1 - heightRatio;
      const ballScale = widthRatio > 1 ? 1 : widthRatio - normalizeHeight;
      const widthSnowRatio = window.innerWidth / snowThreshold;
      const snowTranslate = ((1 - widthSnowRatio + normalizeHeight * 3) * 100) / 5;
      const ballTranslate = snowTranslate - 2;
      el.style.setProperty("--ballScale", String(ballScale));
      el.style.setProperty("--snowScale", String(widthSnowRatio));
      el.style.setProperty("--ballTranslate", String(ballTranslate + "%"));
      el.style.setProperty("--snowTranslate", String(snowTranslate + "%"));
    };

    const observer = new ResizeObserver(resizeHandler);
    observer.observe(el);
    resizeHandler();

    return () => {
      observer.unobserve(el);
    };
  }, [isLoad, isDisplayGame]);

  const ball = (
    <div className="wishesBall__ball">
      <Ball onStartAnimation={getFirstWish} disable={isShowWish} />
    </div>
  );

  const renderGame = () => {
    return (
      <div className={"wishesBall__textWrapper"}>
        <p className={"wishesBall__title"}>Шар пожеланий</p>
        {!isShowWish && <p className={"wishesBall__subTitle"}>Нажмите на шар и получите пожелание!</p>}
        {!isTablet && ball}
      </div>
    );
  };

  useEffect(() => {
    if (!user?.loggedIn) return;
    displayGame();
  }, [user]);

  useEffect(() => {
    onInit && onInit();
  }, []);

  return (
    <div className={`wishesBall ${!isLoad || !isDisplayGame ? "wishesBall__load" : ""}`} ref={rootRef}>
      {isLoad && isDisplayGame ? (
        <>
          {renderGame()}
          <div className={"wishesBall__snowWrapper"} ref={snowWrapperRef}>
            {isTablet && ball}
            <div className={"wishesBall__snow"} ref={snowRef}>
              <div
                className={`wishesBall__wishTextWrapper ${
                  isShowWish && wish ? "wishesBall__wishTextWrapper--active" : ""
                }`}
              >
                <p className={"wishesBall__wishText"}>{wish?.text}</p>
                <p className={"wishesBall__wishSubText"}>{wish?.signature}</p>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className={"new-year-2023-gameWrapper__loader"}>
          <Loader />
        </div>
      )}
    </div>
  );
};

export default WishesBall;
