// @flow
import { fetchRequest } from "../../utils/axiosConfig";

export const PHOTOS_LOAD_HANDLE = "PHOTOS_LOAD_HANDLE";
export const PHOTOS_LOAD_SUCCESS = "PHOTOS_LOAD_SUCCESS";
export const PHOTOS_LOAD_FAIL = "PHOTOS_LOAD_FAIL";

export function loadPhotos() {
  return async function (dispatch) {
    dispatch({
      type: PHOTOS_LOAD_HANDLE,
    });

    const url = "/api/idea/item/get";

    try {
      let response = await fetchRequest(url, null, "GET");
      response = response.data;

      if (response.status === 0) {
        dispatch({
          type: PHOTOS_LOAD_SUCCESS,
          payload: response.data,
        });
      } else {
        dispatch({
          type: PHOTOS_LOAD_FAIL,
          error: true,
          payload: new Error("Ошибка получения фотографий."),
        });
      }
    } catch (err) {
      dispatch({
        type: PHOTOS_LOAD_FAIL,
        error: true,
        payload: new Error("Ошибка получения фотографий."),
      });
    }
  };
}
