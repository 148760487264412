import { loadMetrics } from "../../utils/helperFunctions";

export const ENV_LOADED = "ENV_LOADED";

export const loadEnv = () => async (dispatch) => {
  const env = await loadMetrics();

  return dispatch({
    type: ENV_LOADED,
    payload: env,
  });
};
