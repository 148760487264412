import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Link, useHistory } from "react-router-dom";
import { GorodIdeaCategory, GorodIdeaSphere } from "../../../../services/gorodService";
import { Category } from "../../../../types/Propose.interface";
import { phoneWidth } from "../../../../utils/constants/widthConstants";
import { Scrollbar } from "react-scrollbars-custom";

import Button from "../../Controls/Button/Button";
import Tooltip from "../../Controls/Tooltip/Tooltip";
import "./SphereCard.scss";

interface SphereProps {
  sphere: GorodIdeaSphere;
  onSelect?: (sphere: GorodIdeaSphere) => void;
  onSelectCategory?: (category: GorodIdeaCategory, sphere: GorodIdeaSphere) => void;
}

export function SphereCard(props: SphereProps) {
  const { sphere, onSelect } = props;
  const history = useHistory();
  const isPhone = useMediaQuery({ query: `(max-width: ${phoneWidth}px)` });

  const [foldedOut, setFoldedOut] = useState<boolean>(false);

  const handleClick = (e) => {
    if (onSelect && typeof onSelect === "function") {
      onSelect(sphere);
    }
  };

  const getClassesForList = (sphere: GorodIdeaSphere) => {
    let classlist = "spherecard__list";
    return classlist;
  };

  const getCategories = (sphere: GorodIdeaSphere) => {
    const categories = sphere.categories.filter((cat) => cat.active);
    if (!isExceedList(sphere) || !isPhone) return categories;

    return foldedOut ? categories : categories.slice(0, 3);
  };

  const isExceedList = (sphere: GorodIdeaSphere) => sphere.categories.filter((cat) => cat.active).length > 3;

  const getStyles = (sphere: GorodIdeaSphere) => {
    const styles = {} as any;
    styles.backgroundImage = `url(uploads/get/${isPhone ? sphere.mobileAttachmentId : sphere.attachmentId})`;
    return styles;
  };

  const renderList = () => (
    <ul className={getClassesForList(sphere)}>
      {getCategories(sphere).map((category: GorodIdeaCategory) => (
        <li
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            props.onSelectCategory(category, sphere);
          }}
        >
          {category.title}
        </li>
      ))}
    </ul>
  );

  return (
    <Link className="spherecard" to={`/propose/${sphere.code}`} onClick={handleClick}>
      <div className="spherecard__image" style={getStyles(sphere)}></div>

      <div className="spherecard__body">
        <div className="spherecard__heading">
          <div className="spherecard__title">
            <Tooltip text={"Посмотреть идеи"} idx={sphere.name} place={"bottom"} className={"spherecard__tooltip"}>
              <span className="spherecard__name" dangerouslySetInnerHTML={{ __html: sphere.name }} />
            </Tooltip>

            {sphere.enablePromoLabel && <span className="spherecard__label">{sphere.promoLabelText}</span>}
          </div>
        </div>
        <div className="spherecard__description">{sphere.shortDescription}</div>
        {isPhone ? renderList() : <Scrollbar className="custom-scrollbar">{renderList()}</Scrollbar>}

        {isExceedList(sphere) && (
          <div
            className="spherecard__control"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              setFoldedOut(!foldedOut);
            }}
          >
            <div>{foldedOut ? "Свернуть" : "Все темы"}</div>
            <span className={`ic ui-icon-${foldedOut ? 105 : 88}`} />
          </div>
        )}
      </div>
    </Link>
  );
}
