import { FC, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { hideInfoModal, showInfoModal } from "../../../../store/actions/LayoutActions";
import Button from "../../../presentational/Controls/Button/Button";
import "./SudirSessionError.scss";
import { displayError } from "../../../../utils";
import { inIframeNow } from "../PostMessageListener/PostMessage.helper";

const messageText = `
<div class="sudir-session-error"><h3>Ошибка авторизации.</h3></br> &nbsp; Пожалуйста, повторите попытку позже. &nbsp;В случае повторения ошибки напишите в раздел «Помощь», &nbsp;указав адрес почты или телефон, &nbsp;которые используете для входа в личный кабинет портала <a href="https://www.mos.ru/">mos.ru</a>.</div>`;

const SudirSessionError: FC = ({ children }) => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const authError = searchParams.get("authError") === "true";

    if (authError) {
      if (inIframeNow()) {
        displayError("Ошибка авторизации SUDIR", "!");
      } else {
        dispatch(
          showInfoModal(messageText, <Button type="outlined" text="Хорошо" onClick={() => dispatch(hideInfoModal())} />)
        );
      }

      history.replace(location.pathname);
    }
  }, []);

  return <>{children}</>;
};

export default SudirSessionError;
