import { FC, useState } from "react";
import "./OnlineTranslationsCard.scss";
import Button from "../../Button/Button";
import { OnlineTranslation } from "../OnlineTranslations.types";
import dayjs from "dayjs";
import Scrollbar from "react-scrollbars-custom";

type OnlineTranslationsCardProps = {
  translation: OnlineTranslation;
  onSelectTranslation: (translation: OnlineTranslation) => void;
};

const OnlineTranslationsCard: FC<OnlineTranslationsCardProps> = ({ translation, onSelectTranslation = () => {} }) => {
  const { coverId, description, startDate } = translation;

  return (
    <div className="translation-card">
      <div className="translation-card__header">
        <div className="translation-card__img">
          <img src={`/uploads/get/${coverId}`} alt={""} />
        </div>
      </div>
      <div className="translation-card__body">
        <div className="translation-card__date">{dayjs(startDate).format("DD.MM.YYYY")}</div>
        <Scrollbar className="dls-card__scroll-text discussion-landing-scroll" noDefaultStyles>
          <div className="translation-card__text" dangerouslySetInnerHTML={{ __html: description }}></div>
        </Scrollbar>
      </div>
      <div className="translation-card__footer">
        <Button onClick={() => onSelectTranslation(translation)} text="Смотреть" type="filled"></Button>
      </div>
    </div>
  );
};

export default OnlineTranslationsCard;
