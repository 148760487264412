export interface Photo {
  id: string;
  pos: number;
  title: string;
  logoId: string;
  photoInstruction: string;
  projectName?: string;
  groupName?: string;
  address?: string[];
  published?: boolean;
  updated?: any;
}

export interface PhotoState {
  photos: Photo[] | null;
  error: string;
  isFetching: boolean;
}

export enum PhotoIdeaAddressType {
  EMPTY = "EMPTY",
  DIRECTLY = "DIRECTLY",
  ANY = "ANY",
  LIST = "LIST",
  DEFAULT_LIST = "DEFAULT_LIST",
}
