import React from "react";

import { isGuid } from "../../../utils/index";

import "./ProfilePic.scss";

// type Props = {
//   imageId: String,
//   gender: String,
//   onClick: Function,
//   isRounded: Boolean,
//   showHoverEffect: Boolean,
// };

const ProfilePic = (props) => {
  const onClick = () => {
    if (!props.onClick) return;
    if (props.onClick && typeof props.onClick !== "function") {
      console.error("Please provide a callback for ProfilePic component");
      return;
    }

    props.onClick();
  };

  const getImgSrc = (imageId) => {
    if (!imageId) return;

    if (isGuid(imageId) || imageId === "userid_admin") {
      return "/uploads/get/" + props.imageId +
        "?time=" +
        Number.parseInt(new Date().getTime() / 1000 + ""); // disable cache
    } else {
      try {
        return require(`../../../assets/user/${props.imageId}.png`).default;
      } catch (e) {
        console.log(e);
      }
    }
  };

  const getClassesForImg = () => {
    let classes = "profile-pic__img";

    if (props.isRounded) classes += " profile-pic__img-rounded";

    return classes;
  };

  const getClassesForOverlay = () => {
    let classes = "profile-pic__overlay";

    if (props.isRounded) classes += " profile-pic__overlay-rounded";

    return classes;
  };

  return (
    <div className="profile-pic" onClick={onClick}>
      {props.imageId && (
        <img
          src={getImgSrc(props.imageId)}
          alt="Профиль"
          className={getClassesForImg()}
        />
      )}
      {props.showHoverEffect && (
        <div className={getClassesForOverlay()}>
          <div className="profile-pic__overlay-content">
            <div className="profile-pic__overlay-img"></div>
            <div className="profile-pic__overlay-text">
              Изменить <br /> фото
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProfilePic;
