import { User } from "../../types/User.interface";
import { Video } from "../../types/Video.interface";
import { userMock } from "./mocks/user.mock";
import { videoMock } from "./mocks/video.mock";

export class DataAdapter {
  private static mocks: Record<string, any> = {
    User: userMock,
    Video: videoMock
  };

  static toEntity<T>(data: Partial<T>, entityType: string): T {
    const mock = this.mocks[entityType];
    if (!mock) {
      throw new Error(`Mock data for entity type ${entityType} not found.`);
    }
    return { ...mock, ...data };
  }

  static toUser(data: Partial<User>): User {
    return this.toEntity<User>(data, "User");
  }

  static toVideo(data: Partial<Video>): Video {
    return this.toEntity<Video>(data, "Video");
  }
}
