import { NYLandingObjectSettings } from "../types";
import birdBullfinch from "../../../../../../assets/ny/landing/crowd/bird-bullfinch.svg";
import birdBullfinchAnimateData from "../../../../../../assets/ny/landing/crowd/bird-bullfinch-animated.lottie.json";
import birdBullfinchAnimatedMoving from "../../../../../../assets/ny/landing/crowd/bird-bullfinch-animated-moving.svg";
import birdBluetit from "../../../../../../assets/ny/landing/crowd/bird-bluetit.svg";
import birdBluetitAnimateData from "../../../../../../assets/ny/landing/crowd/bird-bluetit-animated.lottie.json";
import birdBluetitAnimatedMoving from "../../../../../../assets/ny/landing/crowd/bird-bluetit-animated-moving.svg";
import birdJay from "../../../../../../assets/ny/landing/crowd/bird-jay.svg";
import birdJayAnimateData from "../../../../../../assets/ny/landing/crowd/bird-jay-animated.lottie.json";
import birdJayAnimatedMoving from "../../../../../../assets/ny/landing/crowd/bird-jay-animated-moving.svg";

const birdBullfinchSources = {
  staticImgSrc: birdBullfinch,
  staticAnimateData: birdBullfinchAnimateData,
  moveImgSrc: birdBullfinchAnimatedMoving,
};

const birdBluetitSources = {
  staticImgSrc: birdBluetit,
  staticAnimateData: birdBluetitAnimateData,
  moveImgSrc: birdBluetitAnimatedMoving,
};

const birdJaySources = {
  staticImgSrc: birdJay,
  staticAnimateData: birdJayAnimateData,
  moveImgSrc: birdJayAnimatedMoving,
};

const nyLangingObjectsCrowdNoId: Omit<NYLandingObjectSettings, "id">[] = [
  {
    ...birdBullfinchSources,
    placementType: "bg",
    placementLayerIndex: 3,
    posLeftPercent: 1.4,
    posTopPercent: 73,
    moveDirection: "right",
    transform: "rotateY(180deg)",
  },
  {
    ...birdBluetitSources,
    placementType: "bg",
    placementLayerIndex: 3,
    posLeftPercent: 44.75,
    posTopPercent: 92.5,
    moveDirection: "right",
  },
  {
    ...birdJaySources,
    placementType: "bg",
    placementLayerIndex: 3,
    posLeftPercent: 65.7,
    posTopPercent: 14.3,
    underLayer: true,
    moveDirection: "left",
  },
  {
    ...birdBullfinchSources,
    placementType: "bg",
    placementLayerIndex: 3,
    posLeftPercent: 67.87,
    posTopPercent: 55,
    moveDirection: "left",
  },
  {
    ...birdBluetitSources,
    placementType: "bg",
    placementLayerIndex: 3,
    posLeftPercent: 73.2,
    posTopPercent: 35.8,
    moveDirection: "right",
  },
  {
    ...birdBullfinchSources,
    placementType: "bg",
    placementLayerIndex: 3,
    posLeftPercent: 83.8,
    posTopPercent: 77.6,
    moveDirection: "right",
    transform: "rotateY(180deg)",
  },

  {
    ...birdBluetitSources,
    placementType: "presentation",
    placementLayerIndex: 1,
    posLeftPercent: 13.5,
    posTopPercent: -4,
    moveDirection: "right",
    phoneSettings: {
      posLeftPercent: 35,
      posTopPercent: -10,
    },
  },
  {
    ...birdBullfinchSources,
    placementType: "presentation",
    placementLayerIndex: 3,
    posLeftPercent: 79,
    posTopPercent: 0.7,
    moveDirection: "right",
    transform: "rotateY(180deg)",
    phoneSettings: {
      posLeftPercent: 30,
      posTopPercent: -5,
      transform: "none",
      moveDirection: "left",
    },
  },
  {
    ...birdBluetitSources,
    placementType: "presentation",
    placementLayerIndex: 4,
    posLeftPercent: 13.5,
    posTopPercent: -4,
    moveDirection: "right",
    phoneSettings: {
      posLeftPercent: 35,
      posTopPercent: -10,
    },
  },
  {
    ...birdBullfinchSources,
    placementType: "presentation",
    placementLayerIndex: 7,
    posLeftPercent: 79,
    posTopPercent: 0.7,
    moveDirection: "right",
    transform: "rotateY(180deg)",
    phoneSettings: {
      posLeftPercent: 30,
      posTopPercent: -5,
      transform: "none",
      moveDirection: "left",
    },
  },
];

const nyLangingObjectsCrowd: NYLandingObjectSettings[] =
  nyLangingObjectsCrowdNoId.map((item, i) => ({ ...item, id: i + 1 }));

export default nyLangingObjectsCrowd;
