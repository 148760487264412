import { API } from "../../../../services/api";
import { BaseResponse } from "../../../../types/Common.interface";

const base = "/api/city";

export enum ActivityType {
  TRANSPORT = "TRANSPORT",
  GAME = "GAME",
  PARKS = "PARKS",
  ECOLOGY = "ECOLOGY",
  CULTURE = "CULTURE",
}

export const IdeaTypes = [ActivityType.TRANSPORT, ActivityType.ECOLOGY];
export const PhotoTypes = [ActivityType.PARKS, ActivityType.CULTURE];

interface setActivityData {
  id?: string;
  type: ActivityType;
}

export type GetActivityResponse = BaseResponse<getActivityData[]>;

interface getActivityData {
  type: ActivityType;
  exists: boolean;
}

interface SetInformerData {
  date?: string;
  type: string;
}

export interface GetInformerData {
  exists: boolean;
  type: string;
}

export type GetInformerResponse = BaseResponse<GetInformerData[]>;

const CityApiService = {
  setActivity: (data: setActivityData) => {
    return API.post(`${base}/setActivity`, data).then((data) => {
      return data?.data;
    });
  },

  getActivity: () => {
    return API.get<GetActivityResponse>(`${base}/getActivity`).then(({ data }) => {
      return data?.data;
    });
  },

  setInformer: (data: SetInformerData[]) => {
    return API.post(`${base}/setInformer`, data).then((data) => {
      return data?.data;
    });
  },

  getInformer: () => {
    return API.get<GetInformerResponse>(`${base}/getInformer`).then(({ data }) => {
      return data?.data;
    });
  },
};

export default CityApiService;
