import { FC, Fragment, useCallback, useContext, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import "./LeisureLanding.scss";
import { batch, useDispatch, useSelector } from "react-redux";
import ResizeObserver from "resize-observer-polyfill";
import { deviceType, isIOS, isMobile, useMobileOrientation } from "react-device-detect";

import {
  leisureLandingAddProgress,
  leisureLandingSetActiveIndex,
  leisureLandingSetProgress,
  leisureLandingSetScale,
} from "../../../../../store/actions/LeisureLandingActions";
import {
  selectLeisureActiveIndex,
  selectLeisureLandingForward,
  selectLeisureLandingProgress,
  selectLeisureScale,
} from "../../../../../store/reducers/leisureLanding";
import leisureLandingObjects from "./leisureLandingObjects";
import leisureLandingPoints from "./leisureLandingPoints";
import CircleCounterList from "../../components/CircleCounterList/CircleCounterList";
import Informer from "../../components/Informer/Informer";
import Footer from "../../components/Footer";
import FooterProgress from "../../components/Footer/FooterProgress";
import {
  fullHeight,
  fullWidth,
  landingSpeed,
  step,
  trainArrival,
  trainLeaving,
  trainSpeed,
} from "../../utils/constants";
import Train from "../../components/Train";
import Floating from "../../components/Floating";
import Character from "../../components/Character";
import Button from "../../components/Button";
import ProposeIdeaForm from "../../../../containers/Forms/ProposeIdeaForm/ProposeIdeaForm";
import { RootState } from "../../../../../types/State.interface";
import { AuthAction, AuthActionParam, AuthActionType, ComponentType } from "../../../../../services/sudirService";
import { AppContext } from "../../../../Root";
import SendIdeaForm from "../../../../containers/Forms/SendIdeaForm/SendIdeaForm";
import { getProjects } from "../../../../../store/actions/IdeasActions";
import { TooltipProps } from "../../../../presentational/Controls/Tooltip/Tooltip.interface";
import FinalModal from "../../components/FinalModal";
import IdeaSentModal from "../../components/IdeaSentModal";
import Platform from "../../../../../services/platform";
import { shuffle, throttle } from "../../../../../utils";
import Loading from "../../components/Loading";
import { selectIsOperating } from "../../../../../store/reducers/operability";
import usePrevious from "../../../../../hooks/usePrevious";
import LeisureHeader from "../../components/LeisureHeader/LeisureHeader";
import BasketballGame, { BasketballGameController } from "../../components/BasketballGame/BasketballGame";
import { emptyWonList } from "../../components/LeisureHeader/LeisureHeader.data";
import EventsSlider from "../../components/EventsSlider";
import leisureLandingEventsSlides from "./leisureLandingEventsSlides";
import { setTimeout } from "timers";
import ScreenStub from "../../components/ScreenStub";
import CityApiService, { ActivityType, IdeaTypes, PhotoTypes } from "../../services/city-api";
import { ApiStatusCode } from "../../../../../types/Common.interface";
import { GorodIdeaSphere } from "../../../../../services/gorodService";
import leisureLandingInfoItems from "./leisureLandingInfoItems";
import FinishInformersModal from "../../components/FinishInformersModal";

const distanceFromActivePoint = 376;
const characterObject = leisureLandingObjects.find(({ type }) => type === "character");
const isMobileDevice = Platform.isPhone() || Platform.isTablet() || Platform.isIPadOS();

const imgSources = Array.from(
  new Set(
    leisureLandingObjects
      .map(({ src, ...other }) => [
        src,
        other.type === "character" && other.srcAnimating,
        other.type === "informer" && other.data.modalSrc,
      ])
      .flat()
      .filter(Boolean)
  )
);

const emptyInfotmersActivity = leisureLandingInfoItems.reduce((acc, { code }) => {
  acc[code] = false;
  return acc;
}, {} as any);

const slides = shuffle(leisureLandingEventsSlides.slice());

const LeisureLanding: FC = () => {
  const appContext = useContext(AppContext);
  const orientation = useMobileOrientation();

  const dispatch = useDispatch();

  const isOperating = useSelector(selectIsOperating);
  const progress = useSelector(selectLeisureLandingProgress);
  const scale = useSelector(selectLeisureScale);
  const forward = useSelector(selectLeisureLandingForward);
  const user = useSelector((state: RootState) => state.user.userDetails);
  const spheres = useSelector((state: RootState) => state.gorod.spheres);
  const projects = useSelector((state: RootState) => state.ideas.projects);
  const activeIndex = useSelector(selectLeisureActiveIndex);
  const activePoint = leisureLandingPoints[activeIndex];

  const rootRef = useRef<HTMLDivElement>();
  const bgRef = useRef<HTMLDivElement>();
  const characterRef = useRef<HTMLDivElement>();

  const [showRecommendationStub, setShowRecommendationStub] = useState(false);
  const [started, setStarted] = useState(false);
  const [loadingProgress, setLoadingProgress] = useState(0);
  // флаг полной загрузки - нужен для небольшой задержки, чтобы завершилась анимация прогресс бара
  const [loaded, setLoaded] = useState(false);
  const [formOpenedType, setFormOpenedType] = useState<ActivityType>();
  const [supportFormOpened, setSupportFormOpened] = useState(false);
  const [gameOpened, setGameOpened] = useState(false);
  const [isSubmittedProposeModalOpen, setIsSubmittedProposeModalOpen] = useState(false);
  const [isSubmittedPhotoModalOpen, setIsSubmittedPhotoModalOpen] = useState(false);
  const [finishInformersModalOpen, setFinishInformersModalOpen] = useState(false);
  const [isCharacterTransition, setIsCharacterTransition] = useState(false);
  const [wonList, setWonList] = useState(emptyWonList);
  const [gameController, setGameController] = useState<BasketballGameController>(null);
  const [spheresList, setSpheresList] = useState<GorodIdeaSphere[]>();
  const [projectsList, setProjectsList] = useState<any[]>();
  const [informersActivity, setInformersActivity] = useState<Record<string, boolean>>({});
  const [autoOpenInformerIndex, setAutoOpenInformerIndex] = useState<number>();

  const informersActivityRef = useRef(informersActivity);

  useEffect(() => {
    setWonList(emptyWonList);
    setInformersActivity(emptyInfotmersActivity);
    // if (user?.loggedIn) {
    //   CityApiService.getActivity().then((res) => {
    //     const newWonList = JSON.parse(JSON.stringify(emptyWonList)).map((item) => {
    //       const apiItem = res.find((respItem) => respItem.type === item.type);
    //       if (apiItem) {
    //         item.won = apiItem.exists;
    //       }
    //       return item;
    //     });
    //     setWonList(newWonList);
    //   });

    //   CityApiService.getInformer().then((res) =>
    //     setInformersActivity(
    //       res.reduce((acc, { type, exists }) => {
    //         acc[type] = exists;
    //         return acc;
    //       }, {} as any)
    //     )
    //   );
    // } else {
    //   setWonList(emptyWonList);
    //   setInformersActivity(emptyInfotmersActivity);
    // }
  }, [user?.loggedIn]);

  // прогресс в пикселях, учитывая отступы и ширину героя. располагается по диаметральной линиии героя
  const progressPoint = useMemo(() => {
    const indentProgressWidth = characterObject.posLeft * 2 + (characterRef.current?.clientWidth || 0);
    return (fullWidth - indentProgressWidth) * progress + indentProgressWidth / 2;
  }, [progress]);
  const progressPointPrevRef = useRef<number>(progressPoint - 1);

  const paused =
    !!activePoint ||
    !!formOpenedType ||
    supportFormOpened ||
    gameOpened ||
    isSubmittedProposeModalOpen ||
    isSubmittedPhotoModalOpen ||
    finishInformersModalOpen;

  const openForm = (pointIndex: number) => {
    dispatch(leisureLandingSetActiveIndex(undefined));

    const point = leisureLandingPoints[pointIndex];
    if (point && (point.type === "idea" || point.type === "photo")) {
      if (!user?.loggedIn) {
        const thisContainerData: AuthActionParam = {
          component: ComponentType.LEISURE_LANDING_PAGE,
          type: point.type === "idea" ? AuthActionType.OPEN_PROPOSE_MODAL : AuthActionType.OPEN_IDEAS_MODAL,
          args: {
            lastProgress: progress,
            lastForward: forward,
            lastActiveIndex: activeIndex,
          },
        };

        const action = new AuthAction(thisContainerData);
        return appContext.sudirService.authWithActions([action]);
      }

      setFormOpenedType(point.wType);

      if (point.type === "idea") {
        setSpheresList(
          point.data?.sphereCodes ? spheres.filter((s) => point.data.sphereCodes.includes(s.code)) : undefined
        );
      } else {
        setProjectsList(
          point.data?.projectOrCategoryCodes
            ? projects.filter((p) => point.data.projectOrCategoryCodes.includes(p.code))
            : undefined
        );
      }
    }
  };

  const startGame = () => {
    dispatch(leisureLandingSetActiveIndex(undefined));

    if (!user?.loggedIn) {
      const thisContainerData: AuthActionParam = {
        component: ComponentType.LEISURE_LANDING_PAGE,
        type: AuthActionType.START_GAME,
        args: {
          lastProgress: progress,
          lastForward: forward,
        },
      };

      const action = new AuthAction(thisContainerData);
      return appContext.sudirService.authWithActions([action]);
    }

    setGameOpened(true);
    gameController.startGame();
  };

  const overrideTooltipPosition: TooltipProps["overridePosition"] = ({ left, top }) => ({
    left: left / scale,
    top: top / scale,
  });

  const goToPoint = (i: number) => {
    setTimeout(() => {
      const point = leisureLandingPoints[i];
      if (
        point &&
        point !== activePoint &&
        (progressPoint < point.posLeft || progressPoint > point.posLeft + distanceFromActivePoint)
      ) {
        const f = i === 0 ? true : point.posLeft > progressPoint;
        const indentProgressWidth = characterObject.posLeft * 2 + (characterRef.current?.clientWidth || 0);
        const newProgress =
          (point.posLeft - indentProgressWidth / 2 + (f ? 0 : distanceFromActivePoint)) /
          (fullWidth - indentProgressWidth);

        if (point.posLeft < trainArrival || point.posLeft > trainLeaving) {
          rootRef.current.style.setProperty("--transition-duration-train", "0s");
        }
        dispatch(leisureLandingSetProgress(newProgress, f));
        setTimeout(() => rootRef.current.style.setProperty("--transition-duration-train", `${trainSpeed / 1000}s`));
        setTimeout(() => {
          dispatch(leisureLandingSetActiveIndex(i));
        }, landingSpeed + 100);
      }
    });
  };

  const onCircleClick = (data) => {
    const i = leisureLandingPoints.findIndex(({ wType }) => wType === data.type);
    goToPoint(i);
  };

  const onInformerClick = (i: number) => {
    if (user?.loggedIn) {
      return;
    }

    const thisContainerData: AuthActionParam = {
      component: ComponentType.LEISURE_LANDING_PAGE,
      type: AuthActionType.CLICK_INFORMER,
      args: {
        lastProgress: progress,
        lastForward: forward,
        informerIndexNeedOpen: i,
      },
    };

    const action1 = new AuthAction(thisContainerData);
    return appContext.sudirService.authWithActions([action1]);
  };

  const onCharacterClick = () => {
    const i = leisureLandingPoints.findIndex((p) => {
      const left = forward ? p.posLeft : p.posLeft + distanceFromActivePoint;
      const right = p.posRight && (forward ? p.posRight - distanceFromActivePoint : p.posRight);
      return right && right >= progressPoint && left <= progressPoint;
    });
    ~i && dispatch(leisureLandingSetActiveIndex(i));
  };

  const addProgress = useCallback(
    (n: number) => {
      const indent = characterObject.posLeft * 2 + characterRef.current.clientWidth;
      const npp = progressPointPrevRef.current + n;
      const f = npp > progressPointPrevRef.current;
      for (let i = 0; i < leisureLandingPoints.length; i++) {
        const p = leisureLandingPoints[i];
        const left = f ? p.posLeft : p.posLeft + distanceFromActivePoint;
        if (
          (f && progressPointPrevRef.current < left && npp >= left) ||
          (!f && progressPointPrevRef.current > left && npp <= left && i > 0)
        ) {
          i < leisureLandingPoints.length - 1 && dispatch(leisureLandingSetActiveIndex(i));
          dispatch(leisureLandingSetProgress((left - indent / 2) / (fullWidth - indent), f));
          return;
        }
      }

      const progressStep = n / (fullWidth - indent);
      dispatch(leisureLandingAddProgress(progressStep));
    },
    [dispatch]
  );

  // старт
  useEffect(() => {
    if (loaded) {
      batch(() => {
        // пользователь и сферы подгрузились, стартуем лендинг
        setStarted(true);

        if (user?.loggedIn) {
          const actions = appContext.sudirService.getActions(ComponentType.LEISURE_LANDING_PAGE);
          if (actions.length) {
            if (user.partiallyFilled) {
              const mainContainerData: AuthActionParam = {
                component: ComponentType.MAIN,
                type: AuthActionType.REDIRECT_AFTER_REG_FORM,
                args: {
                  absolutePath: true,
                  redirectLink: appContext.sudirService.generateActionsLink(window.location.href, actions),
                },
              };
              const action1 = new AuthAction(mainContainerData);
              const link = appContext.sudirService.generateActionsLink(window.location.origin, [action1]);
              window.location.href = link;
              return;
            }

            const modalActionData = actions.find((act) =>
              [AuthActionType.OPEN_PROPOSE_MODAL, AuthActionType.OPEN_IDEAS_MODAL].includes(act.type)
            );
            if (modalActionData) {
              const { lastProgress, lastForward, lastActiveIndex } = modalActionData.args;
              dispatch(leisureLandingSetProgress(lastProgress, lastForward));
              openForm(lastActiveIndex);
              return;
            }

            const gameActiondata = actions.find((act) => act.type === AuthActionType.START_GAME);
            if (gameActiondata) {
              const { lastProgress, lastForward } = gameActiondata.args;
              dispatch(leisureLandingSetProgress(lastProgress, lastForward));
              startGame();
              return;
            }

            const loginActiondata = actions.find((act) => act.type === AuthActionType.LOGIN);
            if (loginActiondata) {
              const { lastProgress, lastForward } = loginActiondata.args;
              dispatch(leisureLandingSetProgress(lastProgress, lastForward));
              return;
            }

            const informerActionData = actions.find((act) => act.type === AuthActionType.CLICK_INFORMER);
            if (informerActionData) {
              const { lastProgress, lastForward, informerIndexNeedOpen } = informerActionData.args;
              dispatch(leisureLandingSetProgress(lastProgress, lastForward));
              setAutoOpenInformerIndex(informerIndexNeedOpen);
              return;
            }
          }
        }

        addProgress(1);
      });
    }
  }, [loaded]);

  useEffect(() => {
    if (!projects) {
      dispatch(getProjects());
    }
  }, []);

  // фикс стилей body
  useLayoutEffect(() => {
    document.body.classList.add("leisure-landing-body");
    return () => document.body.classList.remove("leisure-landing-body");
  }, []);

  // ресайз
  useEffect(() => {
    const el = rootRef.current;

    const resizeHandler = () => {
      const coef = el.clientHeight / fullHeight;
      el.style.setProperty("--bg-scale", String(coef));
      dispatch(leisureLandingSetScale(coef));
      setShowRecommendationStub(isMobile);
    };

    const observer = new ResizeObserver(resizeHandler);
    observer.observe(el);

    resizeHandler();

    return () => {
      observer.unobserve(el);
    };
  }, [dispatch]);

  // управление
  const posScreenX = useRef(0);
  useEffect(() => {
    if (!started) {
      return;
    }

    const touchstartHahdler = (e: TouchEvent) => {
      if (!characterRef.current?.contains(e.target as any)) {
        dispatch(leisureLandingSetActiveIndex(undefined));
        posScreenX.current = e.touches[0]?.clientX || 0;
      }
    };
    const touchmoveHandler = throttle((e: TouchEvent) => {
      if (!paused) {
        const x = e.touches[0]?.clientX || 0;
        const shift = posScreenX.current - x;

        addProgress(shift);

        posScreenX.current = x;
      }
    }, 30);

    const t = new Date().getTime();
    const wheelHandler = (e: WheelEvent) => {
      if (!paused) {
        addProgress(step * (e.deltaY > 0 ? 1 : -1));
      } else if (new Date().getTime() - t > 2000) {
        dispatch(leisureLandingSetActiveIndex(undefined));
      }
    };
    const keydownHandler = (e: KeyboardEvent) => {
      if ([37, 39].includes(e.keyCode)) {
        !e.repeat && dispatch(leisureLandingSetActiveIndex(undefined));
        if (!paused) {
          addProgress(step * (e.keyCode === 39 ? 1 : -1));
        }
      }
    };

    if (isMobileDevice) {
      // свайп
      document.addEventListener("touchstart", touchstartHahdler);
      document.addEventListener("touchmove", touchmoveHandler);
    } else {
      // колесо мыши/тачпад
      document.addEventListener("wheel", wheelHandler);
      // стрелка влево/вправо
      document.addEventListener("keydown", keydownHandler);
    }

    return () => {
      document.removeEventListener("touchstart", touchstartHahdler);
      document.removeEventListener("touchmove", touchmoveHandler);
      document.removeEventListener("wheel", wheelHandler);
      document.removeEventListener("keydown", keydownHandler);
    };
  }, [addProgress, dispatch, paused, started]);

  useLayoutEffect(() => {
    const t = setTimeout(() => {
      if (progress === 0 && !forward) {
        dispatch(leisureLandingSetActiveIndex(0));
        dispatch(leisureLandingSetProgress(progress, true));
      } else if (progress === 1 && forward) {
        dispatch(leisureLandingSetActiveIndex(leisureLandingPoints.length - 1));
        dispatch(leisureLandingSetProgress(progress, false));
      }
    }, landingSpeed);
    return () => clearTimeout(t);
  }, [dispatch, progress, forward]);

  // транслейты героя и фона
  useLayoutEffect(() => {
    const maxBgTranslate = (bgRef.current?.getBoundingClientRect().width - window.innerWidth) / scale;
    const heroTranslate = progressPoint - characterObject.posLeft - characterRef.current?.clientWidth / 2;
    const bgTranslate =
      heroTranslate - window.innerWidth / scale / 2 + characterObject.posLeft + characterRef.current?.clientWidth / 2;
    bgRef.current?.style.setProperty(
      "transform",
      `translateX(${-(bgTranslate < 0 ? 0 : bgTranslate > maxBgTranslate ? maxBgTranslate : bgTranslate)}px)`
    );
    characterRef.current?.style.setProperty("transform", `translateX(${heroTranslate < 0 ? 0 : heroTranslate}px)`);
  }, [progressPoint, scale]);

  useEffect(() => {
    if (started) {
      setTimeout(() => {
        rootRef.current.style.setProperty("--transition-duration-landing", `${landingSpeed / 1000}s`);
        rootRef.current.style.setProperty("--transition-duration-train", `${trainSpeed / 1000}s`);
      });
    }
  }, [started]);

  useEffect(() => {
    const transitionstartHandler = () => setIsCharacterTransition(true);
    const transitionendHandler = () => setIsCharacterTransition(false);

    const el = characterRef.current;
    el.addEventListener("transitionstart", transitionstartHandler);
    el.addEventListener("transitionend", transitionendHandler);

    return () => {
      el.removeEventListener("transitionstart", transitionstartHandler);
      el.removeEventListener("transitionend", transitionendHandler);
    };
  }, []);

  const apiSources = useMemo(() => [isOperating, projects, spheres, user], [isOperating, projects, spheres, user]);
  const apiSourcesPrev = usePrevious(apiSources);
  const totalSources = imgSources.length + apiSources.length;
  const sourcesLoadedRef = useRef(0);
  // предварительное ожидание до момента basic auth юзера на сервере, а также пока не подгрузяться данные с бека для старта
  useEffect(() => {
    const countLoaded = apiSources.filter(Boolean).length - apiSourcesPrev?.filter(Boolean).length || 0;
    if (countLoaded > 0) {
      sourcesLoadedRef.current += countLoaded;
      setLoadingProgress(sourcesLoadedRef.current / totalSources);
    }
  }, [apiSources]);
  // предварительная загрузка графики
  useEffect(() => {
    imgSources.forEach((src) => {
      const image = new Image();
      image.onload = () => {
        sourcesLoadedRef.current++;
        setLoadingProgress(sourcesLoadedRef.current / totalSources);
      };
      image.src = src;
    });
  }, []);
  // пометка о полной загрузке с задержкой для завершения анимации прогресс бара
  useEffect(() => {
    loadingProgress < 1 ? setLoaded(false) : setTimeout(() => setLoaded(true), 500);
  }, [loadingProgress]);

  useEffect(() => {
    if (started) {
      progressPointPrevRef.current = progressPoint;
    }
  }, [progressPoint, started]);

  const markAsAchived = (type, id?) => {
    const trial = wonList.find((item) => item.type === type);
    if (!trial?.won) {
      const query = {
        ...(id && { id }),
        type,
      };
      CityApiService.setActivity(query)
        .then((res) => {
          if (res.status === ApiStatusCode.OK) {
            const newWonList = wonList.map((item) => (item.type === type ? { ...item, won: true } : item));
            setWonList(newWonList);
          }
        })
        .catch(console.log);
    }
  };

  const isEnoughMemoryForObject = (object, index) => {
    const { type } = object;
    if (deviceType === "mobile" && isIOS) {
      if (type === "train" || type === "floating" || type === "floating-reverse") {
        return false;
      }
      if (type === "informer") {
        return (index + 1) % 3 === 0;
      }
    }

    return true;
  };

  const onShowInformer = (code: string) => {
    informersActivityRef.current = informersActivity;
    if (user?.loggedIn) {
      if (!informersActivity[code]) {
        setInformersActivity((prev) => ({ ...prev, [code]: true }));
        // CityApiService.setInformer([{ type: code }]).catch(() => {
        //   setInformersActivity((prev) => ({ ...prev, [code]: false }));
        // });
      }
    }
  };

  const onHideInformer = () => {
    if (informersActivityRef.current !== informersActivity) {
      const keys = Object.keys(informersActivity);
      const countActive = keys.filter((k) => informersActivity[k]).length;
      const countTotal = keys.length;
      // if (countActive === countTotal) {
      //   setFinishInformersModalOpen(true);
      // }
    }
  };

  return (
    <>
      {showRecommendationStub && <ScreenStub />}

      {!loaded && <Loading progress={loadingProgress} />}

      {IdeaTypes.includes(formOpenedType) && (
        <ProposeIdeaForm
          isOpen
          onClose={() => setFormOpenedType(undefined)}
          setIsSubmittedProposeModalOpen={setIsSubmittedProposeModalOpen}
          spheresList={spheresList}
          onSuccessSubmitData={(data) => markAsAchived(formOpenedType, data?.id)}
        />
      )}
      {PhotoTypes.includes(formOpenedType) && (
        <SendIdeaForm
          isOpened
          onClose={() => setFormOpenedType(undefined)}
          projectsList={projectsList}
          onSuccessSubmit={() => {
            setFormOpenedType(undefined);
            setIsSubmittedPhotoModalOpen(true);
          }}
          onSuccessSubmitData={(data) => markAsAchived(formOpenedType, data?.id)}
        />
      )}

      <div ref={rootRef} className={`leisure-landing ${!loaded ? "leisure-landing_hidden" : ""}`}>
        <LeisureHeader
          informersActivity={informersActivity}
          onLogoClick={() => goToPoint(0)}
          onCircleClick={onCircleClick}
        />

        <BasketballGame
          onInit={setGameController}
          onWin={() => markAsAchived(ActivityType.GAME)}
          onClose={() => setGameOpened(false)}
          onSessionBrokenOnStart={startGame}
        />

        {isSubmittedProposeModalOpen && (
          <IdeaSentModal type="idea" onClose={() => setIsSubmittedProposeModalOpen(false)} />
        )}
        {isSubmittedPhotoModalOpen && (
          <IdeaSentModal type="photo" onClose={() => setIsSubmittedPhotoModalOpen(false)} />
        )}

        {/* {finishInformersModalOpen && <FinishInformersModal onClose={() => setFinishInformersModalOpen(false)} />} */}

        <div className="leisure-landing__bg">
          <div ref={bgRef} className="leisure-landing__bg-inner">
            {leisureLandingObjects.map((object, i) => {
              if (!isEnoughMemoryForObject(object, i)) return;
              const style = { left: object.posLeft, top: object.posTop };
              const cls = "leisure-landing__object";
              switch (object.type) {
                case "character":
                  return (
                    <Fragment key={i}>
                      <div className="leisure-landing__welcome">
                        <div className="leisure-landing__welcome__wrapper">
                          <h1>Досуг в городе</h1>
                          <div>
                            Отправляйтесь в виртуальное путешествие по странице, ознакомьтесь с возможностями платформы
                            и реализованными идеями жителей в ключевых сферах жизнедеятельности города.
                          </div>
                        </div>
                        <CircleCounterList />
                      </div>
                      <FinalModal winningList={wonList} />
                      <Character
                        key={i}
                        ref={characterRef}
                        onClick={onCharacterClick}
                        popup={{
                          rootRef,
                          forceOpened: !!activePoint,
                          topContent: <span dangerouslySetInnerHTML={{ __html: activePoint?.topText }} />,
                          bottomContent: (!!activePoint?.bottomText ||
                            ["idea", "photo", "game"].includes(activePoint?.type)) && (
                            <>
                              <span dangerouslySetInnerHTML={{ __html: activePoint?.bottomText }} />
                              {(activePoint?.type === "idea" || activePoint?.type === "photo") && (
                                <Button onClick={() => openForm(activeIndex)}>
                                  {activePoint.type === "idea" ? "Предложить идею" : "Загрузить фото"}
                                </Button>
                              )}
                              {activePoint?.type === "game" && <Button onClick={() => startGame()}>Начать игру</Button>}
                            </>
                          ),
                          onOutsideMouseDown: () => dispatch(leisureLandingSetActiveIndex(undefined)),
                          size: activePoint?.popupSize,
                        }}
                        className={cls}
                        style={style}
                        position={forward ? "right" : "left"}
                        object={<img src={isCharacterTransition ? object.srcAnimating : object.src} alt="" />}
                      />
                    </Fragment>
                  );

                case "train":
                  return (
                    <Train key={i} currentPoint={progressPoint} className={cls} style={style} {...object.data}>
                      <img src={object.src} alt="" />
                    </Train>
                  );

                case "floating":
                case "floating-reverse":
                  return (
                    <Floating key={i} className={cls} style={style} isReverse={object.type === "floating-reverse"}>
                      <img src={object.src} alt="" />
                    </Floating>
                  );

                case "informer":
                  return (
                    <Informer
                      key={i}
                      className={cls}
                      style={style}
                      overrideTooltipPosition={overrideTooltipPosition}
                      tooltipIdx={`leisure-informer-${i}`}
                      onModalShow={() => onShowInformer(object.data.code)}
                      onModalHide={onHideInformer}
                      onClick={() => onInformerClick(i)}
                      canShowModal={!!user?.loggedIn && !finishInformersModalOpen}
                      autoShowModal={autoOpenInformerIndex === i}
                      modalViewed={informersActivity[object.data.code]}
                      {...object.data}
                    />
                  );

                default:
                  return (
                    <div key={i} className={`${cls} leisure-landing__object_unactive`} style={style}>
                      <img src={object.src} alt="" />
                    </div>
                  );
              }
            })}

            <EventsSlider slides={slides} />
          </div>
        </div>

        <Footer
          onSupportModalOpen={() => setSupportFormOpened(true)}
          onSupportModalHide={() => setSupportFormOpened(false)}
        >
          <FooterProgress key="FooterProgress" paused={paused} addProgress={addProgress} />
        </Footer>
      </div>
    </>
  );
};

export default LeisureLanding;
