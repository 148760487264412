import { API } from "./api";

const ProjectService = {
  getProjectInfo: (id) => {
    return API.get("/api/project/info?id=" + id).then(({ data }) => {
      return data;
    });
  },

  getArchiveProjects: () => {
    return API.get("/api/project/get?type=ARCHIVE").then(({ data }) => {
      return data;
    });
  },

  getCurrentProjects: () => {
    return API.get("/api/project/get?type=CURRENT").then(({ data }) => {
      return data;
    });
  },

  getCompletedProjects: () => {
    return API.get("/api/project/get?type=COMPLETED_LANDING").then(({ data }) => {
      return data;
    });
  },

  getAnnouncementProjects: () => {
    return API.get("/api/project/get?type=ANNOUNCEMENT").then(({ data }) => {
      return data;
    });
  },

  getActiveIDs: () => {
    return API.get("/api/project/activeProjects").then(({ data }) => {
      return data;
    });
  },

  getProjectsStats: () => {
    return API.get("/api/project/totalStats").then(({ data }) => {
      return data;
    });
  },
};

export default ProjectService;
