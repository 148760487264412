import { ActionEventEnum } from "../types/Common.interface";
import { ProjectTypeEnum } from "../types/Projects.interface";
import { UserTypeEnum } from "../types/User.interface";
import { userIsProjectParticipant } from "../utils";
import { API } from "./api";

const baseUrl = "/api/points/";

const PointsService = {
  getPoints: (type: ActionEventEnum) => {
    const query = "event=" + type;
    return API.get(`${baseUrl}getByEvent?` + query).then(({ data }) => {
      return data;
    });
  },

  getWelcomePointsMessage: async (
    user,
    projectName,
    projectType,
    projectId?
  ) => {
    if (userIsProjectParticipant(user, projectId)) {
      return `Вы уже являетесь участником проекта.`;
    }

    if (user.userType === UserTypeEnum.SHORT) {
      //let message = `Спасибо! <br/> Вы стали участником проекта «${projectName}». При наличии полной или стандартной учетной записи на портале <a target="_blank" href="https://www.mos.ru/">mos.ru</a> вы сможете получать <a target="_blank" href="/pointsRules">баллы</a> за участие в проектах.`;
      let message = `Спасибо! <br/> Вы стали участником проекта «${projectName}».`;
      if (projectType === ProjectTypeEnum.ANNOUNCEMENT) {
        message += ` Уведомление о старте проекта придет на вашу электронную почту.`;
      }
      return message;
    }

    // const { data } = await PointsService.getPoints(
    //   ActionEventEnum.ADD_TO_PROJECT
    // );

    //let message = `Спасибо! <br/> Вы стали участником проекта «${projectName}». Вам начислено <a target="_blank" href="/pointsRules">${data} баллов</a>.`;
    let message = `Спасибо! <br/> Вы стали участником проекта «${projectName}».`;
    return projectType === ProjectTypeEnum.ANNOUNCEMENT
      ? (message += ` Уведомление о старте проекта придет на вашу электронную почту.`)
      : message;
  },
};

export default PointsService;
