import React from 'react'
import './Loader.scss';

const Loader = () => {
	return (
			<div className="data-loader-wr">
				<div className="data-loader" />
			</div>
	);
};

export default Loader;
