export class LocalStorageService {
  static saveData(key: string, value: string) {
    localStorage.setItem(key, value);
  }

  static getData(key: string) {
    return localStorage.getItem(key);
  }

  static removeData(key: string) {
    localStorage.removeItem(key);
  }

  static clearData() {
    localStorage.clear();
  }

  static isStorageAvailable() {
    var test = "test";
    try {
      localStorage.setItem(test, test);
      localStorage.removeItem(test);
      return true;
    } catch (e) {
      return false;
    }
  }
}

export enum LocalStorageKeys {
  CROWD_FORCED_LOGOUT = "crowd-forced-logout",
  BIRTHDAY_PAGE_VISITED = "birthday-page-visited",
  EGIP_Access_Token = "EGIP_Access_Token",
  EGIP_Refresh_Token = "EGIP_Refresh_Token",
  NEW_YEAR_24_REDIRECTED = "ny-landing-2024-redirected",
  GenerationDraft = "generation-draft",
  CULTURE_LANDING_PAGE_VISITED = "CULTURE_LANDING_PAGE_VISITED"
}
