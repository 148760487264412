import { District } from "../../../../types/Address.interface";

export function batchDistrictsByFirstLetter(districts) {
  const batched = {};

  const sorted = districts.slice().sort((a, b) => a.name.localeCompare(b.name, "ru"));
  sorted.forEach((item: District) => {
    const firstLetter = item.name[0].toLowerCase();
    if (!batched[firstLetter]) {
      batched[firstLetter] = [];
    }
    batched[firstLetter].push(item);
  });

  return batched;
}
