// @flow
import { ProjectState } from "../../types/Projects.interface";
import {
  PROJECTS_REQUEST,
  PROJECTS_SUCCESS,
  PROJECTS_FAIL,
  BANNERS_REQUEST,
  BANNERS_SUCCESS,
  BANNERS_FAIL,
  GET_ARCHIVE_PROJECTS,
  GET_CURRENT_PROJECTS,
  GET_ANNOUNCEMENT_PROJECTS,
  GET_ACTIVE_IDS,
  GET_PROJECTS_STATS,
  GET_COMPLETED_PROJECTS,
} from "../actions/ProjectActions";
import { Banner } from "../../types/Banner.interface";

const initialState = {
  archive: null,
  completed: null,
  announcement: null,
  current: null,
  activeIDs: null,
  stats: null,
};

export function projectReducer(state: ProjectState = initialState, action) {
  switch (action.type) {
    case GET_ARCHIVE_PROJECTS:
      return { ...state, archive: action.payload };

    case GET_COMPLETED_PROJECTS:
      return { ...state, completed: action.payload };

    case GET_CURRENT_PROJECTS:
      return { ...state, current: action.payload };

    case GET_ANNOUNCEMENT_PROJECTS:
      return { ...state, announcement: action.payload };

    case GET_ACTIVE_IDS:
      return { ...state, activeIDs: action.payload };

    case GET_PROJECTS_STATS:
      return { ...state, stats: action.payload };

    default:
      return state;
  }
}

export interface BannersState {
  banners: Banner[] | null;
  error: string;
  isFetching: boolean;
}

const initialBannersState = {
  banners: null,
  error: "",
  isFetching: false,
};

export function bannersReducer(state: BannersState = initialBannersState, action) {
  switch (action.type) {
    case BANNERS_REQUEST:
      return { ...state, isFetching: true, error: "" };

    case BANNERS_SUCCESS:
      return { ...state, isFetching: false, banners: action.payload };

    case BANNERS_FAIL:
      return { ...state, isFetching: false, error: action.payload.message };

    default:
      return state;
  }
}
