import { Component } from "react";
import { connect } from "react-redux";

import Publications from "../../pages/Publications/PublicationsPage";
import PublicationDetails from "../../pages/PublicationDetails/PublicationDetailsPage";
import FadeIn from "../../presentational/FadeIn/FadeIn";
import { getPublicationsTags } from "../../../store/actions/PublicationsActions";
import FourColumnLoader from "../../presentational/Loaders/FourColumnLoader/FourColumnLoader";
import { setAppColor } from "../../../store/actions/LayoutActions";
import { AppColorsEnum } from "../../App.interface";

class PublicationsContainer extends Component<any, any> {
  constructor(props) {
    super(props);

    this.state = {
      tags: [],
      publicationById: null,
      isPublicationByIdLoaded: false,
    };

    this.loadData();
  }

  componentDidMount(): void {
    this.props.setAppColor(AppColorsEnum.GRAY);
  }

  loadData = async () => {
    // !this.props.publications && this.props.getPublications();
    !this.props.tags && this.props.getTags();
  };

  isDataLoaded = () => {
    // return this.props.publications     &&
    return !!this.props.tags;
  };

  render() {
    return (
      <>
        {this.isDataLoaded() ? (
          <FadeIn>
            {this.props.match.params.id ? (
              <PublicationDetails />
            ) : (
              <Publications
                tags={this.props.tags}
                selectedTag={this.props.selectedTag}
                screenWidth={this.props.screenWidth}
              />
            )}
          </FadeIn>
        ) : (
          <FourColumnLoader />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    publications: state.publications.list,
    tags: state.publications.tags,
    selectedTag: state.publications.selectedTag,
    screenWidth: state.globalEvents.width,
  };
};

const mapDispatchToProps = (dispatch) => ({
  // getPublications: () => dispatch(getPublications({ page: 0, size: 18 })),
  getTags: () => dispatch(getPublicationsTags()),
  setAppColor: (color) => dispatch(setAppColor(color)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PublicationsContainer);
