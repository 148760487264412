import React, { useEffect, useState } from "react";
import FreeScrollBar from "react-free-scrollbar";

import Platform from "../../../../../../services/platform";

import "./CongratulationCard.scss";

export interface CongratulationCardProps {
  avatar: string;
  firstName: string;
  lastName: string;
  text: string;
}

const CongratulationCard = ({
  avatar,
  firstName,
  lastName,
  text,
}: CongratulationCardProps) => {
  return (
    <div className="congratulation-card">
      <div className="congratulation-card__header">
        <div className="congratulation-card__avatar">
          <img src={avatar} alt={firstName} />
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="146"
            height="145"
            viewBox="0 0 146 145"
            fill="none"
          >
            <path d="M0.666001 84.5364C-4.16608 44.3102 18.4364 19.4424 31.644 12.029C75.8575 -16.4968 124.126 12.0132 139.83 32.5614C155.534 53.1097 139.83 129.017 108.905 141.346C77.9792 153.675 6.7061 134.819 0.666001 84.5364Z" />
          </svg>
        </div>
        <div className="congratulation-card__name">
          <p>{firstName}</p>
          <p>{lastName}</p>
        </div>
      </div>
      <div className="congratulation-card__body">
        {Platform.isIOs() ? (
          <FreeScrollBar>
            <div style={{ paddingRight: "35px" }}>
              {text}
            </div>
          </FreeScrollBar>
        ) : (
          <>{text}</>
        )}
      </div>
    </div>
  );
};

export default CongratulationCard;
