// @flow
import { GET_SCREEN_WIDTH, GET_SCROLL, SET_IS_SCROLLING } from "../actions/GlobalEventsActions";

export interface GlobalEventsState {
  width: number;
  scroll: number;
  isScrolling: boolean;
}

const initialState = {
  width: 0,
  scroll: 0,
  isScrolling: false
};

export function globalEventsReducer(
  state: GlobalEventsState = initialState,
  action
) {
  switch (action.type) {
    case GET_SCREEN_WIDTH:
      return {
        ...state,
        width: action.payload,
      };

    case GET_SCROLL:
      return {
        ...state,
        scroll: action.payload,
      };

    case SET_IS_SCROLLING:
        return {
          ...state,
          isScrolling: action.payload,
    };

    default:
      return state;
  }
}
