import React, { useEffect, useState } from "react";
import { User } from "../../../../types/User.interface";
import { getAvatarSrc, isGuid } from "../../../../utils";
import { getQuarterString } from "../../../../utils/dates";
import { useMediaQuery } from "react-responsive";
import { phoneWidth, tabletWidth } from "../../../../utils/constants/widthConstants";

import "./InProgressCard.scss";
import { nameIfHidden } from "../../../../utils/user.utils";

export interface IInProgress {
  text: string;
  author: User;
  description: string;
  date: string;
  deadline: string;
}

export interface InProgressCardProps {
  card: IInProgress;
  onClick: (card) => void;
}

const InProgressCard = ({ card, onClick }: InProgressCardProps) => {
  const isDesktop = useMediaQuery({ query: `(min-width: ${tabletWidth}px)` });
  const isTablet = useMediaQuery({
    query: `(max-width: ${tabletWidth}px) and (min-width: ${phoneWidth}px)`,
  });
  const isPhone = useMediaQuery({ query: `(max-width: ${phoneWidth}px)` });

  let maxTextLines = card.author ? "7" : card.deadline ? "8" : "9";
  if (isTablet) {
    maxTextLines = card.author ? "5" : card.deadline ? "6" : "7";
  } else if (isPhone) {
    maxTextLines = card.author || card.deadline ? "8" : "9";
  }

  const getName = () => card.author.deletedSudir ? nameIfHidden : card.author.firstName + " " + card.author.lastName;

  return (
    <div className="inprogress-card" onClick={() => onClick && onClick(card)}>
      <div className="inprogress-card__head">
        <div className="inprogress-card__left">
          {card.author && (
            <div className="inprogress-card__avatar">
              <img src={getAvatarSrc(card.author)} />
            </div>
          )}
          {card.author && <div className="inprogress-card__name">{getName()}</div>}
        </div>
        <div className="inprogress-card__right">
          {card.deadline && (
            <React.Fragment>
              Реализация: <span>{getQuarterString(card.deadline)}</span>
            </React.Fragment>
          )}
        </div>
      </div>
      <div className="inprogress-card__body">
        <div
          className="inprogress-card__text"
          style={{ WebkitLineClamp: maxTextLines }}
          dangerouslySetInnerHTML={{ __html: card.text }}
        ></div>
        {card.author && <div className="inprogress-card__name-phone">{getName()}</div>}
      </div>
    </div>
  );
};

export default InProgressCard;
