import { NodeSelectorModalProps } from "./NodeSelectorModal.interface";
import { AppModal } from "../../../../containers/Modal/AppModal";
import "./NodeSelectorModal.scss";
import { GroupDirectionNode } from "../../../../../types/GroupDirection.interface";

const NodeSelectorModal = <T extends GroupDirectionNode>({
  open,
  onClose,
  title,
  nodes,
  onChoose,
}: NodeSelectorModalProps<T>) => {
  return (
    <AppModal
      open={open}
      onClose={onClose}
      blockScroll
      center
      classNames={{ modal: "node-selector__modal" }}
      overlayId="modal-overlay"
      closeIconId="close-modal-icon"
      closeIconSize={25}
      closeOnEsc
      closeOnOverlayClick={false}
    >
      <div className="node-selector">
        <p className="node-selector__title">{title}</p>

        <ul className="node-selector__list">
          {nodes.map((node) => (
            <li key={node.id} onClick={() => onChoose(node)}>
              {node.title}
            </li>
          ))}
        </ul>
      </div>
    </AppModal>
  );
};

export default NodeSelectorModal;
