import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import ProjectService from "../../../services/projectService";
import { lessenPage, widenPage } from "../../../store/actions/LayoutActions";
import { SUCCESS } from "../../../types/statusCodes";

import BookView from "../../presentational/BookView/BookView";
import Loader from "../../presentational/Loaders/Loader/Loader";
import {
  BookAttachment,
  BookAttachmentType,
  ProjectBookTypes,
} from "./BookPresentation.interface";
import "./BookPresentation.scss";

const BookPresentationPage = (props: any) => {
  const params = useParams();
  const dispatch = useDispatch();

  const [desktopAttachments, setDesktopAttachments] = useState([]);
  const [mobileAttachments, setMobileAttachments] = useState([]);
  const [bookType, setBookType] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);

  let originPadding;

  useEffect(() => {
    dispatch(widenPage());
    const wrapper = document.querySelector(".wrapper ") as HTMLElement;
    const header = document.querySelector(".cr-header") as HTMLElement;
    const footer = document.querySelector(".footer") as HTMLElement;
    const line = document.querySelector(".news-line") as HTMLElement;
    if (wrapper) {
      originPadding = wrapper.style.padding;
      wrapper.style.padding = "0";
    }
    if (header) {
      header.style.display = "none";
    }
    if (footer) {
      footer.style.display = "none";
    }
    if (line) {
      line.style.display = "none";
    }

    loadData();

    return () => {
      dispatch(lessenPage());

      if (wrapper) {
        wrapper.style.padding = originPadding;
      }
      if (header) {
        header.style.display = "block";
      }
      if (footer) {
        footer.style.display = "flex";
      }
      if (line) {
        line.style.display = "block";
      }
    };
  }, []);

  const loadData = () => {
    ProjectService.getProjectInfo(getProjectName())
      .then((response) => {
        const { data, status } = response;
        if (status === SUCCESS) {
          const slider = data[0].slider;
          if (slider) {
            setBookType(ProjectBookTypes.FULLPAGE);
            setMobileAttachments([
              new BookAttachment(
                slider.mobileAttachmentId,
                BookAttachmentType.MOBILE,
                null
              ),
            ]);
            setDesktopAttachments(
              slider.pcItems.map(
                (s) =>
                  new BookAttachment(
                    s.attachmentId,
                    BookAttachmentType.DESKTOP,
                    s.backgroundColor
                  )
              )
            );
          }
        }
      })
      .finally(() => {
        setIsLoaded(true);
      })
      .catch(console.log);
  };

  const getProjectName = () => params.project;

  return (
    <div className="book-presentation">
      {isLoaded ? (
        <BookView
          type={bookType}
          project={getProjectName()}
          desktopImages={desktopAttachments}
          mobileImages={mobileAttachments}
        />
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default BookPresentationPage;
