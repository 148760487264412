// @flow

import * as React from "react";
import { NavLink } from "react-router-dom";

// type Props = {
//     to: string,
//     className?: string,
//     children?: React.Node,
// 	onLinkClick: Function
// };

const FilterLink = (props) => {
  const { onLinkClick, to, children, ...otherProps } = props;
  const handleClick = () => {
    if (onLinkClick) {
      onLinkClick();
    }
  };

  return (
    <NavLink
      exact
      to={`/${to}`}
      activeStyle={{
        textDecoration: "none",
        color: "black",
      }}
      onClick={handleClick}
      {...otherProps}
    >
      {children}
    </NavLink>
  );
};

export default FilterLink;
