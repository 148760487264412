import React, { useEffect, useState, useCallback } from "react";
import "./Timer.scss";

interface TimerProps {
  initialMinutes: number;
  isStopTimer?: boolean;
  onTimeExpire: () => void;
  className?: string;
}

const Timer = React.memo(({ initialMinutes, onTimeExpire, isStopTimer, className = "" }: TimerProps) => {
  const [time, setTime] = useState({ minutes: initialMinutes, seconds: 0 });
  const [startHideTimestamp, setStartHideTimestamp] = useState<number | null>(null);

  const tick = () => {
    setTime(prevTime => {
      if (prevTime.seconds > 0) {
        return { ...prevTime, seconds: prevTime.seconds - 1 };
      }
      if (prevTime.minutes > 0) {
        return { minutes: prevTime.minutes - 1, seconds: 59 };
      }
      return prevTime;
    });
  };

  useEffect(() => {
    if (isStopTimer || (time.minutes === 0 && time.seconds === 0)) {
      return;
    }
    const intervalId = setInterval(tick, 1000);
    return () => clearInterval(intervalId);
  }, [time, isStopTimer]);

  useEffect(() => {
    if (time.minutes <= 0 && time.seconds <= 0) {
      onTimeExpire();
    }
  }, [time, onTimeExpire]);

  const handleVisibilityChange = useCallback(() => {
    if (document.hidden) {
      setStartHideTimestamp(Date.now());
    } else if (startHideTimestamp) {
      const hiddenDuration = Math.floor((Date.now() - startHideTimestamp) / 1000);
      setStartHideTimestamp(null);
      setTime(prevTime => {
        const totalSeconds = prevTime.minutes * 60 + prevTime.seconds - hiddenDuration;
        return { minutes: Math.floor(totalSeconds / 60), seconds: totalSeconds % 60 };
      });
    }
  }, [startHideTimestamp]);

  useEffect(() => {
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => document.removeEventListener("visibilitychange", handleVisibilityChange);
  }, [handleVisibilityChange]);

  return (
    <div className={"timer " + className}>
      {time.minutes}:{time.seconds < 10 ? `0${time.seconds}` : time.seconds}
    </div>
  );
});

export default Timer;