// @flow
import { News, NewsState } from "../../types/News.interface";
import {
  GET_NEWS_REQUEST,
  GET_NEWS_SUCCESS,
  GET_NEWS_FAIL,
  LOAD_NEWS_TAGS_HANDLE,
  LOAD_NEWS_TAGS_SUCCESS,
  LOAD_NEWS_TAGS_FAIL,
} from "../actions/NewsActions";

const initialState = {
  news: null,
  tags: null,
  selectedTag: null,
  errorFetchingNews: "",
  isFetchingNewsTags: false,
  isFetchingNews: false,
};

export function newsReducer(state: NewsState = initialState, action) {
  switch (action.type) {
    case GET_NEWS_REQUEST:
      return {
        ...state,
        isFetchingNews: true,
        errorFetchingNews: "",
      };

    case LOAD_NEWS_TAGS_HANDLE:
      return {
        ...state,
        isFetchingNewsTags: true,
        errorFetchingNewsTags: "",
      };

    case GET_NEWS_SUCCESS:
      return {
        ...state,
        isFetchingNews: false,
        news: action.payload,
      };

    case LOAD_NEWS_TAGS_SUCCESS: {
      return {
        ...state,
        isFetchingNewsTags: false,
        tags: action.payload,
      };
    }

    case GET_NEWS_FAIL:
      return {
        ...state,
        isFetchingNews: false,
        errorFetchingNews: action.payload.message,
      };

    case LOAD_NEWS_TAGS_FAIL:
      return {
        ...state,
        isFetchingNewsTags: false,
        errorFetchingNewsTags: action.payload.message,
      };

    case "SELECT_NEWS_TAG":
      return {
        ...state,
        selectedTag: action.payload.tag,
      };

    default:
      return state;
  }
}
