import {
  ADDRESS_REGIONS_REQUEST,
  ADDRESS_REGIONS_SUCCESS,
  ADDRESS_REGIONS_FAIL,
  ADDRESS_DISTRICTS_REQUEST,
  ADDRESS_DISTRICTS_SUCCESS,
  ADDRESS_DISTRICTS_FAIL,
} from "../actions/AddressActions";
import { AddressState } from "../../types/Address.interface";

const initialState = {
  regions: null,
  districts: null,
  isFetching: false,
  error: "",
};

export function addressReducer(state: AddressState = initialState, action) {
  switch (action.type) {
    case ADDRESS_REGIONS_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: "",
      };

    case ADDRESS_DISTRICTS_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: "",
      };

    case ADDRESS_REGIONS_SUCCESS:
      return {
        ...state,
        isFetch: false,
        regions: action.payload,
      };

    case ADDRESS_DISTRICTS_SUCCESS:
      return {
        ...state,
        isFetch: false,
        districts: action.payload,
      };

    case ADDRESS_REGIONS_FAIL:
      return {
        ...state,
        isFetching: false,
        error: action.payload.message,
      };

    case ADDRESS_DISTRICTS_FAIL:
      return {
        ...state,
        isFetching: false,
        error: action.payload.message,
      };

    default:
      return state;
  }
}
