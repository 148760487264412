import { useContext, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import type SwiperCore from "swiper";
import ResizeObserver from "resize-observer-polyfill";

import Footer from "../../Components/Footer/Footer";
import Header from "../../Components/Header/Header";
import "./MainPage.scss";
import { debounce } from "../../../../../utils";
import slidesObjects, { slideKeys, SlideObject } from "../../Utils/slidesObjects";
import {
  baseHeight,
  baseWidth,
  GamesTypes,
  SlideObjectTypes,
  SlideTypes,
  ProjectsLink,
  maxSliderRatioCoef,
  squareTabletRatioCoef,
  squarePhoneRatioCoef,
  squarePhoneAddHeightCoef,
  AllProjectsGames,
  WinnableProjectsGames,
} from "../../Utils/constants";
import { keys, values } from "../../../../../utils/object";
import Interactive from "../../Components/Interactive/Interactive";
import Garland from "../../Components/Garland/Garland";
import ModalWelcome from "../../Components/ModalWelcome/ModalWelcome";
import ModalFact from "../../Components/ModalFact/ModalFact";
import { isMobile } from "react-device-detect";
import ScreenStub from "../../Components/ScreenStub/ScreenStub";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../types/State.interface";
import { AuthAction, AuthActionParam, AuthActionType, ComponentType } from "../../../../../services/sudirService";
import { AppContext } from "../../../../Root";
import useUpdateEffect from "../../../../../hooks/useUpdateEffect";
import NewYear2023Service, { InformerSubType } from "../../Services/newyear2023-service";
import { ApiStatusCode } from "../../../../../types/Common.interface";
import Tooltip from "../../Components/Tooltip/Tooltip";
import { TooltipProps } from "../../../../presentational/Controls/Tooltip/Tooltip.interface";
import MazeGame from "../../Components/MazeGame/MazeGame";
import MemoryGame from "../../Components/MemoryGame/MemoryGame";
import WishesBall from "../../Components/WishesBall/WishesBall";
import SudokuGame from "../../Components/SudokuGame/SudokuGame";
import PuzzleGame from "../../Components/PuzzleGame/PuzzleGame";
import ModalGame from "../../Components/ModalGame/ModalGame";
import QuizGame from "../../Components/QuizGame/QuizGame";
import { selectIsOperating } from "../../../../../store/reducers/operability";
import usePrevious from "../../../../../hooks/usePrevious";
import ScreenLoader from "../../Components/ScreenLoader/ScreenLoader";
import ModalFactWin from "../../Components/ModalFactWin/ModalFactWin";
import { MosProjects } from "../../../../../types/Projects.interface";
import { GameStatus } from "../../../../presentational/Game/Game.interface";
import { useMediaQuery } from "react-responsive";
import AfterRegistrationForm from "../../../../containers/Forms/AfterRegistrationForm/AfterRegistrationForm";
import { getUser, logout } from "../../../../../store/actions/UserActions";
import { useHistory } from "react-router-dom";
import ResendEmailModal from "../../../../containers/Modals/ResendEmailModal/ResendEmailModal";
import { hideInfoModal } from "../../../../../store/actions/LayoutActions";
import ChristmasBulbs from "../../Components/ChristmasBulbs/ChristmasBulbs";

const slidesObjectsValues = values(slidesObjects).flat();
const allFactIds = slidesObjectsValues
  .filter(({ type }) => type === SlideObjectTypes.FACT)
  .map((fact: any) => fact.id as string);

const imgSources = slidesObjectsValues
  .filter((item): item is Extract<SlideObject, { type: SlideObjectTypes.BG }> => item.type === SlideObjectTypes.BG)
  .map(({ src }) => src);

const countAllGames = keys(WinnableProjectsGames).length;

const MainPage = () => {
  const isPortrait = useMediaQuery({ query: "(orientation:portrait)" });
  const appContext = useContext(AppContext);
  const dispatch = useDispatch();
  const history = useHistory();
  const isOperating = useSelector(selectIsOperating);
  const user = useSelector((state: RootState) => state.user.userDetails);
  const rootRef = useRef<HTMLDivElement>();
  const innerRef = useRef<HTMLDivElement>();
  const afterAuthProcessedRef = useRef(false);
  const [afterRegistrationFormOpen, setAfterRegistrationFormOpen] = useState(false);
  const [resendEmailModalOpen, setResendEmailModal] = useState(false);
  const [loadingProgress, setLoadingProgress] = useState(0);
  const [viewportScaled, setViewportScaled] = useState(false);
  const [swiper, setSwiper] = useState<SwiperCore>();
  const [slideIndex, setSlideIndex] = useState(0);
  const [factObjectsFound, setFactObjectsFound] = useState<Record<string, boolean>>({});
  const [gameWins, setGameWins] = useState<Partial<Record<GamesTypes, boolean>>>({});
  const [gameOpened, setGameOpened] = useState<GamesTypes>();
  const [modalFactWinOpened, setModalFactWinOpened] = useState(false);
  const [factView, setFactView] = useState<Extract<SlideObject, { type: SlideObjectTypes.FACT }>["id"]>();
  const [screenLoaderMounted, setScreenLoaderMounted] = useState(true);
  const [canGarlandGlow, setCanGarlandGlow] = useState(true);
  const [logoLink, setLogoLink] = useState("");

  const loaded = !(loadingProgress < 1);
  const isDisableSwiper =
    !!gameOpened ||
    !!factView ||
    modalFactWinOpened ||
    viewportScaled ||
    afterRegistrationFormOpen ||
    resendEmailModalOpen;

  const countFactObjectsFound = useMemo(() => {
    return allFactIds.reduce((count, factId) => (factObjectsFound[factId] ? count + 1 : count), 0);
  }, [factObjectsFound]);

  const countGameWins = useMemo(
    () => values(WinnableProjectsGames).reduce((count, curr) => (gameWins[curr] ? count + 1 : count), 0),
    [gameWins]
  );

  const openFact = (factId: typeof factView) => {
    if (!gameOpened) {
      if (user?.loggedIn) {
        setFactView(factId);
      } else {
        const thisContainerData: AuthActionParam = {
          component: ComponentType.NY_2023_PAGE,
          type: AuthActionType.LOGIN,
          args: {
            lastSlideIndex: swiper.realIndex,
            factId,
          },
        };

        const action1 = new AuthAction(thisContainerData);
        return appContext.sudirService.authWithActions([action1]);
      }
    }
  };

  const openGame = (gameId: GamesTypes) => {
    if (user?.loggedIn) {
      setGameOpened(gameId);
    } else {
      const thisContainerData: AuthActionParam = {
        component: ComponentType.NY_2023_PAGE,
        type: AuthActionType.LOGIN,
        args: {
          lastSlideIndex: swiper.realIndex,
          gameId,
        },
      };

      const action1 = new AuthAction(thisContainerData);
      return appContext.sudirService.authWithActions([action1]);
    }
  };

  const onWinGame = (gameType: GamesTypes) => {
    setGameWins((prev) => ({ ...prev, [gameType]: true }));
  };

  const overrideTooltipPosition: TooltipProps["overridePosition"] = (_, __, target) => {
    const { left, bottom, width } = (target as HTMLElement).getBoundingClientRect();
    return {
      left: left + width / 2,
      top: bottom,
    };
  };

  // фикс стилей body
  useLayoutEffect(() => {
    document.body.classList.add("overflow-hidden");
    return () => document.body.classList.remove("overflow-hidden");
  }, []);

  // смена темы
  useLayoutEffect(() => {
    rootRef.current.setAttribute("data-theme", slideKeys[slideIndex] || "");
  }, [slideIndex]);

  // ресайз вьюпорта
  useEffect(() => {
    if (isMobile) {
      const handler = () => setViewportScaled(window.visualViewport.scale > 1);
      window.visualViewport.addEventListener("resize", handler);
      return () => window.visualViewport.removeEventListener("resize", handler);
    }
  }, []);

  // ресайз
  useEffect(() => {
    const el = rootRef.current;

    const resizeHandler = () => {
      const sliderHeight = el.clientHeight - (el.querySelector("footer")?.clientHeight || 0);
      const sliderRatioCoef = el.clientWidth / sliderHeight;
      const ratioCoef = el.clientWidth / el.clientHeight;
      const sliderWidth = sliderRatioCoef > maxSliderRatioCoef ? sliderHeight * maxSliderRatioCoef : el.clientWidth;
      const widthRatio = sliderWidth / baseWidth;
      const heightRatio =
        (ratioCoef <= squareTabletRatioCoef
          ? sliderHeight
          : ratioCoef <= squarePhoneRatioCoef
          ? el.clientHeight - el.clientHeight * squarePhoneAddHeightCoef
          : el.clientHeight) / baseHeight;
      el.style.setProperty("--scale", String(Math.max(widthRatio, heightRatio)));
      if (sliderWidth < el.clientWidth) {
        innerRef.current?.style.setProperty("width", `${sliderWidth}px`);
      } else {
        innerRef.current?.style.removeProperty("width");
      }
      swiper?.update();
    };

    const observer = new ResizeObserver(resizeHandler);
    observer.observe(el);

    resizeHandler();

    return () => {
      observer.unobserve(el);
    };
  }, [swiper]);

  // after auth
  useEffect(() => {
    if (loaded && swiper) {
      if (!afterAuthProcessedRef.current) {
        afterAuthProcessedRef.current = true;

        if (user.loggedIn) {
          const actions = appContext.sudirService.getActions(ComponentType.NY_2023_PAGE);
          if (actions.length) {
            if (user.partiallyFilled) {
              setAfterRegistrationFormOpen(true);
            } else if (!user.emailVerified) {
              setResendEmailModal(true);
            }

            const loginActiondata = actions.find((act) => act.type === AuthActionType.LOGIN);
            if (loginActiondata) {
              const { lastSlideIndex, gameId, factId } = loginActiondata.args;
              swiper.slideTo(lastSlideIndex || 0);
              if (gameId) {
                openGame(gameId);
              } else if (factId) {
                openFact(factId);
              }
              return;
            }
          }
        }
      }
    }
  }, [loaded, swiper]);

  // получить данные по активностям и играм
  useEffect(() => {
    if (user?.loggedIn) {
      NewYear2023Service.getInformer(InformerSubType.NEW_YEAR_2024)
        .then((data) => {
          setFactObjectsFound(
            data.reduce((prev, curr) => {
              return {
                ...prev,
                [curr.type]: curr.exists,
              };
            }, {})
          );
        })
        .catch(console.error);

      NewYear2023Service.status(values(MosProjects))
        .then(({ data }) => {
          setGameWins(
            data.reduce(
              (prev, curr) => ({
                ...prev,
                [AllProjectsGames[curr.type]]: curr.status === GameStatus.WON,
              }),
              {}
            )
          );
        })
        .catch(console.error);
    }
  }, [user?.loggedIn]);

  // сохранить факт
  useEffect(() => {
    if (factView && !factObjectsFound[factView]) {
      setFactObjectsFound((prev) => ({ ...prev, [factView]: true }));
      NewYear2023Service.setInformer([
        {
          subtype: InformerSubType.NEW_YEAR_2024,
          type: factView,
        },
      ])
        .then((data) => {
          if (data.status !== ApiStatusCode.OK) {
            throw new Error(data.message);
          }

          // был найден последний факт
          if (countFactObjectsFound + 1 === allFactIds.length) {
            // setModalFactWinOpened(true);
          }
        })
        .catch((e) => {
          setFactObjectsFound((prev) => ({ ...prev, [factView]: false }));
          console.error(e);
        });
    }
  }, [factView]);

  useEffect(() => {
    if (swiper) {
      isDisableSwiper ? swiper.disable() : swiper.enable();

      if (!isDisableSwiper) {
        const keyupHandler = (e: KeyboardEvent) => {
          if (e.keyCode === 38) {
            swiper.slidePrev();
          } else if (e.keyCode === 40) {
            swiper.slideNext();
          }
        };

        const wheelHandler = debounce((e: WheelEvent) => {
          if (e.deltaY < 0) {
            swiper.slidePrev();
          } else if (e.deltaY > 0) {
            swiper.slideNext();
          }
        }, 200);

        // стрелка вверх/вниз
        document.addEventListener("keyup", keyupHandler);
        // колесо мыши/тачпад
        document.addEventListener("wheel", wheelHandler);

        return () => {
          document.removeEventListener("keyup", keyupHandler);
          document.removeEventListener("wheel", wheelHandler);
        };
      }
    }
  }, [swiper, isDisableSwiper]);

  useUpdateEffect(() => {
    if (!user?.loggedIn) {
      setFactView(undefined);
      setGameOpened(undefined);
      setFactObjectsFound({});
      setGameWins({});
    }
  }, [user?.loggedIn]);

  // PRELOAD

  const apiSources = useMemo(() => [isOperating, user], [isOperating, user]);
  const apiSourcesPrev = usePrevious(apiSources);
  const totalSourcesRef = useRef(imgSources.length + apiSources.length);
  const sourcesLoadedRef = useRef(apiSources.filter(Boolean).length);
  // предварительное ожидание до момента basic auth юзера на сервере, а также пока не подгрузяться данные с бека для старта
  useEffect(() => {
    const countLoaded = apiSources.filter(Boolean).length - apiSourcesPrev?.filter(Boolean).length || 0;
    if (countLoaded > 0) {
      sourcesLoadedRef.current += countLoaded;
      setLoadingProgress(sourcesLoadedRef.current / totalSourcesRef.current);
    }
  }, [apiSources]);
  // предварительная загрузка графики
  useEffect(() => {
    imgSources.forEach((src) => {
      const image = new Image();
      image.onload = () => {
        sourcesLoadedRef.current++;
        setLoadingProgress(sourcesLoadedRef.current / totalSourcesRef.current);
      };
      image.src = src;
    });
  }, []);
  useEffect(() => {
    if (loaded) {
      const tid = setTimeout(() => setScreenLoaderMounted(false), 1000);
      return () => clearTimeout(tid);
    }
  }, [loaded]);

  // RENDER

  const renderItem = (item: typeof slidesObjects extends Record<SlideTypes, (infer R)[]> ? R : never) => {
    switch (item.type) {
      case SlideObjectTypes.BG:
        return <img src={item.src} alt="" />;

      case SlideObjectTypes.FACT:
        return (
          <Interactive
            className={!!gameOpened ? "pointer-none" : ""}
            type={item.type}
            src={item.src}
            srcBorder={item.srcBorder}
            isActive={factObjectsFound[item.id]}
            onClick={() => openFact(item.id)}
            interactiveMap={item.interactiveMap}
          />
        );

      case SlideObjectTypes.GAME:
        const el = (
          <Interactive
            type={item.type}
            src={item.src}
            srcBorder={item.srcBorder}
            isActive={gameWins[item.id]}
            onClick={() => openGame(item.id)}
            interactiveMap={item.interactiveMap}
          />
        );
        return isMobile ? (
          el
        ) : (
          <Tooltip
            text="Играть"
            idx={item.id}
            place="bottom"
            isOuter
            rootSelector=".new-year-2023"
            outerClassName="new-year-2023-tooltip__border"
            overridePosition={overrideTooltipPosition}
          >
            {el}
          </Tooltip>
        );

      case SlideObjectTypes.BULBS:
        return <ChristmasBulbs />;

      case SlideObjectTypes.GARLAND:
        return <Garland canGlow={canGarlandGlow} variantNum={item.variantNum} />;
    }
  };

  const renderGame = () => {
    switch (gameOpened) {
      case GamesTypes.SUDOKU:
        return <SudokuGame onWin={() => onWinGame(GamesTypes.SUDOKU)} />;

      case GamesTypes.MAZE:
        return <MazeGame onWin={() => onWinGame(GamesTypes.MAZE)} />;

      case GamesTypes.MEMORY:
        return <MemoryGame onWin={() => onWinGame(GamesTypes.MEMORY)} />;

      case GamesTypes.PUZZLE:
        return <PuzzleGame onWin={() => onWinGame(GamesTypes.PUZZLE)} />;

      case GamesTypes.QUIZ:
        return <QuizGame onWin={() => onWinGame(GamesTypes.QUIZ)} />;

      case GamesTypes.WISH:
        return <WishesBall onGetFirstWish={() => onWinGame(GamesTypes.WISH)} />;

      default:
        return null;
    }
  };

  const renderSlides = useMemo(() => {
    return slideKeys.map((slideType) => (
      <SwiperSlide key={slideType}>
        {slideType === SlideTypes.WELCOME && <ModalWelcome />}

        {slidesObjects[slideType].map((item, i) => {
          const style = item.pos ? { transform: `translate(${item.pos[0]}px, ${item.pos[1]}px)` } : undefined;
          return (
            <div key={i} className="new-year-2023__swiper__object">
              <div className="new-year-2023__swiper__object__inner" style={style}>
                {renderItem(item)}
              </div>
            </div>
          );
        })}
      </SwiperSlide>
    ));
  }, [factObjectsFound, swiper, gameOpened, gameWins, canGarlandGlow]);

  const onSlideChangeTransitionStart = () => {
    setCanGarlandGlow(false);
  };

  const onSlideChangeTransitionEnd = () => {
    const slide = swiper?.realIndex ?? slideIndex;
    setSlideIndex(slide);
    setLogoLink(ProjectsLink[slideKeys[slide]]);
    setCanGarlandGlow(true);
  };

  return (
    <div className={`new-year-2023 ${loaded ? "new-year-2023_loaded" : ""}`} ref={rootRef}>
      <>
        {isPortrait && <ScreenStub />}

        {screenLoaderMounted && <ScreenLoader progress={loadingProgress} />}

        {afterRegistrationFormOpen && (
          <AfterRegistrationForm
            user={user}
            isOpen
            close={() => {
              setAfterRegistrationFormOpen(false);
              dispatch(hideInfoModal());
            }}
            closeWithLogout={() => {
              setAfterRegistrationFormOpen(false);
              dispatch(logout());
            }}
            onSubmit={() => {
              dispatch(
                getUser(() => {
                  if (!user.emailVerified) {
                    setResendEmailModal(true);
                  }
                })
              );
            }}
          />
        )}

        {resendEmailModalOpen && <ResendEmailModal isOpened onClose={() => setResendEmailModal(false)} />}

        <div className="new-year-2023__inner" ref={innerRef}>
          <Header
            swiper={swiper}
            facts={{ all: allFactIds.length, founded: countFactObjectsFound }}
            games={{ all: countAllGames, completed: countGameWins }}
          />

          <Swiper
            className="new-year-2023__swiper"
            direction="vertical"
            followFinger={false}
            threshold={40}
            onInit={setSwiper}
            onDestroy={() => setSwiper(undefined)}
            onSlideChangeTransitionStart={onSlideChangeTransitionStart}
            onSlideChangeTransitionEnd={onSlideChangeTransitionEnd}
            resistanceRatio={0}
            initialSlide={slideIndex}
          >
            {renderSlides}
          </Swiper>

          <Footer swiper={swiper} />

          {/* Ссылка здесь потому что хедер перекрыавет слайдер*/}
          {!!logoLink && (
            <div className={"new-year-2023__logoLinkWrapper"}>
              <a className={"new-year-2023__logoLink"} href={logoLink} target="_blank" />
            </div>
          )}

          {!!gameOpened && <ModalGame isOpened onClose={() => setGameOpened(undefined)} content={renderGame()} />}

          {!!factView && (
            <ModalFact
              isOpened
              onClose={() => setFactView(undefined)}
              factId={factView}
              closeOnClickOutSide={!afterRegistrationFormOpen && !resendEmailModalOpen}
            />
          )}

          {!factView && modalFactWinOpened && <ModalFactWin isOpened onClose={() => setModalFactWinOpened(false)} />}
        </div>
      </>
    </div>
  );
};

export default MainPage;
