// @ts-nocheck

import React, { useEffect, useLayoutEffect, useState } from "react";
import "./Chat.scss";
import { RootState } from "../../../types/State.interface";
import { useSelector } from "react-redux";

const Chat = ({isVisible}) => {
  const scroll = useSelector((state: RootState) => state.globalEvents.scroll);
  const [chatEl, setChatEl] = useState(null);
  const [adjusted, setAdjusted] = useState(false);

  // для отлавливания загрузки чата
  const observerFn = (mutationList, observer) => {
    mutationList.forEach((mutation) => {
      switch (mutation.type) {
        case "childList":
          Array.from(mutation.addedNodes).forEach((node) => {
            if (
              !!node.childNodes[0]?.classList?.value?.includes(
                "chat-widget__container"
              )
            ) {
              setVisibility();

              if (window.scrollY > 800 && !adjusted) {
                // props.scroll не успевает
                node.childNodes[0].childNodes[1].style.bottom = "95px";
                setAdjusted(true);
              }

              if (window.scrollY < 800 && adjusted) {
                node.childNodes[0].childNodes[1].style.bottom = "0";
                setAdjusted(false);
              }

              node.childNodes[0].setAttribute("data-click-id", "[249]");

              observer.disconnect();
            }
          });
          break;
      }
    });
  };

  useEffect(() => {
    const chatEl = getChatEl();

    const body = document.body;
    const observerOptions = {
      childList: true,
      attributes: false,

      // Omit (or set to false) to observe only changes to the parent node
      subtree: false,
    };
    const observer = new MutationObserver(observerFn);
    observer.observe(body, observerOptions);

    !chatEl && initChat();
  }, []);

  useEffect(() => {
    setVisibility();
  }, [isVisible]);

  useEffect(() => {
    adjustHeight();
  }, [scroll]);

  const adjustHeight = () => {
    const chatEl = getChatEl();

    if (!chatEl) return;

    if (scroll > 800 && !adjusted) {
      chatEl.style.bottom = "95px";
      setAdjusted(true);
    }

    if (scroll < 800 && adjusted) {
      chatEl.style.bottom = "0";
      setAdjusted(false);
    }
  };

  const setVisibility = () => {
    const chatEl = getChatEl();
    if (chatEl) {
      if (isVisible) {
        chatEl.classList.remove('chat-hidden')
      }
      if (!isVisible) {
        chatEl.classList.add('chat-hidden')
      }
    }
  };

  const getChatEl = () => {
    return document.querySelector(".chat-widget__container")?.firstElementChild;
  };

  const initChat = () => {
    /* eslint-disable */
    (function (w, d, s, o, f, js, fjs) {
      w["Chat-Widget"] = o;
      w[o] =
        w[o] ||
        function () {
          (w[o].q = w[o].q || []).push(arguments);
        };
      (js = d.createElement(s)), (fjs = d.getElementsByTagName(s)[0]);
      js.id = o;
      js.src = f;
      js.async = 1;
      fjs.parentNode.insertBefore(js, fjs);
    })(
      window,
      document,
      "script",
      "mw",
      "https://ag1.ditokc.ru/chat/widgetloader/widget-loader.js"
    );

    var config = {
      urlToWidget: "https://ag1.ditokc.ru/chat/widget/?token=crowd_widget", // URL to widget with token
      urlToCss: "https://ag1.ditokc.ru/chat/widgetloader/CROWD/css/style.css", // URL to widget style css
      urlToButtonCss:
        "https://ag1.ditokc.ru/chat/widgetloader/CROWD/css/button.css", // URL to button style
      newMessageText: "*Новое сообщение*", // Bliking message on a tab in a browser
      userToken: document.getElementById("user-token")
        ? document.getElementById("user-token").value
        : null, // User access token
      linkName: "Writer", // Title link at the bottom of widget
      urlLink: "https://www.it-symphony.com/", // Link at the botton of widget
      popupMode: "byUser", // Widget opening mode
      startMode: "auto", // Chat start mode
      startMessageWithoutBot: "Задайте Ваш вопрос", // Start message without bot in the widget channel
      withoutLocation: false, // User opportunity to send his location
      separeteOperatorNameAndTimeSent: true, // Mode to split timesent message and operator name
      changeSendButtonIcon: false, // Changing the button icon for sending a message
      hideClientStartMessage: true, // Hide /start message for client
      withoutAttach: true, // User opportunity to share his files and location
      autoSendSpeechRecognizier: true, // Auto sent a message printed using Speech Recognizier
      sendPreviewMessage: true,
      separateMessageAndButtons: true,
      inputPlaceholder: "Введите сообщение",
      operatorPosition: "bottom",
      setDateTimeFormat: "DateTime", // Send preview message if true
    };

    mw(config);
  };

  return <React.Fragment></React.Fragment>;
};

export default Chat;
