import { Idea, IdeasState } from "../../types/Idea.interface";
import {
  GET_IDEAS,
  GET_IDEA_TAGS,
  GET_PROJECTS,
} from "../actions/IdeasActions";

const initialState = {
  list: [],
  paging: null,
  isLoading: false
};

export function ideasListReducer(state = initialState, action) {
  switch (action.type) {

    case 'GET_IDEAS_LIST_START':
        return {
          ...state,
          isLoading: true
     };

    case 'GET_IDEAS_LIST':
      return {
        ...state,
        list: action.payload.data ? state.list.concat(action.payload.data) : state.list,
        paging: action.payload.paging,
        isLoading: false
      };

      case 'LIKE_IDEA':
        const idea = state.list.find(i => i.id === action.payload.id);
        idea.liked = true;
        idea.rating += 1;
        return {
          ...state,
          list: [...state.list]
        };

    default:
      return state;
  }
}
