// @flow

import React from "react";

import Loader from "../../../presentational/Loaders/Loader/Loader";
import Button from "../../../presentational/Controls/Button/Button";
import { AppModal } from "../../Modal/AppModal";

import "./SubmitForm.scss";
import "../../Modal/AppModal.scss";
import EventsTrackWrapperClick from "../../EventsTrack/wrappers/EventsTrackWrapperClick";
import EventsTrackClickModalCloseBtn from "../../EventsTrack/wrappers/helpers/EventsTrackClickModalCloseBtn";

type Props = {
  user: any;
  isLoading?: Boolean;
  isSubmitting: Boolean;
  isOpen: Boolean;
  title: String;
  onClose: Function;
  onSubmit: Function;
  top?: Boolean;
  classList?: string;
  sendBtnText?: string;
  cancelBtnText?: string;
};

class SubmitForm extends React.Component<Props> {
  getClassListForRoot() {
    let classList = "submit-form-modal";
    if (this.props.classList) {
      classList += ` ${this.props.classList}`;
    }
    return classList;
  }

  render() {
    const sendBtnText = this.props.sendBtnText || "Отправить";
    return (
      <AppModal
        classNames={{ modal: this.getClassListForRoot() }}
        center={!this.props.top}
        overlayId="modal-overlay"
        closeIconId="close-modal-icon"
        blockScroll={true}
        closeIconSize={25}
        open={!!this.props.isOpen}
        closeOnEsc={true}
        closeOnOverlayClick={false}
        onClose={this.props.onClose}
        CloseBtnComponent={EventsTrackClickModalCloseBtn}
      >
        {this.props.isLoading ? (
          <Loader />
        ) : (
          <div className="submit-form">
            <div className={`submit-form__title ${this.props.isSubmitting ? "submit-disabled" : ""} `}>
              {this.props.title}
            </div>

            <div className={`submit-form__content ${this.props.isSubmitting ? "submit-disabled" : ""} `}>
              {this.props.children}
            </div>

            <div className="submit-form__footer">
              {this.props.cancelBtnText && (
                <Button
                  classList="submit-form__cancel"
                  type="outlined-grey"
                  text={this.props.cancelBtnText}
                  size="mid"
                  onClick={this.props.onClose}
                />
              )}
              <EventsTrackWrapperClick id={196} replace={[sendBtnText]}>
                <Button
                  type="filled"
                  text={sendBtnText}
                  size="mid"
                  isLoading={this.props.isSubmitting}
                  onClick={this.props.onSubmit}
                />
              </EventsTrackWrapperClick>
            </div>
          </div>
        )}
      </AppModal>
    );
  }
}

export default SubmitForm;
