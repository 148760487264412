import { ButtonHTMLAttributes, FC } from "react";
import { AppModal } from "../../../../containers/Modal/AppModal";
import { BirthdayModalProps } from "./BirthdayModal.types";
import "./BirthdayModal.scss";

const CloseBtn: FC<ButtonHTMLAttributes<HTMLButtonElement>> = (props) => (
  <button type="button" className="birthday-modal__close-btn" {...props} />
);

const BirthdayModal: FC<BirthdayModalProps> = ({
  isOpen,
  onClose,
  children,
}) => {
  return (
    <AppModal
      classNames={{ modal: "birthday-modal" }}
      CloseBtnComponent={CloseBtn}
      open={isOpen}
      onClose={onClose}
      blockScroll
      closeOnEsc
      center
      closeIconSize={25}
      closeOnOverlayClick={false}
      showHeader={false}
      overlayId="birthday-modal-overlay"
    >
      {children}
    </AppModal>
  );
};

export default BirthdayModal;
