import { StageTypes } from "./Stage.interface";

export interface Specification {
  id: string;
  description: string;
  items?: Specification[];
  orderNumber: number;
  required: boolean;
  title: string;
  type: string;
  validated: boolean;
  value?: string;
}

export enum TaskType {
  FIELD = "FIELD",
  LIST = "LIST",
}

export interface GroupDirectionStage {
  id: string;
  title: string;
  type: StageTypes;
  status: "NEW" | "STARTED" | "FINISHED";
  start: string;
  finish: string;
  specification: Specification;
  shortStructureDescription: string;
}

export interface GroupDirectionNode {
  id: string;
  title: string;
  stages: GroupDirectionStage[];
}

export interface GroupDirection {
  id: string;
  title: string;
  description: string;
  nodes: GroupDirectionNode[];
  hasRootNode: boolean;
  logo: {
    coverAttachmentId: string;
    horizontalAttachmentId: string;
    verticalAttachmentId: string;
    quadrateAttachmentId: string;
    stubAttachmentId: string;
  };
}

export interface RealizedIdeaSection {
  id: string;
  projectGroupDirectionId: string;
  title: string;
  ideas: any[];
}
export interface RealizedIdeaProjectGroupDirection {
  id: string;
  projectGroupDirectionId: string;
  sections: RealizedIdeaSection[];
  title: string;
  description: string;
  ideas: any[];
}
