interface AppRoute {
  id: string;
  route?: string;
  text: string;
  trackClickId?: (string | number)[];
  children?: AppRoute[];
}

export interface RoutesConfig {
  baseRoutes: AppRoute[];
  mobileRoutes: AppRoute[];
  create?: (data: any) => { baseRoutes: AppRoute[]; mobileRoutes: AppRoute[] };
}

const headerTrackId = "HEADER";

interface ProjectData {
  [key: string]: number[];
}

const routes: RoutesConfig = {
  baseRoutes: [
    {
      id: "1",
      text: "О нас",
      trackClickId: [headerTrackId, 2],
      children: [
        {
          id: "1.1",
          route: "/about",
          text: "О платформе",
          trackClickId: [headerTrackId, 2, 10],
        },
        {
          id: "1.2",
          route: "/news",
          text: "Новости",
          trackClickId: [headerTrackId, 2, 8],
        },
        {
          id: "1.3",
          route: "/video",
          text: "Видео",
          trackClickId: [headerTrackId, 2, 9],
        },
      ],
    },
    {
      id: "2",
      route: "/propose",
      text: "Предложить идею",
      trackClickId: [headerTrackId, 7],
    },
    {
      id: "3",
      text: "Результаты работы",
      trackClickId: [headerTrackId, 500],
      children: [
        {
          id: "3.1",
          route: "/archive",
          text: "Выполненные проекты",
          trackClickId: [headerTrackId, 500, 510],
        },
        {
          id: "3.2",
          route: "/ideas",
          text: "Галерея реализованных идей",
          trackClickId: [headerTrackId, 500, 520],
        },
      ],
    },
    {
      id: "8",
      text: "Сообщество",
      route: "/public",
    },
  ],
  mobileRoutes: [
    {
      id: "1",
      text: "О нас",
      trackClickId: [headerTrackId, 2],
      children: [
        {
          id: "1.1",
          route: "/about",
          text: "О платформе",
          trackClickId: [headerTrackId, 2, 10],
        },
        {
          id: "1.2",
          route: "/news",
          text: "Новости",
          trackClickId: [headerTrackId, 2, 8],
        },
        {
          id: "1.3",
          route: "/video",
          text: "Видео",
          trackClickId: [headerTrackId, 2, 9],
        },
      ],
    },
    {
      id: "2",
      route: "/propose",
      text: "Предложить идею",
      trackClickId: [headerTrackId, 22, 7],
    },
    {
      id: "3",
      route: "/ideas",
      text: "Галерея реализованных идей",
      trackClickId: [headerTrackId, 520],
    },
    {
      id: "4",
      route: "/archive",
      text: "Выполненные проекты",
      trackClickId: [headerTrackId, 510],
    },
    {
      id: "8",
      text: "Сообщество",
      route: "/public",
    },
  ],
  create: function (data) {
    const candidateDropdown = {
      id: "5",
      text: "Проекты",
      children: null,
      trackClickId: [headerTrackId, 300],
    };
    const projects = mapIdsToRoutes(data);
    if (projects.length >= 2) {
      candidateDropdown.children = projects.reverse();
    }
    let baseRoutes = [...this.baseRoutes, ...(candidateDropdown.children ? [candidateDropdown] : projects)].sort(
      (a, b) => (a.id < b.id ? -1 : 1)
    );
    let mobileRoutes = [...this.mobileRoutes, ...projects].sort((a, b) => (a.id < b.id ? -1 : 1));
    return { baseRoutes, mobileRoutes };
  },
};


function mapIdsToRoutes(data: ProjectData, forMobile = false): AppRoute[] {
  return Object.entries(data || {})
    .filter(([key, value]) => value.length)
    .map(([key, value]): AppRoute => {
      const queryParams = value.length === 1 ? "?id=" + value[0] : "";
      const isAnnouncement = key.startsWith("announcement");
      return {
        id: isAnnouncement ? "5.1" : "5.2",
        route: isAnnouncement ? "/announcement" + queryParams : "/current" + queryParams,
        text: isAnnouncement ? "Следующий проект" : "Текущий проект",
        trackClickId: [headerTrackId, forMobile ? 22 : 300, isAnnouncement ? 310 : 320],
      };
    });
}

// if (LocalStorageService.isStorageAvailable()) {
//   if (this.isBirthdayPageFirstVisit()) {
//     if (window.location.pathname === "/") {
//       window.location.href = window.location.origin + "/birthday";
//       LocalStorageService.saveData(LocalStorageKeys.BIRTHDAY_PAGE_VISITED, "true");
//     }
//     if (window.location.pathname === "/birthday") {
//       LocalStorageService.saveData(LocalStorageKeys.BIRTHDAY_PAGE_VISITED, "true");
//     }
//   }
// }

export { routes };
