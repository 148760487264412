import React, { ComponentProps, FC } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import ProfilePic from "../../../presentational/ProfilePic/ProfilePic";
import Logo from "../../Header/Logo/Logo";

import "./HeaderModal.scss";
import { RootState } from "../../../../types/State.interface";
import EventsTrackWrapperClick from "../../EventsTrack/wrappers/EventsTrackWrapperClick";

type Props = Partial<Pick<ComponentProps<typeof Logo>, "onLogoClick">>;

const HeaderModal: FC<Props> = ({ onLogoClick }) => {
  const currentUser = useSelector((state: RootState) => state.user.userDetails);
  const logo = useSelector((state: RootState) => state.layout.logo)
  const canShowUser = () => {
    return currentUser?.loggedIn && !currentUser.partiallyFilled;
  };

  return (
    <div className="modal__mobile-header">
    <EventsTrackWrapperClick id={23}>
      <Logo onLogoClick={ onLogoClick } options={logo}/>
    </EventsTrackWrapperClick>

      {canShowUser() && <EventsTrackWrapperClick id={24}>
        <Link to="/profile" className="modal__mobile-profile">
          <ProfilePic imageId={currentUser?.pictureId} />
        </Link>
      </EventsTrackWrapperClick>}
    </div>
  );
};

export default HeaderModal;