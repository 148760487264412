import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { matchPath, useHistory } from "react-router-dom";
import { useOuterClick } from "../../../../../../hooks/useOnOutsideClick";
import { showMosruForm } from "../../../../../../store/actions/LayoutActions";
import { logout } from "../../../../../../store/actions/UserActions";
import { RootState } from "../../../../../../types/State.interface";
import "./BirthdayHeaderMobile.scss";

export interface BirthdayHeaderMobileProps {
  links: any[];
}

const BirthdayHeaderMobile = ({ links }: BirthdayHeaderMobileProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state: RootState) => state.user.userDetails);

  const [showPopup, setShowPopup] = useState(false);

  const popupRef = useOuterClick<HTMLInputElement>((event: any) => {
    const parentElementClassName = "birthday-header-mobile__close";
    event.target.className !== parentElementClassName &&
      event.target.parentElement.className !== parentElementClassName &&
      setShowPopup(false);
  });

  return (
    <div className="birthday-header-mobile" ref={popupRef}>
      {showPopup && (
        <button
          className="birthday-header-mobile__close"
          onClick={() => setShowPopup(false)}
        >
          <div className="ic ui-icon-14"></div>
        </button>
      )}

      {!showPopup && (
        <button
          className="birthday-header-mobile__close"
          onClick={() => setShowPopup(true)}
        >
          <div className="ic ui-icon-39"></div>
        </button>
      )}

      {showPopup && (
        <ul className="birthday-header-mobile__list">
          {links.map((link) => (
            <li
              className="birthday-header-mobile__link"
              key={link.id}
              onClick={() => {
                const url = link.href || "/birthday";
                matchPath(history.location.pathname, { path: url, exact: true })
                  ? history.replace(url, { sectionId: link.id })
                  : history.push(url, { sectionId: link.id });

                setShowPopup(false);
              }}
            >
              <a>{link.text}</a>
            </li>
          ))}

          <div className="divider"></div>

          {user?.loggedIn ? (
            <li
              className="birthday-header-mobile__link"
              key={"exit"}
              onClick={() => {
                setShowPopup(false);
                dispatch(logout());
              }}
            >
              <a>Выход</a>
            </li>
          ) : (
            <li
              className="birthday-header-mobile__link"
              key={"exit"}
              onClick={() => {
                setShowPopup(false);
                dispatch(showMosruForm());
              }}
            >
              <a>Войти</a>
            </li>
          )}
        </ul>
      )}
    </div>
  );
};

export default BirthdayHeaderMobile;
