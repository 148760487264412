import { FC, useEffect, useState } from "react";
import "./QrCodeModal.scss";

import { AppModal } from "../../Modal/AppModal";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../types/State.interface";
import { hideQrCodeModal } from "../../../../store/actions/LayoutActions";
import dayjs from "dayjs";
import Loader from "../../../presentational/Loaders/Loader/Loader";
import { API } from "../../../../services/api";
import { ApiStatusCode, BaseResponse } from "../../../../types/Common.interface";
import { displayError } from "../../../../utils";

interface QrCode {
  qrImage: string;
  expirationDate: string;
}

const QrCodeModal: FC = () => {
  const showQrCodeModal = useSelector((state: RootState) => state.layout.showQrCodeModal);
  const dispatch = useDispatch();

  const [qrCode, setQrCode] = useState<QrCode>(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleClose = () => {
    dispatch(hideQrCodeModal());
    setQrCode(null);
  };

  useEffect(() => {
    if (showQrCodeModal) {
      setIsLoading(true);

      API.get<BaseResponse<QrCode>>("/api/qr/get")
        .then(({ data }) => {
          if (data.status === ApiStatusCode.OK) {
            const { qrImage, expirationDate } = data.data;
            setQrCode({ qrImage, expirationDate });
          } else {
            displayError("Ошибка при получении qr-code", data.message);
          }
        })
        .catch(() => {
          displayError("QrCodeModal", "Ошибка при получении qr-code");
        })
        .finally(() => setIsLoading(false));
    }
  }, [showQrCodeModal]);

  return (
    <>
      {showQrCodeModal && (
        <AppModal
          center
          classNames={{ modal: "qr-modal" }}
          overlayId="modal-overlay"
          open={showQrCodeModal}
          blockScroll={true}
          closeOnEsc={true}
          closeOnOverlayClick={false}
          onClose={handleClose}
        >
          <div className="qr-modal__container">
            <p className="qr-modal__title">Ваш персональный код</p>
            {isLoading ? (
              <div className="qr-modal__loader">
                <Loader />
              </div>
            ) : qrCode ? (
              <>
                <div className="qr-modal__code" dangerouslySetInnerHTML={{ __html: qrCode.qrImage }}></div>
                <p className="qr-modal__text">Персональный код действует до:</p>
                <div className="qr-modal__date">{dayjs(qrCode.expirationDate).format("DD MMMM YYYYг. hh:mm")}</div>
              </>
            ) : null}
          </div>
        </AppModal>
      )}
    </>
  );
};

export default QrCodeModal;
