import { FC } from "react";
import Modal from "../Modal/Modal";
import "./ScreenStub.scss";

const ScreenStub: FC = () => {
  return (
    <div className="screen-stub">
      <Modal
        className="screen-stub__modal"
        isOpened
        onClose={() => {}}
        title={
          <>
            Рекомендуем открыть
            <br />
            страницу на компьютере
            <br />
            или ноутбуке.
          </>
        }
        content={
          <>
            Хотите продолжить здесь?
            <br />
            Поверните мобильное устройство горизонтально.
          </>
        }
      />
    </div>
  );
};

export default ScreenStub;
