import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import "./Game.scss";
import Timer from "../../Timer/Timer";

const clamp = (value: number, min: number, max: number) => {
  if (value < min) {
    return min;
  }
  if (value > max) {
    return max;
  }
  return value;
};

const solveTolerancePercentage = 0.028;

interface Tile {
  tileOffsetX: number;
  tileOffsetY: number;
  tileWidth: number;
  tileHeight: number;
  correctPosition: number;
  currentPosXPerc: number;
  currentPosYPerc: number;
  solved: boolean;
  borderTopLeftRadius: string;
  borderTopRightRadius: string;
  borderBottomLeftRadius: string;
  borderBottomRightRadius: string;
}

export interface PuzzleProps {
  imageSrc: string;
  rows?: number;
  columns?: number;
  showGrid?: boolean;
  onWin?: () => void;
  onLoose?: () => void;
}

const Game: FC<PuzzleProps> = ({
  imageSrc,
  rows = 3,
  columns = 4,
  showGrid = true,
  onWin = () => {},
  onLoose = () => {},
}) => {
  const [tiles, setTiles] = useState<Tile[] | undefined>();
  const [imageSize, setImageSize] = useState<{
    width: number;
    height: number;
  }>();
  const [rootSize, setRootSize] = useState<{ width: number; height: number }>();
  const [calculatedHeight, setCalculatedHeight] = useState<number>();
  const rootElement = useRef<HTMLElement>();
  const resizeObserver = useRef<ResizeObserver>();
  const draggingTile = useRef<
    | {
        tile: Tile;
        elem: HTMLElement;
        mouseOffsetX: number;
        mouseOffsetY: number;
      }
    | undefined
  >();
  const onImageLoaded = useCallback(
    (image: HTMLImageElement) => {
      setImageSize({ width: image.width, height: image.height });
      if (rootSize) {
        setCalculatedHeight((rootSize!.width / image.width) * image.height);
      }
      setTiles(
        Array.from(Array(rows * columns).keys()).map((position, index) => ({
          correctPosition: position,
          tileHeight: image.height / rows,
          tileWidth: image.width / columns,
          tileOffsetX: (position % columns) * (image.width / columns),
          tileOffsetY: Math.floor(position / columns) * (image.height / rows),
          currentPosXPerc: Math.random() * (1 - 1 / rows),
          currentPosYPerc: Math.random() * (1 - 1 / columns),
          solved: false,
          ...getBorderRadiusStyle(index),
        }))
      );
    },
    [rows, columns]
  );

  const onRootElementResized = useCallback(
    (args: ResizeObserverEntry[]) => {
      const contentRect = args.find((it) => it.contentRect)?.contentRect;
      if (contentRect) {
        setRootSize({
          width: contentRect.width,
          height: contentRect.height,
        });
        if (imageSize) {
          setCalculatedHeight((contentRect.width / imageSize!.width) * imageSize!.height);
        }
      }
    },
    [setRootSize, imageSize]
  );

  const onRootElementRendered = useCallback(
    (element: HTMLElement | null) => {
      if (element) {
        rootElement.current = element;
        const observer = new ResizeObserver(onRootElementResized);
        observer.observe(element);
        resizeObserver.current = observer;
        setRootSize({
          width: element.offsetWidth,
          height: element.offsetHeight,
        });
        if (imageSize) {
          setCalculatedHeight((element.offsetWidth / imageSize.width) * imageSize.height);
        }
      }
    },
    [setRootSize, imageSize, rootElement, resizeObserver]
  );

  useEffect(() => {
    const image = new Image();
    image.onload = () => onImageLoaded(image);
    image.src = imageSrc;
  }, [imageSrc, rows, columns]);

  const onTileMouseDown = useCallback(
    (tile: Tile, event: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>) => {
      if (!tile.solved) {
        if (event.type === "touchstart") {
          document.documentElement.style.setProperty("overflow", "hidden");
        }

        const eventPos = {
          x: (event as React.MouseEvent).pageX ?? (event as React.TouchEvent).touches[0].pageX,
          y: (event as React.MouseEvent).pageY ?? (event as React.TouchEvent).touches[0].pageY,
        };
        draggingTile.current = {
          tile,
          elem: event.target as HTMLDivElement,
          mouseOffsetX: eventPos.x - (event.target as HTMLDivElement).getBoundingClientRect().x,
          mouseOffsetY: eventPos.y - (event.target as HTMLDivElement).getBoundingClientRect().y,
        };
        (event.target as HTMLDivElement).classList.add("new-year-2023-puzzle__piece--dragging");
      }
    },
    [draggingTile]
  );

  const onRootMouseMove = useCallback(
    (event: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>) => {
      if (draggingTile.current) {
        event.stopPropagation();
        event.preventDefault();
        const eventPos = {
          x: (event as React.MouseEvent).pageX ?? (event as React.TouchEvent).touches[0].pageX,
          y: (event as React.MouseEvent).pageY ?? (event as React.TouchEvent).touches[0].pageY,
        };
        const draggedToRelativeToRoot = {
          x: clamp(
            eventPos.x - rootElement.current!.getBoundingClientRect().left - draggingTile.current.mouseOffsetX,
            0,
            rootSize!.width - draggingTile.current.elem.offsetWidth
          ),
          y: clamp(
            eventPos.y - rootElement.current!.getBoundingClientRect().top - draggingTile.current.mouseOffsetY,
            0,
            rootSize!.height - draggingTile.current.elem.offsetHeight
          ),
        };
        draggingTile.current.elem.style.setProperty("left", `${draggedToRelativeToRoot.x}px`);
        draggingTile.current.elem.style.setProperty("top", `${draggedToRelativeToRoot.y}px`);
      }
    },
    [draggingTile, rootSize]
  );
  const onRootMouseUp = useCallback(
    (event: React.TouchEvent | React.MouseEvent) => {
      if (draggingTile.current) {
        if (event.type === "touchend") {
          document.documentElement.style.removeProperty("overflow");
        }
        draggingTile.current?.elem.classList.remove("new-year-2023-puzzle__piece--dragging");
        const draggedToPercentage = {
          x: clamp(draggingTile.current!.elem.offsetLeft / rootSize!.width, 0, 1),
          y: clamp(draggingTile.current!.elem.offsetTop / rootSize!.height, 0, 1),
        };
        const draggedTile = draggingTile.current.tile;
        const targetPositionPercentage = {
          x: (draggedTile.correctPosition % columns) / columns,
          y: Math.floor(draggedTile.correctPosition / columns) / rows,
        };
        const isSolved =
          Math.abs(targetPositionPercentage.x - draggedToPercentage.x) <= solveTolerancePercentage &&
          Math.abs(targetPositionPercentage.y - draggedToPercentage.y) <= solveTolerancePercentage;

        const newTile = {
          ...draggedTile,
          currentPosXPerc: !isSolved ? draggedToPercentage.x : targetPositionPercentage.x,
          currentPosYPerc: !isSolved ? draggedToPercentage.y : targetPositionPercentage.y,
          solved: isSolved,
        };

        setTiles((prevState) => {
          const newState = [...prevState!.filter((it) => it.correctPosition !== draggedTile.correctPosition), newTile];
          if (newState.every((tile) => tile.solved)) {
            onWin();
          }
          return newState;
        });

        draggingTile.current.elem.style.setProperty("left", `${newTile.currentPosXPerc * rootSize.width}px`);
        draggingTile.current.elem.style.setProperty("top", `${newTile.currentPosYPerc * rootSize.height}px`);

        draggingTile.current = undefined;
      }
    },

    [draggingTile, setTiles, rootSize, onWin]
  );

  const renderGrid = () => {
    return (
      <div
        className="new-year-2023-puzzle__puzzleGrid"
        style={{
          gridTemplateRows: `repeat(${rows}, 1fr)`,
          gridTemplateColumns: `repeat(${columns}, 1fr)`,
        }}
      >
        {Array.from(Array(rows * columns)).map((i, idx) => (
          <div className="new-year-2023-puzzle__puzzleGrid-item" style={getBorderRadiusStyle(idx)} key={idx} />
        ))}
      </div>
    );
  };

  const getBorderRadiusStyle = (index) => {
    const borderRadius = "22px";
    return {
      borderTopLeftRadius: index === 0 ? borderRadius : "0",
      borderTopRightRadius: index === columns - 1 ? borderRadius : "0",
      borderBottomLeftRadius: index === columns * rows - rows ? borderRadius : "0",
      borderBottomRightRadius: index === columns * rows - 1 ? borderRadius : "0",
    };
  };

  return (
    <div className="new-year-2023-puzzle">
      <div className={"new-year-2023-puzzle__timer"}>
        {" "}
        <Timer initialMinutes={5} onTimeExpire={onLoose} />
      </div>
      <div
        className={"new-year-2023-puzzle__wrapper"}
        ref={onRootElementRendered}
        onTouchMove={onRootMouseMove}
        onMouseMove={onRootMouseMove}
        onTouchEnd={onRootMouseUp}
        onMouseUp={onRootMouseUp}
        onTouchCancel={onRootMouseUp}
        onMouseLeave={onRootMouseUp}
        onDragEnter={(event) => {
          event.stopPropagation();
          //  event.preventDefault();
        }}
        onDragOver={(event) => {
          event.stopPropagation();
          // event.preventDefault();
        }}
      >
        {showGrid && renderGrid()}
        {tiles &&
          rootSize &&
          imageSize &&
          tiles.map((tile, index) => (
            <div
              draggable={false}
              onMouseDown={(event) => onTileMouseDown(tile, event)}
              onTouchStart={(event) => onTileMouseDown(tile, event)}
              key={tile.correctPosition}
              className={`new-year-2023-puzzle__piece ${tile.solved ? "new-year-2023-puzzle__piece--solved" : ""} `}
              style={{
                position: "absolute",
                height: `${(1 / rows) * 100}%`,
                width: `${(1 / columns) * 100}%`,
                backgroundImage: `url(${imageSrc})`,
                backgroundSize: `${rootSize.width}px ${rootSize.height}px`,
                backgroundPositionX: `${((tile.correctPosition % columns) / (columns - 1)) * 100}%`,
                backgroundPositionY: `${(Math.floor(tile.correctPosition / columns) / (rows - 1)) * 100}%`,
                left: `${tile.currentPosXPerc * rootSize.width}px`,
                top: `${tile.currentPosYPerc * rootSize.height}px`,
                borderTopLeftRadius: tile.borderTopLeftRadius,
                borderTopRightRadius: tile.borderTopRightRadius,
                borderBottomLeftRadius: tile.borderBottomLeftRadius,
                borderBottomRightRadius: tile.borderBottomRightRadius,
              }}
            />
          ))}
      </div>
    </div>
  );
};

export default Game;
