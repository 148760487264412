import React, { createRef, RefObject, useRef, useState } from "react";
import { projects } from "./ExternalLinks.helper";
import ExternalPopup from "./ExternalPopup/ExternalPopup";
import "./ExternalLinks.scss";
import EventsTrackWrapperClick from "../../../containers/EventsTrack/wrappers/EventsTrackWrapperClick";

const USLUGI_NAME = "uslugi";

const ExternalLinks = () => {
  const linksRef = useRef(null);
  const [currentProject, setCurrentProject] = useState(null);

  const getClassesForLink = (project) => {
    let classlist = "external-links__link";
    if (project) {
      classlist += ` ${project.name}-link`;
    }
    return classlist;
  };

  return (
    <div ref={linksRef} className="external-links">
      {projects.map((project, i) => {
        return (
          <EventsTrackWrapperClick needParent key={i} id={182} replace={[project.title]}>
            <div
              className={getClassesForLink(project)}
              style={{ color: project.color }}
              onClick={() => setCurrentProject(project)}
            >
              <img
                className={`external-links__link-icon ${
                  USLUGI_NAME === project.name ? "external-links__link-shadow" : ""
                }`}
                src={project.logoSrc}
                alt={project.name}
              />
              <div className="external-links__link-text" dangerouslySetInnerHTML={{ __html: project.title }}></div>
            </div>
          </EventsTrackWrapperClick>
        );
      })}

      {currentProject && (
        <ExternalPopup ref={linksRef} project={currentProject} onClose={() => setCurrentProject(null)} />
      )}
    </div>
  );
};

export default ExternalLinks;
