import { UserRole } from "../types/User.interface";

export const canSeeHiddenNames = (loggedInUser) => {
  return loggedInUser?.roles?.some((role) =>
    [
      UserRole.PROJECT_BOSS,
      UserRole.MANAGER,
      UserRole.FACILITATOR,
      UserRole.PROJECT_FACILITATOR,
      UserRole.ADMINISTRATOR,
      UserRole.ANALYST,
      UserRole.FACILITATOR_IZRG,
    ].includes(role)
  );
};

export const renderHatIfUserNameIsHidden = (postOwner, loggedInUser) => {
  if (postOwner?.showName) return;
  const canSee = canSeeHiddenNames(loggedInUser);
  if (!canSee) return;
  return <span className="ui-icon ui-icon-hidden user-name-hidden-icon"></span>;
};

export const nameIfHidden = "Пользователь платформы";
