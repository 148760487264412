import { FC, useEffect, useState } from "react";
import "./OnlineTranslations.scss";
import { useHistory, useLocation } from "react-router-dom";
import CommonSlider from "../../../../presentational/Sliders/CommonSlider/CommonSlider";
import { useMediaQuery } from "react-responsive";
import { phoneWidth, wideTabletWidth } from "../../../../../utils/constants/widthConstants";
import SwiperCore from "swiper";
import DiscussionLandingService from "../../services/discussion-service";
import { displayError } from "../../../../../utils";
import { ApiStatusCode } from "../../../../../types/Common.interface";
import VideoViewer from "../../../../containers/Modals/VideoViewer/VideoViewer";
import OnlineTranslationsCard from "./OnlineTranslationsCard/OnlineTranslationsCard";
import { OnlineTranslation } from "./OnlineTranslations.types";
import { DataAdapter } from "../../../../../services/adapter/data-adapter";

export interface OnlineTranslationsProps {
  onLoadEnd?: (data) => void;
}

export const translationIdQueryKey = "translationId";

const OnlineTranslations: FC<OnlineTranslationsProps> = ({ onLoadEnd }) => {
  const history = useHistory();
  const location = useLocation();
  const isPhone = useMediaQuery({ query: `(max-width: ${phoneWidth}px)` });
  const isTablet = useMediaQuery({ query: `(max-width: ${wideTabletWidth}px) and (min-width: ${phoneWidth}px)` });
  const [swiperInstance, setSwiperInstance] = useState<SwiperCore>(null);
  const [onlineTranslations, setOnlineTranslations] = useState<OnlineTranslation[]>(null);
  const [selectedTranslation, setSelectedTranslation] = useState<OnlineTranslation>(null);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (!onlineTranslations?.length) return;

    const searchParams = new URLSearchParams(location.search);
    const translationId = searchParams.get(translationIdQueryKey);
    if (translationId) {
      const translation = onlineTranslations.filter((_translation) => translationId === _translation.id)[0] || null;
      setSelectedTranslation(translation);
    }
  }, [location.search, onlineTranslations]);

  const fetchData = async () => {
    try {
      const response = await DiscussionLandingService.getOnlineStreams({size: 100});
      if (response.status === ApiStatusCode.OK) {
        setOnlineTranslations(response.data);
      } else {
        displayError("Ошибка при получении онлайн трансляций", response.message);
      }
    } catch (error) {
      displayError("OnlineTranslations", "Ошибка при получении онлайн трансляций");
    } finally {
      //   onLoadEnd([]);
    }
  };

  const handleOnInit = () => {};

  const handleOnSlideChange = () => {};

  const handleSelectTranslation = (translation: OnlineTranslation | null) => {
    const searchParams = new URLSearchParams(window.location.search);
    if (translation) {
      searchParams.set(translationIdQueryKey, translation.id);
    } else {
      searchParams.delete(translationIdQueryKey);
      setSelectedTranslation(null);
    }

    history.push({ search: searchParams.toString() });
  };

  const handleSlideNext = () => {
    swiperInstance.slideNext();
    const nextIndex = Math.min(
      onlineTranslations.map((translation) => translation.id).indexOf(selectedTranslation.id) + 1,
      onlineTranslations.length
    );
    handleSelectTranslation(onlineTranslations[nextIndex]);
  };

  const handleSlidePrev = () => {
    swiperInstance.slidePrev();
    const prevIndex = Math.max(
      onlineTranslations.map((translation) => translation.id).indexOf(selectedTranslation.id) - 1,
      0
    );
    handleSelectTranslation(onlineTranslations[prevIndex]);
  };

  if (!onlineTranslations?.length) return <></>;

  return (
    <section className="translations" id="OnlineTranslations">
      <div className="discussion-landing-subtitle">Смотрите видео с мероприятий</div>
      <div className="translations__cards">
        <CommonSlider
          onInit={handleOnInit}
          onSwiper={setSwiperInstance}
          onSlideChange={handleOnSlideChange}
          className="notifications__swiper"
          effect="fade"
          options={{
            loop: false,
            spaceBetween: 24,
            slidesPerView: isTablet ? 2 : isPhone ? 1 : 3,
            slidesPerColumn: 1,
            slidesPerGroup: 1,
            touchStartPreventDefault: false,
          }}
        >
          {onlineTranslations?.map((item) => (
            <OnlineTranslationsCard key={item.id} translation={item} onSelectTranslation={handleSelectTranslation} />
          ))}
        </CommonSlider>
      </div>

      {selectedTranslation && (
        <VideoViewer
          video={DataAdapter.toVideo({
            ...selectedTranslation,
            id: selectedTranslation.videoId,
            name: selectedTranslation.description,
            previewId: selectedTranslation.videoPreviewId,
            uploaded: selectedTranslation.startDate,
          })}
          shareOptions={{
            url: `/culture?${translationIdQueryKey}=${selectedTranslation.id}`,
            imageUrl: "../../../../../assets/discussion-landing/repost.svg",
            title: `Посмотрите новое видео от «Города идей» на медиаплатформе «Город обсуждает стандарты культурного досуга»!`,
          }}
          classnames="video-viewer-modal__translations"
          isOpened={true}
          canSlidePrev={onlineTranslations?.indexOf(selectedTranslation) !== 0}
          canSlideNext={onlineTranslations?.indexOf(selectedTranslation) < onlineTranslations.length - 1}
          onSlideNext={handleSlideNext}
          onSlidePrev={handleSlidePrev}
          onTagClick={() => null}
          onClose={() => {
            handleSelectTranslation(null);
          }}
        />
      )}
    </section>
  );
};
export default OnlineTranslations;
