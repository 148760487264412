import { FC, useMemo } from "react";
import { Link } from "react-router-dom";
import Button from "../Button";
import CircleButtonList from "../LeisureHeader/CircleButtonList/CircleButtonList";
import { circleButtonsData } from "../LeisureHeader/LeisureHeader.data";
import Modal from "../Modal";
import "./FinalModal.scss";
import { FinalModalProps } from "./FinalModal.types";

const emptyFn = () => {};

const FinalModal: FC<FinalModalProps> = ({ winningList }) => {
  const mappedWinningList = useMemo(() => {
    if (winningList) {
      return circleButtonsData
        .map((item, index) => {
          return {
            ...item,
            won: winningList[index].won as boolean,
          };
        })
        .filter(({ won }) => won);
    }
    return [];
  }, [winningList]);

  const renderContent = () => (
    <div className="leisure-landing-final-modal__content">
      <div>
        Наше маленькое путешествие по городу,
        <br />
        где ваши идеи становятся реальными, завершилось. Благодаря платформе
        <br />«
        <a href="/" target="_blank">
          <strong>Город идей</strong>
        </a>
        » предложения москвичей
        <br />
        меняют разные сферы нашей с вами
        <br />
        повседневной жизни.
      </div>
      <Button onClick={() => window.open("/")}>Перейти на платформу</Button>
    </div>
  );

  return <Modal onClose={emptyFn} className="leisure-landing-final-modal" content={renderContent()} />;
};

export default FinalModal;
