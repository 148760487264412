import React from "react";

import "./ProgressBar.scss";

export interface ProgressBarProps {
  value: number;
}

const ProgressBar = ({ value }: ProgressBarProps) => {
  return (
    <div className="progress-bar">
      <div className="progress-bar__bar"></div>
      <div className="progress-bar__text"></div>
      <div className="progress-bar__value" style={{ width: value + "%" }}></div>
    </div>
  );
};

export default ProgressBar;
