import { BaseResponse } from "../types/Common.interface";
import { User } from "../types/User.interface";
import { API, httpParamsSerialize } from "./api";

const baseUrl = "/api/mapped/idea";

export interface MappedIdea {
    id: string;
    address: string;
    author: User;
    attachmentIds: string[];
    categoryId: string;
    groupCode: string;
    comment: string;
    latitude: number;
    longitude: number;
    projectId: string;
    projectName: string;
    text: string;
    groupDirectionName: string;
    groupDirectionId: string;
}

interface GetByPolygonQuery {
    x0: number;
    y0: number;
    x1: number;
    y1: number;
}

export type GetByPolygonResponse = BaseResponse<MappedIdea[]>;

const MappedIdeaService = {
    getByPolygon: (query?: GetByPolygonQuery) => {
      return API.get<GetByPolygonResponse>(`${baseUrl}/byPolygon?${httpParamsSerialize(query)}`).then(({ data }) => {
        return data;
      });
    },

  };

export default MappedIdeaService;
