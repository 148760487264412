import React, { useState, useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { store } from "../store/store";

function lazyWithPreload(factory) {
  const Component: any = React.lazy(factory);
  Component.preload = factory;
  return Component;
}

const SmartRoute = (props: any) => {
  const getComponent = () => {
    const _getModule = () => {
      return import(
        `../components/${(isPageType() && "pages") || "containers"}/${
          props.component
        }/${(isPageType() && props.component) || props.component + "Container"}`
      );
    };

    const getModule = () =>
      import(
        `../components/containers/${props.component}/${props.component}Container`
      );

    const component = props.lazy
      ? props.shouldPreload
        ? lazyWithPreload(() => _getModule())
        : React.lazy(() => _getModule() as any)
      : _getModule();

    return component;
  };

  const isPageType = () => props.type === "page";

  const [component, setComponent] = useState(getComponent());
  const state = store.getState();

  useEffect(() => {
    if (props.shouldPreload && props.canPreload) {
      component.preload();
    }
  }, [props.canPreload]);

  const passedGuards = () => {
    if (!props.guards) return true;

    return props.guards.every((g) => g(state));
  };

  const fetchingData = () => {
    return state.user.isFetching;
  };

  return (
    <Route
      path={props.path}
      exact={props.exact}
      render={(props) => {
        if (fetchingData()) return;

        return passedGuards() ? (
          React.createElement(component, props)
        ) : (
          <Redirect to={props.redirect || "/"} />
        );
      }}
    />
  );
};

export default SmartRoute;
