import { FC } from "react";
import { Link } from "react-router-dom";
import Modal from "../Modal";
import "./IdeaSentModal.scss";
import { IdeaSentModalProps } from "./IdeaSentModal.types";

const IdeaSentModal: FC<IdeaSentModalProps> = ({ onClose, type }) => (
  <Modal
    className="leisure-landing-idea-sent-modal"
    onClose={onClose}
    showCloseBtn
    title={
      <>
        Спасибо!
        <br />
        Ваша {type === "idea" ? "идея" : "фотография"} отправлена.
      </>
    }
    content={
      <>
        В случае успешного прохождения модерации пользователям с полной учетной записью mos.ru будут начислены{" "}
        <Link to="/Правила_участия_в_квесте.pdf" target="_blank">
          баллы
        </Link>
        .{" "}
        {type === "idea" ? (
          <>
            Статус идеи можно отслеживать в{" "}
            <Link to="/profile" target="_blank">
              личном кабинете
            </Link>
          </>
        ) : (
          "Уведомления о результатах рассмотрения фотографии вы получите на электронную почту"
        )}
        .
      </>
    }
  />
);

export default IdeaSentModal;
