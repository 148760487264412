import { validateEmail, declOfNum } from "../utils/index";

interface Config {
  notEmpty: boolean;
  minLength: number;
  maxLength: number;
  email: boolean;
  latinAndNumbersOnly: boolean;
  cyrillicOnly: boolean;
  docName: boolean;
  cyrillicWithPunctuation: boolean;
  cyrillicWithPunctuationAndNumbers: boolean;
  mustContainLetters: boolean;
  mustContainUpperCase: boolean;
  mustContainLowerCase: boolean;
  mustContainNumbers: boolean;
  passwordsMustBeEqual: string;
  restrictForeignSymbByPercent: number;
  restrictUppercaseSymbByPercent: number;
  docNameSingleSpace: boolean;
  isLink: { strict?: boolean };
}

class Validator {
  input: HTMLInputElement;
  config: Config;

  constructor(input: HTMLInputElement, config: Config) {
    this.input = input;
    this.config = config;
  }

  isValid() {
    return Object.keys(this.config).every((key) => this[`check${capitalize(key)}`](this.input.value));
  }

  updateConfig(config) {
    this.config = config;
  }

  checkNotEmpty(value) {
    return !!value?.trim();
  }

  checkMaxLength(value) {
    return value.trim().length <= this.config.maxLength;
  }

  checkMinLength(value) {
    return value.trim().length >= this.config.minLength;
  }

  checkLatinAndNumbersOnly(value) {
    return value.match(/^[A-z0-9]+$/);
  }

  checkCyrillicOnly(value) {
    return value.match(/^[ЁёА-я]+$/);
  }

  checkDocName(value) {
    const singleCyrillicCharacter = value.length === 1 && value.match(/^[ЁёА-я]+$/);
    const hyphenatedCyrillicName = value.length > 0 && value.match(/^[ЁёА-я]+([\-]?)[ЁёА-я]+$/);
    return singleCyrillicCharacter || hyphenatedCyrillicName;
  }

  checkDocNameV2(value) {
    const onlyOneSpace = value.split(" ").length <= 2;
    const onlyOneHyphen = value.split("-").length <= 2;
    const isCyrillic = value
      .replace(" ", "")
      .replace("-", "")
      .match(/^[ЁёА-я]+$/);

    return onlyOneSpace && onlyOneHyphen && isCyrillic;
  }

  checkCyrillicWithPunctuation(value) {
    return value.match(/^[ЁёА-я\s\-"!?().,:;]+$/);
  }

  checkCyrillicWithPunctuationAndNumbers(value) {
    return value.match(/^[ЁёА-я\s\-"!?().,:;1234567890]+$/);
  }

  checkMustContainLetters(value) {
    const letterCount = (value.match(/[ЁёА-яA-z]/g) || []).length;
    return letterCount > 0;
  }

  checkMustContainUpperCase(value) {
    const letterCount = (value.match(/[ЁА-ЯA-Z]/g) || []).length;
    return letterCount > 0;
  }

  checkMustContainLowerCase(value) {
    const letterCount = (value.match(/[ёа-яa-z]/g) || []).length;
    return letterCount > 0;
  }

  checkMustContainNumbers(value) {
    const numberCount = (value.match(/[0-9]/g) || []).length;
    return numberCount > 0;
  }

  checkPasswordsMustBeEqual(value) {
    return value === this.config.passwordsMustBeEqual;
  }

  _conditionIsAboveAllowedPercent(value, percent, regex) {
    const conditionSymbolCount = (value.match(regex) || []).length;
    const trimmedValueLength = value.replace(/\s+/g, "").length;
    const conditionAboveAllowedPercent = (conditionSymbolCount / trimmedValueLength) * 100 > percent;

    return conditionAboveAllowedPercent;
  }

  checkRestrictForeignSymbByPercent(value) {
    return !this._conditionIsAboveAllowedPercent(value, this.config.restrictForeignSymbByPercent, /[A-z]/g);
  }

  checkRestrictUppercaseSymbByPercent(value) {
    return !this._conditionIsAboveAllowedPercent(value, this.config.restrictUppercaseSymbByPercent, /[ЁА-ЯA-Z]/g);
  }

  checkEmail(value) {
    return validateEmail(value);
  }

  checkIsLink(string) {
    const checkStrict = () => {
      let url;

      try {
        url = new URL(string);
      } catch (_) {
        return false;
      }

      return url.protocol === "http:" || url.protocol === "https:";
    };

    const checkLoose = () => {
      var pattern = new RegExp(
        "^(https?:\\/\\/)?" + // protocol
          "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
          "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
          "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
          "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
          "(\\#[-a-z\\d_]*)?$",
        "i"
      ); // fragment locator
      return pattern.test(string);
    };

    return this.config.isLink.strict ? checkStrict() : checkLoose();
  }

  getSymbolDecl() {
    return declOfNum(this.config.maxLength || 0, ["символа", "символов", "символов"]);
  }

  getMapErr(rule) {
    return {
      notEmpty: "Поле не должно быть пустым",
      cyrillicOnly: "Разрешена только кириллица",
      latinAndNumbersOnly: "Разрешены только латиница и цифры",
      docName: `Разрешена только кириллица и дефис`,
      docNameV2: `Разрешены только кириллица, пробел и дефис`,
      cyrillicWithPunctuation: "Разрешены только кириллица, знаки препинания, тире",
      cyrillicWithPunctuationAndNumbers: "Разрешены только кириллица, знаки препинания, тире и цифры",
      maxLength: `Не более ${this.config.maxLength} ${this.getSymbolDecl()}`,
      minLength: `Не менее ${this.config.minLength} ${this.getSymbolDecl()}`,
      email: "Неверный формат электронной почты (your@mail.ru)",
      mustContainLetters: "Текст поля должен содержать буквы",
      mustContainUpperCase: "Текст поля должен содержать буквы верхнего регистра",
      mustContainLowerCase: "Текст поля должен содержать буквы нижнего регистра",
      mustContainNumbers: "Текст поля должен содержать цифры",
      passwordsMustBeEqual: "Пароли не совпадают",
      restrictForeignSymbByPercent: `Не более ${this.config.restrictForeignSymbByPercent}% латинских букв от общего числа символов`,
      restrictUppercaseSymbByPercent: `Не более ${this.config.restrictUppercaseSymbByPercent}% заглавных букв от общего числа символов`,
      isLink: "Некорректная ссылка",
    }[rule];
  }

  getErrorList() {
    return Object.keys(this.config)
      .filter((key) => !this[`check${capitalize(key)}`](this.input.value))
      .map(this.getMapErr.bind(this));
  }
}

// Move to utils
function capitalize(s) {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
}

export default Validator;
