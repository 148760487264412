import { API, httpParamsSerialize } from "./api";
import { SittingKey, SittingsData } from "../types/Sittings.interface";

const SittingsService = {
  getSittingByKey: (key: SittingKey): Promise<SittingsData> => {
    return API.get(`/api/settings/get?${httpParamsSerialize({ key })}`).then(
      ({ data }) => data
    );
  },
  saveSittings(data: SittingsData) {
    const _data = data.updated ? data : { ...data, updated: new Date() };
    return API.post("/api/settings/save", _data).then(({ data }) => data);
  },
};

export default SittingsService;
