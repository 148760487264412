import { FC, useCallback, useContext, useEffect, useState } from "react";

import "./AllComments.scss";
import { Comment, Discussion, DiscussionStatus } from "../Discussion.interface";
import CommentCard, { CommentProps } from "../Comment/Comment";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../types/State.interface";
import { useMediaQuery } from "react-responsive";
import { phoneWidth } from "../../../../../../utils/constants/widthConstants";

export interface AllCommentsProps {
  comments: Comment[];
  discussion: Discussion;
  isLikeVisible: CommentProps["isLikeVisible"];
  onVote: (comment: Comment) => Promise<void>;
  isLikeDisabled?: boolean;
}

const AllComments = ({ comments, discussion, onVote, isLikeVisible, isLikeDisabled }: AllCommentsProps) => {
  const env = useSelector((state: RootState) => state.environment);
  const isPhone = useMediaQuery({ query: `(max-width: ${phoneWidth}px)` });

  const getDiscussionLink = (discussion) => `${env.platformUrl}/discussion/${discussion.id}`;

  const expandButton = (
    <a href={getDiscussionLink(discussion)} target="_blank" className="all-comments__expand">
      Посмотреть все
    </a>
  );

  return (
    <div className="all-comments">
      <div className="all-comments__head">
        <div className="all-comments__title">Комментарии и ответы</div>
        {!isPhone && expandButton}
      </div>
      <div className="all-comments__list">
        {comments
          ?.slice(0, 2)
          .filter(Boolean)
          .map((comment) => (
            <CommentCard
              tooltipId={`${comment.id}-all-comments`}
              comment={comment}
              copyLinkUrl={`${getDiscussionLink(discussion)}/${comment.id}?navigate=true`}
              answerLinkUrl={`${getDiscussionLink(discussion)}/${comment.id}?navigate=true&openForm=true`}
              isAnswerButtonVisible={discussion.status === DiscussionStatus.STARTED}
              isLiked={comment.voted}
              isLikeVisible={isLikeVisible}
              onVote={onVote}
              isLikeDisabled={isLikeDisabled}
            />
          ))}
        {isPhone && expandButton}
      </div>
    </div>
  );
};

export default AllComments;
