import React from "react";
import { Redirect, Route, useHistory, useParams } from "react-router-dom";

import MainPage from "../components/pages/Main/MainPage";
import ArchiveContainer from "../components/containers/Archive/ArchiveContainer";
import ArchiveProjectContainer from "../components/containers/Archive/ArchiveProjectContainer";
import NewsContainer from "../components/containers/News/NewsContainer";
import PublicationsContainer from "../components/containers/Publications/PublicationsContainer";
import GenericProjectContainer from "../components/containers/Project/GenericProjectContainer";
import AnnouncementContainer from "../components/containers/Announcement/AnnouncementContainer";
import CurrentContainer from "../components/containers/Current/CurrentContainer";
import PhotosContainer from "../components/containers/Photos/PhotosContainer";
import MayorAppealContainer from "../components/containers/MayorAppeal/MayorAppealContainer";
import VideoContainer from "../components/containers/Video/VideoContainer";
import FailedEmailConfirmation from "../components/pages/FailedEmailConfirmation/FailedEmailConfirmation";
import SuccessEmailConfirmation from "../components/pages/SuccessEmailConfirmation/SuccessEmailConfirmation";
import IdeaService from "../services/ideaService";
import { findUrlParam } from "../utils";
import PointsRulesPage from "../components/pages/PointsRules/PointsRulesPage";
import SmartRoute from "./SmartRoute";
import ProposeIdeaPage from "../components/pages/ProposeIdea/ProposeIdeaPage";
import ProposeIdeaDetailsPage from "../components/pages/ProposeIdeaDetails/ProposeIdeaDetailsPage";
import WorkResultPage from "../components/pages/WorkResult/WorkResultPage";
import WorkResultDetailedPage from "../components/pages/WorkResultDetailed/WorkResultDetailedPage";
import AboutPage from "../components/pages/About/AboutPage";
import IdeasPage from "../components/pages/Ideas/IdeasPage";

const indexedRoutes = [
  <Route exact path="/about" component={AboutPage} key={"AboutPage"} />,
  <Route exact path="/video" component={VideoContainer} key={"VideoContainer"} />,
  <Route
    path="/archive"
    render={(props) => {
      return props.location.search.startsWith("?id=") ? <ArchiveProjectContainer /> : <ArchiveContainer />;
    }}
    key={"Archive"}
  />,
  <Route exact path="/news" component={NewsContainer} key={"NewsContainer"} />,
  <Route exact path="/news/:id" component={NewsContainer} key={"NewsContainerWithId"} />,
  <Route
    path="/announcement"
    render={(props) => {
      return props.location.search.startsWith("?id=") ? <GenericProjectContainer /> : <AnnouncementContainer />;
    }}
    key={"Announcement"}
  />,
  <Route
    path="/current"
    render={(props) => {
      return props.location.search.startsWith("?id=") ? <GenericProjectContainer /> : <CurrentContainer />;
    }}
    key={"Current"}
  />,
  <Route
    path="/plan"
    render={(props) => {
      return props.location.search.startsWith("?id=") && <ArchiveProjectContainer />;
    }}
    key={"Plan"}
  />,
  <Route path="/ideas/:ideaId" component={ProposeIdeaShared} key={"ProposeIdeaShared"} />,
  <Route
    path="/ideas"
    render={(props) => {
      return props.location.search.startsWith("?id=") ? <ArchiveProjectContainer /> : <IdeasPage />;
    }}
    key={"Ideas"}
  />,
  <Route path="/photos" component={PhotosContainer} key={"PhotosContainer"} />,
  <Route exact path="/propose" component={ProposeIdeaPage} key={"ProposeIdeaPage"} />,
  <Route exact path="/propose/:category" component={ProposeIdeaDetailsPage} key={"ProposeIdeaDetailsPage"} />,
  <Route path="/speech" component={MayorAppealContainer} key={"MayorAppealContainer"} />,
  <Route path="/gorod/ideas/:ideaId" component={ProposeIdeaShared} key={"ProposeIdeaShared"} />,
  <Route path="/realized/ideas/:ideaId" component={ProposeIdeaShared} key={"RealizedIdeaShared"} />,
  <Route path="/projects/:projectId" key="BannerProjectShared">
    <Redirect to="/" />
  </Route>,
  <Route path="/reward/:rewardType" key="UserRewardShared">
    <Redirect to="/" />
  </Route>,
  <Route path="/videos/:videoId?" component={VideoShared} key="VideoShared" />,
  <Route path="/successEmailConfirmation" component={SuccessEmailConfirmation} key={"SuccessEmailConfirmation"} />,
  <Route path="/failedEmailConfirmation" component={FailedEmailConfirmation} key={"FailedEmailConfirmation"} />,
  <Route exact path="/workresult" component={WorkResultPage} key={"WorkResultPage"} />,
  <Route exact path="/workresult/detailed" component={WorkResultDetailedPage} key={"WorkResultDetailedPage"} />,
  <Route exact path="/public" component={PublicationsContainer} key={"PublicationsContainer"} />,
  <Route exact path="/public/:id" component={PublicationsContainer} key={"PublicationsContainerWithId"} />,
];

function ProposeIdeaShared(routeState) {
  let { ideaId } = routeState?.match.params;
  ideaId = ideaId.replace("&noparse=true", "");
  const history = useHistory();

  if (routeState.match.path.includes("/gorod")) {
    const sphere = new URLSearchParams(routeState?.location.hash.slice(1)).get("sphere");
    history.push(`/propose/${sphere}?page=0&id=${ideaId}&fromShare=true`, {
      ideaId,
    });
  } else if (routeState.match.path.includes("/realized")) {
    const p = new URLSearchParams(routeState?.location.hash.slice(1));
    const projectId = p.get("projectId");
    const groupDirectionId = p.get("groupDirectionId");
    history.push(
      `/workresult?id=${projectId}&ideaId=${ideaId}${groupDirectionId ? `&groupDirectionId=${groupDirectionId}` : ""}`
    );
  } else {
    history.push(`/ideas?page=0&id=${ideaId}`, { ideaId });
  }

  return <div></div>;
}

function VideoShared(routeState) {
  const { videoId } = routeState?.match.params;
  return <Redirect to={`/video?page=1${videoId ? `&vid=${videoId}` : ""}`} />;
}

export default indexedRoutes;
