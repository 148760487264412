import React, { Suspense, useEffect, useLayoutEffect, useMemo, useState } from "react";
import { CityCounter } from "../CircleCounterList.data";
import { generateInnerCircle, generateOuterCircle } from "./CircleCounter.helper";

import "./CircleCounter.scss";
import { formatNumber } from "../../../../../../utils";

interface CircleCounterProps {
  data: CityCounter;
  onClick?: () => void;
}

const CircleCounter = ({ data: { title, count, subtitle, colors, innerCircleSrc, outerCircleSrc }, onClick }: CircleCounterProps) => {
  return (
    <div className="circle-counter">
      <img src={outerCircleSrc} className="circle-counter__outer" />
      <img src={innerCircleSrc} className="circle-counter__inner" />

      <div className="circle-counter__info">
        <div className="circle-counter__title" style={{color: colors.title}}>{title}</div>
        <div className="circle-counter__count">{formatNumber(count)}</div>
        <div className="circle-counter__subtitle">{subtitle(count)}</div>
      </div>
    </div>
  );
};

export default CircleCounter;
