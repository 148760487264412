import { BaseResponse } from "../types/Common.interface";
import { API } from "./api";

export interface GetStatisticResponse {
  data: SphereStatistic;
}

export interface SphereStatistic {
  activeCategoryCount: number;
  completedIdeaCount: number;
  ideaInProcessCount: number;
}

const ProposeService = {
  getCategories: () => {
    return API.get("/api/gorod/idea/categories").then(({ data }) => {
      return data;
    });
  },

  sendProposition: (query, fromLanding?: boolean) => {
    return API.post(
      `/api/gorod/idea/add${typeof fromLanding !== "undefined" ? `?fromLanding=${fromLanding}` : ""}`,
      query
    ).then(({ data }) => {
      return data;
    });
  },

  getCategoryByKeyword: (keyWordQuery) => {
    return API.get(`/api/gorod/idea/categoryByKeyWord?keyWordQuery=${keyWordQuery}`).then(({ data }) => {
      return data;
    });
  },

  sendAgreementConfirmed: () => {
    return API.post("/api/profile/agreement/confirm").then(({ data }) => {
      return data;
    });
  },

  getStatistic: (query) => {
    return API.get<GetStatisticResponse>(`/api/gorod/idea/statistic?id=${query.id}`).then(({ data }) => {
      return data;
    });
  },

  getKeywords: () => {
    return API.get(`/api/gorod/idea/categoryKeyWords`).then(({ data }) => {
      return data;
    });
  },

  getGorodIdeasCount: (projectId: string) => {
    return API.get(`/api/gorod/idea/count?projectId=${projectId}`).then(({ data }) => {
      return data;
    });
  },
};

export default ProposeService;
