import { FC } from "react";
import Modal from "../Modal/Modal";
import "./ModalWelcome.scss";

const ModalWelcome: FC = () => (
  <Modal
    className="modal-welcome"
    isOpened
    onClose={() => {}}
    title={
      <>
        Приглашаем вас в гости
        <br />к электронным городским проектам!
      </>
    }
    content={
      <>
        Окунитесь в уютную атмосферу новогоднего праздника и узнайте больше о возможностях проектов и их достижениях.
        Перемещайтесь по интерактивному дому, используя кнопку «Прокатиться на лифте» или листая страницу вниз. Найдите
        спрятанные в интерьере предметы и ознакомьтесь с фактами о проектах.
      </>
    }
  />
);

export default ModalWelcome;
