export const randomSort = () => {
  return 0.5 - Math.random();
};

export const cards = [
  { id: 1, src: require("../../../../../../assets/newyear2023/games/memory/flash.png").default },
  { id: 2, src: require("../../../../../../assets/newyear2023/games/memory/computer.png").default },
  { id: 3, src: require("../../../../../../assets/newyear2023/games/memory/camera.png").default },
  { id: 4, src: require("../../../../../../assets/newyear2023/games/memory/headphones.png").default },
  { id: 5, src: require("../../../../../../assets/newyear2023/games/memory/multimedia.png").default },
  { id: 6, src: require("../../../../../../assets/newyear2023/games/memory/phone.png").default },
  { id: 7, src: require("../../../../../../assets/newyear2023/games/memory/speaker.png").default },
  { id: 8, src: require("../../../../../../assets/newyear2023/games/memory/technology.png").default },
];
