import { FC, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LocalStorageKeys, LocalStorageService } from "../../../../services/LocalStorage.service";
import { addPostMessageListener } from "../../../../store/actions/PostMessageActions";
import { setSudirUserDetails } from "../../../../store/actions/UserActions";
import { SudirUserDetails } from "../../../../types/User.interface";
import { inIframeNow, isTopLevelWindow, postToParent } from "../PostMessageListener/PostMessage.helper";
import { ActiveSessionForm } from "./ActiveSessionForm/ActiveSessionForm";

const ContainerName = "SudirSessionContainer";
export const SudirCheckOnlyPath = "oauth2/authorization/sudir?onlyCheck=true";

const SudirSessionContainer: FC = ({ children }) => {
  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState(false);
  const userDetails = useSelector((state: any) => state.user.userDetails);
  const sudirUserDetails = useSelector((state: any) => state.user.sudirUserDetails);
  const forcedLoggedOut = useSelector((state: any) => state.user.forcedLoggedOut);

  const postMessageHandler = (sudirProfileData: SudirUserDetails) => dispatch(setSudirUserDetails(sudirProfileData));
  useEffect(() => {
    isTopLevelWindow() &&
      dispatch(
        addPostMessageListener({
          source: ContainerName,
          handler: postMessageHandler,
        })
      );
  }, []);

  useEffect(() => {
    if (userDetails) {
      if (LocalStorageService.isStorageAvailable()) {
        const userForcedLoggedOut = LocalStorageService.getData(LocalStorageKeys.CROWD_FORCED_LOGOUT);
        if (!userDetails.loggedIn && !forcedLoggedOut && sudirUserDetails) {
          const { isRegisteredOnCrowd } = sudirUserDetails;
          if (!userForcedLoggedOut) {
            if (isRegisteredOnCrowd) {
              window.location.href = `${window.location.origin}/oauth2/authorization/sudir?returnUrl=${window.location.href}`;
            } else {
              setIsOpen(true);
            }
          }
        } else if (userDetails.loggedIn && userForcedLoggedOut) {
          LocalStorageService.removeData(LocalStorageKeys.CROWD_FORCED_LOGOUT);
        }
      }
    }
  }, [userDetails, sudirUserDetails]);

  /**
   * Для локального тестирования раскомментить все что ниже
   */

  useEffect(() => {
    const querystringParams = new URLSearchParams(window.location.search);
    const isRegisteredOnCrowd = querystringParams.get("registered"); // будет true только если у пользователя до конца заполенный акк Крауда. Если false, то это либо первый вход, либо не заполнены форма подтверждения акка.
    if (isRegisteredOnCrowd && inIframeNow()) {
      postToParent(ContainerName, {
        isRegisteredOnCrowd: isRegisteredOnCrowd === "true",
        firstName: querystringParams.get("firstName"),
        lastName: querystringParams.get("lastName"),
        phone: querystringParams.get("phone"),
      });
    }

    // if (inIframeNow() && shouldShowSudirSessionModal) {
    //   postToParent(ContainerName, {
    //     sudirProfileExists: true,
    //     crowdProfileExists: false,
    //     firstName: "FirstName",
    //     lastName: "LastName",
    //     phone: "79000011213",
    //   });
    // }
  }, []);

  const renderIframe = useMemo(() => {
    if (isTopLevelWindow()) {
      if (userDetails && !userDetails.loggedIn) {
        return <iframe style={{ display: "none" }} src={`${window.location.origin}/${SudirCheckOnlyPath}`} />;
        // return <iframe src="/" style={{ display: "none" }} />
      }
    }
    return <></>;
  }, [userDetails]);

  return (
    <>
      {children}
      {renderIframe}
      <ActiveSessionForm isOpen={isOpen} onClose={() => setIsOpen(false)} />
    </>
  );
};

export default SudirSessionContainer;
