import { FC, useCallback, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import {
  EventsTrackConfig,
  EventsTrackData,
  EventsTrackId,
} from "../../../types/EventsTrack.interface";
import { RootState } from "../../../types/State.interface";
import { getTimestamp, isObject, sprintf } from "../../../utils";
import { useHistory, Prompt } from "react-router-dom";

interface Props {
  config: EventsTrackConfig;
}

/**
 * находит самую глубокую запись в объекте data и заменяет последнее событие на mergeData
 */
const mergeDeepestEventDataObjectHelper = (
  data: EventsTrackData,
  mergeData: EventsTrackData
): EventsTrackData => {
  let d = data;
  let k = Object.keys(d)[0];
  const res = {};
  let link = res;
  let i = 0;
  while (k && i < 20) {
    if (isObject(d[k])) {
      link[k] = {};
      link = link[k];
      d = d[k];
      k = Object.keys(d)[0];
    } else {
      break;
    }
    i++;
  }
  Object.entries(mergeData).forEach(([mk, mv]) => {
    link[mk] = mv;
  });
  return res;
};

const EventsTrackContainer: FC<Props> = ({ config, children }) => {
  const { params, callback } = config;
  const lastEventClickDataRef = useRef<EventsTrackData>(null);
  const eventsTrackScrollCounterTrigger = useSelector(
    (state: RootState) => state.layout.eventsTrackScrollCounterTrigger
  );
  const history = useHistory();
  const pageTimeRef = useRef(getTimestamp());
  const prevPathnameRef = useRef(history.location.pathname);
  // колбэк для отслеживания click событий
  const handlerClick = useCallback(
    function (e) {
      // ищем ближайший элемент с аттрибутом data-click-id (не более 10 итераций)
      for (
        let target = e.target, i = 0;
        target &&
        target !== e.currentTarget &&
        target !== document.body &&
        i < 10;
        target = target.parentNode, i++
      ) {
        if (target.hasAttribute("data-click-id")) {
          const trackIds: EventsTrackId[] = JSON.parse(
            target.getAttribute("data-click-id")
          );
          const data = trackIds.reduce<EventsTrackData>(
            (acc, trackId) =>
              mergeDeepestEventDataObjectHelper(
                acc,
                params.click?.[trackId]?.data || params.common?.[trackId]?.data
              ),
            {}
          );
          const { partial, remember, rememberPrev } =
            params.click?.[trackIds[trackIds.length - 1]];
          if (Object.keys(data).length) {
            const replace: string[] = target.hasAttribute("data-replace")
              ? JSON.parse(target.getAttribute("data-replace"))
              : [];
            let sendData = replace.length
              ? JSON.parse(sprintf(JSON.stringify(data), ...replace))
              : data;

            // если нужно добавить событие к предыдущему
            if (partial && !lastEventClickDataRef.current) {
              break;
            } else if (partial) {
              sendData = mergeDeepestEventDataObjectHelper(
                lastEventClickDataRef.current,
                sendData
              );
            }

            callback(sendData);
            // запоминаем последнее событие или забываем
            if (remember) {
              lastEventClickDataRef.current = sendData;
            } else if (!rememberPrev) {
              lastEventClickDataRef.current = null;
            }
          }
          break;
        }
      }
    },
    [params, callback]
  );

  // колбэк при уходе со страницы (перед сменой урла react router или beforeunload)
  const leavePageCallback = useCallback(
    (e: Location | BeforeUnloadEvent) => {
      const nextPathname = "pathname" in e ? e.pathname : null;
      // отправляем событие если beforeunload или pathname действительно поменялся в react router
      if (nextPathname !== document.location.pathname) {
        const currentTime = getTimestamp();
        callback({
          leave: {
            page_time: currentTime - pageTimeRef.current,
          },
        });
        pageTimeRef.current = currentTime;
      }

      if (lastEventClickDataRef.current) {
        sessionStorage.setItem(
          "lastEventClick",
          JSON.stringify(lastEventClickDataRef.current)
        );
      }

      return true;
    },
    [callback]
  );

  useEffect(() => {
    const lastEventClick = sessionStorage.getItem("lastEventClick");
    if (lastEventClick) {
      lastEventClickDataRef.current = JSON.parse(lastEventClick);
      sessionStorage.removeItem("lastEventClick");
    }
  }, []);

  // слушатель IntersectionObserver для scroll событий
  useEffect(() => {
    const intersectionMap = new Map();
    const minRatio = 0;
    const maxRatio = 0.5;
    const observer = new IntersectionObserver(
      (entries) => {
        if (
          document.documentElement.scrollHeight >
          document.documentElement.clientHeight
        ) {
          entries.forEach((entry) => {
            const prevIntersected = intersectionMap.get(entry.target);
            // отправка срабатывает после показа блока на половину или более
            // при условии, что до этого блок полностью побывал за пределами вьюпорта
            if (!prevIntersected && entry.intersectionRatio >= maxRatio) {
              const trackIds: EventsTrackId[] = JSON.parse(
                entry.target.getAttribute("data-scroll-id")
              );
              const data = trackIds.reduce<EventsTrackData>(
                (acc, trackId) =>
                  mergeDeepestEventDataObjectHelper(
                    acc,
                    params.scroll?.[trackId]?.data ||
                      params.common?.[trackId]?.data
                  ),
                {}
              );
              const replace: string[] = entry.target.hasAttribute("data-replace")
                ? JSON.parse(entry.target.getAttribute("data-replace"))
                : [];
              let sendData = replace.length
                ? JSON.parse(sprintf(JSON.stringify(data), ...replace))
                : data;
              callback(sendData);
              intersectionMap.set(entry.target, true);
            } else if (prevIntersected && entry.intersectionRatio <= minRatio) {
              intersectionMap.set(entry.target, false);
            }
          });
        }
      },
      {
        threshold: [minRatio, maxRatio],
      }
    );

    const nodes = document.body.querySelectorAll("[data-scroll-id]");
    nodes.forEach((node) => {
      observer.observe(node);
      intersectionMap.set(node, true);
    });
    return () => {
      observer.disconnect();
    };
  }, [params, callback, eventsTrackScrollCounterTrigger]);

  // событие при входе в приложение
  useEffect(() => {
    const referrer = document.referrer;
    const referrerHost = referrer ? new URL(referrer).host : null;
    if (referrerHost === document.location.host) {
      // переход с того же хоста
      callback({ view: "true" });
    } else {
      // переход с внешнего источника/прямой переход
      const eventParams: { [K: string]: any } = {
        referrer: referrer || "прямой переход",
      };
      const utmKeys = ["utm_source", "utm_medium"];
      new URLSearchParams(document.location.search).forEach((v, k) => {
        if (utmKeys.includes(k)) {
          if (!eventParams.utm) {
            eventParams.utm = {};
          }
          eventParams.utm[k] = v;
        }
      });
      callback({ view: eventParams });
    }
  }, []);

  // событие при переходе через react router
  useEffect(() => {
    const unlistenHistory = history.listen((location) => {
      if (prevPathnameRef.current !== location.pathname) {
        callback({ view: "true" });
        prevPathnameRef.current = location.pathname;
      }
    });

    return () => unlistenHistory();
  }, [history, callback]);

  // событие при beforeunload
  useEffect(() => {
    window.addEventListener("beforeunload", leavePageCallback);
    return () => window.removeEventListener("beforeunload", leavePageCallback);
  }, [leavePageCallback]);

  // слушатель кликов на body, тк есть элементы вне react дерева (например иконка чата)
  useEffect(() => {
    document.body.addEventListener("click", handlerClick, true);
    return () => document.body.removeEventListener("click", handlerClick, true);
  }, [handlerClick]);

  return (
    <>
      <Prompt when={true} message={leavePageCallback} />
      {children}
    </>
  );
};

export default EventsTrackContainer;
