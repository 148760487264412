export const gameTitle = "Новогоднее судоку";

export const startText =
  'Разложите елочные игрушки по коробкам за 2 минуты и получите <a href="new_year2024/Правила_участия_в_квесте.pdf" target="_blank">100 баллов</a>! Выберите игрушку с боковой панели, кликнув на нее. Затем выберите ячейку, в которую необходимо поместить игрушку, также кликнув на нее. Обратите внимание, что игрушки не должны повторяться в коробках, а также в вертикальных и горизонтальных рядах.';

export const decorations = [
  {
    text: "present",
    value: 1,
    src: require("../../../../../assets/newyear2023/games/sudoku/images/present.png").default,
  },
  {
    text: "bubl",
    value: 2,
    src: require("../../../../../assets/newyear2023/games/sudoku/images/bubl.png").default,
  },
  {
    text: "camera",
    value: 3,
    src: require("../../../../../assets/newyear2023/games/sudoku/images/camera.svg").default,
  },
  {
    text: "ball",
    value: 4,
    src: require("../../../../../assets/newyear2023/games/sudoku/images/ball.svg").default,
  },
];
