import React, { Component } from "react";

import MetaDecorator from "../../presentational/MetaDecorator/MetaDecorator";
import PageTitle from "../../presentational/PageTitle/PageTitle";
import PageDescription from "../../presentational/PageDescription/PageDescription";
import ProjectFlipcard from "../../presentational/Cards/ProjectFlipcard/ProjectFlipcard";
import { declOfNum } from "../../../utils";
import { Project } from "../../../types/Projects.interface";
import { Stats } from "../../../types/Stats.interface";
import "./ArchivePage.scss";

interface Props {
  projects: Project[];
  stats: Stats;
}

class ArchivePage extends Component<Props, any> {
  constructor(props) {
    super(props);
  }

  renderList = () => {
    return (
      <div className="archive__list">
        {this.props.projects.map((project, idx) => (
          <ProjectFlipcard
            type="archive"
            project={project}
            defaultLogo={
              require("../../../assets/logo/default/vertical.png").default
            }
            isFlippable={true}
            key={idx}
          />
        ))}
      </div>
    );
  };

  projectDecl(count) {
    return declOfNum(count || 0, ["проект", "проекта", "проектов"]);
  }
  ideasDecl(count) {
    return declOfNum(count || 0, ["идея", "идеи", "идей"]);
  }
  realizationDecl(count) {
    return declOfNum(count || 0, ["реализован", "реализовано", "реализовано"]);
  }

  numberWithSpaces(num) {
    return num?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  }

  get descriptionText() {
    const {
      archivedProjectCount,
      proposalCount,
      processedIdeaCount,
      realizedIdeaCount,
      realizedIdeaCountSetting,
    } = this.props.stats;

    return `На данный момент ${this.realizationDecl(
      archivedProjectCount
    )} <b>${archivedProjectCount} ${this.projectDecl(
      archivedProjectCount
    )}</b>, в рамках которых жители города предлагают идеи по развитию столицы. Предложения участников проходят тщательный экспертный отбор и пользовательское голосование, после чего реализовываются городом.`;
  }

  render() {
    const title = "«Город идей» — Выполненные проекты";
    const description = "Наблюдайте за ходом реализации идей жителей в разделе «Выполненные проекты» — предложения участников платформы уже изменили такие сферы города, как экология, транспорт, парки, спорт, предпринимательство, культура и многие другие.";
    return (
      <section className="archive">
        <MetaDecorator
          title={title}
          description={description}
          opengraph={{title, description}}
        />
        <div className="archive__title">Выполненные проекты</div>
        <div
          className="archive__description"
          dangerouslySetInnerHTML={{ __html: this.descriptionText }}
        />

        {this.renderList()}
      </section>
    );
  }
}

export default ArchivePage;
