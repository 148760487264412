import { FC } from "react";
import "./Gallery.scss";
import IdeasList from "../../../../containers/IdeasList/IdeasList";

const tags = [
  "Музеи",
  "Библиотеки",
  "Мероприятия",
  "Культурные центры",
  "Парки",
  "Культурные мероприятия",
  "Культура онлайн",
  "Городские мастерские",
];

const Gallery: FC = () => {
  return (
    <section className="discussion-landing-gallery">
      <div className="discussion-landing-title">ГАЛЕРЕЯ РЕАЛИЗОВАННЫХ ИДЕЙ</div>
      <div className="discussion-landing-text">
        В этом разделе публикуются фотографии реализованных идей жителей по развитию культурного досуга в Москве.
      </div>
      <IdeasList
        isShowInfoCard={false}
        isAllPublished
        isRandom
        loop
        isShowAllInNewTab
        tags={tags}
        spaceBetween={24}
        maxPages={20}
        viewerClassName="discussion-landing-modal"
      />
    </section>
  );
};

export default Gallery;
