import { useState, useEffect, useMemo, useCallback } from "react";
import "./Game.scss";
import Timer from "../../Timer/Timer";
import { GridBuilder } from "./Game.helper";
import { decorations } from "../SudokuGame.constants";

interface GameProps {
  onWin?: () => void;
  onLoose?: () => void;
}

const Game: React.FC<GameProps> = ({ onWin, onLoose }) => {
  const [grid, setGrid] = useState([]);
  const [solutions, setSolutions] = useState([]);
  const [decoration, setDecoration] = useState(null);

  const generateGrid = () => {
    const { grid, solutions } = GridBuilder.generateGrid();
    setGrid(grid);
    setSolutions(solutions);
  };

  const decorate = useCallback((row: number, col: number) => {
    if (grid[row][col] === 0 && decoration) {
      const newGrid = grid.slice();
      newGrid[row][col] = decoration;
      setGrid(newGrid);
      setDecoration(null);
      if (GridBuilder.isGridFilled(newGrid)) {
        if (GridBuilder.isGridSolved(newGrid, solutions)) {
          onWin();
        } else {
          onLoose();
        }
      }
    }
  }, [grid, decoration, solutions, onWin, onLoose]);

  const getDecorationTemplate = (value: number) => {
    const decoration = decorations.find((decoration) => decoration.value === value);
    if (decoration) {
      return <img src={decoration.src} alt={decoration.text}></img>;
    }
    return null;
  };

  useEffect(() => {
    generateGrid();
  }, []);

  const renderImageInsertionGrid = useMemo(() => {
    return (
      <table className="grid__table">
        <tbody>
          {grid.map((row, i) => (
            <tr key={i} className="grid__row">
              {row.map((value: number, j) => (
                <td onClick={() => decorate(i, j)} key={j} className="grid__cell">
                  <div className="grid__image-container">{getDecorationTemplate(value)}</div>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    );
  }, [grid, decorate]);

  const renderImagePicker = useMemo(() => {
    return (
      <table className="grid__table">
        <tbody>
          {decorations.map((decoration) => {
            return (
              <tr className="grid__row" key={decoration.value}>
                <td className="grid__cell" onClick={() => setDecoration(decoration.value)}>
                  <button className="grid__image-container">
                    <img src={decoration.src} alt={decoration.text} />
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }, [decorations, setDecoration]);

  return (
    <div className="sudoku-game">
      <div className="sudoku-game__timer">
        <Timer onTimeExpire={onLoose} initialMinutes={2} />
      </div>
      <div className="sudoku-game__content">
        <div className="sudoku-game__grid grid">
          <table className="grid__background">
            <tbody>
              <tr>
                <td className="grid__background-cell"></td>
                <td className="grid__background-cell"></td>
              </tr>
              <tr>
                <td className="grid__background-cell"></td>
                <td className="grid__background-cell"></td>
              </tr>
            </tbody>
          </table>
          {renderImageInsertionGrid}
        </div>

        <div className="grid grid--decorator">{renderImagePicker}</div>
      </div>
    </div>
  );
};
export default Game;
