import BirthdayCongrats from "./Components/BirthdayCongrats/BirthdayCongrats";
import BirthdayGallery from "./Components/BirthdayGallery/BirthdayGallery";
import BirthdayGame from "./Components/BirthdayGame/BirthdayGame";
import BirthdayMain from "./Components/BirthdayMain/BirthdayMain";
import BirthdayMayor from "./Components/BirthdayMayor/BirthdayMayor";
import BirthdaySpheres from "./Components/BirthdaySpheres/BirthdaySpheres";

export enum BirthdaySections {
  MAIN = "MAIN",
  MAYOR = "MAYOR",
  CONGRATULATION = "CONGRATULATION",
  PROPOSE = "PROPOSE",
  GALLERY = "GALLERY",
  GAME = "GAME",
}

export enum BirthdayUrls {
  PROPOSE = "/birthday/propose",
  GAME_RULES = "/birthday9/game-rules.pdf",
  CONGRATULATION_RULES = "/birthday9/congratulation-rules.pdf",
}

export interface Birthday9Section {
  id: BirthdaySections;
  text: string;
  component: JSX.Element;
  href?: string;
}

export const sections: Birthday9Section[] = [
  {
    id: BirthdaySections.MAIN,
    text: "Главная",
    component: <BirthdayMain></BirthdayMain>,
  },
  {
    id: BirthdaySections.MAYOR,
    text: "Обращение Мэра",
    component: <BirthdayMayor></BirthdayMayor>,
  },
  {
    id: BirthdaySections.CONGRATULATION,
    text: "Поздравления",
    component: <BirthdayCongrats></BirthdayCongrats>,
  },
  {
    id: BirthdaySections.GALLERY,
    text: "Галерея",
    component: <BirthdayGallery></BirthdayGallery>,
  },
  {
    id: BirthdaySections.GAME,
    text: "Игра",
    component: <BirthdayGame></BirthdayGame>,
  },
  {
    id: BirthdaySections.PROPOSE,
    text: "Предложить идею",
    component: <BirthdaySpheres></BirthdaySpheres>,
    href: BirthdayUrls.PROPOSE,
  },
];
