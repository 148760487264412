export const LEISURE_LANDING_SET_PROGRESS = "LEISURE_LANDING_SET_PROGRESS";
export const LEISURE_LANDING_SET_ACTIVE_INDEX = "LEISURE_LANDING_SET_ACTIVE_INDEX";
export const LEISURE_LANDING_SET_SCALE = "LEISURE_LANDING_SET_SCALE";

export const leisureLandingSetProgress = (n: number, forward = true) => ({
  type: LEISURE_LANDING_SET_PROGRESS,
  payload: {
    progress: n,
    forward,
  },
});

export const leisureLandingAddProgress = (n: number) => (dispatch, getState) => {
  const {
    leisureLanding: { progress },
  } = getState();
  dispatch({
    type: LEISURE_LANDING_SET_PROGRESS,
    payload: {
      progress: progress + n,
      forward: n >= 0,
    },
  });
};

export const leisureLandingSetActiveIndex = (n?: number) => ({
  type: LEISURE_LANDING_SET_ACTIVE_INDEX,
  payload: n,
});

export const leisureLandingSetScale = (n: number) => ({
  type: LEISURE_LANDING_SET_SCALE,
  payload: n,
});
