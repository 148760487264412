import { FC } from "react";
import Button from "../Button";
import Modal from "../Modal";
import "./ScreenStub.scss";

const ScreenStub: FC = () => (
  <div className="leisure-landing-screen-stub">
    <Modal
      className="leisure-landing-screen-stub__modal"
      onClose={() => {}}
      content={
        <>
          <div>
            Вы можете открыть тематическую страницу «Досуг в городе» на компьютере и ознакомиться с реализованными
            идеями жителей, которые были предложены на платформе «Город идей».
          </div>
          <Button
            className="leisure-landing-screen-stub__modal__btn"
            onClick={() => {
              window.location.href = "/";
            }}
          >
            Перейти на «Город идей»
          </Button>
        </>
      }
    />
  </div>
);

export default ScreenStub;
