import React, { Component } from "react";
import ContentLoader from "react-content-loader";

import Platform from "../../../../services/platform";

export default function MainPageLoader(props) {
  if (Platform.isPhone()) {
    return (
      <div style={{ width: "100%" }}>
        <ContentLoader
          viewBox="0 0 100 100"
          preserveAspectRatio="none"
          {...props}
          title="Загрузка..."
        >
          <rect x="0" y="5" rx="0" ry="0" width="100" height="8"></rect>
          <rect x="0" y="15" rx="0" ry="0" width="100" height="12"></rect>
          <rect x="0" y="30" rx="0" ry="0" width="100" height="40"></rect>
        </ContentLoader>
      </div>
    );
  } else {
    return (
      <div style={{ width: "100%" }}>
        <ContentLoader
          viewBox="0 0 100 100"
          preserveAspectRatio="none"
          title="Загрузка..."
          {...props}
        >
          {/* Left banner content */}
          <rect x="0" y="8" rx="0" ry="0" width="40" height="6"></rect>
          <rect x="0" y="15" rx="0" ry="0" width="40" height="8"></rect>
          <rect x="15" y="24" rx="0" ry="0" width="25" height="3"></rect>
          <rect x="0" y="30" rx="0" ry="0" width="20" height="5"></rect>

          {/* Right rectangles */}
          <rect x="54" y="3" rx="0" ry="0" width="40" height="40"></rect>
          <rect x="78" y="6" rx="0" ry="0" width="25" height="25"></rect>
          <rect x="46" y="22" rx="0" ry="0" width="16" height="16"></rect>

          {/* Bottom Lifecycle */}
          <rect x="0" y="50" rx="0" ry="0" width="30" height="4"></rect>
          <rect x="35" y="50" rx="0" ry="0" width="30" height="4"></rect>
          <rect x="70" y="50" rx="0" ry="0" width="30" height="4"></rect>
        </ContentLoader>
      </div>
    );
  }
}
