import { Reducer } from "redux";
import { nyLandingGetSettings } from "../../components/pages/NYLanding/Utils/settings";
import {
  NYLandingObjectsNormalizedState,
  NYLandingObjectState,
  NYLandingPlatform,
  NYLandingState,
} from "../../types/NYLanding.interface";
import { RootState } from "../../types/State.interface";
import { values } from "../../utils/object";
import {
  NY_LANDING_GAME_FINISH,
  NY_LANDING_GAME_ADD_OBJECT,
  NY_LANDING_GAME_INIT,
  NY_LANDING_LOGIN,
  NY_LANDING_LOGOUT,
  NY_LANDING_PRESENTATION_CLOSE,
  NY_LANDING_PRESENTATION_OPEN,
  NY_LANDING_SET_PLATFORM,
  NY_LANDING_SET_PROGRESS,
  NY_LANDING_GAME_START,
  NY_LANDING_GAME_RESET,
  NY_LANDING_GAME_SET_DEFERRED,
} from "../actions/NYLandingActions";

const createNormalizedObjectsData = <T extends NYLandingObjectState>(
  objects: T[]
): NYLandingObjectsNormalizedState<T> => {
  const ids = [];
  const entries = {};

  for (const object of objects) {
    ids.push(object.id);
    entries[object.id] = object;
  }

  return { ids, entries };
};

const initialPlatform = new URLSearchParams(window.location.search)
  .keys()
  .next().value;
const platform = values(NYLandingPlatform).includes(initialPlatform)
  ? initialPlatform
  : NYLandingPlatform.CROWD;
const { objects: initialObjects } = nyLandingGetSettings(platform);

type ObjectType = typeof initialObjects extends (infer R)[] ? R : never;

const initialState: NYLandingState<ObjectType> = {
  presentationOpen: false,
  platform,
  progress: 0,
  user: {
    details: null,
    loggedIn: false,
  },
  game: {
    started: null,
    finished: null,
    state: {
      foundObjects: createNormalizedObjectsData([]),
      allObjects: createNormalizedObjectsData(initialObjects),
    },
  },
};

export const nyLandingReducer: Reducer<NYLandingState<ObjectType>> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case NY_LANDING_PRESENTATION_OPEN:
      return { ...state, presentationOpen: true };

    case NY_LANDING_PRESENTATION_CLOSE:
      return { ...state, presentationOpen: false };

    case NY_LANDING_SET_PLATFORM:
      return { ...state, platform: action.payload, progress: 0 };

    case NY_LANDING_SET_PROGRESS:
      return { ...state, progress: action.payload };

    case NY_LANDING_GAME_INIT:
      return {
        ...state,
        game: {
          started: null,
          finished: null,
          state: {
            foundObjects: createNormalizedObjectsData([]),
            allObjects: createNormalizedObjectsData(action.payload.objects),
          },
        },
      };

    case NY_LANDING_GAME_RESET:
      return {
        ...state,
        game: {
          started: null,
          finished: null,
          state: {
            ...state.game.state,
            foundObjects: createNormalizedObjectsData([]),
          },
        },
      };

    case NY_LANDING_GAME_START:
      return {
        ...state,
        game: {
          ...state.game,
          started: new Date().getTime(),
        },
      };

    case NY_LANDING_GAME_ADD_OBJECT:
      return state.game.state.foundObjects.entries[action.payload] ||
        !state.game.state.allObjects.entries[action.payload]
        ? state
        : {
            ...state,
            game: {
              ...state.game,
              state: {
                ...state.game.state,
                foundObjects: {
                  entries: {
                    ...state.game.state.foundObjects.entries,
                    [action.payload]:
                      state.game.state.allObjects.entries[action.payload],
                  },
                  ids: [...state.game.state.foundObjects.ids, action.payload],
                },
              },
            },
          };

    case NY_LANDING_GAME_SET_DEFERRED:
      return {
        ...state,
        game: {
          ...state.game,
          state: {
            ...state.game.state,
            allObjects: {
              ...state.game.state.allObjects,
              entries: {
                ...state.game.state.allObjects.entries,
                [action.payload.id]: {
                  ...state.game.state.allObjects.entries[action.payload.id],
                  deferred: action.payload.deferred,
                },
              },
            },
          },
        },
      };

    case NY_LANDING_GAME_FINISH:
      return {
        ...state,
        game: {
          ...state.game,
          finished: new Date().getTime(),
        },
      };

    case NY_LANDING_LOGIN:
      return {
        ...state,
        user: {
          details: action.payload,
          loggedIn: action.payload.loggedIn,
        },
      };

    case NY_LANDING_LOGOUT:
      return {
        ...state,
        user: { ...initialState.user },
      };

    default:
      return state;
  }
};

export const selectNYLandingPresentationOpen = (state: RootState) =>
  state.nyLanding.presentationOpen;

export const selectNYLandingPlatform = (state: RootState) =>
  state.nyLanding.platform;

export const selectNYLandingProgress = (state: RootState) =>
  state.nyLanding.progress;

export const selectNYLandingProgressFinished = (state: RootState) =>
  state.nyLanding.progress === 1;

export const selectNYLandingGame = (state: RootState) => state.nyLanding.game;

export const selectNYLandingGameState = (state: RootState) =>
  state.nyLanding.game.state;

export const selectNYLandingGameEnabled = (state: RootState) =>
  !!state.nyLanding.game.started && !selectNYLandingGameFinished(state);

export const selectNYLandingGameFinished = (state: RootState) =>
  !!state.nyLanding.game.finished;

export const selectNYLandingLoggedIn = (state: RootState) =>
  state.nyLanding.user.loggedIn;

export const selectNYLandingLoggedInUser = (state: RootState) =>
  state.nyLanding.user.details;
