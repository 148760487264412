import { API } from "./api";

const VideoService = {
  getList(params) {
    const query = new URLSearchParams(params).toString();
    return API.get(`/api/video/list${query ? `?${query}` : ""}`).then(
      ({ data }) => data
    );
  },
};

export default VideoService;
