import React, { forwardRef, useEffect, useRef } from "react";

import { ExternalProject, projects } from "../ExternalLinks.helper";

import "./ExternalPopup.scss";
import EventsTrackWrapperClick from "../../../../containers/EventsTrack/wrappers/EventsTrackWrapperClick";
import Button from "../../../../presentational/Controls/Button/Button";
import Logo from "../../../../containers/Header/Logo/Logo";
import { useDispatch } from "react-redux";
import { disableBodyScroll, enableBodyScroll } from "../../../../../store/actions/LayoutActions";
import { useMediaQuery } from "react-responsive";
import { phoneWidth } from "../../../../../utils/constants/widthConstants";

interface ExternalPopupProps {
  project: ExternalProject;
  onClose: () => void;
}

const ExternalPopup = forwardRef<HTMLDivElement, ExternalPopupProps>(
  ({ project, onClose }: ExternalPopupProps, parentRef) => {
    const dispatch = useDispatch();
    const popupRef = useRef<HTMLDivElement>(null);
    const arrowRef = useRef<HTMLDivElement>(null);
    const isPhone = useMediaQuery({ query: `(max-width: ${phoneWidth}px)` });

    useEffect(() => {
      if (isPhone) {
        dispatch(disableBodyScroll());
        return () => {
          dispatch(enableBodyScroll());
        };
      }
    }, []);

    useEffect(() => {
      if (project) {
        const ref = parentRef as any;
        const i = projects.findIndex((p) => p.name === project.name);
        const el = ref.current?.children[i];
        if (el) {
          const { top: topEl, left: leftEl, width: widthEl } = el.getBoundingClientRect();
          const { top: topWrapper, left: leftWrapper, width: widthWrapper } = ref.current.getBoundingClientRect();
          const { width: widthPopup } = popupRef.current.getBoundingClientRect();
          popupRef.current.style.top = `${topEl - topWrapper - popupRef.current.clientHeight}px`;
          arrowRef.current.style.left = `${leftEl - leftWrapper + widthEl / 2 - (widthWrapper - widthPopup) / 2}px`;
        }
      }
    }, [project]);

    const getClassListForRoot = () => {
      let classList = "external-popup";
      if (project) {
        classList += ` external-popup--project-${project.name}`;
      }
      return classList;
    };

    return (
      <div className={getClassListForRoot()} ref={popupRef}>
        <Logo />
        <div className="external-popup__head">
          <img src={project.logoPopupSrc} className="external-popup__logo" />
          <EventsTrackWrapperClick id={14}>
            <button onClick={() => onClose()} className="ui-icon-14 ic external-popup__close"></button>
          </EventsTrackWrapperClick>
        </div>
        <div className="external-popup__body">{project.text}</div>
        <div className="external-popup__footer">
          <EventsTrackWrapperClick id={196} replace={["Перейти"]}>
            <Button
              type="filled"
              text="Перейти"
              color={project.buttonColor}
              linkUrl={project.url}
              hrefTarget="_blank"
            ></Button>
          </EventsTrackWrapperClick>
        </div>

        <div ref={arrowRef} className="external-popup__arrow"></div>
      </div>
    );
  }
);

export default ExternalPopup;
