import { RESOURCE_LOADED, Resource } from "../actions/ResourceActions";

export interface ResourceState {
    scripts: any;
}

const initialState: ResourceState = {
    scripts: {}
};

export function resourceReducer(state = initialState, action) {
  switch (action.type) {
    case RESOURCE_LOADED:
      const key = action.resource.payload.src;
      return {
        ...state,
        ...(action.resource.type === 'script' && {scripts: {...state.scripts, [key]: action.resource.payload}}),
      };

    default:
      return state;
  }
}
