import React from "react";

import { shadeColor } from "../../../../utils/index";
import "./Button.scss";

interface Props {
  text: string;
  type?: "filled" | "outlined" | "outlined-grey" | "filled-banner-button"; // remove filled-banner-button
  size?: "mid" | "long";
  onClick?: Function;
  isDisabled?: Boolean;
  isLoading?: Boolean;
  linkUrl?: string;
  externalLink?: Boolean;
  color?: string;
  lightColor?: string;
  textColor?: string;
  borderColor?: string;
  hrefTarget?: string;
  classList?: string;
}

class Button extends React.Component<Props, any> {
  lightColor = "";
  darkColor = "";
  buttonRef;

  constructor(props) {
    super(props);

    this.buttonRef = React.createRef();

    if (this.props.color) {
      this.lightColor = shadeColor(this.props.color, 40);
      this.darkColor = shadeColor(this.props.color, -40);
    }
  }

  onClick = (e) => {
    if (this.props.onClick && typeof this.props.onClick === "function") {
      this.props.onClick(e);
    } else {
      console.error("Please provide a callback for Button component");
    }
  };

  renderLoader = () => {
    if (this.props.isLoading) {
      return (
        <div className="cr-button__icon">
          <i className="cr-button__icon-loader"></i>
        </div>
      );
    }
  };

  getClassesForButton = () => {
    let classes = "cr-button " + "cr-button-" + this.props.type;

    if (this.props.isDisabled) {
      classes += " cr-button-disabled";
    }

    if (this.props.isLoading) {
      classes += " cr-button-loading";
    }

    if (this.props.size) {
      classes += ` cr-button_${this.props.size}`;
    }

    if (this.props.classList) {
      classes += " " + this.props.classList;
    }

    return classes;
  };

  addColor = (e) => {
    e.currentTarget.style.backgroundColor = this.props.lightColor || this.lightColor;
  };

  removeColor = (e) => {
    e.currentTarget.style.backgroundColor = this.props.color;
  };

  render() {
    const {
      color, textColor, borderColor, text, linkUrl, hrefTarget,
      onClick, type, size, isDisabled, isLoading, externalLink, // ненужные пропы для button
      ...otherProps
    } = this.props;
    return (
      <button
        ref={this.buttonRef}
        className={this.getClassesForButton()}
        style={{
          backgroundColor: color,
          color: textColor,
          borderColor,
        }}
        onMouseEnter={this.addColor}
        onMouseLeave={this.removeColor}
        onClick={this.onClick}
        { ...otherProps }
      >
        <span
          className="cr-button__text"
          dangerouslySetInnerHTML={{ __html: text }}
        ></span>
        {this.renderLoader()}

        {linkUrl && (
          <a
            href={linkUrl}
            target={hrefTarget}
            className="cr-button__link"
          />
        )}
      </button>
    );
  }
}

export default Button;
