declare global {
  interface Navigator {
    standalone?: boolean;
  }
}

class Platform {
  static width() {
    return window.innerWidth;
  }

  static isPhone() {
    return (
      !this.isTablet() &&
      !!window.navigator.userAgent.match(
        /Mobile|Windows Phone|Lumia|Android|webOS|iPhone|iPod|Blackberry|PlayBook|BB10|Opera Mini|\bCrMo\/|Opera Mobi/i
      )
    );
  }

  static height() {
    return window.innerHeight;
  }

  static isTablet() {
    return !!window.navigator.userAgent.match(/Tablet|iPad/i);
  }

  static isIPadOS() {
    return (
      !!window.navigator.maxTouchPoints &&
      window.navigator.maxTouchPoints > 2 &&
      /MacIntel/.test(window.navigator.platform)
    );
  }

  static isIOs() {
    return !!window.navigator.userAgent.match(/iPhone|iPad|iPod/i);
  }

  static isAndroid() {
    return !!window.navigator.userAgent.match(/Android/i);
  }

  static isLandscape() {
    return !this.isPortrait();
  }

  static isPortrait() {
    return (
      window.matchMedia && window.matchMedia("(orientation: portrait)").matches
    );
  }

  static maxWidth(maxWidth, unit = "px") {
    return (
      window.matchMedia &&
      window.matchMedia(`(max-width: ${maxWidth}${unit})`).matches
    );
  }

  static isTouchDevice() {
    return "ontouchstart" in document.documentElement;
  }

  static isPWA() {
    return window.matchMedia('(display-mode: standalone)').matches || 
           window.matchMedia('(display-mode: fullscreen)').matches ||
           window.navigator.standalone; 
  }
}

export default Platform;
