const _setListPosition = (position, listRef, inputInfo, padding) => {
  listRef.style.top =
    position === "top" ? "unset" : `${inputInfo.height + padding}px`;
  listRef.style.bottom =
    position === "top" ? `${inputInfo.height + padding}px` : "unset";
  listRef.style.maxHeight =
    position === "top"
      ? `${inputInfo.lengthToTop}px`
      : `${inputInfo.lengthToBottom}px`;
};

export const getInputInfo = (inputRef) => {
  const input = inputRef.current.getBoundingClientRect();
  return {
    lengthToTop: input.top,
    lengthToBottom: window.innerHeight - input.bottom,
    height: input.height,
  };
};

export const setListPosition = (listRef, inputInfo, padding = 0) => {
  if (listRef.current) {
    const { lengthToTop, lengthToBottom } = inputInfo;
    const listRefHeight = listRef.current.offsetHeight;
    window.innerHeight / 2 < lengthToTop &&
    listRefHeight / 2 < lengthToTop &&
    listRefHeight > lengthToBottom
      ? _setListPosition("top", listRef.current, inputInfo, padding)
      : _setListPosition("bottom", listRef.current, inputInfo, padding);
  }
};
