import { ADD_COMMAND, REMOVE_COMMAND } from "../actions/CommandActions";

interface Command {
  name: string;
  callback: any;
}

export interface CommandState {
  commands: Command[];
}

const initialState: any = {
  commands: [],
};

export function commandReducer(state = initialState, action: any) {
  switch (action.type) {
    case ADD_COMMAND:
      return { ...state, commands: [...state.commands, action.payload] };
    case REMOVE_COMMAND:
      return {
        ...state,
        commands: [...state.commands.filter((cmd) => cmd !== action.payload)],
      };
    default:
      return state;
  }
}
