import { RootState } from "../../types/State.interface";
import { GET_PING } from "../actions/OperabilityActions";

export interface OperabilityState {
  isOperating: boolean;
}

const initialState: OperabilityState = {
  isOperating: false
};

export function operabilityReducer(state = initialState, action) {
  switch (action.type) {
    case GET_PING:
      return {
        ...state,
        isOperating: action.payload,
      };

    default:
      return state;
  }
}

export const selectIsOperating = (state: RootState) => state.operability.isOperating;
