import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import AuthForm from "../../containers/Forms/AuthForm/AuthForm";
import Header from "./Header";
import { logout } from "../../../store/actions/UserActions";
import { getActiveIds } from "../../../store/actions/ProjectActions";
import { showMosruForm } from "../../../store/actions/LayoutActions";
import { Project } from "../../../types/Projects.interface";
import { User } from "../../../types/User.interface";
import { AppContext } from "../../Root";
import QrCodeModal from "../Modals/QrCodeModal/QrCodeModal";

interface Props {
  getActiveIds: () => void;
  activeProjects: Project[];
  user: User;
  loggedIn: boolean;
  unreadNotifications: Number;
  showMosRuAuthForm: () => void;
  hideMosRuAuthForm: () => void;
  logout: (params?) => void;
  history: any;
  routes: any;
  logo: {link: string};
}

class HeaderContainer extends Component<Props, any> {
  render() {
    return (
      <>
        {
          <>
            <AppContext.Consumer>
              {({ sudirService }) => (
                <Header
                  routes={this.props.routes?.baseRoutes}
                  mobileRoutes={this.props.routes?.mobileRoutes}
                  logo={this.props.logo}
                  user={this.props.user}
                  loggedIn={this.props.loggedIn}
                  unreadNotifications={this.props.unreadNotifications}
                  showMosRuAuthForm={this.props.showMosRuAuthForm}
                  hideMosRuAuthForm={this.props.hideMosRuAuthForm}
                  logout={(e) => this.props.logout({returnUrl: window.location.href})}
                  sudirService={sudirService}
                />
              )}
            </AppContext.Consumer>

            <AuthForm />
            <QrCodeModal />
          </>
        }
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    routes: state.layout.routes,
    logo: state.layout.logo,
    user: state.user.userDetails,
    loggedIn: state.user.loggedIn,
    unreadNotifications: state.notifications.unreadNotifications,
    activeProjects: state.projects.activeIDs,
  };
};

const mapDispatchToProps = (dispatch) => ({
  showMosRuAuthForm: () => dispatch(showMosruForm()),
  hideMosRuAuthForm: () => dispatch({ type: "HIDE_MOSRU_AUTH_FORM" }),
  logout: (params) => dispatch(logout(params)),
  getActiveIds: () => dispatch(getActiveIds()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(HeaderContainer));
