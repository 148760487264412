import React, { useEffect, useState } from "react";
import SwiperCore from "swiper";
import "./SliderArrows.scss";
import EventsTrackWrapperClick from "../../../../containers/EventsTrack/wrappers/EventsTrackWrapperClick";
import Pagination, { PaginationProps } from "../../../../presentational/Controls/Pagination/Pagination";

export interface ArrowOptionsItem {
  icon?: string;
  title?: string;
}

export interface ArrowOptions {
  left: ArrowOptionsItem;
  right: ArrowOptionsItem;
}

export interface SliderArrowsProps {
  swiper: SwiperCore;
  disabled?: boolean;
  arrowOptions?: ArrowOptions;
  pagination?: boolean;
}

const SliderArrows = ({ swiper, disabled, arrowOptions, pagination }: SliderArrowsProps) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [slidesLength, setSlidesLength] = useState(0);
  const [slidesOnPageCount, setSlidesOnPageCount] = useState(0);

  const getClassList = (direction) => {
    if (!swiper) return;

    let classList = `slider-arrows-${direction}`;
    if ((!swiper.params.loop && direction === "left" && swiper.isBeginning) || disabled) {
      classList += " slider-arrows--disabled";
    }
    if ((!swiper.params.loop && direction === "right" && swiper.isEnd) || disabled) {
      classList += " slider-arrows--disabled";
    }
    return classList;
  };

  useEffect(() => {
    const handler = () => {
      setCurrentPage(Math.ceil(swiper.realIndex / slidesOnPageCount));
    };
    swiper?.on("slideChange", handler);
    return () => swiper?.off("slideChange", handler);
  }, [slidesOnPageCount, swiper]);

  useEffect(() => {
    if (swiper) {
      const handler = () => {
        setSlidesLength(swiper.slides.length - (swiper.loopedSlides || 0) * 2);
        setSlidesOnPageCount(
          (typeof swiper.params.slidesPerView === "number" ? swiper.params.slidesPerView : 1) *
            (swiper.params.slidesPerColumn || 1)
        );
      };
      handler();
      swiper.on("update", handler);
      return () => swiper.off("update", handler);
    }
  }, [swiper]);

  const renderLeftArrow = () => (
    <EventsTrackWrapperClick needParent id={184} replace={["Слайдер влево"]}>
      <div
        className={getClassList("left")}
        onClick={() => {
          if (swiper.params.loop && swiper.params.slidesPerGroup > 1) {
            const newIndex = swiper.realIndex - swiper.params.slidesPerGroup;
            const newRealIndex =
              newIndex < 0
                ? Math.abs(newIndex) < swiper.params.slidesPerGroup
                  ? 0
                  : slidesLength + newIndex
                : newIndex;
            const r = newRealIndex % swiper.params.slidesPerGroup;
            if (r) {
              if (swiper.realIndex === 0) {
                const slidesGrid = (swiper as any).slidesGrid;
                const t = slidesGrid[slidesGrid.length - swiper.loopedSlides];
                typeof t !== "undefined" && swiper.setTranslate(-t);
              }
              setTimeout(() => swiper.slideToLoop(newRealIndex + (swiper.params.slidesPerGroup - r)), 0);
            } else {
              swiper.slidePrev();
            }
          } else {
            swiper.slidePrev();
          }
        }}
      >
        <span className={arrowOptions?.left.icon || "ic ui-icon-105"} />
      </div>
    </EventsTrackWrapperClick>
  );

  const renderRightArrow = () => (
    <EventsTrackWrapperClick needParent id={184} replace={["Слайдер вправо"]}>
      <div
        className={getClassList("right")}
        onClick={(e) => {
          swiper.slideNext();
        }}
      >
        <span className={arrowOptions?.right.icon || "ic ui-icon-88"} />
      </div>
    </EventsTrackWrapperClick>
  );

  const renderPageNum: PaginationProps["renderPageNum"] = (base, page) => (
    <EventsTrackWrapperClick needParent id={184} replace={[`Номер страницы [${page + 1}]`]}>
      {base}
    </EventsTrackWrapperClick>
  );

  return (
    <React.Fragment>
      {swiper && (
        <div className="slider-arrows">
          {pagination ? (
            <Pagination
              currentPage={currentPage}
              totalPages={Math.ceil(slidesLength / slidesOnPageCount)}
              onPageChange={(page) => swiper[swiper.params.loop ? "slideToLoop" : "slideTo"](page * slidesOnPageCount)}
              renderLeftArrow={renderLeftArrow}
              renderRightArrow={renderRightArrow}
              renderPageNum={renderPageNum}
            />
          ) : (
            <>
              {renderLeftArrow()}
              {renderRightArrow()}
            </>
          )}
        </div>
      )}
    </React.Fragment>
  );
};

export default SliderArrows;
