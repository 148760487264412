import React, { useEffect, useState } from "react";
import Timer from "../../../../../presentational/Timer/Timer";
import { Puzzle } from "../Puzzle/Puzzle";

import "./PuzzleGame.scss";

export interface PuzzleGameController {
  start: () => void;
  stop: () => void;
}

export interface PuzzleGameProps {
  image: string;
  rows: number;
  columns: number;
  onInit?: (controller: PuzzleGameController) => void;
  onStart?: () => void;
  onWin?: () => void;
}

const PuzzleGame = ({rows, columns, image, onInit, onStart, onWin }: PuzzleGameProps) => {
  const [started, setStarted] = useState(false);
  const [finished, setFinished] = useState(false);

  useEffect(() => {
    onInit({ start, stop });
  }, []);

  const start = () => {
    setStarted(true);
    onStart();
  };
  const stop = () => {
    setFinished(true);
    setStarted(false);
  };

  return (
    <div className="puzzle-game">
      {!started && <img className="puzzle-game__preview" src={image} />}

      {started && (
        <Puzzle
          imageSrc={image}
          rows={rows}
          columns={columns}
          showGrid={true}
          onSolved={onWin}
        />
      )}
    </div>
  );
};

export default PuzzleGame;
