import { fetchRequest, httpParamsSerialize } from "../../utils/axiosConfig";

export const ADDRESS_REGIONS_REQUEST = "ADDRESS_REGIONS_REQUEST";
export const ADDRESS_REGIONS_SUCCESS = "ADDRESS_REGIONS_SUCCESS";
export const ADDRESS_REGIONS_FAIL = "ADDRESS_REGIONS_FAIL";

export const ADDRESS_DISTRICTS_REQUEST = "ADDRESS_DISTRICTS_REQUEST";
export const ADDRESS_DISTRICTS_SUCCESS = "ADDRESS_DISTRICTS_SUCCESS";
export const ADDRESS_DISTRICTS_FAIL = "ADDRESS_DISTRICTS_FAIL";

export function getRegions() {
  return async function (dispatch) {
    dispatch({
      type: ADDRESS_REGIONS_REQUEST,
    });

    const url = "/api/address/regions";

    try {
      let response = await fetchRequest(url, null, "GET");
      response = response.data;

      if (response.status === 0) {
        dispatch({
          type: ADDRESS_REGIONS_SUCCESS,
          payload: response.data,
        });
      } else {
        dispatch({
          type: ADDRESS_REGIONS_FAIL,
          error: true,
          payload: new Error("Ошибка получения данных."),
        });
      }
    } catch (err) {
      dispatch({
        type: ADDRESS_REGIONS_FAIL,
        error: true,
        payload: new Error("Ошибка получения данных."),
      });
    }
  };
}

export function getDistricts() {
  return async function (dispatch) {
    dispatch({
      type: ADDRESS_DISTRICTS_REQUEST,
    });

    const url = "/api/address/districts";

    try {
      let response = await fetchRequest(url, null, "GET");
      response = response.data;

      if (response.status === 0) {
        dispatch({
          type: ADDRESS_DISTRICTS_SUCCESS,
          payload: response.data,
        });
      } else {
        dispatch({
          type: ADDRESS_DISTRICTS_FAIL,
          error: true,
          payload: new Error("Ошибка получения данных."),
        });
      }
    } catch (err) {
      dispatch({
        type: ADDRESS_DISTRICTS_FAIL,
        error: true,
        payload: new Error("Ошибка получения данных."),
      });
    }
  };
}
