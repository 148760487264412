import React, { useEffect, useState } from "react";

import "./BirthdayMayor.scss";

const BirthdayMayor = () => {
  return (
    <section id="mayor" className="birthday-mayor birthday-block-lessened">
      <div className="birthday-mayor__inner">
        <div className="birthday-mayor__light">
          <div className="birthday-mayor__blue">
            <img
              src={
                require("../../../../../assets/birthday9/images/mayor/mayor.png")
                  .default
              }
            />
          </div>

          <div className="birthday-mayor__info">
            <div className="birthday-mayor__header">
              Уже 9 лет платформа «Город идей» помогает москвичам принимать
              активное участие в развитии Москвы. Идеи, предлагаемые жителями,
              касаются здравоохранения, образования, транспорта, культуры,
              предпринимательства и других значимых сфер жизни. Многие из них
              успешно реализуются.<br></br> 
              Инициативы и пожелания жителей позволяют
              делать нашу любимую столицу еще более комфортной — городом, в
              котором хочется жить и работать.
            </div>
            <div className="birthday-mayor__footer">
              <img
                src={
                  require("../../../../../assets/birthday9/images/mayor/quote.svg")
                    .default
                }
              />
              <div className="birthday-mayor__fio">
                Сергей Собянин <br></br>
                <span>Мэр Москвы</span>
              </div>

              <img
                src={
                  require("../../../../../assets/birthday9/images/mayor/signature.png")
                    .default
                }
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BirthdayMayor;
