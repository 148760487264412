import { combineReducers } from "redux";

import { profileReducer } from "./profile";
import { projectReducer, bannersReducer } from "./projects";
import { videoReducer } from "./video";
import { statisticsReducer } from "./statistics";
import { taglineReducer } from "./tagline";
import { newsReducer } from "./news";
import { proposeReducer } from "./propose";
import { globalEventsReducer } from "./globalEvents";
import { notificationsReducer } from "./notifications";
import { layoutReducer } from "./layout";
import { ideasReducer } from "./ideas";
import { photosReducer } from "./photos";
import { addressReducer } from "./address";
import { persistedReducer } from "./persisted";
import { envReducer } from "./environment";
import { sittingsReducer } from "./sittings";
import { commandReducer } from "./commands";
import { newsLineReducer } from "./newsLine";
import { gorodReducer } from "./gorod";
import { ideasListReducer } from "./ideasList";
import { nyLandingReducer } from "./nyLanding";
import { operabilityReducer } from "./operability";
import { postMessagesReducer } from "./postMessage";
import { toastReducer } from "./toast";
import { resourceReducer } from "./resourceReducer";
import { leisureLandingReducer } from "./leisureLanding";
import { windowReducer } from "./window";
import { publicationsReducer } from "./publications";

export const rootReducer = combineReducers({
  environment: envReducer,
  user: profileReducer,
  notifications: notificationsReducer,
  news: newsReducer,
  publications: publicationsReducer,
  lines: newsLineReducer,
  ideas: ideasReducer,
  ideasList: ideasListReducer,
  layout: layoutReducer,
  photos: photosReducer,
  propose: proposeReducer,
  address: addressReducer,
  projects: projectReducer,
  videos: videoReducer,
  totalStatistics: statisticsReducer,
  banners: bannersReducer,
  tagline: taglineReducer,
  gorod: gorodReducer,
  globalEvents: globalEventsReducer,
  persisted: persistedReducer,
  sittings: sittingsReducer,
  command: commandReducer,
  nyLanding: nyLandingReducer,
  leisureLanding: leisureLandingReducer,
  operability: operabilityReducer,
  postMessages: postMessagesReducer,
  toast: toastReducer,
  resources: resourceReducer,
  window: windowReducer,
});
