import { BaseResponse } from "../types/Common.interface";
import { ProposalSubmitRequest } from "../types/Proposal.interface";
import { API } from "./api";

const baseUrl = "/api/proposal";

const ProposalService = {
  submitProposal: (formData: ProposalSubmitRequest): Promise<BaseResponse> => {
    return API.post(`${baseUrl}/submit`, formData).then(({ data }) => {
      return data;
    });
  },
};

export default ProposalService;
