import React from "react";

import "./SomethingWentWrong.scss";
import Button from "../../presentational/Controls/Button/Button";

const SomethingWentWrong = (props) => {
  return (
    <div className="went-wrong">
      <img src={require("../../../assets/lock-24px@2x.png").default} alt="Логотип"></img>
      <h1>Внимание!</h1>
      <p>На сайте ведутся технические работы.</p>
      <p>Приносим извинения за доставленные неудобства.</p>
      <Button type="filled" linkUrl="/" text="Вернуться на главную" />
    </div>
  );
};

export default SomethingWentWrong;
