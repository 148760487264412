import React, { useEffect, useLayoutEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { matchPath, useLocation } from "react-router-dom";
import { lessenPage, widenPage } from "../../../store/actions/LayoutActions";
import { wideTabletWidth } from "../../../utils/constants/widthConstants";
import AuthForm from "../../containers/Forms/AuthForm/AuthForm";
import { ElementObserver } from "../../presentational/ElementObserver/ElementObserver";
import { ElementObserverItem } from "../../presentational/ElementObserver/ElementObserverItem/ElementObserverItem";
import BirthdayFooter from "./Components/BirthdayFooter/BirthdayFooter";
import BirthdayHeader from "./Components/BirthdayHeader/BirthdayHeader";
import { scrollToBlock } from "./Components/BirthdayHeader/BirthdayHeader.helper";
import { Birthday9Section, sections } from "./CrowdBirthday9.helper";

import "./CrowdBirthday9.scss";

const CrowdBirthday9 = () => {
  const location = useLocation<{ sectionId: Birthday9Section }>();
  const dispatch = useDispatch();
  const [currentSection, setCurrentSection] = useState();

  const isTablet = useMediaQuery({
    query: `(min-width: ${wideTabletWidth}px)`,
  });

  const items = useMemo(() => {
    const foundSection = sections.find(
      (s) => !!matchPath(location.pathname, { path: s.href, exact: true })
    );
    return (
      foundSection ? [foundSection] : sections.filter(({ href }) => !href)
    ).map((s) => (
      <ElementObserverItem data={s} id={s.id} key={s.id}>
        {s.component}
      </ElementObserverItem>
    ));
  }, [location.pathname]);

  useLayoutEffect(() => {
    location.state?.sectionId &&
      scrollToBlock(location.state.sectionId, isTablet);
  }, [isTablet, location.state]);

  useEffect(() => {
    dispatch(widenPage());
    const wrapper: HTMLElement = document.querySelector(".wrapper");
    const initialPadding = wrapper.style.padding;
    wrapper.style.padding = "0";
    return () => {
      dispatch(lessenPage());
      wrapper.style.padding = initialPadding;
    };
  }, []);

  return (
    <>
      <AuthForm />
      <div className="crowd-birthday">
        <BirthdayHeader currentSection={currentSection}></BirthdayHeader>
        <main className="crowd-birthday__main">
          <ElementObserver
            rootMargin="-30% 0px -70% 0px"
            delayBeforeNotify={0}
            autoUnobserve={false}
            onIntersect={setCurrentSection}
          >
            {items}
          </ElementObserver>
        </main>
        <BirthdayFooter></BirthdayFooter>
      </div>
    </>
  );
};

export default CrowdBirthday9;
