import React from "react";
import { Route, Redirect } from "react-router-dom";
import { store } from "../store/store";
import { matchPath } from "react-router";

export default class GuardServce {
  guards: any;
  state: any;

  constructor(guards) {
    this.guards = guards;
    this.state = store.getState();
  }

  getRoutes(routes) {
    return routes.props.children.flat();
  }

  matchUrl(routes) {
    const gUrls = this.getRoutes(routes).map((r) => r.props?.path);
    return gUrls.map(url => matchPath(window.location.pathname, {
      path: url,
      exact: true,
      strict: false,
    })).filter(Boolean);
  }

  getGuarded(routes) {
    if (!this.guards || !this.matchUrl(routes)) return this.getRoutes(routes);

    const guardsResult = this.guards.map((g) => g(this.state));
    const notPassedGuard = guardsResult?.find((r) => !r.passedGuard);

    return notPassedGuard ? (
      <Redirect to={notPassedGuard.redirectIfFail} />
    ) : (
      this.getRoutes(routes)
    );
  }
}
