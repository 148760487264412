import { ComponentProps } from "react";
import Select from "../../../../presentational/Controls/Select/Select";
import EventsTrackWrapperClick from "../EventsTrackWrapperClick";

const EventsTrackClickSelectItem: ComponentProps<typeof Select>["ComponentSelectItem"] = ({selectedText, label, ...otherProps}) => (
    <EventsTrackWrapperClick id={27} replace={[label, selectedText]}>
        <div {...otherProps}/>
    </EventsTrackWrapperClick>
);

export default EventsTrackClickSelectItem;