import { MosProjects } from "../../../../types/Projects.interface";

export const baseWidth = 2180;
export const baseHeight = 1100;
export const squareTabletRatioCoef = 1024 / 768;
export const squarePhoneRatioCoef = 452 / 320;
export const squarePhoneAddHeightCoef = 26 / 320;
export const maxSliderRatioCoef = 2.68;

export enum GamesTypes {
  SUDOKU = "sudoku",
  QUIZ = "quiz",
  MEMORY = "memory",
  PUZZLE = "puzzle",
  MAZE = "maze",
  WISH = "wish",
}

export const WinnableProjectsGames = {
  [MosProjects.GI]: GamesTypes.SUDOKU,
  [MosProjects.NG]: GamesTypes.MAZE,
  [MosProjects.ED]: GamesTypes.MEMORY,
  [MosProjects.GZ]: GamesTypes.PUZZLE,
  [MosProjects.AG]: GamesTypes.QUIZ,
};

export const AllProjectsGames = {
  [MosProjects.GI]: GamesTypes.SUDOKU,
  [MosProjects.NG]: GamesTypes.MAZE,
  [MosProjects.ED]: GamesTypes.MEMORY,
  [MosProjects.GZ]: GamesTypes.PUZZLE,
  [MosProjects.AG]: GamesTypes.QUIZ,
  [MosProjects.MP]: GamesTypes.WISH,
};

export enum SlideTypes {
  WELCOME = "welcome",
  CROWD = "crowd",
  AG = "ag",
  ED = "ed",
  GZ = "gz",
  NG = "ng",
  MP = "mp",
}

export enum SlideObjectTypes {
  FACT = "fact",
  GAME = "game",
  BG = "bg",
  GARLAND = "garland",
  BULBS = "bulbs",
}

export const ProjectsLink = {
  [SlideTypes.WELCOME]: "",
  [SlideTypes.CROWD]: "https://crowd.mos.ru",
  [SlideTypes.AG]: "https://ag.mos.ru",
  [SlideTypes.ED]: "https://ed.mos.ru",
  [SlideTypes.GZ]: "https://gz.mos.ru",
  [SlideTypes.NG]: "https://gorod.mos.ru",
  [SlideTypes.MP]: "https://ag-vmeste.ru",
};
