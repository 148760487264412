import { FC, useMemo } from "react";
import { TrainProps } from "./Train.types";
import "./Train.scss";

const Train: FC<TrainProps> = ({ arrivalPoint, leavingPoint, currentPoint, className, ...otherProps }) => {
  const trainState = useMemo<"pending" | "arrived" | "left">(() => {
    // анимация поезда
    if (currentPoint < arrivalPoint) {
      return "pending";
    }
    if (currentPoint >= arrivalPoint && currentPoint <= leavingPoint) {
      return "arrived";
    }
    if (currentPoint > leavingPoint) {
      return "left";
    }
    return "pending";
  }, [arrivalPoint, currentPoint, leavingPoint]);

  return (
    <div className={`leisure-landing-train leisure-landing-train_${trainState} ${className || ""}`} {...otherProps} />
  );
};

export default Train;
