import { EventsTrackConfig } from "../../types/EventsTrack.interface";

const eventsTrackConfigParams: EventsTrackConfig["params"] = {
  common: {
    PAGE_MAIN: {
      data: {
        "Первая страница": {},
      },
    },
    BLOCK_RESULTS: {
      data: {
        "Результаты работы": {
          "Блок Результаты": {},
        },
      },
    },
    BLOCK_NEWS: {
      data: {
        Новости: {
          "Блок Новости": {},
        },
      },
    },
    BLOCK_GKU_NTU: {
      data: {
        "ГКУ НТУ": {
          "Блок Проекты ГКУ НТУ": {},
        },
      },
    },
    MAIN_CONDITIONS: {
      data: {
        "Основные условия": {
          "Блок Основные условия": {},
        },
      },
    },
    BLOCK_INFOGRAPHIC: {
      data: {
        "Основные условия": {
          "Блок Инфографика": {},
        },
      },
    },
    BLOCK_COLLECT_PHOTOS: {
      data: {
        "Сбор фото": {
          "Блок Сбор фото": {},
        },
      },
    },
    BLOCK_PHOTO_CARD: {
      data: {
        "Галерея фото": {
          "Блок Карточки фото": {},
        },
      },
    },
    BLOCK_SECTION_DESC: {
      data: {
        "Описание раздела": {
          "Блок Описание раздела": {},
        },
      },
    },
    POPUP_MAP_CARD: {
      data: {
        "Результаты работы": {
          "Блок Карта результатов": {
            "Карточка идеи": {},
          },
        },
      },
    },
    SEND_FORM: {
      data: {
        "Форма отправки": {},
      },
    },
    FILTER: {
      data: {
        Фильтр: {},
      },
    },
    FIELD: {
      data: {
        'Поле "%s"': {},
      },
    },
    HEADER: {
      data: {
        Хэдер: {},
      },
    },
    FOOTER: {
      data: {
        Футер: {},
      },
    },
    PAGINATION: {
      data: {
        Пагинация: {},
      },
    },
    SHARE: {
      data: {
        "Иконка Поделиться": {
          "Иконка %s": {},
        },
      },
    },
    EMPTY: {
      data: {
        "%s": {},
      },
    },
  },
  click: {
    CLICK: {
      data: {
        click: "true",
      },
    },
    CLICK_PARTIAL: {
      data: {
        click: "true",
      },
      partial: true,
      rememberPrev: true,
    },
    1: {
      data: {
        Хэдер: {
          "Логотип Город идей": {
            click: "true",
          },
        },
      },
    },
    2: {
      data: {
        "О нас": {
          click: "true",
        },
      },
    },
    22: {
      data: {
        "Иконка Больше": {
          click: "true",
        },
      },
    },
    300: {
      data: {
        Проекты: {
          click: "true",
        },
      },
    },
    310: {
      data: {
        "Следующий проект": {
          click: "true",
        },
      },
    },
    320: {
      data: {
        "Текущий проект": {
          click: "true",
        },
      },
    },
    500: {
      data: {
        "Результаты работы": {
          click: "true",
        },
      },
    },
    510: {
      data: {
        "Выполненные проекты": {
          click: "true",
        },
      },
    },
    520: {
      data: {
        "Реализация идей": {
          click: "true",
        },
      },
    },
    7: {
      data: {
        "Предложить идею": {
          click: "true",
        },
      },
    },
    8: {
      data: {
        Новости: {
          click: "true",
        },
      },
    },
    9: {
      data: {
        Видео: {
          click: "true",
        },
      },
    },
    10: {
      data: {
        "О Платформе": {
          click: "true",
        },
      },
    },
    600: {
      data: {
        "Иконка кросс-промо": {
          click: "true",
        },
      },
    },
    601: {
      data: {
        "Иконка кросс-промо": {
          "%s": {
            click: "true",
          },
        },
      },
    },
    11: {
      data: {
        Хэдер: {
          Войти: {
            click: "true",
          },
        },
      },
      remember: true,
    },
    12: {
      data: {
        "Кнопка Войти через mos.ru": {
          click: "true",
        },
      },
      rememberPrev: true,
      partial: true,
    },
    13: {
      data: {
        "Кнопка Регистрация": {
          click: "true",
        },
      },
      partial: true,
    },
    14: {
      data: {
        "Иконка Крестик": {
          click: "true",
        },
      },
      partial: true,
    },
    141: {
      data: {
        "Иконка Крестик": {
          click: "true",
        },
      },
    },
    15: {
      data: {
        Хэдер: {
          "Аватар авторизованного пользователя": {
            click: "true",
          },
        },
      },
    },
    16: {
      data: {
        Хэдер: {
          "Кнопка Выйти": {
            click: "true",
          },
        },
      },
    },
    17: {
      data: {
        "Первая страница": {
          "Блок Перетяжка": {
            "Перетяжка [%s]": {
              click: "true",
            },
          },
        },
      },
    },
    18: {
      data: {
        "Блок Баннер": {
          "Баннер проектного типа": {
            "Название проекта %s": {
              click: "true",
            },
          },
        },
      },
    },
    19: {
      data: {
        "Блок Баннер": {
          "Баннер проектного типа": {
            "Кнопка %s": {
              click: "true",
            },
          },
        },
      },
    },
    20: {
      data: {
        "Блок Баннер": {
          "Баннер типа Мэр": {
            "Текст цитаты Мэра %s": {
              click: "true",
            },
          },
        },
      },
    },
    21: {
      data: {
        "Блок Баннер": {
          "Баннер типа Мэр": {
            "Кнопка Предложить идею": {
              click: "true",
            },
          },
        },
      },
      remember: true,
    },
    23: {
      data: {
        "Логотип Город идей": {
          click: "true",
        },
      },
      partial: true,
      rememberPrev: true,
    },
    24: {
      data: {
        "Аватар авторизованного пользователя": {
          click: "true",
        },
      },
      partial: true,
    },
    25: {
      data: {
        "Раскрытие списка %s": {
          click: "Действие кнопки: %s",
        },
      },
      partial: true,
      rememberPrev: true,
    },
    27: {
      data: {
        "Раскрытие списка %s": {
          "%s": {
            click: "true",
          },
        },
      },
      partial: true,
      rememberPrev: true,
    },
    35: {
      data: {
        "Кнопка Добавить файл": {
          click: "true",
        },
      },
      partial: true,
      rememberPrev: true,
    },
    36: {
      data: {
        "Ввод текста %s": {
          click: "true",
        },
      },
      partial: true,
      rememberPrev: true,
    },
    // 37: действие 36,
    38: {
      data: {
        "Чекбокс %s": {
          click: "true",
        },
      },
      partial: true,
      rememberPrev: true,
    },
    39: {
      data: {
        "Ссылка %s": {
          click: "true",
        },
      },
      partial: true,
      rememberPrev: true,
    },
    40: {
      data: {
        "Блок Баннер": {
          "Баннер произвольного типа": {
            "Кнопка произвольная %s": {
              click: "true",
            },
          },
        },
      },
      remember: true,
    },
    45: {
      data: {
        "Блок Жизненный цикл": {
          "Ссылка проектная %s": {
            click: "true",
          },
        },
      },
    },
    48: {
      data: {
        "Блок Жизненный цикл": {
          "Ссылка межпроектная %s": {
            click: "true",
          },
        },
      },
    },
    52: {
      data: {
        "Блок Активные направления": {
          "Текст в блоке Подробнее о проекте %s": {
            click: "true",
          },
        },
      },
    },
    53: {
      data: {
        "Блок Активные направления": {
          "Ссылка Узнать больше": {
            click: "true",
          },
        },
      },
    },
    54: {
      data: {
        "Блок Активные направления": {
          "Ссылка Все направления проекта": {
            click: "true",
          },
        },
      },
    },
    55: {
      data: {
        "Блок Активные направления": {
          "Кнопка %s": {
            click: "true",
          },
        },
      },
      remember: true,
    },
    170: {
      data: {
        "Результаты работы": {
          "Блок О платформе": {
            "Ссылка О платформе": {
              click: "true",
            },
          },
        },
      },
    },
    171: {
      data: {
        "Результаты работы": {
          "Блок О платформе": {
            "Ссылка проектов": {
              click: "true",
            },
          },
        },
      },
    },
    172: {
      data: {
        "Результаты работы": {
          "Блок О платформе": {
            "Ссылка идей реализовано": {
              click: "true",
            },
          },
        },
      },
    },
    174: {
      data: {
        "Результаты работы": {
          "Блок Результаты": {
            "Ссылка Результаты": {
              click: "true",
            },
          },
        },
      },
    },
    175: {
      data: {
        "Нажатие на фамилию участника %s": {
          click: "true",
        },
      },
    },
    176: {
      data: {
        "Иконка Сердце": {
          click: "true",
        },
      },
      remember: true,
    },
    182: {
      data: {
        "Кнопка %s": {
          click: "true",
        },
      },
      remember: true,
    },
    183: {
      data: {
        "Ссылка %s": {
          click: "true",
        },
      },
    },
    184: {
      data: {
        "Кнопка %s": {
          click: "true",
        },
      },
    },
    185: {
      data: {
        "Ссылка %s": {
          click: "true",
        },
      },
      remember: true,
    },
    195: {
      data: {
        "Кнопка %s": {
          click: "true",
        },
      },
      partial: true,
    },
    196: {
      data: {
        "Кнопка %s": {
          click: "true",
        },
      },
      partial: true,
      rememberPrev: true,
    },
    197: {
      data: {
        "Кнопка Подробнее": {
          click: "true",
        },
      },
    },
    211: {
      data: {
        "Блок %s": {
          click: "true",
        },
      },
    },
    246: {
      data: {
        Футер: {
          "Кнопка Помощь": {
            click: "true",
          },
        },
      },
    },
    247: {
      data: {
        Футер: {
          "Ссылка Пользовательское соглашение": {
            click: "true",
          },
        },
      },
    },
    248: {
      data: {
        Футер: {
          "Ссылка Соглашение о пользовании информационными системами и ресурсами города Москвы": {
            click: "true",
          },
        },
      },
    },
    249: {
      data: {
        Футер: {
          "Иконка Помощник": {
            click: "true",
          },
        },
      },
    },
    250: {
      data: {
        Футер: {
          "Иконка Назад": {
            click: "true",
          },
        },
      },
    },
    251: {
      data: {
        'Карточка идеи ["%s"]': {
          click: "true",
        },
      },
      remember: true,
    },
    252: {
      data: {
        "Результаты работы": {
          "Блок Карта результатов": {
            "Нажатие на карту результатов": {
              click: "true",
            },
          },
        },
      },
    },
    253: {
      data: {
        "Результаты работы": {
          "Блок Карта результатов": {
            "Клик Приблизить карту": {
              click: "true",
            },
          },
        },
      },
    },
    254: {
      data: {
        "Результаты работы": {
          "Блок Карта результатов": {
            "Клик Отдалить карту": {
              click: "true",
            },
          },
        },
      },
    },
    255: {
      data: {
        "Результаты работы": {
          "Блок Карта результатов": {
            "Клик Местоположение": {
              click: "true",
            },
          },
        },
      },
    },
    256: {
      data: {
        "Клик на проект «%s»": {
          click: "true",
        },
      },
    },
    257: {
      data: {
        "Клик на тему «%s»": {
          click: "true",
        },
      },
    },
  },
  scroll: {
    DOCSCROLL: {
      data: {
        Доскролл: "true",
      },
    },
    1: {
      data: {
        "Блок Баннер": {
          Доскролл: "true",
        },
      },
    },
    2: {
      data: {
        "Блок Жизненный цикл": {
          Доскролл: "true",
        },
      },
    },
    3: {
      data: {
        "Блок Активные направления": {
          Доскролл: "true",
        },
      },
    },
    4: {
      data: {
        "Результаты работы": {
          "Блок О платформе": {
            Доскролл: "true",
          },
        },
      },
    },
    5: {
      data: {
        "Результаты работы": {
          "Блок Результаты": {
            Доскролл: "true",
          },
        },
      },
    },
    6: {
      data: {
        Новости: {
          "Блок Новости": {
            Доскролл: "true",
          },
        },
      },
    },
    7: {
      data: {
        "ГКУ НТУ": {
          "Блок Проекты ГКУ НТУ": {
            Доскролл: "true",
          },
        },
      },
    },
    8: {
      data: {
        Футер: {
          Доскролл: "true",
        },
      },
    },
    9: {
      data: {
        "Результаты работы": {
          "Блок Карта результатов": {
            Доскролл: "true",
          },
        },
      },
    },
    10: {
      data: {
        "Результаты работы": {
          "Блок Карта результатов": {
            "Карточка идеи": {
              Скролл: "true",
            },
          },
        },
      },
    },
  },
};
export default eventsTrackConfigParams;
