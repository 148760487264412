import nyLangingObjectsCrowd from "./crowd";

const nyLangingObjectsEd = nyLangingObjectsCrowd
  .slice(0, nyLangingObjectsCrowd.length - 1)
  .concat([
    {
      ...nyLangingObjectsCrowd[nyLangingObjectsCrowd.length - 1],
      placementLayerIndex: 6,
    },
  ]);

export default nyLangingObjectsEd;
