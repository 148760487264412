import React, { useEffect, useState, useCallback, useRef } from "react";
import Masonry from "@mui/lab/Masonry";
import axios from "axios";
import Pagination from "../../../presentational/Controls/Pagination/Pagination";
import FourColumnLoader from "../../../presentational/Loaders/FourColumnLoader/FourColumnLoader";
import { isMobile } from "../../../../utils";
import { useSelector } from "react-redux";
import { RootState } from "../../../../types/State.interface";
import { IdeaDetailsCardsListProps } from "./IdeaDetailsCardsProps";
import { findUrlParam, removeUrlParam } from "../../../../utils";
import { useHistory } from "react-router-dom";

const IdeaDetailsCardsList: React.FC<IdeaDetailsCardsListProps> = ({
  endpoint,
  withPagination,
  columns,
  gap,
  restrictBy,
  scrollToTopOnSlide,
  onLoadEnd,
  onGridUpdated,
  withUpdateLocation,
  onSlide,
  renderStub,
  isSequencial,
  className,
  onInit,
  ...props
}): JSX.Element => {
  const [items, setItems] = useState([]);
  const [page, setPage] = useState(() => {
    const params = new URLSearchParams(window.location.search);
    return parseInt(params.get("page") || "1", 10) - 1;
  });
  const [isFetching, setIsFetching] = useState(false);
  const [pagination, setPagination] = useState({ totalPages: 1 });
  const screenWidth = useSelector((state: RootState) => state.globalEvents.width);
  const isTablet = screenWidth <= 1024 && screenWidth > 500;
  const gridViewRef = useRef(null);
  const endpointRef = useRef(endpoint);
  const [renderDelay, setRenderDelay] = useState(true)
  const history = useHistory()
  const currentRequest = useRef(null);

  useEffect(() => {
    setRenderDelay(true);
    const timer = setTimeout(() => setRenderDelay(false), 1);
    return () => clearTimeout(timer);
  }, [items]);

  useEffect(() => {
    if (onInit) {
      onInit(gridViewRef.current);
    }
  }, [onInit]);

  useEffect(() => {
    if (endpointRef.current !== endpoint) {
      endpointRef.current = endpoint;
      setPage(0);
    }
    fetchData();
  }, [endpoint, page]);

  useEffect(() => {
    if (gridViewRef && items?.length) {
      const id = findUrlParam("id", history.location.search);
      if (id) {
        setTimeout(() => {
          scrollToElement(id)
        }, 1000)
        removeUrlParam("id", history);
      }
    }
  }, [items, gridViewRef, history, history.location]);

  const scrollToElement = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  const fetchData = useCallback(async () => {
    if (currentRequest.current) {
      currentRequest.current.cancel();
    }
    setIsFetching(true);
    try {
      const source = axios.CancelToken.source();
      currentRequest.current = source;

      const response = await axios.get(endpoint, {
        params: { page, size: restrictBy },
        cancelToken: source.token,
      });
      const data = response.data;
      setItems(data.data);
      setPagination(data.paging);
      onLoadEnd && onLoadEnd({ page, items: data.data });
      onGridUpdated && onGridUpdated();
      if (withUpdateLocation) {
        updateLocation(data.paging.pageNumber + 1);
      }
    } catch (error) {
      if (axios.isCancel(error)) {
        // console.log('Запрос был отменен');
      } else {
        console.error("Error fetching data:", error);
      }
    } finally {
      setIsFetching(false);
    }
  }, [endpoint, page, restrictBy, withUpdateLocation, onLoadEnd, onGridUpdated]);

  const updateLocation = (page) => {
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set("page", page);
    window.history.replaceState(null, "", `?${urlParams.toString()}`);
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
    if (scrollToTopOnSlide) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
    onSlide && onSlide();
  };

  if (isFetching && !items.length) {
    return <FourColumnLoader isTitled={false} />;
  }

  if (!isFetching && !items.length && renderStub) {
    return renderStub();
  }

  const columnCount = isMobile() ? 1 : isTablet ? 2 : columns;

  return (
    <>
      {!renderDelay && (
        <Masonry
          columns={columnCount}
          spacing={gap}
          className={className || ""}
          ref={gridViewRef}
          sequential={isSequencial}
        >
          {items.map((item) => (
            <div
              key={item.id}
              className="ideadetails-card-wrapper"
              id={item.id}
            >
              {React.createElement(props.children.type, {
                [props.bindTo || "item"]: item,
                ...props.children.props,
              })}
            </div>
          ))}
        </Masonry>
      )}

      {withPagination && !renderDelay && pagination.totalPages > 1 && (
        <Pagination
          currentPage={page}
          totalPages={pagination.totalPages}
          onPageChange={handlePageChange}
        />
      )}
    </>
  );
};

export default IdeaDetailsCardsList;
