import { useContext, useEffect, useMemo, useRef, useState } from "react";
import ScrollIntersectionController from "../../../../presentational/ScrollIntersectionController";
import Line1 from "./Lines/Line1";
import Line2 from "./Lines/Line2";
import Line3 from "./Lines/Line3";
import Line4 from "./Lines/Line4";
import Line5 from "./Lines/Line5";
import "./BirthdaySpheres.scss";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../types/State.interface";
import spheresData from "./spheresData";
import SphereBlock, {
  SphereBlockProject,
  SphereBlockProps,
} from "./SphereBlock";
import {
  AuthAction,
  AuthActionParam,
  AuthActionType,
  ComponentType,
} from "../../../../../services/sudirService";
import { AppContext } from "../../../../Root";
import GorodService, {
  GorodIdeaCategory,
  GorodIdeaSphere,
} from "../../../../../services/gorodService";
import ProposeIdeaForm from "../../../../containers/Forms/ProposeIdeaForm/ProposeIdeaForm";
import ProposeIdeaSubmittedForm from "../../../../containers/Forms/ProposeIdeaForm/ProposeIdeaSubmittedForm";
import { useMediaQuery } from "react-responsive";
import SphereProjectModal from "./SphereProjectModal";

const lines = [Line1, Line2, Line3, Line4, Line5];

const BirthdaySpheres = () => {
  const appContext = useContext(AppContext);

  const isWidestDesktop = useMediaQuery({ query: `(min-width: 1660px)` });

  const user = useSelector((state: RootState) => state.user.userDetails);
  const spheres = useSelector((state: RootState) => state.gorod.spheres);

  const [selectedSphere, setSelectedSphere] = useState<GorodIdeaSphere>();
  const [selectedCategory, setSelectedCategory] = useState<GorodIdeaCategory>();
  const [selectedProject, setSelectedProject] = useState<SphereBlockProject>();
  const [isSubmittedProposeModalOpen, setIsSubmittedProposeModalOpen] =
    useState<boolean>(false);

  const lineRefs = useRef<Record<number, SVGPathElement>>({});

  const renderSpheresData = useMemo(() => {
    const res: Omit<
      SphereBlockProps,
      "onSelectCategory" | "onSelectProject"
    >[] = [];
    if (spheres) {
      for (const sphereData of spheresData) {
        const sphere = GorodService.getSphereByCode(sphereData.code, spheres);
        if (sphere) {
          const spheresCategoriesList: SphereBlockProps["spheresCategoriesList"] =
            [];
          sphereData.categoryCodes?.forEach((categoryCode) => {
            if (Array.isArray(categoryCode)) {
              const [anotherSphereCode, anotherCategoryCode] = categoryCode;
              const anotherSphere = GorodService.getSphereByCode(
                anotherSphereCode,
                spheres
              );
              if (anotherSphere) {
                const anotherCategory = GorodService.getCategoryByCode(
                  anotherSphere,
                  anotherCategoryCode
                );
                if (anotherCategory) {
                  spheresCategoriesList.push([anotherSphere, anotherCategory]);
                }
              }
            } else {
              const category = GorodService.getCategoryByCode(
                sphere,
                categoryCode
              );
              if (category) {
                spheresCategoriesList.push([sphere, category]);
              }
            }
          });
          if (!spheresCategoriesList.length) {
            sphere.categories.forEach((category) => {
              spheresCategoriesList.push([sphere, category]);
            });
          }

          res.push({
            spheresCategoriesList,
            ...sphereData,
          });
        }
      }
    }
    return res;
  }, [spheres]);

  const openProposeForm = (
    sphere: GorodIdeaSphere,
    category?: GorodIdeaCategory
  ) => {
    
    window.open("/propose", "_blank");
    return;

    if (!user?.loggedIn) {
      const thisContainerData: AuthActionParam = {
        component: ComponentType.BIRTHDAY9_SPHERE_BLOCK,
        type: AuthActionType.OPEN_PROPOSE_MODAL,
        args: {
          code: sphere?.code || null,
          categoryCode: category?.code || null,
        },
      };

      const action = new AuthAction(thisContainerData);
      return appContext.sudirService.authWithActions([action]);
    }

    setSelectedSphere(sphere);
    setSelectedCategory(category);
  };

  // ACTIONS AFTER AUTH
  useEffect(() => {
    if (!spheres?.length) return;

    const actions = appContext.sudirService.getActions(
      ComponentType.BIRTHDAY9_SPHERE_BLOCK
    );
    if (actions.length) {
      const actionData = actions.find(
        (act) => act.type === AuthActionType.OPEN_PROPOSE_MODAL
      );
      if (actionData) {
        const { code, categoryCode } = actionData.args;
        const sphere = GorodService.getSphereByCode(code, spheres);
        const category =
          sphere && GorodService.getCategoryByCode(sphere, categoryCode);

        setTimeout(() => {
          const element = document.querySelector(`[data-id=${code}]`);
          if (element) {
            element.scrollIntoView({
              behavior: "smooth",
              block: "start",
            });
            setTimeout(() => openProposeForm(sphere, category), 1000);
          }
        }, 1000);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [spheres]);

  return (
    <>
      {!!selectedProject && (
        <SphereProjectModal
          isOpen
          onClose={() => setSelectedProject(null)}
          project={selectedProject}
        />
      )}
      {!!selectedSphere && (
        <ProposeIdeaForm
          isOpen
          onClose={() => {
            setSelectedSphere(null);
            setSelectedCategory(null);
          }}
          sphere={selectedSphere}
          category={selectedCategory}
          setIsSubmittedProposeModalOpen={setIsSubmittedProposeModalOpen}
        />
      )}
      {isSubmittedProposeModalOpen && (
        <ProposeIdeaSubmittedForm
          isOpen
          onClose={() => setIsSubmittedProposeModalOpen(false)}
          handleOpenPropose={openProposeForm}
        />
      )}
      <section id="propose" className="birthday-spheres">
        {renderSpheresData.map((item, i) => {
          const Line = i < renderSpheresData.length - 1 && lines[i];
          return (
            <div className="birthday-spheres__item" key={i}>
              <SphereBlock
                {...item}
                showReverse={!!(i % 2)}
                onSelectCategory={openProposeForm}
                onSelectProject={setSelectedProject}
              />
              {isWidestDesktop && Line && (
                <ScrollIntersectionController
                  delay={100}
                  offetTop="80%"
                  offsetBottom="20%"
                  callback={(coef) => {
                    const fullLength = lineRefs.current[i].getTotalLength();
                    const partLength = fullLength * coef;
                    lineRefs.current[i].style.strokeDasharray =
                      String(fullLength);
                    lineRefs.current[i].style.strokeDashoffset = String(
                      fullLength - partLength
                    );
                  }}
                  className="birthday-spheres__line-wrapper"
                >
                  <Line
                    ref={(el) => {
                      if (el) {
                        lineRefs.current[i] = el;
                      } else {
                        delete lineRefs.current[i];
                      }
                    }}
                  />
                </ScrollIntersectionController>
              )}
            </div>
          );
        })}
      </section>
    </>
  );
};

export default BirthdaySpheres;
