import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import "dayjs/locale/ru";
import { useHistory } from "react-router-dom";

import PublicationService from "../../../services/publicationService";
import Share from "../../presentational/Share/Share";
import MetaDecorator from "../../presentational/MetaDecorator/MetaDecorator";

import "./PublicationDetailsPage.scss";
import { Publication } from "../../../types/Publication.interface";
import { ApiStatusCode } from "../../../types/Common.interface";
import { useDispatch } from "react-redux";
import { setFooterExtra } from "../../../store/actions/LayoutActions";
import SocialNetworks from "../../containers/Footer/SocialNetworks/SocialNetworks";

const PublicationDetails = (props) => {
  const history = useHistory();
  const [publicationItem, setPublicationItem] = useState<Publication | any>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setFooterExtra(null));
    return () => {
      dispatch(setFooterExtra(<SocialNetworks />));
    };
  }, []);

  useEffect(() => {
    const id = getPublicationId();

    PublicationService.getPublicationById(id)
      .then((value) => {
        if (value.status === ApiStatusCode.OK) {
          setPublicationItem(value.data);
        } else {
          history.push("/public");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const getPublicationId = () => {
    const [blank, page, id] = history.location.pathname.split("/");

    return id;
  };

  const pushPage = (item, page) => {
    history.push({
      pathname: "/" + page,
      state: { item },
    });
  };

  // RENDER
  const renderArrowBack = () => {
    return (
      <div className="publication-details__back" onClick={(e) => history.push("/public")}>
        <div className="ic ui-icon-88"></div>
        Все публикации
      </div>
    );
  };

  const renderTitle = () => {
    return publicationItem && <h1 className="publication-details__title">{publicationItem.title}</h1>;
  };

  const renderInfo = () => {
    return (
      <div className="publication-details__info">
        <div className="publication-details__info-date">
          {dayjs(publicationItem.published).locale("ru").format("DD MMMM YYYY, HH:mm")}
        </div>
        <div className="publication-details__info-tag" onClick={() => pushPage(publicationItem, "public")}>
          {publicationItem.tag}
        </div>
      </div>
    );
  };

  const renderImage = () => {
    return (
      <img
        className="publication-details__img"
        src={"/uploads/get/" + publicationItem.imageId}
        alt={publicationItem.imageId}
      />
    );
  };

  const renderText = () => {
    return <div className="publication-details__text" dangerouslySetInnerHTML={{ __html: publicationItem.text }}></div>;
  };

  const renderShare = () => {
    return (
      <div className="publication-details__share">
        <span>Поделиться:</span>
        <Share
          type="unfolded"
          url={`/public/${publicationItem.number}`}
          imageUrl={`/uploads/get/${publicationItem.id}`}
          title={`${publicationItem.title} — Проект Правительства Москвы «Сообщество»`}
          colorIcon="#282828"
        />
      </div>
    );
  };

  return (
    <section className="publication-details">
      {publicationItem && (
        <MetaDecorator
          title="Платформа Правительства Москвы «Город идей»"
          description="Всё самое интересное и актуальное о проектах."
          opengraph={{
            title: publicationItem?.title,
            description: publicationItem?.text,
            url: window.location.href,
            image: window.location.origin + "/uploads/get/" + publicationItem?.imageId,
          }}
        />
      )}

      {renderArrowBack()}
      {publicationItem && renderTitle()}
      {publicationItem && renderInfo()}
      {publicationItem && renderImage()}
      {publicationItem && renderText()}
      {publicationItem && renderShare()}
    </section>
  );
};

export default PublicationDetails;
