import React, { HTMLAttributes } from "react";

import { scrollTop } from "../../../../utils/helperFunctions";

import "./ScrollTopButton.scss";

interface Props extends HTMLAttributes<HTMLDivElement> {
  scrolled: number;
}

class ScrollTopButton extends React.Component<Props, any> {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
    };
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.scrolled !== prevProps.scrolled) {
      this.props.scrolled > 800
        ? this.setState({ visible: true })
        : this.setState({ visible: false });
    }
  }

  render() {
    const { scrolled, ...otherProps } = this.props;
    return (
      <>
        {this.state.visible && (
          <div className="scroll-top-button__wrapper" {...otherProps}>
            <div className="scroll-top-button" onClick={() => scrollTop()}>
              <div className="ic ui-icon-88"></div>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default ScrollTopButton;
