import React, { forwardRef, useContext, useEffect, useState } from "react";
import { useHistory, useLocation, withRouter } from "react-router-dom";
import NewsCard from "../../presentational/Cards/NewsCard/NewsCard";
import MetaDecorator from "../../presentational/MetaDecorator/MetaDecorator";
import PageTitle from "../../presentational/PageTitle/PageTitle";
import PageDescription from "../../presentational/PageDescription/PageDescription";
import EventsTrackWrapperClick from "../../containers/EventsTrack/wrappers/EventsTrackWrapperClick";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../types/State.interface";
import MediaQuery, { useMediaQuery } from "react-responsive";
import { phoneWidth } from "../../../utils/constants/widthConstants";
import Search, { SearchStyleType } from "../../presentational/Controls/Search/Search";
import { AuthAction, AuthActionParam, AuthActionType, ComponentType } from "../../../services/sudirService";
import { AppContext } from "../../Root";
import { findUrlParam, formatNumber, removeUrlParam } from "../../../utils";
import IdeaService from "../../../services/ideaService";
import { ApiStatusCode } from "../../../types/Common.interface";
import { showErrorToast } from "../../../store/actions/ToastActions";
import EventsTrackWrapperScroll from "../../containers/EventsTrack/wrappers/EventsTrackWrapperScroll";
import { setAppColor } from "../../../store/actions/LayoutActions";
import { AppColorsEnum } from "../../App.interface";
import { getIdeaTags } from "../../../store/actions/IdeasActions";
import FourColumnLoader from "../../presentational/Loaders/FourColumnLoader/FourColumnLoader";
import FadeIn from "../../presentational/FadeIn/FadeIn";
import { photosDecl } from "../../../utils/declanation";
import Platform from "../../../services/platform";
import EventsTrackParentIdContext from "../../containers/EventsTrack/EventsTrackParentIdContext";
import GridView from "../../presentational/GridView/GridView";
import SendIdeaForm from "../../containers/Forms/SendIdeaForm/SendIdeaForm";
import UserDetailsModal from "../../containers/Modals/UserDetailsModal/UserDetailsModal";
import IdeasPhotoViewer from "../../containers/Modals/IdeasPhotoViewer/IdeasPhotoViewer";
import IdeaCard from "../../presentational/Cards/IdeaCard/IdeaCard";

import "./IdeasPage.scss";
import Button from "../../presentational/Controls/Button/Button";
import { useForceUpdate } from "../../../hooks/useForceUpdate";
import { Idea } from "../../../types/Idea.interface";
import { selectIsUserReady } from "../../../store/reducers/profile";

const SearchInput = forwardRef<HTMLInputElement, any>((props, ref) => (
  <EventsTrackWrapperClick id={["FILTER", "FIELD", "CLICK"]} replace={["Поиск"]}>
    <input ref={ref} {...props} />
  </EventsTrackWrapperClick>
));

const IdeasPage = (props) => {
  const forceUpdate = useForceUpdate();
  const appContext = useContext(AppContext);
  const dispatch = useDispatch();
  const history = useHistory<{ item: Idea }>();
  const user = useSelector((state: RootState) => state.user.userDetails);
  const screenWidth = useSelector((state: RootState) => state.globalEvents.width);
  const tags = useSelector((state: any) => state.ideas.ideasTags);
  const stats = useSelector((state: RootState) => state.totalStatistics.stats);

  const [ideasList, setIdeasList] = useState(null);
  const [gridViewInstance, setGridViewInstance] = useState(null);
  const [currentIdea, setCurrentIdea] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [filteredGroups, setFilteredGroups] = useState(null);
  const [selectedTags, setSelectedTags] = useState(getTagsFromUri());
  const [filteredTags, setFilteredTags] = useState(getTagsFromUri());
  const [ideaModalOpen, setIdeaModalOpen] = useState(false);
  const [photoViewerOpen, setPhotoViewerOpen] = useState(false);
  const [authorModalOpen, setAuthorModalOpen] = useState(false);
  const isMobile = useMediaQuery({ query: `(max-width: ${phoneWidth}px)` });
  const [page, setPage] = useState(0);
  const isUserReady = useSelector(selectIsUserReady);

  useEffect(() => {
    dispatch(setAppColor(AppColorsEnum.GRAY));
    if (!tags) {
      dispatch(getIdeaTags());
    }
  }, []);

  useEffect(() => {
    const idea = history.location.state?.item;
    if (idea) {
      addTag(idea.tag.name);
    }
  }, [history]);

  useEffect(() => {
    if (tags?.length) {
      setFilteredGroups(getGroupedTags());
    }
  }, [tags]);

  useEffect(() => {
    if (isUserReady && ideasList?.length) {
      checkActionsAfterAuth();
    }
  }, [isUserReady, ideasList]);

  useEffect(() => {
    if (gridViewInstance) {
      loadIdeaFromUrl();
    }
  }, [gridViewInstance]);

  const checkActionsAfterAuth = () => {
    const actions = appContext.sudirService.getActions(ComponentType.IDEA_PAGE);
    if (actions.length) {
      const likeAction = actions.find((act) => act.type === AuthActionType.LIKE);
      if (likeAction) {
        const ideas = gridViewInstance.getItems();
        const idea = ideas.find((i) => i.id === likeAction.args.ideaId);
        idea && handleLike(idea);
      }
      const openFormAction = actions.find((act) => act.type === AuthActionType.OPEN_IDEAS_MODAL);
      if (openFormAction) {
        setIdeaModalOpen(true);
      }
    }
  };

  const loadIdeaFromUrl = async () => {
    const ideaId = history?.location?.state?.ideaId || findUrlParam("id", history.location.search);
    if (ideaId) {
      const idea = await loadIdeaById(ideaId);
      _setIdea(idea);
    }
  };

  const loadIdeaById = async (id) => {
    try {
      const { status, message, data } = await IdeaService.ideaById(id);
      if (status === ApiStatusCode.OK) {
        return data;
      } else {
        throw new Error(message);
      }
    } catch (e: any) {
      dispatch(showErrorToast(e.message));
    }
  };

  const onSearch = (value) => {
    const groupTags = getGroupedTags();
    
    const filtered = groupTags.map(group => {
      const filteredItems = group.items?.filter(item =>
        item.toLowerCase().includes(value.toLowerCase())
      );

      return {
        ...group,
        items: filteredItems,
      };
    }).filter(group => group.items && group.items.length > 0)

    setFilteredGroups(filtered);
    setSearchValue(value);
  };

  const uploadPhoto = () => {
    if (!user.loggedIn) {
      const thisContainerData: AuthActionParam = {
        component: ComponentType.IDEA_PAGE,
        type: AuthActionType.OPEN_IDEAS_MODAL,
        args: {},
      };

      const action1 = new AuthAction(thisContainerData);
      return appContext.sudirService.authWithActions([action1]);
    }

    setIdeaModalOpen(true);
  };

  const addTag = (tag) => setSelectedTags([...selectedTags, tag]);

  const deleteTag = (tag) => setSelectedTags([...selectedTags.filter((t) => t !== tag)]);

  function getGroupedTags() {
    const _tags =
      tags?.map((tag) => {
        return { id: tag.id, title: tag.name, items: tag.tags.map((tag) => tag.name) };
      }) || [];
    return _tags;
  }

  function getTagsFromUri() {
    const decoded = decodeURI(history.location.search);
    if (decoded) {
      const params = findUrlParam("tags", decoded);
      return (params && params.split(",").filter(Boolean)) || [];
    }
    return [];
  }

  const onTagSelect = (tag) => (selectedTags.includes(tag) ? deleteTag(tag) : addTag(tag));

  const onCardTagClick = (idea) => {
    if (!selectedTags.includes(idea.tag?.name)) {
      addTag(idea.tag?.name);
    }
  };

  const handleLike = (idea) => {
    if (!user.loggedIn) {
      const thisContainerData: AuthActionParam = {
        component: ComponentType.IDEA_PAGE,
        type: AuthActionType.LIKE,
        args: {
          ideaId: idea.id,
        },
      };

      const action1 = new AuthAction(thisContainerData);
      return appContext.sudirService.authWithActions([action1]);
    }

    !idea.liked && like(idea);
  };

  const like = async (idea) => {
    try {
      const { status, message, data } = await IdeaService.like(idea.id);
      if (status === ApiStatusCode.OK) {
        idea.liked = true;
        idea.rating++;
      } else {
        throw new Error(message);
      }
    } catch (e: any) {
      dispatch(showErrorToast(e.message));
    } finally {
      forceUpdate();
    }
  };

  const canSlidePrev = () => {
    if (!gridViewInstance) return false;

    const ideas = gridViewInstance.getItems();
    const index = ideas.findIndex((i) => i.id === currentIdea.id);
    if (index === -1) return false;

    return index > 0 || gridViewInstance.grid.page !== 0;
  };

  const canSlideNext = () => {
    if (!gridViewInstance) return false;

    const ideas = gridViewInstance.getItems();
    const index = ideas.findIndex((i) => i.id === currentIdea.id);
    if (index === -1) return false;

    return index < ideas.length - 1 || gridViewInstance.canSlideNext();
  };

  const onSlidePrev = async () => {
    if (!gridViewInstance) return;

    const ideas = gridViewInstance.getItems();
    const index = ideas.findIndex((i) => i.id === currentIdea.id);

    if (index === 0) {
      await gridViewInstance.slidePrev();
      const ideas = gridViewInstance.getItems();
      _setIdea(ideas[ideas.length - 1]);
      return;
    }
    _setIdea(ideas[index - 1]);
  };

  const onSlideNext = async () => {
    if (!gridViewInstance) return;

    const ideas = gridViewInstance.getItems();
    const index = ideas.findIndex((i) => i.id === currentIdea.id);

    if (index === ideas.length - 1) {
      await gridViewInstance.slideNext();
      const ideas = gridViewInstance.getItems();
      _setIdea(ideas[0]);
      return;
    }

    _setIdea(ideas[index + 1]);
  };

  const _setIdea = (idea) => {
    const pageUrl = `/ideas?page=${gridViewInstance.grid.page + 1}&id=${idea.id}`;
    window.history.replaceState("", "", pageUrl);
    setCurrentIdea(idea);
    setPhotoViewerOpen(true);
  };

  const renderSearch = () => {
    const search = (
      <div className="ideas__search">
        <EventsTrackWrapperClick id={["SEND_FORM", 182]} replace={["Загрузить фото"]}>
          <Button
            type="filled"
            size="long"
            text={isMobile ? '<div class="ic ui-icon-97"></div>' : "Загрузить фото"}
            onClick={uploadPhoto}
          />
        </EventsTrackWrapperClick>

        <Search
          value={searchValue}
          placeholder="Поиск"
          restrictListBy={5}
          items={filteredGroups}
          type={SearchStyleType.GROUP}
          onChange={onSearch}
          selectedItems={selectedTags}
          isWithList={true}
          isMultiplePerRow={true}
          isRounded={true}
          isClearPlaceholderOnSelect={true}
          isCloseOnSelect={false}
          onItemSelect={(tag) => onTagSelect(tag)}
          autocomplete={false}
          ComponentInput={SearchInput}
          expandOnSearchMatch={true}
        />
      </div>
    );
    return (
      <>
        <MediaQuery maxWidth={phoneWidth}>
          <EventsTrackWrapperScroll id={["FILTER", "DOCSCROLL"]}>{search}</EventsTrackWrapperScroll>
        </MediaQuery>
        <MediaQuery minWidth={phoneWidth + 1}>{search}</MediaQuery>
      </>
    );
  };

  const getPageDescription = () => {
    const postedIdeaCount = stats?.postedIdeaCount || 0;
    const sent1 = `Уже сегодня в галерее «Города идей» опубликовано более <span class="ideas__count">${formatNumber(
      postedIdeaCount
    )}</span>  ${photosDecl(postedIdeaCount)} от жителей. `;
    const sent2 = "Участвуйте в жизни города и подтверждайте реализацию идей, предложенных на платформе «Город идей»! ";
    const sent3 = `Ознакомьтесь с <a href="${window.location.origin}/photos" target="_blank" data-click-id='["BLOCK_SECTION_DESC", 183]' data-replace='["Списка условий по сбору фото"]'>основными условиями</a> и <a href='${window.location.origin}/moderation' target='_blank' data-click-id='["BLOCK_SECTION_DESC", 183]' data-replace='["Правила модерации"]'>правилами модерации</a>.`;
    return sent1 + sent2 + sent3;
  };

  const renderTags = () => {
    if (!selectedTags.length) return;
    return (
      <div className="ideas__tags">
        <div className="ideas__tags-list">
          {selectedTags.map((tag, idx) => (
            <div className="ideas__tags-list-tag" key={idx}>
              <span className="ideas__tags-list-tag-text">{tag}</span>
              <div className="ic ui-icon-14" onClick={(e) => deleteTag(tag)}></div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const getEndpoint = () => {
    const endpoint = "/api/idea/getByFilter?statuses=PUBLISHED,PUBLISHED_MAIN&sort=pos,desc&";
    if (selectedTags.length) {
      const tagsQuery = encodeURI(selectedTags.join(","));
      return `${endpoint}tags=${tagsQuery}&`;
    }
    return endpoint;
  };

  const renderList = () => {
    const isTabletCardLayout = screenWidth <= 1024 && screenWidth > 500;
    const isWIdeTabletWodth = screenWidth <= 989 && screenWidth >= 744;
    const isTabletWidth = screenWidth <= 812 && screenWidth > 500;
    const isPhoneCardLayout = Platform.isPhone() && !isTabletWidth;
    const restrictBy = isWIdeTabletWodth ? 21 : 20;
    const minCardWidth = isTabletCardLayout ? 213 : isPhoneCardLayout ? 280 : 288;

    const gridColumns = `repeat(auto-fill, minmax(${minCardWidth}px, 1fr))`;

    return (
      <EventsTrackParentIdContext.Provider value="BLOCK_PHOTO_CARD">
        <div className="ideas__list">
          {
            <GridView
              columns={gridColumns}
              gap="24px"
              restrictBy={restrictBy}
              scrollToTopOnSlide={true}
              endpoint={getEndpoint()}
              withUpdateLocation={true}
              withPagination={true}
              bindTo="idea"
              onInit={setGridViewInstance}
              renderPagination={(instance) => (
                <EventsTrackParentIdContext.Provider value="PAGINATION">
                  <EventsTrackWrapperScroll id="DOCSCROLL" needParent>
                    <div>{instance.renderPagination()}</div>
                  </EventsTrackWrapperScroll>
                </EventsTrackParentIdContext.Provider>
              )}
              onLoadEnd={({ page, items }) => {
                setPage(page);
                setIdeasList(items);
              }}
            >
              <IdeaCard
                user={user}
                onCardClick={_setIdea}
                onAuthorClick={(idea) => {
                  setCurrentIdea(idea);
                  setAuthorModalOpen(true);
                }}
                onTagClick={onCardTagClick}
                onLike={handleLike}
              />
            </GridView>
          }
        </div>
      </EventsTrackParentIdContext.Provider>
    );
  };

  const isLoaded = () => tags && stats;
  if (!isLoaded()) return <FourColumnLoader />;

  const title = "«Город идей» — Галерея реализованных идей";
  const description =
    "Ознакомьтесь с фотографиями реализованных идей, которые предлагали жители на платформе «Город идей».";
  return (
    <FadeIn>
      <section className="ideas">
        <MetaDecorator
          title={title + `. Страница ${page + 1}`}
          description={description}
          opengraph={{ title, description }}
        />

        <PageTitle text={"Галерея реализованных идей"} />
        <PageDescription text={getPageDescription()} />
        {renderSearch()}
        {renderTags()}
        {renderList()}
        {ideaModalOpen && <SendIdeaForm user={user} isOpened={ideaModalOpen} onClose={() => setIdeaModalOpen(false)} />}
        {currentIdea && authorModalOpen && (
          <UserDetailsModal idea={currentIdea} isOpen={authorModalOpen} onClose={() => setAuthorModalOpen(false)} />
        )}
        {currentIdea && (
          <IdeasPhotoViewer
            idea={currentIdea}
            isOpened={photoViewerOpen}
            onClose={(e) => {
              const pageUrl = `/ideas?page=${gridViewInstance?.grid.page + 1}`;
              window.history.replaceState("", "", pageUrl);
              removeUrlParam("id", history);
              setPhotoViewerOpen(false);
            }}
            canSlideNext={canSlideNext()}
            canSlidePrev={canSlidePrev()}
            onSlideNext={onSlideNext}
            onSlidePrev={onSlidePrev}
            onAuthorClick={(idea) => {
              setCurrentIdea(idea);
              setAuthorModalOpen(true);
            }}
            onLike={handleLike}
          />
        )}
      </section>
    </FadeIn>
  );
};

export default IdeasPage;
