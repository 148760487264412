import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
// import ReCAPTCHA from "react-google-recaptcha";
import CaptchaMosRu from "../../../presentational/CaptchaMosRu/CaptchaMosRu";
import { ApiStatusCode } from "../../../../types/Common.interface";

import Input from "../../../presentational/Controls/Input/Input";
import Button from "../../../presentational/Controls/Button/Button";
import Textarea from "../../../presentational/Controls/Textarea/Textarea";
import Select from "../../../presentational/Controls/Select/Select";
import FileSelector from "../../../presentational/Controls/FileSelector/FileSelector";
import SubmitForm from "../SubmitForm/SubmitForm";
import SupportService from "../../../../services/supportService";
import {
  hideInfoModal,
  showInfoModal,
} from "../../../../store/actions/LayoutActions";
import "./SupportForm.scss";
import { store } from "../../../../store/store";
import { RootState } from "../../../../types/State.interface";

const types = [
  "Регистрация и авторизация",
  "Участие в проекте",
  "Техническая ошибка",
  "Общие вопросы",
];

const maxFiles = 10;
const maxTotalSizeMB = 10;
const maxAllowedDescriptionLength = 700;

// let CaptchaKey;

const SupportForm = (props) => {
  const dispatch = useDispatch();
  const state = store.getState();
  // CaptchaKey = state.environment?.captcha;

  const user = useSelector((state: RootState) => state.user.userDetails);
  // const recaptchaRef = React.useRef();
  const [captchaValue, setCaptchaValue] = useState<any>(undefined);
  const [captchaId, setCaptchaId] = useState("");
  const [captchaImageBase64, setCaptchaImageBase64] = useState("");
  const [email, setEmail] = useState("");
  const [emailValidator, setEmailValidator] = useState<any>(null);
  const [description, setDescription] = useState("");
  const [descriptionValidator, setDescriptionValidator] = useState<any>(null);
  const [type, setType] = useState(types[0]);
  const [images, setImages] = useState<any[]>([]);
  const [submitted, setSubmitted] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  useEffect(() => setEmail(user?.loggedIn ? user?.email : ""), [user]);

  const refreshCaptcha = () => {
    SupportService.generateCaptcha().then((response) => {
      if (response && response.status === 0) {
        setCaptchaId(response.data.id);
        setCaptchaImageBase64(response.data.content);
      }
    });
  };

  const submitForm = (e) => {
    if (e) e.preventDefault();

    setSubmitted(true);
    if (formValid()) {
      setSubmitting(true);
      const formData = {};

      formData["from"] = "PROMO";
      formData["email"] = email;
      formData["message"] = description;
      formData["type"] = type;
      if (images.length) {
        formData["attachments"] = images.map((img) => img.id);
      }

      if (!isVerifiedUser()) {
        formData["captcha"] = captchaValue;
        formData["captchaId"] = captchaId;
      }

      SupportService.submitIssue(formData).then((response) => {
        if (response && response.status === 0) {
          const workHoursMessage =
            " \nОбратите внимание, что служба технической поддержки наиболее оперативно отвечает на сообщения в будние дни с 9:00 до 18:00.";
          dispatch(
            showInfoModal(
              "Письмо в техподдержку успешно отправлено." +
                (new Date().getHours() < 18 && new Date().getHours() >= 9
                  ? ""
                  : workHoursMessage),
              renderOkButton()
            )
          );
          props.close();
        } else {
          if (response.status === ApiStatusCode.CAPTCHA_VALIDATION_ERROR) {
            refreshCaptcha();
            setCaptchaValue("");
          }
          dispatch(showInfoModal(response.message));
        }

        setSubmitting(false);
      });
    }
  };

  const isVerifiedUser = () => user?.loggedIn && user?.emailVerified;

  const isCaptchaValid = () => (isVerifiedUser() ? true : captchaValue);

  const formValid = () => {
    return (
      emailValidator.isValid() &&
      descriptionValidator.isValid() &&
      isCaptchaValid()
    );
  };

  const renderOkButton = () => {
    return (
      <Button
        type="outlined"
        text="Хорошо"
        onClick={() => dispatch(hideInfoModal())}
      />
    );
  };

  const renderContent = () => {
    return (
      <div className="support-form">
        <Input
          label="Электронная почта"
          placeholder="Введите email"
          isRequired={true}
          value={email}
          onChange={(value) => setEmail(value)}
          validateRules={{
            email: true,
          }}
          onInitValidator={(validator) => setEmailValidator(validator)}
          showError={submitted}
        />

        <Textarea
          value={description}
          label="Сообщение"
          placeholder="Введите текст"
          maxLength={maxAllowedDescriptionLength}
          onlyVerticalResize={true}
          isRequired={true}
          onChange={(value) => setDescription(value)}
          validateRules={{
            minLength: 10,
            maxLength: maxAllowedDescriptionLength,
          }}
          onInitValidator={(validator) => setDescriptionValidator(validator)}
          showError={submitted}
        />

        <Select
          value={type}
          items={types}
          closeOnSelect={true}
          label="Категория вопроса"
          // bindTo="type"
          onItemSelect={(type) => setType(type)}
        />

        {!isVerifiedUser() && (
          <div
            className={`recaptcha-wrapper ${
              submitted && !captchaValue ? "recaptcha-wrapper--error" : ""
            }`}
          >
            {/* <ReCAPTCHA
              ref={recaptchaRef}
              sitekey={CaptchaKey}
              style={{ marginTop: "20px" }}
              onChange={setCaptchaValue}
            /> */}

            <CaptchaMosRu
              captchaValue={captchaValue}
              captchaImageBase64={captchaImageBase64}
              refreshCaptcha={refreshCaptcha}
              setCaptchaValue={(val) => setCaptchaValue(val)}
            />
          </div>
        )}

        <FileSelector
          upload={true}
          name="Добавить файл"
          text={`Вы можете загрузить до ${maxFiles} изображений совокупным объемом до ${maxTotalSizeMB} Мб., допустимы форматы .jpeg, .jpg, .png, .webp`}
          isMultiple
          maxFiles={maxFiles}
          allowedExtensions={["image/jpeg", "image/jpg", "image/png", "image/webp"]}
          maxTotalSizeMB={maxTotalSizeMB}
          onChange={(images) => setImages(images)}
        />
      </div>
    );
  };

  return (
    <SubmitForm
      user={user}
      isSubmitting={submitting}
      isOpen={props.isOpened}
      title="Сообщение в службу поддержки"
      onClose={props.close}
      onSubmit={submitForm}
    >
      {renderContent()}
    </SubmitForm>
  );
};

export default SupportForm;
