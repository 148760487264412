import React from "react";
import Game from "./Game/Game";
import image from "../../../../../assets/newyear2023/games/puzzle/puzzle.svg";
import useImagePreload from "../../hooks/useImagePreload";
import GameWrapper from "../Wrappers/GameWrapper/GameWrapper";
import { MosProjects } from "../../../../../types/Projects.interface";
import { gameTitle, startText } from "./PuzzleGame.constants";

const imageSources = [image];

interface PuzzleGameProps {
  onWin?: () => void;
}

const PuzzleGame: React.FC<PuzzleGameProps> = ({ onWin }) => {
  const isLoad = useImagePreload(imageSources);

  return (
    <GameWrapper onWin={onWin} gameTitle={gameTitle} project={MosProjects.GZ} startText={startText}>
      {isLoad && <Game rows={6} columns={6} imageSrc={image} />}
    </GameWrapper>
  );
};

export default PuzzleGame;
