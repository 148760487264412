import { FC, useEffect, useState } from "react";

import dayjs from "dayjs";
import "./DiscussionCard.scss";
import classNames from "classnames";
import { Discussion, DiscussionStatus } from "../Discussion.interface";

dayjs.locale("ru");

export interface DiscussionCardProps {
  data: Discussion;
  onClick: (data: Discussion) => void;
}

const DiscussionCard: React.FC<DiscussionCardProps> = ({ data, onClick }) => {
  const { id, title, description, start, finish, status } = data;

  const getStatusText = (status, dateStart, dateEnd) => {
    switch (status) {
      case DiscussionStatus.NEW:
        return `Обсуждение откроется ${dayjs(dateStart).format("DD.MM.YYYY")}`;
      case DiscussionStatus.STARTED:
        return `${dayjs(dateStart).format("DD.MM.")} - ${dayjs(dateEnd).format("DD.MM.YYYY")}`;
      case DiscussionStatus.FINISHED:
        return `Обсуждение завершено`;
    }
  };

  const handleClick = (e) => {
    if (status === DiscussionStatus.STARTED || status === DiscussionStatus.FINISHED) {
      onClick(data);
    }
  }


  return (
    <div className={classNames("discussion-card", `discussion-card__${status.toLowerCase()}`)} onClick={handleClick}>
      <div className="discussion-card__head">
        <span
          className={classNames("ic", {
            [`ui-icon-clock-two`]: status === DiscussionStatus.STARTED,
            [`ui-icon-calendar-two`]: status === DiscussionStatus.NEW,
            [`ui-icon-25`]: status === DiscussionStatus.FINISHED,
          })}
        ></span>
        <div className="discussion-card__status">{getStatusText(status, start, finish)}</div>
      </div>
      <div className="discussion-card__title">{title}</div>
    </div>
  );
};

export default DiscussionCard;
