import { API } from "../../../../services/api";
import { BaseResponse } from "../../../../types/Common.interface";
import { MosProjects } from "../../../../types/Projects.interface";
import { AnswerResult, GameStatus, Question } from "../../../presentational/Game/Game.interface";

const cityApiPath = "/api/city";
const gameApiPath = "/api/game";

export enum InformerSubType {
  NEW_YEAR_2023 = "NEW_YEAR_2023",
  NEW_YEAR_2024 = "NEW_YEAR_2024",
}

interface SetInformerData {
  date?: string;
  subtype: InformerSubType;
  type: string;
}

export interface GetInformerData {
  exists: boolean;
  type: string;
}

interface GetNextWishResponse {
  signature: string;
  text: string;
}

enum GameSubtype {
  SIMPLE = "SIMPLE",
  QUIZ = "QUIZ",
  NEW_YEAR_WISH = "NEW_YEAR_WISH",
}

type Game = {
  subtype: GameSubtype;
  description: string;
};

interface GameStateResponse {
  type: MosProjects;
  status: GameStatus;
}

const games: Record<MosProjects, Game> = {
  [MosProjects.GI]: { subtype: GameSubtype.SIMPLE, description: "Судоку" },
  [MosProjects.AG]: { subtype: GameSubtype.QUIZ, description: "Викторина" },
  [MosProjects.ED]: { subtype: GameSubtype.SIMPLE, description: "Найди пару" },
  [MosProjects.GZ]: { subtype: GameSubtype.SIMPLE, description: "Пазл" },
  [MosProjects.NG]: { subtype: GameSubtype.SIMPLE, description: "Новогодний лабиринт" },
  [MosProjects.MP]: { subtype: GameSubtype.NEW_YEAR_WISH, description: "Миллион призов" },
};

const NewYear2023Service = {
  getNextWish: (type = MosProjects.MP) => {
    return API.get<BaseResponse<GetNextWishResponse>>(`${gameApiPath}/nextWish?type=${type}`).then(({ data }) => {
      return data?.data;
    });
  },

  getQuizQuestion: (type = MosProjects.AG) => {
    return API.get<BaseResponse<Question>>(`${gameApiPath}/nextQuestion?type=${type}`).then(({ data }) => {
      return data?.data;
    });
  },

  checkQuizAnswer: (answerText: string, type = MosProjects.AG) => {
    return API.get<BaseResponse<AnswerResult>>(`${gameApiPath}/checkAnswer?answerText=${answerText}&type=${type}`).then(
      ({ data }) => {
        return data?.data;
      }
    );
  },

  startGame: (data: { type: MosProjects }) => {
    return API.post(`${gameApiPath}/start`, data).then((data) => {
      return data?.data;
    });
  },

  finishGame: (data: { type: MosProjects; status: GameStatus }) => {
    return API.post(`${gameApiPath}/finish`, data).then((data) => {
      return data?.data;
    });
  },

  status: (type: MosProjects[]) => {
    return API.get<BaseResponse<GameStateResponse[]>>(`${gameApiPath}/status?type=${type}`).then((data) => {
      return data?.data;
    });
  },

  setInformer: (data: SetInformerData[]) => {
    return API.post<BaseResponse>(`${cityApiPath}/setInformer`, data).then((data) => {
      return data?.data;
    });
  },

  getInformer: (subtype: InformerSubType) => {
    return API.get<BaseResponse<GetInformerData[]>>(`${cityApiPath}/getInformer?subtype=${subtype}`).then(
      ({ data }) => {
        return data?.data;
      }
    );
  },
};

export default NewYear2023Service;
