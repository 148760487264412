import React, { FC, useEffect } from "react";
import {
  isIE,
  isChrome,
  isChromium,
  engineName,
  engineVersion,
  isEdge,
  fullBrowserVersion,
  isOpera,
  isFirefox,
  isSafari,
  isYandex,
  isLegacyEdge,
} from "react-device-detect";

import Logo from "../../Header/Logo/Logo";

import "./BrowserSupport.scss";


const MIN_EDGE = 93;
const MIN_CHROME = 64;
const MIN_FIREFOX = 58;
const MIN_SAFARI = 12;
const MIN_OPERA = 64;
const MIN_YANDEX = 17; // 2017 last for XP and Vista
const MIN_CHROMIUM = 56; // 2017
const BLINK = "Blink"; // Chrome and other similar browsers
const MIN_BLINK = 64; // Chrome v 64
const WEBKIT = "WebKit"; // safari
const MIN_WEBKIT = 605; // Safari v 12

const BrowserSupport: FC = ({ children }) => {

  const isLegacyBrowser = () => {
    const bv = fullBrowserVersion;
    const ev = Number.parseInt(engineVersion, 10);

    return (
      isIE ||
      isLegacyEdge ||
      (isEdge && Number.parseInt(bv) < MIN_EDGE) ||
      (isChrome && Number.parseInt(bv) < MIN_CHROME) ||
      (isOpera && Number.parseInt(bv) < MIN_OPERA) ||
      (isFirefox && Number.parseInt(bv) < MIN_FIREFOX) ||
      (isSafari && Number.parseInt(bv) < MIN_SAFARI) ||
      (isYandex && Number.parseInt(bv) < MIN_YANDEX) ||
      (isChromium && Number.parseInt(bv) < MIN_CHROMIUM) ||
      (engineName === BLINK && ev < MIN_BLINK) ||
      (engineName === WEBKIT && ev < MIN_WEBKIT)
    );
  };

  if (isLegacyBrowser()) {
    return (
      <div className="browser-support">
        <div className="browser-support__header">
          <Logo />
        </div>
        <div className="browser-support__body">
          <p>Ваш браузер не поддерживается. <br/> Рекомендуем воспользоваться браузерами из списка:</p>
        </div>

        <div className="browser-support__footer">
          <a href="https://www.google.com/intl/ru/chrome/">
            <img className="browser-support__image" src={require("../../../../assets/social/chrome.svg").default} />
            <span className="browser-support__name">Chrome</span>
          </a>

          <a href="https://www.apple.com/safari/">
            <img className="browser-support__image" src={require("../../../../assets/social/safari.svg").default} />
            <span className="browser-support__name">Safari</span>
          </a>
        </div>
      </div>
    );
  }

  return <>{children}</>;
};

export default BrowserSupport;
