import { ComponentProps } from "react";
import { stripHTML, trimSpaces } from "../../../../../utils";
import Checkbox from "../../../../presentational/Controls/Checkbox/Checkbox";
import EventsTrackWrapperClick from "../EventsTrackWrapperClick";

const EventsTrackClickCheckbox: ComponentProps<typeof Checkbox>["ComponentCheckbox"] = ({html, ...otherProps}) => (
    <EventsTrackWrapperClick id={38} replace={[trimSpaces(stripHTML(html))]}>
        <input {...otherProps} />
    </EventsTrackWrapperClick>
);

export default EventsTrackClickCheckbox;