import { FC, useCallback, useEffect, useRef } from "react";
import { throttle } from "../../../utils";
import { ScrollIntersectionControllerProps } from "./ScrollIntersectionController.types";

const ScrollIntersectionController: FC<ScrollIntersectionControllerProps> = ({
  offetTop,
  offsetBottom,
  callback,
  children,
  className,
  delay,
}) => {
  const wrapperRef = useRef<HTMLDivElement>();
  const callbackRef = useRef<typeof callback>();

  useEffect(() => {
    callbackRef.current = callback;
  });

  const calcCoef = useCallback(() => {
    const { top, height } = wrapperRef.current.getBoundingClientRect();
    const vh = Math.max(
      document.documentElement.clientHeight,
      window.innerHeight
    );

    const offsetTopPx =
      (typeof offetTop === "string" && offetTop.slice(-1) === "%"
        ? (Number(offetTop.slice(0, -1)) * vh) / 100
        : Number(offetTop)) || 0;

    const offsetBottomPx =
      (typeof offsetBottom === "string" && offsetBottom.slice(-1) === "%"
        ? (Number(offsetBottom.slice(0, -1)) * vh) / 100
        : Number(offsetBottom)) || 0;

    const heightDiff = height - vh + offsetTopPx + offsetBottomPx;
    const coef = 1 - (heightDiff + (top - offsetTopPx)) / heightDiff;

    return coef < 0 ? 0 : coef > 1 ? 1 : coef;
  }, [offetTop, offsetBottom]);

  useEffect(() => {
    const handler = throttle(() => {
      callbackRef.current(calcCoef());
    }, delay || 0);

    handler();

    window.addEventListener("scroll", handler);
    window.addEventListener("resize", handler);

    return () => {
      window.removeEventListener("scroll", handler);
      window.removeEventListener("resize", handler);
      handler.cancel();
    };
  }, [calcCoef, delay]);

  return (
    <div className={className} ref={wrapperRef}>
      {children}
    </div>
  );
};

export default ScrollIntersectionController;
