import { FC, useEffect, useLayoutEffect, useRef, useState } from "react";
import "./Informer.scss";
import { InformerProps } from "./Informer.types";
import informerImage from "../../../../../assets/leisure-landing/info-button.svg";
import informerImageLight from "../../../../../assets/leisure-landing/info-button-light.svg";
import Tooltip from "../Tooltip";
import useUpdateEffect from "../../../../../hooks/useUpdateEffect";
import Modal from "../Modal";
import { useSelector } from "react-redux";
import { selectLeisureLandingProgress } from "../../../../../store/reducers/leisureLanding";
import Platform from "../../../../../services/platform";

const isMobileDevice = Platform.isPhone() || Platform.isTablet() || Platform.isIPadOS();

const Informer: FC<InformerProps> = ({
  className,
  style,
  tooltipPlace = "right",
  overrideTooltipPosition,
  onModalShow,
  onModalHide,
  modalPosX,
  modalPosY,
  modalSrc,
  modalTitle,
  tooltipIdx,
  modalOffset,
  onClick,
  canShowModal,
  autoShowModal,
  modalViewed,
}) => {
  const progress = useSelector(selectLeisureLandingProgress);
  const [modalOpened, setModalOpened] = useState(false);
  const [[xPos, yPos], setModalPos] = useState<[typeof modalPosX, typeof modalPosY]>([modalPosX, modalPosY]);
  const modalRef = useRef<HTMLDivElement>();
  const ref = useRef<HTMLButtonElement>();

  useEffect(() => {
    setModalOpened(false);
  }, [progress]);

  useLayoutEffect(() => {
    if (modalOpened && modalRef.current) {
      const { left, right } = modalRef.current.getBoundingClientRect();
      setModalPos((prev) => {
        const [xPosPrev, yPosPrev] = prev;
        if (xPosPrev === "left" && left < 0) {
          return ["right", yPosPrev];
        }
        if (xPosPrev === "right" && right > window.innerWidth) {
          return ["left", yPosPrev];
        }
        return prev;
      });
    } else {
      setModalPos([modalPosX, modalPosY]);
    }
  }, [modalOpened, modalPosX, modalPosY]);

  useUpdateEffect(() => {
    modalOpened ? onModalShow?.() : onModalHide?.();
  }, [modalOpened]);

  const onBtnClick = () => {
    canShowModal && setModalOpened(true);
    onClick?.();
  };

  useEffect(() => {
    autoShowModal && setModalOpened(true);
  }, [autoShowModal]);

  useEffect(() => {
    if (!canShowModal) {
      setModalOpened(false);
    }
  }, [canShowModal]);

  const renderButton = () => (
    <button ref={ref} type="button" className="leisure-landing-informer__button" onClick={onBtnClick}>
      <img
        className="leisure-landing-informer__button-img"
        src={modalOpened || modalViewed ? informerImageLight : informerImage}
        alt=""
      />
    </button>
  );

  const translate =
    modalOffset?.x || modalOffset?.y ? `translate(${modalOffset?.x || 0}px, ${modalOffset?.y || 0}px)` : undefined;

  return (
    <div className={`leisure-landing-informer ${className || ""}`} style={style}>
      {
        /*modalOpened || isMobileDevice*/ true ? (
          renderButton()
        ) : (
          <Tooltip
            idx={tooltipIdx}
            text="Посмотрите, как реализована идея жителя в городе"
            place={tooltipPlace}
            overridePosition={overrideTooltipPosition}
          >
            {renderButton()}
          </Tooltip>
        )
      }
      <Modal
        ref={modalRef}
        rootRef={ref}
        className={`leisure-landing-informer__modal ${
          !modalOpened ? "leisure-landing-informer__modal-hidden" : ""
        } leisure-landing-informer__modal${
          !xPos && !yPos ? "-right" : `${xPos ? `-${xPos}` : ""}${yPos ? `-${yPos}` : ""}`
        }`}
        style={{
          transform: translate,
        }}
        onClose={() => setModalOpened(false)}
        content={
          <>
            <div className="leisure-landing-informer__modal__img">
              <img src={modalSrc} alt="" />
            </div>
            <div
              className="leisure-landing-informer__modal__title"
              dangerouslySetInnerHTML={{ __html: modalTitle }}
            ></div>
          </>
        }
      />
    </div>
  );
};

export default Informer;
