import { NYLandingPlatform } from "../types/NYLanding.interface";
import { API } from "./api";

interface NYLandingFinishGameParams {
  start: string;
  system: NYLandingPlatform;
}

const NYLandingService = {
  async finishGame(params: NYLandingFinishGameParams) {
    const { data } = await API.post("/api/birds/game/save", params);
    return data;
  },
};

export default NYLandingService;
