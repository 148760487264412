import { useEffect, useRef } from "react";
import { bulbs } from "./ChristmasBulbs.helper";

import "./ChristmasBulbs.scss";

const shakeClass = "christmas-bulbs__bulb--active";

const ChristmasBulbs = () => {
  const bulbsRefs = useRef<NodeListOf<HTMLElement> | null>(null);

  useEffect(() => {
    bulbsRefs.current = document.querySelectorAll(".christmas-bulbs__bulb");

    const handleMouseEnter = (event: Event) => {
      const targetElement = event.target as HTMLElement;
      if (targetElement.classList.contains(shakeClass)) return;
      targetElement.classList.add(shakeClass);
      setTimeout(() => disableBulb(event), 900);
    };

    const disableBulb = (event: Event) => {
      const targetElement = event.target as HTMLElement;
      targetElement.classList.remove(shakeClass);
    };

    bulbsRefs.current.forEach((bulb) => {
      bulb.addEventListener("mouseenter", handleMouseEnter);
    });

    return () => {
      bulbsRefs.current?.forEach((bulb) => {
        bulb.removeEventListener("mouseenter", handleMouseEnter);
      });
    };
  }, []);

  return (
    <div className="christmas-bulbs">
      <div dangerouslySetInnerHTML={{ __html: bulbs }}></div>
    </div>
  );
};

export default ChristmasBulbs;
