// @flow
import { fetchRequest } from "../../utils/axiosConfig";

const url = "/api/tagline/get";

export const TAGLINE_REQUEST = "TAGLINE_REQUEST";
export const TAGLINE_SUCCESS = "TAGLINE_SUCCESS";
export const TAGLINE_FAIL = "TAGLINE_FAIL";

export function getTagline() {
  return function (dispatch) {
    dispatch({
      type: TAGLINE_REQUEST,
    });

    fetchRequest(url, {}, "GET")
      .then((response) => {
        if (response.data.status === 0) {
          dispatch({
            type: TAGLINE_SUCCESS,
            payload: response.data.data,
          });
        } else {
          dispatch({
            type: TAGLINE_FAIL,
            error: true,
            payload: new Error(
              "Ошибка запроса данных. Пожалуйста, повторите попытку позже."
            ),
          });
        }
      })
      .catch(() => {
        dispatch({
          type: TAGLINE_FAIL,
          error: true,
          payload: new Error(
            "Ошибка запроса данных. Пожалуйста, повторите попытку позже."
          ),
        });
      });
  };
}
