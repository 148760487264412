import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { showMosruForm } from "../../../../../store/actions/LayoutActions";
import { logout } from "../../../../../store/actions/UserActions";
import { RootState } from "../../../../../types/State.interface";
import Logo from "../../../../containers/Header/Logo/Logo";
import Button from "../../../../presentational/Controls/Button/Button";
import BirthdayHeaderMobile from "./BirthdayHeaderMobile/BirthdayHeaderMobile";

import "./BirthdayHeader.scss";
import { descktopLinks, mobileLinks } from "./BirthdayHeader.helper";
import {
  Birthday9Section,
  BirthdaySections,
} from "../../CrowdBirthday9.helper";
import { matchPath, useHistory } from "react-router-dom";

export interface BirthdayHeaderProps {
  currentSection?: Birthday9Section;
}

const BirthdayHeader = ({ currentSection }: BirthdayHeaderProps) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user.userDetails);

  const getClassForDescktopLink = (link) => {
    let classlist = "birthday-header__link ";
    if (currentSection?.id === link.id) {
      classlist += "birthday-header__link--active";
    }
    return classlist;
  };

  return (
    <header className="birthday-header">
      <div className="birthday-header__inner birthday-block-lessened">
        <Logo />

        <ul className="birthday-header__links">
          {descktopLinks.map((link) => (
            <li
              className={getClassForDescktopLink(link)}
              key={link.id}
              onClick={() => {
                const url = link.href || "/birthday";
                matchPath(history.location.pathname, { path: url, exact: true })
                  ? history.replace(url, { sectionId: link.id })
                  : history.push(url, { sectionId: link.id });
              }}
            >
              <a>{link.text}</a>
            </li>
          ))}
        </ul>

        {user?.loggedIn ? (
          <Button
            type="filled"
            text="Выйти"
            onClick={() => dispatch(logout())}
          />
        ) : (
          <Button
            type="filled"
            text="Войти"
            onClick={() => dispatch(showMosruForm())}
          />
        )}

        <BirthdayHeaderMobile links={mobileLinks} />
      </div>
    </header>
  );
};

export default BirthdayHeader;
