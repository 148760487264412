import { FC, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import Modal from "../Modal";
import "./EventsSlider.scss";
import { EventsSliderProps } from "./EventsSlider.types";
import SwiperCore from "swiper";

const EventsSlider: FC<EventsSliderProps> = ({ slides }) => {
  const [swiper, setSwiper] = useState<SwiperCore>();

  const renderContent = () => (
    <div
      className="leisure-landing-events-slider__content"
      onTouchStart={(e) => e.stopPropagation()}
      onTouchMove={(e) => e.stopPropagation()}
    >
      <Swiper spaceBetween={10} onSwiper={setSwiper} loop>
        {slides.map(({ src, title, text, link }, i) => (
          <SwiperSlide key={i} className="leisure-landing-events-slider__slide">
            <a href={link} target="_blank" className="leisure-landing-events-slider__slide__img">
              <img src={src} alt="" />
            </a>
            <span className="leisure-landing-events-slider__slide__title">{title}</span>
            <a
              href={link}
              target="_blank"
              className="leisure-landing-events-slider__slide__text"
              dangerouslySetInnerHTML={{ __html: text }}
            />
            <a className="leisure-landing-events-slider__slide__link" target="_blank" href={link}>Подробнее</a>
          </SwiperSlide>
        ))}
      </Swiper>
      <div className="leisure-landing-events-slider__nav">
        <div className="leisure-landing-events-slider__nav-left" onClick={() => swiper.slidePrev()} />
        <div className="leisure-landing-events-slider__nav-right" onClick={() => swiper.slideNext()} />
      </div>
    </div>
  );

  return <Modal className="leisure-landing-events-slider" onClose={() => {}} content={renderContent()} />;
};

export default EventsSlider;
