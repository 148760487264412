import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

interface OpengraphMeta {
  title?: string;
  url?: string;
  description?: string;
  image?: string;
}

interface Props {
  title?: string;
  description?: string;
  opengraph?: OpengraphMeta;
}

const MetaDecorator = ({ title, description, opengraph }: Props) => {
  useEffect(() => {
    const defaultOgTitle = document.querySelector('meta[property~="og:title"]:not([data-react-helmet=true])');
    const defaultOgDescription = document.querySelector('meta[property~="og:description"]:not([data-react-helmet=true])');
    const defaultOgImage = document.querySelector('meta[property~="og:image"]:not([data-react-helmet=true])');
    const defaultOgUrl= document.querySelector('meta[property~="og:url"]:not([data-react-helmet=true])');
    const shouldChangeOgMeta = opengraph && [defaultOgTitle, defaultOgDescription, defaultOgImage, defaultOgUrl].some(Boolean);
    if (shouldChangeOgMeta) {
      opengraph.title && defaultOgTitle?.remove();
      opengraph.description && defaultOgDescription?.remove();
      opengraph.image && defaultOgImage?.remove();
      opengraph.url && defaultOgUrl?.remove();
    }
    return () => {
      if (shouldChangeOgMeta) {
        opengraph.title && document.head.appendChild(defaultOgTitle);
        opengraph.description && document.head.appendChild(defaultOgDescription);
        opengraph.image && document.head.appendChild(defaultOgImage);
        opengraph.url && document.head.appendChild(defaultOgUrl);
      }
    }
  }, [])

  return (
    <Helmet>
      {title && <title>{title}</title>}
      {description && <meta name="description" content={description} />}
      {opengraph?.title && (
        <meta property="og:title" content={opengraph.title}></meta>
      )}
      {opengraph?.url && <meta property="og:url" content={opengraph.url}></meta>}
      {opengraph?.description && (
        <meta property="og:description" content={opengraph.description}></meta>
      )}
      {opengraph?.image && (
        <meta property="og:image" content={opengraph.image}></meta>
      )}
    </Helmet>
  );
};

export default MetaDecorator;
