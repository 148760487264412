import React, { Component } from "react";
import { API } from "../../../services/api";
import { connect } from "react-redux";

import { fetchRequest } from "../../../utils/axiosConfig";
import Loader from "../../presentational/Loaders/Loader/Loader";
import MetaDecorator from "../../presentational/MetaDecorator/MetaDecorator";

import "./ModerationPage.scss";
import { setAppColor } from "../../../store/actions/LayoutActions";
import { AppColorsEnum } from "../../App.interface";

// '/api/promo/ideas/moderation/get'

class ModerationPage extends Component<any, any> {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      data: "",
    };
  }

  componentDidMount() {
    this.props.setAppColor(AppColorsEnum.WHITE);

    API.get("/api/settings/moderation")
      .then(
        (resp) =>
          resp &&
          resp.data &&
          resp.data.data &&
          this.setState({ data: resp.data.data })
      )
      .catch((err) =>
        this.setState({
          data: "Что-то пошло не так, попробуйте перезагрузить страницу.",
        })
      );
  }

  render() {
    return !!this.state.data ? (
      <section className="moderation">
        <MetaDecorator title="Правила модерации" />
        <div
          style={{ fontSize: "16px", lineHeight: "1.3em" }}
          dangerouslySetInnerHTML={{ __html: this.state.data }}
        ></div>
      </section>
    ) : (
      <Loader />
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  setAppColor: (color) => dispatch(setAppColor(color)),
});

export default connect(null, mapDispatchToProps)(ModerationPage);
