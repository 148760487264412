import { SphereBlockProps } from "./SphereBlock";

type SpheresData = (Pick<
  SphereBlockProps,
  "statistics" | "people" | "background" | "projects" | "sphereTitle" | "background2"
> & { code: string; categoryCodes?: (string | [string, string])[] })[];

const spheresData: SpheresData = [
  {
    code: "digital",
    // categoryCodes: ["idea_36", "idea_35", "idea_33", "idea_34", "idea_20"],
    statistics: [
      {
        accepted: "> 1015",
        realized: "> 615",
      },
    ],
    projects: [
      {
        img: require("../../../../../assets/birthday9/images/spheres/projects/crowd.png").default,
        title: "Город идей",
        description:
          "Проект был посвящен развитию платформы «Город идей». Участники предложили свыше 600 идей по развитию функционала на платформе и в разделе «Город идей» в мобильном приложении «Активный гражданин».",
      },
      {
        img: require("../../../../../assets/birthday9/images/spheres/projects/gz.png").default,
        title: "Город заданий",
        description:
          "На проекте «Город заданий» более 20 тысяч жителей предлагали свои идеи о том, как сделать новый электронный проект Правительства Москвы удобным для пользователей. В ходе проекта было получено более 700 идей по трем направлениям: функциональность проекта, мобильное приложение и удобство использования промо-сайта.",
      },
      {
        img: require("../../../../../assets/birthday9/images/spheres/projects/ed.png").default,
        title: "Электронный дом",
        description:
          "На проекте «Электронный дом» идеи жителей города были направлены на создание эффективного инструмента для управления многоквартирным домом. По двум направлениям — развитие функциональности проекта и создание справочника для жителей — было подано почти 2000 идей.",
      },
      {
        img: require("../../../../../assets/birthday9/images/spheres/projects/shag.png").default,
        title: "На шАГ впереди",
        description:
          "Проект «На шАГ впереди» был посвящен формированию плана развития проекта «Активный гражданин». Более 10 тысяч участников предлагали свои идеи по трём направлениям: игровые механики, взаимодействие пользователей, функциональность проекта.",
      },
      {
        img: require("../../../../../assets/birthday9/images/spheres/projects/zachet-msk.png").default,
        title: "Зачетная Москва",
        description:
          "Проект «Зачетная Москва» был проведен с целью повышения удобства пользования и развития мобильного приложения «Зачетная Москва». Более 13 тысяч жителей предложили свои идеи по функциональности приложения, развитию пользовательского рейтинга и новым видам поощрений для студентов и молодежи.",
      },
      {
        img: require("../../../../../assets/birthday9/images/spheres/projects/know-msk.png").default,
        title: "Культурное наследие: Узнай Москву",
        description:
          "Проект был посвящен повышению удобства пользования и развитию сервиса «Узнай Москву». Более 12 тысяч жителей столицы предложили свои идеи по улучшению интерфейса и обновлению разделов портала и одноименного мобильного приложения.",
      },
      {
        img: require("../../../../../assets/birthday9/images/spheres/projects/meus.png").default,
        title: "Московские электронные услуги и сервисы в сфере ЖКХ",
        description:
          "На проекте было собрано более 1000 предложений жителей по трём направлениям: повышение удобства использования московских электронных услуг и цифровых сервисов в сфере ЖКХ для физических и юридических лиц, развитие функционала единого личного кабинета.",
      },
      {
        img: require("../../../../../assets/birthday9/images/spheres/projects/meus-culture.png").default,
        title: "Московские электронные услуги и сервисы в сфере культуры",
        description:
          "В проекте приняли участие более 9 тысяч жителей, которые предложили свыше 800 идей по улучшению процесса предоставления московских электронных услуг и сервисов в сфере культуры. ",
      },
      {
        img: require("../../../../../assets/birthday9/images/spheres/projects/our-city-20.png").default,
        title: "Наш город 2.0",
        description:
          "Проект «Наш город 2.0» был перезапущен в 2017 году с целью развития портала для повышения удобства его использования жителями города. В ходе проекта москвичи предложили более 6000 идей по улучшению пользования порталом и приложением, обсудили новые проблемные темы, а также первыми смогли оценить новую бета-версию.",
      },
      {
        img: require("../../../../../assets/birthday9/images/spheres/projects/my-mosru.png").default,
        title: "Мой mos.ru",
        description:
          "Проект «Мой mos.ru» заложил фундамент по созданию комфортной и современной электронной инфраструктуры города в сфере государственных услуг. В ходе проекта было собрано более 1000 идей по четырем направлениям: новые онлайн-сервисы, улучшение портала, мобильное приложение и раздел «Афиша».",
      },
      {
        img: require("../../../../../assets/birthday9/images/spheres/projects/crowd-platform.png").default,
        title: "Новая платформа",
        description:
          "В проекте состоялось знакомство участников с новой краудсорсинговой платформой Правительства Москвы. Жители предлагали идеи по развитию платформы и обсуждали как сделать её более удобной и понятной для пользователей.",
      },
      {
        img: require("../../../../../assets/birthday9/images/spheres/projects/our-city.png").default,
        title: "Наш город",
        description:
          "Первый проект платформы «Город идей» был посвящен развитию портала «Наш город». В ходе проекта более 9 тысяч участников предложили новые идеи по улучшению функционала городского портала.",
      },
    ],
    people: [
      {
        img: require("../../../../../assets/birthday9/images/spheres/people/digital/1.png").default,
        name: "Александр Пищелко",
        position: "Руководитель ГКУ «Новые технологии управления»",
        description:
          "<strong>Александру</strong> нравится наблюдать за тем, как преображается город благодаря идеям москвичей. А теперь увидеть результаты общей работы можно на сайте платформы, ведь там собраны все реализованные идеи. Кстати, направлять туда фотографии тоже предложили участники!",
      },
      {
        img: require("../../../../../assets/birthday9/images/spheres/people/digital/2.png").default,
        name: "Евгения Костюченко",
        position: "Первый заместитель руководителя ГКУ «Новые технологии управления»",
        description:
          "<strong>Евгения</strong> часто пользуется приложением «Электронный дом», которое помогает общаться с соседями в домовых чатах и оперативно решать вопросы. Благодаря идеям жителей в приложении собрана база с ответами на вопросы по содержанию дома и реагированию в экстренных ситуациях.",
      },
      {
        img: require("../../../../../assets/birthday9/images/spheres/people/digital/3.png").default,
        name: "Анастасия Землянская",
        position: "Начальник Управления развития социальных технологий ГКУ «Новые технологии управления»",
        description:
          "<strong>Анастасии</strong> нравится исследовать Москву, выполняя городские задания. С каждым днем электронный проект «Город заданий» становится удобнее благодаря идеям жителей. Например, недавно на сайте проекта появилась возможность подключить напоминания о выполнении заданий.",
      },
    ],
    background:
      "data:image/svg+xml;base64,PHN2ZwogIHdpZHRoPSI2MDEiCiAgaGVpZ2h0PSI1ODIiCiAgdmlld0JveD0iMCAwIDYwMSA1ODIiCiAgZmlsbD0ibm9uZSIKICB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciCj4KICA8cGF0aAogICAgZD0iTTYwMC4yNSAyODMuMzJDNjAwLjI1IDQ0OC4yNzYgNDY1LjkzNSA1ODIgMzAwLjI1IDU4MkMxMzQuNTY1IDU4MiAwLjI1IDQ0OC4yNzYgMC4yNSAyODMuMzJDMC4yNSAxMTguMzYzIDI0MS43MDcgMCA0MDcuMzkzIDBDNTczLjA3OCAwIDYwMC4yNSAxMTguMzYzIDYwMC4yNSAyODMuMzJaIgogICAgZmlsbD0iI0YzQjlCOCIKICAvPgo8L3N2Zz4K",
    background2: require("../../../../../assets/birthday9/images/spheres/bg-city/1.svg").default,
  },
  {
    code: "improvement",
    categoryCodes: ["idea_1", ["ecology", "idea_37"], ["ecology", "idea_38"], ["movement", "idea_4"], "idea_5"],
    sphereTitle: "Благоустройство и экология",
    statistics: [
      {
        name: "Благоустройство",
        accepted: "> 2 445",
        realized: "> 730",
      },
      {
        name: "Экология",
        accepted: "> 410",
        realized: "> 370",
      },
    ],
    projects: [
      {
        img: require("../../../../../assets/birthday9/images/spheres/projects/comfortable-msk.png").default,
        title: "Комфортная Москва",
        description:
          "В ходе проекта «Комфортная Москва» участники предложили более 1 100 идей по благоустройству и оформлению сквера по улице Юности на востоке столицы, а также по созданию и освещению пешеходных зон на территории сквера.",
      },
      {
        img: require("../../../../../assets/birthday9/images/spheres/projects/nature-time.png").default,
        title: "Время природы",
        description:
          "На проекте «Время природы» 13 тысяч жителей подавали идеи, направленные на сохранение биоразнообразия и создание благоприятных экологических условий для природы в городе. Принятые идеи реализуются на особо охраняемых природных территориях и в парках столицы.",
      },
      {
        img: require("../../../../../assets/birthday9/images/spheres/projects/ecological-strategy-msk.png").default,
        title: "Экологическая стратегия Москвы",
        description:
          "Проект проводился с целью разработки мероприятий по улучшению экологической ситуации Москвы. Свыше 4700 человек предлагали свои идеи по нескольким актуальным направлениям создания благоприятных условий для охраны состояния окружающей среды в столице.",
      },
      {
        img: require("../../../../../assets/birthday9/images/spheres/projects/wild-animals.png").default,
        title: "Дикие животные в городе",
        description:
          "В проекте «Дикие животные в городе» приняли участие более 2500 жителей столицы, которые предлагали идеи по улучшению содержания диких животных в многоквартирных домах, общественных местах и контактных зоопарках столицы. На основании предложений был доработан проект закона «Об охране диких животных в городе Москве».",
      },
    ],
    people: [
      // {
      //   img: require("../../../../../assets/birthday9/images/spheres/people/improvement/1.png")
      //     .default,
      //   name: "Александр Адайкин",
      //   position:
      //     "Заместитель руководителя Департамента капитального ремонта города Москвы",
      //   description:
      //     "<strong>Александр</strong> любит заниматься спортом на свежем воздухе. Благодаря предложениям жителей на проекте «Комфортная Москва» в сквере на востоке столицы оборудовали площадки с навесами от дождя. Здесь можно не только укрыться от непогоды, но и посетить спортивные групповые занятия.",
      // },
      // {
      //   img: require("../../../../../assets/birthday9/images/spheres/people/improvement/2.png")
      //     .default,
      //   name: "Виталий Видяпин",
      //   position: "Руководитель ГПБУ «Мосприрода»",
      //   description:
      //     "<strong>Виталию</strong> нравятся спокойные прогулки по городским экотропам, особенно осенью, когда листва деревьев приобретает багровые и золотистые оттенки. Благодаря идее жителя на проекте «Время природы» в создании новых маршрутов экологических троп помогают волонтеры.",
      // },
      // {
      //   img: require("../../../../../assets/birthday9/images/spheres/people/improvement/3.png")
      //     .default,
      //   name: "Евгения Семутникова",
      //   position:
      //     "Заместитель руководителя Департамента природопользования и охраны окружающей среды города Москвы",
      //   description:
      //     "Летними вечерами <strong>Евгения</strong> любит гулять по паркам района Замоскворечье. Благодаря идеям жителей такие прогулки становятся не только полезными для здоровья, но и познавательными, ведь на маршруте можно встретить бархат амурский и боярышник, бересклет декоративный и можжевельник.",
      // },
      {
        img: require("../../../../../assets/birthday9/images/spheres/people/improvement/4.png").default,
        name: "Александра Пронина",
        position: "Начальник отдела по реализации беспрерывного сбора предложений граждан на платформе «Город идей»",
        description:
          "Летними вечерами <strong>Александра</strong> любит гулять по паркам района Замоскворечье. Благодаря идеям жителей такие прогулки становятся не только полезными для здоровья, но и познавательными, ведь на маршруте можно встретить бархат амурский и боярышник, бересклет декоративный и можжевельник.",
      },
      {
        img: require("../../../../../assets/birthday9/images/spheres/people/improvement/5.png").default,
        name: "Анастасия Алексашкина",
        position: "Заместитель начальника отдела развития проекта «Город идей»",
        description:
          "<strong>Анастасии</strong> нравятся спокойные прогулки по городским экотропам, особенно осенью, когда листва деревьев приобретает багровые и золотистые оттенки. Реализуя идеи жителя в проекте «Время природы», в создании новых маршрутов экологических троп помогают волонтеры.",
      },
      {
        img: require("../../../../../assets/birthday9/images/spheres/people/improvement/6.png").default,
        name: "Анастасия Сахарова",
        position: "Модератор отдела по реализации беспрерывного сбора предложений граждан на платформе «Город идей»",
        description:
          "<strong>Анастасия</strong> любит заниматься спортом на свежем воздухе. В результате предложенных жителями на проекте «Комфортная Москва» идей в сквере на востоке столицы оборудовали площадки с навесами от дождя. Здесь можно не только укрыться от непогоды, но и посетить спортивные групповые занятия.",
      },
    ],
    background:
      "data:image/svg+xml;base64,PHN2ZwogIHdpZHRoPSI2MDEiCiAgaGVpZ2h0PSI1OTQiCiAgdmlld0JveD0iMCAwIDYwMSA1OTQiCiAgZmlsbD0ibm9uZSIKICB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciCj4KICA8cGF0aAogICAgZD0iTTYwMC4yNSAyNDQuNUM2MDAuMjUgNDcwLjUgNDI1Ljc1IDU5My41IDMwMC4yNSA1OTMuNUM1MC4yNSA1OTMuNSAwLjI1IDM3OS43NzYgMC4yNSAyMTQuODJDMC4yNSA0OS44NjMxIDQ0LjYwNzEgLTI1LjY2NDYgMjU2LjI1IDguNTAwMDZDNDMyLjYwNyAzNi45Njg3IDYwMC4yNSA3OS41NDMzIDYwMC4yNSAyNDQuNVoiCiAgICBmaWxsPSIjRkZEODgxIgogIC8+Cjwvc3ZnPgo=",
    background2: require("../../../../../assets/birthday9/images/spheres/bg-city/2.svg").default,
  },
  {
    code: "parks",
    // categoryCodes: ["idea_3", "idea_9", "idea_10"],
    sphereTitle: "Парки и досуг",
    statistics: [
      {
        name: "Парки",
        accepted: "> 740",
        realized: "> 390",
      },
      { name: "Досуг", accepted: "> 345", realized: "> 90" },
    ],
    projects: [
      {
        img: require("../../../../../assets/birthday9/images/spheres/projects/zoopark.png").default,
        title: "Зоологический парк",
        description:
          "К проекту «Зоологический парк» присоединились более 32 тысяч участников, которые направили 1 176 предложений. Жители предлагали свои идеи по развитию образовательных программ Московского зоопарка, новых тематических фотовыставок, по развитию онлайн-сообществ и другим направлениям проекта.",
      },
      {
        img: require("../../../../../assets/birthday9/images/spheres/projects/msk-ice.png").default,
        title: "Московский лед",
        description:
          "Проект «Московский лед» стал одним из наиболее многочисленных — к нему присоединились более 30 тысяч жителей. Участники предлагали идеи по развитию катков в столичных парках и на территории ВДНХ, а также рассказали о программах и мероприятиях, которые стоит проводить на льду.",
      },
      {
        img: require("../../../../../assets/birthday9/images/spheres/projects/my-park.png").default,
        title: "Мой парк",
        description:
          "Проект «Мой парк» был посвящен развитию и улучшению столичных парков. В нем приняли участие более 20 тысяч человек, предложивших идеи по четырем направлениям: события и активности в парках, новости о парках — оффлайн- и онлайн-сервисы, красота парков.",
      },
      {
        img: require("../../../../../assets/birthday9/images/spheres/projects/vdnh.png").default,
        title: "ВДНХ",
        description:
          "ВДНХ — одно из самых любимых мест отдыха москвичей. В ходе проекта, посвященного главной выставке страны, участники направили более 1 600 предложений по трем направлениям. Жители предлагали идеи по созданию новых маршрутов на ВДНХ, развитию спортивных сообществ и информационных каналов.",
      },
      {
        img: require("../../../../../assets/birthday9/images/spheres/projects/tourist-msk.png").default,
        title: "Туристическая Москва",
        description:
          "Проект «Туристическая Москва» был посвящен развитию туристического портала discovermoscow.com и цифрового помощника в путешествиях по России RUSSPASS. В ходе проекта было подано более 700 идей для развития столичных туристических сервисов.",
      },
    ],
    people: [
      // {
      //   img: require("../../../../../assets/birthday9/images/spheres/people/parks/1.png")
      //     .default,
      //   name: "Сергей Перов",
      //   position:
      //     "Первый заместитель руководителя Департамента культуры города Москвы",
      //   description:
      //     "<strong>Сергей</strong> увлекается кинематографом и режиссёрским искусством. И очень рад, что благодаря идее жителя на проекте «Мой парк» в летнее время каждый желающий уже несколько лет подряд может насладиться просмотром любимых фильмов под открытым небом.",
      // },
      // {
      //   img: require("../../../../../assets/birthday9/images/spheres/people/parks/2.png")
      //     .default,
      //   name: "Виктория Хабалонова",
      //   position: "Директор ГКУ «Объединенная дирекция «Мосгорпарк»",
      //   description:
      //     "<strong>Виктории</strong> нравится зимой проводить время на столичных катках. Благодаря идее жителя на проекте «Московский лед» на ледовых пространствах столицы во время сеансов включают композиции знаменитых композиторов и любимые всеми песни про зиму.",
      // },
      // {
      //   img: require("../../../../../assets/birthday9/images/spheres/people/parks/3.png")
      //     .default,
      //   name: "Светлана Акулова",
      //   position: "Генеральный директор ГАУ «Московский зоопарк»",
      //   description:
      //     "<strong>Светлана</strong> любит встречаться с друзьями на открытом воздухе и устраивать пикники в теплое время года. Благодаря идеям жителей в городе стали благоустраиваться специальные территории для летнего досуга.",
      // },
      {
        img: require("../../../../../assets/birthday9/images/spheres/people/parks/4.png").default,
        name: "Тахмина Сафарова",
        position: "Начальник отдела организации и проведения краудсорсинг-проектов",
        description:
          "<strong>Тахмина</strong> увлекается кинематографом и режиссёрским искусством. И очень рада, что благодаря идее жителя на проекте «Мой парк» в летнее время каждый желающий уже несколько лет подряд может насладиться просмотром любимых фильмов под открытым небом.",
      },
      {
        img: require("../../../../../assets/birthday9/images/spheres/people/parks/5.png").default,
        name: "Анастасия Кушнир",
        position: "Старший менеджер сообществ отдела организации и проведения краудсорсинг-проектов",
        description:
          "<strong>Анастасии</strong> нравится зимой проводить время на столичных катках. Благодаря идее жителя на проекте «Московский лед» на ледовых пространствах столицы во время сеансов включают композиции знаменитых композиторов и любимые всеми песни про зиму.",
      },
      {
        img: require("../../../../../assets/birthday9/images/spheres/people/parks/6.png").default,
        name: "Карина Заргарьянц",
        position:
          "Контент-редактор отдела по реализации беспрерывного сбора предложений граждан на платформе «Город идей»",
        description:
          "<strong>Карина</strong> любит встречаться с друзьями на открытом воздухе и устраивать пикники в теплое время года. Благодаря идеям жителей в городе стали благоустраиваться специальные территории для летнего досуга.",
      },
    ],
    background:
      "data:image/svg+xml;base64,PHN2ZwogIHdpZHRoPSI2MDEiCiAgaGVpZ2h0PSI1ODMiCiAgdmlld0JveD0iMCAwIDYwMSA1ODMiCiAgZmlsbD0ibm9uZSIKICB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciCj4KICA8cGF0aAogICAgZD0iTTAuMjQ5OTc1IDI5OS4xOEMwLjI0OTk2MSAxMzQuMjI0IDEzNC41NjUgMC41MDAwNDEgMzAwLjI1IDAuNTAwMDI2QzQ2NS45MzUgMC41MDAwMTIgNjAwLjI1IDEzNC4yMjQgNjAwLjI1IDI5OS4xOEM2MDAuMjUgNDY0LjEzNyAzNTguNzkzIDU4Mi41IDE5My4xMDcgNTgyLjVDMjcuNDIxNyA1ODIuNSAwLjI0OTk5IDQ2NC4xMzcgMC4yNDk5NzUgMjk5LjE4WiIKICAgIGZpbGw9IiM5NkNBRjIiCiAgLz4KPC9zdmc+Cg==",
    background2: require("../../../../../assets/birthday9/images/spheres/bg-city/3.svg").default,
  },
  {
    code: "culture",
    categoryCodes: ["idea_7", "idea_8", "idea_28", "idea_27"],
    sphereTitle: "Культура и спорт",
    statistics: [
      { name: "Культура", accepted: "> 675", realized: "> 535" },
      { name: "Спорт", accepted: "> 115", realized: "> 90" },
    ],
    projects: [
      {
        img: require("../../../../../assets/birthday9/images/spheres/projects/my-sport-district.png").default,
        title: "Мой спортивный район",
        description:
          "Проект «Мой спортивный район» был посвящен развитию одноименного проекта Москомспорта. Жители столицы предлагали идеи по развитию дворовых тренировок для взрослых и детей, развитию сайта и телеграм-канала «Мой спортивный район», обсуждали организацию спортивных занятий в разные сезоны.",
      },
      {
        img: require("../../../../../assets/birthday9/images/spheres/projects/culture-is-near.png").default,
        title: "Культура рядом",
        description:
          "На проекте «Культура рядом» жители предлагали идеи по развитию районных коммуникационных центров, организуемых на базе существующих культурных центров и библиотек. В проекте приняли участие более 16 тысяч человек, которые предложили свыше 700 идей по развитию сервисов и стратегии их популяризации, а также по созданию кофейной сети.",
      },
      {
        img: require("../../../../../assets/birthday9/images/spheres/projects/sport-city.png").default,
        title: "Город спорта",
        description:
          "В ходе проекта «Город спорта» участники делились мнением о том, как должна развиваться и улучшаться спортивная жизнь в столице. На проекте были предложены идеи по проведению спортивных и развлекательных мероприятий, а также по организации спортивных секций в разных районах города.",
      },
      {
        img: require("../../../../../assets/birthday9/images/spheres/projects/gmst.png").default,
        title: "Городские мастерские семейного творчества",
        description:
          "В проекте «Городские мастерские семейного творчества» приняли участие более 13 тысяч жителей. Участники проекта предложили идеи развития городских мастерских по четырем направлениям: новые активности, участие наставников, развитие социальных сетей, популяризация городских мастерских. ",
      },
      {
        img: require("../../../../../assets/birthday9/images/spheres/projects/online-culture.png").default,
        title: "Культура онлайн",
        description:
          "Проект «Культура онлайн» был направлен на улучшение и развитие онлайн-мероприятий и активностей в сфере культуры города Москвы на портале mos.ru в соответствующем разделе. Участники предложили новые сервисы и услуги в культуре, а также поделились идеями о том, какие трансляции следует проводить для разных поколений жителей столицы.",
      },
      {
        img: require("../../../../../assets/birthday9/images/spheres/projects/msk-museums.png").default,
        title: "Музеи Москвы",
        description:
          "Проект «Музеи Москвы» был направлен на улучшение и развитие столичных музеев. В нём приняли участие более 1500 жителей. В ходе проекта было собрано 1400 идей по 6 направлениям: клиентоориентированность, услуги и сервисы, комфортность посещения, новые технологии, музей в городской застройке и дополнительные мероприятия.",
      },
      {
        img: require("../../../../../assets/birthday9/images/spheres/projects/culture-centres-future.png").default,
        title: "Культурные центры. Будущее",
        description:
          "Проект «Культурные центры. Будущее» был направлен на развитие и популяризацию культурных центров столицы. Жители Москвы предложили новые программы и рассказали, какие услуги и сервисы необходимо реализовать в культурных центрах.",
      },
      {
        img: require("../../../../../assets/birthday9/images/spheres/projects/msk-libraries.png").default,
        title: "Московские библиотеки",
        description:
          "Целью проекта «Московские библиотеки» была разработка плана развития библиотек и формирование единого стандарта их работы. В ходе проекта участники отработали более 20 тысяч человеко-часов и предложили порядка 5,5 тысяч идей о том, как сделать столичные библиотеки комфортными и современными.",
      },
    ],
    people: [
      // {
      //   img: require("../../../../../assets/birthday9/images/spheres/people/culture/1.png")
      //     .default,
      //   name: "Михаил Арутюнов",
      //   position: "Заместитель руководителя Департамента спорта города Москвы",
      //   description:
      //     "<strong>Михаил</strong> увлекается киберспортом, который стал очень популярным в столице. Благодаря идеям участников проекта «Город спорта» для любителей киберспорта проводятся специальные мероприятия и командные соревнования на основе видеоигр.",
      // },
      // {
      //   img: require("../../../../../assets/birthday9/images/spheres/people/culture/2.png")
      //     .default,
      //   name: "Владимир Владимиров",
      //   position: "Директор ГБУ «Мосгорспорт»",
      //   description:
      //     "<strong>Владимир</strong> на досуге часто занимается спортом. Благодаря предложениям горожан на сайте проекта «Мой спортивный район» в скором времени появится полезная информация о том, как разминаться перед тренировкой и как избежать травм, а также планы тренировок на уличных тренажерах.",
      // },
      // {
      //   img: require("../../../../../assets/birthday9/images/spheres/people/culture/3.png")
      //     .default,
      //   name: "Тимур Вахитов",
      //   position: "Генеральный директор ГБУК «Мосразвитие»",
      //   description:
      //     "<strong>Тимур</strong> любит брать кофе в стаканчике «с собой», когда ходит в культурные центры и библиотечные пространства. Здорово, что это стало возможным благодаря идее жителя на проекте «Культура рядом».",
      // },
      {
        img: require("../../../../../assets/birthday9/images/spheres/people/culture/4.png").default,
        name: "Ксения Хомутовская",
        nick: "homutovskaya",
        position: "Заместитель начальника отдела организации и проведения краудсорсинг-проектов",
        description:
          "<strong>Ксения</strong> любит брать кофе в стаканчике «с собой», когда ходит в культурные центры и библиотечные пространства. Здорово, что это стало возможным благодаря идее жителя на проекте «Культура рядом».",
      },
      {
        img: require("../../../../../assets/birthday9/images/spheres/people/culture/5.png").default,
        name: "Екатерина Акишкина",
        position: "Контент-редактор отдела организации и проведения краудсорсинг-проектов",
        description:
          "<strong>Екатерина</strong> на досуге часто занимается спортом. Благодаря предложениям горожан на сайте проекта «Мой спортивный район» в скором времени появится полезная информация о том, как разминаться перед тренировкой и как избежать травм, а также планы тренировок на уличных тренажерах.",
      },
      {
        img: require("../../../../../assets/birthday9/images/spheres/people/culture/6.png").default,
        name: "Станислав Рябчиков",
        position: "Модератор отдела по реализации беспрерывного сбора предложений граждан на платформе «Город идей»",
        description:
          "<strong>Станислав</strong> увлекается киберспортом, который стал очень популярным в столице. В результате высказанных участниками проекта «Город спорта» идей для любителей киберспорта проводятся специальные мероприятия и командные соревнования на основе видеоигр.",
      },
    ],
    background:
      "data:image/svg+xml;base64,PHN2ZwogIHdpZHRoPSI2MDEiCiAgaGVpZ2h0PSI1OTQiCiAgdmlld0JveD0iMCAwIDYwMSA1OTQiCiAgZmlsbD0ibm9uZSIKICB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciCj4KICA8cGF0aAogICAgZD0iTTAuMjUwMDIxIDM0OS40NDJDMC4yNTAwNDEgMTIzLjQ0MiAxMTYuMjUgMC40NDE1NDcgMzAwLjI1IDAuNDQxNTYzQzQ2NS45MzUgMC40NDE1NzggNjAwLjI1IDIxNC4xNjUgNjAwLjI1IDM3OS4xMjJDNjAwLjI1IDU0NC4wNzkgNTU1Ljg5MyA2MTkuNjA2IDM0NC4yNSA1ODUuNDQyQzE2Ny44OTMgNTU2Ljk3MyAwLjI1MDAwNyA1MTQuMzk4IDAuMjUwMDIxIDM0OS40NDJaIgogICAgZmlsbD0iI0ZGRDg4MSIKICAvPgo8L3N2Zz4K",
    background2: require("../../../../../assets/birthday9/images/spheres/bg-city/4.svg").default,
  },
  {
    code: "dpir",
    // categoryCodes: ["idea_29", "idea_30", "idea_31", "idea_32"],
    statistics: [
      {
        accepted: "> 255",
        realized: "> 210",
      },
    ],
    projects: [
      {
        img: require("../../../../../assets/birthday9/images/spheres/projects/innovative-claster.png").default,
        title: "Инновационный кластер",
        description:
          "Проект был посвящен развитию и популяризации деятельности Московского инновационного кластера. В нем приняли участие более 17 тысяч человек, которые предложили свои идеи по двум направлениям: новые программы для физических лиц и каналы продвижения.",
      },
      {
        img: require("../../../../../assets/birthday9/images/spheres/projects/my-technocity.png").default,
        title: "Мой Техноград",
        description:
          "Проект «Мой Техноград» был посвящен развитию одноименного информационно-образовательного комплекса. Более 12 тысяч жителей рассказали, как необходимо организовать мастерские по новым направлениям образовательных программ для повышения востребованности обучения профессиям.",
      },
      {
        img: require("../../../../../assets/birthday9/images/spheres/projects/skills-of-future.png").default,
        title: "Навыки будущего",
        description:
          "Целью проекта «Навыки будущего» было развитие программ профориентации для жителей столицы. В ходе проекта более 15 тысяч участников предлагали идеи по шести направлениям, включая работу и поддержку детских технопарков, информирование жителей о предпринимательстве и обучение на бизнес-уикендах.",
      },
    ],
    people: [
      // {
      //   img: require("../../../../../assets/birthday9/images/spheres/people/dpir/1.png")
      //     .default,
      //   name: "Кристина Кострома",
      //   position:
      //     "Первый заместитель руководителя Департамента предпринимательства и инновационного развития города Москвы",
      //   description:
      //     "<strong>Кристина</strong> вдохновляется, когда читает истории успеха творческих активных личностей и новости о проектах «Московского инновационного кластера». Благодаря идее жителя на портале mos.ru публикуется много интересной информации на тему столичного предпринимательства.",
      // },
      // {
      //   img: require("../../../../../assets/birthday9/images/spheres/people/dpir/2.png")
      //     .default,
      //   name: "Максим Киселев",
      //   position: "Генеральный директор АНО «Развитие человеческого капитала»",
      //   description:
      //     "<strong>Максиму</strong> интересно следить за трендами в бизнесе и молодежными инновациями. Благодаря предложению жителя на проекте «Инновационный кластер» в столице запускаются системы сопровождения студентов-новаторов и их разработок.",
      // },
      // {
      //   img: require("../../../../../assets/birthday9/images/spheres/people/dpir/3.png")
      //     .default,
      //   name: "Олеся Беленькая",
      //   position:
      //     "Заместитель руководителя Департамента предпринимательства и инновационного развития города Москвы",
      //   description:
      //     "<strong>Олесе</strong> нравится посещать различные мастер-классы и встречи для специалистов по проектированию «умных городов», которые организованы благодаря идеям участников проекта «Мой Техноград».",
      // },
      {
        img: require("../../../../../assets/birthday9/images/spheres/people/dpir/4.png").default,
        name: "Наталья Сарычева",
        position: "Начальник отдела развития проекта «Город идей»",
        description:
          "<strong>Наталья</strong> вдохновляется, когда читает истории успеха творческих активных личностей и новости о проектах «Московского инновационного кластера». Благодаря идее жителя на портале mos.ru публикуется много интересной информации на тему столичного предпринимательства.",
      },
      {
        img: require("../../../../../assets/birthday9/images/spheres/people/dpir/5.png").default,
        name: "Кирилл Смирнов",
        position: "Методолог отдела развития проекта «Город идей»",
        description:
          "<strong>Кириллу</strong> интересно следить за трендами в бизнесе и молодежными инновациями. Благодаря предложению жителя на проекте «Инновационный кластер» в столице запускаются системы сопровождения студентов-новаторов и их разработок.",
      },
      {
        img: require("../../../../../assets/birthday9/images/spheres/people/dpir/6.png").default,
        name: "Лилия Миронова",
        position:
          "Ведущий контент-редактор отдела по реализации беспрерывного сбора предложений граждан на платформе «Город идей»",
        description:
          "<strong>Лилии</strong> нравится посещать различные мастер-классы и встречи для специалистов по проектированию «умных городов», которые организованы благодаря идеям участников проекта «Мой Техноград».",
      },
    ],
    background:
      "data:image/svg+xml;base64,PHN2ZwogIHdpZHRoPSI2MDAiCiAgaGVpZ2h0PSI1ODMiCiAgdmlld0JveD0iMCAwIDYwMCA1ODMiCiAgZmlsbD0ibm9uZSIKICB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciCj4KICA8cGF0aAogICAgZD0iTTAgMjgzLjgxNUMwIDUzOC40OTUgMTM0LjMxNSA1ODIuNDk1IDMwMCA1ODIuNDk1QzQ2NS42ODUgNTgyLjQ5NSA2MDAgNDQ4Ljc3MSA2MDAgMjgzLjgxNUM2MDAgMTE4Ljg1OCAzNTguNTQzIDAuNDk1MTE3IDE5Mi44NTcgMC40OTUxMTdDMjcuMTcxOCAwLjQ5NTExNyAwIDExOC44NTggMCAyODMuODE1WiIKICAgIGZpbGw9IiM4RUQ3RTUiCiAgLz4KPC9zdmc+Cg==",
    background2: require("../../../../../assets/birthday9/images/spheres/bg-city/5.svg").default,
  },
  {
    code: "transport",
    categoryCodes: [
      ["movement", "idea_2"],
      "idea_16",
      ["bicycle", "idea_22"],
      "idea_21",
      "idea_15",
      "idea_17",
      ["movement", "idea_11"],
      ["bicycle", "idea_23"],
      ["bicycle", "idea_24"],
      ["railway", "idea_25"],
      ["railway", "idea_26"],
    ],
    statistics: [
      {
        accepted: "> 600",
        realized: "> 280",
      },
    ],
    projects: [
      {
        img: require("../../../../../assets/birthday9/images/spheres/projects/mcd.png").default,
        title: "Московские центральные диаметры",
        description:
          "Проект был направлен на развитие Московских центральных диаметров. Более 17 тысяч человек предлагали идеи по трём направлениям: комфорт пребывания в поездах, наполнение станций и информирование / навигация в поездах и на станциях. Свыше 75% предложений граждан уже реализованы городом.",
      },
      {
        img: require("../../../../../assets/birthday9/images/spheres/projects/our-tracks.png").default,
        title: "Наши маршруты",
        description:
          "В рамках проекта «Наши маршруты» было собрано более 1,5 тысяч предложений жителей по улучшению работы общественного наземного транспорта столицы. Благодаря идеям горожан в Москве появились более 10 новых автобусных маршрутов и выделенные полосы для комфортного передвижения по городу.",
      },
    ],
    people: [
      {
        img: require("../../../../../assets/birthday9/images/spheres/people/transport/1.png").default,
        name: "Юлия Игнатьева",
        position:
          "Заместитель начальника отдела по реализации беспрерывного сбора предложений граждан на платформе «Город идей»",
        description:
          "<strong>Юлия</strong> любит кататься на велосипеде весной, когда город снова наполняется яркими красками и цветочными ароматами. Здорово, что велодорожек становится всё больше и скоро можно будет проехать на велосипеде через всю Москву.",
      },
      {
        img: require("../../../../../assets/birthday9/images/spheres/people/transport/2.png").default,
        name: "Анастасия Растащенова",
        position: "Ведущий контент-редактор отдела организации и проведения краудсорсинг-проектов",
        description:
          "<strong>Анастасия</strong> добирается до работы на автобусе, и, когда она по пути читает новости, её телефон нередко разряжается. Благодаря предложениям жителей на остановках общественного транспорта появляются порты для зарядки мобильных устройств.",
      },
      {
        img: require("../../../../../assets/birthday9/images/spheres/people/transport/3.png").default,
        name: "Мария Климова",
        position: "Модератор отдела по реализации беспрерывного сбора предложений граждан на платформе «Город идей»",
        description:
          "В теплое время года <strong>Мария</strong> часто отправляется за город, чтобы провести время на природе. Поездки стали удобнее, ведь благодаря идеям жителей на каждой станции МЦД появились автоматы для продажи билетов, принимающие банковские карты.",
      },
    ],
    background:
      "data:image/svg+xml;base64,PHN2ZwogIHdpZHRoPSI2MDEiCiAgaGVpZ2h0PSI1OTQiCiAgdmlld0JveD0iMCAwIDYwMSA1OTQiCiAgZmlsbD0ibm9uZSIKICB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciCj4KICA8cGF0aAogICAgZD0iTTAuMjUwMDIxIDM0OS45NDJDMC4yNTAwNDEgMTIzLjk0MiAxMTYuMjUgMC45NDE1NDcgMzAwLjI1IDAuOTQxNTYzQzQ2NS45MzUgMC45NDE1NzggNjAwLjI1IDIxNC42NjUgNjAwLjI1IDM3OS42MjJDNjAwLjI1IDU0NC41NzkgNTU1Ljg5MyA2MjAuMTA2IDM0NC4yNSA1ODUuOTQyQzE2Ny44OTMgNTU3LjQ3MyAwLjI1MDAwNyA1MTQuODk4IDAuMjUwMDIxIDM0OS45NDJaIgogICAgZmlsbD0iI0M3QjdGQiIKICAvPgo8L3N2Zz4K",
    background2: require("../../../../../assets/birthday9/images/spheres/bg-city/6.svg").default,
  },
];

export default spheresData;
