import React from "react";

import "./PhotoCard.scss";

interface Props {
  photo: any;
  onClick: () => void;
}

class PhotoCard extends React.Component<Props, any> {
  constructor(props) {
    super(props);

    this.state = {
      isModalOpen: false,
    };
  }

  onClick = () => {
    this.setState({
      isModalOpen: true,
    });
  };

  renderCard = () => {
    return (
      <div className="photo-card" onClick={this.props.onClick}>
        <div className="photo-card__image">
          {this.props.photo.logoId && (
            <img
              src={"/uploads/get/" + this.props.photo.logoId}
              alt={this.props.photo.logoId}
            />
          )}
        </div>

        <div className="photo-card__body">
          <p className="photo-card__body-heading">Идея:</p>
          <div className="photo-card__body-description">
            {this.props.photo.title}
          </div>
        </div>
      </div>
    );
  };

  render() {
    return <>{this.renderCard()}</>;
  }
}

export default PhotoCard;
