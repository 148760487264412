import { forwardRef } from "react";
import { useDispatch } from "react-redux";
import GenerationForm, { GenerationDraft, GenerationFormType } from "../GenerationForm/GenerationForm";
import { showSuccessToast } from "../../../../../../store/actions/ToastActions";
import { LocalStorageKeys, LocalStorageService } from "../../../../../../services/LocalStorage.service";
import { GroupDirectionStage } from "../../../../../../types/GroupDirection.interface";

export interface GenerationFormContainerProps {
  stage: GroupDirectionStage;
  showFormDescription?: boolean;
  showTaskDescription?: boolean;
  showFullScreenButton?: boolean;
  showDraftButton?: boolean;
  showRejectReason?: boolean;
  draftAutoSubmitDelay?: number;
  onFormClose: () => void;
  onSubmit?: () => void;
  onSubmitDraft?: () => void;
  onLocalDraftId?: (draftId: GenerationDraft["draftId"]) => void;
}

const GenerationFormContainer = forwardRef<GenerationFormType, GenerationFormContainerProps>(
  (
    {
      stage,
      showTaskDescription,
      showFormDescription,
      showFullScreenButton,
      showDraftButton,
      showRejectReason,
      draftAutoSubmitDelay,
      onFormClose,
      onSubmit,
      onSubmitDraft,
      onLocalDraftId,
    },
    ref
  ) => {
    const dispatch = useDispatch();

    const handleOnSubmitSuccess = (): void => {
      dispatch(showSuccessToast("Ваша идея отправлена"));
      onSubmit?.();
    };

    const handleOnSubmitDraftSuccess = (): void => {
      dispatch(showSuccessToast("Черновик успешно сохранен."));
      onSubmitDraft?.();
    };

    const handleOnFormClose = () => {
      onFormClose?.();
      LocalStorageService.removeData(LocalStorageKeys.GenerationDraft);
    };

    return (
      <>
        <GenerationForm
          ref={ref}
          stage={stage}
          showFormDescription={showFormDescription}
          showTaskDescription={showTaskDescription}
          showFullScreenButton={showFullScreenButton}
          showDraftButton={showDraftButton}
          showRejectReason={showRejectReason}
          draftAutoSubmitDelay={draftAutoSubmitDelay}
          onSubmitSuccess={handleOnSubmitSuccess}
          onSubmitDraftSuccess={handleOnSubmitDraftSuccess}
          onClose={handleOnFormClose}
          onLocalDraftId={onLocalDraftId}
        />
      </>
    );
  }
);

export default GenerationFormContainer;
