import React, { useCallback, useEffect, useState } from "react";
import "./Game.scss";
import { cards, randomSort } from "./Game.helper";
import Timer from "../../Timer/Timer";

interface GameProps {
  onWin?: () => void;
  onLoose?: () => void;
}

const Game = ({ onWin, onLoose }: GameProps) => {
  const [grid, setGrid] = useState([]);
  const [activesIndex, setActivesIndex] = useState([]);
  const [selected, setSelected] = useState([]);

  const setActive = (index) => {
    const [first, second] = selected;
    if ((first !== undefined && second !== undefined) || activesIndex.includes(index)) return;
    if (first === undefined || second === undefined) {
      setSelected((current) => [...current, index]);
      setActivesIndex((current) => [...current, index]);
    }
    if (first === undefined) return;
    if (grid[first].id !== grid[index].id) {
      setTimeout(() => {
        setSelected([]);
        setActivesIndex((current) => {
          const newValue = [...current];
          newValue.splice(activesIndex.indexOf(first), 1);
          newValue.splice(activesIndex.indexOf(index), 1);
          return newValue;
        });
      }, 500);
    } else {
      setSelected([]);
    }
  };

  const onTimeFinish = useCallback(() => onLoose(), []);

  useEffect(() => {
    if (activesIndex.length && activesIndex.length === grid.length) {
      onWin();
    }
  }, [activesIndex]);

  useEffect(() => {
    const randomCards = [...cards, ...cards].sort(randomSort);
    setGrid(randomCards);
  }, []);

  return (
    <div className={"game"}>
      <div className={"game__timer"}>
        <Timer onTimeExpire={onTimeFinish} initialMinutes={2} />
      </div>
      <div className={"game__grid"}>
        {grid.map((el, index) => (
          <button
            key={index}
            className={`game__card ${activesIndex.includes(index) ? "game__card--active" : ""}`}
            onClick={() => setActive(index)}
          >
            <div className={"game__inner"}>
              <div className={"game__front"} />
              <div className={"game__back"}>
                <img className={"game__img"} src={el.src} alt={"Игровое изображение"} />
              </div>
            </div>
          </button>
        ))}
      </div>
    </div>
  );
};

export default Game;
