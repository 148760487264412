import { Category, ProposeState } from "../../types/Propose.interface";
import {
  PROPOSE_CATEGORIES,
} from "../actions/ProposeActions";

const initialState = {
  categories: null,
  statistics: null,
};

export function proposeReducer(state: ProposeState = initialState, action) {
  switch (action.type) {
    case PROPOSE_CATEGORIES:
      return {
        ...state,
        categories: action.payload,
      };

    default:
      return state;
  }
}
