import React from "react";
import Game from "./Game/Game";
import "./MazeGame.scss";
import { MazeGameProps } from "./MazeGame.types";
import GameWrapper from "../Wrappers/GameWrapper/GameWrapper";
import { MosProjects } from "../../../../../types/Projects.interface";
import playerSvg from "../../../../../assets/newyear2023/games/maze/penguin.svg";
import finishSvg from "../../../../../assets/newyear2023/games/maze/gift.png";
import snowSvg from "../../../../../assets/newyear2023/games/maze/snow.svg";
import backSvg from "../../../../../assets/newyear2023/games/maze/back pic.svg";
import useImagePreload from "../../hooks/useImagePreload";
import { useMediaQuery } from "react-responsive";
import { mediumTabletWidth } from "../../../../../utils/constants/widthConstants";
import { gameTitle, startText } from "./MazeGame.constants";

const imageSources = [playerSvg, finishSvg, snowSvg, backSvg];

const MazeGame = ({ onWin }: MazeGameProps) => {
  const isLoad = useImagePreload(imageSources);
  const isTablet = useMediaQuery({ query: `(max-width: ${mediumTabletWidth}px)` });

  return (
    <GameWrapper className="maze" gameTitle={gameTitle} startText={startText} onWin={onWin} project={MosProjects.NG}>
      {isLoad && <Game gameWidth={isTablet ? 340 : 380} gameHeight={isTablet ? 290 : 320} />}
    </GameWrapper>
  );
};

export default MazeGame;
