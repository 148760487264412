import React, { Children } from "react";
import { ElementObserverProps } from "./ElementObserver.interface";

export class ElementObserver extends React.Component<
  ElementObserverProps,
  null
> {
  elementsRef;
  observer;

  constructor(props) {
    super(props);

    this.elementsRef = React.createRef();
    this.elementsRef.current = {};
  }

  componentDidUpdate(prevProps) {
    const str1 = prevProps.children.map((c) => c.props.id).join("");
    const str2 = (this.props.children as any).map((c) => c.props.id).join("");

    if (str1 !== str2) {
      this.observer = null;
      this.observe();
    }
  }

  componentDidMount() {
    this.observe();
  }

  observe() {
    const callback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const id = entry.target.dataset?.id;
          const jsxNode: JSX.Element = Array.from(
            this.props.children as Array<JSX.Element>
          ).find((c: any) => c.props.data.id === id);

          setTimeout(() => {
            this.props.onIntersect(jsxNode.props.data);
          }, this.props.delayBeforeNotify || 0);

          this.props.autoUnobserve && this.observer.unobserve(entry.target);
        }
      });
    };
    this.observer = new IntersectionObserver(callback, {
      threshold: this.props.threshold,
      rootMargin: this.props.rootMargin,
    });

    Object.entries(this.elementsRef.current).map(([id, element]) => {
      element && this.observer.observe(element as HTMLElement);
    });
  }

  renderItems() {
    return Children.map(this.props.children, (item: any, index) => {
      const innerProps = {
        key: index,
        ref: (el) => (this.elementsRef.current[item.props.data.id] = el),
      };

      return (
        <div
          className="element-observer__item"
          data-id={item.props.data.id}
          {...innerProps}
        >
          {item}
        </div>
      );
    });
  }

  render() {
    return <div className="element-observer">{this.renderItems()}</div>;
  }
}
