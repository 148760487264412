import React from "react";
import "./Barriers.scss";

interface BarrierProps {
  size: number;
  hasLeftBorder: boolean;
  hasRightBorder: boolean;
  hasTopBorder: boolean;
  hasBottomBorder: boolean;
  transparent: boolean;
}

const Barrier = ({ size, hasLeftBorder, hasRightBorder, hasTopBorder, hasBottomBorder, transparent }: BarrierProps) => {
  return (
    <div
      className={"barrier"}
      style={{
        width: `${size}px`,
        height: `${size}px`,
        borderLeft: hasLeftBorder && !transparent ? "1.5px solid #BBCAD2" : "none",
        borderRight: hasRightBorder && !transparent ? "1.5px solid #BBCAD2" : "none",
        borderTop: hasTopBorder && !transparent ? "1.5px solid #BBCAD2" : "none",
        borderBottom: hasBottomBorder && !transparent ? "1.5px solid #BBCAD2" : "none",
        backgroundColor: transparent ? "transparent" : "#fff",
      }}
    />
  );
};

export default Barrier;
