import React from "react";

import MetaDecorator from "../../presentational/MetaDecorator/MetaDecorator";
import PageTitle from "../../presentational/PageTitle/PageTitle";
import FadeIn from "../../presentational/FadeIn/FadeIn";
import signature from "../../../assets/signature.png";

import "./MayorAppealPage.scss";

const MayorAppealPage = (props) => {
  const renderImage = () => {
    return <div className="appeal__container-image"></div>;
  };

  const renderText = () => {
    return (
      <div className="appeal__container-content">
        <div className="appeal__container-content-title">
          Уважаемые москвичи!
        </div>

        <div className="appeal__container-content-text">
          <p>
            Совместное обсуждение важных для города вопросов и поиск оптимальных
            решений дают отличные результаты.
          </p>
          <p>
            Как улучшить парки столицы? Что изменить в работе московских
            центральных диаметров? В каких направлениях развивать московские
            электронные проекты?
          </p>
          <p>
            Предложения по этим вопросам уже внесли москвичи – пользователи
            платформы «Город идей» прошлых лет.
          </p>
          <p>
            Впереди – еще много интересных и актуальных проектов, обсуждение
            которых потребует ваших знаний, умения по-новому посмотреть на
            привычные вещи и, конечно, искренней заинтересованности в делах
            любимого города.
          </p>
          <p>
            Предложить свои идеи могут все жители Москвы. Каждый будет услышан,
            а лучшие идеи – реализованы!
          </p>
          <p>
            Участвуйте в развитии города на платформе Правительства Москвы
            «Город идей» вместе с нами.
          </p>
        </div>

        <div className="appeal__container-content-signature">
          С.Собянин, Мэр Москвы
        </div>
      </div>
    );
  };

  return (
    <FadeIn>
      <section className="appeal">
        <MetaDecorator
          title="Платформа Правительства Москвы «Город идей»"
          description="Уважаемые москвичи! Совместное обсуждение проблем и поиск оптимальных решений дают отличные результаты."
        />
        <PageTitle text={"Обращение мэра Москвы С.С.Собянина"} />

        <div className="appeal__container">
          {renderImage()}
          {renderText()}
        </div>
      </section>
    </FadeIn>
  );
};

export default MayorAppealPage;
