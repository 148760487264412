import React, { ReactElement, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay } from "swiper";

import Slide from "../../../presentational/Sliders/TrioSlider/Slide/Slide";
import TrioSlider from "../../../presentational/Sliders/TrioSlider/TrioSlider";
import {
  formatProjectDates,
  calculateRemainedTime,
  projectIsAnnouncement,
  projectIsCurrent,
  projectIsFinished,
  numberWithSpaces,
  userIsProjectParticipant,
  isTeamMember,
  trimSpaces,
  stripHTML,
  getStringDate,
} from "../../../../utils/index";
import Button from "../../../presentational/Controls/Button/Button";
import { hideInfoModal, showInfoModal } from "../../../../store/actions/LayoutActions";
import { Banner } from "../../../../types/Banner.interface";
import Platform from "../../../../services/platform";
import BannerDirections from "../../../presentational/BannerDirections/BannerDirections";
import "./BannersSection.scss";
import { getSittingByKey } from "../../../../store/actions/SittingsActions";
import { getUserProjectsState } from "../../../../store/actions/UserActions";
import { UserActionTypeEnum } from "../../../../types/User.interface";
import { AppContext } from "../../../Root";
import { ProjectTypeEnum } from "../../../../types/Projects.interface";
import { getParticipationLink } from "./BannersSection.helper";
import { addCommand } from "../../../../store/actions/CommandActions";
import { RootState } from "../../../../types/State.interface";
import { AuthAction, AuthActionParam, AuthActionType, ComponentType } from "../../../../services/sudirService";
import { phoneWidth } from "../../../../utils/constants/widthConstants";
import { useMediaQuery } from "react-responsive";
import PointsService from "../../../../services/pointsService";
import ProposeIdeaSubmittedForm from "../../../containers/Forms/ProposeIdeaForm/ProposeIdeaSubmittedForm";
import ProposeIdeaForm from "../../../containers/Forms/ProposeIdeaForm/ProposeIdeaForm";
import GorodService, { GorodIdeaCategory, GorodIdeaSphere } from "../../../../services/gorodService";
import EventsTrackWrapperScroll from "../../../containers/EventsTrack/wrappers/EventsTrackWrapperScroll";
import EventsTrackWrapperClick from "../../../containers/EventsTrack/wrappers/EventsTrackWrapperClick";
import Share from "../../../presentational/Share/Share";

SwiperCore.use([Autoplay]);

const BannersSection = (props: any) => {
  const appContext = useContext(AppContext);

  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state: RootState) => state.user);
  const env = useSelector((state: RootState) => state.environment);
  const spheres: GorodIdeaSphere[] = useSelector((state: RootState) => state.gorod.spheres);

  const [sphere, setSphere] = useState<GorodIdeaSphere>(null);
  const [banner, setBanner] = useState<Banner | null>(null);
  const [selectCategory, setSelectCategory] = useState(null);
  const [proposeModalOpen, setProposeModalOpen] = useState(false);
  const [trioInstance, setTrioInstance] = useState(null);
  const [swiperInstance, setSwiperInstance] = useState<any>(null);
  const [isTablet, setIsTablet] = useState(Platform.isTablet());
  const [isProposeModalOpen, setIsProposeModalOpen] = useState<boolean>(false);
  const [isSubmittedProposeModalOpen, setIsSubmittedProposeModalOpen] = useState<boolean>(false);

  const isPhone = useMediaQuery({ query: `(max-width: ${phoneWidth}px)` });

  useEffect(() => {
    if (user.loggedIn) {
      dispatch(getUserProjectsState(props.currentProjects.map((p) => p.id)));
    }
  }, [user.loggedIn]);

  useEffect(() => {
    dispatch(getSittingByKey("SHOW_PROJECT_CATEGORY"));
  }, []);

  useEffect(() => {
    if (!banner && props.banners.length) {
      setBanner(props.banners[0]);
    }
  }, [props.banners]);

  // ACTIONS AFTER AUTH
  useEffect(() => {
    if (!spheres?.length) return;
    if (user.userDetails?.partiallyFilled) return;

    const actions = appContext.sudirService.getActions(ComponentType.BANNER);
    if (actions.length) {
      const redirectAction = actions.find((act) => act.type === AuthActionType.REDIRECT);
      if (redirectAction) {
        if (redirectAction.args.absolutePath) {
          const { projectId, projectName, redirectLink } = redirectAction.args;

          PointsService.getWelcomePointsMessage(
            user.userDetails,
            projectName,
            ProjectTypeEnum.CURRENT,
            redirectAction.args.projectId
          ).then((message) => {
            const handler = () => {
              window.location.href = redirectLink;
            };

            return dispatch(showInfoModal(message, renderOkButton(handler), handler));
          });
        } else {
          history.push(redirectAction.args.redirectLink);
        }
      }

      const _openProposeModal = actions.find((act) => act.type === AuthActionType.OPEN_PROPOSE_MODAL);
      if (_openProposeModal) {
        const { code, categoryCode } = _openProposeModal.args;
        const sphere = GorodService.getSphereByCode(code, spheres);
        const category = sphere && GorodService.getCategoryByCode(sphere, categoryCode);
        openProposeModal(sphere, category);
      }
    }
  }, [user.userDetails?.partiallyFilled, spheres]);

  const participateInProject = (e, projectId, link) => {
    e && e.preventDefault();

    if (!user.loggedIn) {
      const thisContainerData: AuthActionParam = {
        component: ComponentType.BANNER,
        type: AuthActionType.REDIRECT,
        args: {
          projectId,
          redirectLink: link,
        },
      };

      const projectContainerData: AuthActionParam = {
        component: ComponentType.GENERIC_PROJECT_CONTAINER,
        type: AuthActionType.JOIN_PROJECT,
        args: {
          projectId,
          redirectLink: link,
        },
      };

      const mainContainerData: AuthActionParam = {
        component: ComponentType.MAIN,
        type: AuthActionType.REDIRECT_AFTER_REG_FORM,
        args: {
          projectId,
          redirectLink: link,
        },
      };

      const action1 = new AuthAction(thisContainerData);
      const action2 = new AuthAction(projectContainerData);
      const action3 = new AuthAction(mainContainerData);

      return appContext.sudirService.authWithActions([action1, action2, action3]);
    }

    history.push({
      pathname: "announcement",
      search: `id=${projectId}`,
      state: {
        participate: true,
        projectId,
      },
    });
  };

  const toPlatform = (e, banner) => {
    e && e.preventDefault();

    const projectId = banner.projectInfo.id;
    const projectName = banner.projectInfo.nameOnPromo || banner.projectInfo.title;
    const link = banner.projectInfo.url;

    if (!user.loggedIn) {
      const thisContainerData: AuthActionParam = {
        component: ComponentType.BANNER,
        type: AuthActionType.REDIRECT,
        args: {
          projectId,
          projectName,
          absolutePath: true,
          redirectLink: link,
        },
      };

      const mainContainerData: AuthActionParam = {
        component: ComponentType.MAIN,
        type: AuthActionType.REDIRECT_AFTER_REG_FORM,
        args: {
          projectId,
          absolutePath: true,
          redirectLink: link,
        },
      };

      const action1 = new AuthAction(thisContainerData);
      const action2 = new AuthAction(mainContainerData);

      return appContext.sudirService.authWithActions([action1, action2]);
    }

    window.location.href = link;
  };

  const getBannerTitle = (banner) => {
    if (banner.projectInfo) {
      return banner.projectInfo.nameOnPromo || banner.projectInfo.title || banner.name;
    }

    return banner.name;
  };

  const getBannerTime = (banner) => {
    if (projectIsAnnouncement(banner.projectInfo)) {
      return <span>{formatProjectDates(banner.projectInfo)}</span>;
    }

    if (projectIsCurrent(banner.projectInfo)) {
      return <span className="blue">{getDaysToEnd(banner)} до завершения</span>;
    }

    if (projectIsFinished(banner.projectInfo)) {
      return <span>{formatProjectDates(banner.projectInfo)}</span>;
    }
  };

  const getDaysToEnd = (banner) => {
    return calculateRemainedTime(banner.projectInfo)[0] + " " + calculateRemainedTime(banner.projectInfo)[1];
  };

  const openProposeModal = (sphere?: GorodIdeaSphere, category?: GorodIdeaCategory) => {
    if (!user.loggedIn) {
      const thisContainerData: AuthActionParam = {
        component: ComponentType.BANNER,
        type: AuthActionType.OPEN_PROPOSE_MODAL,
        args: {
          code: sphere?.code || null,
          categoryCode: category?.code || null,
        },
      };

      const joinGameAction = new AuthAction(thisContainerData);
      return appContext.sudirService.authWithActions([joinGameAction]);
    }

    setSphere(sphere);
    setIsProposeModalOpen(true);
  };

  // RENDER

  const renderProjectBannerButton = (banner) => {
    if (projectIsAnnouncement(banner.projectInfo)) {
      // Авторизован и уже является УЧАСТНИКОМ проекта
      if (
        (props.loggedIn && userIsProjectParticipant(props.user, banner.projectId)) ||
        (!banner.projectInfo.start && !banner.projectInfo.finish)
      ) {
        return (
          <Button
            type="filled-banner-button"
            text="Подробнее"
            linkUrl={"/announcement?id=" + banner.projectId}
          ></Button>
        );
      }

      // НЕ АВТОРИЗОВАН (ГОСТЬ)
      if (!props.loggedIn) {
        return (
          <Button
            type="filled-banner-button"
            text="Хочу участвовать"
            onClick={(event) => participateInProject(event, banner.projectId, "/announcement?id=" + banner.projectId)}
          ></Button>
        );
      }

      //Авторизован, но еще НЕ является Участником проекта
      if (props.loggedIn && !userIsProjectParticipant(props.user, banner.projectId)) {
        return (
          <Button
            type="filled-banner-button"
            text="Хочу участвовать"
            onClick={(event) => participateInProject(event, banner.projectId, "/announcement?id=" + banner.projectId)}
          ></Button>
        );
      }
    }

    if (projectIsCurrent(banner.projectInfo)) {
      //Авторизован и уже является УЧАСТНИКОМ проекта
      if (
        (props.loggedIn && userIsProjectParticipant(props.user, banner.projectId)) ||
        (!banner.projectInfo.start && !banner.projectInfo.finish)
      ) {
        return (
          <Button
            type="filled-banner-button"
            text="Перейти на проект"
            onClick={(event) => toPlatform(event, banner)}
          ></Button>
        );
      }

      //Авторизован, но еще НЕ является Участником проекта
      if (props.loggedIn && !userIsProjectParticipant(props.user, banner.projectId)) {
        const btnText = "Принять участие";
        return (
          <EventsTrackWrapperClick needParent id={19} replace={[btnText]}>
            <Button type="filled-banner-button" text={btnText} onClick={(event) => toPlatform(event, banner)}></Button>
          </EventsTrackWrapperClick>
        );
      }

      //НЕ АВТОРИЗОВАН (ГОСТЬ)
      if (!props.loggedIn) {
        const btnText = "Принять участие";
        return (
          <EventsTrackWrapperClick needParent id={19} replace={[btnText]}>
            <Button type="filled-banner-button" text={btnText} onClick={(event) => toPlatform(event, banner)}></Button>
          </EventsTrackWrapperClick>
        );
      }
    }

    if (projectIsFinished(banner.projectInfo)) {
      const _isTeamMember = isTeamMember(user.userDetails, banner.projectId);
      return (
        <Button
          type="filled-banner-button"
          text={_isTeamMember ? "Перейти на проект" : "Итоги проекта"}
          // hrefTarget="_blank"
          linkUrl={
            _isTeamMember
              ? banner.projectInfo.url
              : banner.buttonLink
              ? banner.buttonLink
              : "/plan?id=" + banner.projectId
          }
        ></Button>
      );
    }
  };

  const renderBannerTitle = (banner) => {
    if (banner.type === "PROJECT") {
      if (projectIsAnnouncement(banner.projectInfo)) {
        return <div className="banner__title">Следующий проект</div>;
      }

      if (projectIsCurrent(banner.projectInfo)) {
        return <div className="banner__title">Текущий проект</div>;
      }

      if (projectIsFinished(banner.projectInfo)) {
        return <div className="banner__title">Завершенный проект</div>;
      }

      return <div className="banner__title">ПРОЕКТ</div>;
    }

    if (banner.type === "COMMON") {
      return <div className="banner__title common-title">{banner.firstTitle}</div>;
    }

    if (banner.type === "MAYOR") {
      return <div className="banner__title mayor-title">Город идей</div>;
    }
  };

  const renderBannerName = (banner) => {
    const bannerTitle = getBannerTitle(banner);
    if (banner.type === "PROJECT") {
      return projectIsCurrent(banner.projectInfo) && !!banner.projectInfo.url ? (
        <EventsTrackWrapperClick needParent id={18} replace={[bannerTitle]}>
          <a
            className="banner__name"
            href={banner.projectInfo.url}
            style={{ cursor: "pointer" }}
            dangerouslySetInnerHTML={{
              __html: bannerTitle,
            }}
          ></a>
        </EventsTrackWrapperClick>
      ) : (
        <EventsTrackWrapperClick needParent id={18} replace={[bannerTitle]}>
          <div
            className="banner__name"
            style={{ cursor: "default" }}
            dangerouslySetInnerHTML={{
              __html: bannerTitle,
            }}
          ></div>
        </EventsTrackWrapperClick>
      );
    }
  };

  const renderBannerTime = (banner) => {
    if (banner.type === "PROJECT") {
      return <div className="banner__time">{getBannerTime(banner)}</div>;
    }
  };

  const renderBannerDescription = (banner) => {
    if (banner.type === "PROJECT" || banner.type === "COMMON") {
      return (
        <div
          className="banner__description"
          dangerouslySetInnerHTML={{
            __html: banner.comment,
          }}
        />
      );
    }

    if (banner.type === "MAYOR") {
      return (
        <>
          <div className="banner__description mayor-description">
            <EventsTrackWrapperClick needParent id={20} replace={[trimSpaces(stripHTML(banner.comment))]}>
              <a
                dangerouslySetInnerHTML={{
                  __html: banner.comment,
                }}
                href="/speech"
                style={{ textDecoration: "none", color: "inherit" }}
              />
            </EventsTrackWrapperClick>
          </div>
          <div className="banner__description mayor-signature">
            <p>
              <img src={require("../../../../assets/mayor_sign.svg").default} alt="С.Собянин" /> Мэр Москвы
            </p>
          </div>
        </>
      );
    }
  };

  const renderBannerButton = (banner) => {
    if (banner.type === "PROJECT") {
      return renderProjectBannerButton(banner);
    }

    if (banner.type === "COMMON") {
      return renderCommonBannerButton(banner);
    }

    if (banner.type === "MAYOR") {
      return renderMayorBannerButton(banner);
    }
  };

  const renderBannerStats = (banner) => {
    if (banner.type === "PROJECT" && banner.projectInfo) {
      return (
        <div className="banner__footer-stats">
          {banner.projectInfo.participantCount > 0 && (
            <div className="banner__footer-stats-item">
              <div className="ic ui-icon-73"></div>
              <span>{numberWithSpaces(banner.projectInfo.participantCount)}</span>
            </div>
          )}

          {banner.projectInfo.proposalCount > 0 && (
            <div className="banner__footer-stats-item">
              <div className="ic ui-icon-107"></div>
              <span>{numberWithSpaces(banner.projectInfo.proposalCount)}</span>
            </div>
          )}
        </div>
      );
    }
  };

  const renderBannerShare = (banner) => {
    return (
      (projectIsAnnouncement(banner.projectInfo) || projectIsCurrent(banner.projectInfo)) && (
        <div className="banner__footer__share">
          Поделитесь приглашением на проект
          <Share
            type="unfolded"
            url={`/projects/${banner.projectInfo.id}`}
            imageUrl={`/uploads/get/${banner.projectInfo.logo.horizontal || banner.projectInfo.logo.square}`}
            title={`Приглашаю принять участие в проекте «${(
              banner.projectInfo.nameOnPromo ||
              banner.projectInfo.title ||
              ""
            ).replace(/&nbsp;?/g, " ")}» на платформе «Город идей»!`}
            colorIcon="#666666"
          />
        </div>
      )
    );
  };

  const renderOkButton = (handler) => {
    return <Button type="outlined" text="Хорошо" onClick={handler} />;
  };

  const hasButtonLink = (buttonLink) => {
    return buttonLink && buttonLink !== "false";
  };

  const renderCommonBannerButton = (banner) => {
    return (
      <EventsTrackWrapperClick needParent id={40} replace={[banner.buttonName]}>
        <Button
          type="filled-banner-button"
          text={banner.buttonName}
          linkUrl={hasButtonLink(banner.buttonLink) ? banner.buttonLink : ""}
          onClick={!hasButtonLink(banner.buttonLink) && openProposeModal}
          externalLink={hasButtonLink(banner.buttonLink)}
        ></Button>
      </EventsTrackWrapperClick>
    );
  };

  const renderMayorBannerButton = (banner) => {
    const btnText = "Предложить идею";
    return (
      <EventsTrackWrapperClick needParent id={21}>
        <Button type="filled-banner-button" text={btnText} onClick={openProposeModal}></Button>
      </EventsTrackWrapperClick>
    );
  };

  const renderMobileBannersSwiper = () => {
    if (!Platform.isPhone()) return;

    const classes = () => {
      let classes = "banner-slide-mobile ";
      if (process.env.NODE_ENV === "development") {
        classes += "item-colorized";
      }

      return classes;
    };

    return (
      <div className="mobile-swiper">
        <Swiper
          autoplay={{
            delay: 7000,
            disableOnInteraction: false,
            waitForTransition: false,
          }}
          loop={true}
          slidesPerView={1}
          spaceBetween={20}
          onSwiper={(swiper) => setSwiperInstance(swiper as any)} // TODO разобраться почему TS ругается
          onSlideChange={(swiper) => {
            setBanner(props.banners[swiper.realIndex]);
          }}
        >
          {props.banners.map((banner, idx) => (
            <SwiperSlide key={banner.id} className={classes()}>
              {banner.phoneAttachmentId && (
                <img src={`/uploads/get/${banner.phoneAttachmentId}`} alt={banner.phoneAttachmentId} />
              )}
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    );
  };

  const renderBannerContent = () => {
    if (!banner) return;

    return (
      <div className="banner">
        {renderBannerTitle(banner)}
        {renderBannerName(banner)}
        {renderBannerTime(banner)}
        {renderMobileBannersSwiper()}
        {renderBannerDescription(banner)}

        <div className="banner__footer">
          {renderBannerButton(banner)}
          {renderBannerStats(banner)}
          {renderBannerShare(banner)}
        </div>
      </div>
    );
  };

  const getLink = (banner) => {
    if (banner.type === "PROJECT") {
      if (projectIsCurrent(banner.projectInfo)) {
        return env.platformUrl;
      }

      if (projectIsAnnouncement(banner.projectInfo)) {
        return `${window.location.origin}/announcement?id=` + banner.projectInfo.id;
      }

      if (projectIsFinished(banner.projectInfo)) {
        return `${window.location.origin}/about`;
      }
    }

    if (banner.type === "COMMON" || banner.type === "MAYOR") {
      return `${window.location.origin}/about`;
    }
  };

  const renderLifeCycleBlock = () => {
    if (!banner) return;

    const texts = {
      propose: "Вы предлагаете идеи",
      consider: "Все идеи рассматриваются",
      implement: "Лучшие идеи реализуются",
      discuss: "Вы обсуждаете темы",
      vote: "Вы голосуете за лучшие идеи",
    };

    let content: ReactElement;
    if (
      (banner.type === "PROJECT" && projectIsFinished(banner.projectInfo)) ||
      banner.type === "MAYOR" ||
      banner.type === "COMMON"
    ) {
      content = (
        <>
          <EventsTrackWrapperClick needParent id={45} replace={[texts.propose]}>
            <div className="mein__stats-item icon-propose2">{texts.propose}</div>
          </EventsTrackWrapperClick>
          <EventsTrackWrapperClick needParent id={45} replace={[texts.consider]}>
            <div className="mein__stats-item icon-consider">{texts.consider}</div>
          </EventsTrackWrapperClick>
          <EventsTrackWrapperClick needParent id={45} replace={[texts.implement]}>
            <div className="mein__stats-item icon-implement">{texts.implement}</div>
          </EventsTrackWrapperClick>
        </>
      );
    } else {
      content = (
        <>
          <EventsTrackWrapperClick needParent id={48} replace={[texts.propose]}>
            <div className="mein__stats-item icon-propose">{texts.propose}</div>
          </EventsTrackWrapperClick>
          <EventsTrackWrapperClick needParent id={48} replace={[texts.discuss]}>
            <div className="mein__stats-item icon-discuss">{texts.discuss}</div>
          </EventsTrackWrapperClick>
          <EventsTrackWrapperClick needParent id={48} replace={[texts.vote]}>
            <div className="mein__stats-item icon-vote">{texts.vote}</div>
          </EventsTrackWrapperClick>
        </>
      );
    }

    return (
      <EventsTrackWrapperScroll id={2} needParent>
        <a href={getLink(banner)} className="mein__stats">
          {content}
        </a>
      </EventsTrackWrapperScroll>
    );
  };

  const renderdContent = () => {
    const banners = () => {
      if (!props.banners) return [];

      // make smth better
      return props.banners.length < 6 ? [...props.banners, ...props.banners] : props.banners;
    };
    return (
      <>
        <EventsTrackWrapperScroll id={1} needParent>
          <div className="mein__head">
            <div className="mein__head-left">{renderBannerContent()}</div>

            <div className="mein__head-right">
              {props.banners && !isPhone && (
                <TrioSlider
                  setWidthAsHeight={true}
                  onInit={(instance) => {
                    setTrioInstance(instance);
                    props.onInitTrio?.(instance);
                  }}
                  autoPlay={true}
                  interval={7000}
                  onChange={(banner) => {
                    setBanner(banner);
                  }}
                >
                  {banners().map((banner, id) => (
                    <Slide data={banner} key={id}>
                      <div
                        style={{
                          backgroundImage: `url('/uploads/get/${
                            isTablet ? banner.tabAttachmentId : banner.attachmentId
                          }')`,
                        }}
                      />
                    </Slide>
                  ))}
                </TrioSlider>
              )}
            </div>
          </div>
        </EventsTrackWrapperScroll>

        {/* {renderLifeCycleBlock()} */}
      </>
    );
  };

  const handleDirectionsSwipe = (e) => {
    if (!Platform.isPhone()) return;
    const currentIndex = props.banners.findIndex((el) => el.id === banner?.id) || 0;
    if (e.direction === 4) {
      // prev
      const notFirstItem = currentIndex !== 0;

      notFirstItem ? setBanner(props.banners[currentIndex - 1]) : setBanner(props.banners[props.banners.length - 1]);
      swiperInstance?.slidePrev();
    }

    if (e.direction === 2) {
      // next
      const notLastItem = currentIndex !== props.banners.length - 1;

      notLastItem ? setBanner(props.banners[currentIndex + 1]) : setBanner(props.banners[0]);
      swiperInstance?.slideNext();
    }
  };

  const handleOpenPropose = (sphere) => {
    setSphere(sphere);
    setIsProposeModalOpen(true);
  };

  return (
    <>
      {renderdContent()}
      {!!banner && (
        <BannerDirections
          banner={banner}
          currentProjects={props.currentProjects}
          announcementProjects={props.announcementProjects}
          screenWidth={props.screenWidth}
          onDirectionsSwipe={handleDirectionsSwipe}
        />
      )}
      {isProposeModalOpen && (
        <ProposeIdeaForm
          sphere={null}
          isOpen={isProposeModalOpen}
          onClose={() => setIsProposeModalOpen(false)}
          setIsSubmittedProposeModalOpen={setIsSubmittedProposeModalOpen}
        />
      )}
      {isSubmittedProposeModalOpen && (
        <ProposeIdeaSubmittedForm
          isOpen={isSubmittedProposeModalOpen}
          onClose={() => setIsSubmittedProposeModalOpen(false)}
          handleOpenPropose={handleOpenPropose}
        />
      )}
    </>
  );
};

export default BannersSection;
