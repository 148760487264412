import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import FadeIn from "../../presentational/FadeIn/FadeIn";
import VideoPage from "../../pages/Video/VideoPage";
import { RootState } from "../../../types/State.interface";
import { getVideosTags } from "../../../store/actions/VideoActions";
import { setAppColor } from "../../../store/actions/LayoutActions";
import { AppColorsEnum } from "../../App.interface";

const VideoContainer = () => {
  const dispatch = useDispatch();
  const screenWidth = useSelector(
    (state: RootState) => state.globalEvents.width
  );
  useEffect(() => {
    dispatch(setAppColor(AppColorsEnum.WHITE));
    dispatch(getVideosTags());
  }, []);
  return (
    <FadeIn>
      <VideoPage screenWidth={screenWidth} />
    </FadeIn>
  );
};

export default VideoContainer;
