const isDevelopment = process.env.NODE_ENV === "development";

export interface Script {
  src: string;
  defer?: boolean;
  async?: boolean;
}

export const mapDevScripts = [
  {
    src: "https://egiptest.mos.ru/jsapi/lib/proj4-2.4.4.js",
    defer: true,
  },
  {
    src: "https://egiptest.mos.ru/jsapi/lib/ol-5.2.0.js",
    defer: true,
  },
  {
    src: "https://egiptest.mos.ru/jsapi/lib/ol-ext-3.0.1.js",
    defer: true,
  },
  {
    src: "https://egiptest.mos.ru/jsapi/dist/egip.js",
    defer: true,
  },
];

export const mapProdScripts = [
  {
    src: "https://egip.mos.ru/jsapi/lib/proj4-2.4.4.js",
    defer: true,
  },
  {
    src: "https://egip.mos.ru/jsapi/lib/ol-5.2.0.js",
    defer: true,
  },
  {
    src: "https://egip.mos.ru/jsapi/lib/ol-ext-3.0.1.js",
    defer: true,
  },
  {
    src: "https://egip.mos.ru/jsapi/dist/egip.js",
    defer: true,
  },
];

export const mapScripts = isDevelopment ? mapDevScripts : mapProdScripts;

export const motivationScripts = [
  {
    src: "https://minio.ag.mos.ru/ag-motivation/widget/ag-motivation-user-widget.js",
    defer: true,
  },
];

const scripts: Script[] = [...mapScripts, ...motivationScripts];

const headScripts = isDevelopment
  ? [
      {
        html: `
            egip.setConfig({
              baseURL: "https://egiptest.mos.ru/",
              withCredentials: true
            })
        `,
        dependsOn: ["https://egiptest.mos.ru/jsapi/dist/egip.js"],
      },
    ]
  : [
      {
        html: `
          egip.setConfig({
            baseURL: "https://egip.mos.ru/",
            withCredentials: true
          })
      `,
        dependsOn: ["https://egip.mos.ru/jsapi/dist/egip.js"],
      },
    ];

const stylesheets = isDevelopment
  ? ["https://egiptest.mos.ru/jsapi/lib/ol-5.2.0.css", "https://egiptest.mos.ru/jsapi/lib/ol-ext-3.0.1.css"]
  : ["https://egip.mos.ru/jsapi/lib/ol-5.2.0.css", "https://egip.mos.ru/jsapi/lib/ol-ext-3.0.1.css"];

export { scripts, headScripts, stylesheets };
