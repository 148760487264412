import { API } from "./api";

export interface FinishGameParams {
    status: GameResult;
}

export enum GameStatus {
    WON = 'WON',
    LOST = 'LOST',
    INPROGRESS = 'IN_PROGRESS'
}

export enum GameResult {
    WON = 'WON',
    LOST = 'LOST',
}

const GameService = {
    async start() {
        const { data } = await API.post("/api/game/start");
        return data;
    },

    async finish(params: FinishGameParams) {
        const { data } = await API.post("/api/game/finish", params);
        return data;
    },

    async status() {
        const { data } = await API.get("/api/game/status");
        return data;
    },
};

export default GameService;
