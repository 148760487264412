import bgChangeDeviceStubCrowd from "../../../../../assets/ny/landing/crowd/bg-change-device-stub.png";
import bgSkyCrowd from "../../../../../assets/ny/landing/crowd/sky.png";
import bgBackCrowd from "../../../../../assets/ny/landing/crowd/bg-back.png";
import bgMiddleCrowd from "../../../../../assets/ny/landing/crowd/bg-middle.png";
import bgFrontCrowd from "../../../../../assets/ny/landing/crowd/bg-front.png";
import snowflakeCrowd from "../../../../../assets/ny/landing/crowd/snowflake.svg";
import logoCrowd from "../../../../../assets/ny/landing/crowd/logo.svg";
import logoHorizontalCrowd from "../../../../../assets/ny/landing/crowd/logo-horizontal.svg";
import loadingCrowd from "../../../../../assets/ny/landing/crowd/bg-loading.png";
import nyLangingObjectsCrowd from "./objects/crowd";

import bgChangeDeviceStubAg from "../../../../../assets/ny/landing/ag/bg-change-device-stub.png";
import bgSkyAg from "../../../../../assets/ny/landing/ag/sky.png";
import bgBackAg from "../../../../../assets/ny/landing/ag/bg-back.png";
import bgMiddleAg from "../../../../../assets/ny/landing/ag/bg-middle.png";
import logoAg from "../../../../../assets/ny/landing/ag/logo.svg";
import logoHorizontalAg from "../../../../../assets/ny/landing/ag/logo-horizontal.svg";
import loadingAg from "../../../../../assets/ny/landing/ag/bg-loading.png";
import nyLangingObjectsAg from "./objects/ag";

import bgChangeDeviceStubEd from "../../../../../assets/ny/landing/ed/bg-change-device-stub.png";
import bgSkyEd from "../../../../../assets/ny/landing/ed/sky.png";
import bgBackEd from "../../../../../assets/ny/landing/ed/bg-back.png";
import bgMiddleEd from "../../../../../assets/ny/landing/ed/bg-middle.png";
import logoEd from "../../../../../assets/ny/landing/ed/logo.svg";
import logoHorizontalEd from "../../../../../assets/ny/landing/ed/logo-horizontal.svg";
import loadingEd from "../../../../../assets/ny/landing/ed/bg-loading.png";
import nyLangingObjectsEd from "./objects/ed";

import { NYLandingPlatform } from "../../../../../types/NYLanding.interface";
import { NYLandingSettings } from "./types";

const currentYear = new Date().getFullYear();

const settings: NYLandingSettings = {
  CROWD: {
    backgroundsSrc: [bgSkyCrowd, bgBackCrowd, bgMiddleCrowd, bgFrontCrowd],
    bgChangeDeviceStubSrc: bgChangeDeviceStubCrowd,
    loadingSrc: loadingCrowd,
    objects: nyLangingObjectsCrowd,
    speed: 1000,
    speedBirds: 2000,
    snowflakeSrc: snowflakeCrowd,
    logoSrc: logoCrowd,
    logoHorizontalSrc: logoHorizontalCrowd,
    platformUrl: "https://crowd.mos.ru",
    platformSupportMail: "crowd_support@mos.ru",
    platformTitle: ["Город идей", "Города идей", "Городу идей"],
    platformColor: "#0EA4E6",
    copyrightText: `Краудсорсинг-проекты Правительства Москвы, ${currentYear}`,
  },
  AG: {
    backgroundsSrc: [bgSkyAg, bgBackAg, bgMiddleAg, bgFrontCrowd],
    bgChangeDeviceStubSrc: bgChangeDeviceStubAg,
    loadingSrc: loadingAg,
    objects: nyLangingObjectsAg,
    speed: 1000,
    speedBirds: 2000,
    snowflakeSrc: snowflakeCrowd,
    logoSrc: logoAg,
    logoHorizontalSrc: logoHorizontalAg,
    platformUrl: "https://ag.mos.ru",
    platformSupportMail: "support@ag.mos.ru",
    platformTitle: [
      "Активный гражданин",
      "Активного гражданина",
      "Активному гражданину",
    ],
    platformColor: "#01716D",
    copyrightText: `${currentYear}, Активный Гражданин`,
    theme: {
      loadingBar:
        "linear-gradient(269.97deg, #45A2D7 0.83%, #176591 50.27%, #34BDE4 98.11%)",
    },
  },
  ED: {
    backgroundsSrc: [bgSkyEd, bgBackEd, bgMiddleEd, bgFrontCrowd],
    bgChangeDeviceStubSrc: bgChangeDeviceStubEd,
    loadingSrc: loadingEd,
    objects: nyLangingObjectsEd,
    speed: 1000,
    speedBirds: 2000,
    snowflakeSrc: snowflakeCrowd,
    logoSrc: logoEd,
    logoHorizontalSrc: logoHorizontalEd,
    platformUrl: "https://ed.mos.ru",
    platformSupportMail: "support@ed.mos.ru",
    platformTitle: [
      "Электронный дом",
      "Электронного дома",
      "Электронному дому",
    ],
    platformColor: "#F97254",
    copyrightText: `${currentYear}, Электронный дом`,
    theme: {
      sliderCardBg: "#fff",
      sliderCardBgPhone: "#fff",
      sliderCardText: "#333",
      sliderProgressBarBg: "#882312",
      primaryV4: "#F6714C",
      primaryV5: "#862312",
      secondaryV1: "#A83517",
      phoneHeaderBg: "transparent",
      sliderRoundBtnBg: "#F66C49",
      counterBg: "rgba(226, 131, 116, 0.66)",
      sliderBg:
        "linear-gradient(250.81deg, rgba(194, 105, 105, 0.22) 0.01%, rgba(255, 111, 92, 0.42) 52.46%, rgba(166, 68, 68, 0.58) 102.58%)",
      sliderBgPhone:
        "linear-gradient(250.81deg, rgba(194, 105, 105, 0.42) 0.01%, rgba(255, 111, 92, 0.72) 52.46%, rgba(166, 68, 68, 0.88) 102.58%)",
      loadingBar:
        "linear-gradient(269.97deg, #FFC7A4 0.83%, #F97254 50.27%, #FC7E4D 98.11%)",
    },
  },
};

export const nyLandingGetAllSettings = () => settings;

export const nyLandingGetSettings = (platform: NYLandingPlatform) =>
  settings[platform];
