import React from "react";

const styles = `
.cr-header, footer {
  display: none !important;
}
  .wrapper {padding: 40px 20px !important;}
  .app{background-color: white !important;}
  .pointsRulesWrapper{max-width: 1200px; margin: 0 auto; text-align: left}
  li, p{font-family:sans-serif; font-size:16px;}
  h1{text-align:center; font-size:34px;}
  h3{font-size:24px;line-height: 26px;}
  h2{font-size:30px;}
  div{text-align:center;font-family:sans-serif; font-size:16px;}
  p{margin: 20px 0}
`;

const NewYearRulesPage = (props) => {
  return (
    <div className="pointsRulesWrapper">
      <style>{styles}</style>
      <p style={{ textAlign: "center", marginTop: 0 }}>&nbsp;</p>
      <h3 style={{ textAlign: "center", textTransform: "capitalize" }}>
        <b>ПРАВИЛА УЧАСТИЯ В ИГРЕ НА ЗНАНИЕ ПРОЕКТОВ ПЛАТФОРМЫ «ГОРОД ИДЕЙ»</b>
      </h3>
      <h3 style={{ textAlign: "center", textTransform: "capitalize" }}>
        <b>(С ИЗМЕНЕНИЯМИ НА 15.03.2022)</b>
      </h3>

      <p style={{ textAlign: "center" }}>1. СРОКИ ПРОВЕДЕНИЯ</p>

      <p style={{ textAlign: "left" }}></p>
      <p>
        1.1. Игра на знание проектов платформы «Город идей» (далее – Игра)
        проводится на краудсорсинговой платформе Правительства Москвы «Город
        идей» (далее – Платформа) с 24.02.2022 по 25.04.2022.
      </p>

      <p style={{ textAlign: "center" }}>2. УСЛОВИЯ УЧАСТИЯ В ИГРЕ</p>
      <p>
        2.1. К участию в Игре допускаются лица, авторизованные на Платформе в
        порядке, предусмотренном Пользовательским соглашением, размещенным на
        сайте Платформы в информационно-телекоммуникационной сети Интернет{" "}
        <a href="/license/">https://crowd.mos.ru/license/</a>, и имеющие полную
        учетную запись на сайте mos.ru (далее – Участник Игры).
      </p>

      <p>
        2.2. Для участия в Игре необходимо перейти по ссылке{" "}
        <a href="/birthday">https://crowd.mos.ru/birthday</a> и нажать кнопку
        «Начать игру». Совершая указанные действия, Участник Игры подтверждает,
        что он ознакомился с настоящими правилами участия в Игре (далее –
        Правила), согласен с ними и обязуется их соблюдать.
      </p>

      <p>2.3. Условия Игры:</p>

      <p>
        2.3.1. Участнику Игры необходимо правильно ответить на 10 предложенных
        вопросов подряд.
      </p>

      <p>
        2.3.2. Время для ответа на каждый вопрос – 30 секунд. По истечении этого
        времени ответ на вопрос не засчитывается.
      </p>

      <p>
        2.3.3. В случае успешного выполнения условий Игры, предусмотренных п.
        2.3.1, 2.3.2 Правил, Участник становится победителем, и ему начисляются
        300 баллов, которые можно обменять на поощрения в порядке,
        предусмотренном п. 3.4 Правил.
      </p>

      <p>
        2.3.4. В случае направления неверного ответа или истечения времени на
        ответ, предусмотренного п. 2.3.2 Правил, Участнику Игры предоставляется
        возможность начать Игру заново. Количество попыток участия в Игре не
        ограничено.
      </p>

      <p style={{ textAlign: "center" }}>
        3. ПОДВЕДЕНИЕ ИТОГОВ И ПОЛУЧЕНИЕ ПООЩРЕНИЙ
      </p>
      <p>
        3.1. Уведомление о победе Участник Игры видит на экране после успешного
        выполнения условий Игры, предусмотренных п. 2.3 Правил.
      </p>
      <p>
        3.2. Баллы за победу в Игре начисляются Участнику Игры только один раз
        вне зависимости от количества успешных попыток участия в Игре.
      </p>
      <p>
        3.3. Начисление баллов производится:
      </p>
      <p>
        - в период с 22.03.2022 по 15.04.2022 для участников, ставших победителями в срок до 21.03.2022 включительно;
      </p>
      <p>
        - в период с 26.04.2022 по 25.05.2022 для участников, ставших победителями в срок с 22.03.2022 по 25.04.2022.
      </p>
      <p>
        3.4. Полученные за победу в Игре баллы Участник Игры вправе по своему
        усмотрению обменять на поощрения, перечень которых представлен в разделе
        «Магазин поощрений» по адресу в информационно-телекоммуникационной сети
        «Интернет»{" "}
        <a href="https://ag-vmeste.ru/events/7">
          https://ag-vmeste.ru/events/7
        </a>
        . Информация о том, как обменять баллы на поощрения, доступна по{" "}
        <a href="https://ag-vmeste.ru/help">ссылке</a>.
      </p>

      <p style={{ textAlign: "center" }}>4. ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ</p>
      <p>
        4.1. Правила проведения Игры, а также даты и сроки ее проведения, виды
        поощрений могут быть изменены организатором Игры в одностороннем
        порядке.
      </p>
      <p>
        4.2. В случае возникновения технических сбоев, а также по иным
        возникающим в процессе Игры и использования Платформы вопросам Участник
        Игры может направить обращение по электронной почте{" "}
        <a href="mailto:crowd_support@mos.ru">crowd_support@mos.ru</a> или
        посредством специального сервиса «Помощь» на Платформе Проекта.
      </p>

      <p>
        Редакция правил размещена 15.03.2022, 19:00.
      </p>

    </div>
  );
};

export default NewYearRulesPage;
