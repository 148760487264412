import React, { useEffect, useState, useRef, InputHTMLAttributes, ComponentType, RefAttributes } from "react";
import Validator from "../../../../services/validator";
import { declOfNum, forceMaxLengthOnAndroid } from "../../../../utils";

import "./Input.scss";

interface Props {
  type?: string;
  label?: string;
  value?: string;
  placeholder?: string;
  maxLength?: number;
  isRequired?: boolean;
  onChange: Function;
  onFocus?: (event) => void;
  onBlur?: (event) => void;
  showError?: boolean;
  isDisabled?: boolean;
  spellCheck?: boolean;
  validateRules?: any;
  onInitValidator?: Function;
  ComponentInput?: ComponentType<
    InputHTMLAttributes<HTMLInputElement> & { label: Props["label"] } & RefAttributes<HTMLInputElement>
  >;
  inputRef?: React.RefObject<HTMLInputElement>;
  hint?: string;
  showSymbolsReminded?: boolean;
}

const Input = (props: Props) => {
  const [errorList, setErrorList] = useState<string[]>([]);
  const [validator, setValidator] = useState<Validator | null>(null);
  const inputEl = useRef<HTMLInputElement | any>(null);
  const inputRef = props.inputRef ? props.inputRef : inputEl;
  const [currentLength, setCurrentLength] = useState(props.value?.trim().length || 0);

  useEffect(() => {
    if (!validator && props.validateRules) {
      const validator = new Validator(inputRef.current, props.validateRules);
      setValidator(validator);
      if (props.onInitValidator && typeof props.onInitValidator === "function") {
        props.onInitValidator(validator);
      }
    }
  }, []);

  useEffect(() => {
    if (validator) {
      const list = validator.getErrorList();
      setErrorList(list);
    }
  }, [props.value, props.showError]);

  useEffect(() => {
    if (props.isDisabled) {
      change("");
    }
  }, [props.isDisabled]);

  const change = (value) => {
    props.onChange(value);
    setCurrentLength(value.trim().length);
  };

  const getClassesForInput = () => {
    let classes = "cr-input__input";

    if (props.showError && !validator?.isValid()) {
      classes += " error";
    }

    if (props.isDisabled) {
      classes += " cr-input-disabled";
    }

    return classes;
  };

  const getClassesForTooltip = () => {
    let classes = "cr-input__tooltip";

    if (!props.label) {
      classes += " cr-input__tooltip--no-label";
    }

    return classes;
  };

  const canShowErrorTooltip = () => {
    return props.showError && Boolean(errorList?.length);
  };

  const inputProps: InputHTMLAttributes<HTMLInputElement> = {
    type: props.type || "text",
    placeholder: props.placeholder,
    maxLength: props.type !== "password" ? props.maxLength : undefined,
    onKeyUp: (e) => forceMaxLengthOnAndroid(e, props.maxLength),
    className: getClassesForInput(),
    spellCheck: props.spellCheck,
    lang: "ru",
    value: props.value,
    onFocus: props.onFocus,
    onBlur: props.onBlur,
    onChange: (e) => change(e.target.value),
  };

  const { ComponentInput } = props;
  return (
    <div className="cr-input">
      {props.label && (
        <div className="cr-input__label">
          {props.label}
          {props.isRequired && props.label && <span className="cr-input__label-required">*</span>}
        </div>
      )}

      {ComponentInput ? (
        <ComponentInput ref={inputRef} label={props.label} {...inputProps} />
      ) : (
        <input ref={inputRef} {...inputProps} />
      )}
      {canShowErrorTooltip() && (
        <div className={getClassesForTooltip()}>
          {errorList.map((err, key) => (
            <span key={key}>{err}</span>
          ))}
        </div>
      )}
      {(props.showSymbolsReminded && props.maxLength && currentLength && (
        <div className="cr-input__length">
          Осталось {props.maxLength - currentLength}{" "}
          {declOfNum(props.maxLength - currentLength || 0, ["символ", "символа", "символов"])}
        </div>
      )) ||
        ""}
      {props.hint && (!currentLength || !props.showSymbolsReminded) && (
        <div className="cr-input__length">{props.hint}</div>
      )}
    </div>
  );
};

export default Input;
