import homeIcon from "../../../../../assets/newyear2023/icons/Union.svg";
import ideaIcon from "../../../../../assets/newyear2023/icons/idea.svg";
import agIcon from "../../../../../assets/newyear2023/icons/logo-ag.svg";
import smartHomeIcon from "../../../../../assets/newyear2023/icons/smartHome.svg";
import questIcon from "../../../../../assets/newyear2023/icons/quests.svg";
import ourCityIcon from "../../../../../assets/newyear2023/icons/ourCity.svg";
import rewardsIcon from "../../../../../assets/newyear2023/icons/rewards.svg";
import { navigationType } from "./Header.types";

export const navigations: navigationType[] = [
  { icon: homeIcon, text: "Главная", slide: 0, className: "new-year-2023-button__blueIcon" },
  { icon: ideaIcon, text: "Город идей", slide: 1, className: "new-year-2023-button__lightblueIcon" },
  { icon: agIcon, text: "Активный гражданин", slide: 2, className: "new-year-2023-button__green" },
  { icon: smartHomeIcon, text: "Электронный дом", slide: 3, className: "new-year-2023-button__orange" },
  { icon: questIcon, text: "Город заданий", slide: 4, className: "new-year-2023-button__yellow" },
  { icon: ourCityIcon, text: "Наш город", slide: 5, className: "new-year-2023-button__turquoise" },
  { icon: rewardsIcon, text: "Миллион призов", slide: 6, className: "new-year-2023-button__secondBlue" },
];
