import React, { useState } from "react";
import { useSelector } from "react-redux";
import { GorodIdeaCategory, GorodIdeaSphere } from "../../../services/gorodService";
import { RootState } from "../../../types/State.interface";
import { SphereCard } from "../../presentational/Cards/SphereCard/SphereCard";
import "./SphereList.scss";

interface SphereListProps {
  onSelect?: (sphere: GorodIdeaSphere) => void;
  onSelectCategory?: (category: GorodIdeaCategory, sphere: GorodIdeaSphere) => void;
}

const SphereList = (props: SphereListProps) => {
  const spheres: GorodIdeaSphere[] = useSelector(
    (state: RootState) => state.gorod.spheres
  );

  return (
    <div className="sphere-list">
      {spheres?.filter(s => s.active).map((sphere) => {
        return (
          <SphereCard
            key={sphere.code}
            sphere={sphere}
            onSelect={props.onSelect}
            onSelectCategory={props.onSelectCategory}
          />
        );
      })}
    </div>
  );
};

export default SphereList;
