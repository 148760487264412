import React from "react";

import BookViewBase from "./BookViewBase";
import Zaglushka from "../../../../assets/zaglushka.png";

class NotAvaiable extends BookViewBase {
  bookView: any;

  constructor(bookView) {
    super();

    this.bookView = bookView;
  }

  render() {
    return (
      <div className="no-book">
        <img className="patch" src={Zaglushka}></img>
      </div>
    );
  }
}

export default NotAvaiable;
