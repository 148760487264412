import React from "react";
import NextModal from "../../../presentational/Modals/NextModal/NextModal";
import "swiper/swiper.scss";
import "./VideoViewer.scss";
import { VideoViewerProps } from "./VideoViewer.interface";
import VideoCard from "../../../presentational/Cards/VideoCard/VideoCard";
import classNames from "classnames";

const VideoViewer = ({
  isOpened,
  onClose,
  onSlideNext,
  onSlidePrev,
  canSlidePrev,
  canSlideNext,
  onTagClick,
  video,
  shareOptions,
  classnames
}: VideoViewerProps) => {
  const renderContent = () => {
    return (
      <VideoCard
        element={video}
        showedVideo={true}
        onTagClick={onTagClick}
        share={shareOptions || {
          url: `/videos/${video.id}`,
          imageUrl: `/uploads/get/${video.previewId}`,
          title: `${video.name} — Платформа Правительства Москвы «Город идей»`,
        }}
      />
    );
  };

  return (
    <NextModal
      class={classNames("video-viewer-modal", classnames)}
      isOpened={isOpened}
      canSlidePrev={canSlidePrev}
      canSlideNext={canSlideNext}
      onSlidePrev={onSlidePrev}
      onSlideNext={onSlideNext}
      onClose={onClose}
    >
      {renderContent()}
    </NextModal>
  );
};

export default VideoViewer;
