import { forwardRef } from "react";

const Line2 = forwardRef<SVGPathElement>((_, ref) => (
  <svg
    width="1513"
    height="1115"
    viewBox="0 0 1513 1115"
    fill="none"
    style={{ transform: "translate(-50px, 0px)" }}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      ref={ref}
      d="M1418.15 1C1474.66 22.41 1531.28 118.601 1505.66 210.4C1474.61 321.701 1315.14 385.362 1061.37 312.595C911.08 269.5 786.64 163.248 799.835 86.485C808.541 35.8321 849.072 42.0985 849.072 70.297C849.072 159.07 712.694 207.587 492.754 254.11C297.727 295.363 195.688 359.901 111.971 447.322C-127.562 697.453 77.4662 1053.95 197.483 1114"
      stroke="#252526"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
));

export default Line2;
