import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { Idea, IdeaAuthor } from "../../../../types/Idea.interface";
import Share from "../../Share/Share";
import "./IdeaCard.scss";
import {
  SudirService,
} from "../../../../services/sudirService";
import { User } from "../../../../types/User.interface";
import EventsTrackWrapperClick from "../../../containers/EventsTrack/wrappers/EventsTrackWrapperClick";
import { canSeeHiddenNames, nameIfHidden, renderHatIfUserNameIsHidden } from "../../../../utils/user.utils";

interface InternalProps {
  like?: (idea) => void;
}

interface Props extends InternalProps {
  idea?: Idea | any;
  isHideFooter?: Boolean;
  onClick?: () => void;
  user?: User;
  onCardClick?: (idea: Idea) => void;
  onAuthorClick?: (idea: Idea) => void;
  onTagClick?: (idea: Idea) => void | null;
  onLike?: (idea: Idea) => void;
  history?: any;
  sudirService: SudirService;
}

const vkUrl = "https://vk.com/share.php?";
const twUrl = "http://twitter.com/share?";
const okUrl = "https://connect.ok.ru/offer?";

class IdeaCard extends React.Component<Props, any> {
  constructor(props: Props) {
    super(props);
  }

  onClick = () => {
    if (this.props.onClick && typeof this.props.onClick === "function") {
      this.props.onClick();
    } else {
      console.error("Please provide a callback for Idea component");
    }
  };

  getName = () => {
    const {idea} = this.props;
    const author = idea?.author;
    if (!author) return "";
    if (author.deletedSudir || (!author.showName && !canSeeHiddenNames(this.props.user))) return nameIfHidden;

    return `${author.lastName} ${this.props.idea.author.firstName && this.props.idea.author.firstName[0]}.`
  };

  getTitle = () =>
    `На платформе «Город идей» в рамках проекта «${this.props.idea?.projectName}» предложена идея «${this.props.idea?.ideaName}»`;

  like = (e, idea) => {
    e?.stopPropagation();

    if (this.props.onLike && typeof this.props.onLike === "function")
      return this.props.onLike(idea);
  };

  renderHeader = () => {
    const authorName = this.getName();
    return (
      <div
        className="idea-card__header"
        onClick={(e) =>
          this.props.onCardClick && this.props.onCardClick(this.props.idea)
        }
      >
        {this.props.idea?.watermarkAttachmentId && (
          <img
            src={"/uploads/get/" + this.props.idea.watermarkAttachmentId}
            alt={this.props.idea.ideaName}
          />
        )}

        <div className="idea-card__header-actions">
          <EventsTrackWrapperClick needParent id={175} replace={[authorName]}>
            <div
              className="idea-card__header-actions-author"
              onClick={(e) => {
                e.stopPropagation();
                this.props.onAuthorClick &&
                  this.props.onAuthorClick(this.props.idea);
              }}
            >
              {authorName}
              {renderHatIfUserNameIsHidden(this.props.idea.author, this.props.user)}
            </div>
          </EventsTrackWrapperClick>

          <div className="idea-card__header-actions-social">
            <EventsTrackWrapperClick needParent id={176}>
              <div
                className="idea-card__header-actions-social-like"
                onClick={(e) => this.like(e, this.props.idea)}
              >
                <div
                  className={`ic ${
                    this.props.idea?.liked && this.props.user?.loggedIn
                      ? "ui-icon-91"
                      : "ui-icon-92"
                  } `}
                />
                {this.props.idea?.rating > 0 && (
                  <span className="idea-card__header-actions-social-like-rating">
                    {this.props.idea?.rating}
                  </span>
                )}
              </div>
            </EventsTrackWrapperClick>

            <Share
              url={`/ideas/${this.props.idea?.id}`}
              imageUrl={`/uploads/get/${this.props.idea?.watermarkAttachmentId}`}
              title={this.getTitle()}
              backgroundColorIcon="rgba(0, 0, 0, 0.8)"
              colorIcon="white"
            />
          </div>
        </div>
      </div>
    );
  };

  renderFooter = () => {
    if (this.props.isHideFooter) return;

    return (
      <div className="idea-card__footer">
        <div className="idea-card__footer-tag--wrapper">
          <div
            className={`idea-card__footer-tag ${
              this.props.idea.groupId ? "idea-card__footer-tag--group" : ""
            }`}
            onClick={(e) =>
              this.props.onTagClick && this.props.onTagClick(this.props.idea)
            }
          >
            {this.props.idea?.tag?.name}
          </div>
        </div>
        <div className="idea-card__footer-name">
          {this.props.idea?.ideaName}
        </div>
      </div>
    );
  };

  render() {
    return (
      <div className="idea-card">
        {this.renderHeader()}
        {this.renderFooter()}
      </div>
    );
  }
}

export default connect(null, null)(withRouter(IdeaCard));
