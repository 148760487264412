import React, { Children } from "react";
import ResizeObserver from "resize-observer-polyfill";

import "./TrioSlider.scss";

const classes = {
  itemCurrent: "trio-center-item",
  itemPast: "trio-left-item",
  itemFuture: "trio-right-item",
};

const classRemover = new RegExp(
  "\\b(" +
    classes.itemCurrent +
    "|" +
    classes.itemPast +
    "|" +
    classes.itemFuture +
    ")(.*?)(\\s|$)",
  "g"
);

const whiteSpaceRemover = new RegExp("\\s\\s+", "g");

interface Props {
  onInit: (slider) => void;
  setWidthAsHeight: boolean;
  autoPlay: boolean;
  interval: number;
  onChange: Function;
  children: any[];
}

class TrioSlider extends React.Component<Props, any> {
  hostRef: any = null;
  itemsRef: any = [];
  timer: any = null;
  canPlay: boolean = true;
  resizeObserver: ResizeObserver | any;

  constructor(props) {
    super(props);

    this.state = {
      activeIndex: 0,
    };

    this.autoPlay();
  }

  componentDidMount() {
    if (this.props.onInit && typeof this.props.onInit === "function") {
      this.props.onInit(this);
    }

    this.onChange();

    if (this.props.setWidthAsHeight) {
      this.setResizeListener();
    }
  }

  componentWillUnmount() {
    this.destroy();
  }

  autoPlay = () => {
    if (!this.props.autoPlay || Children.count(this.props.children) <= 1)
      return;

    if (this.timer) clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.canPlay && this.moveRight();
    }, this.props.interval);
  };

  setHostRef = (element) => {
    this.hostRef = element;
  };

  setItemsRef = (node, index) => {
    if (!this.itemsRef) {
      this.itemsRef = [];
    }
    this.itemsRef[index] = node;
  };

  removeExtraClasses(index) {
    const element = this.itemsRef[index];
    if (element) {
      element.className = element.className
        .replace(classRemover, "")
        .replace(whiteSpaceRemover, " ");
    }
  }

  setResizeListener() {
    if (!this.hostRef) return;

    this.resizeObserver = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        entry.target.style.height = entry.contentRect.width + "px";
        this.autoPlay();
      });
    });
    this.resizeObserver.observe(this.hostRef);
  }

  getClassForItem = (element, index) => {
    //this.removeExtraClasses(index);

    let classNames = "trio__front-item";

    if (process.env.NODE_ENV === "development") {
      classNames += " item-colorized";
    }

    if (index === this.state.activeIndex) {
      classNames += " trio-center-item";
    }

    if (index === this.getActualIndex(this.state.activeIndex + 1)) {
      classNames += " trio-right-item";
    }

    if (index === this.getActualIndex(this.state.activeIndex - 1)) {
      classNames += " trio-left-item";
    }

    return classNames;
  };

  getActualIndex(index) {
    const itemsLength = Children.count(this.props.children);

    return Math.sign(index) < 0
      ? itemsLength - Math.abs(index)
      : index % itemsLength;
  }

  moveRight = () => {
    this.setState(
      (state, props) => ({
        activeIndex: this.getActualIndex(state.activeIndex + 1),
      }),
      () => {
        this.onChange();
      }
    );

    this.autoPlay();
  };

  moveLeft = () => {
    this.setState(
      (state, props) => ({
        activeIndex: this.getActualIndex(state.activeIndex - 1),
      }),
      () => {
        this.onChange();
      }
    );

    this.autoPlay();
  };

  onChange = () => {
    if (this.props.onChange && typeof this.props.onChange === "function") {
      const child = this.props.children[
        this.getActualIndex(this.state.activeIndex)
      ];
      if (child) this.props.onChange(child.props?.data);
    }
  };

  handleClickItem(index) {
    if (index === this.getActualIndex(this.state.activeIndex + 1)) {
      this.moveRight();
    }

    if (index === this.getActualIndex(this.state.activeIndex - 1)) {
      this.moveLeft();
    }
  }

  onMouseEnter = () => {
    if (this.props.autoPlay) {
      this.canPlay = false;
    }
  };

  onMouseLeave = () => {
    if (this.props.autoPlay) {
      this.canPlay = true;
      this.autoPlay();
    }
  };

  renderItems() {
    return Children.map(this.props.children, (element, index) => {
      const slideProps = {
        key: index,
        ref: (element) => this.setItemsRef(element, index),
        className: this.getClassForItem(element, index),
        onClick: this.handleClickItem.bind(this, index),
      };

      return <div {...slideProps}>{element}</div>;
    });
  }

  renderArrows = () => {
    return (
      <div className="trio__arrows">
        <span
          className="trio__arrows-btn trio__arrows-btn--left"
          onClick={(e) => this.moveLeft()}
        >
          <div className="ic ui-icon-105"></div>
        </span>

        <span
          className="trio__arrows-btn trio__arrows-btn--right"
          onClick={(e) => this.moveRight()}
        >
          <div className="ic ui-icon-88"></div>
        </span>
      </div>
    );
  };

  destroy() {
    if (this.resizeObserver && this.hostRef) {
      this.resizeObserver.unobserve(this.hostRef);
    }
  }

  canRender() {
    return this.props.children?.length;
  }

  render() {
    return (
      <>
        {this.canRender() && (
          <div
            className="trio"
            ref={(host) => this.setHostRef(host)}
            onMouseEnter={this.onMouseEnter}
            onMouseLeave={this.onMouseLeave}
          >
            <div className="trio__front">
              {this.renderItems()}
              <div></div>
            </div>

            {this.renderArrows()}
          </div>
        )}
      </>
    );
  }
}

export default TrioSlider;
