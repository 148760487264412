const CommentService = {
  getPlainCommentText: (htmlCommentText: string): string => {
    const commentEl = document.createElement("section");
    commentEl.innerHTML = htmlCommentText;

    commentEl.querySelectorAll(".textAreaEditor__quote").forEach((qEl) => {
      qEl.remove();
    });

    return commentEl.innerText;
  },
};

export default CommentService;
