import React, {
  useEffect,
  useState,
  useRef,
  TextareaHTMLAttributes,
  Ref,
  ReactElement,
  ComponentType,
} from "react";
import Validator from "../../../../services/validator";
import { declOfNum, forceMaxLengthOnAndroid } from "../../../../utils/index";

import "./Textarea.scss";

interface Props {
  label: string;
  value: string;
  placeholder: string;
  isRequired: boolean;
  maxLength: number;
  onlyVerticalResize?: boolean;
  onChange: Function;
  hint?: string;
  showError: boolean;
  isDisabled?: boolean;
  spellCheck?: boolean;
  validateRules: any;
  onInitValidator: (validator) => void;
  ComponentTextarea?: ComponentType<
    TextareaHTMLAttributes<HTMLTextAreaElement> & {
      textareaRef: Ref<HTMLTextAreaElement>;
      label: Props["label"];
    }
  >;
}

const Textarea = (props: Props) => {
  const [errorList, setErrorList] = useState<string[]>([]);
  const [validator, setValidator] = useState<Validator | null>(null);
  const textareaEl = useRef<HTMLInputElement | any>(null);
  const [countCharacters, setCountCharacters] = useState(0);

  useEffect(() => {
    if (!validator && props.validateRules) {
      const validator = new Validator(textareaEl.current, props.validateRules);
      setValidator(validator);
      if (
        props.onInitValidator &&
        typeof props.onInitValidator === "function"
      ) {
        props.onInitValidator(validator);
      }
    }
  }, []);

  useEffect(() => {
    if (validator) {
      const list = validator.getErrorList();
      setErrorList(list);
    }
  }, [props.value]);

  useEffect(() => {
    if (props.isDisabled) {
      props.onChange("");
    }
  }, [props.isDisabled]);

  const change = (value) => {
    props.onChange(value);
    setCountCharacters(value.length);
  };

  const getClassesForTextarea = () => {
    let classes = "cr-textarea__input";

    if (props.showError && !validator?.isValid()) {
      classes += " cr-textarea__input-invalid";
    }

    if (props.isDisabled) {
      classes += " cr-textarea-disabled";
    }

    if (props.onlyVerticalResize) {
      classes += " cr-textarea__only-vertical-resize";
    }

    return classes;
  };

  const getClassesForTooltip = () => {
    let classes = "cr-textarea__tooltip";

    return classes;
  };

  const canShowErrorTooltip = () => {
    return props.showError && Boolean(errorList?.length);
  };

  const textareaProps: TextareaHTMLAttributes<HTMLTextAreaElement> = {
    placeholder: props.placeholder,
    maxLength: props.maxLength,
    spellCheck: props.spellCheck,
    lang: "ru",
    onKeyUp: (e) => forceMaxLengthOnAndroid(e, props.maxLength),
    className: getClassesForTextarea(),
    value: props.value,
    onChange: (e) => change(e.target.value),
  };

  const { ComponentTextarea } = props;
  return (
    <div className="cr-textarea">
      <div className="cr-textarea__label">
        {props.label}
        {props.isRequired && (
          <span className="cr-textarea__label-required">*</span>
        )}
      </div>

      {ComponentTextarea ? (
        <ComponentTextarea
          textareaRef={textareaEl}
          label={props.label}
          {...textareaProps}
        />
      ) : (
        <textarea ref={textareaEl} {...textareaProps} />
      )}

      {canShowErrorTooltip() && (
        <div className={getClassesForTooltip()}>
          {errorList.map((err, key) => (
            <span key={key}>{err}</span>
          ))}
        </div>
      )}

      {(props.maxLength && countCharacters && (
        <span className="cr-textarea__length">
          Осталось {props.maxLength - countCharacters}{" "}
          {declOfNum(props.maxLength - countCharacters || 0, [
            "символ",
            "символа",
            "символов",
          ])}
        </span>
      )) ||
        ""}

      {props.hint && !countCharacters && (
        <span className="cr-textarea__length">{props.hint}</span>
      )}
    </div>
  );
};

export default Textarea;
