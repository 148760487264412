import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";

import { SudirService } from "../services/sudirService";
import { eventsTrackConfig } from "../utils/eventsTrack";
import EventsTrackContainer from "./containers/EventsTrack/EventsTrackContainer";

import ToastContainer from "./containers/Toast/Toast";

import App from "./App";

import {
  BrowserSupport,
  ErrorBoundary,
  LayoutControl,
  PostMessageListener,
  SudirSessionContainer,
} from "./containers/Wrappers";
import GlobalControl from "./containers/Wrappers/GlobalControl/GlobalControl";
import WindowControl from "./containers/Wrappers/WindowControl/WindowControl";
import SudirSessionError from "./containers/Wrappers/SudirSessionError/SudirSessionError";

const sudirService = new SudirService();
export const AppContext = React.createContext({ sudirService });

const Root = ({ store }) => (
  <Provider store={store}>
    <WindowControl>
      <ErrorBoundary>
        <Router>
          <LayoutControl>
            <GlobalControl>
              <BrowserSupport>
                <AppContext.Provider value={{ sudirService }}>
                  <ToastContainer>
                    <PostMessageListener>
                      <SudirSessionContainer>
                        <SudirSessionError>
                          <EventsTrackContainer config={eventsTrackConfig}>
                            <App />
                          </EventsTrackContainer>
                        </SudirSessionError>
                      </SudirSessionContainer>
                    </PostMessageListener>
                  </ToastContainer>
                </AppContext.Provider>
              </BrowserSupport>
            </GlobalControl>
          </LayoutControl>
        </Router>
      </ErrorBoundary>
    </WindowControl>
  </Provider>
);

export default Root;
