import { FC, useEffect, useState } from "react";
import "./Comment.scss";
import UserAvatar from "../../../../../presentational/UserAvatar/UserAvatar";
import { relativeTime } from "../../../../../../utils/dates";
import Button from "../../Button/Button";
import { Comment } from "../Discussion.interface";
import { DataAdapter } from "../../../../../../services/adapter/data-adapter";
import { copyToClipboard, displayError } from "../../../../../../utils";
import Tooltip from "../../../../../presentational/Controls/Tooltip/Tooltip";
import LikeCounter from "./LikeCounter/LikeCounter";
import { useMediaQuery } from "react-responsive";
import { phoneWidth } from "../../../../../../utils/constants/widthConstants";
import DiscussionLandingService from "../../../services/discussion-service";
import { ApiStatusCode } from "../../../../../../types/Common.interface";
import UsersLikePopup from "./UsersLikePopup/UsersLikePopup";
import Popup from "../../Popup/Popup";

export interface CommentProps {
  tooltipId?: string;
  label?: string;
  comment: Comment;
  copyLinkUrl?: string;
  answerLinkUrl?: string;
  isLikeVisible?: boolean | ((comment: Comment) => boolean);
  isAnswerButtonVisible?: boolean;
  isLiked?: boolean;
  onCounterClick?: (comment: Comment) => void;
  onVote?: (comment: Comment) => void;
  isLikeDisabled?: boolean;
}

const CommentCard = ({
  label,
  comment,
  copyLinkUrl,
  answerLinkUrl,
  isLiked,
  isLikeVisible,
  isAnswerButtonVisible,
  onVote,
  tooltipId,
  isLikeDisabled,
}: CommentProps) => {
  const isPhone = useMediaQuery({ query: `(max-width: ${phoneWidth}px)` });

  const [isLinkCopied, setIsLinkCopied] = useState(false);
  const [likesCount, setLikesCount] = useState(comment.upVotes);
  const [selectedUserLikeList, setSelectedUserLikeList] = useState(null);
  const [isOpenPopup, setIsOpenPopup] = useState(false);

  const copyLink = () => {
    setIsLinkCopied(true);
    copyToClipboard(copyLinkUrl, true);
  };

  useEffect(() => {
    setLikesCount(comment.upVotes);
  }, [comment]);

  const handleCounterClick = async (comment: Comment) => {
    setIsOpenPopup(true);
    try {
      const response = await DiscussionLandingService.getUsersLikedList({ commentId: comment.id, page: 0, size: 100 });
      if (response.status === ApiStatusCode.OK) {
        setSelectedUserLikeList(response.data);
      } else {
        displayError("Ошибка при получении списка лайков коммента", response.message);
        setIsOpenPopup(false);
      }
    } catch (error) {
      displayError("CommentCard", "Ошибка при получении списка лайков коммента");
      setIsOpenPopup(false);
    }
  };

  const closePopup = () => {
    isOpenPopup && setIsOpenPopup(false);
    setSelectedUserLikeList(null);
  };

  return (
    <div className="comment-card">
      {label && <div className="comment-card__label">{label}</div>}
      <div className="comment-card__head">
        <div className="comment-card__user">
          <UserAvatar user={DataAdapter.toUser(comment.author)} size={isPhone ? "XS" : "M"} />
          <div className="comment-card__user-info">
            <div className="comment-card__user-name">{`${comment.author.firstName} ${comment.author.lastName}`}</div>
            <div className="comment-card__user-activity">
              {comment.author.online
                ? "Онлайн"
                : comment.author.lastActivityDate && relativeTime(comment.author.lastActivityDate)}
            </div>
          </div>
        </div>
        <div className="comment-card__tools">
          <Tooltip
            text={isLinkCopied ? "Ссылка скопирована" : "Скопировать ссылку"}
            idx={(tooltipId || comment.id) + "copy"}
          >
            <div className=" ic ui-icon-65 comment-card__tools-copy" onClick={() => copyLink()}></div>
          </Tooltip>
        </div>
      </div>
      <div className="comment-card__body" dangerouslySetInnerHTML={{ __html: comment.text }}></div>
      <div className="comment-card__footer">
        {isAnswerButtonVisible && <Button type="filled" text="Ответить" linkUrl={answerLinkUrl} hrefTarget="_blank" />}
        {(typeof isLikeVisible === "function" ? isLikeVisible(comment) : isLikeVisible) && (
          <div className="comment-card__popupWrapper">
            <Popup
              body={<UsersLikePopup users={selectedUserLikeList} onClose={() => closePopup()} />}
              className={`comment-card__popup ${selectedUserLikeList?.length <= 6 ? "comment-card__offset" : ""}`}
              isOpen={isOpenPopup}
              onClose={closePopup}
            >
              <LikeCounter
                isLiked={isLiked}
                count={likesCount}
                onVote={() => onVote(comment)}
                onCounterClick={() => handleCounterClick(comment)}
                isLikeDisabled={isLikeDisabled}
              />
            </Popup>
          </div>
        )}
      </div>
    </div>
  );
};
export default CommentCard;
