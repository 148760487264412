import { FC, useEffect } from "react";
import { hideElement } from "../../../utils";
import logoSrc from "../../../assets/Logo-b.svg";
import "./NotExistPage.scss";
import { Link } from "react-router-dom";

const NotExistPage: FC = () => {
  useEffect(() => {
    const cleanUpLogout = hideElement(".cr-header__inner-profile");
    const cleanUpFooter = hideElement("footer");

    return () => {
      cleanUpLogout();
      cleanUpFooter();
    };
  }, []);

  return (
    <div className="not-exist-page">
      <img className="not-exist-page__logo" src={logoSrc} alt="" />
      <h1>Запрашиваемая страница больше не существует</h1>
      <div className="not-exist-page__text">
        Рекомендуем перейти на <Link to="/">главную страницу</Link>.
      </div>
    </div>
  );
};

export default NotExistPage;
