import React, { Suspense, useContext, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";

import Modal from "../Modal";
import Game from "./Game/Game";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../types/State.interface";
import { AuthAction, AuthActionParam, AuthActionType, ComponentType } from "../../../../../services/sudirService";
import { AppContext } from "../../../../Root";
import GameService, { GameResult, GameStatus } from "../../../../../services/birthday-game.service";
import { ApiStatusCode } from "../../../../../types/Common.interface";
import Button from "../Button";

import "./BasketballGame.scss";
import useUpdateEffect from "../../../../../hooks/useUpdateEffect";

export interface BasketballGameController {
  startGame: () => void;
  stopGame: () => void;
}

interface BasketballGameProps {
  onInit?: (controller: BasketballGameController) => void;
  onStart?: () => void;
  onWin?: () => void;
  onClose?: () => void;
  onSessionBrokenOnStart?: () => void;
}

const BasketballGame = ({ onInit, onClose, onWin, onSessionBrokenOnStart }: BasketballGameProps) => {
  const appContext = useContext(AppContext);
  const user = useSelector((state: RootState) => state.user.userDetails);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [started, setStarted] = useState(false);
  const [won, setWon] = useState(false);
  const [lost, setLost] = useState(false);
  const [sessionBrokenOnStart, setSessionBrokenOnStart] = useState(false);

  const onSessionBrokenOnStartRef = useRef(onSessionBrokenOnStart);
  useEffect(() => {
    onSessionBrokenOnStartRef.current = onSessionBrokenOnStart;
  });

  useEffect(() => {
    onInit({ startGame, stopGame });
  }, [user]);

  useEffect(() => {
    if (user?.loggedIn) {
      GameService.status()
        .then((res) => {
          if (res.status === ApiStatusCode.OK) {
            if (res.data === GameStatus.INPROGRESS) {
              GameService.finish({ status: GameResult.LOST }).catch(console.log);
            }
          }
        })
        .catch(console.log);
    }
  }, [user]);

  useUpdateEffect(() => {
    if (!user?.loggedIn && sessionBrokenOnStart) {
      onSessionBrokenOnStartRef.current ? onSessionBrokenOnStartRef.current() : startGame();
    }
  }, [sessionBrokenOnStart, user?.loggedIn]);

  const startGame = () => {
    setSessionBrokenOnStart(false);

    if (!user?.loggedIn) {
      const thisContainerData: AuthActionParam = {
        component: ComponentType.GAME,
        type: AuthActionType.START_GAME,
        args: {},
      };

      const action = new AuthAction(thisContainerData);
      return appContext.sudirService.authWithActions([action]);
    }

    setLost(false);
    GameService.start()
      .then((res) => {
        if (res.status === ApiStatusCode.ACCESS_RIGHTS_ERROR) {
          setSessionBrokenOnStart(true);
          return;
        }

        setIsModalOpen(true);
        switch (res.status) {
          case ApiStatusCode.OK: {
            setStarted(true);
            break;
          }
          case ApiStatusCode.USER_ALREADY_WON: {
            setWon(true);
            break;
          }
        }
      })
      .catch(console.log);
  };

  const handleWin = () => {
    setWon(true);
    GameService.finish({ status: GameResult.WON })
      .then((res) => {
        if (res.status === ApiStatusCode.OK) {
          setWon(true);
          onWin();
        } else {
          console.log(res.message);
        }
      })
      .catch(console.log);
  };

  const stopGame = () => {
    setStarted(false);
    if (!lost) {
      setLost(true);
      GameService.finish({ status: GameResult.LOST })
        .then((res) => {})
        .catch(console.log);
    }
  };

  const renderWinContent = () => {
    return (
      <div className="basketball__win">
        <img src={require("../../../../../assets/leisure-landing/game/images/winner-cup.png").default} />
        <div className="basketball__game-title">Поздравляем!</div>
        <div className="basketball__game-subtitle">
          Вы забросили все мячи в корзину. <br></br>
          <a href="/Правила_участия_в_квесте.pdf" target="_blank">
            Баллы
          </a>{" "}
          за победу в игре будут начислены <br></br> пользователям с полной учетной записью <br></br> mos.ru.
        </div>
      </div>
    );
  };

  const renderLoseContent = () => {
    return (
      <div className="basketball__lose">
        <img src={require("../../../../../assets/leisure-landing/game/images/loser-smile.png").default} />
        <div className="basketball__game-title">Попробуйте еще раз!</div>
        <div className="basketball__game-subtitle">К сожалению, вам не удалось забросить все мячи.</div>
        <Button onClick={() => startGame()}>Начать игру</Button>
      </div>
    );
  };

  const handleModalClose = () => {
    stopGame();
    setIsModalOpen(false);
    onClose();
  };

  const renderGameStatus = useMemo(() => {
    if (started && !won && !lost) return <Game onWin={() => handleWin()} onTimeExpire={() => stopGame()} />;
    if (won) return renderWinContent();
    if (lost) return renderLoseContent();
    return <></>;
  }, [started, won, lost]);

  return (
    <div className="basketball">
      {isModalOpen && (
        <Modal
          className="leisure-landing__baketball-modal"
          onClose={() => handleModalClose()}
          withBackdrop={true}
          showCloseBtn
          closeOnOutside={false}
          closeOnEsc={true}
          content={renderGameStatus}
        />
      )}
    </div>
  );
};

export default BasketballGame;
