import { FC } from "react";
import { FloatingProps } from "./Floating.types";
import "./Floating.scss";

const Floating: FC<FloatingProps> = ({ isReverse = false, className, ...otherProps }) => {
  return (
    <div
      className={`leisure-landing-floating ${isReverse ? "leisure-landing-floating_reverse" : ""} ${className || ""}`}
      {...otherProps}
    />
  );
};

export default Floating;
