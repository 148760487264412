import { FC, useEffect, useRef, useState } from "react";
import { scripts, headScripts, stylesheets, Script } from "./config";
import { useDispatch } from "react-redux";
import { setLoadedResource } from "../../../../store/actions/ResourceActions";

const ResourceLoader: FC = ({ children }: any) => {
  const dispatch = useDispatch();

  const [loaded, setLoaded] = useState({});
  const [dependentScripts, setDependentScripts] = useState(headScripts.filter((hs: any) => hs.dependsOn?.length));

  useEffect(() => {
    dependentScripts.forEach((script: any) => {
      if (script.dependsOn.every((src) => loaded[src])) {
        invokeHeadScript(script);
        setDependentScripts(dependentScripts.filter((ds: any) => ds.src !== script.src));
      }
    });
  }, [loaded]);

  const invokeHeadScript = (script) => {
    const headScript = document.createElement("script");
    headScript.defer = false;
    headScript.innerHTML = script.html;
    getHeadElement().appendChild(headScript);
    return headScript;
  };

  useEffect(() => {
    const scriptElements = scripts.map((_script: Script) => {
      const script = document.createElement("script");
      script.addEventListener("load", handleScriptLoad);
      script.defer = _script.defer || false;
      script.async = _script.async || false;
      script.src = _script.src;
      return script;
    });

    scriptElements.forEach((element) => {
      getHeadElement().appendChild(element);
    });

    return () => {
      scriptElements.forEach((element) => {
        getHeadElement().removeChild(element);
      });
    };
  }, [scripts]);

  //////////////////////

  useEffect(() => {
    const stylesheetElements = stylesheets.map((stylesheetSrc) => {
      const link = document.createElement("link");
      link.href = stylesheetSrc;
      link.rel = "stylesheet";
      link.type = "text/css";
      return link;
    });

    stylesheetElements.forEach((element) => {
      getHeadElement().appendChild(element);
    });

    return () => {
      stylesheetElements.forEach((element) => {
        getHeadElement().removeChild(element);
      });
    };
  }, [stylesheets]);

  const getHeadElement = () => document.head || document.getElementsByTagName("head")[0];

  const handleScriptLoad = (script) => {
    setLoaded((state) => {
      const _state = { ...state };
      _state[script.currentTarget.src] = true;
      return _state;
    });
    dispatch(setLoadedResource({ type: "script", payload: { src: script.currentTarget.src } }));
  };

  return <>{children}</>;
};

export default ResourceLoader;
