import { type } from "os";
import React from "react";

import Button from "../../../presentational/Controls/Button/Button";

import "./SendRestoreEmailForm.scss";

type Props = {
  onSubmit: Function;
  isLoading?: boolean;
  error?: string;
};

type State = {
  email: string;
  [key: string]: any;
};
class SendRestoreEmailForm extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
    };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleSubmit = (event) => {
    event && event.preventDefault();

    this.props.onSubmit({
      email: this.state.email,
    });
  };

  render() {
    return (
      <div className="restore-password-form">
        <form onSubmit={(e) => this.handleSubmit(e)}>
          <p>
            Пожалуйста, укажите адрес электронной почты, который вы использовали
            при регистрации на портал.
          </p>
          <input
            type="email"
            name="email"
            value={this.state.email}
            onChange={this.handleChange}
            placeholder="Email"
            className="restore-password-form__input"
          />

          <p className="restore-password-form__error"> {this.props.error}</p>

          <Button
            type="outlined"
            text="Отправить"
            isLoading={this.props.isLoading}
            onClick={(e) => this.handleSubmit(e)}
          />
        </form>
      </div>
    );
  }
}

export default SendRestoreEmailForm;
