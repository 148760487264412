import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import SubmitForm from "../SubmitForm/SubmitForm";
import Input from "../../../presentational/Controls/Input/Input";
import Select from "../../../presentational/Controls/Select/Select";
import Textarea from "../../../presentational/Controls/Textarea/Textarea";
import FileSelector from "../../../presentational/Controls/FileSelector/FileSelector";
import DynamoList from "../../../presentational/Controls/DynamoList/DynamoList";
import Radio from "../../../presentational/Controls/Radio/Radio";
import Checkbox from "../../../presentational/Controls/Checkbox/Checkbox";
import Map from "../../../presentational/Map/Map";
import ProposeService from "../../../../services/proposeService";
import Search, { SearchStyleType } from "../../../presentational/Controls/Search/Search";
import { hideInfoModal, showInfoModal } from "../../../../store/actions/LayoutActions";
import { ApiStatusCode } from "../../../../types/Common.interface";

import "./ProposeIdeaForm.scss";
import Button from "../../../presentational/Controls/Button/Button";
import { RootState } from "../../../../types/State.interface";
import { getUser } from "../../../../store/actions/UserActions";
import GorodService, { GorodIdeaCategory, GorodIdeaSphere } from "../../../../services/gorodService";
import { User } from "../../../../types/User.interface";
import EventsTrackClickCheckbox from "../../EventsTrack/wrappers/helpers/EventsTrackClickCheckbox";
import EventsTrackClickTextarea from "../../EventsTrack/wrappers/helpers/EventsTrackClickTextarea";
import EventsTrackClickInput from "../../EventsTrack/wrappers/helpers/EventsTrackClickInput";
import EventsTrackClickFileSelectorLabel from "../../EventsTrack/wrappers/helpers/EventsTrackClickFileSelectorLabel";
import EventsTrackClickSelectInput from "../../EventsTrack/wrappers/helpers/EventsTrackClickSelectInput";
import EventsTrackClickSelectItem from "../../EventsTrack/wrappers/helpers/EventsTrackClickSelectItem";
import { LocationInfo } from "../../../../services/mapService";
import { createPortal } from "react-dom";
import { useForceUpdate } from "../../../../hooks/useForceUpdate";
import { displayError } from "../../../../utils";

const maxAllowedNameLength = 90;
const maxAllowedDescriptionLength = 300;
const maxFileSizeMB = 5;
const maxTotalSizeMB = 25;
const maxFiles = 5;

interface ProposeIdeaFormProps {
  sphere?: GorodIdeaSphere;
  spheresList?: GorodIdeaSphere[];
  category?: GorodIdeaCategory | any;
  isOpen?: boolean;
  onClose?: () => void;
  setIsSubmittedProposeModalOpen: Function;
  onSuccessSubmitData?: (data: any) => void;
  fromLanding?: boolean;
}



const ProposeIdeaForm = (props: ProposeIdeaFormProps) => {
  const dispatch = useDispatch();

  const spheres: GorodIdeaSphere[] = useSelector((state: RootState) => state.gorod.spheres);
  const categories = useSelector((state: RootState) => state.propose.categories);

  const ideaMapRef = useRef(null);
  const nameInputRef = useRef(null)
  const forceUpdate = useForceUpdate();

  const regions = useSelector((state: RootState) => state.address.regions);
  const districts = useSelector((state: RootState) => state.address.districts);
  const userDetails = useSelector((state: RootState) => state.user.userDetails);

  const [sphere, setSphere] = useState<GorodIdeaSphere>(props.sphere || null);
  const [category, setCategory] = useState(props.category || null);

  const [ideaTitle, setIdeaTitle] = useState("");
  const [nameValidator, setNameValidator] = useState<any>(null);
  const [description, setDescription] = useState("");
  const [descriptionValidator, setDescriptionValidator] = useState<any>(null);
  const [images, setImages] = useState([]);
  const [exact, setExact] = useState(canSpecifyExactOnly(category));
  const [exactAddress, setExactAddress] = useState<LocationInfo>(null);
  const [mapAddress, setMapAddress] = useState("");
  const [region, setRegion] = useState<any>(null);
  const [district, setDistrict] = useState("");
  const [agreed, setAgreed] = useState(false);
  const [agreementConfirmed, setAgreementConfirmed] = useState<boolean | undefined>(false);
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [mapErr, setMapErr] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [selectedKeyword, setSelectedKeyword] = useState("");
  const [keywords, setKeywords] = useState([]);
  const [filteredKeywords, setFilteredKeywords] = useState([]);
  const [categoryData, setCategoryData] = useState(null);
  const [sphereSelectedByKeyword, setSphereSelectedByKeyword] = useState<GorodIdeaSphere>(null)
  const [categorySelectedByKeyword, setCategorySelectedByKeyword] = useState<GorodIdeaCategory>(null)
  const [showKeywordSection, setShowKeywordSection] = useState(false)

  useEffect(() => {
    setShowKeywordSection(props.sphere? false : true)
   }, [])

  useEffect(() => {
    forceUpdate();
  }, [exact])

  useEffect(() => {
    if (sphere && sphereSelectedByKeyword) {
      if(sphere.id !== sphereSelectedByKeyword.id) {
        setSearchValue("");
        setSelectedKeyword("");
      }
    }
  }, [sphere]);

  useEffect(() => {
    if (category && categorySelectedByKeyword) {
      if (category?.id !== categorySelectedByKeyword.id) {
        setSearchValue("");
        setSelectedKeyword("");
      }
    }
  }, [category]);

  useEffect(() => {
    const fetchKeywords = async () => {
      try {
        const data = await ProposeService.getKeywords();
        setKeywords(data?.data);
      } catch (error: any) {
        displayError('Ошибка при получении ключевых слов', error.message)
      }
    }
    fetchKeywords();
  }, [])

  useEffect(() => {
    const findCategoryByKeyword = async () => {
      if (selectedKeyword) {
        try {
          const response = await ProposeService.getCategoryByKeyword(selectedKeyword);
          setCategoryData(response?.data);
        } catch (error) {
          console.error(error);
        }
      }
    };
    findCategoryByKeyword();
  }, [selectedKeyword]);

  useEffect(() => {
    if (categoryData) {
      const foundSphere: GorodIdeaSphere = findSphereById(categoryData);
      handleSphereSelect(foundSphere)
      setSphereSelectedByKeyword(foundSphere)
      setCategorySelectedByKeyword(categoryData)
      onCategorySelect(categoryData)
    }
  }, [categoryData, spheres]);
  
  useEffect(() => {
    setFilteredKeywords(keywords.filter((item) => {
      return item.keyWord.toLowerCase().includes(searchValue.toLowerCase());
    }));
  }, [searchValue, keywords]) // 

  const findSphereById = (category) => {
    if (spheres && category && category.groupId) {
      const groupId = category.groupId;
      return spheres.find(sphere => sphere.id === groupId);
    }
    return null;
  }


  // useEffect(() => setAgreementConfirmed(userDetails?.agreementConfirmed), [userDetails]); // закомменчено так как запрашивать agreement нужно для каждой идеи

  const submitForm = async (e) => {
    if (e) e.preventDefault();

    setSubmitted(true);

    if (formValid()) {
      setSubmitting(true);

      const formData = {};
      formData["category"] = category.code;
      formData["description"] = description;
      formData["type"] = "IDEA";
      formData["attachments"] = formAttachments(images);
      formData["attributes"] = formAttributes(exact, ideaTitle, region, district, exactAddress);
      try {
        const data = await ProposeService.sendProposition(formData, props.fromLanding);

        if (data) {
          if (data.status === ApiStatusCode.OK) {
            await sendAgreementConfirmed();
            invalidate();
            props.onSuccessSubmitData?.(data.data);
            props.setIsSubmittedProposeModalOpen(true);
            props.onClose();
          } else if (data.status === ApiStatusCode.GOROD_IDEA_EXISTS) {
            dispatch(
              showInfoModal("Идея с данным текстом уже была подана ранее. Необходимо изменить текст.", renderOkButton())
            );
          } else {
            dispatch(showInfoModal(data.message, renderOkButton(), props.onClose()));
          }
        }
      } catch (error) {
        props.onClose();
      }

      setSubmitting(false);
    }
  };
  const sendAgreementConfirmed = async () => {
    if (!agreementConfirmed) {
      const data = await ProposeService.sendAgreementConfirmed();
      if (data?.status === ApiStatusCode.OK) {
        setAgreementConfirmed(true);
        dispatch(getUser());
      }
    }
  };

  // Move logic to propose form in order to just call form.invalidate()
  const invalidate = () => {
    setIdeaTitle("");
    setDescription("");
    setImages([]);
    setCategory(null);
    setExact(false);
    setExactAddress(null);
    setMapAddress("");
    setRegion("");
    setDistrict("");
    setAgreed(false);
    setSubmitted(false);
    setSubmitting(false);
    setSelectedKeyword(null);
  };

  const formAttachments = (images) => {
    const getCleanBase64 = (str) => {
      return str.substring(str.indexOf(",") + 1);
    };

    return images.map((image) => {
      return {
        data: getCleanBase64(image.preview),
        description: image.file.name,
        tags: image.file.name,
        type: image.file.type,
      };
    });
  };

  const formAttributes = (exact, ideaTitle, region, district, exactAddress) => {
    const attrs = canSpecifyAddress(category)
      ? exact
        ? [
            { id: "ADDRESS", value: exactAddress.address },
            { id: "UNOM", value: exactAddress.unom },
            { id: "PLACE", value: 103 },
          ]
        : [
            { id: "REGION", value: region.externalId },
            ...(district && district.externalId ? [{ id: "DISTRICT", value: district.externalId }] : []),
            { id: "PLACE", value: 102 },
          ]
      : [{ id: "PLACE", value: "CITY" }];

    return [...attrs, { id: "NAME", value: ideaTitle }];
  };

  const getDistricts = () => {
    if (region && districts) {
      return districts.filter((d) => d.regionId === region.id);
    }

    return [];
  };

  const formValid = () => {
    return (
      category &&
      nameValidator.isValid() &&
      descriptionValidator.isValid() &&
      (canSpecifyAddress(category) ? (exact ? exactAddress && !mapErr : region) : true) &&
      (agreementConfirmed ? true : agreed)
    );
  };

  const canSpecifyAddress = (category: GorodIdeaCategory) => {
    if (!category) return;

    return !GorodService.isCityPlace(category);
  };

  function canSpecifyRegion(category: GorodIdeaCategory) {
    return GorodService.isRegionPlace(category);
  }

  function canSpecifyExact(category: GorodIdeaCategory) {
    return GorodService.isAddressPlace(category);
  }

  function canSpecifyExactOnly(category: GorodIdeaCategory) {
    return canSpecifyExact(category) && !canSpecifyRegion(category);
  }

  const onCategorySelect = (category: GorodIdeaCategory) => {
    setCategory(category);
    if (canSpecifyExactOnly(category)) {
      setExact(true);
    } else {
      setExact(false);
    }
  };


  const handleKeywordSelect = (keyword) => {
    setSelectedKeyword(keyword);
    setSearchValue(keyword);
    if (nameInputRef.current) {
      nameInputRef.current.focus()
    }
  }

  const handleSphereSelect = (sphere: GorodIdeaSphere) => {
    setSphere(sphere);
  }

  //RENDER

  const renderOkButton = () => {
    return <Button type="outlined" text="Хорошо" onClick={() => dispatch(hideInfoModal())} />;
  };

  const handleIdeaTitleChange = (value) => {
    setIdeaTitle(value)
  }


  const renderContent = () => {
    return (
      <div className="propose-idea-form">
        {showKeywordSection && (
          <>
            <p className="cr-search__label">Укажите ключевое слово, характеризующее идею</p>
            <Search
              isWithList={false}
              placeholder="Укажите ключевое слово, характеризующее идею"
              items={filteredKeywords.map(item => item.keyWord)}
              value={searchValue}
              onChange={setSearchValue}
              onItemSelect={handleKeywordSelect}
              isMultiplePerRow={false}
              isCloseOnSelect={true}
              autocomplete={false}
              replaceInputOnMouseEnter={true}
            />
          </>
        )}

        <Select
          value={sphere?.name}
          items={props.spheresList || spheres}
          closeOnSelect={true}
          bindTo="name"
          label={showKeywordSection ? "Или выберите сферу" : "Выберите сферу"}
          isRequired={true}
          isInvalid={submitted && !sphere}
          renderAsHtml
          onItemSelect={handleSphereSelect}
          ComponentSelectInput={EventsTrackClickSelectInput}
          ComponentSelectItem={EventsTrackClickSelectItem}
          placeholder={'Выберите сферу'}
        />

        {sphere && (
          <React.Fragment>
            <Select
              value={category?.title}
              items={sphere.categories?.filter((c) => c.active)}
              closeOnSelect={true}
              bindTo="title"
              label="Выберите тему"
              isRequired={true}
              isInvalid={submitted && !category}
              onItemSelect={onCategorySelect}
              ComponentSelectInput={EventsTrackClickSelectInput}
              ComponentSelectItem={EventsTrackClickSelectItem}
            />
            {category && <p className="propose-idea-form__catdesc">{category.description}</p>}
          </React.Fragment>
        )}

        <Input
          inputRef={nameInputRef}
          value={ideaTitle}
          label="Придумайте название идеи"
          placeholder="Введите текст"
          maxLength={maxAllowedNameLength}
          isRequired={true}
          spellCheck={true}
          onChange={handleIdeaTitleChange}
          validateRules={{
            minLength: 3,
            maxLength: 90,
            mustContainLetters: true,
            restrictForeignSymbByPercent: 30,
            restrictUppercaseSymbByPercent: 30,
          }}
          onInitValidator={(validator) => setNameValidator(validator)}
          showError={submitted}
          ComponentInput={EventsTrackClickInput}
        />

        <Textarea
          value={description}
          label="Опишите идею"
          placeholder="Опишите идею"
          maxLength={maxAllowedDescriptionLength}
          onlyVerticalResize={true}
          hint="Пожалуйста, придерживайтесь правила: одна идея – одна заполненная форма. Текст идеи не должен превышать 300 символов."
          isRequired={true}
          spellCheck={true}
          onChange={(value) => setDescription(value)}
          validateRules={{
            minLength: 10,
            maxLength: maxAllowedDescriptionLength,
            mustContainLetters: true,
            restrictForeignSymbByPercent: 30,
            restrictUppercaseSymbByPercent: 30,
          }}
          onInitValidator={(validator) => setDescriptionValidator(validator)}
          showError={submitted}
          ComponentTextarea={EventsTrackClickTextarea}
        />

        <FileSelector
          name="Добавить файл"
          text={`Вы можете загрузить до ${maxFiles} изображений в формате *jpeg, *jpg, *png. Максимальный размер одного изображения – ${maxFileSizeMB} Мб. Совокупный размер всех изображений — не более ${maxTotalSizeMB} Мб.`}
          isMultiple={true}
          allowedExtensions={["image/jpeg", "image/jpg", "image/png"]}
          maxFileSizeMB={maxFileSizeMB}
          maxTotalSizeMB={maxTotalSizeMB}
          maxFiles={maxFiles}
          onChange={(images) => setImages(images)}
          ComponentLabel={EventsTrackClickFileSelectorLabel}
        />

        {canSpecifyAddress(category) && (
          <React.Fragment>
            <p className="propose-idea-form__paragraph">
              Укажите, где необходимо реализовать идею
              <span className="propose-idea-form__paragraph-require">*</span>
            </p>

            {!canSpecifyExactOnly(category) && (
              <div className="propose-idea-form__radios">
                <Radio
                  changed={() => {
                    setExactAddress(null);
                    setExact(false);
                  }}
                  id="proposeFormRegion"
                  isSelected={!exact}
                  label="Округ/район"
                  value="region"
                />

                <Radio
                  changed={() => setExact(true)}
                  id="proposeFormExact"
                  isSelected={exact}
                  label="Точный адрес"
                  value="exact"
                />
              </div>
            )}

            {!exact ? (
              <div className="propose-idea-form__selects">
                <Select
                  items={regions}
                  bindTo="name"
                  closeOnSelect={true}
                  label="Выберите округ"
                  isRequired={true}
                  onItemSelect={(region) => {
                    setRegion(region);
                    setDistrict("");
                  }}
                  isInvalid={(submitted && !region) || false}
                />
                <Select
                  items={getDistricts()}
                  bindTo="name"
                  closeOnSelect={true}
                  label="Выберите район"
                  isRequired={false}
                  isDisabled={!getDistricts()?.length}
                  onItemSelect={(district) => setDistrict(district)}
                />
              </div>
            ) : (
              <>
                <DynamoList
                  placeholder="Начните вводить адрес"
                  value={mapAddress}
                  endpoint="/api/address/search?query="
                  debounceTime={500}
                  bindTo="simpleAddress"
                  onChange={(location: LocationInfo) => {
                    setMapErr("");
                    setExactAddress(location);
                  }}
                  error={mapErr}
                  onError={(err) => setMapErr(err)}
                  isInvalid={(submitted && (!exactAddress || !!mapErr)) || false}
                />

                <p className="propose-idea-form__paragraph">Выбранный адрес на карте:</p>

                <div className="test" ref={ideaMapRef}></div>
                {ideaMapRef.current &&
                  createPortal(
                    <Map
                      address={exactAddress?.address || exactAddress}
                      onClick={(location: LocationInfo) => setMapAddress(location.simpleAddress)}
                      onError={(err) => setMapErr(err)}
                    />,
                    ideaMapRef.current
                  )}
              </>
            )}
          </React.Fragment>
        )}

        <Checkbox
          html={`Я ознакомился с <a data-click-id='[39]' data-replace='${JSON.stringify([
            "Правилами модерации",
          ])}' href='/ideaModeration' target='_blank'>Правилами модерации</a>`}
          onChange={(value) => setAgreed(value)}
          isInvalid={(submitted && !agreed) || false}
          ComponentCheckbox={EventsTrackClickCheckbox}
        />
      </div>
    );
  };
  return (
    <>
      <SubmitForm
        user={userDetails}
        isLoading={loading}
        isSubmitting={submitting}
        isOpen={props.isOpen}
        title="Предложить идею"
        top={true}
        onClose={props.onClose}
        onSubmit={submitForm}
      >
        {renderContent()}
      </SubmitForm>
    </>
  );
};

export default ProposeIdeaForm;
