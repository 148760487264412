import axios from "axios";
import React, { useEffect, useState } from "react";

import Button from "../components/presentational/Controls/Button/Button";
import { store } from "../store/store";
import {
  hideInfoModal,
  showInfoModal,
  showMaintenanceModal,
  showMosruForm,
} from "../store/actions/LayoutActions";
import { logout } from "../store/actions/UserActions";
import { ApiStatusCode } from "../types/Common.interface";

export const API = axios.create({
  headers: {
    Accept: "application/json",
  },
  timeout: 100000,
});

API.interceptors.response.use(
  (res) => {
    if (res.data.status === ApiStatusCode.SIGNED_OUT) {
      store.dispatch(logout());
    }

    if (res.data.status === ApiStatusCode.ACCESS_RIGHTS_ERROR) {
      store.dispatch(logout());
    }

    return res;
  },
  (err) => {
    //handle 404
    if (err.toString().includes("404")) {
      return;
    }

    //No backend
    if (err.toString().includes("504")) {
      return (document.location.href =
        document.location.origin + "/unreachable.html");
    }

    //handle 502
    if (err.toString().includes("502")) {
      document.location.href = document.location.origin + "/construction.html";
      return;
    }

    const renderOkButton = () => {
      return (
        <Button
          type="outlined"
          text="Хорошо"
          onClick={() => store.dispatch(hideInfoModal())}
        />
      );
    };

    console.error(err);

    store.dispatch(
      showInfoModal(
        "При обращении к серверу произошла ошибка. Пожалуйста, повторите попытку позже.",
        renderOkButton()
      )
    );

    // store.dispatch(showMaintenanceModal());
  }
);

export function httpParamsSerialize(params) {
  if (!params) return "";
  const esc = encodeURIComponent;
  let query = Object.keys(params)
    .map((k) => esc(k) + "=" + esc(params[k]))
    .join("&");

  return query;
}
