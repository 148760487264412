import React, { ReactElement, useContext, useEffect, useMemo, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import SwiperCore from "swiper";

import CommonSlider from "../../../presentational/Sliders/CommonSlider/CommonSlider";
import { phoneWidth, tabletWidth } from "../../../../utils/constants/widthConstants";
import NewsCard from "../../../presentational/Cards/NewsCard/NewsCard";

import "./NewsSection.scss";
import EventsTrackParentIdContext from "../../../containers/EventsTrack/EventsTrackParentIdContext";
import EventsTrackWrapperScroll from "../../../containers/EventsTrack/wrappers/EventsTrackWrapperScroll";
import EventsTrackWrapperClick from "../../../containers/EventsTrack/wrappers/EventsTrackWrapperClick";
import { News } from "../../../../types/News.interface";
import NewsService from "../../../../services/newsService";
import { ApiStatusCode } from "../../../../types/Common.interface";

const NewsSection = () => {
  const history = useHistory();
  const isTablet = useMediaQuery({ query: `(max-width: ${tabletWidth}px)` });
  const isPhone = useMediaQuery({ query: `(max-width: ${phoneWidth}px)` });

  const [news, setNews] = useState<News[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [hasMore, setHasMore] = useState<boolean>(true);

  useEffect(() => {
    if (loading || !hasMore) return;

    const fetchNews = async () => {
      setLoading(true);
      try {
        const query = {
          page,
          size: 10,
          sort: "published,desc",
        };
        const response = await NewsService.getNews(query);
        if (response.status === ApiStatusCode.OK) {
          setNews((prev) => [...prev, ...response.data]);
          if (response.paging.pageNumber + 1 >= response.paging.totalPages) {
            setHasMore(false);
          }
        }
      } catch (error) {
        console.error("Ошибка при получении новостей:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchNews();
  }, [page]);

  const handleSlideChange = (sw: SwiperCore) => {
    if (sw.isEnd) {
      setPage((prev) => prev + 1);
    }
  };

  const getClasslistForSlider = () => {
    let classlist = "news-section__slider";
    if (loading) {
      classlist += " news-section__slider--loading";
    }
    return classlist;
  };

  const newsSlider = useMemo(() => {
    if (!news) return;

    return (
      <CommonSlider
        className={getClasslistForSlider()}
        options={{
          loop: false,
          spaceBetween: 24,
          slidesPerView: isPhone ? 1 : isTablet ? 3 : 4,
          slidesPerColumn: 1,
          slidesPerGroup: 1,
          centerInsufficientSlides: true,
        }}
        linkOptions={{ text: "Посмотреть все", linkUrl: "/news" }}
        onSlideChange={handleSlideChange}
      >
        {news.map((news, idx) => (
          <NewsCard
            key={idx}
            id={idx + ""}
            element={news}
            href={(n) => "/news/" + n.number}
            linkRel="canonical"
            onTagClick={(n) =>
              history.push({
                pathname: "/news",
                search: `page=1&tags=${n.tag}`,
                state: { n },
              })
            }
          />
        ))}
      </CommonSlider>
    );
  }, [news, loading, isTablet, isPhone]);

  return (
    <>
      <EventsTrackParentIdContext.Provider value="BLOCK_NEWS">
        <EventsTrackWrapperScroll id={6}>
          <div className="news-section">
            <div className="news-section__description">
              <EventsTrackWrapperClick id={["BLOCK_NEWS", 183]} replace={["Новости"]}>
                <Link to={`/news`} className="mein__news-head-title undecorated-link">
                  Новости
                </Link>
              </EventsTrackWrapperClick>
            </div>

            {newsSlider}
          </div>
        </EventsTrackWrapperScroll>
      </EventsTrackParentIdContext.Provider>
    </>
  );
};

export default NewsSection;
