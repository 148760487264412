import React, { ReactElement, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { RootState } from "../../../../types/State.interface";
import EventsTrackWrapperScroll from "../../../containers/EventsTrack/wrappers/EventsTrackWrapperScroll";
import EventsTrackWrapperClick from "../../../containers/EventsTrack/wrappers/EventsTrackWrapperClick";
import { declOfNum } from "../../../../utils";

import "./AboutSection.scss";

const AboutSection = (props: any) => {
  const totalStatistics = useSelector((state: RootState) => state.totalStatistics.stats);

  return (
    <>
      {totalStatistics && (
        <EventsTrackWrapperScroll id={4}>
          <div className="about-platform">
            <div className="about-platform__description">
              <EventsTrackWrapperClick id={170}>
                <Link to={`/about`} className="about-platform__title undecorated-link">
                  О платформе
                </Link>
              </EventsTrackWrapperClick>

              <div className="about-platform__text">
                Платформа Правительства Москвы «Город идей» дает возможность жителям предлагать идеи по развитию города.
              </div>
            </div>

            <div className="about-platform__stats">
              <div className="about-platform__stat">
                <span className="about-platform__count">
                  {totalStatistics.registrationCount?.toLocaleString("ru-RU")}
                </span>
                <span className="about-platform__name">
                  {declOfNum(totalStatistics.registrationCount || 0, ["участник", "участника", "участников"])}
                </span>
              </div>
              <div className="about-platform__stat">
                <Link to={"/propose"}>
                  <span className="about-platform__count">{totalStatistics.categoryCount || 0}</span>
                  <span className="about-platform__name">
                    {declOfNum(totalStatistics.categoryCount || 0, ["тема", "темы", "тем"])} для подачи <br></br> идей
                  </span>
                </Link>
              </div>
              <div className="about-platform__stat">
                <EventsTrackWrapperClick id={171}>
                  <a href="/archive">
                    <span className="about-platform__count">
                      {totalStatistics.archivedProjectCount?.toLocaleString("ru-RU")}
                    </span>
                    <span className="about-platform__name">
                      {declOfNum(totalStatistics.archivedProjectCount || 0, ["выполненный", "выполненных", "выполненных"])} <br></br> {declOfNum(totalStatistics.archivedProjectCount || 0, ["проект", "проекта", "проектов"])}
                    </span>
                  </a>
                </EventsTrackWrapperClick>
              </div>
              <div className="about-platform__stat">
                <Link to={"/ideas"}>
                  <span className="about-platform__count">
                    {totalStatistics.realizedIdeaCount
                      ? totalStatistics.realizedIdeaCount?.toLocaleString("ru-RU")
                      : totalStatistics.realizedIdeaCountSetting?.toLocaleString("ru-RU")}
                  </span>
                  <span className="about-platform__name">
                  {declOfNum(totalStatistics.realizedIdeaCount || totalStatistics.realizedIdeaCountSetting || 0, [
                      "идея",
                      "идеи",
                      "идей",
                    ])}
                    {" "}
                    <br></br>

                    {declOfNum(totalStatistics.realizedIdeaCount || totalStatistics.realizedIdeaCountSetting || 0, [
                      "реализована",
                      "реализованы",
                      "реализовано",
                    ])}
                  </span>
                </Link>
              </div>
            </div>
          </div>
        </EventsTrackWrapperScroll>
      )}
    </>
  );
};

export default AboutSection;
