import { FC } from "react";
import { InteractiveProps } from "./Interactive.types";
import "./Interactive.scss";

const Interactive: FC<InteractiveProps> = ({
  interactiveMap,
  className,
  src,
  srcBorder,
  onClick,
  isActive = false,
  type,
}) => {
  const maskImgBorder = `url(${srcBorder})`;

  return (
    <div
      className={`interactive interactive_${type} ${isActive ? "interactive_active" : ""} ${
        !!interactiveMap ? "interactive_disabled" : ""
      } ${className || ""}`}
      onClick={onClick}
    >
      {!!interactiveMap &&
        interactiveMap.map(({ top, left, width, height }, i) => (
          <div key={i} className="interactive__wrapper-map" style={{ top, left, width, height }} />
        ))}
      <img src={src} alt="" />
      <div className="interactive__border" style={{ maskImage: maskImgBorder, WebkitMaskImage: maskImgBorder }} />
    </div>
  );
};

export default Interactive;
