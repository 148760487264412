import React from "react";
import { ProjectInfo } from "../../../types/Banner.interface";
import { declOfNum, getGroupDirectionIdeasCount } from "../../../utils";

import "./ProgressCounter.scss";
import { RealizedIdeaProjectGroupDirection } from "../../../types/GroupDirection.interface";

export interface ProgressCountProps {
  project: ProjectInfo;
  projectGroupDirections?: {
    waiting?: RealizedIdeaProjectGroupDirection;
    realized?: RealizedIdeaProjectGroupDirection;
  };
}

const ProgressCounter = ({ project, projectGroupDirections }: ProgressCountProps) => {
  const getProgressTitle = (
    project: ProjectInfo,
    projectGroupDirections?: ProgressCountProps["projectGroupDirections"]
  ) => {
    let waitingRealizedIdeaCount, realizedIdeaCount;
    if (projectGroupDirections) {
      waitingRealizedIdeaCount = getGroupDirectionIdeasCount(projectGroupDirections.waiting);
      realizedIdeaCount = getGroupDirectionIdeasCount(projectGroupDirections.realized);
    } else {
      waitingRealizedIdeaCount = project.waitingRealizedIdeaCount;
      realizedIdeaCount = project.realizedIdeaCount;
    }

    const totalCount = waitingRealizedIdeaCount + realizedIdeaCount;

    const isOnlyAccepted = waitingRealizedIdeaCount && !realizedIdeaCount;
    const isSomeRealized = waitingRealizedIdeaCount && realizedIdeaCount;
    const isAllRealized = realizedIdeaCount && realizedIdeaCount === totalCount;

    if (isOnlyAccepted) {
      return `<span>${waitingRealizedIdeaCount}</span> ${declOfNum(waitingRealizedIdeaCount, [
        "идея",
        "идеи",
        "идей",
      ])} ${declOfNum(waitingRealizedIdeaCount, ["принята", "принято", "принято"])} в реализацию`;
    }
    if (isSomeRealized) {
      return `<span>${realizedIdeaCount}</span> ${declOfNum(realizedIdeaCount, [
        "идея",
        "идеи",
        "идей",
      ])} из <span>${totalCount}</span> ${declOfNum(realizedIdeaCount, ["реализована", "реализованы", "реализовано"])}`;
    }
    if (isAllRealized) {
      return `<span>${realizedIdeaCount}</span> ${declOfNum(realizedIdeaCount, ["идея", "идеи", "идей"])} ${declOfNum(
        realizedIdeaCount,
        ["реализована", "реализованы", "реализовано"]
      )}`;
    }

    return "Информация появится позже";
  };

  return (
    <div
      className="progress-count"
      dangerouslySetInnerHTML={{ __html: project && getProgressTitle(project, projectGroupDirections) }}
    ></div>
  );
};

export default ProgressCounter;
