import { FC, useEffect, useState } from "react";
import "./RatingCard.scss";
import { IdeaCategory } from "../Rating";
import DiscussionLandingService from "../../../services/discussion-service";
import { ApiStatusCode } from "../../../../../../types/Common.interface";
import { displayError } from "../../../../../../utils";

type RatingCardProps = IdeaCategory;
export interface ProposedIdea {
  id: string;
  number: number;
  author: string;
  ideaText: string;
  categoryId: string;
  className?: string;
}

const RatingCard: FC<RatingCardProps> = ({ id, name, link, imageId, className}) => {
  const [proposedIdeas, setProposedIdeas] = useState<ProposedIdea[]>([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await DiscussionLandingService.getIdeaByByCategoryIds([id]);

      if (response.status === ApiStatusCode.OK) {
        setProposedIdeas(response.data.sort((a, b) => a.number - b.number).slice(0, 5));
      } else {
        displayError("Ошибка при получении идей для категории", response.message);
      }
    } catch (error) {
      displayError("RatingCard", "Ошибка при получении идей для категории");
    }
  };

  return (
    <div className={`rating-card ${className}`}>
      <div className="rating-card__project">
        <div className="rating-card__img">
          <img src={`/uploads/get/${imageId}`} alt="" />
        </div>
        <div className="rating-card__aside">
          <p className="rating-card__title">{name}</p>
          {link && (
            <a className="rating-card__link" href={link}>
              Итоги проекта
            </a>
          )}
        </div>
      </div>

      <span className="rating-card__splitter"></span>

      {proposedIdeas.length ? (
        <div className="rating-card__authors">
          {proposedIdeas.map((proposedIdea) => {
            return (
              <div className="author rating-card__author" key={proposedIdea.id}>
                <div className="author__order">{proposedIdea.number}</div>
                <div className="author__info">
                  <p className="author__name">{proposedIdea.author}</p>
                  <p className="author__idea">{proposedIdea.ideaText}</p>
                </div>
              </div>
            );
          })}
        </div>
      ) : null}
    </div>
  );
};
export default RatingCard;
