import { PaginationResponse } from "../types/Common.interface";
import { News } from "../types/News.interface";
import { queryBuilder } from "../utils/url";
import { API } from "./api";

interface GetNewsQuery {
  page: number;
  size: number;
  sort: string;
}

const NewsService = {
  getNews: (query: GetNewsQuery) => {
    return API.get<PaginationResponse<News[]>>(`/api/news/list${queryBuilder(query)}`).then(({ data }) => {
      return data;
    });
  },

  getNewsById: (id) => {
    return API.get("/api/news/getByNumber?number=" + id).then(({ data }) => {
      return data;
    });
  },
};

export default NewsService;
