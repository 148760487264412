export const RESOURCE_LOADED = "RESOURCE_LOADED";

export interface Resource {
  type: "script" | "stylesheet";
  payload: ResourcePayload;
}

interface ResourcePayload {
  src: string;
}

export const setLoadedResource = (resource) => (dispatch) => {
  return dispatch({
    type: RESOURCE_LOADED,
    resource,
  });
};
