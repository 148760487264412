import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Button from "../../presentational/Controls/Button/Button";
import {
  showInfoModal,
  hideInfoModal,
} from "../../../store/actions/LayoutActions";
import "./FailedEmailConfirmation.scss";

const FailedEmailConfirmation = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    const message = `Данная ссылка недействительна. <br> Срок ее действия истек, или она уже была активирована.`;
    dispatch(showInfoModal(message, renderOkButton()));
  }, []);

  const renderOkButton = () => {
    return (
      <Button
        type="outlined"
        text="Закрыть"
        onClick={() => {
          dispatch(hideInfoModal());
          history.push("/");
        }}
      />
    );
  };

  return <section className="failed-email"></section>;
};

export default FailedEmailConfirmation;
