import React, { useEffect, useState } from "react";

import "./BirthdayMain.scss";

const achiveImgs = [
  require("../../../../../assets/birthday9/images/main/main-bg-item1.png")
    .default,
  require("../../../../../assets/birthday9/images/main/main-bg-item2.png")
    .default,
  require("../../../../../assets/birthday9/images/main/main-bg-item3.png")
    .default,
  require("../../../../../assets/birthday9/images/main/main-bg-item4.png")
    .default,
];

const BirthdayMain = () => {
  return (
    <section id="main" className="birthday-main birthday-block-lessened">
      <div className="birthday-main__inner">
        <div className="birthday-main__info">
          <div className="birthday-main__years">
            9 лет
            <br></br> городу <br></br> идей
          </div>
          <div className="birthday-main__about">
          Платформа Правительства Москвы «Город идей» дает возможность жителям участвовать в жизни столицы и предлагать свои идеи по ее развитию. Вместе мы делаем город лучше!
          </div>
        </div>

        <div className="birthday-main__celebration">
          <img
            className="birthday-main__celebration-bg"
            src={
              require("../../../../../assets/birthday9/images/main/main-bg.png")
                .default
            }
          />
          {achiveImgs.map((src, idx) => (
            <img
              key={idx}
              className={`birthday-main__celebration-item birthday-main__celebration-item${
                idx + 1
              }`}
              src={src}
            />
          ))}

          <div className="birthday-main__celebration-mobile">
            <img
              src={
                require("../../../../../assets/birthday9/images/main/main-bg-item-mobile.png").default
              }
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default BirthdayMain;
