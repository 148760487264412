import React, { useEffect } from "react";
import { useHistory, withRouter } from "react-router-dom";

import MetaDecorator from "../../presentational/MetaDecorator/MetaDecorator";
import PageTitle from "../../presentational/PageTitle/PageTitle";
import PageDescription from "../../presentational/PageDescription/PageDescription";
import ProjectFlipcard from "../../presentational/Cards/ProjectFlipcard/ProjectFlipcard";

import "../GenericProject/GenericProjectListPage.scss";
import "./AnnouncementPage.scss";
import { useDispatch } from "react-redux";
import { AppColorsEnum } from "../../App.interface";
import { setAppColor } from "../../../store/actions/LayoutActions";

// type Props = {
//   projects: Array,
// };

const AnnouncementPage = (props) => {
  const dispath = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (!props.projects?.length) history.push("/");
  }, [props.projects]);

  useEffect(() => {
    dispath(setAppColor(AppColorsEnum.WHITE));
  }, []);

  const renderList = () => {
    return (
      <div className="announcement__list">
        {props.projects.map((project, idx) => (
          <ProjectFlipcard
            type="announcement"
            isFlippable={false}
            project={project}
            defaultLogo={require("../../../assets/logo/default/vertical.png").default}
            key={idx}
          ></ProjectFlipcard>
        ))}
      </div>
    );
  };

  return (
    <section className="announcement">
      <MetaDecorator
        title="Платформа Правительства Москвы «Город идей»"
        description="Информация о новых проектах, в которых можно принять участие."
      />
      <PageTitle text={"Анонс проектов"} />
      <PageDescription
        text={
          "В этом разделе вы можете ознакомиться с перечнем проектов, проведение которых уже запланировано, и оставить заявку на уведомление о начале подачи заявок на проект."
        }
      />

      {renderList()}
    </section>
  );
};

export default withRouter(AnnouncementPage);
