import { declOfNum, makeMultiCriteriaSort } from "../../../utils";
import { RealizedType, ResultIdea } from "./WorkResultPage";

function createList(item, type) {
  const result =
    item.ideas.concat(item.sections.map((s) => s.ideas).flat()).map((i) => {
      i.type = type;
      return i as ResultIdea;
    }) || [];

  return type === RealizedType.ACCEPTED
    ? sortAccepted(result)
    : sortRealized(result);
}

function sortByDeadline(ideas) {
  return ideas.sort((a, b) => {
    const aDate = a.deadline ? new Date(a.deadline).valueOf() : 0;
    const bDate = b.deadline ? new Date(b.deadline).valueOf() : 0;
    return aDate && bDate ? aDate - bDate : bDate - aDate;
  });
}

function sortAccepted(ideas) {
  let result = sortByDeadline(ideas);
  return result;
}

const getPhotoList = (idea) => {
  const photoMainId = idea.mainPhotoId ? [idea.mainPhotoId] : [];
  const photoMainUserId = idea.mainUserPhotoAttachmentId
    ? [idea.mainUserPhotoAttachmentId]
    : [];
  const photoIds = idea.publishedPhotoIds || [];
  const photoUserIds = idea.publishedUserPhotoAttachmentsIds || [];
  const uniq = new Set([
    ...photoMainId,
    ...photoMainUserId,
    ...photoIds,
    ...photoUserIds,
  ]);
  return Array.from(uniq);
};

function sortByPhotoExists(a, b) {
  const aPhotoExists = getPhotoList(a).length ? 1 : 0;
  const bPhotoExists = getPhotoList(b).length ? 1 : 0;

  return bPhotoExists - aPhotoExists;
}

function sortByCompleted(a, b) {
  const aDate = a.completed ? new Date(a.completed).valueOf() : 0;
  const bDate = b.completed ? new Date(b.completed).valueOf() : 0;

  return bDate - aDate;
}

function sortRealized(ideas) {
  return ideas.sort(makeMultiCriteriaSort(sortByPhotoExists, sortByCompleted));
}

const ideasDecl = (count) => {
  return declOfNum(count || 0, ["идея", "идеи", "идей"]);
};

const realizedDecl = (count) => {
  return declOfNum(count || 0, ["реализована", "реализованы", "реализовано"]);
};

export { createList, ideasDecl, realizedDecl, getPhotoList, sortRealized };
