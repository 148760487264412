import { useEffect, useState } from "react";

const useImagePreload = (imageSources) => {
  const [loadingProgress, setLoadingProgress] = useState(0);

  useEffect(() => {
    let sourcesLoaded = 0
    imageSources.forEach((src) => {
      const image = new Image();
      image.onload = () => {
        sourcesLoaded++;
        setLoadingProgress(sourcesLoaded / imageSources.length);
      };
      image.src = src;
    });
  }, [])

  return loadingProgress === 1
}

export default useImagePreload;