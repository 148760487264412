import React, { Component } from "react";
import ContentLoader from "react-content-loader";

import "./ProfileLoader.scss";

export default function ProfileLoader() {
  return (
    <div className="profile-loader">
      <div className="profile-loader__avatar">
        <ContentLoader viewBox="0 0 100 100" title="Загрузка...">
          <circle cx="50" cy="50" r="50" />
        </ContentLoader>
      </div>
    </div>
  );
}
