import React, { useMemo, useState } from "react";
import "./LinkPopup.scss";
import Validator from "../../../services/validator";
import { useOuterClick } from "../../../hooks/useOnOutsideClick";
import Input from "../Controls/Input/Input";
import Button from "../Controls/Button/Button";

export interface LinkPopupProps {
  link?: HTMLAnchorElement;
  text?: string;
  target?: string;
  onClose: () => void;
  onSubmit: (link: JSX.Element | string) => void;
  onEditSave: () => void;
}

export const LinkPopup = ({ link, text, target = "_blank", onClose, onSubmit, onEditSave }: LinkPopupProps) => {
  const [_text, setText] = useState(link?.text || text || "");
  const [_link, setLink] = useState(link?.href || "");
  const [textValidator, setTextValidator] = useState<Validator>(null);
  const [linkValidator, setLinkValidator] = useState<Validator>(null);
  const [submitted, setSubmitted] = useState<boolean>(false);

  const LPRef = useOuterClick<HTMLDivElement>((event: any, innerRef) => {
    if (event.target instanceof HTMLAnchorElement) return;
    if (event.target.dataset?.lp) return;
    onClose();
  });

  const _onSubmit = (ev) => {
    ev.preventDefault();
    setSubmitted(true);
    if (isValid()) {
      if (isEditMode()) {
        link.text = _text.trim();
        link.href = _link;
        onEditSave();
      } else {
        const candidate = `<a href="${
          _link.includes("http") ? _link : "//" + _link
        }" target="${target}">${_text.trim()}</a>`;
        onSubmit(candidate);
      }
      onClose();
    }
  };

  const isValid = () => {
    return textValidator?.isValid() && linkValidator?.isValid();
  };

  const isEditMode = () => link;

  const renderPopup = useMemo(() => {
    return (
      <div className="link-popup" ref={LPRef}>
        <div className="link-popup__title">Добавление ссылки</div>
        <div className="link-popup__inputs">
          <Input
            value={_text}
            validateRules={{ notEmpty: true, maxLength: 2000, minLength: 3 }}
            onInitValidator={(validator) => setTextValidator(validator)}
            showError={submitted}
            onChange={setText}
            placeholder={"Текст"}
            isRequired={true}
          />
          <Input
            value={_link}
            validateRules={{ notEmpty: true, isLink: { strict: false }, maxLength: 2500 }}
            onInitValidator={(validator) => setLinkValidator(validator)}
            showError={submitted}
            onChange={setLink}
            placeholder={"Адрес ссылки"}
            isRequired={true}
          />
        </div>

        <div className="link-popup__buttons">
          <Button text={"Отменить"} type={"outlined-grey"} size="mid" onClick={onClose} />
          <Button text={"Сохранить"} type={"filled"} size="mid" onClick={_onSubmit} />
        </div>
      </div>
    );
  }, [link, _link, _text, textValidator, linkValidator, submitted]);

  return renderPopup;
};

export default LinkPopup;
