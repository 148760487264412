import { API, httpParamsSerialize } from "./api";
import { deleteCookie } from "../utils/index";
import { BaseResponse } from "../types/Common.interface";
import { RewardsEnum } from "../utils/constants/rewards";
import { User } from "../types/User.interface";

const UserService = {
  login: (data) => {
    return API.post("/api/login", data).then(({ data }) => {
      return data;
    });
  },

  getUser: () => {
    return API.get("/api/profile/current").then(({ data }) => {
      return data;
    });
  },

  getUserForNyLanding: () => {
    return API.get("/api/birds/user/current").then((data) => {
      return data?.data;
    });
  },

  updateUser: (query) => {
    return API.post("/api/profile/update", query).then(({ data }) => {
      return data;
    });
  },

  getPerson: (id) => {
    const urlProfile = "api/profile/get?id=" + id;

    return API.get(urlProfile).then(({ data }) => {
      return data;
    });
  },

  getUserProjectsState: (projectId) => {
    // Возвращает статус текущего пользователя в указанном проекте.
    const url = "api/profile/projectState?projectId=" + projectId;

    return API.get(url).then(({ data }) => {
      return data;
    });
  },

  addToProject: (userId, projectId) => {
    return API.post("/api/profile/addToProject", {
      userId: userId,
      projectId: projectId,
    }).then(({ data }) => {
      return data;
    });
  },

  addToProjects: (query: { ids: string[] }) => {
    return API.post("/api/profile/addToProjects", {
      ids: query.ids,
    }).then(({ data }) => {
      return data;
    });
  },

  uploadDefaultPicture: (id) => {
    return API.post("/api/userpicture/setDefault?index=" + id).then(({ data }) => {
      return data;
    });
  },

  uploadPicture: (params) => {
    const query = httpParamsSerialize(params.coords);
    const url = `api/userpicture/upload?${query}`;
    const data = Uint8Array.from(atob(params.src.match(/,(.*)$/)[1]), function (c) {
      return c.charCodeAt(0);
    });
    const headers = {
      headers: { "Content-Type": params.type },
    };

    return API.post(url, data, headers).then(({ data }) => {
      return data;
    });
  },

  resendEmailVerification: () => {
    return API.get("/api/profile/resendEmailVerification").then((data) => {
      return data;
    });
  },

  setNewPassword: (params) => {
    return API.post("/api/profile/newPassword", params).then((data) => {
      return data;
    });
  },

  changePassword: (params) => {
    return API.post("/api/profile/changePassword", params).then((data) => {
      return data;
    });
  },

  sendRestoreEmail: (params) => {
    return API.get("/api/profile/restorePassword", { params }).then((data) => {
      return data;
    });
  },

  restorePassword: (params) => {
    return API.post("/api/profile/restorePassword", params).then((data) => {
      return data;
    });
  },

  getPoints: () => {
    return API.post("/api/emmc/getPoints").then((data) => {
      return data;
    });
  },

  logout: function (queryParams?) {
    let baseUrl = "/logout";
    if (queryParams && typeof queryParams === "object") {
      const params = Object.keys(queryParams).map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`
      );
      baseUrl += "?" + params.join("&");
    }
    window.location.href = baseUrl;
  },

  logoutNY: function () {
    return API.get("/api/birds/logout").then((data) => {
      deleteCookie("BIRDS_SESSION");
      return data;
    });
  },

  getRewards: (userId: string) =>
    API.post<BaseResponse<RewardsEnum[]>>("/api/profile/rewards", { id: userId }).then(({ data }) => data),

  getExperts: (projectId: string) =>
    API.get<BaseResponse<User[]>>(`/api/users/experts?projectId=${projectId}`).then(({ data }) => data),

  getProjectsCount: () => API.get<BaseResponse<number>>("/api/profile/myProjectCount").then(({ data }) => data),
};

export default UserService;
