export const ADD_COMMAND = "ADD_COMMAND";
export const REMOVE_COMMAND = "REMOVE_COMMAND";

export const addCommand = (command) => (dispatch) => {
  dispatch({
    type: ADD_COMMAND,
    payload: command,
  });
};

export const removeCommand = (command) => (dispatch) => {
  dispatch({
    type: REMOVE_COMMAND,
    payload: command,
  });
};
