import React, { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../types/State.interface";
import { setFullScreenElementById } from "../../../../store/actions/WindowActions";

interface FullScreenElement extends Element {
  mozRequestFullScreen?: () => Promise<void>;
  webkitRequestFullScreen?: () => Promise<void>;
}

const WindowControl: FC = ({ children }) => {
  const dispatch = useDispatch()
  const fullScreenElementId = useSelector((state: RootState) => state.window.fullScreenElementId);

  const setFullScreen = () => {
    const element = document.querySelector(fullScreenElementId) as FullScreenElement;
    if (element.requestFullscreen) {
      element.requestFullscreen().catch((e) => console.log(e));
    } else if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen().catch((e) => console.log(e));
    } else if (element.webkitRequestFullScreen) {
      element.webkitRequestFullScreen().catch((e) => console.log(e));
    }
  };

  const checkScreen = () => {
    if (fullScreenElementId) {
      setFullScreen();
    } else if (document.fullscreenElement) {
      document.exitFullscreen().catch((e) => console.log(e));
    }
  }
  const checkBrowserHide = () => {
    if(document.hidden && fullScreenElementId){
      dispatch(setFullScreenElementById(""))
    }
  }

  useEffect(() => {
    checkScreen()
  }, [fullScreenElementId]);

  useEffect(() => {
    document.addEventListener("visibilitychange", checkBrowserHide)
    return () => document.removeEventListener("visibilitychange", checkBrowserHide)
  }, [fullScreenElementId]);

  return <>{children}</>;
};

export default WindowControl;
