import { FC } from "react";
import TooltipBase from "../../../../presentational/Controls/Tooltip/Tooltip";
import { TooltipProps } from "../../../../presentational/Controls/Tooltip/Tooltip.interface";
import "./Tooltip.scss";

const Tooltip: FC<Omit<TooltipProps, "type">> = ({ className, outerClassName, ...otherProps }) => {
  return (
    <TooltipBase
      className={`new-year-2023-tooltip ${className || ""}`}
      outerClassName={`new-year-2023-tooltip__outer ${outerClassName || ""}`}
      {...otherProps}
    />
  );
};

export default Tooltip;
