import { FC, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import SupportForm from "../../../../containers/Forms/SupportForm/SupportForm";
import "./Footer.scss";
import { FooterProps } from "./Footer.types";

const fullYear = new Date().getFullYear();

const Footer: FC<FooterProps> = ({ swiper }) => {
  const [helpModalOpen, setHelpModalOpen] = useState(false);
  const [slideIndex, setSlideIndex] = useState(0);
  const [swiperEnabled, setSwiperEnabled] = useState(true);
  const swiperEnabledWhileOpeningModalRef = useRef(true);

  const canPrev = () => swiperEnabled && slideIndex > 0;
  const canNext = () => swiperEnabled && slideIndex < swiper?.slides.length - 1;
  const prev = () => swiper?.slidePrev();
  const next = () => swiper?.slideNext();

  useEffect(() => {
    if (swiper) {
      setSwiperEnabled((swiper as any).enabled);
      swiperEnabledWhileOpeningModalRef.current = (swiper as any).enabled;

      const cbSlideChange = () => setSlideIndex(swiper.realIndex);
      const cbEnable = () => setSwiperEnabled(true);
      const cbDisable = () => setSwiperEnabled(false);
      swiper.on("slideChange", cbSlideChange);
      swiper.on("enable" as any, cbEnable);
      swiper.on("disable" as any, cbDisable);
      return () => {
        swiper.off("slideChange", cbSlideChange);
        swiper.off("enable" as any, cbEnable);
        swiper.off("disable" as any, cbDisable);
      };
    }
  }, [swiper]);

  useEffect(() => {
    if (swiper) {
      if (helpModalOpen) {
        swiperEnabledWhileOpeningModalRef.current = (swiper as any).enabled;
        swiper.disable();
      } else if (swiperEnabledWhileOpeningModalRef.current) {
        swiper.enable();
      }
    }
  }, [helpModalOpen, swiper]);

  return (
    <>
      {helpModalOpen && <SupportForm isOpened close={() => setHelpModalOpen(false)} />}
      <footer className="new-year-2023-footer">
        <div className="new-year-2023-footer__copy">
          <span>© Краудсорсинг-проекты</span> <span>Правительства Москвы, {fullYear}</span>
        </div>
        <div className="new-year-2023-footer__nav">
          <button
            type="button"
            className={`new-year-2023-footer__nav__btn ${!canNext() ? "disabled" : ""}`}
            onClick={next}
          />
          <span className="new-year-2023-footer__nav__text">Листайте</span>
          <button
            type="button"
            className={`new-year-2023-footer__nav__btn ${!canPrev() ? "disabled" : ""}`}
            onClick={prev}
          />
        </div>
        <div className="new-year-2023-footer__help">
          <Link to="#" onClick={() => setHelpModalOpen(true)} className="new-year-2023-footer__help__help-link">
            Помощь
          </Link>
          <a href="new_year2024/Правила_участия_в_квесте.pdf" target="_blank">
            Правила участия
          </a>
        </div>
      </footer>
    </>
  );
};

export default Footer;
