import { fetchRequest, httpParamsSerialize } from "../../utils/axiosConfig";

export const GET_NEWS_REQUEST = "GET_NEWS_REQUEST";
export const GET_NEWS_SUCCESS = "GET_NEWS_SUCCESS";
export const GET_NEWS_FAIL = "GET_NEWS_FAIL";

export const LOAD_NEWS_TAGS_HANDLE = "LOAD_NEWS_TAGS_HANDLE";
export const LOAD_NEWS_TAGS_SUCCESS = "LOAD_NEWS_TAGS_SUCCESS";
export const LOAD_NEWS_TAGS_FAIL = "LOAD_NEWS_TAGS_FAIL";

export function getNews(params) {
  return async function (dispatch) {
    dispatch({
      type: GET_NEWS_REQUEST,
    });

    const url = "/api/news/list?" + httpParamsSerialize(params);

    try {
      let response = await fetchRequest(url, params, "GET");
      response = response.data;

      if (response.status === 0) {
        dispatch({
          type: GET_NEWS_SUCCESS,
          payload: response.data,
        });
      } else {
        dispatch({
          type: GET_NEWS_FAIL,
          error: true,
          payload: new Error("Ошибка получения новостей."),
        });
      }
    } catch (err) {
      dispatch({
        type: GET_NEWS_FAIL,
        error: true,
        payload: new Error("Ошибка получения новостей."),
      });
    }
  };
}

export function getNewsTags(params?) {
  return async function (dispatch) {
    dispatch({
      type: LOAD_NEWS_TAGS_HANDLE,
    });

    const url = "/api/news/tags?query=";

    try {
      let response = await fetchRequest(url, params, "GET");
      response = response.data;

      if (response.status === 0) {
        dispatch({
          type: LOAD_NEWS_TAGS_SUCCESS,
          payload: response.data,
        });
      } else {
        dispatch({
          type: LOAD_NEWS_TAGS_FAIL,
          error: true,
          payload: new Error("Ошибка получения тегов новостей."),
        });
      }
    } catch (err) {
      dispatch({
        type: LOAD_NEWS_TAGS_FAIL,
        error: true,
        payload: new Error("Ошибка получения тегов новостей."),
      });
    }
  };
}
