import { Swiper, SwiperSlide } from "swiper/react";
import "./UsersLikePopup.scss";

import Loader from "../../../../../../presentational/Loaders/Loader/Loader";
import { getAvatarSrc } from "../../../../../../../utils";
import { User } from "../../../../../../../types/User.interface";

interface LikePopupBodyProps {
  users: Partial<User>[];
  onClick?: (user) => void;
  onClose: () => void;
}

const step = 6;

const UsersLikePopup = ({ users, onClick, onClose }: LikePopupBodyProps) => {
  const onClickHandler = (user) => {
    onClick(user);
    onClose();
  };

  return (
    <Swiper
      className="likePopup"
      slidesPerView={step}
      slidesPerGroup={step}
      spaceBetween={8}
      width={280}
      centeredSlides={!users?.length}
      onTouchStart={(_, e) => {
        e.stopPropagation();
      }}
    >
      {Boolean(users?.length) ? (
        users?.map((user) => (
          <SwiperSlide key={user.id}>
            <div className="likePopup__avatar" onClick={() => onClickHandler(user)}>
              <img src={getAvatarSrc(user)} alt="Пользователь" className="likePopup__avatarImg" />
            </div>
          </SwiperSlide>
        ))
      ) : (
        <Loader />
      )}
    </Swiper>
  );
};

export default UsersLikePopup;
