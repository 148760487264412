import { FC } from "react";
import TooltipBase from "../../../../presentational/Controls/Tooltip/Tooltip";
import { TooltipProps } from "../../../../presentational/Controls/Tooltip/Tooltip.interface";
import "./Tooltip.scss";

const Tooltip: FC<Omit<TooltipProps, "type">> = ({ className, ...otherProps }) => {
  return <TooltipBase className={`leisure-landing-tooltip ${className || ""}`} {...otherProps} />;
};

export default Tooltip;
