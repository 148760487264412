import React, { useContext } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { AppModal } from "../../Modal/AppModal";
import EventsTrackWrapperClick from "../../EventsTrack/wrappers/EventsTrackWrapperClick";
import EventsTrackClickModalCloseBtn from "../../EventsTrack/wrappers/helpers/EventsTrackClickModalCloseBtn";
import { LocalStorageService } from "../../../../services/LocalStorage.service";
import { RootState } from "../../../../types/State.interface";

import "./AuthForm.scss";
import { AppContext } from "../../../Root";

type AuthFormProps = {
  edp?: string;
};

const AuthForm = ({ edp = "/oauth2/authorization/sudir" }: AuthFormProps) => {
  const appContext = useContext(AppContext);
  const dispatch = useDispatch();
  const returnUrl = useSelector((state: RootState) => state.layout.returnUrl);
  const isShowMosRuAuthForm = useSelector((state: RootState) => state.layout.isShowMosRuAuthForm);

  const logIn = (e) => {
    e.preventDefault();
    LocalStorageService.removeData("crowd-forced-logout");

    window.location.href = window.location.origin + edp + (returnUrl ? `?${returnUrl}` : "");
  };

  const signUp = (e) => {
    e.preventDefault();
    window.location.href = window.location.origin + edp + (returnUrl ? `?${returnUrl}` : "") + (returnUrl ? "&reg=true" : "?reg=true");
  };

  const handleClose = () => {
    dispatch({ type: "HIDE_MOSRU_AUTH_FORM" });
    dispatch({ type: "CLEAR_PAGE_RELOAD_FORM" });
  };

  return (
    <>
      {isShowMosRuAuthForm && (
        <AppModal
          classNames={{ modal: "mosru-auth-form" }}
          center
          overlayId="modal-overlay"
          closeIconId="close-modal-icon"
          blockScroll={false}
          closeIconSize={25}
          open={isShowMosRuAuthForm}
          closeOnEsc={false}
          closeOnOverlayClick={false}
          onClose={handleClose}
          CloseBtnComponent={EventsTrackClickModalCloseBtn}
        >
          <div className="form-container">
            <EventsTrackWrapperClick id={12}>
              <button className="btn btn_red mos-ru mos-ru__auth" onClick={logIn} tabIndex={1}>
                <div className="mos-ru__icon" />
                Войти через mos.ru
              </button>
            </EventsTrackWrapperClick>

            <EventsTrackWrapperClick id={13}>
              <button className="btn" onClick={signUp} tabIndex={2}>
                Регистрация
              </button>
            </EventsTrackWrapperClick>
          </div>
        </AppModal>
      )}
    </>
  );
};

export default AuthForm;