export const bulbs = `<svg width="805" height="821" viewBox="0 0 805 821" fill="none" xmlns="http://www.w3.org/2000/svg">
<g class="christmas-bulbs__bulb">
<path d="M185.748 416.735C185.84 431.452 173.99 443.458 159.28 443.551C144.569 443.644 132.569 431.789 132.476 417.073C132.383 402.356 144.233 390.35 158.943 390.257C173.654 390.164 185.654 402.019 185.748 416.735Z" fill="#4AA4C8"/>
<path d="M171.683 411.134C169.598 396.852 160.442 391.208 160.442 391.208C160.442 391.208 163.161 401.13 164.212 413.668C165.264 426.206 161.736 443.406 161.736 443.406C161.736 443.406 173.767 425.415 171.683 411.134Z" fill="#53BAE5"/>
<path d="M184.764 415.882C183.774 396.31 164.682 391.452 164.682 391.452C164.682 391.452 178.951 396.773 180.507 414.957C182.062 433.141 163.974 442.117 163.974 442.117C163.974 442.117 185.754 435.454 184.764 415.882Z" fill="#53BAE5"/>
<path d="M133.459 416.021C134.449 396.449 153.542 391.591 153.542 391.591C153.542 391.591 140.012 397.513 138.456 415.697C136.9 433.881 154.249 442.256 154.249 442.256C154.249 442.256 132.469 435.593 133.459 416.021Z" fill="#53BAE5"/>
<path d="M153.868 391.637C153.868 391.637 145.544 398.411 145.386 412.837C145.228 427.264 159.558 443.551 159.558 443.551C159.558 443.551 153.763 426.954 153.131 414.389C152.498 401.824 153.868 391.637 153.868 391.637Z" fill="#53BAE5"/>
<path d="M153.528 383.706L154.354 390.732H161.965L162.791 383.706C162.858 383.14 162.416 382.643 161.846 382.643H154.473C153.903 382.643 153.462 383.14 153.528 383.706Z" fill="#E5E5E5"/>
<g opacity="0.15">
<path d="M141.98 397.87C141.98 397.87 125.443 415.225 138.356 432.16C152.983 451.342 173.381 438.22 173.381 438.22C173.381 438.22 173.603 428.367 164.326 423.143C155.048 417.919 143.813 417.848 141.772 410.008C139.732 402.168 141.98 397.87 141.98 397.87Z" fill="black" style="mix-blend-mode:multiply"/>
</g>
</g>
<g class="christmas-bulbs__bulb">
<path d="M201.42 308.939C201.473 317.447 194.623 324.388 186.118 324.441C177.613 324.495 170.676 317.642 170.622 309.134C170.568 300.625 177.419 293.685 185.923 293.631C194.428 293.577 201.366 300.431 201.42 308.939Z" fill="#FD7EAE"/>
<path d="M182.793 289.844L183.271 293.906H187.671L188.148 289.844C188.187 289.517 187.931 289.229 187.602 289.229H183.339C183.01 289.229 182.755 289.517 182.793 289.844Z" fill="#E5E5E5"/>
<g opacity="0.15">
<path d="M176.117 298.032C176.117 298.032 166.556 308.066 174.022 317.856C182.478 328.946 194.27 321.36 194.27 321.36C194.27 321.36 194.398 315.663 189.035 312.643C183.672 309.623 177.176 309.582 175.996 305.049C174.817 300.517 176.117 298.032 176.117 298.032Z" fill="black" style="mix-blend-mode:multiply"/>
</g>
</g>
<g class="christmas-bulbs__bulb">
<path d="M389.14 488.703C389.194 497.211 382.343 504.151 373.839 504.205C365.334 504.259 358.396 497.406 358.343 488.898C358.289 480.389 365.14 473.449 373.644 473.395C382.149 473.341 389.086 480.195 389.14 488.703Z" fill="#8A6FB8"/>
<path d="M370.513 469.607L370.991 473.67H375.391L375.869 469.607C375.907 469.28 375.652 468.993 375.322 468.993H371.06C370.73 468.993 370.475 469.28 370.513 469.607Z" fill="#E5E5E5"/>
<g opacity="0.1">
<path d="M363.837 477.796C363.837 477.796 354.277 487.83 361.742 497.62C370.199 508.71 381.991 501.123 381.991 501.123C381.991 501.123 382.119 495.427 376.756 492.407C371.392 489.387 364.897 489.346 363.717 484.813C362.538 480.281 363.837 477.796 363.837 477.796Z" fill="black" style="mix-blend-mode:multiply"/>
</g>
</g>
<g class="christmas-bulbs__bulb">
<path d="M415.799 429.71C415.852 438.218 409.002 445.159 400.497 445.213C391.992 445.266 385.055 438.413 385.001 429.905C384.947 421.396 391.798 414.456 400.302 414.402C408.807 414.348 415.745 421.202 415.799 429.71Z" fill="#53BAE5"/>
<path d="M397.172 410.615L397.65 414.677H402.05L402.527 410.615C402.566 410.288 402.31 410 401.981 410H397.718C397.389 410 397.134 410.288 397.172 410.615Z" fill="#E5E5E5"/>
<g opacity="0.1">
<path d="M390.496 418.803C390.496 418.803 380.935 428.837 388.4 438.627C396.857 449.717 408.649 442.131 408.649 442.131C408.649 442.131 408.777 436.434 403.414 433.414C398.051 430.394 391.555 430.353 390.375 425.82C389.196 421.288 390.496 418.803 390.496 418.803Z" fill="black" style="mix-blend-mode:multiply"/>
</g>
</g>
<g class="christmas-bulbs__bulb">
<path d="M359.799 571.71C359.852 580.218 353.002 587.159 344.497 587.213C335.992 587.266 329.055 580.413 329.001 571.905C328.947 563.396 335.798 556.456 344.302 556.402C352.807 556.348 359.745 563.202 359.799 571.71Z" fill="#FAD573"/>
<path d="M341.172 552.615L341.65 556.677H346.049L346.527 552.615C346.566 552.288 346.31 552 345.981 552H341.718C341.389 552 341.133 552.288 341.172 552.615Z" fill="#E5E5E5"/>
<g opacity="0.1">
<path d="M334.496 560.803C334.496 560.803 324.936 570.837 332.401 580.627C340.858 591.717 352.65 584.131 352.65 584.131C352.65 584.131 352.778 578.434 347.415 575.414C342.052 572.394 335.556 572.353 334.376 567.82C333.197 563.288 334.496 560.803 334.496 560.803Z" fill="black" style="mix-blend-mode:multiply"/>
</g>
</g>
<g class="christmas-bulbs__bulb">
<path d="M309.799 388.71C309.852 397.218 303.002 404.159 294.497 404.213C285.992 404.266 279.055 397.413 279.001 388.905C278.947 380.396 285.798 373.456 294.302 373.402C302.807 373.348 309.745 380.202 309.799 388.71Z" fill="#A8F2FB"/>
<path d="M291.171 369.615L291.649 373.677H296.048L296.526 369.615C296.565 369.287 296.309 369 295.98 369H291.717C291.388 369 291.132 369.287 291.171 369.615Z" fill="#E5E5E5"/>
<g opacity="0.1">
<path d="M284.496 377.803C284.496 377.803 274.935 387.837 282.4 397.627C290.857 408.717 302.649 401.13 302.649 401.13C302.649 401.13 302.777 395.434 297.414 392.414C292.051 389.394 285.555 389.353 284.375 384.82C283.196 380.288 284.496 377.803 284.496 377.803Z" fill="black" style="mix-blend-mode:multiply"/>
</g>
</g>
<g class="christmas-bulbs__bulb">
<path d="M245.59 240.649C245.643 249.157 238.793 256.097 230.288 256.151C221.783 256.205 214.846 249.351 214.792 240.843C214.738 232.335 221.589 225.395 230.093 225.341C238.598 225.287 245.536 232.141 245.59 240.649Z" fill="#A8F2FB"/>
<path d="M226.963 221.553L227.44 225.616H231.84L232.318 221.553C232.356 221.226 232.101 220.939 231.772 220.939H227.509C227.18 220.939 226.924 221.226 226.963 221.553Z" fill="#E5E5E5"/>
<g opacity="0.1">
<path d="M220.286 229.742C220.286 229.742 210.725 239.776 218.191 249.566C226.647 260.656 238.439 253.069 238.439 253.069C238.439 253.069 238.568 247.373 233.205 244.353C227.841 241.333 221.345 241.292 220.166 236.759C218.986 232.227 220.286 229.742 220.286 229.742Z" fill="black" style="mix-blend-mode:multiply"/>
</g>
</g>
<g class="christmas-bulbs__bulb">
<path d="M158.799 519.71C158.852 528.218 152.002 535.159 143.497 535.212C134.992 535.266 128.055 528.413 128.001 519.905C127.947 511.396 134.798 504.456 143.302 504.402C151.807 504.348 158.745 511.202 158.799 519.71Z" fill="#FD7EAE"/>
<path d="M140.172 500.615L140.65 504.677H145.05L145.528 500.615C145.566 500.287 145.311 500 144.981 500H140.719C140.389 500 140.134 500.287 140.172 500.615Z" fill="#E5E5E5"/>
<g opacity="0.1">
<path d="M133.496 508.803C133.496 508.803 123.936 518.837 131.401 528.627C139.858 539.717 151.65 532.13 151.65 532.13C151.65 532.13 151.778 526.434 146.415 523.414C141.052 520.394 134.556 520.353 133.376 515.82C132.197 511.288 133.496 508.803 133.496 508.803Z" fill="black" style="mix-blend-mode:multiply"/>
</g>
</g>
<g class="christmas-bulbs__bulb">
<path d="M76.9802 554.236C77.034 562.744 70.1832 569.685 61.6787 569.739C53.174 569.793 46.2362 562.939 46.1824 554.431C46.1287 545.923 52.9795 538.982 61.484 538.928C69.9885 538.874 76.9263 545.728 76.9802 554.236Z" fill="#8A6FB8"/>
<path d="M58.3542 535.141L58.832 539.203H63.2317L63.7094 535.141C63.7479 534.814 63.4924 534.526 63.1632 534.526H58.9004C58.5712 534.526 58.3158 534.814 58.3542 535.141Z" fill="#E5E5E5"/>
<g opacity="0.1">
<path d="M51.6771 543.33C51.6771 543.33 42.1165 553.363 49.5821 563.154C58.0384 574.243 69.8305 566.657 69.8305 566.657C69.8305 566.657 69.959 560.961 64.5958 557.94C59.2322 554.92 52.7365 554.879 51.5568 550.347C50.3774 545.814 51.6771 543.33 51.6771 543.33Z" fill="black" style="mix-blend-mode:multiply"/>
</g>
</g>
<g class="christmas-bulbs__bulb">
<path d="M285.8 136.71C285.853 145.218 279.002 152.159 270.498 152.212C261.993 152.266 255.055 145.413 255.002 136.905C254.948 128.396 261.799 121.456 270.303 121.402C278.808 121.348 285.746 128.202 285.8 136.71Z" fill="#FD9E5E"/>
<path d="M267.173 117.614L267.651 121.677H272.051L272.528 117.614C272.567 117.287 272.311 117 271.982 117H267.719C267.39 117 267.135 117.287 267.173 117.614Z" fill="#E5E5E5"/>
<g opacity="0.1">
<path d="M260.496 125.803C260.496 125.803 250.936 135.837 258.401 145.627C266.858 156.717 278.65 149.13 278.65 149.13C278.65 149.13 278.778 143.434 273.415 140.414C268.051 137.394 261.556 137.353 260.376 132.82C259.197 128.288 260.496 125.803 260.496 125.803Z" fill="black" style="mix-blend-mode:multiply"/>
</g>
</g>
<g class="christmas-bulbs__bulb">
<path d="M283.273 478.092C283.366 492.809 271.516 504.815 256.805 504.908C242.095 505.001 230.094 493.146 230.001 478.43C229.908 463.713 241.758 451.707 256.469 451.614C271.179 451.521 283.18 463.376 283.273 478.092Z" fill="#EA8849"/>
<path d="M269.208 472.491C267.124 458.209 257.967 452.565 257.967 452.565C257.967 452.565 260.686 462.488 261.738 475.025C262.789 487.563 259.262 504.764 259.262 504.764C259.262 504.764 271.292 486.772 269.208 472.491Z" fill="#FD9E5E"/>
<path d="M282.289 477.24C281.299 457.667 262.207 452.809 262.207 452.809C262.207 452.809 276.476 458.13 278.032 476.314C279.588 494.498 261.5 503.474 261.5 503.474C261.5 503.474 283.279 496.812 282.289 477.24Z" fill="#FD9E5E"/>
<path d="M230.984 477.378C231.974 457.806 251.067 452.948 251.067 452.948C251.067 452.948 237.537 458.87 235.981 477.054C234.426 495.238 251.774 503.613 251.774 503.613C251.774 503.613 229.995 496.95 230.984 477.378Z" fill="#FD9E5E"/>
<path d="M251.393 452.994C251.393 452.994 243.069 459.768 242.911 474.194C242.754 488.621 257.083 504.909 257.083 504.909C257.083 504.909 251.288 488.311 250.656 475.746C250.024 463.181 251.393 452.994 251.393 452.994Z" fill="#FD9E5E"/>
<path d="M251.054 445.063L251.88 452.089H259.49L260.317 445.063C260.383 444.497 259.941 444 259.372 444H251.998C251.429 444 250.987 444.497 251.054 445.063Z" fill="#E5E5E5"/>
<g opacity="0.2">
<path d="M239.506 459.227C239.506 459.227 222.968 476.582 235.882 493.517C250.509 512.699 270.906 499.577 270.906 499.577C270.906 499.577 271.128 489.724 261.851 484.5C252.574 479.276 241.338 479.205 239.297 471.365C237.257 463.525 239.506 459.227 239.506 459.227Z" fill="black" style="mix-blend-mode:multiply"/>
</g>
</g>
<g class="christmas-bulbs__bulb">
<path d="M463.823 547.277C463.916 561.994 452.066 574 437.355 574.093C422.644 574.186 410.644 562.331 410.551 547.615C410.458 532.898 422.308 520.892 437.018 520.799C451.729 520.706 463.729 532.561 463.823 547.277Z" fill="#E86597"/>
<path d="M449.758 541.676C447.674 527.394 438.518 521.75 438.518 521.75C438.518 521.75 441.236 531.672 442.288 544.21C443.34 556.748 439.812 573.948 439.812 573.948C439.812 573.948 451.842 555.957 449.758 541.676Z" fill="#FD7EAE"/>
<path d="M462.84 546.424C461.85 526.852 442.757 521.994 442.757 521.994C442.757 521.994 457.027 527.315 458.583 545.499C460.138 563.683 442.05 572.659 442.05 572.659C442.05 572.659 463.829 565.996 462.84 546.424Z" fill="#FD7EAE"/>
<path d="M411.535 546.563C412.525 526.991 431.617 522.133 431.617 522.133C431.617 522.133 418.088 528.055 416.532 546.239C414.976 564.423 432.324 572.798 432.324 572.798C432.324 572.798 410.545 566.135 411.535 546.563Z" fill="#FD7EAE"/>
<path d="M431.944 522.179C431.944 522.179 423.62 528.953 423.462 543.379C423.304 557.806 437.633 574.094 437.633 574.094C437.633 574.094 431.838 557.496 431.206 544.931C430.574 532.366 431.944 522.179 431.944 522.179Z" fill="#FD7EAE"/>
<path d="M431.604 514.248L432.43 521.274H440.041L440.867 514.248C440.934 513.682 440.492 513.185 439.922 513.185H432.549C431.98 513.185 431.538 513.682 431.604 514.248Z" fill="#E5E5E5"/>
<g opacity="0.1">
<path d="M420.056 528.412C420.056 528.412 403.519 545.767 416.432 562.702C431.059 581.884 451.456 568.762 451.456 568.762C451.456 568.762 451.679 558.909 442.402 553.685C433.124 548.461 421.888 548.39 419.848 540.55C417.808 532.71 420.056 528.412 420.056 528.412Z" fill="black" style="mix-blend-mode:multiply"/>
</g>
</g>
<g class="christmas-bulbs__bulb">
<path d="M345.302 263.092C345.395 277.809 333.545 289.815 318.834 289.908C304.123 290.001 292.123 278.146 292.03 263.43C291.937 248.713 303.787 236.707 318.497 236.614C333.208 236.521 345.208 248.376 345.302 263.092Z" fill="#765EA0"/>
<path d="M331.237 257.491C329.153 243.209 319.997 237.565 319.997 237.565C319.997 237.565 322.715 247.488 323.767 260.025C324.819 272.563 321.291 289.764 321.291 289.764C321.291 289.764 333.321 271.772 331.237 257.491Z" fill="#8A6FB8"/>
<path d="M344.319 262.24C343.329 242.668 324.236 237.809 324.236 237.809C324.236 237.809 338.506 243.13 340.062 261.314C341.617 279.498 323.529 288.474 323.529 288.474C323.529 288.474 345.308 281.812 344.319 262.24Z" fill="#8A6FB8"/>
<path d="M293.014 262.378C294.004 242.806 313.096 237.948 313.096 237.948C313.096 237.948 299.567 243.87 298.011 262.054C296.455 280.238 313.803 288.613 313.803 288.613C313.803 288.613 292.024 281.95 293.014 262.378Z" fill="#8A6FB8"/>
<path d="M313.423 237.994C313.423 237.994 305.099 244.768 304.941 259.194C304.783 273.621 319.112 289.909 319.112 289.909C319.112 289.909 313.317 273.311 312.685 260.746C312.053 248.181 313.423 237.994 313.423 237.994Z" fill="#8A6FB8"/>
<path d="M313.082 230.063L313.909 237.089H321.519L322.345 230.063C322.412 229.497 321.97 229 321.401 229H314.027C313.458 229 313.016 229.497 313.082 230.063Z" fill="#E5E5E5"/>
<g opacity="0.1">
<path d="M301.535 244.227C301.535 244.227 284.998 261.582 297.911 278.517C312.538 297.699 332.935 284.577 332.935 284.577C332.935 284.577 333.158 274.724 323.881 269.5C314.603 264.276 303.367 264.205 301.327 256.365C299.287 248.525 301.535 244.227 301.535 244.227Z" fill="black" style="mix-blend-mode:multiply"/>
</g>
</g>
</svg>`;
