import { FC, forwardRef, useContext, useEffect, useMemo, useRef, useState } from "react";
import "./Welcome.scss";
import { declOfNum, isCurrentProjectActive, userHasRoleInProject } from "../../../../../utils";
import Button from "../Button/Button";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../types/State.interface";
import { AppContext } from "../../../../Root";
import ProposeService from "../../../../../services/proposeService";
import Sign from "../Sign/Sign";
import useConnectToProject from "../../hooks/useConnectToProject";
import { LandingInfo } from "../../DiscussionLanding.interface";
import DiscussionLandingService from "../../services/discussion-service";
import { phoneWidth, wideTabletWidth } from "../../../../../utils/constants/widthConstants";
import { useMediaQuery } from "react-responsive";
import { AuthAction, AuthActionType, ComponentType } from "../../../../../services/sudirService";
import { Project } from "../../../../../types/Projects.interface";
import { getCurrentCultureLandingProject } from "../../DiscussionLanding.helper";
import Tooltip from "../../../../presentational/Controls/Tooltip/Tooltip";
import classNames from "classnames";

const Expert = forwardRef<HTMLDivElement>((_, ref) => (
  <div className="discussion-landing-welcome__expert" ref={ref}>
    <div className="discussion-landing-welcome__expert__avatar">
      <img src={require("../../../../../assets/discussion-landing/expert-avatar.png").default} alt="" />
    </div>
    <div className="discussion-landing-welcome__expert__quote">
      <div className="discussion-landing-welcome__expert__quote__text">
        Мы хотим развивать культуру Москвы, делая ее доступной, разнообразной и интересной для жителей и гостей столицы.
        Для этого создан новый формат взаимодействия с участниками «Города идей» — медиаплатформа «Город обсуждает
        стандарты культурного досуга». Она позволит горожанам активно участвовать в повышении качества оказываемых услуг
        и формировании культурной повестки столицы. Пользователи станут участниками масштабного проекта, первыми узнают
        об оценке экспертов отрасли и смогут внести свои предложения. Услуги, сервисы и мероприятия в области культуры
        получат новую жизнь именно благодаря мнениям москвичей.
      </div>
      <div className="discussion-landing-sign">
        <div className="discussion-landing-sign__name">А.А. Фурсин</div>
        <div className="discussion-landing-sign__position">
          Министр Правительства Москвы,
          <br /> руководитель Департамента культуры города Москвы
        </div>
      </div>
    </div>
  </div>
));

const Welcome: FC = () => {
  const appContext = useContext(AppContext);
  const expertRef = useRef<HTMLDivElement>();
  const connectToProject = useConnectToProject();
  const loggedIn = useSelector((state: RootState) => state.user.loggedIn);
  const currentProjects = useSelector((state: RootState) => state.projects.current);
  const completedProjects = useSelector((state: RootState) => state.projects.completed);
  const user = useSelector((state: RootState) => state.user.userDetails);
  const [count, setCount] = useState();
  const [participantCount, setParticipantCount] = useState();
  const [currentProject, setCurrentProject] = useState<Project>(null);
  const [landingInfo, setLandingInfo] = useState<Partial<LandingInfo>>();
  const isPhone = useMediaQuery({ query: `(max-width: ${phoneWidth}px)` });
  const isTablet = useMediaQuery({ query: `(max-width: ${wideTabletWidth}px)` });
  const bgId = isPhone
    ? landingInfo?.mobileImageId
    : isTablet
    ? landingInfo?.tabletImageId
    : landingInfo?.desktopImageId;

  const isParticipant = useMemo(
    () => !!currentProjects?.length && userHasRoleInProject(user, currentProjects[0].id),
    [currentProjects, user]
  );

  const shouldShowCounters = useMemo(() => !!(Number(count) && Number(participantCount)), [count, participantCount]);

  const connect = () => {
    if (!loggedIn) {
      const action = new AuthAction({
        component: ComponentType.DISCUSSION_LANDING,
        type: AuthActionType.JOIN_PROJECT,
      });
      return appContext.sudirService.authWithActions([action]);
    }

    connectToProject();
  };

  useEffect(() => {
    Promise.all([DiscussionLandingService.getInfo(), DiscussionLandingService.getParticipantCount()])
      .then(([{ data }, { data: pCount }]) => {
        setLandingInfo(data || {});
        setParticipantCount(pCount);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (currentProjects && completedProjects) {
      const project = getCurrentCultureLandingProject(currentProjects, completedProjects);
      if (project) {
        setCurrentProject(project);
        ProposeService.getGorodIdeasCount(project.id)
          .then(({ data }) => {
            setCount(data || 0);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  }, [currentProjects, completedProjects]);

  const renderCounter = (count = 0, words, isVisible: boolean) => (
    <div
      className={classNames("discussion-landing-welcome__banner__counter", {
        "discussion-landing-welcome__banner__counter--visible": isVisible,
      })}
    >
      <div className="discussion-landing-welcome__banner__counter__num">
        {String(count)
          .split("")
          .map((num, i) => (
            <span key={i}>{num}</span>
          ))}
      </div>
      <div className="discussion-landing-welcome__banner__counter__txt">{declOfNum(count, words)}</div>
    </div>
  );

  const renderParticipantTooltip = (title: string) => (
    <Tooltip
      text={`Уникальные посетители медиаплатформы «Город обсуждает стандарты культурного досуга»`}
      idx="discussion-landing-welcome-participant-tip"
      place="bottom"
      className="discussion-landing-welcome__participant-tip"
      overridePosition={({ left, top }, _, __, refNode) => {
        let oLeft = left;
        if (!isTablet && expertRef.current) {
          oLeft = expertRef.current.offsetLeft + expertRef.current.offsetWidth - refNode.clientWidth;
        }
        return { left: Math.max(oLeft, 20), top };
      }}
    >
      {title}
    </Tooltip>
  );

  return (
    <section className="discussion-landing-welcome">
      <div
        className="discussion-landing-welcome__banner"
        style={{ backgroundImage: bgId ? `url(/uploads/get/${bgId})` : undefined }}
      >
        <div className="discussion-landing-welcome__banner__plate">
          {!!landingInfo && (
            <>
              <div className="discussion-landing-title">
                {landingInfo.name || "Город обсуждает стандарты культурного досуга"}
              </div>
              <div
                className="discussion-landing-welcome__banner__description"
                dangerouslySetInnerHTML={{
                  __html:
                    landingInfo.description ||
                    `Принимайте участие в создании нового стандарта культуры Москвы — предлагайте идеи, узнавайте мнение профессионалов отрасли, первыми читайте новости об акциях и проектах.`,
                }}
              />
              <div className="discussion-landing-welcome__banner__bottom">
                {renderCounter(count, ["идея подана", "идеи поданы", "идей подано"], shouldShowCounters)}
                {renderCounter(
                  participantCount,
                  [
                    renderParticipantTooltip("посетитель*"),
                    renderParticipantTooltip("посетителя*"),
                    renderParticipantTooltip("посетителей*"),
                  ],
                  shouldShowCounters
                )}
              </div>
            </>
          )}
        </div>
        {!isPhone && <Expert ref={expertRef} />}
      </div>
      {isPhone && <Expert ref={expertRef} />}
    </section>
  );
};

export default Welcome;
