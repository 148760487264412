import { FC, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../types/State.interface";

import "./LayoutControl.scss";
import { findUrlParam } from "../../../../utils";
import { setWebViewMode } from "../../../../store/actions/LayoutActions";
import { PageExpandEnum } from "../../../../store/reducers/layout";

const LayoutControl: FC = ({ children }) => {
  const dispatch = useDispatch();
  const appColor = useSelector((state: RootState) => state.layout.appColor);
  const pageExpandState = useSelector((state: RootState) => state.layout.pageExpand);
  const isBodyScrollDisabled = useSelector((state: RootState) => state.layout.isBodyScrollDisabled);
  const inWebViewMode = findUrlParam("contentOnly", window.location.search);

  const getClassListForApp = () => {
    let classList = "app";
    if (appColor) classList += ` app-color-${appColor}`;
    return classList;
  };

  const getClassListForWrapper = () => {
    let classList = "wrapper";
    if (pageExpandState === PageExpandEnum.LESSEN) {
      classList += " wrapper__lessen";
    } else if (pageExpandState === PageExpandEnum.WIDEN) {
      classList += " wrapper__widen";
    } else if (pageExpandState === PageExpandEnum.FULLSCREEN) {
      classList += " wrapper__fullscreen";
    }
    return classList;
  };

  useEffect(() => {
    const body = document.body;
    if (isBodyScrollDisabled) {
      body.classList.add("layout__disable-scroll");
    } else {
      body.classList.remove("layout__disable-scroll");
    }
  }, [isBodyScrollDisabled]);

  useEffect(() => {
    if (inWebViewMode) {
      dispatch(setWebViewMode(true));
    }
  }, [inWebViewMode]);

  return (
    <div className={getClassListForApp()}>
      <div className={getClassListForWrapper()}>{children}</div>
    </div>
  );
};

export default LayoutControl;
