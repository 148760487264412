const reports = [
  {
    title: "Отчет за 2 полугодие 2023",
    url: "/reports/report2023_2.pdf",
    year: 2023,
  },
  {
    title: "Отчет за 1 полугодие 2023",
    url: "/reports/report2023_1.pdf",
    year: 2023,
  },
  {
    title: "Отчет за 2022",
    url: "/reports/report2022.pdf",
    year: 2022,
  },
  {
    title: "Отчет за 4 квартал 2022",
    url: "/reports/report2022_4.pdf",
    year: 2022,
  },
  {
    title: "Отчет за 3 квартал 2022",
    url: "/reports/report2022_3.pdf",
    year: 2022,
  },
  {
    title: "Отчет за 2 квартал 2022",
    url: "/reports/report2022_2.pdf",
    year: 2022,
  },
  {
    title: "Отчет за 1 квартал 2022",
    url: "/reports/report2022_1.pdf",
    year: 2022,
  },
  {
    title: "Отчет за 2021",
    url: "/reports/report2021.pdf",
    year: 2021,
  },
  {
    title: "Отчет за 4 квартал 2021",
    url: "/reports/report2021_4.pdf",
    year: 2021,
  },
  {
    title: "Отчет за 3 квартал 2021",
    url: "/reports/report2021_3.pdf",
    year: 2021,
  },
  {
    title: "Отчет за 2 квартал 2021",
    url: "/reports/report2021_2.pdf",
    year: 2021,
  },
  {
    title: "Отчет за 1 квартал 2021",
    url: "/reports/report2021_1.pdf",
    year: 2021,
  },
  {
    title: "Отчет за 2020",
    url: "/reports/report2020.pdf",
    year: 2020,
  },
];

export { reports };
