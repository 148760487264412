import React, { FC, useEffect, useRef, useState } from "react";
import { GameWrapperProps } from "./GameWrapper.types";
import GameButton from "../../Buttons/GameButton";
import "./GameWrapper.scss";
import newyear2023Service from "../../../Services/newyear2023-service";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../types/State.interface";
import { ApiStatusCode } from "../../../../../../types/Common.interface";
import { GameStatus } from "../../../../../presentational/Game/Game.interface";
import { useMediaQuery } from "react-responsive";
import { mediumTabletWidth } from "../../../../../../utils/constants/widthConstants";
import Loader from "../../../../../presentational/Loaders/Loader/Loader";

const GameWrapper: FC<GameWrapperProps> = ({
  startText,
  winTitle,
  winText,
  winIcon,
  looseTitle,
  looseText,
  looseIcon,
  gameTitle,
  children,
  onWin,
  project,
  className,
}) => {
  const isTablet = useMediaQuery({ query: `(max-width: ${mediumTabletWidth}px)` });
  const [isFetchingGame, setIsFetchingGame] = useState(false);
  const [isWin, setIsWin] = useState(false);
  const [isLoose, setIsLoose] = useState(false);
  const [isStarted, setIsStarted] = useState(false);
  const [isDisplayGame, setIsDisplayGame] = useState(false);
  const acutalStatus = useRef({ started: false, win: false, loose: false }); // для получения акутального состояие при unmount;
  acutalStatus.current = { started: isStarted, win: isWin, loose: isLoose };
  const user = useSelector((state: RootState) => state.user.userDetails);

  const setGameWin = () => {
    setIsStarted(true);
    setIsWin(true);
  };

  const handleWin = () => {
    setGameWin();
    fetchFinishGame(GameStatus.WON);
    onWin && onWin();
  };

  const handleLoose = () => {
    setIsLoose(true);
    fetchFinishGame(GameStatus.LOST);
  };

  const startGame = () => {
    setIsFetchingGame(true);
    newyear2023Service
      .startGame({ type: project })
      .then(() => {
        setIsStarted(true);
      })
      .catch(console.log)
      .finally(() => setIsFetchingGame(false));
  };

  const refresh = () => {
    setIsWin(false);
    setIsLoose(false);
    startGame();
  };

  const fetchFinishGame = (status: GameStatus) => {
    newyear2023Service.finishGame({ type: project, status: status }).catch(console.log);
  };

  useEffect(() => {
    if (!user?.loggedIn) return;
    newyear2023Service.status([project]).then((res) => {
      if (res.status === ApiStatusCode.OK) {
        const [gameRes] = res.data || [];
        if (gameRes?.status === GameStatus.WON) {
          setGameWin();
          setIsWin(true);
        } else if (gameRes?.status === GameStatus.IN_PROGRESS) {
          fetchFinishGame(GameStatus.LOST);
        }
      }

      setIsDisplayGame(true);
    });
  }, [user]);

  useEffect(() => {
    return () => {
      const { started, win, loose } = acutalStatus.current;
      if (started && !win && !loose) fetchFinishGame(GameStatus.LOST);
    };
  }, []);

  const renderLooseInfo = () => {
    return (
      <div className={"new-year-2023-gameWrapper__textWrapper"}>
        {looseIcon ? (
          looseIcon
        ) : (
          <div className={"new-year-2023-gameWrapper__icon new-year-2023-gameWrapper__loose "} />
        )}
        <p className={"new-year-2023-gameWrapper__title"}>{looseTitle ? looseTitle : "Увы!"}</p>
        <p className={"new-year-2023-gameWrapper__subTitle new-year-2023-gameWrapper__endText"}>
          {looseText ? looseText : "К сожалению, игра не пройдена. Попробуйте еще раз!"}
        </p>
        <GameButton onClick={refresh}>НАЧАТЬ ИГРУ</GameButton>
      </div>
    );
  };

  const renderWinInfo = () => {
    return (
      <div className={"new-year-2023-gameWrapper__textWrapper"}>
        {winIcon ? winIcon : <div className={"new-year-2023-gameWrapper__icon new-year-2023-gameWrapper__victory"} />}
        <p className={"new-year-2023-gameWrapper__title"}>{winTitle ? winTitle : "Поздравляем!"}</p>
        <p
          className={
            "new-year-2023-gameWrapper__subTitle new-year-2023-gameWrapper__winText new-year-2023-gameWrapper__endText"
          }
        >
          {winText
            ? winText
            : "Вы выиграли. Баллы за победу в игре будут начислены пользователям с полной учетной записью mos.ru"}
        </p>
      </div>
    );
  };

  const renderStartedInfo = () => {
    return (
      <div className={"new-year-2023-gameWrapper__textWrapper"} data-game-wrapper="started-info">
        <p className={"new-year-2023-gameWrapper__title"}>{gameTitle}</p>
        <div className={"new-year-2023-gameWrapper__subTitle"} dangerouslySetInnerHTML={{ __html: startText }}></div>
        <GameButton onClick={startGame} isLoading={isFetchingGame}>
          НАЧАТЬ ИГРУ
        </GameButton>
      </div>
    );
  };

  const renderGame = () => {
    const game = React.Children.map(children, (child) => {
      if (React.isValidElement(child)) {
        return React.cloneElement(child, { onWin: handleWin, onLoose: handleLoose });
      }
      return child;
    });
    return (
      <div className={"new-year-2023-gameWrapper__textWrapper"} data-game-wrapper="game">
        <p className={`new-year-2023-gameWrapper__title ${isTablet ? "new-year-2023-gameWrapper__hidden" : ""}`}>
          {gameTitle}
        </p>
        {game}
      </div>
    );
  };

  const renderGameStatus = () => {
    if (isStarted && !isLoose && !isWin) return renderGame();
    if (isStarted && isLoose) return renderLooseInfo();
    if (isStarted && isWin) return renderWinInfo();
    if (!isStarted) return renderStartedInfo();
  };

  return (
    <div className={`new-year-2023-gameWrapper ${className || ""}`}>
      {isDisplayGame ? (
        renderGameStatus()
      ) : (
        <div className={"new-year-2023-gameWrapper__loader"}>
          <Loader />
        </div>
      )}
    </div>
  );
};

export default GameWrapper;
