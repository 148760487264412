import React from "react";
import { AppModal } from "../../Modal/AppModal";

import "./SendSuccessModal.scss";

interface Props {
  isOpened: boolean;
  onClose: Function;
  text: string;
  children: React.ReactNode;
}

const SendSuccessModal = (props: Props) => {
  return (
    <AppModal
      center
      classNames={{ modal: "send-modal", overlay: "send-modal-overlay" }}
      open={props.isOpened}
      blockScroll={true}
      closeOnEsc={true}
      closeOnOverlayClick={false}
      onClose={props.onClose}
    >
      <div
        className="send-modal__text"
        dangerouslySetInnerHTML={{ __html: props.text }}
      ></div>
      <div className="send-modal__buttons">{props.children}</div>
    </AppModal>
  );
};

export default SendSuccessModal;
