import { Children, cloneElement, ReactElement } from "react";
import { EventsTrackConfig, EventsTrackId } from "../../types/EventsTrack.interface";
import eventsTrackConfigParams from "./eventsTrackConfigParams";
import { sendStats } from "./stats";

export const renderWithTrackId = (
    children: ReactElement,
    id: EventsTrackId | (EventsTrackId | EventsTrackId[])[],
    type: Exclude<keyof EventsTrackConfig["params"], "common"> = "click",
    replace: string[] = []
 ): ReactElement => {
    const ids = (Array.isArray(id) ? id : [id]).flat().filter(Boolean);
    if (!ids.length) {
        return children;
    }
    const child = Children.only(children);
    const addProps: {[K: string]: any} = {
        [`data-${type}-id`]: JSON.stringify(ids)
    };
    if (replace.length) {
        addProps["data-replace"] = JSON.stringify(replace);
    }
    return cloneElement(child, addProps);
}

export const serviceName = "crowd";

export const eventsTrackConfig: EventsTrackConfig = {
    params: eventsTrackConfigParams,
    callback: (data) => {
      const dataSend = {
        [serviceName]: data
      };
      sendStats(dataSend);
    }
};