// @flow

import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import Loader from "../../presentational/Loaders/Loader/Loader";
import "./ArchiveProjectDescription.scss";

interface Props {
  location: any;
  projectId: string;
  pageType: string;
  plannedRealizedIdeasData: any;
}

interface State {
  // loading: boolean;
  ideasData: any;
  defaultIdeasLimit: number;
}

class ArchiveProjectDescription extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      ideasData: null,
      defaultIdeasLimit: 200,
    };
  }

  componentDidMount() {
    this.loadComponent();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.loadComponent();
    }
  }

  async loadComponent() {
    const data = this.props.plannedRealizedIdeasData;
    const limit = this.state.defaultIdeasLimit;

    if (data) {
      data["waiting"].showMoreButton = data["waiting"].ideas?.length > limit;

      data["waiting"].sections?.forEach((section) => {
        section.showMoreButton = section.ideas?.length > limit;
      });

      data["realized"].showMoreButton = data["realized"].ideas?.length > limit;

      data["realized"].sections?.forEach((section) => {
        section.showMoreButton = section.ideas?.length > limit;
      });

      this.setState({ ideasData: data });
    }
  }

  getDefaultDescriptionText() {
    if (this.props.pageType === "ideas") {
      return "Информация будет размещаться по мере реализации идей.";
    } else if (this.props.pageType === "plan") {
      return "Список идей будет размещен в ближайшее время.";
    } else {
      return "Информация будет размещаться по мере реализации идей.";
    }
  }

  hasNoIdeas(status) {
    const noIdeas =
      !this.state.ideasData[status].title &&
      this.state.ideasData[status].ideas.length === 0;
    const noSections = this.state.ideasData[status].sections.length === 0;
    const noIdeasInSections =
      this.state.ideasData[status].sections.filter(
        (section) => section.ideas.length > 0
      ).length === 0;

    return noIdeas && (noSections || noIdeasInSections);
  }

  getIdeaListItem(idea) {
    return (
      <li
        key={idea.id}
        className="idea-text"
        dangerouslySetInnerHTML={{ __html: idea.text }}
      ></li>
    );
  }

  setShowMoreButton(show, sectionId) {
    let status = "";
    if (this.props.pageType === "plan") {
      status = "waiting";
    } else if (this.props.pageType === "ideas") {
      status = "realized";
    }

    let data = this.state.ideasData;
    if (sectionId) {
      data[status].sections.forEach((section) => {
        if (section.id === sectionId) {
          section.showMoreButton = show;
        }
      });
    } else {
      data[status].showMoreButton = show;
    }

    this.setState({ ideasData: data });
  }

  getIdeaList(data) {
    let { ideas, showMoreButton } = data;
    const limit = this.state.defaultIdeasLimit;
    const exceedingLimit = ideas.length > 200;
    if (showMoreButton && limit) ideas = ideas.slice(0, limit);

    return (
      <>
        <div className="ideas-list" key="ideas-list">
          <ol key="ol">{ideas.map((idea) => this.getIdeaListItem(idea))}</ol>
          {exceedingLimit && (
            <>
              {showMoreButton && (
                <div
                  onClick={() => this.setShowMoreButton(false, data.id)}
                  className="show-more"
                  key="show-more"
                >
                  Показать еще
                </div>
              )}
              {!showMoreButton && (
                <div
                  onClick={() => this.setShowMoreButton(true, data.id)}
                  className="show-more"
                  key="show-less"
                >
                  Свернуть
                </div>
              )}
            </>
          )}
        </div>
      </>
    );
  }

  getSection(section) {
    if (section.ideas.length === 0) {
      return <></>;
    } else {
      return (
        <div className="section-wrapper" key={section.id}>
          <p
            className="section-title"
            dangerouslySetInnerHTML={{ __html: section.title }}
          ></p>
          {this.getIdeaList(section)}
        </div>
      );
    }
  }

  getDefaultDescription() {
    return (
      <p
        style={{ textAlign: "center" }}
        className="fake-text fake-text__center"
      >
        {this.getDefaultDescriptionText()}
      </p>
    );
  }

  getIdeas() {
    if (!this.state.ideasData) return <></>;

    let status = "";
    let showDefaultDescription = false;
    const allIdeasRealized =
      this.hasNoIdeas("waiting") && !this.hasNoIdeas("realized");

    if (this.props.pageType === "plan") {
      status = "waiting";
      if (this.hasNoIdeas("waiting") && this.hasNoIdeas("realized")) {
        showDefaultDescription = true;
      }
    } else if (this.props.pageType === "ideas") {
      status = "realized";
      showDefaultDescription = this.hasNoIdeas("realized");
    }

    return (
      <div className="ideas-container">
        {showDefaultDescription ? (
          this.getDefaultDescription()
        ) : (
          <>
            {this.state.ideasData[status].title &&
              (!this.hasNoIdeas(status) ||
                (status === "waiting" && allIdeasRealized)) && (
                <p
                  className="ideas-title"
                  key="ideas-title"
                  dangerouslySetInnerHTML={{
                    __html: this.state.ideasData[status].title,
                  }}
                ></p>
              )}

            {this.state.ideasData[status].sections.length
              ? this.state.ideasData[status].sections.map((section) =>
                  this.getSection(section)
                )
              : this.getIdeaList(this.state.ideasData[status])}
          </>
        )}
      </div>
    );
  }

  renderContent() {
    // if (!this.state.loading) {
    if (this.props.pageType === "ideas" || this.props.pageType === "plan") {
      return this.getIdeas();
    }
    // }
  }

  render() {
    return <>{this.renderContent()}</>;
  }
}

export default withRouter(ArchiveProjectDescription);
